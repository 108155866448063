import { MAX_NUMBER_VALUE, MIN_NUMBER_VALUE } from '../constants';
import { CellObjectValue } from '../interfaces';

// Code copied from app-core (app-core/dev2/web/javascript/legacyApp/src/app1/NumberUtil.ts)
// If change is required here, consider changing in app-core as well
/**
 * Multiply a number by a specified power of 10 without being subject to rounding errors that can normally occur.
 * Can fail if the resulting number is too large to be interpreted by parseFloat.
 */
const safeMultiplyByPowerOf10 = (numberToMultiply: number | string, power: number): number => {
    // need 2 different approaches, one
    const numString = numberToMultiply.toString();
    if (numString.indexOf('e') > 0) {
        // scientific notation - in this case we need to modify the power that's already there
        const parts = numString.split('e');
        return +(parts[0] + 'e' + (parseInt(parts[1], 10) + power)); // eslint-disable-line @typescript-eslint/restrict-plus-operands
    } else {
        // normal - in this case we use a shortcut of adding scientific notation and letting JS parse the number back to a float
        return +(numString + 'e' + power); // eslint-disable-line @typescript-eslint/restrict-plus-operands
    }
};

// Code copied from app-core (app-core/dev2/web/javascript/legacyApp/src/app1/NumberUtil.ts)
// If change is required here, consider changing in app-core as well
/**
 * Returns a string representation of the given number in standard format (not in exponential format). The word
 * "safe" in this method name is referring to the "unsafety" of letting JS cast a number to a string on its own;
 * when JS casts a number on its own, it sometimes casts it in exponential notation, which is undesirable in our
 * app.
 */
const safeNumberToString = (numericValue: number): string => {
    const numberString = numericValue.toString();

    // Note that ECMAScript denotes that 'e' will be used in exponential format, not 'E', so we can just check for 'e'.
    if (numberString.indexOf('e') === -1) {
        return numberString;
    }

    // If we're here, we need to pull information out of the exponentially formatted string to figure out how many digits
    // to print. We're not trying to handle really big numbers that get converted to exponential format in Javascript because
    // the largest number we support is 9007199254740992, which is 16 digits, and ECMAScript states that it's only after 21
    // digits appear to the left of the decimal that numbers are to be stringified in exponential format.
    const numberParts: string[] = numberString.split('e');
    // Any type used since we are coercing a string to a number in this case and typescript will not allow a string in this case
    const numberOfZeros: number = 1 * (numberParts[1].substr(1) as any); // Avoiding minus sign, converting string to number.
    const numCharactersToAvoid = numericValue < 0 ? 3 : 2; // Avoiding first digit, and decimal point and minus sign (if present)
    const numberOfDigitsRightOfDecimalPoint = Math.max(numberParts[0].length - numCharactersToAvoid, 0); // Being careful of cases like 1e-10, where
    // we want to note 0 digits right of the decimal.
    // Note: Although we ran into issues with using .toFixed on Chrome vs. IE (Bug-16141), this usage should be safe, since we
    // won't be recomputing these values each time a user opens a sheet. Users in IE vs Chrome will get different values for very
    // improbable numbers such as -0.0000000432632465455 (19 digits, IE and Chrome round differently to 18 digits), but it's
    // only during that rounding step and never again, so there is no dirty save risk.
    // We can only store 18 digits after the decimal in the database.
    return numericValue.toFixed(Math.min(numberOfZeros + numberOfDigitsRightOfDecimalPoint, 18));
};

// Code copied from app-core (app-core/dev2/web/javascript/legacyApp/src/app1/NumberUtil.ts)
// If change is required here, consider changing in app-core as well
/**
 * Returns the number of decimal places to store for the given numericValue based on the following two conditions:
 *   We can store at most 15 digits, in order to honor floating point precision (where the significand
 *       is stored with 52 bits, which approximately equals 15 decimal digits)
 *   We cannot store any digits past the 18th position, in order to honor the way our database stores numbers
 *
 * @param numericInputValue - number to get decimal places for
 * @return number of decimals places to store for the given numericValue
 */
const getNumValidDecimalPlaces = (numericInputValue: number): number => {
    // These cases require numericValue to be positive.
    const numericValue = Math.abs(numericInputValue);

    let numValidDecimalPlaces;

    // These cases satisfy the requirements listed in the description of the method by using the size of the number
    // to determine how many digits after the decimal we should store.
    // Note that while the largest value we support (9007199254740992) is 16 digits, we only treat user input as numeric if it
    // is less than or equal to that value, so we don't need to worry about encountering numbers that can't be represented exactly
    // in floating point. We could do this rounding (to the -1st position when a number is >=1e+15), but we encountered issues
    // with grid formats from doing this rounding, and not doing it shouldn't hurt anything.
    // This is a hardcoded binary search for speeeeeed!
    if (numericValue >= 1e5) {
        if (numericValue >= 1e10) {
            if (numericValue >= 1e13) {
                if (numericValue >= 1e14) {
                    numValidDecimalPlaces = 0;
                } else {
                    numValidDecimalPlaces = 1;
                }
            } else {
                if (numericValue >= 1e12) {
                    numValidDecimalPlaces = 2;
                } else if (numericValue >= 1e11) {
                    numValidDecimalPlaces = 3;
                } else {
                    numValidDecimalPlaces = 4;
                }
            }
        } else {
            if (numericValue >= 1e7) {
                if (numericValue >= 1e9) {
                    numValidDecimalPlaces = 5;
                } else if (numericValue >= 1e8) {
                    numValidDecimalPlaces = 6;
                } else {
                    numValidDecimalPlaces = 7;
                }
            } else {
                if (numericValue >= 1e6) {
                    numValidDecimalPlaces = 8;
                } else {
                    numValidDecimalPlaces = 9;
                }
            }
        }
    } else {
        if (numericValue >= 1) {
            if (numericValue >= 1e2) {
                if (numericValue >= 1e4) {
                    numValidDecimalPlaces = 10;
                } else if (numericValue >= 1e3) {
                    numValidDecimalPlaces = 11;
                } else {
                    numValidDecimalPlaces = 12;
                }
            } else {
                if (numericValue >= 1e1) {
                    numValidDecimalPlaces = 13;
                } else {
                    numValidDecimalPlaces = 14;
                }
            }
        } else {
            if (numericValue >= 1e-2) {
                if (numericValue >= 1e-1) {
                    numValidDecimalPlaces = 15;
                } else {
                    numValidDecimalPlaces = 16;
                }
            } else {
                if (numericValue >= 1e-3) {
                    numValidDecimalPlaces = 17;
                } else {
                    numValidDecimalPlaces = 18;
                }
            }
        }
    }
    return numValidDecimalPlaces;
};

// Return true if entry is a string that can be converted to a number or if input is a number.
const isStringNumber = (entry: CellObjectValue): boolean => {
    return typeof entry === 'number' || (typeof entry === 'string' && getNumberFromString(entry) !== undefined);
};

/**
 * Returns an equivalent number if a string conforms to Smartsheet's number format
 */
const getNumberFromString = (entry: string, thousandsSeparator = ',', decimalSeparator = '.'): number | undefined => {
    if (entry.trim() === '') {
        return;
    }

    entry = entry.trim();

    // Check if first char is non a numeric and is not '-' or '.'
    // This will remove issues with Number function converting true, false, Infinity, or dates to valid numbers
    if (entry.slice(0, 1) !== '-' && entry.slice(0, 1) !== decimalSeparator && isNaN(Number(entry.slice(0, 1)))) {
        return;
    }

    // Check for more than one decimal point
    const splitByDecimalArray = entry.split(decimalSeparator);
    if (splitByDecimalArray.length > 2) {
        return;
    }

    // Check for leading zeros
    // If leading character is '0' and 2nd char is NOT a decimal, return false so that entry gets sent back as a string
    if (entry.length > 1 && entry.slice(0, 1) === '0' && entry.slice(1, 2) !== decimalSeparator) {
        return;
    }

    // If leading character is '-' and 2nd char is '0' and 3rd char is NOT a decimal, return false so that entry gets sent back as a string
    if (entry.length > 2 && entry.slice(0, 1) === '-' && entry.slice(1, 2) === '0' && entry.slice(2, 3) !== decimalSeparator) {
        return;
    }

    // Check for valid thousands separator or no thousands separator
    const leftSplitByThousands = splitByDecimalArray[0].split(thousandsSeparator);

    if (leftSplitByThousands.length > 1) {
        const validThousands = leftSplitByThousands.every((numberGroup: string, index: number) => {
            if (index === 0) {
                return numberGroup.length <= 3;
            }

            return numberGroup.length === 3;
        });

        if (!validThousands) {
            return;
        }
    }

    // Check if decimal contains thousands separator since this will be removed
    if (splitByDecimalArray.length > 1) {
        if (splitByDecimalArray[1].includes(thousandsSeparator)) {
            return;
        }
    }

    // Remove thousands separator
    let entryInEnglishFormat = entry.split(thousandsSeparator).join('');

    // Remove decimal separator if applicable
    if (decimalSeparator !== '.') {
        entryInEnglishFormat = entryInEnglishFormat.split(decimalSeparator).join('.');
    }

    // Check for scientific notation
    if (/e|E/.test(entryInEnglishFormat)) {
        return;
    }

    const numberFromString = Number(entryInEnglishFormat);

    // Check if number
    if (isNaN(numberFromString)) {
        return;
    }

    // Check if number exceeds supported values
    if (numberFromString > MAX_NUMBER_VALUE || numberFromString < MIN_NUMBER_VALUE) {
        return;
    }

    // Return Number
    return numberFromString;
};

/**
 * Returns an equivalent number if a string ends in '%' (ie. 30% will return 0.3), otherwise call getNumberFromString
 * Note this percentage case is not added into getNumberFromString because that might impact other areas of the app that use getNumberFromString
 */
const getNumberFromPercentStringOrOtherString = (entry?: string): number | undefined => {
    if (!entry) {
        return undefined;
    }

    // If last character is a %, try to convert to a valid percentage & divide by 100 & return
    const indexOfPercent = entry.indexOf('%');
    const length = entry.length;
    if (length > 1 && indexOfPercent === length - 1) {
        const peelNumber = getNumberFromString(entry.slice(0, indexOfPercent));
        if (peelNumber !== undefined) {
            return peelNumber / 100;
        }
    }

    // If entry is not a percentage, just call regular method to get number from string
    return getNumberFromString(entry);
};

export {
    getNumValidDecimalPlaces,
    getNumberFromPercentStringOrOtherString,
    getNumberFromString,
    isStringNumber,
    safeMultiplyByPowerOf10,
    safeNumberToString,
};
