import * as React from 'react';
import { AutomationTypes } from '../../common/enums/AutomationElements.enum';
import '../Modal/Content/GenericModalContent.css';
import './Spinner.css';

interface Props {
    label?: string;
    color?: Color;
    size?: Size;
    className?: string;
}

const Spinner: React.FC<Props> = (props) => {
    return (
        <span
            className={`${props.color || Color.BLUE} ${props.size || Size.MEDIUM} ${props.className || ''}`}
            data-client-type={AutomationTypes.PROGRESS_INDICATOR}
        >
            <div className="spinner-container">
                <div className="spinner" />
                {props.label ? <p>{props.label}</p> : null}
            </div>
        </span>
    );
};

export default Spinner;

// Add small and large sizes as needed
export enum Size {
    XSMALL = 'xsmall',
    SMALL = 'small',
    MEDIUM = 'medium',
}

export enum Color {
    WHITE = 'white',
    BLUE = 'blue',
    GREEN = 'green',
}
