import { Column } from '../../../common/interfaces';
import * as React from 'react';
import { Option } from 'react-select';

import { WithDataClientProps } from '../../../components/hoc/WithDataClient';
import Select from '../../../components/Select';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';
import SectionTitle from '../SectionTitle';

export interface BasicUserColumnProps extends WithDataClientProps {
    dropDownColumns: Column[];
    userColumnData: Column | undefined;
    onUserColumnChange: (option: Option<number>) => void;
}

const BasicUserColumn: React.FC<BasicUserColumnProps> = (props) => {
    const languageElements = useLanguageElements();
    return (
        <div className="userCol" id="basicUserCol">
            <SectionTitle
                title={languageElements.ADMIN_PANEL_BASIC_TAB_SELECT_USER_COLUMN_HEADER}
                subtitle={languageElements.ADMIN_PANEL_BASIC_TAB_SELECT_USER_COLUMN_BODY}
            />
            <Select
                id={'basicUserColSelect'}
                placeholder={languageElements.ADMIN_PANEL_BASIC_USER_COLUMN_NO_RESTRICTION}
                dataClientId={props.dataClientId}
                // @ts-ignore: stopgap until <Select> replaced with accessible (Lodestar) version
                options={props.dropDownColumns.map((column: Column) => ({ value: column.id, label: column.title }))}
                // @ts-ignore: stopgap until <Select> replaced with accessible (Lodestar) version
                selectedOption={props.userColumnData ? props.userColumnData.id : 0}
                // @ts-ignore: stopgap until <Select> replaced with accessible (Lodestar) version
                onSelect={(selectedOption) => props.onUserColumnChange(selectedOption)}
            />
        </div>
    );
};

export default BasicUserColumn;
