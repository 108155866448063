import * as React from 'react';
import { AutomationIds } from '../../../common/enums/AutomationElements.enum';
import { RailItem } from './RailItem.interface';

interface Props {
    activeItemKey: string;
    items: RailItem[];
    onActiveItemChange: (item: RailItem) => void;
}

/**
 * Currently a placeholder
 */
const RightRail: React.SFC<Props> = (props: Props) => (
    <div className={'right-rail'}>
        {
            props.items.map((item: RailItem, index: number) => {
                return (
                    <span
                        key={item.key}
                        onClick={() => props.onActiveItemChange(item)}
                        className={props.activeItemKey === item.key ? 'active' : ''}
                        data-client-id={`${AutomationIds.RIGHT_RAIL_SPAN}${index}`}
                    >
                        <img
                            src={item.icon}
                            alt-text={item.text}
                        />
                    </span>
                );
            })
        }
    </div>
);

export default RightRail;
