import { Location } from 'history';
import { matchPath } from 'react-router';
import { loggingClient } from '../../http-clients/Logging.client';

const ERRORS_TO_IGNORE = [
    `ResizeObserver loop limit exceeded`,
    `ResizeObserver loop completed with undelivered notifications.`,
    `You can't have a focus-trap without at least one focusable element`,
    `Script error.`, // This error often indicates a problem with loading external scripts
    `Identifier 'listenerName' has already been declared`, // This error is thrown in chrome extensions
];

export const handleAppErrors = (event: Event | string, source?: string, lineno?: number, colno?: number, error?: Error) => {
    const eventType = typeof event === 'string' ? event : event.type;

    // There are some errors that are either safe to ignore or are ones that we don't want to report.
    const ignoreError = ERRORS_TO_IGNORE.some((errorToIgnore) => eventType.includes(errorToIgnore));
    if (ignoreError) {
        return true;
    }

    loggingClient.logError('AppUtils.ts', 'handleAppErrors', error ?? 'Unknown Error', { source, lineno, colno, eventType });

    return false;
};

export const getViewIdFromRoute = (location: Location): string | undefined => {
    const match = matchPath<{ viewId: string }>(location.pathname, {
        path: '/views/:viewId/',
    });
    return match?.params?.viewId;
};

export const createUrlPath = (path: string = '') => {
    return `${process.env.PUBLIC_URL || ''}${path}`;
};
