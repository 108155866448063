export enum OwnershipTransferStatus {
    PENDING = 'PENDING',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    CANCELED = 'CANCELED',
    ACCEPT_IN_PROGRESS = 'ACCEPT_IN_PROGRESS',
    REJECT_IN_PROGRESS = 'REJECT_IN_PROGRESS',
    CANCEL_IN_PROGRESS = 'CANCEL_IN_PROGRESS',
    NONE = 'NONE',
}
