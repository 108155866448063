import { HomeView } from '../../common/interfaces';
import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import infoIcon from '../../assets/images/symbolIcons/icon-info-circle-main.svg';
import { AutomationIds } from '../../common/enums/AutomationElements.enum';
import homeClient from '../../http-clients/HomeClient';
import * as HomeActions from '../Home/Actions';
import { favoriteViews } from '../Home';
import { useLanguageElements } from '../../language-elements/withLanguageElementsHOC';

export interface ViewHeaderOwnProps {
    viewName?: string;
    viewId: string;
    onClickViewInfo: () => void;
}

interface StateProps {
    favoriteViews: HomeView[];
}

interface DispatchProps {
    removeFavoriteView: (viewId: string) => HomeActions.Actions;
    storeFavoriteView: (viewId: string) => HomeActions.Actions;
}

type ViewHeaderProps = ViewHeaderOwnProps & StateProps & DispatchProps;

const ViewHeader: React.FC<ViewHeaderProps> = (props) => {
    const languageElements = useLanguageElements();
    if (!props.viewName) {
        return null;
    }

    const isFavoriteView = props.favoriteViews.filter((view) => view.id === props.viewId).length > 0;

    const altText = isFavoriteView ? languageElements.FAVORITE_ICON_REMOVE_ALT_TEXT : languageElements.FAVORITE_ICON_ADD_ALT_TEXT;

    const toggleFavorite = (): void => {
        if (isFavoriteView) {
            homeClient.deleteFavoriteView(props.viewId);
            props.removeFavoriteView(props.viewId);
        } else {
            homeClient.storeFavoriteView(props.viewId);
            props.storeFavoriteView(props.viewId);
        }
    };

    return (
        <React.Fragment>
            <button
                className={`btn-toggle-favorites ${isFavoriteView ? 'favorite' : ''}`}
                onClick={toggleFavorite}
                data-client-id={AutomationIds.VIEW_FAVORITE_ICON}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                    <g fill="none" fillRule="nonzero">
                        <path
                            fill="#CCC"
                            className="favorite-outline"
                            // eslint-disable-next-line max-len
                            d="M8 12.25l-3.212 1.809a.5.5 0 0 1-.739-.515l.622-3.885-2.665-2.784a.5.5 0 0 1 .284-.84l3.653-.569 1.603-3.48a.5.5 0 0 1 .908 0l1.603 3.48 3.653.569a.5.5 0 0 1 .284.84L11.33 9.659l.622 3.885a.5.5 0 0 1-.74.515L8 12.25z"
                        />
                        <path
                            fill="#FFF"
                            className="favorite-fill"
                            d="M10.263 9.326l2.346-2.45-3.236-.504L8 3.39 6.627 6.372l-3.236.504 2.346 2.45-.536 3.353L8 11.102l2.8 1.577z"
                        />
                    </g>
                </svg>
                <div className="tooltip">
                    <div>{altText}</div>
                </div>
            </button>
            <div className="view-description-container" data-client-id={AutomationIds.VIEW_TITLE}>
                {props.viewName}
            </div>
            <div className="view-description-info">
                <img
                    className="info-icon"
                    src={infoIcon}
                    alt="info icon"
                    onClick={props.onClickViewInfo}
                    data-client-id={AutomationIds.VIEW_DESCRIPTION}
                />
                <div className={'tooltip'}>
                    <div>{languageElements.ADMIN_PANEL_BASIC_VIEW_DESCRIPTION}</div>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapState = createStructuredSelector({
    favoriteViews,
});

const mapDispatch = {
    removeFavoriteView: HomeActions.Actions.removeFavoriteView,
    storeFavoriteView: HomeActions.Actions.storeFavoriteView,
};

export default connect<StateProps, DispatchProps>(mapState, mapDispatch)(ViewHeader);
