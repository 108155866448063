import styled from '@emotion/styled';
import { ModalBody, ModalFooter, ModalFooterButton, ModalHeader } from '@smartsheet/lodestar-core';
import { AdminControlsData } from '../../common/interfaces';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { AutomationIds } from '../../common/enums/AutomationElements.enum';
import { ViewStatus } from '../../common/enums/ViewStatus';
import viewClient from '../../http-clients/View.client';
import { useLanguageElements } from '../../language-elements/withLanguageElementsHOC';
import { Actions as AppActions } from '../App/Actions';
import { Actions as HomeActions } from '../Home/Actions';

interface Props {
    itemsToDelete: Set<string>;
    views: Map<string, AdminControlsData>;
    onHideModal: () => void;
    onUpdateSelectedViews: () => void;
    onUpdateViews: (views: Map<string, AdminControlsData>) => void;
}

const AdminControlsDeleteModalBody: React.FC<Props> = ({ views, itemsToDelete, onHideModal, onUpdateSelectedViews, onUpdateViews }) => {
    const dispatch = useDispatch();
    const languageElements = useLanguageElements();

    const handleConfirm = async () => {
        try {
            await viewClient.updateViewStatus(Array.from(itemsToDelete), ViewStatus.ARCHIVED);

            // update view counts in home panel
            dispatch(HomeActions.fetchHomeData());

            // update admin control views
            const newData = new Map(views);
            itemsToDelete.forEach((id) => newData.delete(id));

            onUpdateViews(newData);
            onUpdateSelectedViews();
        } catch (err) {
            // generic error handler
            dispatch(AppActions.setAppStageError(err));
        } finally {
            onHideModal();
        }
    };

    const modalSubtitle =
        itemsToDelete.size === 1
            ? languageElements.ADMIN_CONTROLS_DELETE_SPECIFIC_ITEM_MODAL_BODY(views.get(itemsToDelete.values().next().value)?.name || '')
            : languageElements.ADMIN_CONTROLS_DELETE_AMOUT_OF_ITEMS_MODAL_BODY(itemsToDelete.size);

    return (
        <div data-testid={AutomationIds.ADMIN_CONTROLS_DELETE_VIEW_MODAL}>
            <ModalHeader title={languageElements.ADMIN_CONTROLS_DELETE_MODAL_TITLE} onCloseRequested={onHideModal} />
            <ModalBody>
                <Subtitle>{modalSubtitle}</Subtitle>
            </ModalBody>
            <ModalFooter>
                <ModalFooterButton appearance="secondary" onClick={onHideModal}>
                    {languageElements.BUTTON_TEXT_CANCEL}
                </ModalFooterButton>
                <ModalFooterButton appearance="destructive" onClick={handleConfirm}>
                    {languageElements.MODAL_DELETE_BUTTON_TEXT}
                </ModalFooterButton>
            </ModalFooter>
        </div>
    );
};

const Subtitle = styled.h4`
    font-size: 13px;
    font-weight: 400;
    margin: 0;
`;

export default AdminControlsDeleteModalBody;
