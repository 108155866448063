import axiosInstance from './Axios.instance';

class HomeClient {
    private static url = '/home';

    public async deleteFavoriteView(viewId: string): Promise<void> {
        try {
            await axiosInstance.delete(`${HomeClient.url}/favorites/${viewId}`);
        } catch (e) {
            // fail silently
        }
    }

    public async deletePinnedView(viewId: string): Promise<void> {
        try {
            await axiosInstance.delete(`${HomeClient.url}/pinned/${viewId}`);
        } catch (e) {
            // fail silently
        }
    }

    public async storeFavoriteView(viewId: string): Promise<void> {
        try {
            await axiosInstance.post(`${HomeClient.url}/favorites/${viewId}`);
        } catch (e) {
            // fail silently
        }
    }

    public async storePinnedView(viewId: string): Promise<void> {
        try {
            await axiosInstance.post(`${HomeClient.url}/pinned/${viewId}`);
        } catch (e) {
            // fail silently
        }
    }
}

const homeClient = new HomeClient();
export default homeClient;
