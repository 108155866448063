import { createSelector } from 'reselect';
import { StoreState } from '../../store';
import { convertImmutableMapToJsMap } from '../Utils/ConvertImmutableMapToJsMap';
import { ImagesState, StateProps as ImagesStateProps } from './Reducers';

export const imageThumbnailsSelector = createSelector(
    (state: StoreState) => state.images.get(ImagesStateProps.THUMBNAILS) as ImagesState[ImagesStateProps.THUMBNAILS],
    (imageUrls) => convertImmutableMapToJsMap(imageUrls)
);

export const imageUrlsSelector = createSelector(
    (state: StoreState) => state.images.get(ImagesStateProps.IMAGES) as ImagesState[ImagesStateProps.IMAGES],
    (imageUrls) => convertImmutableMapToJsMap(imageUrls)
);

export const imageExpirationsSelector = createSelector(
    (state: StoreState) => state.images.get(ImagesStateProps.EXPIRATIONS) as ImagesState[ImagesStateProps.EXPIRATIONS],
    (imageExpirations) => convertImmutableMapToJsMap(imageExpirations)
);

export const imageGridThumbnailsSelector = createSelector(
    (state: StoreState) => state.images.get(ImagesStateProps.GRID) as ImagesState[ImagesStateProps.GRID],
    (imageUrls) => convertImmutableMapToJsMap(imageUrls)
);
