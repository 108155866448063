/* eslint max-classes-per-file:"off",@typescript-eslint/unbound-method:"off" */
import * as React from 'react';
import { deepEqual } from '@smartsheet/smart-utils/lib/MiscUtils';
import { autoBindMethods } from '@smartsheet/smart-utils/lib/ObjectUtils';
import { camelToDashed } from '@smartsheet/smart-utils/lib/StringUtils';
import { AutomationIds, AutomationTypes } from '../../common/enums/AutomationElements.enum';

export interface DataClientProps {
    dataClientId?: AutomationIds;
    dataClientType?: AutomationTypes;
}

// Base interface to be extended if your component supports/passes through Aria tags
export interface AriaProps {
    ariaDisabled?: boolean;
    ariaHidden?: boolean;
    ariaLabel?: string;
    ariaLabelledby?: string;
    ariaModal?: boolean;
    ariaRequired?: boolean;
    role?: string;
}

export interface BaseProps extends DataClientProps, AriaProps { }

// list of prop names of above interface so we can do run-time checking/filtering of props
type AriaKey = keyof AriaProps;
const ariaPropNames: AriaKey[] = [
    'ariaDisabled',
    'ariaHidden',
    'ariaLabel',
    'ariaLabelledby',
    'ariaModal',
    'ariaRequired',
    'role',
];
const ariaPropNameSet: Set<string> = new Set<string>(ariaPropNames);

// Returns an object containing a subset of the provided props,
// including only those defined in AriaProps.
// Note that the keys get converted to kebab-case for outputting as JSX attributes
export const getAriaAttributesFromProps = (props: AriaProps): {[key: string]: string} => {
    const ariaAttributes: {[key: string]: string} = {};
    if (props) {
        for (const key of Object.keys(props)) {
            if (ariaPropNameSet.has(key)) {
                ariaAttributes[camelToDashed(key)] = (props as any)[key];
            }
        }
    }

    return ariaAttributes;
};

// list of method names we don't want to auto-bind,
// specifically the object constructor and React lifecycle methods
const lifecycleMethods = new Set([
    'constructor',
    'UNSAFE_componentWillMount',
    'componentDidMount',
    'shouldComponentUpdate',
    'componentDidUpdate',
    'render',
]);

// Wrapper around React.PureComponent<> which auto-binds any methods which may be used as event-handlers
// or passed as props to a react component, ensuring 'this' is properly bound
export abstract class PureBaseComponent<P extends object, S = unknown> extends React.PureComponent<P, S> {
    public constructor(props: P) {
        super(props);
        autoBindMethods(this, lifecycleMethods);
    }
}

// Wrapper around React.Component<> which auto-binds any methods which may be used as event-handlers
// or passed as props to a react component, ensuring 'this' is properly bound
export abstract class BaseComponent<P extends object, S = unknown> extends React.Component<P, S> {
    public constructor(props: P) {
        super(props);
        autoBindMethods(this, lifecycleMethods);
    }

    // returns whether or not 'nextProps' is deep equal to the component's
    // current props
    public propsDeepEqual(nextProps: P): boolean {
        return deepEqual(this.props, nextProps);
    }

    // returns whether or not 'nextState' is deep equal to the component's
    // current state
    public stateDeepEqual(nextState: S): boolean {
        return deepEqual(this.state, nextState);
    }
}
