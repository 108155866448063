import classNames from 'classnames';
import { MouseEventHandler, SFC } from 'react';
import './PinToggle.css';

import * as React from 'react';
import pinIcon from '../../../assets/images/icons/pin.svg';
import unpinIcon from '../../../assets/images/icons/un-pin.svg';
import { AutomationIds, AutomationTypes } from '../../../common/enums/AutomationElements.enum';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';

interface PinToggleProps {
    isPinned: boolean;
    onClick: MouseEventHandler<HTMLButtonElement>;
}

// Adapted from UI Engineering Recents Panel
export const PinToggle: SFC<PinToggleProps> = (props) => {
    const className = classNames('pin-button', `${props.isPinned ? 'pinned' : ''}`);
    const languageElements = useLanguageElements();
    return (
        <button
            className={className}
            data-client-id={AutomationIds.LEFT_PANEL_PIN_BUTTON}
            data-client-type={AutomationTypes.LEFT_PANEL_PIN_TYPE}
            onClick={props.onClick}
        >
            <img className="pin-icon" src={pinIcon} alt={languageElements.RECENTS_PIN_ALT_TEXT} data-client-id={AutomationIds.LEFT_PANEL_PIN_ICON} />
            <img
                className="unpin-icon"
                src={unpinIcon}
                alt={languageElements.RECENTS_UNPIN_ALT_TEXT}
                data-client-id={AutomationIds.LEFT_PANEL_UNPIN_ICON}
            />
        </button>
    );
};
