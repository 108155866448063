import styled from '@emotion/styled';
import { Spinner } from '@smartsheet/lodestar-core';
import { IHyperlink, Image } from '../../../common/interfaces/ViewData.interface';
import classnames from 'classnames';
import * as React from 'react';
import { AutomationTypes } from '../../../common/enums/AutomationElements.enum';
import { ValueWithSpansAndLinks } from '../../ValueWithSpansAndLinks/ValueWithSpansAndLinks';
import CellImage from './CellImage';

export const innerCell = (
    displayValue: string,
    cellFormatClassNames: string[],
    symbol?: string,
    hyperlink?: IHyperlink,
    image?: Image,
    containerId?: number
): JSX.Element | undefined => {
    const classNames = classnames('data-cell', cellFormatClassNames);
    let child: React.ReactNode;

    if (symbol) {
        child = (
            <span className="option-picker-value">
                <img className={classnames({ hidden: displayValue === 'None' })} src={symbol} alt={displayValue + ' icon'} />
            </span>
        );
    } else if (image) {
        child = <CellImage image={image} />;
    } else if (containerId && hyperlink) {
        switch (hyperlink.url) {
            case '':
                child = <SpinnerStyled size="small" />;
                break;
            case '-1':
                hyperlink.url = '';
                child = ValueWithSpansAndLinks({ displayValue, hyperlink });
                break;
            default:
                child = ValueWithSpansAndLinks({ displayValue, hyperlink });
        }
    } else {
        child = ValueWithSpansAndLinks({ displayValue, hyperlink });
    }

    // Revised to use tertiary with symbol instead of '{symbol && ...}' nested in the JSX because the latter returns null on the JSX which
    // complicated unit tests
    return (
        <div title={displayValue} className={classNames} data-client-type={AutomationTypes.GRID_RENDERER}>
            {child}
        </div>
    );
};

const SpinnerStyled = styled(Spinner)`
    width: 3px !important;
`;
