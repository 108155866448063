import { DisplayField } from '../../common/interfaces';
import * as React from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { AutomationIds, AutomationTypes } from '../../common/enums/AutomationElements.enum';
import { OrderedListProps } from '../DragAndDrop/DndInterfaces';
import DndItem from '../DragAndDrop/DndItem';
import DroppableContainer from '../DragAndDrop/DroppableContainer';
import { useLanguageElements } from '../../language-elements/withLanguageElementsHOC';

const reorder = (list: DisplayField[], startIndex: number, endIndex: number): DisplayField[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const displayFieldsToDndItemModel = (displayFields: DisplayField[]): DisplayField[] => {
    let ordinal = 0;
    const itemModels: DisplayField[] = displayFields.map((displayField: DisplayField) => ({
        ...displayField,
        id: `id-${ordinal}`,
        ordinal: ordinal++,
    }));
    return itemModels;
};

const Orderedlist: React.FC<OrderedListProps> = (props) => {
    const languageElements = useLanguageElements();
    let displayedFields: DisplayField[] = displayFieldsToDndItemModel(props.collection);

    const onDragEnd = (result: DropResult): void => {
        if (!result.destination) {
            return;
        }

        displayedFields = reorder(displayedFields, result.source.index, result.destination.index);

        updateOrdinal();
        props.onChange(displayedFields);
    };

    const updateOrdinal = (): void => {
        let ordinal = 0;
        for (const item of displayedFields) {
            const newOrdinal = ordinal++;

            item.ordinal = newOrdinal;
        }
    };

    const style = {
        width: props.width,
    };

    return (
        <div>
            <DragDropContext onDragEnd={onDragEnd}>
                <DroppableContainer
                    droppableId="droppable"
                    width={props.width}
                    style={style}
                    className="dnd-items"
                    isDropDisabled={false}
                    dataClientId={AutomationIds.ORDERED_LIST_DISPLAY_FIELD_CONTAINER}
                >
                    {displayedFields.map((item) => (
                        <DndItem key={item.columnId} itemModel={item} dataClientType={AutomationTypes.ORDERED_LIST_DISPLAY_FIELD_ITEM} />
                    ))}
                    {!displayedFields.length && <span className="displayDefaultText">{languageElements.SELECT_VISIBLE_FIELDS}</span>}
                </DroppableContainer>
            </DragDropContext>
        </div>
    );
};

export default Orderedlist;
