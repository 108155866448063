import styled from '@emotion/styled';
import { colors } from '@smartsheet/lodestar-core';
import { format } from 'date-fns';
import * as React from 'react';
import { default as ReactDatePicker, ReactDatePickerProps } from 'react-datepicker';
import { useSelector } from 'react-redux';
import { AutomationTypes } from '../../common/enums/AutomationElements.enum';
import { getDefaultDateFnsFormatForLocale } from '../../common/utils/DatesFns';
import { isValidDateString, parseDateInput } from '../../common/utils/MomentDates';
import { userLocaleSelector } from '../../containers/Auth/Selectors';
import { useLanguageElements } from '../../language-elements/withLanguageElementsHOC';
import { WithDataClientProps } from '../hoc/WithDataClient';
import './DateSelector.css';
import dateIcon from './assets/icon-date-picker.svg';

export interface DateSelectorProps extends WithDataClientProps {
    date?: string;
    onChange: (date?: string) => void;
    disabled?: boolean;
    containerClassName?: string;
    datePickerProps?: Partial<ReactDatePickerProps>;
}

interface CustomInputProps {
    disabled?: boolean;
    dataTestId?: string;
}

const CustomInput = React.forwardRef((props: CustomInputProps, ref: React.Ref<HTMLInputElement>) => {
    const languageElements = useLanguageElements();

    return (
        <DateSelectorInputWrapper disabled={props.disabled}>
            <DateSelectorInput ref={ref} data-testid={props.dataTestId} {...props} />
            <DateSelectorIcon src={dateIcon} alt={languageElements.DATE_SELECTOR_CALENDAR_ICON} />
        </DateSelectorInputWrapper>
    );
});

CustomInput.displayName = 'DateSelectorCustomInput';

const DateSelector: React.FC<DateSelectorProps> = (props) => {
    const [value, setValue] = React.useState<string | undefined | Date>(props.date);
    React.useEffect(() => {
        if (!value && props.date) {
            setValue(props.date);
        }
    }, [value, props.date]);

    const datePickerRef = React.useRef<ReactDatePicker>(null);

    const userLocale = useSelector(userLocaleSelector);
    const defaultDateFormat = getDefaultDateFnsFormatForLocale(userLocale);

    const handleChangeRaw = (e: React.FocusEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        props.onChange(e.target.value);
    };

    const handleChange = (date: Date | [Date, Date] | null) => {
        if (date instanceof Date) {
            setValue(date);
            props.onChange(format(date, 'yyyy-MM-dd'));
        }
    };

    // We should have this fix to close the date picker when the user clicks on the tab key to support the keyboard navigation
    // This is the open react-datepicker issue: https://github.com/Hacker0x01/react-datepicker/issues/2028
    const handlePressTab = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Tab' && datePickerRef.current) {
            datePickerRef.current.setOpen(false);
        }
    };

    const selected = React.useMemo(() => {
        if (value instanceof Date) {
            return value;
        }

        if (isValidDateString(value)) {
            return parseDateInput(value);
        }

        return undefined;
    }, [value, defaultDateFormat]);

    const valueToRender = React.useMemo(() => {
        if (value instanceof Date) {
            return undefined;
        }

        // Most of the date should be at least 10 characters long to be a valid date
        // If we have less than 10 characters, it will be transformed to a new Date which makes immposible to type a 4 digits year
        if (typeof value === 'string' && value.length > 9 && isValidDateString(value)) {
            return format(parseDateInput(value)!, defaultDateFormat);
        }
        return value;
    }, [value, defaultDateFormat]);

    return (
        <DateSelectorWrapper
            tabIndex={0}
            id="dateSelectorWrapper"
            className={`${props.containerClassName ? props.containerClassName : ''}`}
            data-client-type={AutomationTypes.DATE_PICKER}
        >
            <ReactDatePicker
                {...props.datePickerProps}
                ref={datePickerRef}
                customInput={<CustomInput dataTestId={props.dataClientId} />}
                value={valueToRender}
                shouldCloseOnSelect
                selected={selected}
                disabled={props.disabled}
                placeholderText={defaultDateFormat}
                onChange={handleChange}
                onChangeRaw={handleChangeRaw}
                dateFormat={defaultDateFormat}
                onKeyDown={handlePressTab}
            />
        </DateSelectorWrapper>
    );
};

const DateSelectorWrapper = styled.div`
    position: relative;
`;

const DateSelectorIcon = styled.img`
    position: absolute;
    cursor: pointer;
    z-index: 0;
    top: 7px;
    right: 7px;
`;

const DateSelectorInput = styled.input`
    height: 30px;
    border: solid 1px #cccccc;
    width: 100%;
    background: transparent !important;
    position: relative;
    z-index: 1;
`;

const DateSelectorInputWrapper = styled.div<CustomInputProps>`
    background: ${(props) => (props.disabled ? colors.neutralLight20 : colors.neutralLight40)};
`;

export default DateSelector;
