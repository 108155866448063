import { Column, DisplayField, ViewConfig, ViewSourceMetaData } from '../../../common/interfaces';
import { fromJS } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import { AdminPanelComponentProps } from '../interfaces/AdminPanelComponentProps.interface';
import { AdminPanelOnChange } from '../interfaces/AdminPanelOnChange.interface';
import { makeSelectViewSourceMetaData } from '../Selectors';
import AdminPanelDisplay from './AdminPanelDisplay';

interface Props extends AdminPanelComponentProps {
    viewSourceMetaData: ViewSourceMetaData;
    config: ViewConfig;
}

interface StateProps {
    viewSourceMetaData: ViewSourceMetaData;
}

type AdminPanelDisplayContainerProps = Props & StateProps;

interface State {
    displayAttachments: boolean;
    addAttachments: boolean;
    addAttachmentsDisabled?: boolean;
    displayComments: boolean;
    addComments: boolean;
    addCommentsDisabled?: boolean;
    availableFields: DisplayField[];
    displayedFields: DisplayField[];
}

export class AdminPanelDisplayContainer extends React.Component<AdminPanelDisplayContainerProps> {
    public state: State;

    public constructor(props: AdminPanelDisplayContainerProps) {
        super(props);
        this.state = this.setStateToProps();
    }

    public render(): React.ReactNode {
        return (
            <AdminPanelDisplay
                displayAttachments={this.state.displayAttachments}
                addAttachments={this.state.addAttachments}
                onDisplayAttachmentsChange={this.handleOnDisplayAttachmentsChange}
                onAddAttachmentsChange={this.handleOnAddAttachmentsChange}
                displayComments={this.state.displayComments}
                addComments={this.state.addComments}
                onDisplayCommentsChange={this.handleOnDisplayCommentsChange}
                onAddCommentsChange={this.handleOnAddCommentsChange}
                availableFields={this.state.availableFields}
                displayedFields={this.state.displayedFields}
                onSelectedFieldsChange={this.handleOnSelectedFieldsChange}
                onDisplayedFieldsChange={this.handleDisplayedFieldsChange}
            />
        );
    }
    public componentDidUpdate(prevProps: Props): void {
        if ((this.props.cancelChanges && this.props.cancelChanges !== prevProps.cancelChanges) || this.props.viewId !== prevProps.viewId) {
            this.setState(this.setStateToProps());
        }
    }

    public handleOnDisplayAttachmentsChange = (): void => {
        const displayAttachments = !this.state.displayAttachments;
        const addAttachments = displayAttachments ? this.state.addAttachments : false;

        this.setState({ displayAttachments, addAttachments }, this.onContainerChange);
    };

    public handleOnAddAttachmentsChange = (): void => {
        const addAttachments = !this.state.addAttachments;
        const displayAttachments = addAttachments ? true : this.state.displayAttachments;

        this.setState({ addAttachments, displayAttachments }, this.onContainerChange);
    };

    public handleOnDisplayCommentsChange = (): void => {
        const displayComments = !this.state.displayComments;
        const addComments = displayComments ? this.state.addComments : false;

        this.setState({ displayComments, addComments }, this.onContainerChange);
    };

    public handleOnAddCommentsChange = (): void => {
        const addComments = !this.state.addComments;
        const displayComments = addComments ? true : this.state.displayComments;

        this.setState({ addComments, displayComments }, this.onContainerChange);
    };

    public handleOnSelectedFieldsChange = (e: React.FormEvent<HTMLInputElement>): void => {
        const targetId = (e.target as HTMLDivElement).id;
        const checked = (e.target as HTMLInputElement).checked;
        const availableField = this.state.availableFields.find((displayField: DisplayField) => displayField.columnId.toString() === targetId);
        const displayedFields = this.state.displayedFields;

        if (checked) {
            if (availableField) {
                displayedFields.push(availableField);
            }
        } else {
            const index = displayedFields.findIndex((displayField: DisplayField) => displayField.columnId.toString() === targetId);
            displayedFields.splice(index, 1);
        }
        const availableFields = this.populateViewSourceAvailableFields(displayedFields);

        let i = 0;
        displayedFields.forEach((displayedField: DisplayField) => (displayedField.ordinal = i++));

        this.setState(
            {
                availableFields,
                displayedFields,
            },
            this.onContainerChange
        );
    };

    public handleDisplayedFieldsChange = (displayFields: DisplayField[]): void => {
        this.setState({ displayedFields: displayFields }, this.onContainerChange);
    };

    private columnsToDisplayFields = (columns: Column[]): DisplayField[] => {
        return columns.map((column: Column, index: number) => ({
            ordinal: index,
            title: column.title,
            columnId: column.id,
            type: column.type,
            required: false,
            readOnly: false,
            hidden: false,
        }));
    };

    private onContainerChange = (): void => {
        const value: AdminPanelOnChange = {
            isValid: true,
            data: fromJS({
                config: {
                    displayAttachments: this.state.displayAttachments,
                    canAddAttachments: this.state.addAttachments,
                    displayComments: this.state.displayComments,
                    canAddComments: this.state.addComments,
                    displayedFields: this.state.displayedFields,
                },
            }),
        };

        this.props.onChange(value);
    };

    private populateViewSourceAvailableFields = (displayedFields: DisplayField[] | undefined): DisplayField[] => {
        return this.props.viewSourceMetaData.columns.map((column: Column, index: number) => {
            return {
                title: column.title,
                type: column.type,
                columnId: column.id,
                checked: this.columnIsInIdMapping(column, displayedFields),
                ordinal: index,
                required: false,
                readOnly: false,
                hidden: false,
            };
        });
    };

    private populateViewSourceDisplayedFields = (): DisplayField[] => {
        if (!this.props.config.displayedFields) {
            return [];
        }
        const displayedColumns: DisplayField[] = [];
        const metaDataColumnsAsDisplayFields = this.columnsToDisplayFields(this.props.viewSourceMetaData.columns);
        this.props.config.displayedFields!.forEach((field: DisplayField) => {
            const displayedColumn = metaDataColumnsAsDisplayFields.find((displayfield: DisplayField) => {
                return displayfield.columnId === field.columnId;
            });
            if (displayedColumn) {
                displayedColumns.push(displayedColumn);
            }
        });

        return displayedColumns;
    };

    private columnIsInIdMapping = (column: Column, displayedFields: DisplayField[] | undefined): boolean => {
        if (!displayedFields) {
            return false;
        }

        const result = displayedFields!.find((displayField: DisplayField) => {
            return displayField.columnId === column.id;
        });

        return result !== undefined;
    };

    private setStateToProps = (): State => {
        return {
            displayAttachments: this.props.config.displayAttachments!,
            addAttachments: this.props.config.canAddAttachments!,
            displayComments: this.props.config.displayComments!,
            addComments: this.props.config.canAddComments!,
            availableFields: this.populateViewSourceAvailableFields(this.props.config.displayedFields),
            displayedFields: this.populateViewSourceDisplayedFields(),
        };
    };
}

const mapState = createStructuredSelector({
    viewSourceMetaData: createSelector(makeSelectViewSourceMetaData(), (stateViewSourceMetaData) => stateViewSourceMetaData!),
});

export default connect<StateProps>(mapState)(AdminPanelDisplayContainer);
