import { Column, FormFieldInterface, IPaginatedResult, SmartsheetUser } from '../../../common/interfaces';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { AutomationIds } from '../../../common/enums/AutomationElements.enum';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';
import { isDisabledContactPickerOnDemandSelector } from '../../App/Selectors';
import FieldSettingsContainer from '../FieldSettings';

interface Props {
    viewId: string;
    field?: FormFieldInterface;
    onFieldChange: (field: FormFieldInterface) => void;
    selectedColumn?: Column;
    smartsheetUsers: IPaginatedResult<SmartsheetUser>;
    smartsheetUsersByEmails: IPaginatedResult<SmartsheetUser>;
}

const FieldPropertiesContainer: React.SFC<Props> = (props) => {
    let fieldTitle;
    if (props.field) {
        fieldTitle = props.field!.title;
        if (props.field.customLabel) {
            fieldTitle = `${props.field.customLabel} (${props.field.title || ''})`;
        }
    }

    const languageElements = useLanguageElements();
    const isDisabledContactPickerOnDemand = useSelector(isDisabledContactPickerOnDemandSelector);
    return (
        <div className="field-properties-container">
            <span className="container-header" data-client-id={AutomationIds.FIELD_PROPERTIES_CONTAINER_HEADER_SPAN}>
                {languageElements.FIELD_PROPERTIES_HEADER}
                <span className="muted" data-client-id={AutomationIds.FIELD_PROPERTIES_CONTAINER_MUTED_SPAN}>
                    {props.field ? fieldTitle : languageElements.FIELD_PROPERTIES_SUB_HEADER}
                </span>
            </span>
            <p className="container-sub-header" style={props.field ? { display: 'none' } : {}}>
                {languageElements.FIELD_PROPERTIES_SUB_HEADER_ALT}
            </p>
            {props.selectedColumn && (
                <FieldSettingsContainer
                    field={props.field!}
                    onFieldChange={props.onFieldChange}
                    selectedColumn={props.selectedColumn}
                    smartsheetUsers={props.smartsheetUsers}
                    smartsheetUsersByEmails={props.smartsheetUsersByEmails}
                    dataClientId={AutomationIds.FIELD_SETTINGS_CONTAINER}
                    gsGetColumnsFeature={false}
                    isDisabledContactPickerOnDemand={isDisabledContactPickerOnDemand}
                />
            )}
        </div>
    );
};

export default FieldPropertiesContainer;
