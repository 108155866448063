import { SmartsheetUser } from '../../../../common/interfaces';
import classNames from 'classnames';
import * as React from 'react';
import { AutomationIds } from '../../../../common/enums/AutomationElements.enum';
import { getUserInitialCssClass } from '../../../../common/utils/GetUserInitialCssClass';
import { getUserInitials } from '../../../../common/utils/GetUserInitials';
import './Comment.css';
import { UserProfileImageId } from './Comment.interface';

export interface UserIconProps {
    user: SmartsheetUser;
    userProfileImageIds: UserProfileImageId;
}

export class UserIcon extends React.Component<UserIconProps> {

    public constructor(props: UserIconProps) {
        super(props);
    }

    public render = () => {
        const name: string = this.props.user.name || this.props.user.email;
        const initials = getUserInitials(name);
        const userInitialsCssClass = getUserInitialCssClass(initials);
        const profileImageId = this.props.userProfileImageIds[this.props.user.email];
        return (
            <div
                data-client-id={AutomationIds.COMMENT_COMMENTS_ICON}
                className={classNames('icon-user', 'initials', { [userInitialsCssClass]: !profileImageId })}
            >
                {profileImageId ? <img src={profileImageId} />: initials}
            </div>
        );
    }
}