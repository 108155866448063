export const addPropIfDefined = <TExistingObject extends Record<string, unknown>>(
    existingObject: TExistingObject | undefined,
    propName: keyof TExistingObject,
    defaultEmptyValue?: number | string | boolean | null
): Record<string, unknown> | undefined => {
    if (!existingObject || existingObject[propName] === undefined) {
        return undefined;
    }

    const propValue = existingObject[propName] !== null ? existingObject[propName] : defaultEmptyValue;

    return { [propName]: propValue };
};
