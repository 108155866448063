import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import hamburgerImg from '../../assets/images/toolbar/hamburger-menu.svg';
import './HamburgerMenu.css';
import { Actions as ActionsApp } from '../../containers/App/Actions';
import { leftSidePanelOpenedSelector } from '../../containers/App/Selectors';
import { useLanguageElements } from '../../language-elements/withLanguageElementsHOC';

export const ControlId = {
    MENU: 'tbm-1',
};

const HamburgerMenu = () => {
    const languageElements = useLanguageElements();
    const leftSidePanelOpened = useSelector(leftSidePanelOpenedSelector);
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(ActionsApp.toggleLeftSidePanel());
    };

    const description = leftSidePanelOpened ? languageElements.LEFT_PANEL_CLOSE : languageElements.LEFT_PANEL_OPEN;
    return (
        <div className="hamburger-menu" data-client-id={ControlId.MENU} onClick={handleClick}>
            <img src={hamburgerImg} alt={description} />
            <div className="tooltip">
                <div>{description}</div>
            </div>
        </div>
    );
};

export default HamburgerMenu;
