import { AxiosResponse } from 'axios';
import { fromJS, getIn, is, List, Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { Prompt, Route, RouteComponentProps, withRouter } from 'react-router';
import { Action } from 'redux';
import { createStructuredSelector } from 'reselect';
import alertIcon from '../../assets/images/alert/icon-info-circle-bluebigger.svg';
import { VIEW_NAME_MAX_LENGTH } from '../../common/constants';
import { OwnershipTransferStatus } from '../../common/enums';
import { DetailsPanelTabType } from '../../common/enums/DetailsPanelTabType.enum';
import { UserActions } from '../../common/enums/UserActions.enum';
import { ViewStatus } from '../../common/enums/ViewStatus';
import { FormInterface, IOwnershipTransfer, View, ViewConfig, ViewShareBasic, ViewWithOwnerAndUserDetails } from '../../common/interfaces';
import { UserAnalyticsAction } from '../../common/metrics/UserAnalyticsAction';
import withSetAppActionInProgress, { WithSetAppActionInProgressProps } from '../../components/hoc/WithSetAppActionInProgress';
import withSetAppError, { WithSetAppErrorProps } from '../../components/hoc/WithSetAppError';
import ModalWrapper from '../../components/Modal';
import GenericModalContent from '../../components/Modal/Content/GenericModalContent';
import * as AppActions from '../../containers/App/Actions';
import { default as viewClient, default as ViewClient } from '../../http-clients/View.client';
import { LanguageElementsProp, withLanguageElementsHOC } from '../../language-elements/withLanguageElementsHOC';
import { ActionByType } from '../../store';
import { leftSidePanelOpenedSelector } from '../App/Selectors';
import { userEmailSelector } from '../Auth/Selectors';
import FormContainer from '../Form/index';
import * as HomeActions from '../Home/Actions';
import { pendingTransfersSelector } from '../OwnershipTransfer/Selectors';
import * as AdminActions from './Actions';
import AdminFooter from './AdminFooter';
import AdminHeader from './AdminHeader';
import AdminPanelBasic from './Basic/AdminPanelBasic';
import AdminPanelDisplayContainer from './Display';
import { AdminPanelOnChange, AdminPanelOnChangeData, AdminPanelPermissionOnChange } from './interfaces/AdminPanelOnChange.interface';
import { AdminPanelTab, AdminPanelTabs } from './interfaces/AdminPanelTabs.interface';
import AdminPanelPermissionsContainer from './Permissions';
import * as selectors from './Selectors';

interface OwnProps {
    viewId: string;
    page: string;
}

interface StateProps {
    view?: ViewWithOwnerAndUserDetails;
    config?: ViewConfig;
    shares?: ViewShareBasic[];
    form?: FormInterface;
    hasAllData: boolean;
    leftSidePanelOpened: boolean;
    pendingTransfers: List<IOwnershipTransfer>;
    userEmail: string;
}

interface DispatchProps {
    fetchHomeData: () => ActionByType<HomeActions.Actions, HomeActions.ActionTypes.FETCH_HOME_DATA>;
    removeView: (viewId: string) => ActionByType<HomeActions.Actions, HomeActions.ActionTypes.REMOVE_VIEW>;
    fetchAllAdminData: (
        viewId: string,
        includeConfig: boolean,
        includeShares: boolean,
        includeForm: boolean,
        includeFilters: boolean
    ) => AdminActions.Actions;
    updateAdminViewData: (viewId: string, view: View, redirectUrl?: string) => AdminActions.Actions;
    updateShares: (viewId: string, viewShares: ViewShareBasic[], redirectUrl?: string, email?: string) => AdminActions.Actions;
    resetAdminViewData: () => AdminActions.Actions;
    resetViewConfig: () => AdminActions.Actions;
    resetShares: () => AdminActions.Actions;
    resetViewSourceMetaData: () => AdminActions.Actions;
    resetSmartsheetGroups: () => AdminActions.Actions;
    resetSmartsheetUsers: () => AdminActions.Actions;
    resetSmartsheetAdmins: () => AdminActions.Actions;
    resetForm: () => AdminActions.Actions;
    storeRecentView: (viewId: string) => Action<HomeActions.ActionTypes.STORE_RECENT_VIEW>;
    toggleLeftSidePanel: () => AppActions.Actions;
}

type Props = StateProps & DispatchProps & RouteComponentProps<OwnProps> & WithSetAppErrorProps & WithSetAppActionInProgressProps;

interface ActiveTabState {
    route: string;
    data?: AdminPanelOnChangeData;
    isDirty: boolean;
    isValid: boolean;
    cancelChanges: boolean;
}

interface State {
    activeTab: ActiveTabState;
    showAdminPanelContainerModal: AdminPanelContainerModalType;
    newRoute?: string;
    pendingTransfer?: IOwnershipTransfer;
    copiedViewId: string | undefined;
}

export enum AdminPanelContainerModalType {
    NONE,
    SHOW_SAVE,
    CREATE_VIEW_FAILS,
    CREATE_SETTINGS_FAILS,
    SAVE_AS_NEW_SUCCESS,
}

/**
 * Responsible for loading view data, and rendering 1+ child components to display the view data.
 * Also responsible for saving/updating a given views data back to the Db
 */

export class AdminPanelContainer extends React.PureComponent<Props & LanguageElementsProp> {
    public state: State;
    private viewId: string;
    private readonly adminPanelTabs: AdminPanelTabs = {
        // TODO: change tab keys/route to reflect new titles
        basic: {
            title: this.props.languageElements.ADMIN_PANEL_BASIC_TAB_TITLE,
            route: this.props.languageElements.ADMIN_PANEL_BASIC_TAB_KEY,
            tabIndex: 1,
        },
        display: {
            title: this.props.languageElements.ADMIN_PANEL_DISPLAY_TAB_TITLE,
            route: this.props.languageElements.ADMIN_PANEL_DISPLAY_TAB_KEY,
            tabIndex: 2,
        },
        form: {
            title: this.props.languageElements.ADMIN_PANEL_FORM_TAB_TITLE,
            route: this.props.languageElements.ADMIN_PANEL_FORM_TAB_KEY,
            tabIndex: 3,
        },
        share: {
            title: this.props.languageElements.ADMIN_PANEL_PERMISSIONS_TAB_TITLE,
            route: this.props.languageElements.ADMIN_PANEL_PERMISSIONS_TAB_KEY,
            tabIndex: 4,
        },
    };

    public constructor(props: Props & LanguageElementsProp) {
        // TODO: props.view.id is out of sync with this.viewId when user switches from one view to another. See bug DV-0420 for more info.
        super(props);
        this.viewId = props.match.params.viewId;
        this.state = this.getInitialState();
    }

    public render(): React.ReactNode {
        return (
            <div className="admin-panel-container">
                {this.props.hasAllData && (
                    <div>
                        <AdminHeader
                            viewId={this.props.match.params.viewId}
                            viewName={this.props.view!.name}
                            activeSub={this.state.activeTab.route}
                            adminPanelTabs={this.adminPanelTabs}
                            onDeleteClick={this.handleOnDelete}
                            onSaveAsNewClick={this.handleSaveAsNew}
                            onClickNavLink={this.handleOnClickNavLink}
                            onKeyDownNavLink={this.handleOnKeyDownNavLink}
                            userAccessLevel={
                                this.props.view && this.props.view.currentUserDetails ? this.props.view.currentUserDetails.accessLevel : undefined
                            }
                        />
                        <div className={'admin-panel-content'}>
                            <Route
                                path={this.generateRoutePath(this.adminPanelTabs.basic)}
                                render={() => (
                                    <AdminPanelBasic
                                        viewId={this.viewId}
                                        view={this.props.view!}
                                        config={this.props.config!}
                                        cancelChanges={this.state.activeTab.cancelChanges}
                                        onChange={this.handleOnActiveTabChange}
                                    />
                                )}
                            />
                            <Route
                                path={this.generateRoutePath(this.adminPanelTabs.display)}
                                render={() => (
                                    <AdminPanelDisplayContainer
                                        viewId={this.viewId}
                                        cancelChanges={this.state.activeTab.cancelChanges}
                                        config={this.props.config!}
                                        onChange={(value: AdminPanelOnChange) => this.handleOnActiveTabChange(value)}
                                    />
                                )}
                            />
                            <Route
                                path={this.generateRoutePath(this.adminPanelTabs.form)}
                                render={() => (
                                    <FormContainer
                                        viewId={this.viewId}
                                        isDirty={this.state.activeTab.isDirty}
                                        form={this.props.form!}
                                        config={this.props.config!}
                                        cancelChanges={this.state.activeTab.cancelChanges}
                                        onChange={(value: AdminPanelOnChange) => this.handleOnActiveTabChange(value)}
                                    />
                                )}
                            />
                            <Route
                                path={this.generateRoutePath(this.adminPanelTabs.share)}
                                render={() => (
                                    <AdminPanelPermissionsContainer
                                        viewId={this.viewId}
                                        cancelChanges={this.state.activeTab.cancelChanges}
                                        view={this.props.view!}
                                        viewShares={this.props.shares}
                                        config={this.props.config!}
                                        onChange={(value: AdminPanelOnChange) => this.handleOnActiveTabChange(value)}
                                        currentUser={this.props.userEmail}
                                        userInOwnerOrg={this.props.view ? this.props.view.currentUserDetails.inOwnersOrg : false}
                                        pendingTransfer={this.state.pendingTransfer}
                                    />
                                )}
                            />
                        </div>
                        <AdminFooter
                            {...this.props}
                            isDirty={this.state.activeTab.isDirty}
                            isValid={this.state.activeTab.isValid}
                            onCancel={() => this.handleOnActiveTabCancel()}
                            onSave={() => this.handleOnActiveTabSave()}
                        />
                        {this.state.showAdminPanelContainerModal === AdminPanelContainerModalType.SHOW_SAVE && this.state.activeTab.isValid && (
                            <ModalWrapper isModalOpen={true} onClose={this.handleOnSaveModalClose}>
                                <GenericModalContent
                                    title={this.props.languageElements.ADMIN_PANEL_MODAL_UNSAVED_CHANGES_TITLE}
                                    message={this.props.languageElements.ADMIN_PANEL_MODAL_UNSAVED_CHANGES_MESSAGE}
                                    primaryButtonText={this.props.languageElements.ADMIN_PANEL_MODAL_UNSAVED_CHANGES_PRIMARY_BUTTON_TEXT}
                                    secondaryButtonText={this.props.languageElements.ADMIN_PANEL_MODAL_UNSAVED_CHANGES_SECONDARY_BUTTON_TEXT}
                                    onClickPrimaryButton={() => this.handleOnActiveTabSave()}
                                    onClickSecondaryButton={() => this.handleOnActiveTabCancel()}
                                    icon={alertIcon}
                                />
                            </ModalWrapper>
                        )}
                        {this.state.showAdminPanelContainerModal === AdminPanelContainerModalType.SHOW_SAVE && !this.state.activeTab.isValid && (
                            <ModalWrapper isModalOpen={true} onClose={this.handleOnSaveModalClose}>
                                <GenericModalContent
                                    title={this.props.languageElements.ADMIN_PANEL_MODAL_UNSAVED_INVALID_CHANGES_TITLE}
                                    message={this.props.languageElements.ADMIN_PANEL_MODAL_UNSAVED_INVALID_CHANGES_MESSAGE}
                                    primaryButtonText={this.props.languageElements.BUTTON_TEXT_OK}
                                    secondaryButtonText={this.props.languageElements.BUTTON_TEXT_CANCEL}
                                    onClickPrimaryButton={() => this.handleOnActiveTabCancel()}
                                    onClickSecondaryButton={() => this.setState({ showAdminPanelContainerModal: AdminPanelContainerModalType.NONE })}
                                    icon={alertIcon}
                                />
                            </ModalWrapper>
                        )}
                        {this.state.showAdminPanelContainerModal === AdminPanelContainerModalType.CREATE_VIEW_FAILS && (
                            <ModalWrapper isModalOpen={true} onClose={this.handleOnSaveModalClose}>
                                <GenericModalContent
                                    title={this.props.languageElements.ADMIN_PANEL_MODAL_CREATE_VIEW_FAILS_TITLE}
                                    message={this.props.languageElements.ADMIN_PANEL_MODAL_CREATE_VIEW_FAILS_MESSAGE}
                                    primaryButtonText={this.props.languageElements.BUTTON_TEXT_OK}
                                    onClickPrimaryButton={() => this.setState({ showAdminPanelContainerModal: AdminPanelContainerModalType.NONE })}
                                    onClickSecondaryButton={() => {}}
                                    icon={alertIcon}
                                    hideSecondaryButton={true}
                                />
                            </ModalWrapper>
                        )}
                        {this.state.showAdminPanelContainerModal === AdminPanelContainerModalType.CREATE_SETTINGS_FAILS && (
                            <ModalWrapper isModalOpen={true} onClose={this.handleOnSaveModalClose}>
                                <GenericModalContent
                                    title={this.props.languageElements.ADMIN_PANEL_MODAL_CREATE_SETTINGS_FAILS_TITLE}
                                    message={this.getSaveAsNewMessage(
                                        this.props.languageElements.ADMIN_PANEL_MODAL_CREATE_SETTINGS_FAILS_MESSAGE,
                                        this.props.languageElements.ADMIN_PANEL_VIEW_NAME_PREFIX_INCOMPLETE
                                    )}
                                    primaryButtonText={this.props.languageElements.BUTTON_TEXT_OK}
                                    onClickPrimaryButton={() => this.setState({ showAdminPanelContainerModal: AdminPanelContainerModalType.NONE })}
                                    onClickSecondaryButton={() => {}}
                                    icon={alertIcon}
                                    hideSecondaryButton={true}
                                />
                            </ModalWrapper>
                        )}
                        {this.state.showAdminPanelContainerModal === AdminPanelContainerModalType.SAVE_AS_NEW_SUCCESS && (
                            <ModalWrapper isModalOpen={true} onClose={this.openCopiedView}>
                                <GenericModalContent
                                    title=""
                                    message={this.getSaveAsNewMessage(
                                        this.props.languageElements.ADMIN_PANEL_MODAL_CREATE_SUCCESS_MESSAGE,
                                        this.props.languageElements.ADMIN_PANEL_VIEW_NAME_PREFIX_COMPLETED
                                    )}
                                    primaryButtonText={this.props.languageElements.BUTTON_TEXT_OK}
                                    onClickPrimaryButton={this.openCopiedView}
                                    onClickSecondaryButton={() => {}}
                                    icon={alertIcon}
                                    hideSecondaryButton={true}
                                />
                            </ModalWrapper>
                        )}
                        <Prompt
                            message={this.props.languageElements.ADMIN_PANEL_PROMPT_UNSAVED_CHANGES_MESSAGE}
                            when={!this.state.showAdminPanelContainerModal && this.state.activeTab.isDirty}
                        />
                    </div>
                )}
            </div>
        );
    }

    public componentDidMount(): void {
        this.fetchData();
    }

    public componentDidUpdate(prevProps: Props): void {
        // Setup for new view
        if (this.props.match.params.viewId !== prevProps.match.params.viewId) {
            this.viewId = this.props.match.params.viewId;
            this.setState(this.getInitialState());
            this.fetchData();
            return;
        }

        // Setup for tab
        if (this.props.match.params.page !== prevProps.match.params.page) {
            this.setState({ activeTab: this.getNewActiveTab(this.props.match.params.page) });
            return;
        }

        // Update the state when the Ownership Transfer is created
        if (this.props.pendingTransfers !== prevProps.pendingTransfers) {
            this.setState({ pendingTransfer: this.getPendingTransfer() });
            return;
        }
    }

    /**
     * Use existing api calls to implement copying a view. This is adapted from HTS. At some point we may want to add a new api
     * call for this purpose & simplify this code.
     */
    public handleSaveAsNew = async (event: React.MouseEvent): Promise<void> => {
        const { id, name, viewSourceType, viewSourceId, description, filters } = this.props.view!;
        const config = this.props.config!;
        const form = this.props.form!;
        let response: AxiosResponse;
        const incompletePrefix = this.props.languageElements.ADMIN_PANEL_VIEW_NAME_PREFIX_INCOMPLETE;
        const completedPrefix = this.props.languageElements.ADMIN_PANEL_VIEW_NAME_PREFIX_COMPLETED;
        let showAdminPanelContainerModal: AdminPanelContainerModalType;

        this.props.onSetAppStageActionInProgress(this.props.languageElements.SPINNER_SAVING_AS_NEW_LABEL);

        // Submit user analytics event
        UserAnalyticsAction.addFromEvent(
            event,
            {
                id,
                viewSourceType,
                viewSourceId,
            },
            UserActions.CLICK_SAVE_AS_NEW
        );

        // Copy the view
        try {
            const tempNameOfCopy = this.getNewViewName(incompletePrefix(name!));
            response = await viewClient.create({ name: tempNameOfCopy, viewSourceType, viewSourceId, description });
        } catch (error) {
            this.props.onResetAppStage();
            showAdminPanelContainerModal = AdminPanelContainerModalType.CREATE_VIEW_FAILS;
            this.setState({ showAdminPanelContainerModal });
            return;
        }
        const viewWithOwnerAndUserDetailsAxiosResponse = response.data;

        // Update the admin properties for the copy
        const copiedViewId = viewWithOwnerAndUserDetailsAxiosResponse.id!;
        const updatedView = viewWithOwnerAndUserDetailsAxiosResponse;

        // Add config settings to updatedView
        updatedView.form = form;
        updatedView.config = config;
        try {
            await viewClient.update(copiedViewId, updatedView);
        } catch (error) {
            this.props.onResetAppStage();
            showAdminPanelContainerModal = AdminPanelContainerModalType.CREATE_SETTINGS_FAILS;
            this.setState({ showAdminPanelContainerModal });
            return;
        }

        // Copy filters from the original view to the new view.
        if (filters) {
            try {
                await Promise.all(filters.map((filter) => viewClient.addFilter(copiedViewId, filter)));
            } catch (error) {
                this.props.onResetAppStage();
                showAdminPanelContainerModal = AdminPanelContainerModalType.CREATE_SETTINGS_FAILS;
                this.setState({ showAdminPanelContainerModal });
                return;
            }
        }

        // Update name of view from 'Incomplete Copy of ...' to 'Copy of ...'
        const finalNameOfCopy = this.getNewViewName(completedPrefix(name!));
        try {
            await viewClient.update(copiedViewId, { ...updatedView, name: finalNameOfCopy });
        } catch (error) {
            this.props.onResetAppStage();
            showAdminPanelContainerModal = AdminPanelContainerModalType.CREATE_SETTINGS_FAILS;
            this.setState({ showAdminPanelContainerModal });
            return;
        }

        // Close spinner
        this.props.onResetAppStage();

        // Update redux store with new view
        this.props.fetchHomeData();

        showAdminPanelContainerModal = AdminPanelContainerModalType.SAVE_AS_NEW_SUCCESS;
        this.setState({ showAdminPanelContainerModal, copiedViewId });
    };

    public handleOnDelete = async (): Promise<void> => {
        this.props.onSetAppStageActionInProgress(this.props.languageElements.SPINNER_DELETE_VIEW_LABEL);

        try {
            await ViewClient.updateViewStatus([`${this.viewId}`], ViewStatus.INACTIVE);

            this.resetData();

            //  remove it from the redux store.
            this.props.removeView(this.viewId);

            if (!this.props.leftSidePanelOpened) {
                this.props.toggleLeftSidePanel();
            }
            this.props.history.push(`/`);
            this.props.onResetAppStage();
        } catch (error) {
            this.props.onSetAppStageError(error);
        }
    };

    public handleOnSaveModalClose = (): void => {
        this.setState({
            showAdminPanelContainerModal: AdminPanelContainerModalType.NONE,
            newRoute: undefined,
        });
    };

    /**
     * checks for if the specific key pressed is the enter key, or the space bar. If so,
     * act as if the tab had been clicked and update the route to render.
     */
    public handleOnKeyDownNavLink = (e: any, route: string): void => {
        if (e.key && (e.key === 'Enter' || e.key === ' ')) {
            this.checkAndNavigateRoute(route);
        }
    };

    public handleOnClickNavLink = (route: string): void => {
        this.checkAndNavigateRoute(route);
    };

    public handleOnActiveTabChange = (value: AdminPanelOnChange): void => {
        const route: string = this.state.activeTab.route;
        const isTabDataDirty = this.isTabDataDirty(route, value.data);
        const activeTab = this.getNewActiveTab(route, value.data, isTabDataDirty, value.isValid);
        this.setState({ activeTab });
    };

    /**
     * Reset active page data to original route data
     */
    public handleOnActiveTabCancel = (): void => {
        if (this.state.newRoute) {
            this.navigateRoute(this.state.newRoute);
        } else {
            const activeTab: ActiveTabState = this.getNewActiveTab(this.state.activeTab.route, undefined, false, true, true);
            this.setState({
                activeTab,
                newRoute: undefined,
                showAdminPanelContainerModal: AdminPanelContainerModalType.NONE,
            });
        }
    };

    /**
     * Save data for appropriate route and update original route data with updated data
     */
    public handleOnActiveTabSave = () => {
        if (!this.state.activeTab.isDirty) {
            return;
        }

        const redirect: string | undefined = this.state.newRoute ? this.generateActualRoutePath(this.state.newRoute) : undefined;

        switch (this.state.activeTab.route) {
            case this.adminPanelTabs.share.route:
                const viewSharesWithNewOwner: AdminPanelPermissionOnChange = this.state.activeTab.data! as AdminPanelPermissionOnChange;
                const shares: ViewShareBasic[] = viewSharesWithNewOwner.shares.toJS();
                const newOwnerEmail = viewSharesWithNewOwner.newOwner ? viewSharesWithNewOwner.newOwner.email : undefined;
                this.props.updateShares(this.viewId, shares, redirect, newOwnerEmail);
                break;

            case this.adminPanelTabs.basic.route:
                const basicData: any = (this.state.activeTab.data! as Map<string, any>).toJS();
                this.props.updateAdminViewData(this.viewId, basicData, redirect);
                break;
            case this.adminPanelTabs.display.route:
                // Check if the initial tab selected in DetailsPanel settings is invalid based on whether
                // displayAttachments and displayComments option is disabled in ViewDisplay settings.
                // If invalid, default the detailsPanelInitialTab value in Redux store to Data tab
                const displayData: any = (this.state.activeTab.data! as Map<string, any>).toJS();
                if (
                    (!displayData.config!.displayAttachments && this.props.config!.detailsPanelInitialTab === DetailsPanelTabType.ATTACHMENTS) ||
                    (!displayData.config!.displayComments && this.props.config!.detailsPanelInitialTab === DetailsPanelTabType.COMMENTS)
                ) {
                    displayData.config!.detailsPanelInitialTab = DetailsPanelTabType.DATA;
                }
                this.props.updateAdminViewData(this.viewId, displayData, redirect);
                break;
            default:
                const data: any = (this.state.activeTab.data! as Map<string, any>).toJS();
                this.props.updateAdminViewData(this.viewId, data, redirect);
        }

        const activeTab: ActiveTabState = this.getNewActiveTab(this.state.newRoute || this.state.activeTab.route);

        this.setState({
            showAdminPanelContainerModal: AdminPanelContainerModalType.NONE,
            activeTab,
            newRoute: undefined,
        });
    };

    private generateRoutePath = (adminPanelTab: AdminPanelTab): string => {
        return `/views/:viewId/admin/${adminPanelTab.route}`;
    };

    private generateActualRoutePath = (route: string): string => {
        return `/views/${this.viewId}/admin/${route}`;
    };

    private checkAndNavigateRoute = (route: string): void => {
        if (this.state.activeTab.isDirty) {
            this.setState({
                showAdminPanelContainerModal: AdminPanelContainerModalType.SHOW_SAVE,
                newRoute: route,
            });
        } else {
            this.navigateRoute(route);
        }
    };

    private navigateRoute = (route: string): void => {
        this.setState(
            {
                activeTab: this.getNewActiveTab(route),
                showAdminPanelContainerModal: AdminPanelContainerModalType.NONE,
                newRoute: undefined,
            },
            (): void => this.props.history.push(this.generateActualRoutePath(route))
        );
    };

    private getNewActiveTab = (
        route: string,
        data: AdminPanelOnChangeData | undefined = undefined,
        isDirty: boolean = false,
        isValid: boolean = true,
        cancelChanges: boolean = false
    ): ActiveTabState => {
        return {
            route,
            data,
            isDirty,
            isValid,
            cancelChanges,
        };
    };

    private isTabDataDirty = (route: string, updatedData: AdminPanelOnChangeData): boolean => {
        let isDirty: boolean = false;
        let updatedMap: Map<string, any>;

        switch (route) {
            case this.adminPanelTabs.basic.route:
                const viewMap = fromJS(this.props.view);
                updatedMap = updatedData as Map<string, any>;
                isDirty =
                    this.isPropDirty(viewMap, 'name', updatedMap.get('name')) ||
                    this.isPropDirty(viewMap, 'description', updatedMap.get('description')) ||
                    this.isMapDirty(fromJS(this.props.config!), updatedMap.get('config'));
                break;
            case this.adminPanelTabs.display.route:
                updatedMap = updatedData as Map<string, any>;
                isDirty = this.isMapDirty(fromJS(this.props.config), updatedMap.get('config'));
                break;
            case this.adminPanelTabs.form.route:
                updatedMap = updatedData as Map<string, any>;
                isDirty = this.isMapDirty(fromJS(this.props.form), updatedMap.get('form'));
                isDirty =
                    isDirty ||
                    this.isPropDirty(
                        fromJS(this.props.config),
                        'detailsPanelDescription',
                        getIn(updatedMap, ['config', 'detailsPanelDescription'], undefined)
                    ) ||
                    this.isPropDirty(
                        fromJS(this.props.config),
                        'detailsPanelInitialTab',
                        getIn(updatedMap, ['config', 'detailsPanelInitialTab'], undefined)
                    );
                break;
            case this.adminPanelTabs.share.route:
                const updatedList = updatedData as AdminPanelPermissionOnChange;
                const isListDirty = this.isListDirty<ViewShareBasic>(fromJS(this.props.shares!), updatedList.shares);
                isDirty = !!(isListDirty || updatedList.newOwner);
                break;
        }

        return isDirty;
    };

    private isMapDirty = (originalData: Map<string, any> | undefined, updatedData: Map<string, any>): boolean => {
        let isDirty = false;

        if (updatedData) {
            updatedData.entrySeq().forEach((entry) => {
                isDirty = this.isPropDirty(originalData, entry![0], entry![1]);
                return !isDirty;
            });
        }

        return isDirty;
    };

    private isListDirty = <T,>(originalData: List<T>, updatedData: List<T>): boolean => {
        return !originalData.equals(updatedData);
    };

    private isPropDirty = (originalData: Map<string, any> | undefined, key: string, updatedProp: any): boolean => {
        let isDirty = false;

        if (originalData && originalData.has(key)) {
            const originalProp = originalData.get(key);
            if (Map.isMap(originalProp)) {
                if (this.isMapDirty(originalProp, updatedProp)) {
                    isDirty = true;
                }
            } else if (!is(originalProp, updatedProp)) {
                isDirty = true;
            }
        } else if (updatedProp != null) {
            isDirty = true;
        }

        return isDirty;
    };

    private getNewViewName = (viewName: string): string => {
        return viewName.substring(0, VIEW_NAME_MAX_LENGTH);
    };

    private getPendingTransfer = (): IOwnershipTransfer | undefined => {
        const pendingTransfers = (this.props.pendingTransfers.toJS() as IOwnershipTransfer[])
            .filter((transfer) => transfer.view.id === this.viewId)
            .filter((transfer) => transfer.status === OwnershipTransferStatus.PENDING);
        return pendingTransfers[0];
    };

    private getInitialState = (): State => {
        return {
            activeTab: this.getNewActiveTab(this.props.match.params.page),
            showAdminPanelContainerModal: AdminPanelContainerModalType.NONE,
            pendingTransfer: this.getPendingTransfer(),
            copiedViewId: undefined,
        };
    };

    private resetData = () => {
        this.props.resetAdminViewData();
        this.props.resetShares();
        this.props.resetSmartsheetAdmins();
        this.props.resetSmartsheetUsers();
        this.props.resetSmartsheetGroups();
        this.props.resetViewConfig();
        this.props.resetViewSourceMetaData();
    };

    private fetchData = () => {
        this.resetData();
        this.props.fetchAllAdminData(this.viewId, true, true, true, true);
    };

    private getSaveAsNewMessage = (formatMessage: (arg0: string) => string, prefixName: (arg0: string) => string) => {
        const newViewName = this.getNewViewName(prefixName(this.props.view!.name!));
        return formatMessage(newViewName);
    };

    private openCopiedView = () => {
        // Redirect the user to the new view.
        this.props.history.push(`/views/${this.state.copiedViewId!}`);
    };
}

const mapState = createStructuredSelector({
    view: selectors.makeSelectView(),
    config: selectors.makeSelectConfig(),
    shares: selectors.makeSelectShares(),
    form: selectors.makeSelectForm(),
    hasAllData: selectors.makeSelectHasAllAdminPanelContainerData(),
    leftSidePanelOpened: leftSidePanelOpenedSelector,
    pendingTransfers: pendingTransfersSelector,
    userEmail: userEmailSelector,
});

const mapDispatch = {
    fetchHomeData: HomeActions.Actions.fetchHomeData,
    removeView: HomeActions.Actions.removeView,
    fetchAllAdminData: AdminActions.Actions.fetchAllAdminData,
    updateAdminViewData: AdminActions.Actions.updateAdminViewData,
    updateShares: AdminActions.Actions.updateShares,
    resetAdminViewData: AdminActions.Actions.storeAdminViewData,
    resetViewConfig: AdminActions.Actions.storeViewConfig,
    resetShares: AdminActions.Actions.storeShares,
    resetViewSourceMetaData: AdminActions.Actions.storeViewSourceMetaData,
    resetSmartsheetGroups: AdminActions.Actions.storeSmartsheetGroups,
    resetSmartsheetUsers: AdminActions.Actions.storeSmartsheetUsers,
    resetSmartsheetAdmins: AdminActions.Actions.storeSmartsheetAdmins,
    resetForm: AdminActions.Actions.storeForm,
    storeRecentView: HomeActions.Actions.storeRecentView,
    toggleLeftSidePanel: AppActions.Actions.toggleLeftSidePanel,
};

export default withLanguageElementsHOC(
    withRouter(
        withSetAppActionInProgress(
            withSetAppError(connect<StateProps, DispatchProps, RouteComponentProps<OwnProps>>(mapState, mapDispatch)(AdminPanelContainer))
        )
    )
);
