import * as React from 'react';
import { CSVLink } from 'react-csv';
import { SyncClickHandler } from 'react-csv/components/CommonPropTypes';
import exportContent from '../../assets/images/download.svg';
import { Button } from '../../components/Buttons';
import { useLanguageElements } from '../../language-elements/withLanguageElementsHOC';

export interface DownloadProps {
    filename?: string;
    csvData: object[];
    onClick: (event: React.MouseEvent) => void;
    isLoadingData: boolean;
    isVisibleLoadingIndicator: boolean;
}

/**
 * This downloads view data to csv format. Note that the onClick event updates state in ViewContainer
 * which passes in updated props.cvsData here BEFORE the CSVLink component executes the download.
 * Using <CSVLink> component instead of <CSVDownload> component from react-csv package because CSVDownload does not support
 * a filename prop (it just downloads data with uuid type names & no extension).
 * More here: https://github.com/react-csv/react-csv/issues/220
 */
const Download: React.FC<DownloadProps> = (props) => {
    const languageElements = useLanguageElements();
    const downloadClassName = `export-data ${props.isVisibleLoadingIndicator && props.isLoadingData ? 'disabled' : ''}`;
    const displayTooltip = !props.isVisibleLoadingIndicator && !props.isLoadingData ? languageElements.VIEW_DOWNLOAD_VIEW_DATA : '';
    return (
        <CSVLink
            // The type definitions for react-csv are not correct.
            // The event argument is accidentally typed as an event handler, rather than as an event.
            onClick={props.onClick as unknown as SyncClickHandler}
            data={props.csvData}
            filename={`${props.filename || languageElements.VIEW_DOWNLOAD_DEFAULT_FILENAME}.csv`}
        >
            <Button icon={exportContent} tooltip={displayTooltip} className={downloadClassName} imageAltText={languageElements.DOWNLOAD_ALT_TEXT} />
        </CSVLink>
    );
};

export default Download;
