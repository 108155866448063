import { Contact, SmartsheetUser } from '../../common/interfaces';
import { ReactFragment } from 'react';
import * as React from 'react';
import { AutomationTypes } from '../../common/enums/AutomationElements.enum';
import { getUserInitialCssClass } from '../../common/utils/GetUserInitialCssClass';
import { getUserInitials } from '../../common/utils/GetUserInitials';
import { WithDataClientProps } from '../hoc/WithDataClient';
import './SingleContact.css';

interface Props extends WithDataClientProps {
    contact: Contact | SmartsheetUser;
    className?: string;
    showNameAndEmail?: boolean;
}

const SingleContact: React.SFC<Props> = (props) => {
    const userIcon = userIconRenderer(props.contact);
    const className = `cell-content ${props.className || ''}`;

    return (
        <div className={className} data-client-type={props.dataClientType}>
            <div
                className="data-cell single-contact"
                title={props.contact.name ? props.contact.name : props.contact.email}
                data-client-id={props.dataClientId}
            >
                <div className="icon-user-container">{userIcon}</div>
                <div className="name-and-email" data-client-type={AutomationTypes.CONTACT_NAME_AND_EMAIL}>
                    {props.showNameAndEmail ? (
                        <>
                            <label className="option-label">{props.contact.name}</label>
                            <label className="option-sub-label">{props.contact.email}</label>
                        </>
                    ) : (
                        <label className="option-label">{props.contact.name ? props.contact.name : props.contact.email}</label>
                    )}
                </div>
            </div>
        </div>
    );
};

/**
 * Renders a circular div with a users initials as the center text
 */
const userIconRenderer = (contact: Contact | SmartsheetUser): ReactFragment => {
    if ('profileImage' in contact && contact.profileImage && contact.profileImage.url) {
        return <img src={contact.profileImage.url} alt="Profile Image" />;
    }

    const userInitials: string = getUserInitials(contact.name || contact.email || '');

    return (
        <div
            data-client-type={AutomationTypes.CONTACT_ICON}
            className={'icon-user initials ' + getUserInitialCssClass(userInitials)}
            title={contact.email}
        >
            {userInitials}
        </div>
    );
};

export default SingleContact;
