/* eslint @typescript-eslint/unbound-method:"off" */
import * as React from 'react';

interface Props {
    close: any;
    children?: any;
}
const ControlId = {
    CLOSE: 'ocl-1',
};

/**
 * OutsideClickListener is a wrapper that executes a method (close) that is passed through
 * props when an area outside of OutsideClickListener's children is clicked.
 */
class OutsideClickListener extends React.Component<Props> {
    private wrapperRef: any;

    public constructor(props: any) {
        super(props);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    public componentDidMount(): void {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    public componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    public render(): React.ReactNode {
        return (
            <div ref={this.setWrapperRef} data-client-id={ControlId.CLOSE}>
                {this.props.children}
            </div>
        );
    }

    private setWrapperRef(node: any): void {
        this.wrapperRef = node;
    }

    private handleClickOutside(event: MouseEvent): void {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.props.close();
        }
    }
}

export default OutsideClickListener;
