import styled from '@emotion/styled';
import { Checkbox } from '@smartsheet/lodestar-core';
import * as React from 'react';
import { AutomationIds } from '../../common/enums/AutomationElements.enum';
import TextNumber from '../TextNumber/TextNumber';

export interface Props {
    inputIndex: number;
    value?: any;
    onChange?: (value: boolean) => void;
    readOnly?: boolean;
    isSettingsMode: boolean;
}

const FormFieldCheckbox = ({ inputIndex, value, readOnly, isSettingsMode, onChange }: Props) => {
    const handleChangeCheckbox = () => {
        if (onChange) {
            onChange(!value);
        }
    };

    if (value != null && typeof value === 'string') {
        if (!isSettingsMode) {
            return <TextNumber key={`tni-${inputIndex}`} dataClientId={AutomationIds.FORM_FIELD_WRAPPER_TEXT} value={value} disabled={true} />;
        }
        value = value.length > 0 && value.toLowerCase() === 'true';
    }

    return (
        <StyledCheckbox
            isDisabled={readOnly}
            key={`cbi-${inputIndex}`}
            checkedState={value ? 'checked' : 'unchecked'}
            label={''}
            onClick={handleChangeCheckbox}
            clientId={AutomationIds.FORM_FIELD_WRAPPER_CHECKBOX}
            inputId={`cbk-key-${inputIndex}`}
            isSettingsMode={isSettingsMode}
        />
    );
};

export default FormFieldCheckbox;

const StyledCheckbox = styled(Checkbox)`
    pointer-events: ${(props) => (props.isSettingsMode ? 'none' : 'auto')};
`;
