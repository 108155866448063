import styled from '@emotion/styled';
import { Chip, colors, SearchInput, sizes, Toast, ToastContent, ToastDismissButton, TypeRampV2 } from '@smartsheet/lodestar-core';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AutomationIds } from '../../common/enums/AutomationElements.enum';
import { HomeFilter } from '../../common/enums/HomeFilter';
import { useLanguageElements } from '../../language-elements/withLanguageElementsHOC';
import { StoreState } from '../../store';
import { Actions } from '../App/Actions';
import { HomeSourceFilter } from '../App/Reducers';
import { homeSourceFilterSelector } from '../App/Selectors';
import { Actions as HomeActions } from '../Home/Actions';
import ViewNewItem from '../LeftPanel/HomePanel/ViewNewItem';
import { showPermissionsErrorSelector } from './Selectors';

interface HomeHeaderProps {
    filter: HomeFilter;
    showSubtitle: boolean;
    onSearchChange: (val: string) => void;
    searchValue: string;
    invalidSearch: boolean;
    userIsLicensed: boolean;
}

const StyledChip = styled(Chip)({
    margin: '2px 10px',
});

const StyledToast = styled(Toast)({
    margin: `${sizes.xxSmall}px ${sizes.small - sizes.xxSmall}px ${sizes.xxSmall}px auto`,
    borderRadius: `${sizes.small}px`,
    boxShadow: `0 ${sizes.xxSmall}px ${sizes.small}px 0 ${colors.shadowMenu}`,
    background: colors.visBlueLight10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

export const HomeHeader: React.FC<HomeHeaderProps> = (props) => {
    const languageElements = useLanguageElements();
    const dispatch = useDispatch();
    const homeSourceFilter = useSelector<StoreState, HomeSourceFilter>(homeSourceFilterSelector);
    const showToast = useSelector<StoreState, boolean>(showPermissionsErrorSelector);
    const sourceType = homeSourceFilter?.isReport ? languageElements.SOURCE_TYPE_REPORT : languageElements.SOURCE_TYPE_SHEET;
    const toastContentMessage =
        sourceType === languageElements.SOURCE_TYPE_SHEET
            ? languageElements.DSC_PERMISSIONS_CREATE_SHEET
            : languageElements.DSC_PERMISSIONS_CREATE_REPORT;
    const closeToast = () => {
        dispatch(HomeActions.showPermissionsError(false));
    };
    return (
        <div className="home-header" data-client-id={AutomationIds.HOME_HEADER}>
            <div className="title-container">
                <TypeRampV2 system="ursa" variant="TT-Norms-Pro--28px--400w">
                    {languageElements.HOME_TITLE[props.filter]}
                </TypeRampV2>

                {props.userIsLicensed && showToast && (
                    <StyledToast type={'info'}>
                        <ToastContent>{toastContentMessage}</ToastContent>
                        <ToastDismissButton aria-label="close" onClick={closeToast} />
                    </StyledToast>
                )}

                {props.userIsLicensed && (
                    <ViewNewItem
                        text={languageElements.CREATE_VIEW_TEXT}
                        controlId={AutomationIds.MAIN_NEW_VIEW}
                        class="btn-primary landing-page-button"
                    />
                )}

                <SearchInput
                    type="text"
                    placeholder={languageElements.HOME_SEARCH_PLACEHOLDER[props.filter]}
                    aria-label={languageElements.HOME_SEARCH_EXPLANATION}
                    role="searchbox"
                    value={props.searchValue}
                    onChange={props.onSearchChange}
                    className="home-search"
                    validationState={props.invalidSearch && 'error'}
                />
            </div>
            {homeSourceFilter ? (
                <StyledChip
                    data-client-id={AutomationIds.HOME_SOURCE_FILTER}
                    onRemove={() => {
                        dispatch(Actions.setHomeSourceFilter(undefined));
                    }}
                >
                    {sourceType} {homeSourceFilter?.name}
                </StyledChip>
            ) : (
                props.showSubtitle && <p className="subtitle">{languageElements.HOME_SUBTITLE[props.filter]}</p>
            )}
        </div>
    );
};

export default HomeHeader;
