import { ContainerTree, ContainerTreeData, TreeItemType, useContainerTree } from '@smartsheet/container-tree';
import { SearchInput } from '@smartsheet/lodestar-core';
import { ViewSourceType } from '../../common/enums';
import { CreateView } from '../../common/interfaces';
import * as debounce from 'debounce-promise';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { workspaceSearchFeatureSelector } from '../../containers/App/Selectors';
import { Actions } from '../../containers/Home';
import viewClient from '../../http-clients/View.client';
import { workspaceClient } from '../../http-clients/Workspace.client';
import { useLanguageElements } from '../../language-elements/withLanguageElementsHOC';
import ModalWrapper from '../Modal';
import Spinner from '../Spinner';
import './CreateViewModal.css';

const ControlID = {
    CREATE: 'nvm-1',
    CANCEL: 'nvm-2',
    NEW_VIEW_MODAL: 'nvm-3',
};

interface Props {
    toggleCreateViewModal: () => void;
    displaySheetPicker: () => void;
    isCreateViewModalOpen: boolean;
    sourceApp?: string;
}

export const CreateViewModal = (props: Props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const languageElements = useLanguageElements();
    const isWorkspaceSearch = useSelector(workspaceSearchFeatureSelector);

    // State used to enable spinner while waiting for data to return
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        setIsLoading(true);
        workspaceClient.getInitialData().then((results) => {
            setIsLoading(false);
            setData(results);
            results.forEach((result) => {
                if (result.childData && result.childData.loadState === 'loaded') {
                    expandedSet.add(result);
                }
            });
        });
    }, []);

    // Retrieves data when a folder in the tree is expanded
    const fetchChildren = (parentContainerData: ContainerTreeData): Promise<ContainerTreeData> => {
        if (parentContainerData.containerType === TreeItemType.FOLDER) {
            if (parentContainerData.isPersonalWorkspace) {
                return workspaceClient.getFolders();
            } else {
                return workspaceClient.getFolder(parentContainerData.id);
            }
        } else {
            return workspaceClient.getWorkspace(parentContainerData.id);
        }
    };

    const { containerTreeProps, data, selectedSet, expandedSet, setData } = useContainerTree({
        data: [],
        fetchChildren,
        parentTypesSelectable: false,
        expandParentTypeOnClick: true,
    });

    const handleCloseModal = () => {
        props.toggleCreateViewModal();
        props.displaySheetPicker();
    };

    // Creates a new dynamic view using the selected sheet/report
    const handleCreateViewClick = (): void => {
        if (selectedSet.size() !== 1) {
            return;
        } else {
            const selected: ContainerTreeData = selectedSet.values().next().value;
            selectedSet.clear();

            const viewType = selected.containerType === TreeItemType.SHEET ? ViewSourceType.SHEET : ViewSourceType.REPORT;

            const newView: CreateView = {
                name: selected.name,
                viewSourceType: viewType,
                viewSourceId: Number(selected.id),
                sourceApp: props.sourceApp,
            };

            viewClient.create(newView).then((response) => {
                const view = response.data;
                dispatch(Actions.fetchHomeData());

                history.push(`/views/${view.id!}/admin/basic`);
            });
        }
    };

    // Performs search within tree. Uses debounce to avoid many duplicate calls. Search limited to 100 characters
    const handleSearchTree = debounce((query: string): void => {
        if (query.length >= 1 && query.length < 100) {
            setIsLoading(true);
            workspaceClient.getSearch(query, isWorkspaceSearch).then((results) => {
                selectedSet.clear();
                setData(results);
                results.forEach((result) => {
                    if (result.childData?.loadState === 'loaded') {
                        expandedSet.add(result);
                    }
                });
                setIsLoading(false);
            });
        } else {
            setIsLoading(true);
            workspaceClient.getInitialData().then((results) => {
                setData(results);
                expandedSet.clear();
                selectedSet.clear();
                results.forEach((result) => {
                    if (result.childData?.loadState === 'loaded') {
                        expandedSet.add(result);
                    }
                });
                setIsLoading(false);
            });
        }
    }, 350);

    return (
        <ModalWrapper isModalOpen={props.isCreateViewModalOpen} onClose={handleCloseModal} classes={'new-view-modal'}>
            <div className={'CreateViewModal'} data-client-id={ControlID.NEW_VIEW_MODAL}>
                <span className="create-new-view-text">{languageElements.CREATE_VIEW_TEXT}</span>
                <span className="create-new-view-basic-text">{languageElements.CREATE_NEW_VIEW_BASIC_TEXT}</span>
                <SearchInput aria-label="Search" placeholder={languageElements.VIEW_TABLE_SEARCH} onChange={handleSearchTree} />
                {isLoading ? (
                    <div className="spinner create-modal-picker">
                        <Spinner label={languageElements.SPINNER_LOADING_LABEL} />
                    </div>
                ) : (
                    <div className="create-modal-picker" data-testid="ContainerTree">
                        <ContainerTree {...containerTreeProps} data={data} />
                    </div>
                )}

                <div className="create-modal-footer">
                    <button
                        tabIndex={0}
                        data-client-id={ControlID.CREATE}
                        disabled={selectedSet.size() !== 1}
                        className="btn btn-primary"
                        onClick={handleCreateViewClick}
                    >
                        {languageElements.MODAL_CREATE_BUTTON_DEFAULT_TEXT}
                    </button>
                    <button tabIndex={0} data-client-id={ControlID.CANCEL} className="btn btn-secondary" onClick={handleCloseModal}>
                        {languageElements.MODAL_CANCEL_BUTTON_DEFAULT_TEXT}
                    </button>
                </div>
            </div>
        </ModalWrapper>
    );
};
