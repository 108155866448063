import styled from '@emotion/styled';
import { Flyout } from '@smartsheet/lodestar-core';

export const StyledTooltip = styled(Flyout)`
    white-space: nowrap;
    margin: 0 auto;
    padding: 0 10px;
    line-height: 26px;
    color: #444444;
    font-size: 13px;
    background-color: #fff !important;
    border-radius: 2px;
    border: 2px solid rgba(0, 94, 224, 0.25);
    outline: 1px solid #005ee0;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    z-index: 11;
`;
