import * as React from 'react';
import alertIcon from '../../../../assets/images/alert/icon-info-circle-bluebigger.svg';
import { ModalBody, ModalFooter, ModalFooterButton, ModalHeader } from '@smartsheet/lodestar-core/';
import { useLanguageElements } from '../../../../language-elements/withLanguageElementsHOC';

interface PropsWithCloseModalFunction {
    onCloseModalDetailsPanel: () => void;
}

const alertImg = <img src={alertIcon} alt="alert" />;

export const InvalidModalContent = (props: PropsWithCloseModalFunction) => {
    const languageElements = useLanguageElements();
    return (
        <>
            <ModalHeader
                title={languageElements.DETAILS_DATA_MODAL_UNSAVED_INVALID_CHANGES_TITLE}
                titleIcon={alertImg}
                onCloseRequested={props.onCloseModalDetailsPanel}
            />
            <ModalBody>
                <p>{languageElements.INVALID_CHANGES_MESSAGE}</p>
            </ModalBody>
            <ModalFooter>
                <ModalFooterButton onClick={props.onCloseModalDetailsPanel}>{languageElements.BUTTON_TEXT_OK}</ModalFooterButton>
            </ModalFooter>
        </>
    );
};
