export enum ErrorType {
    // Smartsheet
    SMARTSHEET_AUTH_ACCOUNT_BLOCKED = 2003,
    SMARTSHEET_OFFLINE = 4001,
    SMARTSHEET_SERVER_TIMEOUT = 4002,
    SMARTSHEET_API_RATE_LIMIT_EXCEEDED = 4003,
    SMARTSHEET_UNEXPECTED_ERROR_OR_LOCKED_TOKEN = 4004,
    SMARTSHEET_REPORT_BLOCKED = 5118,
    SMARTSHEET_ACCESS_TOKEN_INVALID = 1002,
    SMARTSHEET_ACCESS_TOKEN_EXPIRED = 1003,
    SMARTSHEET_OPERATION_NOT_SUPPORTED_BY_PLAN = 1013,

    // User
    USER_NOT_LICENSED = 10001,
    USER_NOT_ADMIN = 10002,
    USER_UNAUTHORIZED_TO_VIEW_ROW = 10003,
    USER_UNAUTHORIZED_TO_DELETE_VIEW = 10004,
    USER_UNAUTHORIZED_TO_VIEW_ATTACHMENTS = 10005,
    USER_UNAUTHORIZED_TO_ADD_ATTACHMENTS = 10006,
    USER_UNAUTHORIZED_TO_ADD_EDIT_DELETE_COMMENTS = 10007,
    USER_JWT_INVALID = 10008,
    USER_NOT_FOUND = 10009,
    USER_NOT_ADMIN_IN_OWNER_ORG = 10010,
    USER_UNAUTHORIZED_TO_ADD_ROW = 10011,
    USER_UNAUTHORIZED_TO_UPDATE_ROW = 10012,
    USER_NOT_OWNER = 10013,
    USER_EMAIL_INVALID = 10014,
    USER_SMAR_TOKEN_INVALID = 10015,
    USER_SESSION_INVALID = 10016,
    USER_TOKEN_ID_MISSING = 10017,
    USER_TOKEN_MISMATCH = 10018,
    MISSING_DV_SESSION = 10019,
    MISSING_CSRF_COOKIE = 10020,
    MISSING_CSRF_HEADER = 10021,
    MISSING_SMAR_FEDERATED_SESSION = 10022,
    S4DV_DECRYPT_VERIFY_FAILED = 10023,
    CSRF_HEADER_COOKIE_MISMATCH = 10024,
    CSRF_TOKEN_INVALID = 10025,
    DV_SMAR_SESSION_MISMATCH = 10026,
    USER_UNAUTHORIZED = 10027,
    USER_DELEGATED_GRANTOR_NOT_OWNER_ADMIN = 10029,
    USER_UNAUTHORIZED_TO_ACCESS_VIEW = 10031,
    USER_ROW_ACCESS_CHECK_ERROR = 10032,
    MISSING_ACTOR_ID = 10033,
    USER_UNAUTHORIZED_TO_UPDATE_VIEW_STATUS = 10034,

    // View
    VIEW_SOURCE_NOT_FOUND = 10101,
    VIEW_SOURCE_NON_OWNER_NOT_FOUND = 10102,
    VIEW_SOURCE_ROW_NOT_FOUND = 10103,
    VIEW_NOT_FOUND = 10104,
    VIEW_ID_INVALID = 10105,
    VIEW_UPDATE_BODY_REQUIRED = 10106,
    VIEW_FORM_INVALID = 10107,
    VIEW_ATTACHMENT_SUB_TYPE_INVALID = 10108,
    VIEW_ITEM_SUBMISSION_NOT_ALLOWED = 10109,
    VIEW_INTAKE_SHEET_ID_INVALID = 10110,
    VIEW_SOURCE_COLUMN_NOT_FOUND = 10111,
    VIEW_FILE_REQUIRED = 10112,
    VIEW_FILE_UPLOAD_NOT_ALLOWED = 10113,
    VIEW_FILE_UPLOAD_SIZE_LIMIT_EXCEEDED = 10114,
    VIEW_FILE_UPLOAD_QTY_LIMIT_EXCEEDED = 10115,
    VIEW_SHEET_FILTER_DOES_NOT_EXIST = 10117,
    VIEW_SHEET_FILTER_DOES_NOT_EXIST_ADMIN = 10118,
    VIEW_SOURCE_LOAD_ERROR = 10119,
    VIEW_COMPARATOR_INVALID = 10120,
    VIEW_INTAKE_SHEET_NOT_FOUND = 10121,
    VIEW_SOURCE_TYPE_NOT_SUPPORTED = 10122,
    /// region Cell Images
    VIEW_CELL_IMAGE_UPLOAD_FAILED_SMAR_API_5XX = 10123,
    VIEW_CELL_IMAGE_UPLOAD_FAILED_SMAR_API_4XX = 10124,
    VIEW_CELL_IMAGE_UPLOAD_FAILED_SMAR_API_UNKNOWN = 10125,
    VIEW_CELL_IMAGE_UPLOAD_RESPONSE_MISSING_ROW = 10126,
    VIEW_CELL_IMAGE_UPLOAD_RESPONSE_MISSING_CELL = 10127,
    VIEW_CELL_IMAGE_UPLOAD_RESPONSE_MISSING_IMAGE = 10128,
    VIEW_CELL_IMAGE_UPLOAD_FAILED_MISSING_FILE = 10129,
    VIEW_CELL_IMAGE_MULTIPART_UPLOAD_FAILED = 10130,
    /// endregion
    VIEW_CONFIG_NOT_FOUND = 10131,
    VIEW_REPORT_ROW_LIMIT_EXCEEDED = 10132,

    // Row Data Resolver
    ROW_DATA_RESOLVER_FIELD_MISSING = 10201,

    //  Filter
    FILTER_NOT_FOUND = 10301,
    FILTER_INVALID = 10302,
    FILTER_SHARING_NOT_ALLOWED = 10303,
    FILTER_DELETE_NOT_ALLOWED = 10304,

    // Favorites, Recents and Pinned
    RECENTS_GET_FAILED = 10400,
    DYNAMODB_INITIALIZATION_FAILED = 10401,
    FAVORITES_GET_FAILED = 10402,
    PINNED_GET_FAILED = 10403,
    DYNAMODB_REQUEST_FAILED = 10404,

    // Misc
    BULLETINS_CONFIG_SETTING_MISCONFIGURED = 10500,
    IMAGE_URL_VALIDATION_FAILED = 10501,
    NUMBER_ARRAY_VALIDATION_FAILED = 10502,
    OPTIONAL_NUMBER_VALIDATION_FAILED = 10503,
    AT_MENTIONS_VIEW_SOURCE_UNSUPPORTED = 10504,
    AT_MENTIONS_ROW_SHEET_ID_UNDEFINED = 10505,

    // Reports
    REPORT_ROW_NOT_FOUND = 10700,

    // Grid Service
    GRID_SERVICE_ENVIRONMENT_UNSUPPORTED = 10800,
    GRID_SERVICE_ERROR = 10801,
    GRID_SERVICE_OFFLINE = 10802,
    GRID_SERVICE_SMARTSHEETID_MISSING = 10803,
    GRID_SERVICE_TRACKING_OPERATION_FAILED = 10804,
    GRID_SERVICE_CONTACT_EMAIL_NOT_FOUND = 10805,
    GRID_SERVICE_UNEXPECTED_CONTACT_VALUE = 10806,

    // General
    CRITICAL_SERVER_ERROR = 10901,
    NOT_FOUND = 10902,
    UNAUTHORIZED = 10903,
    BEARER_TOKEN_INVALID = 10904,
    ROW_CREATION_FAILED = 10905,
    UNKNOWN_ERROR = 10906,
    NOT_SUPPORTED = 10907,
    FORBIDDEN = 10908,

    //  Ownership Transfer
    OWNERSHIP_TRANSFER_NOT_FOUND = 11000,
    OWNERSHIP_TRANSFER_NEW_OWNER_IS_CURRENT_OWNER = 11001,
    OWNERSHIP_TRANSFER_PRIVATE_SHEET_FILTER = 11002,
    OWNERSHIP_TRANSFER_IN_PROGRESS = 11003,
    OWNERSHIP_TRANSFER_NOT_ADMIN_OF_VIEW_SOURCE = 11004,
    OWNERSHIP_TRANSFER_VIEW_ACCESS_NOT_FOUND = 11005,
    OWNERSHIP_TRANSFER_OWNER_ACCOUNT_NOT_FOUND = 11006,
    OWNERSHIP_TRANSFER_NEW_OWNER_ACCOUNT_NOT_FOUND = 11007,
    OWNERSHIP_TRANSFER_INVALID_STATUS = 11008,
    OWNERSHIP_TRANSFER_SMARTSHEET_USER_MISSING_FOR_CURRENT_OWNER = 11009,
    OWNERSHIP_TRANSFER_SMARTSHEET_USER_MISSING_FOR_NEW_OWNER = 11010,
    OWNERSHIP_TRANSFER_STATUS_NOT_FOUND = 11011,
    OWNERSHIP_TRANSFER_STATUS_NOT_PENDING = 11012,
    OWNERSHIP_TRANSFER_CURRENT_USER_NOT_CURRENT_OWNER = 11013,
    OWNERSHIP_TRANSFER_CURRENT_USER_NOT_NEW_OWNER = 11014,
    OWNERSHIP_TRANSFER_NEW_OWNER_NO_DYNAMIC_VIEW_LICENSE = 11015,
    OWNERSHIP_TRANSFER_STATUS_UPDATE_NOT_SUPPORTED = 11016,
    SYS_ADMIN_VIEW_DIFFERENT_ORG = 11017,
    SYS_ADMIN_ASSUME_OWNER_UNAUTHORIZED = 11018,

    // Safe Sharing
    SAFE_SHARING_NOT_ENABLED = 11100,

    // Provisioning Validation
    SOS_INVALID_HOST = 11200,

    // Misc
    INVALID_REPORT_SHEET_ID = 11300,
    REPORT_COLUMN_MAPPING_NOT_FOUND = 11301,
}
