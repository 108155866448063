import classnames from 'classnames';
import * as React from 'react';
import { BaseComponent } from '../Base';
import './Tooltip.css';
import { TooltipPortal } from './TooltipPortal';

export interface DistinctOffset {
    top?: number;
    left?: number;
}

export type Offset = number | DistinctOffset;

export const isDistinctOffset = (offset: Offset): offset is DistinctOffset => typeof offset !== 'number';

interface Props {
    content: React.ReactNode;
    offset?: Offset;
}

interface State {
    visible: boolean;
    style?: DistinctOffset;
}

export class Tooltip extends BaseComponent<Props, State> {
    private wrapper: React.RefObject<HTMLDivElement>;
    private tooltip: React.RefObject<HTMLDivElement>;
    private offset?: Required<DistinctOffset>;
    private timeout: NodeJS.Timer | null = null;
    private tooltipIsMounted: boolean = false;

    public constructor(props: Props) {
        super(props);
        this.state = { visible: false };
        this.wrapper = React.createRef();
        this.tooltip = React.createRef();
        if (props.offset) {
            this.offset = {
                top: isDistinctOffset(props.offset) ? props.offset.top || 0 : props.offset,
                left: isDistinctOffset(props.offset) ? props.offset.left || 0 : props.offset,
            };
        }
    }

    public componentDidMount(): void {
        this.tooltipIsMounted = true;
    }

    public componentWillUnmount(): void {
        this.tooltipIsMounted = false;
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    public render(): JSX.Element {
        return (
            <div onMouseMove={this.showTooltip} onMouseLeave={this.hideTooltip} ref={this.wrapper} style={{ display: 'contents' }}>
                {this.props.children}
                <TooltipPortal>
                    <div
                        className={classnames('tooltip-component', { 'visible-tooltip': this.state.visible })}
                        style={this.state.style}
                        ref={this.tooltip}
                        onMouseOver={this.hideTooltip}
                    >
                        <div className="inner-tooltip">{this.props.content}</div>
                    </div>
                </TooltipPortal>
            </div>
        );
    }

    private showTooltip = (e: React.MouseEvent<HTMLSpanElement>) => {
        const dimensions = this.wrapper.current ? this.wrapper.current.getBoundingClientRect() : new DOMRect(0, 0, 0, 0);
        const tooltipWidth = this.tooltip.current ? this.tooltip.current.offsetWidth : 0;
        const tooltipHeight = this.tooltip.current ? this.tooltip.current.offsetHeight : 0;

        const offset = 5; // Prevents tooltip from being immediately dismissed

        const style: State['style'] = {};
        style.left = this.offset ? dimensions.left + dimensions.width / 2 + this.offset.left : e.clientX + offset;
        style.top = this.offset ? dimensions.top + dimensions.height / 2 + this.offset.top : e.clientY + offset;

        // Handle tooltip overflow page
        if (style.left + tooltipWidth > document.body.offsetWidth) {
            style.left -= tooltipWidth + offset * 2;
        }
        if (style.top + tooltipHeight > document.body.offsetHeight) {
            style.top -= tooltipHeight + offset * 2;
        }

        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
            if (this.tooltipIsMounted) {
                this.setState({ visible: true, style });
            }
        }, 1000);
    };

    private hideTooltip = () => {
        this.setState({ visible: false });
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    };
}
