import { OwnershipTransferStatus } from '../../common/enums';
import { IOwnershipTransfer } from '../../common/interfaces';
import * as classNames from 'classnames';
import * as Immutable from 'immutable';
import { List } from 'immutable';
import * as React from 'react';
import { AutoSizer, Column as ColumnRV, Table } from 'react-virtualized';
import { AutomationIds, AutomationTypes } from '../../common/enums/AutomationElements.enum';
import { OwnershipTransferTabOption } from '../../common/enums/OwnershipTransferTabOption';
import {
    CellRendererDateTime,
    CellRendererOrgViewsActions,
    CellRendererReceivedActions,
    CellRendererSentActions,
    CellRendererText,
} from '../../common/utils/CellRenderer';
import { BaseComponent } from '../../components/Base';
import { RowGetter } from '../../components/Grid/Grid.interface';
import SingleContact from '../../components/SingleContact/SingleContact';
import { WithDataClientProps } from '../../components/hoc/WithDataClient';
import { LanguageElementsProp, withLanguageElementsHOC } from '../../language-elements/withLanguageElementsHOC';
import OwnershipTransferRowElement from './Rows/OwnershipTransferRowElement';

interface Props extends WithDataClientProps {
    activeTab: OwnershipTransferTabOption;
    activeTabTransfers: IOwnershipTransfer[];
    onAccept: (transferId: string) => void;
    onDecline: (transferId: string) => void;
    onCancel: (transferId: string) => void;
    onTransferModalOpen: (transfer: IOwnershipTransfer) => void;
}

interface State {
    list: List<OwnershipTransferRowData>;
}

interface TransferColumnWidths {
    sent: number[];
    received: number[];
    orgViews: number[];
}

interface OwnershipTransferRowData extends IOwnershipTransfer {
    index: number;
    columnData: object; // not currently used in our app
}

export class OwnershipTransferTable extends BaseComponent<Props & LanguageElementsProp> {
    public state: State;
    private ownershipTransferRowData: OwnershipTransferRowData[];
    private transferColumnWidths: TransferColumnWidths = {
        sent: [280, 326, 160, 360, 120],
        received: [300, 276, 150, 360, 180],
        orgViews: [300, 276, 510, 180],
    };
    private activeTabColumnWidths: number[];
    private totalWidth: number;
    private totalActiveTabTransfers: number = 0;

    public constructor(props: Props & LanguageElementsProp) {
        super(props);
        this.ownershipTransferRowData = this.addIndexingToTransfersData(props.activeTabTransfers);

        const list = Immutable.List(this.ownershipTransferRowData);
        this.updateColumnWidthInfo(props.activeTab);
        this.totalActiveTabTransfers = props.activeTabTransfers.length;
        this.state = { list };
    }

    public render(): React.ReactNode {
        let columns: JSX.Element[] | null;
        let noRowsText: string;
        switch (this.props.activeTab) {
            case OwnershipTransferTabOption.RECEIVED:
                columns = this.getReceivedColumns(this.activeTabColumnWidths);
                noRowsText = this.props.languageElements.OWNERSHIP_TRANSFER_LIST_EMPTY;
                break;
            case OwnershipTransferTabOption.SENT:
                columns = this.getSentColumns(this.activeTabColumnWidths);
                noRowsText = this.props.languageElements.OWNERSHIP_TRANSFER_LIST_EMPTY;
                break;
            case OwnershipTransferTabOption.ORG_VIEWS:
                columns = this.getOrgViewsColumns(this.activeTabColumnWidths);
                noRowsText = this.props.languageElements.OWNERSHIP_TRANSFER_LIST_ORG_VIEWS_EMPTY;
                break;
            default:
                columns = null;
                noRowsText = '';
        }

        // Calculation for height here is to account for space for headers
        const autoSizerStyle = {
            height: `calc(100vh - 262px)`,
            width: this.totalWidth,
        };

        return (
            <div className="ownership-transfer-all">
                <div className="ownership-transfer-body">
                    <div data-client-id={AutomationIds.OWNERSHIP_TRANSFER_TABLE}>
                        <AutoSizer className="autosizer" disableWidth={false} style={autoSizerStyle}>
                            {({ height, width }: { height: number; width: number }) => (
                                <Table
                                    disableHeader={false}
                                    headerClassName={'column-title'}
                                    headerHeight={50}
                                    height={height}
                                    overscanRowCount={10}
                                    className={'transfer-table'}
                                    gridClassName={'transfer-listing'}
                                    rowClassName={({ index }) => (index === -1 ? 'list-header' : 'row-data')}
                                    rowHeight={50}
                                    rowGetter={(getter: RowGetter) => this.getOwnershipTransferRowData(this.state.list, getter.index)}
                                    rowCount={this.totalActiveTabTransfers}
                                    rowRenderer={OwnershipTransferRowElement}
                                    scrollToIndex={1}
                                    width={width}
                                    noRowsRenderer={() => (
                                        <div className="row-data">
                                            <div className="data-cell">{noRowsText}</div>
                                        </div>
                                    )}
                                >
                                    {columns}
                                </Table>
                            )}
                        </AutoSizer>
                    </div>
                </div>
            </div>
        );
    }

    public componentDidUpdate(prevProps: Props): void {
        if (this.props.activeTabTransfers !== prevProps.activeTabTransfers) {
            this.totalActiveTabTransfers = this.props.activeTabTransfers.length;
            this.ownershipTransferRowData = this.addIndexingToTransfersData(this.props.activeTabTransfers);
            this.updateColumnWidthInfo(this.props.activeTab);
            const list = Immutable.List(this.ownershipTransferRowData);
            this.setState({ list });
        }
    }

    private getOwnershipTransferRowData = (list: List<OwnershipTransferRowData>, index: number): OwnershipTransferRowData => {
        return list.get(index % list.size)!;
    };

    /**
     * Adds an index prop and empty columnData object to the array so that the
     * shape conforms to shape needed for react-virtualized table use
     */
    private addIndexingToTransfersData = (activeTabTransfers: IOwnershipTransfer[]): OwnershipTransferRowData[] => {
        return activeTabTransfers.map((transfer, index) => {
            return {
                ...transfer,
                index,
                columnData: {},
            };
        });
    };

    private getReceivedColumns = (activeTabColumnWidths: number[]): JSX.Element[] => {
        return [
            <ColumnRV
                key="currentOwner"
                width={activeTabColumnWidths[0]}
                disableSort={true}
                label={this.props.languageElements.OWNERSHIP_TRANSFER_RECEIVED_COLUMN_HEADERS[0]}
                dataKey="currentOwner"
                className={'outer-cell'}
                cellRenderer={(props) => (
                    <SingleContact
                        className={this.getClassForCurrentStatus(props.rowData.status)}
                        contact={props.rowData.currentOwner}
                        dataClientId={AutomationIds.CONTACT_WITH_ICON_AND_USER}
                        dataClientType={AutomationTypes.OWNERSHIP_TRANSFER_CELL}
                        showNameAndEmail={true}
                    />
                )}
                flexGrow={1}
            />,
            <ColumnRV
                key="viewName"
                width={activeTabColumnWidths[1]}
                disableSort={true}
                label={this.props.languageElements.OWNERSHIP_TRANSFER_RECEIVED_COLUMN_HEADERS[1]}
                dataKey="viewName"
                className={'outer-cell'}
                cellRenderer={(props) => CellRendererText(props.rowData.view.name, this.getClassForCurrentStatus(props.rowData.status))}
                flexGrow={1}
            />,
            <ColumnRV
                key="created"
                width={activeTabColumnWidths[2]}
                disableSort={true}
                label={this.props.languageElements.OWNERSHIP_TRANSFER_RECEIVED_COLUMN_HEADERS[2]}
                dataKey="created"
                className={'outer-cell'}
                cellRenderer={(props) => CellRendererDateTime(props.rowData.created, true, this.getClassForCurrentStatus(props.rowData.status))}
                flexGrow={1}
            />,
            <ColumnRV
                key="viewDescription"
                width={activeTabColumnWidths[3]}
                disableSort={true}
                label={this.props.languageElements.OWNERSHIP_TRANSFER_RECEIVED_COLUMN_HEADERS[3]}
                dataKey="view.description"
                className={'outer-cell'}
                cellRenderer={(props) => CellRendererText(props.rowData.view.description, this.getClassForCurrentStatus(props.rowData.status))}
                flexGrow={1}
            />,
            <ColumnRV
                key="actions"
                width={activeTabColumnWidths[4]}
                disableSort={true}
                label={this.props.languageElements.OWNERSHIP_TRANSFER_RECEIVED_COLUMN_HEADERS[4]}
                dataKey="actions"
                className={'outer-cell'}
                cellRenderer={(props) =>
                    CellRendererReceivedActions(props.rowData.id, props.rowData.status, this.props.onDecline, this.props.onAccept)
                }
                flexGrow={1}
            />,
        ];
    };

    private getSentColumns = (activeTabColumnWidths: number[]): JSX.Element[] => {
        return [
            <ColumnRV
                key="viewName"
                width={activeTabColumnWidths[0]}
                disableSort={true}
                label={this.props.languageElements.OWNERSHIP_TRANSFER_SENT_COLUMN_HEADERS[0]}
                dataKey="viewName"
                className={'outer-cell'}
                cellRenderer={(props) => CellRendererText(props.rowData.view.name, this.getClassForCurrentStatus(props.rowData.status))}
                flexGrow={1}
            />,
            <ColumnRV
                key="newOwner"
                width={activeTabColumnWidths[1]}
                disableSort={true}
                label={this.props.languageElements.OWNERSHIP_TRANSFER_SENT_COLUMN_HEADERS[1]}
                dataKey="newOwner"
                className={'outer-cell'}
                cellRenderer={(props) => (
                    <SingleContact
                        className={this.getClassForCurrentStatus(props.rowData.status)}
                        contact={props.rowData.newOwner}
                        dataClientId={AutomationIds.CONTACT_WITH_ICON_AND_USER}
                        dataClientType={AutomationTypes.OWNERSHIP_TRANSFER_CELL}
                        showNameAndEmail={true}
                    />
                )}
                flexGrow={1}
            />,
            <ColumnRV
                key="created"
                width={activeTabColumnWidths[2]}
                disableSort={true}
                label={this.props.languageElements.OWNERSHIP_TRANSFER_SENT_COLUMN_HEADERS[2]}
                dataKey="created"
                className={'outer-cell'}
                cellRenderer={(props) => CellRendererDateTime(props.rowData.created, true, this.getClassForCurrentStatus(props.rowData.status))}
                flexGrow={1}
            />,
            <ColumnRV
                key="viewDescription"
                width={activeTabColumnWidths[3]}
                disableSort={true}
                label={this.props.languageElements.OWNERSHIP_TRANSFER_SENT_COLUMN_HEADERS[3]}
                dataKey="view.description"
                className={'outer-cell'}
                cellRenderer={(props) => CellRendererText(props.rowData.view.description, this.getClassForCurrentStatus(props.rowData.status))}
                flexGrow={1}
            />,
            <ColumnRV
                key="actions"
                width={activeTabColumnWidths[4]}
                disableSort={true}
                label={this.props.languageElements.OWNERSHIP_TRANSFER_SENT_COLUMN_HEADERS[4]}
                dataKey="actions"
                className={'outer-cell'}
                cellRenderer={(props) => CellRendererSentActions(props.rowData.id, props.rowData.status, this.props.onCancel)}
                flexGrow={1}
            />,
        ];
    };

    private getOrgViewsColumns = (activeTabColumnWidths: number[]): JSX.Element[] => {
        return [
            <ColumnRV
                key="currentOwner"
                width={activeTabColumnWidths[0]}
                disableSort={true}
                label={this.props.languageElements.OWNERSHIP_TRANSFER_ORG_VIEWS_COLUMN_HEADERS[0]}
                dataKey="currentOwner"
                className={'outer-cell'}
                cellRenderer={(props) => (
                    <SingleContact
                        className={this.getClassForOrgViewsStatus(props.rowData.status)}
                        contact={props.rowData.currentOwner}
                        dataClientId={AutomationIds.CONTACT_WITH_ICON_AND_USER}
                        dataClientType={AutomationTypes.OWNERSHIP_TRANSFER_CELL}
                        showNameAndEmail={true}
                    />
                )}
                flexGrow={1}
            />,
            <ColumnRV
                key="viewName"
                width={activeTabColumnWidths[1]}
                disableSort={true}
                label={this.props.languageElements.OWNERSHIP_TRANSFER_ORG_VIEWS_COLUMN_HEADERS[1]}
                dataKey="viewName"
                className={'outer-cell'}
                cellRenderer={(props) => CellRendererText(props.rowData.view.name, this.getClassForOrgViewsStatus(props.rowData.status))}
                flexGrow={1}
            />,
            <ColumnRV
                key="viewDescription"
                width={activeTabColumnWidths[2]}
                disableSort={true}
                label={this.props.languageElements.OWNERSHIP_TRANSFER_ORG_VIEWS_COLUMN_HEADERS[2]}
                dataKey="view.description"
                className={'outer-cell'}
                cellRenderer={(props) => CellRendererText(props.rowData.view.description, this.getClassForOrgViewsStatus(props.rowData.status))}
                flexGrow={1}
            />,
            <ColumnRV
                key="actions"
                width={activeTabColumnWidths[3]}
                disableSort={true}
                label={this.props.languageElements.OWNERSHIP_TRANSFER_ORG_VIEWS_COLUMN_HEADERS[3]}
                dataKey="actions"
                className={'outer-cell'}
                cellRenderer={(props) => CellRendererOrgViewsActions(props.rowData, this.props.onTransferModalOpen)}
                flexGrow={1}
            />,
        ];
    };

    private updateColumnWidthInfo = (activeTab: OwnershipTransferTabOption): void => {
        this.activeTabColumnWidths = this.transferColumnWidths[activeTab];
        this.totalWidth = this.activeTabColumnWidths.reduce((total: number, currentValue: number) => total + currentValue, 0);
    };

    private getClassForCurrentStatus = (status: OwnershipTransferStatus): string => {
        if (
            status === OwnershipTransferStatus.ACCEPTED ||
            status === OwnershipTransferStatus.CANCELED ||
            status === OwnershipTransferStatus.REJECTED
        ) {
            return 'resolved';
        }

        if (
            status === OwnershipTransferStatus.ACCEPT_IN_PROGRESS ||
            status === OwnershipTransferStatus.CANCEL_IN_PROGRESS ||
            status === OwnershipTransferStatus.REJECT_IN_PROGRESS
        ) {
            return 'in-progress';
        }

        return '';
    };

    private getClassForOrgViewsStatus = (status: OwnershipTransferStatus): string => {
        return classNames({ resolved: status === OwnershipTransferStatus.PENDING });
    };
}

export default withLanguageElementsHOC(OwnershipTransferTable);
