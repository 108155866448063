import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AutomationIds } from '../../../common/enums/AutomationElements.enum';
import { Actions as AppActionsCreators } from '../../../containers/App/Actions';
import { Actions as HomeActions } from '../../../containers/Home';
import LeftPanelType from '../../../containers/LeftPanel/LeftPanelType';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';
import './DynamicViewTitle.css';

interface DispatchProps {
    fetchHomeData: () => void;
    setActiveSidePanel: (panel: LeftPanelType) => void;
    openLeftSidePanel: () => void;
}

export const DynamicViewTitle = (props: DispatchProps) => {
    const languageElements = useLanguageElements();
    return (
        <div className="dynamicViewTitle">
            <Link
                to="/"
                onClick={() => {
                    props.setActiveSidePanel(LeftPanelType.HOME);
                    props.openLeftSidePanel();
                }}
                data-client-id={AutomationIds.DYNAMIC_VIEW_TITLE}
            >
                <h1>{languageElements.HEADER_SMARTSHEET_DV}</h1>
            </Link>
        </div>
    );
};

const mapDispatch = {
    fetchHomeData: HomeActions.fetchHomeData,
    setActiveSidePanel: AppActionsCreators.setActiveSidePanel,
    openLeftSidePanel: AppActionsCreators.openLeftSidePanel,
};

export default connect<null, DispatchProps>(null, mapDispatch)(DynamicViewTitle);
