import { SmartsheetUser, User } from '../common/interfaces';
import { fullName } from '../common/utils/FullName';

export class SmartsheetUserMapper {
    public static fromUser = (user: User): SmartsheetUser => ({
        id: user.smartsheetId!,
        name: fullName(user),
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        email: user.email,
    });
}
