import { DisplayField } from '../../../common/interfaces';
import * as React from 'react';

import { AutomationIds } from '../../../common/enums/AutomationElements.enum';
import { Checkbox, Spacer } from '@smartsheet/lodestar-core';
import OrderedList from '../../../components/OrderedList/index';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';
import SectionTitle from '../SectionTitle';

declare interface Props {
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    availableFields: DisplayField[];
    displayedFields: DisplayField[];
    onDisplayedFieldsChange: (displayFields: DisplayField[]) => void;
}

const ControlId = {
    AVAILABLE_FIELDS: 'aaf-1',
    AVAILABLE_ITEMS: 'aaf-2',
};

const FieldSelector: React.SFC<Props> = props => {
    const languageElements = useLanguageElements();
    return (
        <div>
            <SectionTitle
                title={languageElements.ADMIN_PANEL_DISPLAY_FIELD_SELECTOR_HEADER}
                subtitle={languageElements.ADMIN_PANEL_DISPLAY_FIELD_SELECTOR_BODY}
            />
            <div className="column-panel-container">
                <div className="col-6">
                    <div className="column-panel">
                        <div className="column-panel-header">
                            <span>{languageElements.AVAILABLE_FIELDS} ({props.availableFields.length})</span>
                        </div>
                        <div className="column-panel-body">
                            <ul data-client-id={ControlId.AVAILABLE_FIELDS} className="available-fields">
                                {props.availableFields.map((field: DisplayField) => {
                                    return (
                                        <li key={field.columnId} data-client-type={ControlId.AVAILABLE_ITEMS}>
                                            <Spacer orientation="row" space="xSmall">
                                                <Checkbox 
                                                     checkedState={field.checked ? "checked": "unchecked" }
                                                     label={field.title!}
                                                     onClick={props.onChange}
                                                     inputId={field.columnId.toString()}
                                                     clientId={AutomationIds.FIELD_SELECTOR_CHECKBOX}
                                                />
                                            </Spacer>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="column-panel">
                        <div className="column-panel-header">
                            <span>{languageElements.VISIBLE_FIELDS} ({props.displayedFields.length})</span>
                        </div>
                        <OrderedList
                            collection={props.displayedFields}
                            width={270}
                            onChange={props.onDisplayedFieldsChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FieldSelector;
