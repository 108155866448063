import { FeatureFlag } from '../common/interfaces';
import axiosInstance from './Axios.instance';

class FeatureFlagsClient {
    private static url = 'featureflags';

    public async get(): Promise<FeatureFlag[]> {
        const response = await axiosInstance.get<FeatureFlag[]>(`${FeatureFlagsClient.url}`);
        return response.data;
    }
}

const featureFlagsClient = new FeatureFlagsClient();
export default featureFlagsClient;
