import { Button } from '@smartsheet/lodestar-core';
import { AsyncStatus } from '../../../common/enums';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AutomationIds } from '../../../common/enums/AutomationElements.enum';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';
import { Actions } from '../Details/Actions';
import { selectStatusForView } from '../Details/Selectors';
import { VerticalRule } from '../VerticalRule';
import './DismissErrors.css';

export const DismissErrors = () => {
    const languageElements = useLanguageElements();
    const viewStatus = useSelector(selectStatusForView);
    const dispatch = useDispatch();
    if (viewStatus !== AsyncStatus.ERROR) {
        return null;
    } else {
        return (
            <>
                <VerticalRule />
                <Button
                    onClick={(): void => {
                        // dispatch action to remove errors
                        dispatch(Actions.dismissErrors());
                    }}
                    className="btn btn-secondary dismiss-errors"
                    text={languageElements.VIEW_STATUS_DISMISS_ALL}
                    clientId={AutomationIds.VIEW_STATUS_DISMISS}
                    appearance="borderless"
                    size="medium"
                >
                    {languageElements.VIEW_STATUS_DISMISS_ALL}
                </Button>
            </>
        );
    }
};
