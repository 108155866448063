import { AsyncStatus } from '../../common/enums';
import { HomeView } from '../../common/interfaces';
import { Map as ImmutableMap } from 'immutable';
import { SortDirectionType } from 'react-virtualized';
import { createSelector } from 'reselect';
import { StoreState } from '../../store';
import { homeSourceFilterSelector } from '../App/Selectors';
import { HomeTableColumn } from './HomeTableColumnData';

export const homeViewStatusSelector = (state: StoreState) => state.home.get('status') as AsyncStatus;
export const homeViewsByIdSelector = (state: StoreState) => state.home.get('data') as ImmutableMap<string, HomeView>;
export const homeViews = createSelector(homeViewsByIdSelector, (data) => (data ? data.valueSeq().toArray() : []));

export const favoriteViews = createSelector(homeViews, (views) => views.filter((view) => view.favorite));
export const recentViews = createSelector(homeViews, (views) => views.filter((view) => view.lastAccessed));
export const pinnedViews = createSelector(homeViews, (views) => views.filter((view) => view.pinned));

// If sourceFilter is applied, filter view list by source filter id otherwise return original view list
export const sourceFilterHomeViewsSelector = createSelector(homeViews, homeSourceFilterSelector, (views, sourceFilter) => {
    if (sourceFilter) {
        return views.filter((view) => view.viewSourceId === sourceFilter.id);
    }
    return views;
});

export const sortBy = (state: StoreState) => state.home.get('sortBy') as HomeTableColumn;
export const sortDirection = (state: StoreState) => state.home.get('sortDirection') as SortDirectionType;
export const showPermissionsErrorSelector = (state: StoreState) => state.home.get('showPermissionsError') as boolean;
