import { Bulletin } from '../../common/interfaces';
import { isBefore } from 'date-fns';
import { Epic, ofType } from 'redux-observable';
import { from } from 'rxjs';
import { ignoreElements, map, switchMap, tap } from 'rxjs/operators';
import { LocalStorageKey } from '../../common/enums/LocalStorageKey';
import bulletinsClient from '../../http-clients/BulletinsClient';
import featureFlagsClient from '../../http-clients/FeatureFlagsClient';
import { ActionByType } from '../../store';
import { Actions, ActionTypes } from './Actions';

export const fetchFeatureFlagsEpic: Epic<Actions> = (action$) =>
    action$.pipe(
        ofType(ActionTypes.FETCH_FEATURE_FLAGS),
        switchMap(() => from(featureFlagsClient.get()).pipe(map(Actions.storeFeatureFlags)))
    );

export const fetchBulletinsEpic: Epic<Actions> = (action$) =>
    action$.pipe(
        ofType(ActionTypes.FETCH_FEATURE_FLAGS),
        switchMap(() =>
            from(bulletinsClient.get()).pipe(
                map((bulletins) => {
                    // We use localStorage to track which bulletins have been dismissed by the user. This is a quick and dirty way to allow users to
                    // dismiss bulletins and not show them to the user again, so long as they use the same browser. We did not want to go through the
                    // effort of tracking a user's acknowledgement of a bulletin in the database... yet.
                    //
                    // We only dispatch bulletins to the redux store if they have not yet been dismissed.
                    //
                    // If the id of a bulletin that has previously been dismissed is not present in the list of bulletins given to us by the server,
                    // we drop it from the list tracked in localStorage. We remove the localStorage item itself
                    // if we no longer have any ids for dismissed bulletins that we need to track.
                    const closedBulletinIdString = localStorage.getItem(LocalStorageKey.CLOSED_BULLETIN_IDS);
                    const previouslyClosedBulletinIds = closedBulletinIdString ? new Set(closedBulletinIdString.split(',')) : new Set<string>();

                    const bulletinsForDisplay: Bulletin[] = [];
                    const closedBulletinIds: string[] = [];

                    bulletins.forEach((bulletin) => {
                        const bulletinIdString = bulletin.id.toString();
                        if (previouslyClosedBulletinIds.has(bulletinIdString)) {
                            closedBulletinIds.push(bulletinIdString);
                        } else {
                            // Only pass bulletins to the redux store if they have no end date, or it has not already passed.
                            if (!bulletin.end || isBefore(new Date(), new Date(bulletin.end))) {
                                bulletinsForDisplay.push(bulletin);
                            }
                        }
                    });

                    if (closedBulletinIds.length > 0) {
                        localStorage.setItem(LocalStorageKey.CLOSED_BULLETIN_IDS, closedBulletinIds.join());
                    } else {
                        localStorage.removeItem(LocalStorageKey.CLOSED_BULLETIN_IDS);
                    }

                    return Actions.storeBulletins(bulletinsForDisplay);
                })
            )
        )
    );

export const clearBulletinEpic: Epic<Actions, never> = (action$) =>
    action$.pipe(
        ofType(ActionTypes.CLEAR_BULLETIN),
        tap((action: ActionByType<Actions, ActionTypes.CLEAR_BULLETIN>) => {
            const bulletinId = action.payload;
            const closedBulletinIdString = localStorage.getItem(LocalStorageKey.CLOSED_BULLETIN_IDS);
            const closedBulletinIds = closedBulletinIdString ? new Set(closedBulletinIdString.split(',')) : new Set();
            closedBulletinIds.add(bulletinId.toString());
            localStorage.setItem(LocalStorageKey.CLOSED_BULLETIN_IDS, Array.from(closedBulletinIds).join());
        }),
        ignoreElements()
    );
