import { Locale, TimeZone } from './enums';

export const dateTimeFormat = (userLocale: string = Locale.EN_US, userTimeZone: string = TimeZone.GREENWICH) => {
    try {
        return new Intl.DateTimeFormat(userLocale, {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: userTimeZone,
        });
    } catch (e) {
        return new Intl.DateTimeFormat(userLocale, { year: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric' });
    }
};
