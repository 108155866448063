/**
 * To determine if the calling code (app) is being rendered within an iFrame.
 */
export const isInIframe = (): boolean => {
    try {
        return window.self !== window.top || isInEmbeddedMode();
    } catch (e) {
        return true;
    }
};
/**
 * Determines if the app is being rendered with a query parameter of "embedded" equal to a truthy value.
 */
export const isInEmbeddedMode = (): boolean => {
    const url = new URL(window.location.href);
    const embedded = url.searchParams.get('embedded');
    return Boolean(embedded);
};
