import { CURRENT_USER, CURRENT_USER_DEFAULT_EMAIL, CURRENT_USER_DEFAULT_NAME } from '../constants';
import { ObjectType } from '../enums';
import { CellObjectValue, Contact, ContactObjectValue, isContact } from '../interfaces';
import { MultiPicklistObjectValue } from '../interfaces/CellObjectValue.interface';
import { isEmailValid, isMultiContactObjectValue } from '../utils';

/*
 * Return a contact object based on email input. If found in contacts argument, use the name prop found there.
 * If email = current user placeholder, swap for actual info if flag is true.
 * Otherwise if name is not available, assign email to name prop.
*/
export class HandleContacts {
    private readonly _user: Contact | undefined;

    public constructor(user?: Contact) {
        this._user = user ? user : undefined;
        if (this._user && !this._user.name) {
            this._user.name = this._user.email;
        }
    }

    public getContactFromCurrentUserEmailIfValid = (initialValue: string | Contact[] | CellObjectValue): Contact | string | undefined => {
        if (initialValue === CURRENT_USER_DEFAULT_EMAIL) {
            initialValue = CURRENT_USER;
        }

        return this.getContactIfValid(initialValue);
    }

    public getContactIfValid = (
        initialValue: string | Contact[] | CellObjectValue,
        name?: string,
        substituteCurrentUser = false,
    ): Contact | string | undefined => {

        // If initialValue is Contact object, return it
        if (isContact(initialValue)) {
            return initialValue;

        // If string, return Contact object if possible (otherwise return string)
        } else if (typeof initialValue === 'string') {
            const email = initialValue as string;
            if (isEmailValid(email) || email === CURRENT_USER) {
                return this.getFormattedContact(email, name, substituteCurrentUser);
            } else {
                return email;
            }

        // If initialValue is an array, return a string of concatenated emails
        } else if (Array.isArray(initialValue)) {
            const contactArray = initialValue as Contact[];

            return this.getStringFromContactArray(contactArray, true);

        // Otherwise ignore any other type of input and return null
        } else {
            return undefined;
        }
    }

    public getMultiContactsIfValid = (
        initialValue: CellObjectValue,
        name?: string,
        substituteCurrentUser = false,
        contacts?: Contact[]): Contact[] | string | null => {

        // If initialValue is of type CellObjectValue & includes a values array, return the contact array
        // (case: when editing existing row, SMAR will return objectValue for a multi-contact cell)
        if (isMultiContactObjectValue(initialValue)) {
            return (initialValue!).values as Contact[] || null;

        // If initialValue is already an array, return it
        // (case: when adding new row, default value for multi-contact is a contact array)
        } else if (Array.isArray(initialValue)) {
            return initialValue;

        // Otherwise return an array of 1 contact object if possible (from email string), otherwise return string or null
        // (case: add new row & default value was set before column type changed to multi-contact)
        } else {
            const contact = this.getContactIfValid(
                initialValue as string,
                undefined,
                true,
            );

            return typeof contact === 'object' ? [contact as Contact] : contact as string || null;
        }
    }

    public getFormattedContact = (
        email: string,
        name?: string,
        substituteCurrentUser = false,
        contacts?: Contact[],
        ): ContactObjectValue => {

        const contact = contacts && contacts.find((option: Contact) =>
            option.email !== undefined && option.email.toLowerCase() === email.toLowerCase());

        // Return contact if found in contactOptions (add objType for consistency)
        // This will get the name prop from the contacts (overrides name prop if passed in)
        if (contact != null) {
            const contactObject: ContactObjectValue = {...contact};
            contactObject.objectType = ObjectType.CONTACT;
            return contactObject;

        // else get info for current user (either actual or placeholder, depending on flag)
        } else if (email === CURRENT_USER) {
            return (this.getCurrentUser(substituteCurrentUser));

        // otherwise return email & name passed in, if any
        } else {
            return ({
                objectType: ObjectType.CONTACT,
                name: name || email,
                email,
            });
        }
    }

    /*
     * Returns objectValue for multi-contacts, replacing CURRENT_USER placeholder if flag is true.
     * Handles cases where column type is changed to MULTI_CONTACT after a default value is established for
     * a single contact column type.
     * If string isn't a valid email, placeholder or array of contacts, just return the string.
     * For now, any other column type that's switched to MULTI will just return the defaultValue.
    */
    public getMultiContactObjectValue = (defaultValue: string | Contact[],  substituteCurrentUser = false): CellObjectValue => {
        let adjustedDefaultValue: Contact[] | null = null;

        if (typeof defaultValue === 'string') {
            if (isEmailValid(defaultValue) || defaultValue === CURRENT_USER) {
                const formattedContact = this.getFormattedContact(defaultValue, undefined, substituteCurrentUser);
                adjustedDefaultValue =  formattedContact != null ? [formattedContact] : null;
            }

        } else if (Array.isArray(defaultValue)) {
            adjustedDefaultValue = (defaultValue as Contact[]).slice();
            const currentUserIndex = substituteCurrentUser ? adjustedDefaultValue.findIndex(el => el.name === CURRENT_USER_DEFAULT_NAME) : -1;
            if (currentUserIndex !== -1) {
                adjustedDefaultValue[currentUserIndex] = this.getCurrentUser(true);
            }
        }
        return (adjustedDefaultValue != null
            ? {objectType: ObjectType.MULTI_CONTACT, values: adjustedDefaultValue}
            : String(defaultValue)
        );
    }

    public getStringFromContactArray = (selectedValue: Contact[],  substituteCurrentUser = false ): string => {
        return (
            selectedValue
                .map((value: Contact) => {
                    return this.getStringFromContact(value, substituteCurrentUser);
                    })
                .join(', ')
        );
    }

    public getMultiPicklistFromContactArray = (contactArray: Contact[]): MultiPicklistObjectValue => {
        const contactStringArray = contactArray.map((contact: Contact) => {
            return this.getStringFromContact(contact, true);
        });

        return {
            objectType: ObjectType.MULTI_PICKLIST,
            values: contactStringArray,
        };
    }

    public getStringFromContact = (contact: Contact,  substituteCurrentUser: boolean): string => {
        let email = contact.email;
        let name = contact.name;
        if (substituteCurrentUser && name === CURRENT_USER_DEFAULT_NAME && this._user) {
            name = this._user.name;
            email = this._user.email;
        }
        return name ? `${name} <${email!}>` : email!;
    }

    public getContactListValue = (value: CellObjectValue | ContactObjectValue, substituteCurrentUser: boolean, contacts?: Contact[])
        : null | Contact => {
        if (value) {
            if (isContact(value)) {
                return value as ContactObjectValue;

            // Call getFormattedContact only if value is a string (note it might be an object other than contact obj)
            } else if (typeof value === 'string') {
                return this.getFormattedContact(value as string, undefined, false, contacts);
            }
        }
        return null;
    }

    /*
     * Returns a contact object either with current user placeholders (for form builder) or
     * with actual user email & name (for details panel), depending on boolean
    */
    private getCurrentUser = (substituteCurrentUser: boolean): ContactObjectValue => {
        if (substituteCurrentUser && this._user) {
            return ({
                objectType: ObjectType.CONTACT,
                name: this._user.name,
                email: this._user.email,
            });
        } else {
            return ({
                objectType: ObjectType.CONTACT,
                name: CURRENT_USER_DEFAULT_NAME,
                email: CURRENT_USER_DEFAULT_EMAIL,
            });
        }
    }
}
