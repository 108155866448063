import { MAIN_HEADER_HEIGHT } from '../../../common/constants';
import { AsyncStatus } from '../../../common/enums';
import { HomeView } from '../../../common/interfaces';
import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { AutomationIds } from '../../../common/enums/AutomationElements.enum';
import { HomeFilter } from '../../../common/enums/HomeFilter';
import { getUrlBarHeight } from '../../../common/utils';
import { BaseComponent } from '../../../components/Base';
import Spinner, { Color, Size } from '../../../components/Spinner';
import { LanguageElementsProp, withLanguageElementsHOC } from '../../../language-elements/withLanguageElementsHOC';
import { ActionByType } from '../../../store';
import { Actions as HomeActions, ActionTypes as HomeActionTypes, homeViews, homeViewStatusSelector } from '../../Home';
import { DETAILS_PANEL_HEADER_HEIGHT } from '../../View/Details/DetailsData/DetailsData';
import EmptyStateLeftRail from '../EmptyStateLeftRail/EmptyStateLeftRail';
import PanelFrame from '../PanelFrame/PanelFrame';
import './HomeFilterPanel.css';
import HomeFilterPanelAdminRow from './HomeFilterPanelAdminRow';
import HomeFilterPanelRow from './HomeFilterPanelRow';

interface OwnProps {
    leftSidePanelOpened: boolean;
}

interface StateProps {
    views: HomeView[];
    viewStatus: AsyncStatus;
}

interface DispatchProps {
    fetchHomeData: () => ActionByType<HomeActions, HomeActionTypes.FETCH_HOME_DATA>;
}

type HomeFilterPanelProps = OwnProps & StateProps & DispatchProps & LanguageElementsProp;

class HomeFilterPanel extends BaseComponent<HomeFilterPanelProps> {
    public render = () => (
        <PanelFrame titleLabel={this.props.languageElements.LEFT_SIDEBAR_TITLE} dataClientId={AutomationIds.HOME_PANEL_TITLE}>
            <div className="left-nav-list">{this.getPanelContent()}</div>
        </PanelFrame>
    );

    public componentDidMount(): void {
        this.props.fetchHomeData();
    }

    public componentDidUpdate(prevProps: Readonly<HomeFilterPanelProps>): void {
        const userOpenedLeftPanel = this.props.leftSidePanelOpened && this.props.leftSidePanelOpened !== prevProps.leftSidePanelOpened;
        if (userOpenedLeftPanel) {
            this.props.fetchHomeData();
        }
    }

    private getPanelContent = () => {
        switch (this.props.viewStatus) {
            case AsyncStatus.ERROR:
                // TODO: Create Home error component to be used in HomeFilterPanel and Home
                // eslint-disable-next-line max-len
                return (
                    <EmptyStateLeftRail message={this.props.languageElements.HOME_ERROR_MESSAGE} classNames="views-error" excludeHomeButton={true} />
                );

            case AsyncStatus.IN_PROGRESS:
                return this.props.views.length > 0 ? this.getRows() : <Spinner color={Color.BLUE} size={Size.SMALL} />;

            case AsyncStatus.DONE:
            case AsyncStatus.PARTIAL:
                return this.getRows();

            case AsyncStatus.NOT_STARTED:
                return <Spinner color={Color.BLUE} size={Size.SMALL} />;
        }
    };

    private getRows = () => (
        <ul
            className="home-filter-items"
            style={{ maxHeight: `calc(100vh - ${MAIN_HEADER_HEIGHT + DETAILS_PANEL_HEADER_HEIGHT + getUrlBarHeight()}px)` }}
        >
            <HomeFilterPanelRow filter={HomeFilter.ALL} />
            <HomeFilterPanelRow filter={HomeFilter.OWNED_BY_ME} />
            <HomeFilterPanelRow filter={HomeFilter.SHARED_WITH_ME} />
            <HomeFilterPanelRow filter={HomeFilter.SHARED_WITH_GROUP} />
            <HomeFilterPanelAdminRow />
        </ul>
    );
}

const mapState = createStructuredSelector({
    views: homeViews,
    viewStatus: homeViewStatusSelector,
});

const mapDispatch: DispatchProps = {
    fetchHomeData: HomeActions.fetchHomeData,
};

export default withLanguageElementsHOC(connect<StateProps, DispatchProps>(mapState, mapDispatch)(HomeFilterPanel));
