import { FormRule } from '../../../common/classes';
import { Column, FormFieldInterface, FormRuleInterface } from '../../../common/interfaces';
import * as React from 'react';
import { AutomationIds } from '../../../common/enums/AutomationElements.enum';
import BorderButton from '../../../components/Buttons/BorderButton/BorderButton';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';
import FormRuleBase from '../FormRule/FormRuleBase';

interface Props {
    onClickAddRule: () => void;
    onUpdateRule: (rule: FormRuleInterface) => void;
    onDeleteRule: (rule: FormRuleInterface) => void;
    rules?: FormRuleInterface[];
    fields: Column[];
    includedFields: FormFieldInterface[];
}

const FormLogicContainer: React.SFC<Props> = props => {
    const languageElements = useLanguageElements();
    const rulesValid = !props.rules || (props.rules && props.rules.every((rule: FormRuleInterface) => new FormRule(
        props.fields,
        props.includedFields,
        rule,
    ).isValid));
    return (
        <div className="form-logic-container">
            <span className="container-header">{languageElements.ADMIN_PANEL_FORM_FIELD_LOGIC}</span>
            { props.rules &&
                props.rules.map((rule: FormRuleInterface, i: number) => {
                    return (
                        <FormRuleBase
                            key={rule.id}
                            rule={rule}
                            fields={props.fields}
                            includedFields={props.includedFields}
                            onUpdateRule={props.onUpdateRule}
                            onDeleteRule={props.onDeleteRule}
                            dataClientId={AutomationIds.FORM_RULE_WRAPPER}
                            index={i}
                        />
                    );
                })
            }
            <BorderButton
                disabled={!rulesValid}
                onClick={props.onClickAddRule}
                dataClientId={AutomationIds.FORM_RULE_BORDER_BUTTON}
                imageAltText={languageElements.FORM_LOGIC_ADD_RULE}
            />
        </div>
    );
};

export default FormLogicContainer;
