import { Column, FormFieldInterface, FormInterface, ViewSourceMetaData } from '../../common/interfaces';
import { fromJS } from 'immutable';
import { Actions, ActionTypes } from './Actions';

const initialState = fromJS({
    view: {},
    config: {},
    shares: [],
    shareErrors: [],
    form: {},
    viewSourceMetaData: {},
    smartsheetGroups: [],
    smartsheetUsers: [],
    smartsheetAdmins: [],
    smartsheetUsersByEmails: [],
    pendingOwnershipTransfer: undefined,
    error: undefined,
});

const reducer = (state = initialState, action: Actions) => {
    switch (action.type) {
        case ActionTypes.STORE_ADMIN_VIEW_DATA:
            return state.set('view', fromJS(action.payload));
        case ActionTypes.STORE_VIEW_CONFIG:
            return state.set('config', fromJS(action.payload));
        case ActionTypes.STORE_SHARES:
            return state.set('shares', fromJS(action.payload));
        case ActionTypes.STORE_SHARE_ERRORS:
            return state.set('shareErrors', fromJS(action.payload));
        case ActionTypes.STORE_VIEW_SOURCE_META_DATA:
            return state.set('viewSourceMetaData', fromJS(action.payload));
        case ActionTypes.STORE_SMARTSHEET_GROUPS:
            return state.set('smartsheetGroups', fromJS(action.payload));
        case ActionTypes.STORE_SMARTSHEET_USERS:
            return state.set('smartsheetUsers', fromJS(action.payload));
        case ActionTypes.STORE_SMARTSHEET_USERS_BY_EMAILS:
            return state.set('smartsheetUsersByEmails', fromJS(action.payload));
        case ActionTypes.STORE_OWNERSHIP_TRANSFER:
            return state.set('pendingOwnershipTransfer', fromJS(action.payload));
        case ActionTypes.STORE_SMARTSHEET_ADMINS:
            return state.set('smartsheetAdmins', fromJS(action.payload));
        case ActionTypes.STORE_FORM:
            return state.set('form', fromJS(action.payload));
        case ActionTypes.CLEAN_FORM:
            // clean form data of form fields that have their underlining column
            // removed from Smartsheet.
            // TODO: This logic was added as part of a hotfix. It should be moved to common and replace the code
            // in ViewFormResolver.cleanFormFields() which is doing the exact same thing.
            if (!state.get('form')) {
                return state;
            }

            const form: FormInterface = state.get('form').toJS();
            if (!form.fields || form.fields.length === 0) {
                return state;
            }

            const viewSourceMetaData: ViewSourceMetaData = state.get('viewSourceMetaData').toJS();
            if (!viewSourceMetaData) {
                return state;
            }

            const sourceFieldsSet: Set<number> = new Set<number>();
            if (viewSourceMetaData.columns) {
                viewSourceMetaData.columns.forEach((column: Column) => {
                    sourceFieldsSet.add(column.id);
                });
            }

            const existingFormFields: FormFieldInterface[] = [];
            let newOrdinal = 0;
            form.fields.forEach((field: FormFieldInterface) => {
                if (sourceFieldsSet.has(field.columnId)) {
                    field.ordinal = newOrdinal++;
                    existingFormFields.push(field);
                }
            });

            form.fields = existingFormFields;

            return state.set('form', fromJS(form));
    }
    return state;
};

export default reducer;
