import { Models } from '@smartsheet/core-views-api-models';
import { MAX_CELL_VALUE_CHARS } from '../../common/constants';
import { NumberFormatType } from '../../common/enums/FormatTypes.enum';
import { CellObjectValue, Format } from '../../common/interfaces';
import { isStringNumber } from '../../common/utils';
import * as React from 'react';
import { useSelector } from 'react-redux';
import 'reflect-metadata';
import { AutomationTypes } from '../../common/enums/AutomationElements.enum';
import * as gsFormattingUtility from '../../common/utils/GsFormattingUtility';
import { gsGetColumnsFeatureSelector } from '../../containers/App/Selectors';
import { getFormattedValueAndSortingValue } from '../../containers/View/utils/GetFormattedCell';
import TextNumber from '../TextNumber';

export interface Props {
    inputIndex: number;
    defaultValue?: string | number;
    value?: string | number;
    format?: Format;
    multiline?: boolean;
    readOnly?: boolean;
    displayValue?: string;
    objectValue?: CellObjectValue;
    onChange?: (value: string) => void;
}

const FormFieldTextNumber = ({
    inputIndex,
    defaultValue,
    value: propValue,
    format,
    multiline,
    readOnly,
    displayValue,
    objectValue,
    onChange,
}: Props) => {
    const gsGetColumnsFeature = useSelector(gsGetColumnsFeatureSelector);
    const value = propValue ?? defaultValue ?? '';

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        if (onChange) {
            onChange(newValue);
        }
    };

    let preview: string | undefined;
    const numberFormatObject = format ? gsFormattingUtility.getNumberFormatObjectAll(format, gsGetColumnsFeature) : undefined;

    if (
        numberFormatObject &&
        (numberFormatObject.numberFormat === NumberFormatType.currency || numberFormatObject.numberFormat === Models.NumberFormat.CURRENCY)
    ) {
        preview = getFormattedValueAndSortingValue({ displayValue, objectValue, value, format }, gsGetColumnsFeature).displayValue;
    }

    return (
        <TextNumber
            key={`tni-${inputIndex}`}
            dataClientType={AutomationTypes.TEXT_NUMBER_COMPONENT}
            rows={multiline ? 3 : 1}
            value={value}
            numberFormatObject={numberFormatObject}
            onChange={handleChange}
            disabled={readOnly}
            maxLength={MAX_CELL_VALUE_CHARS}
            fadeSymbol={!isStringNumber(propValue)}
            preview={preview}
        />
    );
};

export default FormFieldTextNumber;
