import { ColumnType } from '../../../common/enums';
import { DetailsPanelTabType } from "../../../common/enums/DetailsPanelTabType.enum";
import { FormFieldInterface, SmartsheetUser } from "../../../common/interfaces";

export const getInitialTab = (isNewSubmission: boolean, initialTab?: DetailsPanelTabType): DetailsPanelTabType => {
  if (isNewSubmission) {
    return DetailsPanelTabType.DATA;
  }

  return initialTab || DetailsPanelTabType.DATA;
};


export const mapContactColumns = (inputForm: FormFieldInterface[], smartsheetUsersData: SmartsheetUser[]): FormFieldInterface[] => {
  // map over every field in inputForm
  return inputForm.map((formField: FormFieldInterface) => {
    const updatedFormField = { ...formField };
    // return if type is not contact list or multi contact list...
    if (formField.type !== ColumnType.CONTACT_LIST && formField.type !== ColumnType.MULTI_CONTACT_LIST) {
      return updatedFormField;
    }

    // ... or if validation is enabled
    if (formField.validation) {
      return updatedFormField;
    }

    // set contactOptions for the field by mapping over the smartsheetUsers
    // mainly cast id to string and select id, name, email.
    // NB: name might be undefined
    updatedFormField.contactOptions = smartsheetUsersData.map((user: SmartsheetUser) => ({
      id: String(user.id),
      name: user.name,
      email: user.email,
    }));
    return updatedFormField;
  });
};