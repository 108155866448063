import { CurrencySymbolType } from '../enums';

import brazil_real from '../../assets/images/currencySymbolIcons/brazil_real.svg';
import dollar from '../../assets/images/currencySymbolIcons/dollar.svg';
import euro from '../../assets/images/currencySymbolIcons/euro.svg';
import indian_rupee from '../../assets/images/currencySymbolIcons/indian_rupee.svg';
import israel_shekel from '../../assets/images/currencySymbolIcons/israel_shekel.svg';
import japanese_yen from '../../assets/images/currencySymbolIcons/japanese_yen.svg';
import krona from '../../assets/images/currencySymbolIcons/krona.svg';
import krone from '../../assets/images/currencySymbolIcons/krone.svg';
import russian_ruble from '../../assets/images/currencySymbolIcons/russian_ruble.svg';
import south_african_rand from '../../assets/images/currencySymbolIcons/south_african_rand.svg';
import south_korean_won from '../../assets/images/currencySymbolIcons/south_korean_won.svg';
import swiss_franc from '../../assets/images/currencySymbolIcons/swiss_franc.svg';
import uk_pound from '../../assets/images/currencySymbolIcons/uk_pound.svg';
import { LanguageElements } from '../../language-elements/LanguageElements';

export class CurrencySymbolUtility {
    private currencyIconUrlMap: Map<CurrencySymbolType, string>;
    private currencyNameMap: Map<CurrencySymbolType, string>;

    public constructor(languageElements: LanguageElements) {
        this.currencyIconUrlMap = this.generateCurrencyIconUrlMap();
        this.currencyNameMap = this.generateCurrencyNameMap(languageElements);
    }

    public getCurrencyIconUrlFromSymbol = (currencySymbolString: CurrencySymbolType): string | undefined => {
        return this.currencyIconUrlMap.get(currencySymbolString);
    };

    public getCurrencyNameFromSymbol = (currencySymbolString: CurrencySymbolType): string | undefined => {
        return this.currencyNameMap.get(currencySymbolString);
    };

    /**
     * Helper Method: Generates lookup map for a currency icon
     */
    private generateCurrencyIconUrlMap = (): Map<CurrencySymbolType, string> => {
        return new Map([
            [CurrencySymbolType.NONE, ''],
            [CurrencySymbolType.ARS, dollar],
            [CurrencySymbolType.AUD, dollar],
            [CurrencySymbolType.BRL, brazil_real],
            [CurrencySymbolType.CAD, dollar],
            [CurrencySymbolType.CHF, swiss_franc],
            [CurrencySymbolType.CLP, dollar],
            [CurrencySymbolType.CNY, japanese_yen],
            [CurrencySymbolType.DKK, krona],
            [CurrencySymbolType.EUR, euro],
            [CurrencySymbolType.GBP, uk_pound],
            [CurrencySymbolType.HKD, dollar],
            [CurrencySymbolType.ILS, israel_shekel],
            [CurrencySymbolType.INR, indian_rupee],
            [CurrencySymbolType.JPY, japanese_yen],
            [CurrencySymbolType.KRW, south_korean_won],
            [CurrencySymbolType.MXN, dollar],
            [CurrencySymbolType.NOK, krone],
            [CurrencySymbolType.NZD, dollar],
            [CurrencySymbolType.RUB, russian_ruble],
            [CurrencySymbolType.SEK, krona],
            [CurrencySymbolType.SGD, dollar],
            [CurrencySymbolType.USD, dollar],
            [CurrencySymbolType.ZAR, south_african_rand],
        ]);
    };

    /**
     * Helper Method: Generates lookup map for a currency name
     */
    private generateCurrencyNameMap = (languageElements: LanguageElements): Map<CurrencySymbolType, string> => {
        return new Map([
            [CurrencySymbolType.NONE, ''],
            [CurrencySymbolType.ARS, languageElements.CURRENCY_ARGENTINA_PESO],
            [CurrencySymbolType.AUD, languageElements.CURRENCY_AUSTRALIA_DOLLAR],
            [CurrencySymbolType.BRL, languageElements.CURRENCY_BRAZIL_REAL],
            [CurrencySymbolType.CAD, languageElements.CURRENCY_CANADA_DOLLAR],
            [CurrencySymbolType.CHF, languageElements.CURRENCY_SWITZERLAND_FRANC],
            [CurrencySymbolType.CLP, languageElements.CURRENCY_CHILE_PESO],
            [CurrencySymbolType.CNY, languageElements.CURRENCY_CHINA_YUAN],
            [CurrencySymbolType.DKK, languageElements.CURRENCY_DENMARK_KRONE],
            [CurrencySymbolType.EUR, languageElements.CURRENCY_EURO],
            [CurrencySymbolType.GBP, languageElements.CURRENCY_UNITED_KINGDOM_POUND],
            [CurrencySymbolType.HKD, languageElements.CURRENCY_HONG_KONG_DOLLAR],
            [CurrencySymbolType.ILS, languageElements.CURRENCY_ISRAEL_NEW_SHEKEL],
            [CurrencySymbolType.INR, languageElements.CURRENCY_INDIA_RUPEE],
            [CurrencySymbolType.JPY, languageElements.CURRENCY_JAPAN_YEN],
            [CurrencySymbolType.KRW, languageElements.CURRENCY_SOUTH_KOREA_WON],
            [CurrencySymbolType.MXN, languageElements.CURRENCY_MEXICO_PESO],
            [CurrencySymbolType.NOK, languageElements.CURRENCY_NORWAY_KRONE],
            [CurrencySymbolType.NZD, languageElements.CURRENCY_NEW_ZEALAND_DOLLAR],
            [CurrencySymbolType.RUB, languageElements.CURRENCY_RUSSIA_RUBLE],
            [CurrencySymbolType.SEK, languageElements.CURRENCY_SWEDEN_KRONA],
            [CurrencySymbolType.SGD, languageElements.CURRENCY_SINGAPORE_DOLLAR],
            [CurrencySymbolType.USD, languageElements.CURRENCY_UNITED_STATES_DOLLAR],
            [CurrencySymbolType.ZAR, languageElements.CURRENCY_SOUTH_AFRICA_RAND],
        ]);
    };
}
