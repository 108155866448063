import { RefObject } from 'react';
import * as React from 'react';
import { PureBaseComponent } from '../Base';

export interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    maxHeight?: string;
    value: string;
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
}

export default class TextArea extends PureBaseComponent<TextAreaProps> {
    private readonly textAreaRef: RefObject<HTMLTextAreaElement>;

    public constructor(props: TextAreaProps) {
        super(props);

        this.textAreaRef = React.createRef();
    }

    public render(): JSX.Element {
        const {
            maxHeight = '50vh',
            ...rest } = this.props;

        return (
            <textarea
                {...rest}
                ref={this.textAreaRef}
                style={{ resize: 'none', height: 'inherit', maxHeight }}
                onChange={this.handleChange}
            />
        );
    }

    public componentDidMount(): void {
        if (this.props.value) {
            this.setTextAreaElementHeight();
        }
    }

    public componentDidUpdate(): void {
        if (this.props.value) {
            this.setTextAreaElementHeight();
        }
    }

    private handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = event => {
        this.setTextAreaElementHeight();

        if (this.props.onChange) {
            this.props.onChange(event);
        }
    }

    private setTextAreaElementHeight(): void {
        const textAreaElement = this.textAreaRef.current;

        if (!textAreaElement) {
            return;
        }

        // Set text area height to 'inherit' before getting the scroll height. This causes the text area to shrink to the height of the value making
        // the scroll height correct
        textAreaElement.style.height = 'inherit';

        // Get the computed styles for the text area element
        const computedStyles = window.getComputedStyle(textAreaElement);

        // To stop the text area increasing incorrectly in height, add the border width top and bottom to the scroll height. IMPORTANT: Changing the
        // CSS for text area could potentially break the auto expand/shrink logic here
        const newHeight = textAreaElement.scrollHeight + this.getIntegerPropertyValue(computedStyles, 'border-top-width')
            + this.getIntegerPropertyValue(computedStyles, 'border-bottom-width');

        textAreaElement.style.height = `${newHeight}px`;
    }

    private getIntegerPropertyValue(computedStyles: CSSStyleDeclaration, propertyName: string): number {
        return parseInt(computedStyles.getPropertyValue(propertyName), 10);
    }
}
