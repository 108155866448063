import * as React from 'react';
import bluePlus from '../assets/blue.svg';
import { Button } from '../index';
import './BorderButton.css';
import { ButtonProps } from '../Button/Button';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';

const BorderButton = (props: ButtonProps) => {
    const languageElements = useLanguageElements();
    return (
        <Button
            className={`btn-bordered btn-block ${props.disabled ? 'disabled' : ''}`}
            tabIndex={props.tabIndex}
            icon={bluePlus}
            onClick={props.onClick}
            text={languageElements.FORM_ADD_FIELD_LOGIC_BUTTON}
            dataClientId={props.dataClientId}
            disabled={props.disabled}
        />
    );
};

export default BorderButton;
