/* eslint @typescript-eslint/unbound-method:"off" */
import * as React from 'react';
import { Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { WithDataClientProps } from '../hoc/WithDataClient';
import './DndItem.css';

interface DroppableProps extends WithDataClientProps {
    width: number;
    droppableId: string;
    className: string;
    style?: any;
    isDropDisabled: boolean;
}

const DroppableContainer: React.SFC<DroppableProps> = props => {
        return (
            <Droppable droppableId={props.droppableId} isDropDisabled={props.isDropDisabled}>
                {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                    <div
                        ref={provided.innerRef}
                        style={props.style}
                        className={props.className}
                        data-client-id={props.dataClientId}
                    >
                        {props.children}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        );
};

export default DroppableContainer;
