import { View } from '../../../common/interfaces';
import { SharedWithGroup } from '../../../common/interfaces/View.interface';
import * as React from 'react';
import { BaseComponent } from '../../Base';
import Pill from '../../Pill';
import Tooltip from '../../TooltipLegacy';
import { CellRenderer } from '../CellRenderer';
import './CellRendererPill.css';

interface CellRendererPillProps {
    content: NonNullable<View['sharedWithGroups']>;
    columnWidth: number;
    updateWidth: () => void;
    classes?: string;
}

interface CellRendererPillState {
    content: NonNullable<View['sharedWithGroups']>;
    overflowed: NonNullable<View['sharedWithGroups']>;
    pillWidths: Map<string, number>;
}

export class CellRendererPill extends BaseComponent<CellRendererPillProps, CellRendererPillState> {
    private contentMap: Map<string, SharedWithGroup>;
    public constructor(props: CellRendererPillProps) {
        super(props);
        this.state = {
            content: props.content,
            overflowed: [],
            pillWidths: new Map(),
        };
        this.contentMap = new Map();
    }

    public componentDidMount(): void {
        this.createContentMap(this.props.content);
        this.props.updateWidth();
    }

    public componentDidUpdate(prevProps: Readonly<CellRendererPillProps>, prevState: Readonly<CellRendererPillState>): void {
        if (this.props.content !== prevProps.content) {
            this.createContentMap(this.props.content);
            const c = this.updateContent();
            const pillIdSet = new Set(c.map((val) => val.id));
            const overflowed = this.props.content.filter((val) => !pillIdSet.has(val.id));
            this.setState({ content: c, overflowed });
        }

        const content = this.updateContent();
        if (
            content.length !== prevState.content.length ||
            this.props.content !== prevProps.content ||
            this.props.columnWidth !== prevProps.columnWidth
        ) {
            const pillIdSet = new Set(content.map((val) => val.id));
            const overflowed = this.props.content.filter((val) => !pillIdSet.has(val.id));
            this.setState({ content, overflowed });
        }
    }

    public render(): JSX.Element {
        return (
            <CellRenderer className={this.props.classes}>
                {this.state.content.map((pillContent) => (
                    <Pill updateWidth={this.updateWidth(pillContent.id)} label={pillContent.name!} value="" isValid={true} key={pillContent.id} />
                ))}
                {this.state.overflowed.length > 0 && (
                    <Tooltip
                        content={
                            <ul>
                                {this.state.overflowed.map((group) => (
                                    <li key={group.id}>{group.name}</li>
                                ))}
                            </ul>
                        }
                    >
                        <Pill label={`+${this.state.overflowed.length}`} value="" isValid={true} />
                    </Tooltip>
                )}
            </CellRenderer>
        );
    }

    public updateWidth = (pillId: string) => (width: number) => {
        const prev = this.state.pillWidths.get(pillId);
        if (!prev || prev !== width) {
            const pillWidths = this.state.pillWidths;
            pillWidths.set(pillId, width);
            this.setState({ pillWidths });
        }
    };

    private updateContent = () => {
        let totalWidth = 0;
        const content: NonNullable<View['sharedWithGroups']> = [];
        for (const group of this.props.content) {
            const width = this.state.pillWidths.get(group.id) || 0;
            totalWidth += width + 10;
            if (totalWidth >= this.props.columnWidth - 30) {
                // 30px for overflow buffer
                return content;
            }
            const newContent = this.contentMap.get(group.id);
            if (newContent) {
                content.push(newContent);
            }
        }
        return content;
    };

    private createContentMap = (content: NonNullable<View['sharedWithGroups']>) => content.map((group) => this.contentMap.set(group.id, group));
}
