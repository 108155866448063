import * as React from 'react';

import { WithDataClientProps } from '../../../components/hoc/WithDataClient';
import { LanguageElementsProp, withLanguageElementsHOC } from '../../../language-elements/withLanguageElementsHOC';
import SectionTitle from '../SectionTitle';

export interface BasicDescriptionProps extends WithDataClientProps {
    viewDescription: string;
    onDescriptionChange: (event: React.FormEvent<HTMLTextAreaElement>) => void;
}

const VIEW_DESCRIPTION_MAX_LENGTH = 4000;

export class BasicDescription extends React.PureComponent<BasicDescriptionProps & LanguageElementsProp> {
    public render(): React.ReactNode {
        return (
            <div className="viewDescription" id="basicViewDescription">
                <SectionTitle
                    title={this.props.languageElements.ADMIN_PANEL_BASIC_VIEW_DESCRIPTION}
                    subtitle={this.props.languageElements.ADMIN_PANEL_BASIC_VIEW_DESCRIPTION_DESCRIPTION}
                />
                <textarea
                    className="descriptionInput"
                    rows={8}
                    maxLength={VIEW_DESCRIPTION_MAX_LENGTH}
                    data-client-id={this.props.dataClientId}
                    onChange={this.props.onDescriptionChange}
                    value={this.props.viewDescription || undefined}
                />
            </div>
        );
    }
}

export default withLanguageElementsHOC(BasicDescription);
