/* eslint no-var:"off", eqeqeq:"off", no-bitwise:"off" -- code from app-core */
// noinspection ES6ConvertVarToLetConst

// Used in AtMentions.tsx. Copied from app-core: dev2/web/javascript/legacyApp/src/core/Common.ts

/**
 * Simple string hash function.
 */
export function hashCode(str: string): number {
    let hash = 0;
    if (str.length == 0) {
        return hash;
    }
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}

/**
 * Determine if a string represents an astral character, where an astral character is defined as the presence of a high
 * surrogate code unit (character range \uD800-\uDBFF) followed by a low surrogate code unit (character range \uDC00\uDFFF).
 *
 * NOTE: any string of length != 2 IS NOT an astral character; this is not a "starts with astral character" function.
 *
 * @returns true if the string is a single astral character, false if not.
 */
export class StringUtil {
    public static isAstralCharacter(s: string | null | undefined): boolean {
        if (!s || s.length !== 2) {
            return false;
        }

        const c1 = s.charCodeAt(0);
        const c2 = s.charCodeAt(1);

        return 0xd800 <= c1 && c1 <= 0xdbff && 0xdc00 <= c2 && c2 <= 0xdfff;
    }
}
