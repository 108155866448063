import * as React from 'react';
import { AutomationTypes } from '../../../common/enums/AutomationElements.enum';
import './FormFieldMenuItem.css';

export interface FormFieldMenuItemProps {
    onClick: () => void;
    icon?: string;
}

export const FormFieldMenuItem: React.SFC<FormFieldMenuItemProps> = props => (
    <div className={'form-field-menu-item'} data-client-type={AutomationTypes.DROP_DOWN_MENU_ITEM} onClick={props.onClick}>
        <div className={'form-field-menu-item-icon'}>
            {props.icon && <img src={props.icon} alt={''}/>}
        </div>
        {props.children}
    </div>
);
