import { MAX_CELL_VALUE_CHARS } from '../../common/constants';
import { CellObjectValue, Contact, ContactObjectValue } from '../../common/interfaces';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { AutomationIds } from '../../common/enums/AutomationElements.enum';
import { disableFreeTextNewOptions } from '../../common/utils/DisableFreeTextNewOptions';
import { isDisabledContactPickerOnDemandSelector } from '../../containers/App/Selectors';
import ContactPicker from '../ContactPicker';
import { checkIfOnDemandOptionsShouldBeEnabled } from '../ContactPicker/CheckIfOnDemandOptionsShouldBeEnabled';
import TextNumber from '../TextNumber';

export interface Props {
    inputIndex: number;
    value?: string | number | Contact;
    options?: Contact[];
    onChange?: (value: Contact) => void;
    readOnly?: boolean;
    placeholder?: string;
    validation?: boolean;
    onClearContent?: () => void;
    isSettingsMode: boolean;
    onGetContactListValue?: (value: CellObjectValue | ContactObjectValue, substituteCurrentUser: boolean, contacts?: Contact[]) => null | Contact;
}

/**
 * Find the full contact object if the field has a value (email) already. If the full contact cannot be found
 * in the provided list of contact options (the field is strict, and the value of the cell isn't within the specified
 * contact options, find the full contact information using the objectValue of the field.
 *
 * Also check if the value being displayed is formatted as MULTI_CONTACT_LIST data (the column has been switched from
 * multi to single). In this case we want to just show a read-only text/number field. To check this, look for ' ' in the value
 * which will exist in any comma delimited string of multi-contacts. For any string that does NOT have a space, show
 * contact instead of a text/number field, whether it's valid or not. (This allows the user to correct a typo in the contact picker.)
 */
const FormFieldContactList = ({
    inputIndex,
    value,
    validation,
    options,
    placeholder,
    onChange,
    onClearContent,
    readOnly,
    isSettingsMode,
    onGetContactListValue,
}: Props) => {
    /**
     * If current user is specified, add current user to contactOptions, set defaultValue to current user email
     * check if current user is already in contacts to avoid adding twice
     */
    // If initialValue is a string email, create Contact object from it (otherwise return the string )
    // If initialValue is a contact [], create concatenated string of Contacts

    const copyOfValue = isSettingsMode ? (value as ContactObjectValue) : onGetContactListValue!(value as CellObjectValue, true, options);

    const notRestrictedToContactPickerOptions = !validation;
    const isDisabledContactPickerOnDemand = useSelector(isDisabledContactPickerOnDemandSelector);
    const enableOnDemandOptions =
        notRestrictedToContactPickerOptions && !isDisabledContactPickerOnDemand && checkIfOnDemandOptionsShouldBeEnabled(options);

    const handleChangeContact = (contact: Contact): void => {
        if (onChange) {
            onChange(contact);
        }
    };

    // If value passed in is null or formattedContact is valid, display contact picker
    if (copyOfValue == null || typeof copyOfValue === 'object') {
        return (
            <ContactPicker
                key={`cpi-${inputIndex}`}
                dataClientId={AutomationIds.FORM_FIELD_WRAPPER_CONTACT}
                allOptions={options}
                selectedOptions={copyOfValue as Contact | Contact[]}
                placeholder={placeholder}
                validateExistingSelections={validation}
                isValidNewOption={validation ? disableFreeTextNewOptions : undefined}
                enableOnDemandOptions={enableOnDemandOptions}
                allowClearing={true}
                onChange={handleChangeContact}
                disabled={readOnly}
                maxLength={MAX_CELL_VALUE_CHARS}
                multi={false}
                inPlace={true}
            />
        );
        // Otherwise display non-contact info as TEXT_NUMBER field
    } else {
        // TODO: When implementing the new lodestar contact picker, check if this logic is still valid. Currently, due to a bug in the
        // HandleContact.getContactIfValid function, this condition is never met.
        return (
            <TextNumber
                key={`tni-${inputIndex}`}
                dataClientId={AutomationIds.FORM_FIELD_WRAPPER_TEXT}
                value={copyOfValue as string}
                disabled={true}
                maxLength={MAX_CELL_VALUE_CHARS}
                onClearContent={onClearContent}
            />
        );
    }
};

export default FormFieldContactList;
