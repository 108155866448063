import { ContactObjectValue, FormFieldInterface } from '../../../interfaces';
import { isMultiContactObjectValue } from '../../../utils';
import { FuncTransformForSmarUpsert } from './FuncTransformForSmarUpsert';
import { ISmarCell } from './ISmarCell';

export const transformContactListForSmarUpsert: FuncTransformForSmarUpsert = (rowField: FormFieldInterface): ISmarCell => {
    const cell = {
        columnId: rowField.columnId,
        value: undefined,
        objectValue: rowField.objectValue,
        strict: false,
    };

    if (isMultiContactObjectValue(cell.objectValue)) {
        cell.objectValue.values.filter((value: ContactObjectValue) => value.imageId).forEach((value: ContactObjectValue) => delete value.imageId);
    }

    return cell;
};
