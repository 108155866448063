import { IToggleIcon } from '../../../common/interfaces/IToggleIcon.interface';
import { SyntheticEvent } from 'react';
import * as React from 'react';
import iconWrapText from '../../../assets/images/wrapText/wrap-text.svg';
import { AutomationIds, AutomationTypes } from '../../../common/enums/AutomationElements.enum';
import { Button } from '../../../components/Buttons';
import '../../../components/Toggle/Toggle.css';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';

interface Props {
    onClick: (e: SyntheticEvent) => void;
    wrapText: boolean;
}

const ToggleWrapText: React.FC<Props> = (props) => {
    const languageElements = useLanguageElements();
    const wrapTextIcon: IToggleIcon = {
        src: iconWrapText,
        alt: languageElements.VIEW_WRAP_ALT_TEXT,
        tooltip: languageElements.VIEW_WRAP_TEXT,
    };
    const classes = `toggle-button ${props.wrapText ? 'selected' : ''}`;
    return (
        <Button
            dataClientId={AutomationIds.VIEW_WRAP_TEXT}
            dataClientType={AutomationTypes.TOGGLE_ICON_CONTAINER}
            icon={wrapTextIcon.src}
            className={classes}
            onClick={props.onClick}
            id={'row-height-option'}
            tooltip={wrapTextIcon.tooltip}
            imageAltText={wrapTextIcon.alt}
        />
    );
};

export default ToggleWrapText;
