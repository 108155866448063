import { HandleContacts } from '../../../common/classes';
import { CURRENT_USER_DEFAULT_EMAIL, CURRENT_USER_DEFAULT_NAME } from '../../../common/constants';
import { ColumnType, SymbolSetName } from '../../../common/enums';
import { Contact, FormFieldInterface, PicklistItem } from '../../../common/interfaces';
import { MultiPicklistObjectValue } from '../../../common/interfaces';
import { getObjectValueFromDefaultValue } from '../../../common/utils';
import * as React from 'react';
import { AutomationIds } from '../../../common/enums/AutomationElements.enum';
import { getSelectedItem, mapToMultiPicklistOptions } from '../../../common/utils/GetOptions';
import DraggableItem from '../../../components/DragAndDrop/DraggableItem';
import FormFieldWrapper from '../../../components/FormFieldWrapper';
import { WithDataClientProps } from '../../../components/hoc/WithDataClient';
import './IncludedFormField.css';

interface Props extends WithDataClientProps {
    model: FormFieldInterface;
    index: number;
    readOnly?: boolean;
    hide?: boolean;
    onRemoveEvent: () => void;
    itemClick: any;
}

const IncludedFormField: React.FC<Props> = (props) => {
    const handleContacts = new HandleContacts({ email: CURRENT_USER_DEFAULT_EMAIL, name: CURRENT_USER_DEFAULT_NAME });
    const handleRemoveClick = (e: React.MouseEvent<HTMLElement>): void => {
        props.onRemoveEvent();
        e.stopPropagation();
    };
    let defaultValue: string | Contact | Contact[] | undefined;
    const defaultObjectValue = getObjectValueFromDefaultValue(
        props.model.defaultValue,
        props.model.type!,
        CURRENT_USER_DEFAULT_EMAIL,
        CURRENT_USER_DEFAULT_NAME,
        true
    );
    /**
     * Add blank placeholder text for contact lists and picklists in the details panel layout
     */
    switch (props.model.type) {
        /**
         * If defaultValue is a string, try to create a contact object.
         * If defaultValue is an array, concatenate the emails into a string. This happens if
         * the column was previously a multi-contact column and default values were set up for it.
         * (the concat. field will be displayed as read-only)
         */
        case ColumnType.CONTACT_LIST:
            props.model.placeholder = '';

            // If value is a string email, create Contact object from it (otherwise return the string )
            // If value is a contact [], create concatenated string of Contacts'
            defaultValue = handleContacts.getContactFromCurrentUserEmailIfValid(defaultObjectValue);
            break;

        /**
         * If defaultValue is a string email, try to create a contact array with one element.
         * This is needed when a default value is set for a single contact, and then the Smartsheet column is
         * changed to multi-contact. We want to try to display the default correctly even though it's now multi-contact.
         */
        case ColumnType.MULTI_CONTACT_LIST:
            props.model.placeholder = '';

            // If initialValue is a string email, create array of 1 Contact object from it (otherwise return the string )
            // If initialValue is an objectValue, return contact array from objectValue.values
            defaultValue = handleContacts.getMultiContactsIfValid(defaultObjectValue, undefined, false) as Contact[] | string | undefined;
            break;

        case ColumnType.PICKLIST:
            props.model.placeholder = '';
            props.model.selectedPicklistOption = getSelectedItem(
                props.model.options,
                props.model.symbol as SymbolSetName,
                defaultObjectValue as string
            ) as PicklistItem;
            defaultValue = defaultObjectValue as string;
            break;

        case ColumnType.MULTI_PICKLIST:
            props.model.placeholder = '';
            const defValue = defaultObjectValue as MultiPicklistObjectValue;
            const selectedOpts = defValue ? defValue.values : undefined;
            props.model.selectedMultiSelectOptions = mapToMultiPicklistOptions(selectedOpts);
            if (props.model.selectedMultiSelectOptions && props.model.selectedMultiSelectOptions.length === 1) {
                defaultValue = defaultObjectValue as MultiPicklistObjectValue;
            }
            break;

        default:
    }

    // If nothing yet is set to defaultValue from switch cases, use props.model.defaultValue
    // Intentionally not setting this at top because sometimes the defaultValue is an array object. If we set it
    // at the top (to an array obj) and then redefine it in the switch, that would unintentionally change the prop byRef.
    defaultValue = defaultValue != null ? defaultValue : props.model.defaultValue;
    return (
        <DraggableItem
            model={{
                columnId: props.model.columnId,
                type: props.model.type!,
                title: props.model.title!,
                ordinal: props.model.ordinal,
            }}
            draggableKey={`included-${props.model.columnId}`}
            style={{}}
            dataClientType={props.dataClientType}
            className={`edit-item ${props.model.type || ''}${props.model.selected ? ' selected' : ''}${props.model.hidden ? ' hide' : ''}`}
            itemClick={props.itemClick}
        >
            <div className="drag-handle" data-client-id={AutomationIds.AVAILABLE_FORM_FIELDS_DRAG_HANDLE} />
            <FormFieldWrapper
                value={defaultValue}
                multiline={props.model.multiline}
                readOnly={props.readOnly}
                columnId={props.model.columnId}
                inputIndex={props.index}
                isSettingsMode={true}
                isNewSubmission={true}
                type={props.model.type}
                placeholder={props.model.placeholder}
                title={props.model.title}
                required={props.model.required}
                format={props.model.format}
                options={props.model.options}
                symbol={props.model.symbol}
                description={props.model.description}
                customLabel={props.model.customLabel}
                displayValue={props.model.displayValue}
                systemColumnType={props.model.systemColumnType}
            />
            <span
                className="item-icon icon-trash float-right"
                onClick={(e: React.MouseEvent<HTMLElement>) => handleRemoveClick(e)}
                data-client-id={AutomationIds.INCLUDED_FORM_FIELD_DELETE}
            />
            <div className="clearfix" />
        </DraggableItem>
    );
};

export default IncludedFormField;
