import * as React from 'react';
import { ShareError } from '../../../common/interfaces';
import { Banner, BannerActions, BannerContent, BannerDismissButton } from '@smartsheet/lodestar-core';
import styled from '@emotion/styled';
import { Actions } from '../Actions';
import { useDispatch } from 'react-redux';

interface ErrorBannerProps {
    errors?: ShareError[];
}

const WidthContainer = styled.div({
    width: '550px',
    margin: '0 auto',
});

export const SharingErrorBanner = ({ errors }: ErrorBannerProps) => {
    const dispatch = useDispatch();
    const message = errors?.[0]?.msg
        ? "Some users, groups or domains weren't able to be saved and were removed. Please contact a System Admin for more information."
        : undefined;
    if (message) {
        return (
            <WidthContainer>
                <Banner type="warning">
                    <BannerContent>{message}</BannerContent>
                    <BannerActions>
                        <BannerDismissButton
                            onClick={() => {
                                // dismiss and reset errors
                                dispatch(Actions.storeShareErrors([]));
                            }}
                            aria-label="close share error banner"
                        />
                    </BannerActions>
                </Banner>
            </WidthContainer>
        );
    } else {
        return <></>;
    }
};
