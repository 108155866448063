import { AsyncStatus } from '../../common/enums';
import { HomeView } from '../../common/interfaces';
import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { AutomationIds } from '../../common/enums/AutomationElements.enum';
import { HomeFilter } from '../../common/enums/HomeFilter';
import { BaseComponent } from '../../components/Base';
import LandingPage from '../../components/LandingPage';
import withSetAppActionInProgress, { WithSetAppActionInProgressProps } from '../../components/hoc/WithSetAppActionInProgress';
import withSetAppError, { WithSetAppErrorProps } from '../../components/hoc/WithSetAppError';
import { LanguageElementsProp, withLanguageElementsHOC } from '../../language-elements/withLanguageElementsHOC';
import { ActionByType } from '../../store';
import { HomeSourceFilter } from '../App/Reducers';
import { homeFilterSelector, homeSourceFilterSelector } from '../App/Selectors';
import { ActionTypes, Actions } from './Actions';
import './Home.css';
import HomeHeader from './HomeHeader';
import HomeTable from './HomeTable';
import { searchAndFilterViews } from './HomeUtils';
import { homeViewStatusSelector, homeViews } from './Selectors';

interface OwnProps {
    userIsLicensed: boolean;
}

interface StateProps {
    views: HomeView[];
    viewStatus: AsyncStatus;
    viewFilter: HomeFilter;
    viewSourceFilter: HomeSourceFilter | undefined;
}

interface DispatchProps {
    fetchHomeData: () => ActionByType<Actions, ActionTypes.FETCH_HOME_DATA>;
}

export type HomeProps = OwnProps & StateProps & DispatchProps & WithSetAppActionInProgressProps & WithSetAppErrorProps & LanguageElementsProp;

interface HomeState {
    views: HomeView[];
    searchString: string;
}

export class Home extends BaseComponent<HomeProps, HomeState> {
    public constructor(props: HomeProps) {
        super(props);
        this.state = {
            views: searchAndFilterViews(props.views, props.viewFilter, props.viewSourceFilter, '', this.props.languageElements),
            searchString: '',
        };
    }

    public render(): React.ReactNode {
        let content = null;

        switch (this.props.viewStatus) {
            case AsyncStatus.ERROR:
                content = <LandingPage title={this.props.languageElements.HOME_ERROR_MESSAGE} message={''} />;
                break;

            case AsyncStatus.DONE:
                content = this.getHomeTable();
                break;

            case AsyncStatus.IN_PROGRESS:
            case AsyncStatus.NOT_STARTED:
                break;
        }

        return (
            <div className={'home'} data-client-id={AutomationIds.HOME_LIST}>
                {content}
            </div>
        );
    }

    public componentDidMount(): void {
        if (this.props.viewStatus === AsyncStatus.NOT_STARTED) {
            this.props.fetchHomeData();
        }

        if (this.props.viewStatus === AsyncStatus.IN_PROGRESS) {
            this.props.onSetAppStageActionInProgress(this.props.languageElements.SPINNER_LOADING_LABEL);
        }
    }

    public componentDidUpdate(prevProps: Readonly<HomeProps>, prevState: Readonly<HomeState>): void {
        if (
            prevProps.viewFilter !== this.props.viewFilter ||
            prevState.searchString !== this.state.searchString ||
            prevProps.views !== this.props.views ||
            prevProps.viewSourceFilter !== this.props.viewSourceFilter
        ) {
            this.setState({
                views: searchAndFilterViews(
                    this.props.views,
                    this.props.viewFilter,
                    this.props.viewSourceFilter,
                    this.state.searchString,
                    this.props.languageElements
                ),
            });
        }

        if (prevProps.views && prevProps.viewStatus === this.props.viewStatus) {
            return;
        }

        if (this.props.viewStatus === AsyncStatus.DONE || this.props.viewStatus === AsyncStatus.ERROR) {
            this.props.onResetAppStage();
        }
    }

    private onSearchChange = (searchString: string) => this.setState({ searchString });

    private getHomeTable = () => {
        const invalidSearch = !!this.state.searchString && !this.state.views.length;
        return (
            <>
                <HomeHeader
                    filter={this.props.viewFilter}
                    showSubtitle={this.state.views.length > 0}
                    userIsLicensed={this.props.userIsLicensed}
                    onSearchChange={this.onSearchChange}
                    searchValue={this.state.searchString}
                    invalidSearch={invalidSearch}
                />
                <HomeTable
                    filter={this.props.viewFilter}
                    views={this.state.views}
                    userIsLicensed={this.props.userIsLicensed}
                    invalidSearch={invalidSearch}
                    searchValue={this.state.searchString}
                    setSearchValue={this.onSearchChange}
                />
            </>
        );
    };
}

const mapState = createStructuredSelector({
    views: homeViews,
    viewStatus: homeViewStatusSelector,
    viewFilter: homeFilterSelector,
    viewSourceFilter: homeSourceFilterSelector,
});

const mapDispatch: DispatchProps = {
    fetchHomeData: Actions.fetchHomeData,
};

export default withLanguageElementsHOC(withSetAppActionInProgress(withSetAppError(connect<StateProps, DispatchProps>(mapState, mapDispatch)(Home))));
