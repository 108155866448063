import styled from '@emotion/styled';
import { Banner, BannerContent, colors, SelectV2, sizes } from '@smartsheet/lodestar-core';
import { BaseOptionType, FormatSelectValue, OptionSpreadProps } from '@smartsheet/lodestar-core/dist/esm/components/selectV2/types';
import { FALLBACK_I18N_LOCALE } from '../../../common/enums';
import { SelectItem, SheetFilter, SheetFilterType } from '../../../common/interfaces';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Option } from 'react-select';
import { dynamicSort } from '../../../common/utils/DynamicSort';
import { WithDataClientProps } from '../../../components/hoc/WithDataClient';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';
import { shortLocaleSelector } from '../../Auth/Selectors';
import SectionTitle from '../SectionTitle';
import './BasicSheetFilter.css';

export const NO_RESTRICTION_SHEET_FILTER_VALUE = '0';

export interface BasicSheetFilterProps extends WithDataClientProps {
    sheetFilters: SheetFilter[] | undefined;
    selectedOption?: string;
    onSheetFilterChange: (option: Option<string>) => void;
    sheetFilterDeleted: boolean;
}

const BasicSheetFilter = (props: BasicSheetFilterProps) => {
    const languageElements = useLanguageElements();
    const locale = useSelector(shortLocaleSelector) ?? FALLBACK_I18N_LOCALE;
    const noRestrictionOption = { label: languageElements.ADMIN_PANEL_BASIC_SHEET_FILTER_NO_RESTRICTION, value: NO_RESTRICTION_SHEET_FILTER_VALUE };
    const defaultSelectItem = { value: '', label: '', subLabel: '', image: '' };

    // Convert the option list from SheetFilter type to the SelectItem type
    // Also, filter out personal filters from options list
    const mapSheetFiltersToOptions = (sheetFilters?: SheetFilter[]): SelectItem[] => {
        const opts = [noRestrictionOption];
        if (sheetFilters) {
            opts.push(
                ...sheetFilters
                    .filter((sheetFilter) => sheetFilter.filterType !== SheetFilterType.PERSONAL)
                    .map((sheetFilter) => convertItem(sheetFilter))
                    .sort(dynamicSort('label'))
            );
        }
        return opts;
    };

    const convertItem = (filter: SheetFilter): SelectItem => {
        return {
            label: filter.name || languageElements.UNNAMED_FILTER,
            value: filter.id || '',
            image: '',
        };
    };

    const handleGetOptionKey = (option: SelectItem) => {
        return option.label;
    };

    const FormattedOptionComponent = (
        option: SelectItem,
        index: number,
        highlightedIndex: number,
        optionSpreadProps: OptionSpreadProps
    ): React.ReactElement => {
        const isHighlighted = index === highlightedIndex;
        return (
            <StyledSelectImageMenu {...optionSpreadProps} isSelected={isHighlighted}>
                {option.image && (
                    <SelectImage>
                        <img src={option.image} alt={option.label} />
                    </SelectImage>
                )}
                <SelectLabel>{option.label}</SelectLabel>
            </StyledSelectImageMenu>
        );
    };

    const CustomValueComponent: FormatSelectValue<BaseOptionType> = () => {
        return selectedOption ? (
            <StyledSelectImageValue isSelected={false}>
                {selectedOption.image && (
                    <SelectImage>
                        <img src={selectedOption.image} alt={selectedOption.label} />
                    </SelectImage>
                )}
                <SelectLabel>{selectedOption.label}</SelectLabel>
            </StyledSelectImageValue>
        ) : (
            <div />
        );
    };

    const options = mapSheetFiltersToOptions(props.sheetFilters);
    if (props.selectedOption === '0') {
        defaultSelectItem.label = languageElements.ADMIN_PANEL_BASIC_SHEET_FILTER_NO_RESTRICTION;
    }
    const selectFilter = props.sheetFilters?.find((filter) => filter.id === props.selectedOption);
    const selectedOption = !selectFilter ? defaultSelectItem : convertItem(selectFilter);

    return (
        <div className="basic-sheet-filter">
            <SectionTitle
                title={languageElements.ADMIN_PANEL_BASIC_TAB_SELECT_SHEET_FILTER_HEADER}
                subtitle={languageElements.ADMIN_PANEL_BASIC_TAB_SELECT_SHEET_FILTER_BODY}
            />
            <div className={`${props.sheetFilterDeleted ? 'warn ' : ''}darken-placeholder`}>
                <div>
                    <SelectV2
                        clientId={props.dataClientId}
                        placeholder={languageElements.ADMIN_PANEL_BASIC_TAB_SELECT_SHEET_FILTER_WARN_PLACEHOLDER}
                        getOptionKey={handleGetOptionKey}
                        options={options}
                        selectedOption={selectedOption || defaultSelectItem}
                        onChange={props.onSheetFilterChange}
                        formatSelectedValue={CustomValueComponent}
                        formatOption={FormattedOptionComponent}
                        tempFormatSelectedValueWrapperFix
                        locale={locale}
                        isClearable={false}
                        isSearchable
                        highlightFirstOptionAutomatically
                    />
                    {props.sheetFilterDeleted && (
                        <Banner type={'warning'} dataClientId={`warning-${props.dataClientId || ''}`}>
                            <BannerContent>{languageElements.ADMIN_PANEL_BASIC_TAB_SELECT_SHEET_FILTER_WARN_OWNER}</BannerContent>
                        </Banner>
                    )}
                    {!props.sheetFilterDeleted && selectFilter?.filterType === SheetFilterType.PERSONAL && (
                        <Banner type={'warning'} dataClientId={`warning-${props.dataClientId || ''}`}>
                            <BannerContent>
                                {languageElements.ADMIN_PANEL_BASIC_TAB_SELECT_SHEET_FILTER_WARN_PRIVATE(selectFilter.name)}
                            </BannerContent>
                        </Banner>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BasicSheetFilter;

const StyledSelectImageMenu = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${sizes.small}px;
    &:hover {
        background-color: ${colors.neutralLight20};
    }
    ${(props: { isSelected: boolean }) => props.isSelected && `background-color: ${colors.neutralLight20};`}
`;

const StyledSelectImageValue = styled(StyledSelectImageMenu)`
    padding-left: 0;
    background-color: ${colors.neutralLight40};
    &:hover {
        background-color: ${colors.neutralLight40};
    }
    ${(props: { isSelected: boolean }) => props.isSelected && `background-color: ${colors.neutralLight40};`}
`;

const SelectImage = styled.div`
    padding-right: ${sizes.small}px;
`;

const SelectLabel = styled.div`
    text-align: center;
    font-size: 13px;
`;
