/* eslint react/jsx-no-target-blank:"off" */
import { Bulletin } from '../../common/interfaces';
import * as React from 'react';
import alert from '../../assets/images/alert/alert.svg';
import './SystemBulletin.css';

export interface SystemBulletinProps {
    bulletin: Bulletin;
    onDisplay: () => void;
    onClose: () => void;
}

export class SystemBulletin extends React.Component<SystemBulletinProps, unknown> {
    public componentDidMount(): void {
        this.props.onDisplay();
    }

    public componentDidUpdate(prevProps: Readonly<SystemBulletinProps>, prevState: Readonly<unknown>, snapshot?: any): void {
        if (prevProps.bulletin !== this.props.bulletin) {
            this.props.onDisplay();
        }
    }

    public render(): React.ReactNode {
        const { bulletin } = this.props;
        const link =
            bulletin.linkUrl && bulletin.linkText ? (
                <a href={bulletin.linkUrl} target="_blank">
                    {bulletin.linkText}
                </a>
            ) : null;
        return (
            <div className="clsBulletinBar">
                <span>
                    <img className="warn-message-icon" src={alert} alt="warning" />
                    <span>
                        {bulletin.message} {link}
                    </span>
                </span>
                <span className="clsBulletinBarClose" onClick={this.closeBulletin} />
            </div>
        );
    }

    private closeBulletin = (): void => {
        this.props.onClose();
    };
}
