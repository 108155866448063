import styled from '@emotion/styled';
import { sizes } from '@smartsheet/lodestar-core';
import * as classNames from 'classnames';
import * as React from 'react';
import { SortDirection, TableHeaderProps } from 'react-virtualized';
import sortDown from '../../assets/images/icons/sort-down.svg';
import { LanguageElementsProp } from '../../language-elements/withLanguageElementsHOC';

const AdminControlsTableHeaderContainer = ({ languageElements }: LanguageElementsProp) =>
    function AdminControlsTableHeader({ dataKey, label = '', sortBy, sortDirection }: TableHeaderProps) {
        const sortIndicatorClassNames = classNames('ReactVirtualized__Table__sortableHeaderIcon', 'sort-down', {
            'ReactVirtualized__Table__sortableHeaderIcon--ASC': sortDirection === SortDirection.ASC,
            'ReactVirtualized__Table__sortableHeaderIcon--DESC': sortDirection === SortDirection.DESC,
        });

        return (
            <HeaderContainer>
                <span
                    className="ReactVirtualized__Table__headerTruncatedText"
                    key={label ? label.toString() : ''}
                    title={typeof label === 'string' ? label : ''}
                >
                    {label}
                </span>

                {sortBy === dataKey && (
                    <img src={sortDown} className={sortIndicatorClassNames} alt={languageElements.ADMIN_CONTROLS_HEADER_SORT_ICON_ALT} />
                )}
            </HeaderContainer>
        );
    };

const HeaderContainer = styled.div`
    position: relative;
    width: 100%;

    &&& {
        .ReactVirtualized__Table__sortableHeaderIcon {
            position: relative;
            right: 0;
            left: ${sizes.xxSmall}px;
        }

        .ReactVirtualized__Table__sortableHeaderIcon--DESC {
            transform: rotate(180deg);
        }
    }
`;

export default AdminControlsTableHeaderContainer;
