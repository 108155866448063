import { ReactNode } from 'react';
import * as React from 'react';

export interface Props {
    className: string;
    columns: ReactNode[];
    style: object;
}
const ControlId = {
    VIEW_TABLE_HEADER: 'hrr-1',
};

const GridHeaderRow: React.SFC<Props> = props => (
    <div
        className={props.className}
        role="row"
        style={props.style}
        data-client-id={ControlId.VIEW_TABLE_HEADER}
    >
        {props.columns}
    </div>
);

export default GridHeaderRow;
