// Adapted from app-core

/**
 * The basic button component. Invokes callbacks for single and double clicking, as well as
 * hovering in and out.
 */

import { MouseEvent } from 'react';
import * as React from 'react';
import { PureBaseComponent } from '../../../../components/Base';

export interface ButtonProps {
    autoFocus?: boolean;
    children?: React.ReactNode;
    innerRef?: (instance: HTMLButtonElement | null) => void;
    id?: string;
    className?: string | string[];
    isEnabled?: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>;
    onMouseLeave?: React.MouseEventHandler<HTMLButtonElement>;
    onMouseDown?: React.MouseEventHandler<HTMLButtonElement>;
    onMouseUp?: React.MouseEventHandler<HTMLButtonElement>;
    controlId?: string;
    controlType?: string;
}

interface ButtonState {
    focusedWithMouse: boolean;
}

export class FilterButton extends PureBaseComponent<ButtonProps, ButtonState> {
    // Boolean to determine if a mouse click triggered the button's focus handler
    private clickedWithMouse: boolean;
    private buttonRef: HTMLButtonElement | null;

    public constructor(props: ButtonProps) {
        super(props);
        this.state = { focusedWithMouse: false };
    }

    public focus(): void {
        if (this.buttonRef) {
            this.buttonRef.focus();
        }
    }

    /**
     * Browsers will automatically focus <button> elements when they are clicked, for accessibility. That's great,
     * but unfortunately in many cases it creates an awkward user experience when it is unexpected/distracting for
     * the user.
     *
     * So instead, we will focus the <button> element, but don't style it as if it were focused until the user uses
     * the keyboard to move around. To do this, we'll detect when the button was focused with a click and add the class
     * `focusedWithMouse` that we can use to style the button appropriately.
     */
    private handleMouseDown = (evt: MouseEvent<HTMLButtonElement>): void => {
        // Remember that the button was clicked with the mouse, which will be used in the focus handler
        // Timeout is set to 100ms just to be sure that this gets to the focus handler, given that react uses synthetic
        // events (which might change their timings at some point)
        this.clickedWithMouse = true;
        window.setTimeout(() => {
            this.clickedWithMouse = false;
        }, 100);

        // Invoke the onMouseDown prop, if given
        if (this.props.onMouseDown) {
            this.props.onMouseDown(evt);
        }
    }

    private handleFocus = (): void => {
        if (this.clickedWithMouse) {
            this.setState({
                focusedWithMouse: true,
            });
        }
    }

    private handleBlur = (): void => {
        if (this.state.focusedWithMouse) {
            this.setState({
                focusedWithMouse: false,
            });
        }
    }

    private handleClick = (evt: MouseEvent<HTMLButtonElement>): void => {
        const {
            onClick,
        } = this.props;

        if (onClick) {
            onClick(evt);
        }
    }

    private attachRef = (c: HTMLButtonElement | null): void => {
        const { innerRef } = this.props;
        if (innerRef) {
            innerRef(c);
        }
        this.buttonRef = c;
    }

    public render(): React.ReactNode {
        const {
            autoFocus,
            controlId,
            controlType,
            id,
            isEnabled = true,
            onMouseEnter,
            onMouseLeave,
            onMouseUp,
            children,
        } = this.props;

        return (
            <button
                autoFocus={autoFocus}
                className={'button'}
                disabled={!isEnabled}
                onClick={this.handleClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onMouseDown={this.handleMouseDown}
                onMouseUp={onMouseUp}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                data-client-type={controlType}
                data-client-id={controlId}
                id={id}
                ref={this.attachRef}
            >
                {children}
            </button>
        );
    }
}
