import { datadogRum } from '@datadog/browser-rum';
import { Locale } from '../enums';
import { DateFnsFormat } from '../enums/DateFnsFormat.enum';

export interface DateFnsFormatsByLocale {
    [propName: string]: DateFnsFormat;
}

export const getDefaultDateFnsFormatForLocale = (locale: string = Locale.EN_US) => {
    if (!defaultDateFnsFormats.hasOwnProperty(locale)) {
        datadogRum.addAction(`Dynamic View has no date-fns format for ${locale}. Using date-fns format for ${Locale.EN_US}.`);
    }
    return defaultDateFnsFormats[locale] || defaultDateFnsFormats[Locale.EN_US];
};

const defaultDateFnsFormats: DateFnsFormatsByLocale = {
    // English
    [Locale.EN_US]: DateFnsFormat.MM_DD_YY_SLASH, // United States
    [Locale.EN_AU]: DateFnsFormat.DD_MM_YY_SLASH, // Australia
    [Locale.EN_CA]: DateFnsFormat.DD_MM_YY_SLASH, // Canada
    [Locale.EN_IN]: DateFnsFormat.DD_MM_YY_SLASH, // India
    [Locale.EN_IE]: DateFnsFormat.DD_MM_YY_SLASH, // Ireland
    [Locale.EN_MT]: DateFnsFormat.DD_MM_YY_SLASH, // Malta
    [Locale.EN_NZ]: DateFnsFormat.DD_MM_YY_SLASH, // New Zealand
    [Locale.EN_PH]: DateFnsFormat.MM_DD_YY_SLASH, // Philippines
    [Locale.EN_SG]: DateFnsFormat.DD_MM_YY_SLASH, // Singapore
    [Locale.EN_ZA]: DateFnsFormat.YYYY_MM_DD_SLASH, // South Africa
    [Locale.EN_GB]: DateFnsFormat.DD_MM_YY_SLASH, // United Kingdom
    // Albanian
    [Locale.SQ_AL]: DateFnsFormat.YY_MM_DD_DASH, // Albania
    // Arabic
    [Locale.AR_DZ]: DateFnsFormat.DD_MM_YY_SLASH, // Algeria
    [Locale.AR_BH]: DateFnsFormat.DD_MM_YY_SLASH, // Bahrain
    [Locale.AR_EG]: DateFnsFormat.DD_MM_YY_SLASH, // Egypt
    [Locale.AR_IQ]: DateFnsFormat.DD_MM_YY_SLASH, // Iraq
    [Locale.AR_JO]: DateFnsFormat.DD_MM_YY_SLASH, // Jordan
    [Locale.AR_KW]: DateFnsFormat.DD_MM_YY_SLASH, // Kuwait
    [Locale.AR_LB]: DateFnsFormat.DD_MM_YY_SLASH, // Lebanon
    [Locale.AR_LY]: DateFnsFormat.DD_MM_YY_SLASH, // Libya
    [Locale.AR_MA]: DateFnsFormat.DD_MM_YY_SLASH, // Morocco
    [Locale.AR_OM]: DateFnsFormat.DD_MM_YY_SLASH, // Oman
    [Locale.AR_QA]: DateFnsFormat.DD_MM_YY_SLASH, // Qatar
    [Locale.AR_SA]: DateFnsFormat.DD_MM_YY_SLASH, // Saudi Arabia
    [Locale.AR_SD]: DateFnsFormat.DD_MM_YY_SLASH, // Sudan
    [Locale.AR_SY]: DateFnsFormat.DD_MM_YY_SLASH, // Syria
    [Locale.AR_TN]: DateFnsFormat.DD_MM_YY_SLASH, // Tunisia
    [Locale.AR_AE]: DateFnsFormat.DD_MM_YY_SLASH, // United Arab Emirates
    [Locale.AR_YE]: DateFnsFormat.DD_MM_YY_SLASH, // Yemen
    // Belarusian
    [Locale.BE_BY]: DateFnsFormat.DD_MM_YY_DOT, // Belarus
    // Bulgarian
    [Locale.BG_BG]: DateFnsFormat.DD_MM_YY_DOT, // Bulgaria
    // Catalan
    [Locale.CA_ES]: DateFnsFormat.DD_MM_YY_SLASH, // Spain
    // Chinese
    [Locale.ZH_CN]: DateFnsFormat.YY_MM_DD_DASH, // China
    [Locale.ZH_HK]: DateFnsFormat.YY_MM_DD_KANJI, // Hong Kong
    [Locale.ZH_SG]: DateFnsFormat.DD_MM_YY_SLASH, // Singapore
    [Locale.ZH_TW]: DateFnsFormat.YYYY_MM_DD_SLASH, // Taiwan
    // Croatian
    [Locale.HR_HR]: DateFnsFormat.DD_MM_YY_EXTRA_DOT, // Croatia
    // Czech
    [Locale.CS_CZ]: DateFnsFormat.DD_MM_YY_DOT, // Czech Republic
    // Danish
    [Locale.DA_DK]: DateFnsFormat.DD_MM_YY_DASH, // Denmark
    // German
    [Locale.DE_DE]: DateFnsFormat.DD_MM_YY_DOT, // Germany
    [Locale.DE_LU]: DateFnsFormat.DD_MM_YY_DOT, // Luxembourg
    [Locale.DE_AT]: DateFnsFormat.DD_MM_YY_DOT, // Austria
    [Locale.DE_CH]: DateFnsFormat.DD_MM_YY_DOT, // Switzerland
    // Dutch
    [Locale.NL_BE]: DateFnsFormat.DD_MM_YY_SLASH, // Belgium
    [Locale.NL_NL]: DateFnsFormat.DD_MM_YY_DASH, // Netherlands
    // Spanish
    [Locale.ES_AR]: DateFnsFormat.DD_MM_YY_SLASH, // Argentina
    [Locale.ES_BO]: DateFnsFormat.DD_MM_YY_DASH, // Bolivia
    [Locale.ES_CL]: DateFnsFormat.DD_MM_YY_DASH, // Chile
    [Locale.ES_CO]: DateFnsFormat.DD_MM_YY_SLASH, // Colombia
    [Locale.ES_CR]: DateFnsFormat.DD_MM_YY_SLASH, // Costa Rica
    [Locale.ES_EC]: DateFnsFormat.DD_MM_YY_SLASH, // Ecuador
    [Locale.ES_SV]: DateFnsFormat.MM_DD_YY_DASH, // El Salvador
    [Locale.ES_ES]: DateFnsFormat.DD_MM_YY_SLASH, // Spain
    [Locale.ES_US]: DateFnsFormat.MM_DD_YY_SLASH, // United States
    [Locale.ES_GT]: DateFnsFormat.DD_MM_YY_SLASH, // Guatemala
    [Locale.ES_HN]: DateFnsFormat.MM_DD_YY_DASH, // Honduras
    [Locale.ES_MX]: DateFnsFormat.DD_MM_YY_SLASH, // Mexico
    [Locale.ES_NI]: DateFnsFormat.MM_DD_YY_DASH, // Nicaragua
    [Locale.ES_PA]: DateFnsFormat.MM_DD_YY_SLASH, // Panama
    [Locale.ES_PY]: DateFnsFormat.DD_MM_YY_SLASH, // Paraguay
    [Locale.ES_PE]: DateFnsFormat.DD_MM_YY_SLASH, // Peru
    [Locale.ES_PR]: DateFnsFormat.MM_DD_YY_DASH, // Puerto Rico
    [Locale.ES_DO]: DateFnsFormat.DD_MM_YY_SLASH, // Dominican Republic
    [Locale.ES_UY]: DateFnsFormat.DD_MM_YY_SLASH, // Uruguay
    [Locale.ES_VE]: DateFnsFormat.DD_MM_YY_SLASH, // Venezuela
    // Estonian
    [Locale.ET_EE]: DateFnsFormat.DD_MM_YY_DOT, // Estonia
    // Finnish
    [Locale.FI_FI]: DateFnsFormat.DD_MM_YYYY_DOT, // Finland
    // French
    [Locale.FR_BE]: DateFnsFormat.DD_MM_YY_SLASH, // Belgium
    [Locale.FR_CA]: DateFnsFormat.YY_MM_DD_DASH, // Canada
    [Locale.FR_FR]: DateFnsFormat.DD_MM_YY_SLASH, // France
    [Locale.FR_LU]: DateFnsFormat.DD_MM_YY_SLASH, // Luxembourg
    [Locale.FR_CH]: DateFnsFormat.DD_MM_YY_DOT, // Switzerland
    // Greek
    [Locale.EL_CY]: DateFnsFormat.DD_MM_YYYY_SLASH, // Cyprus
    [Locale.EL_GR]: DateFnsFormat.DD_MM_YYYY_SLASH, // Greece
    // Hebrew
    [Locale.IW_IL]: DateFnsFormat.DD_MM_YY_SLASH, // Israel
    // Hindi
    [Locale.HI_US]: DateFnsFormat.DD_MM_YY_SLASH, // India
    // Hungarian
    [Locale.HU_HU]: DateFnsFormat.YYYY_MM_DD_EXTRA_DOT, // Hungary
    // Icelandic
    [Locale.IS_IS]: DateFnsFormat.DD_MM_YYYY_DOT, // Iceland
    // Indonesian
    [Locale.IN_ID]: DateFnsFormat.DD_MM_YY_SLASH, // Indonesia
    // Irish
    [Locale.GA_IE]: DateFnsFormat.DD_MM_YYYY_SLASH, // Ireland
    // Italian
    [Locale.IT_IT]: DateFnsFormat.DD_MM_YY_SLASH, // Italy
    [Locale.IT_CH]: DateFnsFormat.DD_MM_YY_DOT, // Switzerland
    // Korean
    [Locale.KO_KR]: DateFnsFormat.YY_MM_DD_DOT_SPACE, // South Korea
    // Latvian
    [Locale.LV_LV]: DateFnsFormat.YY_DD_MM_DOT, // Latvia
    // Lithuanian
    [Locale.LT_LT]: DateFnsFormat.YY_MM_DD_DOT, // Lithuania
    // Macedonian
    [Locale.MK_MK]: DateFnsFormat.DD_MM_YY_DOT, // Macedonia
    // Malay
    [Locale.MS_MY]: DateFnsFormat.DD_MM_YYYY_SLASH, // Malaysia
    // Maltese
    [Locale.MT_MT]: DateFnsFormat.DD_MM_YYYY_SLASH, // Malta
    // Norwegian
    [Locale.NO_NO]: DateFnsFormat.DD_MM_YY_DOT, // Norway
    // Polish
    [Locale.PL_PL]: DateFnsFormat.DD_MM_YY_DOT, // Poland
    // Portuguese
    [Locale.PT_BR]: DateFnsFormat.DD_MM_YY_SLASH, // Brazil
    [Locale.PT_PT]: DateFnsFormat.DD_MM_YYYY_DASH, // Portugal
    // Russian
    [Locale.RU_RU]: DateFnsFormat.DD_MM_YY_DOT, // Russia
    // Romanian
    [Locale.RO_RO]: DateFnsFormat.DD_MM_YYYY_DOT, // Romania
    // Serbian
    [Locale.SR_BA]: DateFnsFormat.YY_MM_DD_DASH, // Bosnian and Herzegovina
    [Locale.SR_CS]: DateFnsFormat.DD_MM_YY_EXTRA_DOT, // Serbia
    // Slovak
    [Locale.SK_SK]: DateFnsFormat.DD_MM_YYYY_DOT, // Slovakia
    // Slovenian
    [Locale.SL_SI]: DateFnsFormat.DD_MM_YYYY_DOT, // Slovenia
    // Swedish
    [Locale.SV_SE]: DateFnsFormat.YYYY_MM_DD_DASH, // Sweden
    // Thai
    [Locale.TH_US]: DateFnsFormat.DD_MM_YYYY_SLASH, // United States
    // Turkish
    [Locale.TR_TR]: DateFnsFormat.DD_MM_YYYY_DOT, // Turkey
    // Ukrainian
    [Locale.UK_UA]: DateFnsFormat.DD_MM_YY_DOT, // Ukraine
    // Vietnamese
    [Locale.VI_VN]: DateFnsFormat.DD_MM_YYYY_SLASH, // Vietnam
    // Japanese
    [Locale.JA_JP]: DateFnsFormat.YY_MM_DD_SLASH, // Japan
    // Fake, dev test only
    [Locale.ZZ_ZZ]: DateFnsFormat.DD_MM_YY_SLASH, // Psuedo-loc
};
