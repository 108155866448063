import { AxiosError } from 'axios';
import { createSelector } from 'reselect';
import { AsyncStatus } from '../../common/enums';
import * as dv from '../../common/interfaces';
import { AsyncResult, StoreState } from '../../store';
import { selectedRowIdSelector } from './Details/Selectors';
import { getRowSheetIdFromViewData } from './utils/ViewUtils';

const viewSelector = (state: StoreState) => state.view;

export const viewDataSelector = createSelector(viewSelector, (viewState) => viewState.viewData);
export const viewConfigSelector = createSelector(viewSelector, (viewState) => viewState.config);

export const viewIntakeSheetSelector = createSelector(viewConfigSelector, (viewConfig) => {
    if (viewConfig?.status === AsyncStatus.DONE || viewConfig?.status === AsyncStatus.PARTIAL) {
        return viewConfig.data.config?.intakeSheetId;
    }
    return;
});

export const rowSheetIdSelector = createSelector(
    viewDataSelector,
    (_: StoreState, rowId: string) => rowId,
    viewIntakeSheetSelector,
    (viewData, rowId, intakeSheetId) => getRowSheetIdFromViewData(viewData, rowId, intakeSheetId)
);

export const currentRowSheetIdSelector = createSelector(
    viewDataSelector,
    selectedRowIdSelector,
    viewIntakeSheetSelector,
    (viewData: AsyncResult<dv.ViewData, AxiosError>, selectedRowId, intakeSheetId) =>
        getRowSheetIdFromViewData(viewData, selectedRowId, intakeSheetId) ?? undefined
);
