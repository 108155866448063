import { DisplayField } from '../../common/interfaces';
import * as React from 'react';
import { AutomationIds } from '../../common/enums/AutomationElements.enum';
import { WithDataClientProps } from '../hoc/WithDataClient';
import DraggableItem from './DraggableItem';

interface ItemProps extends WithDataClientProps {
    itemModel: DisplayField;
}

const DndItem: React.SFC<ItemProps> = (props) => {
    const grid = 4;
    const style = {
        padding: grid,
        margin: `0 0 ${grid}px 0`,
    };

    return (
        <DraggableItem
            model={props.itemModel}
            draggableKey={`available-${props.itemModel.columnId}`}
            style={style}
            dataClientType={props.dataClientType}
            className="dnd-item truncate drag-item"
        >
            <span className="drag-handle" data-client-id={AutomationIds.DND_ITEM_HANDLE} />
            <span className="ordinal" data-client-id={AutomationIds.DND_ITEM_ORDINAL}>
                {props.itemModel!.ordinal! + 1}
            </span>
            <span className="text" data-client-id={AutomationIds.DND_ITEM_TITLE}>
                {props.itemModel.title}
            </span>
        </DraggableItem>
    );
};

export default DndItem;
