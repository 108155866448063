export const getUserInitialCssClass = (initials: string): string => {
    let sum = 0;
    if (initials) {
        for (const letter of initials) {
            sum += letter.charCodeAt(0);
        }
    }

    const calc = (sum % 20 + 1);
    return `color-${calc}`;
};
