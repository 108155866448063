import { WidthName } from './WidthName';
import { WidthSize } from './WidthSize';

export const getModalWidthName = (width: number) => {
    const widthPlusGutters = 32 + width + 32;

    if (widthPlusGutters <= WidthSize.EXTRA_SMALL) {
        return WidthName.EXTRA_SMALL;
    }

    if (widthPlusGutters <= WidthSize.SMALL) {
        return WidthName.SMALL;
    }

    if (widthPlusGutters <= WidthSize.MEDIUM) {
        return WidthName.MEDIUM;
    }

    if (widthPlusGutters <= WidthSize.LARGE) {
        return WidthName.LARGE;
    }

    if (widthPlusGutters <= WidthSize.EXTRA_LARGE) {
        return WidthName.EXTRA_LARGE;
    }

    return '90%';
};
