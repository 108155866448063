// Provide overloads where the return type can be inferred.
// If the consumer of this method passes in more than five arguments, then the return type is unknown,
// and it's up to the caller to explicitly set the type in their code.

/**
 * Returns the first argument that is not null or undefined.
 * This method provides similar functionality to the
 * [nullish coalescing operator (??)]{@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Nullish_coalescing_operator},
 * which we don't have yet thanks to our old TypeScript version.
 */
export function nullish<T, U>(a: T, b: U): T | U;

/**
 * Returns the first argument that is not null or undefined.
 * This method provides similar functionality to the
 * [nullish coalescing operator (??)]{@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Nullish_coalescing_operator},
 * which we don't have yet thanks to our old TypeScript version.
 */
export function nullish<T, U, V>(a: T, b: U, c: V): T | U | V;

/**
 * Returns the first argument that is not null or undefined.
 * This method provides similar functionality to the
 * [nullish coalescing operator (??)]{@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Nullish_coalescing_operator},
 * which we don't have yet thanks to our old TypeScript version.
 */
export function nullish<T, U, V, W>(a: T, b: U, c: V, d: W): T | U | V | W;

/**
 * Returns the first argument that is not null or undefined.
 * This method provides similar functionality to the
 * [nullish coalescing operator (??)]{@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Nullish_coalescing_operator},
 * which we don't have yet thanks to our old TypeScript version.
 */
export function nullish<T, U, V, W, X>(a: T, b: U, c: V, d: W, e: X): T | U | V | W | X;

/**
 * Returns the first argument that is not null or undefined.
 * This method provides similar functionality to the
 * [nullish coalescing operator (??)]{@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Nullish_coalescing_operator},
 * which we don't have yet thanks to our old TypeScript version.
 */
export function nullish<T, U>(a: T, b: U, ...values: unknown[]): unknown;

/**
 * Returns the first argument that is not null or undefined.
 * This method provides similar functionality to the
 * [nullish coalescing operator (??)]{@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Nullish_coalescing_operator},
 * which we don't have yet thanks to our old TypeScript version.
 */
export function nullish(a: any, b: any, ...values: any[]): any {
    if (a != null) {
        return a;
    }

    if (!values || values.length < 1) {
        return b;
    }

    const [c, ...rest] = values;
    return nullish(b, c, ...rest);
}
