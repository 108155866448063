import * as React from 'react';
import { TableRowProps } from 'react-virtualized';
import { AutomationTypes } from '../../../common/enums/AutomationElements.enum';
import { OwnershipTransferMouseEvent, OwnershipTransferRowData } from '../OwnershipTransferTable.interface';

interface Props extends TableRowProps {
    key: string;
    onRowClick: (event: OwnershipTransferMouseEvent) => void;
    onRowDoubleClick: (event: OwnershipTransferMouseEvent) => void;
    onRowMouseOut: (event: OwnershipTransferMouseEvent) => void;
    onRowMouseOver: (event: OwnershipTransferMouseEvent) => void;
    onRowRightClick: (event: OwnershipTransferMouseEvent) => void;
    ownershipTransferRowData: OwnershipTransferRowData;
    style: object;
    activeTabColumnWidths: number[];
}

interface RowProps {
    'aria-label': string;
    tabIndex: number;
    onClick?: (mouseEvent: React.MouseEvent<HTMLDivElement>) => void;
    onDoubleClick?: (mouseEvent: React.MouseEvent<HTMLDivElement>) => void;
    onMouseOut?: (mouseEvent: React.MouseEvent<HTMLDivElement>) => void;
    onMouseOver?: (mouseEvent: React.MouseEvent<HTMLDivElement>) => void;
    onContextMenu?: (mouseEvent: React.MouseEvent<HTMLDivElement>) => void;
}

const OwnershipTransferRowElement: React.SFC<Props> = props => {
    const rowProps: RowProps = {'aria-label': 'row', 'tabIndex': -1};

    return (
        <div
            {...rowProps}
            className="row-data"
            key={props.key}
            role="row"
            style={props.style}
            data-client-type={AutomationTypes.OWNERSHIP_TRANSFER_ROW}
        >
            {props.columns}
        </div>
    );
};

export default OwnershipTransferRowElement;
