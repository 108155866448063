import { collator } from '../../../common/utils/Collator';
import { MultiSelectItem } from '../../../components/MultiSelect/MultiSelect.interface';

/*
 * Returns a combined map of multi-select items needed for filtering on PICKLIST, CONTACT_LIST and CHECKBOX columns
 * The following is consolidated into 1 Map:
 * 1) 'allColumnOptionsMap' contains multi-select items based on the column.options or column.contactOptions data from SMAR
 * 2) 'columnCellMap' contains multi-select items based on unique cell data that does not match the column options
 */
export const createAllFilterItemsMap = (
    columnCellMap: Map<number, Map<string, MultiSelectItem>>,
    allColumnOptionsMap: Map<number, Map<string, MultiSelectItem>>
): Map<number, Map<string, MultiSelectItem>> => {
    // Initialize allFilterItemsMap with all of the column Options
    const allFilterItemsMap: Map<number, Map<string, MultiSelectItem>> = new Map(allColumnOptionsMap);
    // Then loop over the unique cell data for the column (which only includes the data that doesn't match the column.options)
    columnCellMap.forEach((columnCellValuesMap, columnId) => {
        const columnOptionsMap = allColumnOptionsMap.get(columnId);
        if (columnOptionsMap) {
            allFilterItemsMap.set(columnId, getCombinedOptionsMap(columnOptionsMap, columnCellValuesMap));
        }
    });

    return allFilterItemsMap;
};

const getCombinedOptionsMap = (
    columnOptionsMap: Map<string, MultiSelectItem>,
    columnCellValuesMap: Map<string, MultiSelectItem>
): Map<string, MultiSelectItem> => {
    // Use Intl.collator utility to sort cell data
    const columnCellValues = Array.from(columnCellValuesMap.values());

    columnCellValues.sort((a, b) => collator.compare(a.value, b.value));

    // Initialize filterMap based on contents of columnOptionsMap
    const filterItemsMap: Map<string, MultiSelectItem> = new Map();
    columnOptionsMap.forEach((multiSelectItem, key) => {
        filterItemsMap.set(key, multiSelectItem);
    });

    // Then add other info from the columnCellValuesMap
    // Note that keys in the columnOptionsMap and columnCellValuesMap are distinct, so we don't have to check if key is in columnOptionsMap
    columnCellValues.forEach((columnCellValue) => {
        filterItemsMap.set(columnCellValue.value, columnCellValue);
    });

    return filterItemsMap;
};
