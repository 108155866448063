import { OwnershipTransferStatus } from '../enums';
import { IOwnershipTransfer } from '../interfaces';

export function isInProgressOrPending(status: OwnershipTransferStatus): boolean {
    return (
        status === OwnershipTransferStatus.PENDING ||
        status === OwnershipTransferStatus.ACCEPT_IN_PROGRESS ||
        status === OwnershipTransferStatus.REJECT_IN_PROGRESS ||
        status === OwnershipTransferStatus.CANCEL_IN_PROGRESS
    );
}

export const getPendingReceivedTransfers = (pendingTransfers: IOwnershipTransfer[], currentUserEmail: string): IOwnershipTransfer[] =>
    pendingTransfers.filter((transfer: IOwnershipTransfer) => currentUserEmail === transfer.newOwner.email && isInProgressOrPending(transfer.status));
