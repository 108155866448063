/* eslint max-len:"off" */
import { SUPPORTED_I18N_LOCALES } from '../common/enums';
import i18n from '../i18n';

export class LanguageElements {
    public readonly ADHOC_FILTER_CHECKED = i18n.t('Checked');
    public readonly ADHOC_FILTER_COPY_OF = (filterName: string) => i18n.t('Copy of {{filterName}}', { filterName });
    public readonly ADHOC_FILTER_CRITERIA_OPERATOR_CHECKED = i18n.t('is checked');
    public readonly ADHOC_FILTER_CRITERIA_OPERATOR_IS_NOT_ONE_OF_OPTION = i18n.t('is not one of');
    public readonly ADHOC_FILTER_CRITERIA_OPERATOR_IS_ONE_OF_OPTION = i18n.t('is one of');
    public readonly ADHOC_FILTER_CRITERIA_OPERATOR_UNCHECKED = i18n.t('is not checked');
    public readonly ADHOC_FILTER_DEFAULT_NAME = i18n.t('Unnamed filter');
    public readonly ADHOC_FILTER_DELETED_COLUMN_PROMPT = i18n.t('The column was deleted. Please select another column.');
    public readonly ADHOC_FILTER_INVALID_COLUMN_TYPE_PROMPT = i18n.t('Please select a column.');
    public readonly ADHOC_FILTER_INVALID_FILTER_OPERATOR_PROMPT = i18n.t('Please select an operator.');
    public readonly ADHOC_FILTER_INVALID_FILTER_VALUE_PROMPT = i18n.t('Please enter a value.');
    public readonly ADHOC_FILTER_INVALID_FILTER_DATE_VALUE_PROMPT = i18n.t('This is not a valid date.');
    public readonly ADHOC_FILTER_NAME_PROMPT = i18n.t('Name ');
    public readonly ADHOC_FILTER_NEW = i18n.t('New Filter...');
    public readonly ADHOC_FILTER_OFF = i18n.t('Filter Off');
    public readonly ADHOC_FILTER_OPTIONAL = i18n.t('(Optional)');
    public readonly ADHOC_FILTER_SELECT_FIELD_PLACEHOLDER = i18n.t('Select field');
    public readonly ADHOC_FILTER_SELECT_OPERATOR_PLACEHOLDER = i18n.t('Select');
    public readonly ADHOC_FILTER_SHARE = i18n.t('Share Filter');
    public readonly ADHOC_FILTER_DELETE = i18n.t('Delete Filter');
    public readonly ADHOC_FILTER_UNCHECKED = i18n.t('Unchecked');
    public readonly ADMIN_PANEL_BASIC_NEW_DESTINATION_ERROR = i18n.t(`The previously selected sheet is no longer valid.
        Please select another sheet or choose 'Do Not Allow'.`);
    public readonly ADMIN_PANEL_BASIC_NEW_DESTINATION_NO_SOURCE_SHEETS = i18n.t(
        'No valid source sheet exists. Please visit Smartsheet to resolve...'
    );
    public readonly ADMIN_PANEL_BASIC_NEW_DESTINATION_PLACEHOLDER_ERROR = i18n.t('Please select another sheet...');
    public readonly ADMIN_PANEL_BASIC_SHEET_FILTER_NO_RESTRICTION = i18n.t('No filter - all rows visible to everyone shared');
    public readonly ADMIN_PANEL_BASIC_TAB_KEY = 'basic'; // DO NOT TRANSLATE -- URL parameter needs to match
    public readonly ADMIN_PANEL_BASIC_TAB_SELECT_SHEET_FILTER_BODY_OWNER =
        i18n.t(`Select an existing sheet filter to automatically restrict what data will appear in the view. 
        Only the view owner can change this filter.`);
    public readonly ADMIN_PANEL_BASIC_TAB_SELECT_SHEET_FILTER_BODY = i18n.t(
        `Select a shared sheet filter to restrict the data that appears in the view.`
    );
    public readonly ADMIN_PANEL_BASIC_TAB_SELECT_SHEET_FILTER_HEADER = i18n.t('Restrict view by sheet filter');
    public readonly ADMIN_PANEL_BASIC_TAB_SELECT_SHEET_FILTER_WARN_ADMIN =
        i18n.t(`This field is currently empty because the selected sheet filter has been deleted.
        To fix this issue, please contact the view owner.`);
    public readonly ADMIN_PANEL_BASIC_TAB_SELECT_SHEET_FILTER_WARN_OWNER = i18n.t(
        `The currently applied filter is no longer available. Please select another filter or choose '{{sheetFilterNoRestrictions}}'.`,
        { sheetFilterNoRestrictions: this.ADMIN_PANEL_BASIC_SHEET_FILTER_NO_RESTRICTION }
    );
    public readonly ADMIN_PANEL_BASIC_TAB_SELECT_SHEET_FILTER_WARN_PRIVATE = (filterName: string) =>
        i18n.t(
            `{{filterName}} is a private sheet filter. Please share this filter to prevent it from being lost if ownership of this view changes.`,
            { filterName }
        );
    public readonly ADMIN_PANEL_BASIC_TAB_SELECT_SHEET_FILTER_WARN_PLACEHOLDER = i18n.t('Deleted filter');
    public readonly ADMIN_PANEL_BASIC_TAB_SELECT_USER_COLUMN_BODY = i18n.t(
        `Select a contact column that will restrict the current user's view. If a column is selected, the current user will only see rows that contain their email in the column.`
    );
    public readonly ADMIN_PANEL_BASIC_TAB_SELECT_USER_COLUMN_HEADER = i18n.t('Restrict view by current user');
    public readonly ADMIN_PANEL_BASIC_TAB_TITLE = i18n.t('General');
    public readonly ADMIN_PANEL_BASIC_USER_COLUMN_DELETED = i18n.t('Deleted column');
    public readonly ADMIN_PANEL_BASIC_USER_COLUMN_NO_RESTRICTION = i18n.t('No restriction - all rows visible to everyone shared');
    public readonly ADMIN_PANEL_BASIC_VIEW_DESCRIPTION = i18n.t('View description');
    public readonly ADMIN_PANEL_BASIC_VIEW_DESCRIPTION_DESCRIPTION = i18n.t('Describe your view, who should be using it, etc.');
    public readonly ADMIN_PANEL_BASIC_VIEW_NAME = i18n.t('View name');
    public readonly ADMIN_PANEL_BASIC_VIEW_SOURCE = i18n.t('Source sheet or report');
    public readonly ADMIN_PANEL_COLORS_LOGO_TAB_KEY = 'brand'; // DO NOT TRANSLATE -- URL parameter needs to match
    public readonly ADMIN_PANEL_COLORS_LOGO_TAB_TITLE = i18n.t('Colors & Logo');
    public readonly ADMIN_PANEL_DETAILS_CHECKED = i18n.t('is checked');
    public readonly ADMIN_PANEL_DETAILS_CUSTOM_LABEL = i18n.t('Custom label');
    public readonly ADMIN_PANEL_DETAILS_DESCRIPTION = i18n.t('Description/help text');
    public readonly ADMIN_PANEL_DETAILS_DISPLAY_AS = i18n.t('Display as');
    public readonly ADMIN_PANEL_DETAILS_FIELD_NAME = i18n.t('Field name');
    public readonly ADMIN_PANEL_DETAILS_FOCUS_TAB_ALT_TEXT = i18n.t('More information about focus tab');
    public readonly ADMIN_PANEL_DETAILS_FOCUS_TAB_DESCRIPTION = i18n.t('When a record is accessed, the details panel will open to this tab.');
    public readonly ADMIN_PANEL_DETAILS_FOCUS_TAB_TEXT = i18n.t('Focus tab');
    public readonly ADMIN_PANEL_DETAILS_INITIAL_VALUE = i18n.t('Initial value');
    public readonly ADMIN_PANEL_DETAILS_INITIAL_VALUE_DESCRIPTION = i18n.t('this value will be set on new item submission');
    public readonly ADMIN_PANEL_DETAILS_NOT_CHECKED = i18n.t('is not checked');
    public readonly ADMIN_PANEL_DETAILS_TOGGLE_LABEL_REQUIRE = i18n.t('Require');
    public readonly ADMIN_PANEL_DETAILS_TOGGLE_LABEL_READ_ONLY = i18n.t('Read-Only');
    public readonly ADMIN_PANEL_DETAILS_TOGGLE_LABEL_HIDE = i18n.t('Hide');
    public readonly ADMIN_PANEL_DISPLAY_ATTACHMENTS_OPTIONS_ADD_ATTACHMENTS_LABEL = i18n.t('Users can add attachments');
    public readonly ADMIN_PANEL_DISPLAY_ATTACHMENTS_OPTIONS_DISPLAY_ATTACHMENTS_LABEL = i18n.t('Display attachments');
    public readonly ADMIN_PANEL_DISPLAY_ATTACHMENTS_OPTIONS_HEADER = i18n.t('Attachments');
    public readonly ADMIN_PANEL_DISPLAY_COMMENTS_OPTIONS_ADD_COMMENTS_LABEL = i18n.t('Users can add comments');
    public readonly ADMIN_PANEL_DISPLAY_COMMENTS_OPTIONS_DISPLAY_COMMENTS_LABEL = i18n.t('Display comments');
    public readonly ADMIN_PANEL_DISPLAY_COMMENTS_OPTIONS_HEADER = i18n.t('Comments');
    public readonly ADMIN_PANEL_DISPLAY_FIELD_SELECTOR_BODY = i18n.t('Select which fields will be visible (choose at least one)');
    public readonly ADMIN_PANEL_DISPLAY_FIELD_SELECTOR_HEADER = i18n.t('Displayed fields');
    public readonly ADMIN_PANEL_DISPLAY_GROUP_FILTER_BODY = i18n.t('Enter a group name that represents these values');
    public readonly ADMIN_PANEL_DISPLAY_GROUP_FILTER_HEADER = i18n.t('Group Filter Values');
    public readonly ADMIN_PANEL_DISPLAY_SELECT_FILTER_OPTION_NONE = i18n.t('None');
    public readonly ADMIN_PANEL_DISPLAY_SELECT_FILTER_OPTION_SELECT = i18n.t('Select');
    public readonly ADMIN_PANEL_DISPLAY_TAB_KEY = 'display'; // DO NOT TRANSLATE -- URL parameter needs to match
    public readonly ADMIN_PANEL_DISPLAY_TAB_TITLE = i18n.t('View Display');
    public readonly ADMIN_PANEL_FOOTER_PRIMARY_BUTTON_TEXT = i18n.t('Save');
    public readonly ADMIN_PANEL_FOOTER_SECONDARY_BUTTON_TEXT = i18n.t('Discard Changes');
    public readonly ADMIN_PANEL_FORM_FIELD_LOGIC = i18n.t('Field Logic');
    public readonly ADMIN_PANEL_FORM_TAB_KEY = 'form'; // DO NOT TRANSLATE -- URL parameter needs to match
    public readonly ADMIN_PANEL_FORM_TAB_TITLE = i18n.t('Details Panel');
    public readonly ADMIN_PANEL_HEADER_SETTINGS = i18n.t('Settings: ');
    public readonly ADMIN_PANEL_MODAL_CREATE_VIEW_FAILS_MESSAGE = i18n.t(`Please try again.`);
    public readonly ADMIN_PANEL_MODAL_CREATE_VIEW_FAILS_TITLE = i18n.t('There was a problem copying the view.');
    public readonly ADMIN_PANEL_MODAL_CREATE_SETTINGS_FAILS_MESSAGE = (viewName: string) =>
        i18n.t('Your copy of this view "{{viewName}}" is missing some settings. Please try again.', { viewName });
    public readonly ADMIN_PANEL_MODAL_CREATE_SETTINGS_FAILS_TITLE = i18n.t('There was a problem copying the view.');
    public readonly ADMIN_PANEL_MODAL_CREATE_SUCCESS_MESSAGE = (viewName: string) =>
        i18n.t('Your view was successfully copied as "{{viewName}}".', { viewName });
    public readonly ADMIN_PANEL_MODAL_UNSAVED_CHANGES_MESSAGE = i18n.t('Do you want to save your changes before moving to a new tab?');
    public readonly ADMIN_PANEL_MODAL_UNSAVED_CHANGES_PRIMARY_BUTTON_TEXT = i18n.t('Save');
    public readonly ADMIN_PANEL_MODAL_UNSAVED_CHANGES_SECONDARY_BUTTON_TEXT = i18n.t('Discard Changes');
    public readonly ADMIN_PANEL_MODAL_UNSAVED_CHANGES_TITLE = i18n.t('Unsaved changes');
    public readonly ADMIN_PANEL_MODAL_UNSAVED_INVALID_CHANGES_MESSAGE = i18n.t(`You have invalid changes, do you want to continue
     to a new tab and lose your changes?`);
    public readonly ADMIN_PANEL_MODAL_UNSAVED_INVALID_CHANGES_TITLE = i18n.t('Unsaved changes');
    public readonly ADMIN_PANEL_MODAL_WARN_ADMIN_MESSAGE = i18n.t(`Are you sure you want to remove yourself as an Administrator?
     You will no longer be able to access this view unless you add yourself as a Shared User before you save your changes.`);
    public readonly ADMIN_PANEL_MODAL_WARN_ADMIN_PRIMARY_BUTTON_TEXT = i18n.t('Remove me');
    public readonly ADMIN_PANEL_MODAL_WARN_ADMIN_SECONDARY_BUTTON_TEXT = i18n.t('Cancel');
    public readonly ADMIN_PANEL_MODAL_WARN_ADMIN_TITLE = i18n.t('Caution!');
    public readonly ADMIN_PANEL_MODAL_WARN_PRIVATE_FILTER_TITLE = i18n.t('Private Filter');
    public readonly ADMIN_PANEL_MODAL_WARN_PRIVATE_FILTER_MESSAGE = i18n.t(`This view is based on your private filter and will be invalid 
        for others. If you choose to continue the new owner will need to update the view configuration.`);
    public readonly ADMIN_PANEL_MODAL_WARN_PRIVATE_FILTER_WITH_PENDING_TRANSFER_MESSAGE = i18n.t(`This view has a pending ownership transfer. 
        You have chosen to restrict this view with a private filter, which is invalid for others. 
        If you save these view settings and the ownership transfer is accepted, the new owner will need to update the view configuration.`);
    public readonly ADMIN_PANEL_PERMISSIONS_ADD_VALID_DOMAIN = i18n.t('Please add a valid domain');
    public readonly ADMIN_PANEL_PERMISSIONS_ADD_VALID_EMAIL = i18n.t('Please add a valid email');
    public readonly ADMIN_PANEL_PERMISSIONS_ADMINS_PLACEHOLDER = i18n.t('Select admin');
    public readonly ADMIN_PANEL_PERMISSIONS_ADMINS_PROMPT = i18n.t('Only sheet admin can be added as view admin');
    public readonly ADMIN_PANEL_PERMISSIONS_ADMINS_SUBTITLE = i18n.t(
        `Select who can edit this view’s settings and share this view (they must be shared as an admin in the source sheet or report)`
    );
    public readonly ADMIN_PANEL_PERMISSIONS_ADMINS_TITLE = i18n.t('Admins');
    public readonly ADMIN_PANEL_PERMISSIONS_DOMAINS_PLACEHOLDER = i18n.t('Add domains (ie. acme.com, contoso.com, etc.)');
    public readonly ADMIN_PANEL_PERMISSIONS_DOMAINS_PROMPT = i18n.t('valid domain required');
    public readonly ADMIN_PANEL_PERMISSIONS_DOMAINS_SUBTITLE = i18n.t('Add work domains that will receive access to this view');
    public readonly ADMIN_PANEL_PERMISSIONS_DOMAINS_TITLE = i18n.t('Shared domains');
    public readonly ADMIN_PANEL_PERMISSIONS_GROUPS_PLACEHOLDER = i18n.t('Add groups');
    public readonly ADMIN_PANEL_PERMISSIONS_GROUPS_PROMPT = i18n.t('valid groups required');
    public readonly ADMIN_PANEL_PERMISSIONS_GROUPS_SUBTITLE = i18n.t('Add Smartsheet groups that will receive access to this view');
    public readonly ADMIN_PANEL_PERMISSIONS_GROUPS_TITLE = i18n.t('Shared groups');
    public readonly ADMIN_PANEL_PERMISSIONS_OWNER_DOES_NOT_EXIST = i18n.t('Owner does not exist');
    public readonly ADMIN_PANEL_PERMISSIONS_OWNER_TITLE = i18n.t('Owner');
    public readonly ADMIN_PANEL_PERMISSIONS_READONLY_INFO = i18n.t(`You cannot update sharing because you are outside the view owner's organization`);
    public readonly ADMIN_PANEL_PERMISSIONS_TAB_KEY = 'share'; // DO NOT TRANSLATE -- URL parameter needs to match
    public readonly ADMIN_PANEL_PERMISSIONS_TAB_TITLE = i18n.t('Sharing');
    public readonly ADMIN_PANEL_PERMISSIONS_TRANSFER_OWNERSHIP_CANCEL_PLACEHOLDER = i18n.t('Pending Transfer to');
    public readonly ADMIN_PANEL_PERMISSIONS_TRANSFER_OWNERSHIP_INVALID_FILTER = i18n.t(
        'Note: Ownership cannot be transferred until a valid filter option is selected in the General tab.'
    );
    public readonly ADMIN_PANEL_PERMISSIONS_TRANSFER_OWNERSHIP_PLACEHOLDER = i18n.t('Select New Owner');
    public readonly ADMIN_PANEL_PERMISSIONS_TRANSFER_OWNERSHIP_ERROR = i18n.t('Unable to retrieve ownership transfer data.');
    public readonly ADMIN_PANEL_PERMISSIONS_USERS_PLACEHOLDER = i18n.t('Add users');
    public readonly ADMIN_PANEL_PERMISSIONS_USERS_PROMPT = i18n.t('valid email required');
    public readonly ADMIN_PANEL_PERMISSIONS_USERS_SUBTITLE = i18n.t('Add emails that will receive access to this view');
    public readonly ADMIN_PANEL_PERMISSIONS_USERS_TITLE = i18n.t('Shared Users');
    public readonly ADMIN_PANEL_PERMISSIONS_USERS_DUPLICATE_SELECTION = i18n.t(
        i18n.t('The newly added value is already in the list of selected values.')
    );
    public readonly ADMIN_PANEL_PROMPT_UNSAVED_CHANGES_MESSAGE = i18n.t(`You have changes that won’t be saved. Do you want to continue?`);
    public readonly ADMIN_PANEL_VIEW_FILTER_BODY =
        i18n.t('Select a dropdown or symbol column such as “Status”, “Category”, or “Stage”.') +
        i18n.t('This will enable users to filter the view by values in the column.');
    public readonly ADMIN_PANEL_VIEW_FILTER_HEADER = i18n.t('View filter');
    public readonly ADMIN_PANEL_VIEW_NAME_PREFIX_INCOMPLETE = (viewName: string) => i18n.t('Incomplete Copy of {{viewName}}', { viewName });
    public readonly ADMIN_PANEL_VIEW_NAME_PREFIX_COMPLETED = (viewName: string) => i18n.t('Copy of {{viewName}}', { viewName });
    public readonly ALL = i18n.t('All');
    public readonly APPLICATION_NAME = i18n.t('Dynamic View');
    public readonly ARIA_DETAILS_PANEL_ERROR_BANNER = i18n.t('Error banner');
    public readonly ARIA_DETAILS_PANEL_LOADING_OVERLAY = i18n.t('Loading overlay');
    public readonly AVAILABLE_FIELDS = i18n.t('Available fields');
    public readonly BACK_TO_VIEW = i18n.t('Back to view');
    public readonly BASIC_NEW_DESTINATION_HEADER = i18n.t('New Submission Destination');
    public readonly BASIC_NEW_TEXT_PARAGRAPH = i18n.t('Select the sheet you want to receive new submissions.');
    public readonly BUTTON_TEXT_CANCEL = i18n.t('Cancel');
    public readonly BUTTON_TEXT_CONTINUE = i18n.t('Continue');
    public readonly BUTTON_TEXT_OK = i18n.t('OK');
    public readonly BUTTON_TEXT_SAVE = i18n.t('Save');
    public readonly BUTTON_TEXT_REFRESH = i18n.t('Refresh');
    public readonly BUTTON_TEXT_DISCARD_CHANGES = i18n.t('Discard Changes');
    public readonly CALLBACK_TRY_AGAIN_BUTTON_TEXT = i18n.t('Try Again');
    public readonly COMMENT_ADD = i18n.t('Add a comment');
    public readonly COMMENT_HELP_TEXT = i18n.t('Press Enter to send | Shift + Enter to add new line');
    public readonly COMMENT_ITEM_REPLY = i18n.t('Reply');
    public readonly COMMENT_SEND = i18n.t('Send comment');
    public readonly CONTACT_SALES = i18n.t('Contact Sales');
    public readonly CREATE_NEW_VIEW_BASIC_TEXT = i18n.t('Select a sheet or report to create your new view');
    public readonly CREATE_OR_SELECT_A_VIEW = i18n.t('Select or create a view');
    public readonly CREATE_VIEW_TEXT = i18n.t('Create View');
    public readonly CREATE_VIEW_ALT_TEXT = i18n.t('Create new view');
    public readonly CURRENCY_ARGENTINA_PESO = i18n.t('Argentina, Peso (ARS)');
    public readonly CURRENCY_AUSTRALIA_DOLLAR = i18n.t('Australia, Dollar (AUD)');
    public readonly CURRENCY_BRAZIL_REAL = i18n.t('Brazil, Real (BRL)');
    public readonly CURRENCY_CANADA_DOLLAR = i18n.t('Canada, Dollar (CAD)');
    public readonly CURRENCY_CHILE_PESO = i18n.t('Chile, Peso (CLP)');
    public readonly CURRENCY_CHINA_YUAN = i18n.t('China, Yuan (CNY)');
    public readonly CURRENCY_DENMARK_KRONE = i18n.t('Denmark, Krone (DKK)');
    public readonly CURRENCY_EURO = i18n.t('Euro (EUR)');
    public readonly CURRENCY_HONG_KONG_DOLLAR = i18n.t('Hong Kong, Dollar (HKD)');
    public readonly CURRENCY_INDIA_RUPEE = i18n.t('India, Rupee (INR)');
    public readonly CURRENCY_ISRAEL_NEW_SHEKEL = i18n.t('Israel, New Shekel (ILS)');
    public readonly CURRENCY_JAPAN_YEN = i18n.t('Japan, Yen (JPY)');
    public readonly CURRENCY_MEXICO_PESO = i18n.t('Mexico, Peso (MXN)');
    public readonly CURRENCY_NEW_ZEALAND_DOLLAR = i18n.t('New Zealand, Dollar (NZD)');
    public readonly CURRENCY_NORWAY_KRONE = i18n.t('Norway, Krone (NOK)');
    public readonly CURRENCY_RUSSIA_RUBLE = i18n.t('Russia, Ruble (RUB)');
    public readonly CURRENCY_SINGAPORE_DOLLAR = i18n.t('Singapore, Dollar (SGD)');
    public readonly CURRENCY_SOUTH_AFRICA_RAND = i18n.t('South Africa, Rand (ZAR)');
    public readonly CURRENCY_SOUTH_KOREA_WON = i18n.t('South Korea, Won (KRW)');
    public readonly CURRENCY_SWEDEN_KRONA = i18n.t('Sweden, Krona (SEK)');
    public readonly CURRENCY_SWITZERLAND_FRANC = i18n.t('Switzerland, Franc (CHF)');
    public readonly CURRENCY_UNITED_KINGDOM_POUND = i18n.t('United Kingdom, Pound (GBP)');
    public readonly CURRENCY_UNITED_STATES_DOLLAR = i18n.t('United States, Dollar (USD)');
    public readonly DATE_SELECTOR_CALENDAR_ICON = i18n.t('Calendar Icon');
    public readonly DEFAULT_MESSAGE = i18n.t('Default message...');
    public readonly DELETE_VIEW_TEXT = i18n.t('Delete View');
    public readonly SAVE_AS_NEW_TEXT = i18n.t('Save as New');
    public readonly DETAILS_DATA_MODAL_UNSAVED_CHANGES_MESSAGE = i18n.t('You have unsaved changes. Please save or discard them before continuing.');
    public readonly DETAILS_DATA_MODAL_UNSAVED_CHANGES_TITLE = i18n.t('Unsaved changes');
    public readonly DETAILS_DATA_MODAL_UNSAVED_INVALID_CHANGES_TITLE = i18n.t('Invalid changes');
    public readonly DETAILS_PANEL_DESCRIPTION = i18n.t('Description');
    public readonly DETAILS_PANEL_GET_ROW_FAILED = i18n.t('The row was filtered out by the view.');
    public readonly DETAILS_PANEL_ROW_SAVED = i18n.t('Row saved');
    public readonly DETAILS_PANEL_SAVE_FAILED = i18n.t('Save failed');
    public readonly DETAILS_PANEL_SAVE_FAILED_TRY_AGAIN = i18n.t('Save failed. Please try again.');
    public readonly DETAILS_PANEL_UNABLE_SAVE_TRY_AGAIN = i18n.t('Unable to save. Please try again.');
    public readonly DETAILS_PANEL_REFRESH = i18n.t('Refresh');
    public readonly DETAILS_PANEL_VIEW_CREATOR = i18n.t('View created by');
    public readonly DETAIL_ATTACHMENTS_ATTACH_FILE = i18n.t('Attach a File');
    public readonly DETAIL_ATTACHMENTS_BY = i18n.t(' by ');
    public readonly DETAIL_ATTACHMENTS_EMPTY = i18n.t('No attachments to display');
    public readonly DETAIL_COMMENTS_EMPTY = i18n.t('No comments to display');
    public readonly DETAIL_PANEL_ATTACHMENTS = i18n.t('Attachments');
    public readonly DETAIL_PANEL_COMMENTS = i18n.t('Comments');
    public readonly DETAIL_PANEL_DATA = i18n.t('Data');
    public readonly DETAIL_PANEL_DETAILS = i18n.t('Details');
    public readonly DETAIL_PANEL_LABEL = i18n.t('Details, Attachments, and Comments tabs');

    public readonly DETAIL_PANEL_CLEAR_TOOLTIP = i18n.t('Clear value');
    public readonly DETAIL_PANEL_INFO_ALT_TEXT = i18n.t('Set field properties');
    public readonly DETAIL_PANEL_LOGIC_ALT_TEXT = i18n.t('Set field logic');
    public readonly DETAIL_PANEL_ROW_NOT_IN_VIEW_TITLE = i18n.t('Record no longer in view');
    public readonly DETAIL_PANEL_ROW_NOT_IN_VIEW_MESSAGE = i18n.t(
        'Due to changes since you last refreshed your view, this record is no longer available. Refresh your view for the most recent data.'
    );
    public readonly DOWNLOAD_ALT_TEXT = i18n.t('Export to .csv file');
    public readonly DOWNLOAD_OPTION_SUBTITLE = i18n.t(
        'This option allows users to export the contents shown in the View Display into a .csv file. It will not download content in the Details Panel.'
    );
    public readonly DOWNLOAD_OPTION_TITLE = i18n.t('Export to .csv file');
    public readonly ENTER_VALID_EMAIL = i18n.t('Enter a valid email');
    public readonly ERRORSTATE_AUTH_ACCOUNT_BLOCKED = i18n.t(`There was a problem loading this view.
        To resolve this issue, please contact the view owner.`);
    public readonly ERRORSTATE_BAD_REQUEST_TITLE = i18n.t('Bad request');
    public readonly ERRORSTATE_CALLBACK_ERROR_MESSAGE = i18n.t('Could not Sign in to Dynamic View.');
    public readonly ERRORSTATE_CALLBACK_ERROR_TITLE = i18n.t('Something went wrong');
    public readonly ERRORSTATE_CALLBACK_ERROR_EMBEDDED_THIRD_PARTY_SITE_MESSAGE = i18n.t(
        'To access Dynamic View from a third party site, please add a link to it and it will open in a new tab.'
    );
    public readonly ERRORSTATE_CALLBACK_ERROR_EMBEDDED_THIRD_PARTY_SITE_TITLE = i18n.t('Dynamic View is not supported in third party sites');
    public readonly ERRORSTATE_FORBIDDEN_TITLE = i18n.t('This action is not allowed');
    public readonly ERRORSTATE_GENERIC_MESSAGE = i18n.t(`The server can't complete your request. Please try again.`);
    public readonly ERRORSTATE_GENERIC_TITLE = i18n.t('Error');
    public readonly ERRORSTATE_INVALID_VIEW_TITLE = i18n.t('Invalid view');
    public readonly ERRORSTATE_NETWORK_ERROR_MESSAGE = i18n.t(
        `It appears that your device is no longer connected to the internet. Please check your connection and try again.`
    );
    public readonly ERRORSTATE_NETWORK_ERROR_TITLE = i18n.t('Network error');
    public readonly ERRORSTATE_NOT_FOUND_TITLE = i18n.t('Not found');
    public readonly ERRORSTATE_URL_NOT_FOUND_MESSAGE = i18n.t('The view may have been deleted, or you no longer have access to the view.');
    public readonly ERRORSTATE_URL_NOT_FOUND_TITLE = i18n.t('Dynamic View URL not found');
    public readonly ERROR_HEADER = i18n.t('Error');
    public readonly TRIAL_CHIP_LABEL = i18n.t('Evaluation Mode');
    public readonly TRIAL_TOOLTIP_ONE_PRE_TRIAL = i18n.t('Create and share a view to evaluate if Dynamic View is right for you and your team.');
    public readonly TRIAL_TOOLTIP_ONE_IN_TRIAL = (adminEmail: string) =>
        i18n.t('To add Dynamic View to your plan, contact your System Admin ({{adminEmail}})', { adminEmail });
    public readonly TRIAL_TOOLTIP_TWO = i18n.t('Learn more about the');
    public readonly TRIAL_TOOLTIP_LINK_TEXT = i18n.t('Dynamic View evaluation.');
    public readonly FALSE = i18n.t('false');
    public readonly FAVORITES_ERROR_MESSAGE = i18n.t(`Your favorites couldn't be loaded.
    Please try again.`);
    public readonly FAVORITE_ICON_ADD_ALT_TEXT = i18n.t('Add to Favorites');
    public readonly FAVORITE_ICON_ALT_TEXT = i18n.t('Open favorites panel');
    public readonly FAVORITE_ICON_REMOVE_ALT_TEXT = i18n.t('Remove from Favorites');
    public readonly FAVORITE_TITLE = i18n.t('Favorites');
    public readonly FIELD_PROPERTIES_HEADER = i18n.t('Field Properties: ');
    public readonly FIELD_PROPERTIES_SUB_HEADER = i18n.t('Select Field');
    public readonly FIELD_PROPERTIES_SUB_HEADER_ALT = i18n.t('Select a field to see its properties');
    public readonly FIELD_UPLOAD_NOT_ALLOWED = i18n.t(`This attachment's file type is not allowed`);
    public readonly FILE_EXCEEDS_2_MB = i18n.t('Max file size is 2MB');
    public readonly FILE_REQUIRED = i18n.t('You must attach a file greater than 0 KB before uploading');
    public readonly FILE_SIZE_EXCEEDS_LIMITS = i18n.t('File size is too large. Maximum file size is 30 MB');
    public readonly FILTER_ALT_TEXT = i18n.t('Filter');
    public readonly FILTER_AND = i18n.t('AND');
    public readonly FILTER_CONDITION = i18n.t('Condition');
    public readonly FILTER_FOOTER_APPLY_BUTTON_TEXT = i18n.t('Apply');
    public readonly FILTER_FOOTER_CANCEL_BUTTON_TEXT = i18n.t('Cancel');
    public readonly FILTER_FOOTER_DELETE_BUTTON_TEXT = i18n.t('Delete');
    public readonly FILTER_SHOW_ROWS_WHERE = i18n.t('Show rows where');
    public readonly FORM_ACTION_EDITABLE_OPTION = i18n.t('Show the following fields as Editable');
    public readonly FORM_ACTION_HIDE_OPTION = i18n.t('Hide the following fields');
    public readonly FORM_ACTION_READ_ONLY_OPTION = i18n.t('Show the following fields as Read-only');
    public readonly FORM_ACTION_REQUIRE_OPTION = i18n.t('Show the following fields as Required');
    public readonly FORM_CONTAINER_ADD_FIELD_TITLE = i18n.t('Add Field');
    public readonly FORM_CONTAINER_EDIT_FORM_TITLE = i18n.t('Details Panel Layout');
    public readonly FORM_CONTAINER_EDIT_FORM_REQUIRED = i18n.t(' * Required');
    public readonly FORM_CONTAINER_EDIT_FORM_TOGGLE_DESCRIPTION = i18n.t('Details panel description');
    public readonly FORM_CONTAINER_EDIT_FORM_DESCRIPTION_TITLE = i18n.t('Details description');
    public readonly FORM_CRITERIA_COMPARATOR_HAS_ANY_OF_OPTION = i18n.t('has any of');
    public readonly FORM_CRITERIA_COMPARATOR_HAS_NONE_OF_OPTION = i18n.t('has none of');
    public readonly FORM_CRITERIA_COMPARATOR_IS_NOT_ONE_OF_OPTION = i18n.t('is not one of');
    public readonly FORM_CRITERIA_COMPARATOR_IS_ONE_OF_OPTION = i18n.t('is one of');
    public readonly FORM_DELETE_MENU_OPTION = i18n.t('Delete this rule');
    public readonly FORM_DISABLE_MENU_OPTION = i18n.t('Disable this rule');
    public readonly FORM_EDIT_MENU__OPTION = i18n.t('Edit rule name');
    public readonly FORM_ENABLE_MENU_OPTION = i18n.t('Enable this rule');
    public readonly FORM_LOGIC_ADD_RULE = i18n.t('Add a new rule for field logic');
    public readonly FORM_LOGIC_CHANGE_RULE = i18n.t('Make changes to field logic for this rule');
    public readonly FORM_FIELD_MENU_ALT_TEXT = i18n.t('Menu options for the following form field:');
    public readonly FORM_FIELD_MENU_INSERT_IMAGE = i18n.t('Insert Image');
    public readonly FORM_FIELD_MENU_CLEAR_CONTENTS = i18n.t('Clear Contents');
    public readonly FORM_ADD_FIELD_LOGIC_BUTTON = i18n.t('Logic');
    public readonly COMMENT_MENU_EDIT_COMMENT = i18n.t('Edit comment');
    public readonly COMMENT_MENU_DELETE_COMMENT = i18n.t('Delete comment');
    public readonly COMMENT_MENU_DELETE_THREAD = i18n.t('Delete thread');
    public readonly COMMENT_MENU_ALT_TEXT = i18n.t('Menu options for the following comment:');
    public readonly FORM_FIELD_MENU_ENTER_TEXT = i18n.t('Enter Text');
    public readonly FORM_FIELD_MENU_PREVIEW_LABEL = i18n.t('Preview');
    public readonly GENERIC_FATAL_ERROR = i18n.t(
        `We ran into an error loading your Dynamic View. Please refresh the page and try again. If this error persists, please contact support.`
    );
    public readonly HEADER_SMARTSHEET_DV = i18n.t('Smartsheet Dynamic View');
    public readonly HELP = i18n.t('Help');
    public readonly HOME_ICON_ALT_TEXT = i18n.t('Open home panel');
    public readonly HOME_ERROR_MESSAGE = i18n.t('Unable to load your views');
    public readonly HOME_ADD_VIEW = i18n.t('Click Create View to get started with your own view.');
    public readonly HOME_LIST_EMPTY = i18n.t('Any views that you have access to appear here.');
    public readonly HOME_CONTACT_SALES = i18n.t('To create a view, you need a license. Contact us to get started.');
    public readonly HOME_TABLE_DATA_LOADING_MESSAGE = i18n.t('Loading data - Filters, sorting and exporting will be available shortly.');
    public readonly HOME_TITLE = {
        ALL: i18n.t('All Views'),
        OWNED_BY_ME: i18n.t('My Views'),
        SHARED_WITH_ME: i18n.t('Shared with Me'),
        SHARED_WITH_GROUP: i18n.t('Shared with Group'),
        ADMIN_CONTROLS_LABEL: i18n.t('Admin Controls'),
    };
    public readonly HOME_SUBTITLE = {
        ALL: i18n.t('Any views that you have access to appear here.'),
        OWNED_BY_ME: i18n.t('Any views that you own appear here.'),
        SHARED_WITH_ME: i18n.t('Any views that are shared with you directly appear here.'),
        SHARED_WITH_GROUP: i18n.t("Any views shared with groups that you're in appear here."),
    };
    public readonly HOME_FILTER_TOOLTIP = {
        ALL: i18n.t('Browse all the views that you have access to.'),
        OWNED_BY_ME: i18n.t('Browse only views that you own.'),
        SHARED_WITH_ME: i18n.t('Browse only views shared with you directly.'),
        SHARED_WITH_GROUP: i18n.t('Browse only views shared with groups that you’re in.'),
    };
    public readonly HOME_TABLE_COLUMN_HEADER = {
        NAME: i18n.t('Name'),
        OWNER: i18n.t('Owner'),
        MY_PERMISSION: i18n.t('My Permission'),
        LAST_ACCESSED: i18n.t('Last Opened by Me'),
        DESCRIPTION: i18n.t('View Description'),
        GROUP: i18n.t('Group/Domain'),
    };
    public readonly HOME_TABLE_ACCESS_LEVEL = { OWNER: i18n.t('Owner'), ADMIN: i18n.t('Admin'), COLLABORATOR: i18n.t('Collaborator') };
    public readonly HOME_PANEL_FILTERS = {
        ALL: i18n.t('All Views'),
        OWNED_BY_ME: i18n.t('My Views'),
        SHARED_WITH_ME: i18n.t('Shared with Me'),
        SHARED_WITH_GROUP: i18n.t('Shared with Group'),
    };
    public readonly HOME_SEARCH_PLACEHOLDER = {
        ALL: i18n.t('Search All Views'),
        OWNED_BY_ME: i18n.t('Search My Views'),
        SHARED_WITH_ME: i18n.t('Search Shared with Me'),
        SHARED_WITH_GROUP: i18n.t('Search Shared with Group'),
    };
    public readonly HOME_SEARCH_EXPLANATION = i18n.t('Filter the views displayed below.');
    public readonly HOME_SEARCH_EMPTY = {
        MESSAGE: (searchString: string) =>
            i18n.t(`We couldn't find any results matching "{{searchString}}". Try using a different word or phrase.`, { searchString }),
        BUTTON: i18n.t('See All Results'),
    };
    public readonly SOURCE_TYPE_REPORT = i18n.t('Report:');
    public readonly SOURCE_TYPE_SHEET = i18n.t('Sheet:');
    public readonly INVALID_ATTACHMENT_TYPE = i18n.t(`Invalid attachment. Please check attachment type and try again.`);
    public readonly INVALID_CHANGES_MESSAGE = i18n.t(`You have invalid changes. Please review and try again.`);
    public readonly LEARN_MORE = i18n.t('Learn more');

    public readonly LEFT_PANEL_OPEN = i18n.t('Open Left Panel Menu');
    public readonly LEFT_PANEL_CLOSE = i18n.t('Close Left Panel Menu');
    public readonly LEFT_SIDEBAR_TITLE = i18n.t('Home');
    public readonly LOGIN_TEXT = i18n.t('Login with Smartsheet Account');
    public readonly LOGO_FILE_TYPE = i18n.t('File must be a PNG, GIF or JPG');
    public readonly MAIN_NOT_SHARED_TO_VIEW_IN_DASHBOARD_TEXT = i18n.t(
        'You’re currently not shared in Dynamic View. Please contact the owner of this dashboard.'
    );
    public readonly MAIN_NOT_SHARED_TO_VIEW_IN_DASHBOARD_TITLE = i18n.t(`You're not shared to this view`);
    public readonly MODAL_CANCEL_BUTTON_DEFAULT_TEXT = i18n.t('Cancel');
    public readonly MODAL_CREATE_BUTTON_DEFAULT_TEXT = i18n.t('Create');
    public readonly MODAL_DELETE_BUTTON_TEXT = i18n.t('Delete');
    public readonly MODAL_DELETE_FILTER_TEXT = i18n.t('Are you sure you want to delete this filter?');
    public readonly MODAL_DELETE_VIEW_TEXT = i18n.t('Are you sure you want to delete this view?');
    public readonly MODAL_PRIMARY_BUTTON_DEFAULT_TEXT = i18n.t('OK');
    public readonly MODAL_SECONDARY_BUTTON_DEFAULT_TEXT = i18n.t('Cancel Changes');
    public readonly NEW_ITEM_SUBMISSION_TEXT = i18n.t('Allow users to submit new items to the underlying sheet or report.');
    public readonly NEW_ITEM_SUBMISSION_SUBTITLE = i18n.t(
        'This option allows users to create a new entry. If allowed, the Details Panel is required to be set up.'
    );
    public readonly NEW_ITEM_SUBMISSION_TITLE = i18n.t('New Item Submission');
    public readonly NEW_VIEW = i18n.t('New View');
    public readonly NOTHING_TO_SHOW = i18n.t(`There's nothing to show.`);
    public readonly NOTIFICATION_ALT_TEXT = i18n.t('Close notification');
    public readonly NOTIFICATION_MESSAGE = i18n.t('You have pending ownership transfers.');
    public readonly NO_ITEMS_TO_SHOW = i18n.t('No Items to Show');
    public readonly NO_VIEWS_TO_DISPLAY = i18n.t('No views to display');

    // DISCOVERABILITY
    public readonly DSC_HOME_LIST_VIEWS_EMPTY = i18n.t('Views you create or views shared with you will be here.');
    public readonly DSC_NO_DYNAMIC_VIEWS_HEADER = i18n.t('Share only what you need at the right time');
    public readonly DSC_NO_DYNAMIC_VIEWS_YET = i18n.t('No Dynamic Views yet');
    public readonly DSC_INFO_BULLETS_ONE = i18n.t('Share without giving access to the whole sheet');
    public readonly DSC_INFO_BULLETS_TWO = i18n.t('Simplify sheets and reports for different audiences');
    public readonly DSC_INFO_BULLETS_THREE = i18n.t('Automate multi-step processes');
    public readonly DSC_LEARN_HOW_TO_USE_DV = i18n.t('Learn how to use Dynamic View');
    public readonly DSC_PERMISSIONS_CREATE_SHEET = i18n.t(
        'To create a Dynamic View from this sheet, ask the sheet Owner or Admin for Admin permissions.'
    );
    public readonly DSC_PERMISSIONS_CREATE_REPORT = i18n.t(
        'To create a Dynamic View from this report, ask the report Owner or Admin for Admin permissions.'
    );

    public readonly NO_RECENTS_TO_DISPLAY = i18n.t('Open a view for it to appear here.');
    public readonly NO_RESULTS_FOUND = i18n.t('No results found');
    public readonly NO_FAVORITES_TO_DISPLAY = i18n.t(`To add an item to favorites,
        open the view and click
         the star next to its name.`);
    public readonly OWNERSHIP_TRANSFER_REDIRECT_HEADLINE = i18n.t('Views management has moved');
    public readonly OWNERSHIP_TRANSFER_REDIRECT_TEXT = i18n.t('Please manage your views from the new System Admin Controls page.');
    public readonly OWNERSHIP_TRANSFER_REDIRECT_BUTTON = i18n.t('Go to System Admin Controls');
    public readonly OWNERSHIP_TRANSFER_ACCEPT = i18n.t('Accept');
    public readonly OWNERSHIP_TRANSFER_ACCEPT_IN_PROGRESS = i18n.t('Accepting');
    public readonly OWNERSHIP_TRANSFER_ACCEPTED = i18n.t('Accepted');
    public readonly OWNERSHIP_TRANSFER_ALREADY_ACCEPTED_OR_DECLINED = i18n.t('The transfer was already accepted or declined by the new owner');
    public readonly OWNERSHIP_TRANSFER_CANCEL = i18n.t('Cancel');
    public readonly OWNERSHIP_TRANSFER_CANCEL_IN_PROGRESS = i18n.t('Canceling');
    public readonly OWNERSHIP_TRANSFER_CANCELED = i18n.t('Canceled');
    public readonly OWNERSHIP_TRANSFER_CANCELED_BY_OWNER = i18n.t('The transfer was canceled by the current owner');
    public readonly OWNERSHIP_TRANSFER_DECLINE = i18n.t('Decline');
    public readonly OWNERSHIP_TRANSFER_DECLINE_IN_PROGRESS = i18n.t('Declining');
    public readonly OWNERSHIP_TRANSFER_DECLINED = i18n.t('Declined');
    public readonly OWNERSHIP_TRANSFER_ERROR_MESSAGE = i18n.t(
        'Please try again. If the problem persists, ensure you are enrolled in a Business Plan or higher, and have an active Dynamic View license, then log out and log back in.'
    );
    public readonly OWNERSHIP_TRANSFER_ERROR_TITLE = i18n.t('Ownership transfers are not available at this time');
    public readonly OWNERSHIP_TRANSFER_LIST_RECEIVED = i18n.t('Received');
    public readonly OWNERSHIP_TRANSFER_LIST_SENT = i18n.t('Sent');
    public readonly OWNERSHIP_TRANSFER_LIST_ORG_VIEWS = i18n.t('All Views');
    public readonly OWNERSHIP_TRANSFER_LIST_EMPTY = i18n.t('You have no pending transfers.');
    public readonly OWNERSHIP_TRANSFER_LIST_ORG_VIEWS_EMPTY = "Your organization currently doesn't have any dynamic views.";
    public readonly OWNERSHIP_TRANSFER_NOT_ADMIN_OF_VIEW_SOURCE = i18n.t('Selected user is not an administrator of the view source.');
    public readonly OWNERSHIP_TRANSFER_RECEIVED_COLUMN_HEADERS = [
        i18n.t('Current Owner'),
        i18n.t('View Name'),
        i18n.t('Requested'),
        i18n.t('View Description'),
        i18n.t('Actions'),
    ];
    public readonly OWNERSHIP_TRANSFER_SUBTITLE1 = i18n.t(
        'To transfer ownership of your view, go to view Settings and select the new owner. Pending view transfer requests will be listed here.'
    );
    public readonly OWNERSHIP_TRANSFER_SUBTITLE2 = i18n.t(
        `You can accept or decline ownership of views you’ve received, or cancel requests you've sent.`
    );
    public readonly OWNERSHIP_TRANSFER_REQUEST_TITLE = i18n.t('Pending Ownership Transfer Requests');
    public readonly OWNERSHIP_TRANSFER_SENT_COLUMN_HEADERS = [
        i18n.t('View Name'),
        i18n.t('Recipient'),
        i18n.t('Sent'),
        i18n.t('View Description'),
        i18n.t('Actions'),
    ];
    public readonly OWNERSHIP_TRANSFER_ORG_VIEWS_COLUMN_HEADERS = [
        i18n.t('Current Owner'),
        i18n.t('View Name'),
        i18n.t('View Description'),
        i18n.t('Actions'),
    ];
    public readonly OWNERSHIP_TRANSFER_MODAL_MESSAGE = i18n.t(
        'Owners must be Dynamic View users, with administrator or owner permissions for the view source.'
    );
    public readonly OWNERSHIP_TRANSFER_MODAL_PRIMARY_BUTTON_TEXT = i18n.t('Apply');
    public readonly OWNERSHIP_TRANSFER_MODAL_SECONDARY_BUTTON_TEXT = i18n.t('Cancel');
    public readonly OWNERSHIP_TRANSFER_PENDING = i18n.t('Pending Transfer');
    public readonly OWNERSHIP_TRANSFER_TRY_AGAIN_TITLE = i18n.t('Try Again');
    public readonly OWNERSHIP_TRANSFER_TRY_AGAIN_MESSAGE = i18n.t('Something went wrong with the ownership transfer request. Please try again.');
    public readonly OWNERSHIP_TRANSFER_VIEW_NO_LONGER_EXISTS = i18n.t('This view no longer exists');
    public readonly OWNERSHIP_TRANSFER_WARN_INVALID_FILTER = i18n.t(
        'This view is based on a filter that is no longer valid. To continue using the view, reset the filter in Settings.'
    );
    public readonly OWNERSHIP_TRANSFER_BADGE_TOOLTIP = i18n.t('Ownership Transfer Requests');
    public readonly TRANSFER_OWNERSHIP_BUTTON_TEXT = i18n.t('Transfer Ownership');
    public readonly NO_DYNAMIC_VIEW_LICENSE = i18n.t('To continue, please ensure you have an active Dynamic View license.');
    public readonly PLACEHOLDER_BASIC_INPUT = i18n.t('Enter value');
    public readonly PLACEHOLDER_MULTI_SELECT = i18n.t('Select value(s)');
    public readonly PLACEHOLDER_DUAL_INPUT_START_VALUE = i18n.t('Enter starting value');
    public readonly PLACEHOLDER_DUAL_INPUT_END_VALUE = i18n.t('Enter ending value');
    public readonly RADIO_ALLOW = i18n.t('Allow');
    public readonly RADIO_DO_NOT_ALLOW = i18n.t('Do Not Allow');
    public readonly RECENTLY_OPENED = i18n.t('Recently Opened');
    public readonly RECENTS_ERROR_MESSAGE = i18n.t(`Your recently accessed views couldn't
    be loaded. Please try again.`);
    public readonly RECENTS_ACTIVATE_HOME_PANEL = i18n.t('Go to Home Panel');
    public readonly RECENTS_ICON_ALT_TEXT = i18n.t('Open recents panel');
    public readonly RECENTS_PIN_ALT_TEXT = i18n.t('Pin view to top');
    public readonly RECENTS_UNPIN_ALT_TEXT = i18n.t('Unpin view');
    public readonly REVERT_COLOR_AND_LOGO = i18n.t('Revert to default StatusView logo and color');
    public readonly SELECTION_FOR_BLANK_ENTRY = i18n.t('Blank');
    public readonly SELECTV2_UNSELECT_ALL = i18n.t('Unselect All');
    public readonly SELECT_VIEW_FROM_LEFT_PANEL = i18n.t(`To get started, select a view from the left panel.
    To create a view, you need a license. Contact us to get started.`);
    public readonly SELECT_VISIBLE_FIELDS = i18n.t('Select fields that will be visible');
    public readonly SHEETS = i18n.t('Sheets');
    public readonly SHEET_FILTER_INVALID = i18n.t('Sheet filter is no longer available');
    public readonly SIGN_OUT = i18n.t('Sign Out');
    public readonly SPINNER_DELETE_VIEW_LABEL = i18n.t('Deleting View');
    public readonly SPINNER_LOADING_LABEL = i18n.t('Loading...');
    public readonly SPINNER_SAVING_AS_NEW_LABEL = i18n.t('Saving as new view...');
    public readonly SPINNER_SAVING_LABEL = i18n.t('Saving...');
    public readonly SPINNER_SAVING_EDIT_OTHER_ROWS_LABEL = i18n.t('Saving your changes. You can still edit other rows.');
    public readonly SYMBOLPICKER_COLUMN_TYPE_ERROR = i18n.t('You must use a symbol column');
    public readonly TEMPLATE = {
        COMMENT_ITEM_VIEW_PREVIOUS_REPLIES: (length: number) =>
            i18n.t(`View {{length, number}} Previous {{replyOrReplies}}`, {
                length,
                replyOrReplies: length > 1 ? i18n.t('Replies') : i18n.t('Reply'),
            }),
        SHOWING_TOTAL: (showingQty: number, total: number) => i18n.t(`({{showingQty, number}} of {{total, number}})`, { showingQty, total }),
    };
    public readonly UNABLE_HANDLE_SERVER_ERROR = i18n.t(`Unable to handle server error message`);
    public readonly UNABLE_TO_RETRIEVE_VIEW_SOURCE_DATA = i18n.t('Unable to retrieve view source data.');
    public readonly UNKNOWN_VIEW_NAME = i18n.t('Unknown View Name');
    public readonly UNNAMED_FILTER = i18n.t('Unnamed Filter');
    public readonly USER = i18n.t('User');
    public readonly VIEW_CELL_COLLAPSE_ALL = i18n.t('Compact display');
    public readonly VIEW_CONTAINER_MODAL_SAVE_ROW_IN_PROGRESS_MESSAGE = i18n.t(`Your previous changes are still being saved.
    Please check back shortly. You can still edit other rows.`);
    public readonly VIEW_CONTAINER_MODAL_SAVE_ROW_IN_PROGRESS_TITLE = i18n.t('Save in progress');
    public readonly VIEW_DESCRIPTION_EMPTY_STATE_BUTTON = i18n.t('Add Description');
    public readonly VIEW_DESCRIPTION_EMPTY_STATE_DESCRIPTION = i18n.t(
        'Help your team understand this view. After adding a description, anyone shared to this view can see it, but only the view admin can edit the description.'
    );
    public readonly VIEW_DESCRIPTION_EMPTY_STATE_TITLE = i18n.t('Add a description');
    public readonly VIEW_DOWNLOAD_VIEW_DATA = i18n.t('Export view to .csv file');
    public readonly VIEW_DOWNLOAD_DEFAULT_FILENAME = i18n.t('view');
    public readonly VIEW_FILTER = i18n.t('Filter');
    public readonly VIEW_FILTER_DISABLED = i18n.t('Filter Off');
    public readonly VIEW_SHOW_FORMATS = i18n.t('Show/Hide Formatting');
    public readonly VIEW_SHOW_FORMATS_ALT_TEXT = i18n.t('Show formats');
    public readonly VIEW_STATUS_DISMISS_ALL = i18n.t('Dismiss Errors');
    public readonly VIEW_STATUS_SAVE_ERROR = i18n.t('Something went wrong when saving your changes.');
    public readonly VIEW_STATUS_NOT_SAVED_AND_SOME_NOT_IN_VIEW = i18n.t(
        'One or more rows you were editing could not be saved and are no longer in your view.'
    );
    public readonly VIEW_STATUS_SAVED = i18n.t('Changes saved.');
    public readonly VIEW_STATUS_SAVED_SOME_NO_LONGER_IN_VIEW = i18n.t('Changes saved. Some rows are no longer in the view.');
    public readonly VIEW_STATUS_SAVING = i18n.t('Saving...');
    public readonly VIEW_STATUS_SYNC_ERROR = i18n.t('Sync Error');
    public readonly VIEW_STATUS_SYNCING = i18n.t('Syncing');
    public readonly VIEW_STATUS_SYNC_COMPLETE = i18n.t('Sync complete');
    public readonly VIEW_STATUS_SYNCING_TIP_TEXT = i18n.t('Recently saved changes are syncing. You can continue to work or exit the page.');
    public readonly VIEW_TABLE_EMPTYSTATE_SETTINGS = i18n.t('Settings');
    public readonly VIEW_TABLE_EMPTYSTATE_SETTINGS_ALT_TEXT = i18n.t('Settings');
    public readonly VIEW_TABLE_GROUP_BY = i18n.t('Group by');
    public readonly VIEW_TABLE_NEW = i18n.t('New');
    public readonly VIEW_TABLE_SEARCH = i18n.t('Search');
    public readonly VIEW_TABLE_SETTINGS = i18n.t('Settings');
    public readonly VIEW_WRAP_ALT_TEXT = i18n.t('Wrap text');
    public readonly VIEW_WRAP_TEXT = i18n.t('Wrap Text');
    public readonly VISIBLE_FIELDS = i18n.t('Visible fields');
    public readonly WELCOME_TO_DYNAMIC_VIEW = i18n.t('Welcome to Dynamic View');

    public readonly SAVING_DATA_MODAL_IN_PROGRESS = i18n.t('Saving data');
    public readonly SAVING_DATA_MODAL_DONE = i18n.t('Data saved');

    public readonly EVALUATION_SHARING_BANNER_TITLE = i18n.t('Congratulations! You successfully shared a Dynamic View.');
    public readonly EVALUATION_SHARING_BANNER_BULLET_SYS_ADMIN_NOTIFIED = i18n.t(
        "Your System Admins will be notified that you're participating in the Dynamic View evaluation."
    );
    public readonly EVALUATION_SHARING_BANNER_BULLET_CONTACT_SYS_ADMIN_EMAIL_ADDRESS = (adminEmail: string) =>
        i18n.t('Please contact {{adminEmail}} to add Dynamic View to your plan.', { adminEmail });
    public readonly EVALUATION_SHARING_BANNER_BULLET_CONTACT_SYS_ADMIN = i18n.t(
        'Please contact your System Admins to add Dynamic View to your plan.'
    );

    public readonly ADMIN_CONTROLS_APPLY_BUTTON_TEXT = i18n.t('Apply');
    public readonly ADMIN_CONTROLS_CANCEL_BUTTON_TEXT = i18n.t('Cancel');
    public readonly ADMIN_CONTROLS_OWNERSHIP_TRANSFER_MODAL_MESSAGE = i18n.t(
        'Owners must be Dynamic View users, with administrator or owner permissions for the view source.'
    );
    public readonly ADMIN_CONTROLS_TRY_AGAIN_MESSAGE = i18n.t('Something went wrong with the ownership transfer request. Please try again.');
    public readonly ADMIN_CONTROLS_NOT_ADMIN_OF_VIEW_SOURCE = i18n.t('Selected user is not an administrator of the view source.');
    public readonly ADMIN_CONTROLS_LIST_ORG_VIEWS_EMPTY = "Your organization currently doesn't have any dynamic views.";
    public readonly ADMIN_CONTROLS_TRANSFER_OWNERSHIP_BUTTON_TEXT = i18n.t('Transfer Ownership');
    public readonly ADMIN_CONTROLS_SEARCH_EXPLANATION = i18n.t('Filter the views displayed below.');
    public readonly ADMIN_CONTROLS_SEARCH_PLACEHOLDER = i18n.t('Search All Views');
    public readonly ADMIN_CONTROLS_HEADER_SORT_ICON_ALT = i18n.t('Sort Indicator');
    public readonly ADMIN_CONTROLS_COLUMN_HEADERS = [i18n.t('View Name'), i18n.t('Owner'), i18n.t('View Description'), i18n.t('Actions')];
    public readonly ADMIN_CONTROLS_TITLE = i18n.t('System Admin Controls');
    public readonly ADMIN_CONTROLS_SUBTITLE = i18n.t("Take action on your plan's Dynamic Views.");
    public readonly ADMIN_CONTROLS_SUBTITLE_NEW = (viewCount: number) =>
        i18n.t("Take action on your plan's {{viewCount}} active Dynamic Views.", { viewCount });
    public readonly ADMIN_CONTROLS_DELETE_ALL_VIEWS = (viewCount: number) => {
        return viewCount > 0 ? i18n.t('Delete all {{viewCount}} views', { viewCount }) : i18n.t('Delete all views');
    };
    public readonly ADMIN_CONTROLS_DELETE_BUTTON_TEXT = i18n.t('Delete');
    public readonly ADMIN_CONTROLS_DELETE_MODAL_TITLE = i18n.t('Delete Views');
    public readonly ADMIN_CONTROLS_DELETE_AMOUT_OF_ITEMS_MODAL_BODY = (viewCount: number) =>
        i18n.t('{{viewCount}} views on your plan will be deleted.', { viewCount });
    public readonly ADMIN_CONTROLS_DELETE_SPECIFIC_ITEM_MODAL_BODY = (viewName: string) =>
        i18n.t('{{viewName}} on your plan will be deleted.', { viewName });
    public readonly ADMIN_CONTROLS_AMOUNT_OF_SELECTED_ITEMS = (selected: number, total: number) =>
        i18n.t('{{selected}} of {{total}} selected', { selected, total });

    // Language elements with app-core keys
    public readonly lbl_contains = i18n.t('contains');
    public readonly lbl_does_not_contain = i18n.t('does not contain');
    public readonly lbl_does_not_have_all_of = i18n.t('does not have all of');
    public readonly lbl_has_all_of = i18n.t('has all of');
    public readonly lbl_has_any_of = i18n.t('has any of');
    public readonly lbl_has_none_of = i18n.t('has none of');
    public readonly lbl_is_blank = i18n.t('is blank');
    public readonly lbl_is_checked = i18n.t('is checked');
    public readonly lbl_is_equal_to = i18n.t('is equal to');
    public readonly lbl_is_not_blank = i18n.t('is not blank');
    public readonly lbl_is_not_checked = i18n.t('is not checked');
    public readonly lbl_is_not_equal_to = i18n.t('is not equal to');
    public readonly lbl_is_not_one_of = i18n.t('is not one of');
    public readonly lbl_is_one_of = i18n.t('is one of');
    public readonly lbl_is_greater_than = i18n.t('is greater than');
    public readonly lbl_is_less_than = i18n.t('is less than');
    public readonly lbl_is_greater_than_or_equal_to = i18n.t('is greater than or equal to');
    public readonly lbl_is_less_than_or_equal_to = i18n.t('is less than or equal to');
    public readonly lbl_is_between = i18n.t('is between');
    public readonly lbl_is_not_between = i18n.t('is not between');
    public readonly lbl_is_a_number = i18n.t('is a number');
    public readonly lbl_is_not_a_number = i18n.t('is not a number');
    public readonly lbl_is_a_date = i18n.t('is a date');
    public readonly lbl_is_not_a_date = i18n.t('is not a date');
    public readonly lbl_multiSelectV2_selectAll = i18n.t('Select All');
    public readonly lbl_multiSelectV2_selectedCount = i18n.t('Selected');

    // Language elements for symbol values for Picklist Symbol
    public readonly Symbol_Zero = i18n.t('Zero');
    public readonly Symbol_Empty = i18n.t('Empty');
    public readonly Symbol_Quarter = i18n.t('Quarter');
    public readonly Symbol_Half = i18n.t('Half');
    public readonly Symbol_Three_Quarter = i18n.t('Three Quarter');
    public readonly Symbol_Full = i18n.t('Full');
    public readonly Symbol_Angle_Down = i18n.t('Angle Down');
    public readonly Symbol_Angle_Up = i18n.t('Angle Up');
    public readonly Symbol_Blue = i18n.t('Blue');
    public readonly Symbol_Cloudy = i18n.t('Cloudy');
    public readonly Symbol_Down = i18n.t('Down');
    public readonly Symbol_Easy = i18n.t('Easy');
    public readonly Symbol_Experts_Only = i18n.t('Experts Only');
    public readonly Symbol_Extreme = i18n.t('Extreme');
    public readonly Symbol_False = i18n.t('False');
    public readonly Symbol_Fast_Forward = i18n.t('Fast Forward');
    public readonly Symbol_Five = i18n.t('Five');
    public readonly Symbol_Four = i18n.t('Four');
    public readonly Symbol_Gray = i18n.t('Gray');
    public readonly Symbol_Green = i18n.t('Green');
    public readonly Symbol_Advanced = i18n.t('Advanced');
    public readonly Symbol_High = i18n.t('High');
    public readonly Symbol_Hold = i18n.t('Hold');
    public readonly Symbol_Intermediate = i18n.t('Intermediate');
    public readonly Symbol_Left = i18n.t('Left');
    public readonly Symbol_Low = i18n.t('Low');
    public readonly Symbol_Medium = i18n.t('Medium');
    public readonly Symbol_Mild = i18n.t('Mild');
    public readonly Symbol_Moderate = i18n.t('Moderate');
    public readonly Symbol_No = i18n.t('No');
    public readonly Symbol_No_Pain = i18n.t('No Pain');
    public readonly Symbol_One = i18n.t('One');
    public readonly Symbol_Partly_Sunny = i18n.t('Partly Sunny');
    public readonly Symbol_Pause = i18n.t('Pause');
    public readonly Symbol_Play = i18n.t('Play');
    public readonly Symbol_Rainy = i18n.t('Rainy');
    public readonly Symbol_Red = i18n.t('Red');
    public readonly Symbol_Rewind = i18n.t('Rewind');
    public readonly Symbol_Right = i18n.t('Right');
    public readonly Symbol_Severe = i18n.t('Severe');
    public readonly Symbol_Sideways = i18n.t('Sideways');
    public readonly Symbol_Stop = i18n.t('Stop');
    public readonly Symbol_Stormy = i18n.t('Stormy');
    public readonly Symbol_Sunny = i18n.t('Sunny');
    public readonly Symbol_Three = i18n.t('Three');
    public readonly Symbol_True = i18n.t('True');
    public readonly Symbol_Two = i18n.t('Two');
    public readonly Symbol_Unchanged = i18n.t('Unchanged');
    public readonly Symbol_Up = i18n.t('Up');
    public readonly Symbol_Very_Severe = i18n.t('Very Severe');
    public readonly Symbol_Yellow = i18n.t('Yellow');
    public readonly Symbol_Yes = i18n.t('Yes');

    public readonly DETAILS_DATA_CELL_IMAGE_ATTACHED = (cellImageFileName: string) =>
        i18n.t(`A thumbnail of the selected image ({{cellImageFileName}}) will appear once changes are saved.`, {
            cellImageFileName,
        });
    public readonly OWNERSHIP_TRANSFER_MODAL_HAS_TRANSFER = (viewName: string | undefined, newOwnerEmail: string) => {
        const defaultViewName = i18n.t('the current view');
        return i18n.t(
            `Ownership of {{viewName}} is pending transfer to {{newOwnerEmail}}. Starting a new ownership transfer will override the current pending transfer.`,
            {
                viewName: viewName ? viewName : defaultViewName,
                newOwnerEmail,
            }
        );
    };
}

export let instance = new LanguageElements();

export async function changeLanguage(lang: string) {
    const shortLocale = lang.slice(0, 2);
    const matchShortLocale = (supportedLocale: string) => supportedLocale === shortLocale;
    const localeSupported = Object.values(SUPPORTED_I18N_LOCALES).some(matchShortLocale);
    if (localeSupported) {
        await i18n.changeLanguage(lang);
        instance = new LanguageElements();
    }
}

export default instance;
