import { ObjectType } from '../enums';
import { Contact } from './Contact.interface';
import { PicklistItem } from './Picklist.interface';

export type CellObjectValue =
    | string
    | number
    | boolean
    | null
    | PicklistItem
    | MultiPicklistObjectValue
    | ContactObjectValue
    | AbstractDatetimeObjectValue
    | MultiContactObjectValue
    | GenericObjectValue
    | DateObjectValue
    | undefined;

export interface GenericObjectValue {
    // objectType is not actually optional when it is in a cell. However, it is marked as optional to help with converting a Contact interface to
    // ContactObjectValue
    objectType?: ObjectType | undefined;
}

export interface ContactObjectValue extends GenericObjectValue, Contact {
    imageId?: string;
}

export interface MultiContactObjectValue extends GenericObjectValue {
    values: ContactObjectValue[];
}

export interface AbstractDatetimeObjectValue extends GenericObjectValue {
    value: string;
}

export interface MultiPicklistObjectValue extends GenericObjectValue {
    values: string[];
}

export interface DateObjectValue extends GenericObjectValue {
    value: string;
}

export function instanceOfDateObjectValue(data: any): data is DateObjectValue {
    return typeof data === 'object' && 'value' in data;
}

export interface DurationObjectValue extends GenericObjectValue {
    weeks: number;
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    milliseconds: number;
    elapsed: boolean;
    negative: boolean;
}
