import { useEffect } from 'react';

interface ResizeHeightOptions {
    resizeHeightCallback: (height: number) => void;
    targetRef?: React.RefObject<HTMLElement>;
}

export const useResizeHeight = ({ resizeHeightCallback, targetRef }: ResizeHeightOptions) => {
    useEffect(() => {
        if (!targetRef?.current) {
            return;
        }

        const resizeObserver = new ResizeObserver((entries) => {
            window.requestAnimationFrame(() => {
                for (const entry of entries) {
                    const borderBoxSize = Array.isArray(entry.borderBoxSize) ? entry.borderBoxSize[0] : entry.borderBoxSize;

                    const blockSize = borderBoxSize?.blockSize ?? entry.contentRect.height;

                    if (entry.target === targetRef.current) {
                        resizeHeightCallback(blockSize);
                        break;
                    }
                }
            });
        });

        resizeObserver.observe(targetRef.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, [targetRef, resizeHeightCallback]);
};
