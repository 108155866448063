/* eslint @typescript-eslint/no-unnecessary-type-assertion: "off" */
import { ColumnType, SymbolSetName } from '../../common/enums';
import { CellObjectValue, Column, DateObjectValue } from '../../common/interfaces';
import { isGenericObjectValue } from '../../common/utils';
import { isBefore } from 'date-fns';
import { List } from 'immutable';
import { SortDirection } from 'react-virtualized';
import { collator } from '../../common/utils/Collator';
import { ViewSource } from '../../common/utils/ViewSource';
import { ViewSourceFactory } from '../../common/utils/ViewSourceFactory';
import { GridCell, RowData, SortConfig } from './Grid.interface';

const comparerForUndefinedOrNullValues = (value1: CellObjectValue, value2: CellObjectValue): number | undefined => {
    if (value1 == null && value2 == null) {
        return 0;
    }

    if (value1 == null && value2 != null) {
        return 1;
    }

    if (value1 != null && value2 == null) {
        return -1;
    }

    return undefined;
};

const comparerForNumberValues = (value1: CellObjectValue, value2: CellObjectValue): number | undefined => {
    if (typeof value1 === 'number' && typeof value2 === 'number') {
        if (value1 < value2) {
            return -1;
        }

        if (value1 > value2) {
            return 1;
        }

        return 0;
    }

    if (typeof value1 === 'number' && typeof value2 !== 'number') {
        return -1;
    }

    if (typeof value2 === 'number' && typeof value1 !== 'number') {
        return 1;
    }

    return undefined;
};

export const sortRows = (
    list: List<RowData> | undefined,
    config: SortConfig,
    columnMap: Map<number, Column> | undefined,
    rowIdToReportColumnIdToSheetColumn?: ViewSource['rowIdToReportColumnIdToSheetColumn']
): List<RowData> | undefined => {
    if (!list || !columnMap || config.sortBy === 'index') {
        return list;
    }

    const columnToSortBy = columnMap.get(parseInt(String(config.sortBy), 10));
    if (!columnToSortBy) {
        return list;
    }

    // Sort on data prop for selected column
    return list
        .sort((rowData1: RowData, rowData2: RowData) => {
            const cell1 = rowData1[config.sortBy] as GridCell;
            const cell2 = rowData2[config.sortBy] as GridCell;

            let cellValueToSortOn1 = cell1.value;
            let cellValueToSortOn2 = cell2.value;

            switch (columnToSortBy.type) {
                case ColumnType.ABSTRACT_DATETIME:
                case ColumnType.ABSTRACTDATETIME:
                case ColumnType.DATE:
                case ColumnType.DATETIME:
                    if (!cellValueToSortOn1 || !isGenericObjectValue(cellValueToSortOn1)) {
                        break;
                    }
                    if (!cellValueToSortOn2 || !isGenericObjectValue(cellValueToSortOn2)) {
                        break;
                    }

                    const dateFromCell1 = new Date((cellValueToSortOn1 as DateObjectValue).value);
                    const dateFromCell2 = new Date((cellValueToSortOn2 as DateObjectValue).value);

                    return isBefore(dateFromCell1, dateFromCell2) ? -1 : 1;

                case ColumnType.CHECKBOX:
                    // For checkbox column types, we want to ensure that boolean cell values are grouped in the sorting output similar to app-core. If
                    // neither cell value 1 nor 2 are booleans then we fall back to using the cell values (NOT displayValues) for the comparison
                    if (typeof cellValueToSortOn1 === 'boolean' && typeof cellValueToSortOn2 !== 'boolean') {
                        return -1;
                    }

                    if (typeof cellValueToSortOn2 === 'boolean' && typeof cellValueToSortOn1 !== 'boolean') {
                        return 1;
                    }
                    break;

                // Sort symbols (like "HIGH", "MEDIUM", "LOW") according to their order in the options property.
                case ColumnType.PICKLIST:
                    let symbol = columnToSortBy.symbol;
                    let options = columnToSortBy.options;

                    // It's possible that the picklist column is coming from a report, which lacks the symbol and options properties.
                    // In that case, we need to use info from the sheet columns to sort correctly.
                    if ((symbol == null || options == null) && rowIdToReportColumnIdToSheetColumn) {
                        const reportColumnId = Number(config.sortBy);
                        const row1SheetColumn = ViewSourceFactory.getSheetColumn(
                            rowIdToReportColumnIdToSheetColumn,
                            Number(rowData1.id),
                            reportColumnId
                        );
                        const row2SheetColumn = ViewSourceFactory.getSheetColumn(
                            rowIdToReportColumnIdToSheetColumn,
                            Number(rowData2.id),
                            reportColumnId
                        );
                        if (row1SheetColumn && row2SheetColumn && row1SheetColumn.symbol && row2SheetColumn.symbol) {
                            // If the sheetColumns are different types, then sort according to type.
                            if (row1SheetColumn.symbol !== row2SheetColumn.symbol) {
                                return row1SheetColumn.symbol > row2SheetColumn.symbol ? 1 : -1;
                            }

                            // If they are the same symbol type, then back-fill the symbol type and options array from the sheet column.
                            symbol = row1SheetColumn.symbol;
                            options = row1SheetColumn.options;
                        }
                    }

                    // We can't sort if we don't know what type of symbol this is, or if we don't know the options for that symbol type.
                    if (symbol == null || options == null) {
                        break;
                    }

                    // We can't sort if we don't have string values that represent the symbols in each cell.  (Null values are sorted lower down.)
                    if (typeof cellValueToSortOn1 !== 'string' || typeof cellValueToSortOn2 !== 'string') {
                        break;
                    }

                    if (symbol !== SymbolSetName.NONE && Object.keys(SymbolSetName).includes(symbol)) {
                        const index1 = options.indexOf(cellValueToSortOn1);
                        const index2 = options.indexOf(cellValueToSortOn2);
                        return index1 <= index2 ? -1 : 1;
                    }
                    break;
            }

            // If the sort column type is not DATE and cells containing objects, we update the cellValueToSortOn1/cellValueToSortOn2 to use
            // displayValues if the cell values contain objects.
            if (isGenericObjectValue(cellValueToSortOn1)) {
                cellValueToSortOn1 = cell1.displayValue;
            }
            if (isGenericObjectValue(cellValueToSortOn2)) {
                cellValueToSortOn2 = cell2.displayValue;
            }

            // Ensure null or undefined values appear last when sorting
            const comparerResultForUndefinedOrNull = comparerForUndefinedOrNullValues(cellValueToSortOn1, cellValueToSortOn2);
            if (comparerResultForUndefinedOrNull !== undefined) {
                return comparerResultForUndefinedOrNull;
            }

            // Due to an issue with Intl.Collator not able to sort negative numbers correctly, perform number comparison here
            const comparerResultForUNumber = comparerForNumberValues(cellValueToSortOn1, cellValueToSortOn2);
            if (comparerResultForUNumber !== undefined) {
                return comparerResultForUNumber;
            }

            return collator.compare(cellValueToSortOn1 as string, cellValueToSortOn2 as string);
        })
        .toList()
        .update(
            (sortedList: List<RowData>): List<RowData> => (config.sortDirection === SortDirection.DESC ? sortedList.reverse().toList() : sortedList)
        );
};
