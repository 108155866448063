import { SmartsheetUser } from '../common/interfaces';
import { AxiosPromise } from 'axios';
import axiosInstance from './Axios.instance';

class ViewSourceAdminClient {
    public static getInstance(): ViewSourceAdminClient {
        if (!ViewSourceAdminClient.instance) {
            ViewSourceAdminClient.instance = new ViewSourceAdminClient();
        }
        return ViewSourceAdminClient.instance;
    }

    private static instance: ViewSourceAdminClient;

    private static url = '/viewsources';

    private constructor() {}

    public getViewSourceAdmins(viewId: string): AxiosPromise<SmartsheetUser[]> {
        const url = `${ViewSourceAdminClient.url}/${viewId}/admins`;
        return axiosInstance.get(url);
    }
}
const viewSourceAdminClient = ViewSourceAdminClient.getInstance();
export default viewSourceAdminClient;
