import * as React from 'react';
import { ValueComponentProps } from 'react-select';
import Pill from '../Pill';

const MultiSelectValue: React.SFC<ValueComponentProps<any>> = props => {
    return (
        <Pill
            value={props.value.value}
            label={props.value.label ? props.value.label : props.value.value}
            onRemove={() => {
                if (props.onRemove) {
                    props.onRemove(props.value);
                }
            }}
            isValid={props.value.isValid}
        />
    );
};

export default MultiSelectValue;
