/* eslint react/no-children-prop:"off" */
import * as React from 'react';
import { useSelector } from 'react-redux';
import alertIcon from '../../assets/images/alert/modal-warning-large.svg';
import { OwnershipTransferStatus } from '../../common/enums';
import { AutomationIds } from '../../common/enums/AutomationElements.enum';
import { OwnershipTransferModalType } from '../../common/enums/OwnershipTransferModalType';
import { OwnershipTransferTabOption } from '../../common/enums/OwnershipTransferTabOption';
import * as dv from '../../common/interfaces';
import { isEmailValid } from '../../common/utils';
import { disableFreeTextNewOptions } from '../../common/utils/DisableFreeTextNewOptions';
import ContactPicker from '../../components/ContactPicker';
import { checkIfOnDemandOptionsShouldBeEnabled } from '../../components/ContactPicker/CheckIfOnDemandOptionsShouldBeEnabled';
import { ErrorMessage } from '../../components/ErrorMessage';
import InfoMessage from '../../components/InfoMessage';
import RenderModal, { ModalProps } from '../../components/Modal/RenderModal';
import { WithDataClientProps } from '../../components/hoc/WithDataClient';
import { LanguageElements } from '../../language-elements/LanguageElements';
import { useLanguageElements } from '../../language-elements/withLanguageElementsHOC';
import BasicLinkOut from '../Admin/Basic/BasicLinkOut';
import { isDisabledContactPickerOnDemandSelector } from '../App/Selectors';
import OwnershipTransferAdminControlRedirect from './OwnershipTransferAdminControlRedirect';
import OwnershipTransferHeader from './OwnershipTransferHeader';
import OwnershipTransferTable from './OwnershipTransferTable';

export interface TransfersByTab {
    sent: dv.IOwnershipTransfer[];
    received: dv.IOwnershipTransfer[];
    orgViews: dv.IOwnershipTransfer[];
}

interface Props extends WithDataClientProps {
    activeTab: OwnershipTransferTabOption;
    transfersByTab: TransfersByTab;
    onClickTab: (tab: OwnershipTransferTabOption) => void;
    onKeyDownTab: (e: React.KeyboardEvent, tab: OwnershipTransferTabOption) => void;
    onAccept: (transferId: string) => void;
    onDecline: (transferId: string) => void;
    onCancel: (transferId: string) => void;
    showModalType: OwnershipTransferModalType;
    onErrorMessageClose: () => void;
    isSysAdminOwnershipTransferAllowed: boolean;
    onTransferModalOpen: (transfer: dv.IOwnershipTransfer) => void;
    activeTransfer: dv.IOwnershipTransfer | undefined;
    onTransferModalClose: () => void;
    isAllUsers: boolean;
    transferModalOptions: dv.Contact[];
    newOwner: dv.Contact | undefined;
    onUserChange: (contact: dv.Contact) => void;
    onTransferOwnership: () => void;
    transferError: string | undefined;
}

const OwnershipTransfer: React.FC<Props> = (props) => {
    const languageElements = useLanguageElements();
    const isDisabledContactPickerOnDemand = useSelector(isDisabledContactPickerOnDemandSelector);
    const receivedCount = props.transfersByTab.received.length;
    const sentCount = props.transfersByTab.sent.length;
    const orgViewsCount = props.transfersByTab.orgViews.length;
    const isEnableOnDemandOptions =
        props.isAllUsers && !isDisabledContactPickerOnDemand && checkIfOnDemandOptionsShouldBeEnabled(props.transferModalOptions);
    const validateNewOption = props.isAllUsers
        ? (newOption: string, allowFreeTextOptionCallback: (isValid: boolean) => void): void => {
              allowFreeTextOptionCallback(isEmailValid(newOption));
          }
        : disableFreeTextNewOptions;

    // Instead of passing just the transfers for the 'active' tab, we are passing an object to <OwnershipTransferTabData>
    // that contains a key-value pair for both 'sent' transfers and 'received' transfers.
    // This enables us to display the COUNT in both of the tabs.
    return (
        <div className={`ownership-transfer ${props.activeTab}`} data-client-id={AutomationIds.OWNERSHIP_TRANSFER_LIST}>
            <OwnershipTransferHeader
                activeTab={props.activeTab}
                onClickTab={props.onClickTab}
                onKeyDownTab={props.onKeyDownTab}
                receivedCount={receivedCount}
                sentCount={sentCount}
                orgViewsCount={orgViewsCount}
                isSysAdminOwnershipTransferAllowed={props.isSysAdminOwnershipTransferAllowed}
            />
            {props.activeTab === OwnershipTransferTabOption.ORG_VIEWS ? (
                <OwnershipTransferAdminControlRedirect />
            ) : (
                <OwnershipTransferTable
                    activeTab={props.activeTab}
                    activeTabTransfers={props.transfersByTab[props.activeTab]}
                    onAccept={props.onAccept}
                    onDecline={props.onDecline}
                    onCancel={props.onCancel}
                    onTransferModalOpen={props.onTransferModalOpen}
                />
            )}

            {/* Error modal */}
            {props.showModalType !== OwnershipTransferModalType.NONE && <RenderModal {...getModalProps(props, languageElements)} />}

            {props.activeTransfer && (
                <RenderModal
                    title={languageElements.TRANSFER_OWNERSHIP_BUTTON_TEXT}
                    message={languageElements.OWNERSHIP_TRANSFER_MODAL_MESSAGE}
                    wrapperClasses="transfer-ownership-modal"
                    closeWrapper={props.onTransferModalClose}
                    primaryButtonText={languageElements.OWNERSHIP_TRANSFER_MODAL_PRIMARY_BUTTON_TEXT}
                    onClickPrimaryButton={props.onTransferOwnership}
                    secondaryButtonText={languageElements.OWNERSHIP_TRANSFER_MODAL_SECONDARY_BUTTON_TEXT}
                    onClickSecondaryButton={props.onTransferModalClose}
                    hideSecondaryButton={false}
                    children={
                        <div className="select-new-owner">
                            {props.activeTransfer && props.activeTransfer.status === OwnershipTransferStatus.PENDING && (
                                <InfoMessage
                                    message={languageElements.OWNERSHIP_TRANSFER_MODAL_HAS_TRANSFER(
                                        props.activeTransfer.view.name,
                                        props.activeTransfer.newOwner.email
                                    )}
                                    dataClientId={AutomationIds.OWNERSHIP_TRANSFER_INFO_ON_TRANSFER}
                                />
                            )}
                            <ContactPicker
                                dataClientId={AutomationIds.OWNERSHIP_TRANSFER_USERS}
                                classNames="new-owner-dropdown"
                                allOptions={props.transferModalOptions}
                                selectedOptions={props.newOwner}
                                enableOnDemandOptions={isEnableOnDemandOptions}
                                isValidNewOption={validateNewOption}
                                allowClearing={false}
                                onChange={props.onUserChange}
                                multi={false}
                                inPlace={true}
                                placeholder={languageElements.ADMIN_PANEL_PERMISSIONS_TRANSFER_OWNERSHIP_PLACEHOLDER}
                            />
                            {props.transferError && (
                                <ErrorMessage message={props.transferError} dataClientId={AutomationIds.OWNERSHIP_TRANSFER_ERROR_ON_TRANSFER} />
                            )}
                        </div>
                    }
                />
            )}
        </div>
    );
};

const getModalProps = (props: Props, languageElements: LanguageElements) => {
    // Specify any customized props needed for the modal
    const modalSpecificProps: ModalProps = {
        onClickPrimaryButton: props.onErrorMessageClose,
        icon: alertIcon,
        closeWrapper: props.onErrorMessageClose,
        wrapperClasses: 'ownership-transfer-warning',
    };

    switch (props.showModalType) {
        case OwnershipTransferModalType.ERROR_ON_TRANSFER:
            modalSpecificProps.title = languageElements.OWNERSHIP_TRANSFER_TRY_AGAIN_TITLE;
            modalSpecificProps.message = languageElements.OWNERSHIP_TRANSFER_TRY_AGAIN_MESSAGE;
            break;

        case OwnershipTransferModalType.ERROR_TRANSFER_WAS_CANCELED:
            modalSpecificProps.title = languageElements.OWNERSHIP_TRANSFER_CANCELED_BY_OWNER;
            break;

        case OwnershipTransferModalType.ERROR_TRANSFER_WAS_ALREADY_ACCEPTED_OR_REJECTED_BY_NEW_OWNER:
            modalSpecificProps.title = languageElements.OWNERSHIP_TRANSFER_ALREADY_ACCEPTED_OR_DECLINED;
            break;

        case OwnershipTransferModalType.ERROR_VIEW_NOT_FOUND:
            modalSpecificProps.title = languageElements.OWNERSHIP_TRANSFER_VIEW_NO_LONGER_EXISTS;
            break;

        // The modal for possible invalid filter warning requires add'l props in order to display link and 2 buttons
        case OwnershipTransferModalType.WARN_INVALID_FILTER:
            modalSpecificProps.title = languageElements.OWNERSHIP_TRANSFER_WARN_INVALID_FILTER;

            // Implement child component for 'Learn more' link to display in modal
            modalSpecificProps.children = (
                <BasicLinkOut
                    dataClientId={AutomationIds.ADMIN_PANEL_PERMISSIONS_OWNER_TRANSFER_MODAL_LINK}
                    sourceDescription={languageElements.LEARN_MORE}
                    sourceLink="https://help.smartsheet.com/articles/2478391#privatefilter"
                />
            );
            break;

        case OwnershipTransferModalType.NO_DYNAMIC_VIEW_LICENSE:
            modalSpecificProps.title = languageElements.NO_DYNAMIC_VIEW_LICENSE;
            break;

        case OwnershipTransferModalType.NONE:
        default:
            throw new Error(`${props.showModalType} is not a supported modal type when generating props for the GenericModelContent component.`);
    }

    return modalSpecificProps;
};

export default OwnershipTransfer;
