/*
* NOTE  = The value of each enum is tightly coupled with the Smartsheet API.
* Before updating, please reference the Smartsheet API Documentation.
*/

export enum fontFamily {
    Arial = '0',
    Tahoma = '1',
    TimesNewRoman = '2',
    Verdana = '3',
}

export enum fontSize {
    size8pt = '0',
    size9pt = '1',
    size10pt = '2',
    size12pt = '3',
    size14pt = '4',
    size16pt = '5',
    size18pt = '6',
    size20pt = '7',
    size24pt = '8',
    size28pt = '9',
    size32pt = '10',
    size36pt = '11',
}

export enum horizontalAlign {
    default = '0',
    left = '1',
    center = '2',
    right = '3',
}

export enum verticalAlign {
    default = '0',
    top = '1',
    middle = '2',
    bottom = '3',
}

export enum colorFormat {
    'none' = '0',
    '#000000' = '1',
    '#FFFFFF' = '2',
    'transparent' = '3',
    '#FFEBEE' = '4',
    '#FFF3DF' = '5',
    '#FFFEE6' = '6',
    '#E7F5E9' = '7',
    '#E2F2FE' = '8',
    '#F4E4F5' = '9',
    '#F2E8DE' = '10',
    '#FFCCD2' = '11',
    '#FFE1AF' = '12',
    '#FEFF85' = '13',
    '#C6E7C8' = '14',
    '#B9DDFC' = '15',
    '#EBC7EF' = '16',
    '#EEDCCA' = '17',
    '#E5E5E5' = '18',
    '#F87E7D' = '19',
    '#FFCD7A' = '20',
    '#FEFF00' = '21',
    '#7ED085' = '22',
    '#5FB3F9' = '23',
    '#D190DA' = '24',
    '#D0AF8F' = '25',
    '#BDBDBD' = '26',
    '#EA352E' = '27',
    '#FF8D00' = '28',
    '#FFED00' = '29',
    '#40B14B' = '30',
    '#1061C3' = '31',
    '#9210AD' = '32',
    '#974C00' = '33',
    '#757575' = '34',
    '#991310' = '35',
    '#EA5000' = '36',
    '#EBC700' = '37',
    '#237F2E' = '38',
    '#0B347D' = '39',
    '#61058B' = '40',
    '#592C00' = '41',
}

export enum currencyFormat {
    NONE = '0',
    ARS = '1',
    AUD = '2',
    BRL = '3',
    CAD = '4',
    CLP = '5',
    EUR = '6',
    GBP = '7',
    ILS = '8',
    INR = '9',
    JPY = '10',
    MXN = '11',
    RUB = '12',
    USD = '13',
    ZAR = '14',
    CHF = '15',
    CNY = '16',
    DKK = '17',
    HKD = '18',
    KRW = '19',
    NOK = '20',
    NZD = '21',
    SEK = '22',
    SGD = '23',
}

export enum decimalCount {
    zero = '0',
    one = '1',
    two = '2',
    three = '3',
    four = '4',
    five = '5',
}

export enum numberFormat {
    none = '0',
    number = '1', // eslint-disable-line id-denylist
    currency = '2',
    percent = '3',
}

export enum appliedFormat {
    none = '0',      // '0'
    on = '1',       // '1'
}

export enum dateFormatSMAR {
    LOCALE_BASED = '0',
    MMMM_D_YYYY = '1',
    MMM_D_YYYY = '2',
    D_MMM_YYYY = '3',
    YYYY_MM_DD_HYPHEN = '4',
    YYYY_MM_DD_DOT = '5',
    DWWWW_MMMM_D_YYYY = '6',
    DWWW_DD_MMM_YYYY = '7',
    DWWW_MM_DD_YYYY = '8',
    MMMM_D = '9',
    D_MMMM = '10',
}
