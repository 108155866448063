import * as React from 'react';
import { AutomationIds } from '../../../common/enums/AutomationElements.enum';
import { BaseProps, PureBaseComponent } from '../../Base';
import './Button.css';

export interface ButtonProps extends BaseProps {
    id?: string;
    tabIndex?: number;
    text?: string;
    secondaryText?: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    className?: string;
    icon?: string;
    imageAltText?: string;
    disabled?: boolean;
    tooltip?: string;
}

class Button extends PureBaseComponent<ButtonProps> {
    public render(): React.ReactNode {
        const {
            text,
            secondaryText,
            onClick,
            className,
            icon,
            imageAltText,
            disabled,
            dataClientId,
            dataClientType,
            tabIndex,
            tooltip,
        } = this.props;

        return (
            <button
                tabIndex={tabIndex}
                className={`dv-button ${className || ''} ${text && icon ? 'right-pad' : ''}`}
                data-client-id={dataClientId}
                data-client-type={dataClientType}
                disabled={disabled}
                onClick={e => !disabled ? onClick ? onClick(e) : undefined : undefined}
            >
                {icon &&
                    <img
                        alt={imageAltText}
                        src={icon}
                    />
                }
                {text &&
                    <span className="primary-text" data-client-id={AutomationIds.BUTTON_TEXT}>{text}</span>
                }
                {secondaryText &&
                    <span  className="secondary-text" data-client-id={AutomationIds.BUTTON_SECONDARY_TEXT}>{secondaryText}</span>
                }
                {tooltip &&
                    <div className={'tooltip'}>
                        <div>{tooltip}</div>
                    </div>
                }
            </button>
        );
    }
}

export default Button;
