import { Modal } from '@smartsheet/lodestar-core';
import classNames from 'classnames';
import * as FocusTrap from 'focus-trap-react';
import * as React from 'react';
import './ModalWrapper.css';

const ControlID = {
    MODAL_WRAPPER: '906',
};

interface Props {
    isModalOpen: boolean;
    onClose: () => void;
    hideCloseButton?: boolean;
    focusTrap?: boolean;
    classes?: string;
    children?: React.ReactNode;
}

interface State {
    activeTrap: boolean;
}

/**
 * Use this modal wrapper for all application modals. The modal wrapper accepts a boolean to open/close
 * and a generic handle close method.
 *
 * Props.focusTrap is used to enable/disable restricting focus to elements within the modal itself. FocusTrap
 * is enable by default, but should be disabled when there are no focusable elements within the content passed
 * into the modal.
 */
class ModalWrapper extends React.Component<Props> {
    public state: State;

    public constructor(props: Props) {
        super(props);
        this.state = {
            activeTrap: this.props.focusTrap !== false,
        };
    }

    public render(): React.ReactNode {
        return (
            <Modal
                className={classNames('modal', this.props.classes)}
                isOpen={true}
                onCloseRequested={this.onModalClose}
                shouldCloseOnOverlayClick={false}
                shouldCloseOnEscapePress={true}
            >
                <FocusTrap active={this.state.activeTrap}>
                    <div>
                        {!this.props.hideCloseButton && (
                            <button data-client-id={ControlID.MODAL_WRAPPER} className="close-button" onClick={this.props.onClose}>
                                <svg width="15" height="15" viewBox="0 0 36 36">
                                    <path
                                        d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18
                            7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"
                                    />
                                </svg>
                            </button>
                        )}
                        {this.props.children}
                    </div>
                </FocusTrap>
            </Modal>
        );
    }

    private onModalClose = (): void => {
        this.setState({ activeTrap: false });
        this.props.onClose();
    };
}

export default ModalWrapper;
