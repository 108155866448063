export enum UserActions {
    CLICK_SAVE_AS_NEW = 'Click Save as New',
    CLICK_EXPORT_VIEW = 'Click Export View',
    ADD_AT_MENTION = 'Select Contact (@Mentions)',
    CLICK_DETAILS_PANEL_IMAGE = 'Click Cell Image (Details Panel)',
    CLICK_CELL_IMAGE = 'Click Cell Image (Grid)',
    ZOOM_CELL_IMAGE = 'Zoom Cell Image (Image Modal)',
    CLICK_LEFT_PANEL = 'Select Left Panel Category',
    CLICK_HOME_FILTER = 'Select Home Table Filter',
    OPEN_HOME_VIEW = 'Open View using Home Table',
}
