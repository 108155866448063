import * as React from 'react';
import { Link } from 'react-router-dom';
import { TableRowProps } from 'react-virtualized';
import { AutomationIds } from '../../common/enums/AutomationElements.enum';
import { HomeTableMouseEvent, HomeTableRowData } from './HomeTable';

export interface Props extends TableRowProps {
    key: string;
    onRowClick: (event: HomeTableMouseEvent) => void;
    onRowDoubleClick: (event: HomeTableMouseEvent) => void;
    onRowMouseOut: (event: HomeTableMouseEvent) => void;
    onRowMouseOver: (event: HomeTableMouseEvent) => void;
    onRowRightClick: (event: HomeTableMouseEvent) => void;
    homeTableRowData: HomeTableRowData;
    style: object;
    activeTabColumnWidths: number[];
}

interface RowProps {
    'aria-label': string;
    tabIndex: number;
    rowData?: HomeTableRowData;
    onClick?: (mouseEvent: React.MouseEvent<HTMLAnchorElement>) => void;
    onDoubleClick?: (mouseEvent: React.MouseEvent<HTMLAnchorElement>) => void;
    onMouseOut?: (mouseEvent: React.MouseEvent<HTMLAnchorElement>) => void;
    onMouseOver?: (mouseEvent: React.MouseEvent<HTMLAnchorElement>) => void;
    onContextMenu?: (mouseEvent: React.MouseEvent<HTMLAnchorElement>) => void;
}

const HomeTableRowElement: React.SFC<Props> = (props) => {
    const rowProps: RowProps = { 'aria-label': 'row', tabIndex: 0 };

    return (
        <Link
            to={`/views/${props.rowData.id}`}
            {...rowProps}
            className="row-data"
            role="row"
            style={props.style}
            data-client-id={AutomationIds.HOME_ROW}
        >
            {props.columns}
        </Link>
    );
};

export default HomeTableRowElement;
