import { truncateOnSpaceWithEllipses } from '../../../common/utils';
import classNames from 'classnames';
import * as React from 'react';
import onClickOutside, { InjectedOnClickOutProps } from 'react-onclickoutside';
import { MenuDivider } from '../../../containers/View/Filter/FilterMenu/MenuDivider';
import { DataClientProps } from '../../Base';
import { CommentMenuItem } from './CommentMenuItem';

import editIcon from '../../../assets/images/filter/edit.svg';
import deleteIcon from '../../../assets/images/icons/trash.svg';
import verticalEllipsis from '../../../containers/Form/FormRule/assets/vertical-ellipsis.svg';

import './CommentMenu.css';
import { withLanguageElementsHOC, LanguageElementsProp } from '../../../language-elements/withLanguageElementsHOC';

interface OwnProps {
    for: string;
    onClickEditComment?: () => void;
    onClickDeleteComment?: () => void;
    onClickDeleteDiscussion?: () => void;
}

interface State {
    menuOpen?: boolean;
}

export type FormFieldMenuProps = OwnProps & InjectedOnClickOutProps & DataClientProps & LanguageElementsProp;

class CommentMenu extends React.Component<FormFieldMenuProps, State> {
    public constructor(props: FormFieldMenuProps) {
        super(props);
        this.state = { menuOpen: false };
    }

    public render = (): React.ReactNode => {
        if (!this.props.onClickEditComment && !this.props.onClickDeleteComment && !this.props.onClickDeleteDiscussion) {
            return null;
        }

        const shouldRenderMenuDivider = (this.props.onClickEditComment || this.props.onClickDeleteComment) && this.props.onClickDeleteDiscussion;
        const menuAltText = truncateOnSpaceWithEllipses(this.props.for, 50);
        return (
            <div tabIndex={0} className={classNames('comment-menu', { open: this.state.menuOpen })} data-client-id={this.props.dataClientId}>
                <a
                    className={classNames('comment-menu-button', { active: this.state.menuOpen })}
                    data-client-id={this.props.dataClientId}
                    onClick={() => this.setState({ menuOpen: !this.state.menuOpen })}
                >
                    <img src={verticalEllipsis} alt={`${this.props.languageElements.COMMENT_MENU_ALT_TEXT} ${menuAltText}`} />
                </a>
                {this.state.menuOpen && (
                    <div className="comment-menu-dropdown">
                        {this.props.onClickEditComment && (
                            <CommentMenuItem onClick={this.handleEditComment} icon={editIcon}>
                                {this.props.languageElements.COMMENT_MENU_EDIT_COMMENT}
                            </CommentMenuItem>
                        )}
                        {this.props.onClickDeleteComment && (
                            <CommentMenuItem onClick={this.handleDeleteComment} icon={deleteIcon}>
                                {this.props.languageElements.COMMENT_MENU_DELETE_COMMENT}
                            </CommentMenuItem>
                        )}
                        {shouldRenderMenuDivider && <MenuDivider />}
                        {this.props.onClickDeleteDiscussion && (
                            <CommentMenuItem onClick={this.handleDeleteDiscussion} icon={deleteIcon}>
                                {this.props.languageElements.COMMENT_MENU_DELETE_THREAD}
                            </CommentMenuItem>
                        )}
                    </div>
                )}
            </div>
        );
    };

    // This event handler is used by the onClickOutside higher order component that wraps the FormFieldMenu.
    // See the following for more info: https://github.com/Pomax/react-onclickoutside#ensuring-there-is-a-click-handler
    // noinspection JSUnusedGlobalSymbols
    public handleClickOutside = () => this.closeMenu();

    private closeMenu = () => this.state.menuOpen && this.setState({ menuOpen: false });

    private handleEditComment = () => {
        if (this.props.onClickEditComment) {
            this.props.onClickEditComment();
        }
        this.closeMenu();
    };

    private handleDeleteComment = () => {
        if (this.props.onClickDeleteComment) {
            this.props.onClickDeleteComment();
        }
        this.closeMenu();
    };

    private handleDeleteDiscussion = () => {
        if (this.props.onClickDeleteDiscussion) {
            this.props.onClickDeleteDiscussion();
        }
        this.closeMenu();
    };
}

export default withLanguageElementsHOC(onClickOutside(CommentMenu));
