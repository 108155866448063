import { IPaginatedResult  } from '../interfaces';

export class PaginatedResult<T> implements IPaginatedResult<T> {
    public readonly pageNumber: number = 1; // Setting to 1, this mirrors what app-core does when no data exists
    public readonly pageSize?: number;
    public readonly totalPages: number = 1; // Setting to 1, this mirrors what app-core does when no data exists
    public readonly totalCount: number = 0;
    public readonly data: T[] = [];

    public constructor(paginatedResult?: IPaginatedResult<T>) {
        if (paginatedResult == null) {
            return;
        }

        this.pageNumber = paginatedResult.pageNumber;
        this.pageSize = paginatedResult.pageSize;
        this.totalPages = paginatedResult.totalPages;
        this.totalCount = paginatedResult.totalCount;
        this.data = paginatedResult.data;
    }

    public toWireModel(): IPaginatedResult<T> {
        return {
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            totalPages: this.totalPages,
            totalCount: this.totalCount,
            data: this.data,
        };
    }
}
