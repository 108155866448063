import styled from '@emotion/styled';
import { Modal, Spacer, TypeRampV2, sizes } from '@smartsheet/lodestar-core';
import * as React from 'react';
import { AutomationIds } from '../../common/enums/AutomationElements.enum';
import { useLanguageElements } from '../../language-elements/withLanguageElementsHOC';
import AdminControlsActionsBar from './AdminControlsActionsBar';
import AdminControlsDeleteModalBody from './AdminControlsDeleteModalBody';
import AdminControlsTable from './AdminControlsTable';
import AdminControlsTransferOwnershipModalBody from './AdminControlsTransferOwnershipModalBody';
import { useAdminControlsViewsData } from './useAdminControlsViewsData';

enum ActionType {
    DELETE = 'delete',
    TRANSFER = 'transfer',
}

const AdminControls: React.FC = () => {
    const [showModal, setShowModal] = React.useState(false);
    const [selectedViews, setSelectedViews] = React.useState<Set<string>>(new Set());
    // actionItems can be set of views to delete or transfer ownership
    const [actionItems, setActionItems] = React.useState<Set<string>>(new Set());
    const [actionType, setActionType] = React.useState<ActionType>(ActionType.DELETE);
    const [searchValue, setSearchValue] = React.useState('');

    const { views, viewsTotal, handleUpdateViews, viewsLoadingStatus } = useAdminControlsViewsData(searchValue);
    const languageElements = useLanguageElements();

    const handleSelectAllView = () => {
        if (selectedViews.size !== 0) {
            setSelectedViews(new Set());
        } else {
            setSelectedViews(new Set(views.keys()));
        }
    };

    const handleBulkDelete = () => {
        setShowModal(true);
        setActionType(ActionType.DELETE);
        setActionItems(new Set(selectedViews));
    };

    const handleSingleDelete = (id: string) => {
        setShowModal(true);
        setActionType(ActionType.DELETE);
        setActionItems(new Set([id]));
    };

    const handleSingleTransferOwnership = (id: string) => {
        setActionType(ActionType.TRANSFER);
        setShowModal(true);
        setActionItems(new Set([id]));
    };

    const handleSelectView = (viewId: string) => {
        const newSelected = new Set(selectedViews);

        if (newSelected.has(viewId)) {
            newSelected.delete(viewId);
        } else {
            newSelected.add(viewId);
        }

        setSelectedViews(newSelected);
    };

    const handleUpdateSelectedViews = () => {
        const newSelected = new Set(selectedViews);

        actionItems.forEach((id) => {
            if (newSelected.has(id)) {
                newSelected.delete(id);
            }
        });

        setSelectedViews(newSelected);
    };

    const handleHideModal = () => {
        setShowModal(false);
    };

    return (
        <div data-testid={AutomationIds.ADMIN_CONTROLS_CONTAINER}>
            <PageContainer>
                <Spacer orientation="column" space="xSmall">
                    <TypeRampV2 system="ursa" variant="TT-Norms-Pro--28px--400w">
                        {languageElements.ADMIN_CONTROLS_TITLE}
                    </TypeRampV2>
                    <StyledSubtitle>{languageElements.ADMIN_CONTROLS_SUBTITLE_NEW(viewsTotal)}</StyledSubtitle>

                    <AdminControlsActionsBar
                        searchValue={searchValue}
                        onSetSearchValue={setSearchValue}
                        viewsTotal={viewsTotal}
                        selectedViewsSize={selectedViews.size}
                        isBulkDeleteDisabled={selectedViews.size === 0}
                        onBulkDelete={handleBulkDelete}
                        onSelectAllViews={handleSelectAllView}
                    />

                    <AdminControlsTable
                        views={views}
                        viewsLoadingStatus={viewsLoadingStatus}
                        selectedViews={selectedViews}
                        onSingleDelete={handleSingleDelete}
                        onSingleTransferOwnership={handleSingleTransferOwnership}
                        onSelectView={handleSelectView}
                        onViewsUpdate={handleUpdateViews}
                    />
                </Spacer>
            </PageContainer>

            <Modal width="small" placement="center" isOpen={showModal} onCloseRequested={handleHideModal}>
                {actionType === ActionType.DELETE && (
                    <AdminControlsDeleteModalBody
                        views={views}
                        itemsToDelete={actionItems}
                        onUpdateSelectedViews={handleUpdateSelectedViews}
                        onHideModal={handleHideModal}
                        onUpdateViews={handleUpdateViews}
                    />
                )}
                {actionType === ActionType.TRANSFER && (
                    <AdminControlsTransferOwnershipModalBody
                        views={views}
                        itemsToTransfer={actionItems}
                        onUpdateSelectedViews={handleUpdateSelectedViews}
                        onUpdateViews={handleUpdateViews}
                        onHideModal={handleHideModal}
                    />
                )}
            </Modal>
        </div>
    );
};

const PageContainer = styled.div`
    box-sizing: border-box;
    margin: ${sizes.large}px ${sizes.xLarge}px 0;
    position: relative;
    flex: 1 1 auto;
`;

const StyledSubtitle = styled.div`
    margin: 0;
    font-size: 13px;
    min-height: ${sizes.xxLarge}px;
    white-space: nowrap;
    overflow: hidden;
`;

export default AdminControls;
