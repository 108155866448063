/* eslint react/jsx-no-target-blank:"off" */
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { AutoSizer, Column, RowMouseEventHandlerParams, SortDirection, SortDirectionType, Table, TableHeaderProps } from 'react-virtualized';
import { createStructuredSelector } from 'reselect';
import sortDown from '../../assets/images/icons/sort-down.svg';
import { AccessLevel, GroupType } from '../../common/enums';
import { AutomationIds } from '../../common/enums/AutomationElements.enum';
import { HomeFilter } from '../../common/enums/HomeFilter';
import { UserActions } from '../../common/enums/UserActions.enum';
import { HomeView, SmartsheetUser } from '../../common/interfaces';
import { SharedWithGroup } from '../../common/interfaces/View.interface';
import { UserAnalyticsAction } from '../../common/metrics/UserAnalyticsAction';
import { compareStrings, withNullChecks } from '../../common/utils';
import { BaseComponent } from '../../components/Base';
import { HomeEmptyState } from '../../components/Home/HomeEmptyState';
import LandingPage from '../../components/LandingPage';
import { LanguageElementsProp, withLanguageElementsHOC } from '../../language-elements/withLanguageElementsHOC';
import { ActionByType } from '../../store';
import ViewNewItem from '../LeftPanel/HomePanel/ViewNewItem';
import { ActionTypes, Actions } from './Actions';
import {
    ColumnsByHomeFilter,
    HomeTableColumn,
    WeakHomeTableColumnInfo,
    getColumnWidthsByFilter,
    homeTableColumnCellRenderer,
    homeTableColumnWidths,
} from './HomeTableColumnData';
import HomeTableRowElement, { Props as RowElementProps } from './HomeTableRowElement';
import * as Selectors from './Selectors';

export interface HomeTableRowData extends HomeView {
    index: number;
}

export interface HomeTableMouseEvent extends RowMouseEventHandlerParams {
    rowData: HomeTableRowData;
    event: React.MouseEvent<any>;
}

interface OwnProps {
    views: HomeView[];
    filter: HomeFilter;
    userIsLicensed: boolean;
    invalidSearch: boolean;
    searchValue: string;
    setSearchValue: (val: string) => void;
}

interface StateProps {
    sortBy: HomeTableColumn;
    sortDirection: SortDirectionType;
}

interface DispatchProps {
    setSortBy: (sortBy: HomeTableColumn) => ActionByType<Actions, ActionTypes.SET_SORT_BY>;
    setSortDirection: (sortDirection: SortDirectionType) => ActionByType<Actions, ActionTypes.SET_SORT_DIRECTION>;
}

export type HomeTableProps = OwnProps & StateProps & DispatchProps & LanguageElementsProp;

interface State {
    views: HomeTableRowData[];
    groupColumnWidth: number;
}

export class HomeTable extends BaseComponent<HomeTableProps, State> {
    private totalWidth: number;
    private groupColumnRef: React.RefObject<HTMLDivElement>;

    public constructor(props: HomeTableProps) {
        super(props);
        this.totalWidth = this.updateColumnWidths(getColumnWidthsByFilter(this.props.filter));
        this.groupColumnRef = React.createRef();
        const views = this.addIndexToViews(props.views);
        this.state = { views, groupColumnWidth: 0 };
    }

    public render(): React.ReactNode {
        const autoSizerStyle = {
            // .home margin-top = 20px, .home-all padding-bottom = 15px (for horizontal scrollbar)
            height: `calc(100vh - var(--headerHeight) - var(--homeHeaderHeight) - 20px - 15px)`,
            width: this.totalWidth,
        };

        return (
            <div className="home-all">
                <div className="home-body">
                    <div data-client-id={AutomationIds.HOME_TABLE}>
                        <AutoSizer style={autoSizerStyle} disableWidth={false}>
                            {({ height, width }: { height: number; width: number }) => (
                                <Table
                                    disableHeader={this.state.views.length === 0 && !this.props.invalidSearch}
                                    headerClassName={'column-title'}
                                    headerHeight={50}
                                    height={height || window.innerHeight}
                                    overscanRowCount={10}
                                    className={'home-table'}
                                    rowClassName={({ index }) => (index === -1 ? 'list-header' : 'row-data')}
                                    rowHeight={50}
                                    rowGetter={({ index }) => this.state.views[index]}
                                    rowCount={this.state.views.length}
                                    rowRenderer={this.rowWrapper}
                                    scrollToIndex={1}
                                    width={width || window.innerHeight}
                                    sort={this.sortViews}
                                    sortBy={this.props.sortBy}
                                    sortDirection={this.props.sortDirection}
                                    noRowsRenderer={this.getLandingPage}
                                >
                                    {this.getColumns()}
                                </Table>
                            )}
                        </AutoSizer>
                    </div>
                </div>
            </div>
        );
    }

    public componentDidMount(): void {
        this.sortViews(this.props);
        window.onresize = this.handleResize;
    }

    public componentDidUpdate(prevProps: Readonly<HomeTableProps>): void {
        if (this.props.views !== prevProps.views) {
            this.totalWidth = this.updateColumnWidths(homeTableColumnWidths);
            this.sortViews(this.props);
        }
    }

    public componentWillUnmount(): void {
        window.onresize = null;
    }

    private sortViews = ({ sortBy, sortDirection }: Pick<HomeTableProps, 'sortBy' | 'sortDirection'>): void => {
        const views = this.props.views.sort(CompareFunctions[sortBy]);
        if (sortDirection === SortDirection.DESC) {
            views.reverse();
        }
        if (this.props.sortBy !== sortBy) {
            this.props.setSortBy(sortBy);
        }
        if (this.props.sortDirection !== sortDirection) {
            this.props.setSortDirection(sortDirection);
        }
        this.setState({ views: this.addIndexToViews(views) });
    };

    private addIndexToViews = (views: HomeView[]): HomeTableRowData[] =>
        views.map((view, index) => {
            if (!view.sharedWithGroups) {
                return { ...view, index };
            }
            const sortNames = (a: SharedWithGroup, b: SharedWithGroup) => {
                if (!a.name || a.type === GroupType.DOMAIN) {
                    return 1;
                }
                if (!b.name || b.type === GroupType.DOMAIN) {
                    return -1;
                }
                return a.name.localeCompare(b.name);
            };
            const sharedWithGroups = view.sharedWithGroups.sort(sortNames);
            return { ...view, index, sharedWithGroups };
        });

    private getColumns = () =>
        ColumnsByHomeFilter[this.props.filter].map((column) => {
            const name = column as HomeTableColumn;
            const cellRenderer = homeTableColumnCellRenderer[name];
            return (
                <Column
                    dataKey={HomeTableColumn[name]}
                    key={HomeTableColumn[name]}
                    label={this.props.languageElements.HOME_TABLE_COLUMN_HEADER[name]}
                    width={homeTableColumnWidths[name][0]}
                    cellRenderer={(p) =>
                        name === HomeTableColumn.GROUP
                            ? cellRenderer(p.rowData, this.props.languageElements, {
                                  columnWidth: this.state.groupColumnWidth,
                                  updateWidth: this.handleResize,
                              })
                            : cellRenderer(p.rowData, this.props.languageElements, {})
                    }
                    className={'outer-cell'}
                    flexGrow={homeTableColumnWidths[name][1]}
                    headerRenderer={this.headerRenderer}
                />
            );
        });

    private updateColumnWidths = (widths: WeakHomeTableColumnInfo<number[]>) =>
        Object.keys(widths).reduce((totalWidth: number, columnName: HomeTableColumn) => totalWidth + (widths[columnName]![0] || 0), 0);

    private handleResize = () => this.setState({ groupColumnWidth: this.groupColumnRef.current ? this.groupColumnRef.current.offsetWidth : 0 });

    private getLandingPage = () => {
        if (this.props.invalidSearch) {
            return (
                <LandingPage
                    title=""
                    message={this.props.languageElements.HOME_SEARCH_EMPTY.MESSAGE(this.props.searchValue)}
                    className="white"
                    showViewIcon={false}
                >
                    <button className="landing-page-button" onClick={() => this.props.setSearchValue('')}>
                        {this.props.languageElements.HOME_SEARCH_EMPTY.BUTTON}
                    </button>
                </LandingPage>
            );
        }
        let message = this.props.languageElements.HOME_SUBTITLE[this.props.filter] + '\n';
        message += this.props.userIsLicensed ? this.props.languageElements.HOME_ADD_VIEW : this.props.languageElements.HOME_CONTACT_SALES;
        return this.props.userIsLicensed ? (
            <HomeEmptyState />
        ) : (
            <LandingPage title="" message={message} showViewIcon={true} className={'white'}>
                {this.props.userIsLicensed ? (
                    <ViewNewItem
                        text={this.props.languageElements.CREATE_VIEW_TEXT}
                        controlId={AutomationIds.MAIN_NEW_VIEW}
                        class="btn-primary landing-page-button"
                    />
                ) : (
                    <a
                        className="landing-page-button"
                        href="https://www.smartsheet.com/contact-us-sales?fts=dynamic-view"
                        target="_blank"
                        data-client-id={AutomationIds.HOME_CONTACT_SALES}
                    >
                        {this.props.languageElements.CONTACT_SALES}
                    </a>
                )}
            </LandingPage>
        );
    };

    private rowWrapper = (props: RowElementProps) => {
        const onClick = (e: React.MouseEvent<HTMLDivElement>) =>
            UserAnalyticsAction.addFromEvent(e, { viewSearch: this.props.searchValue !== '', filter: this.props.filter }, UserActions.OPEN_HOME_VIEW);
        return (
            <div key={props.key} style={{ display: 'contents' }} onClick={onClick}>
                <HomeTableRowElement {...props} />
            </div>
        );
    };

    private headerRenderer = ({ dataKey, label = '', sortBy, sortDirection }: TableHeaderProps) => {
        const sortIndicatorClassNames = classNames('ReactVirtualized__Table__sortableHeaderIcon', 'sort-down', {
            'ReactVirtualized__Table__sortableHeaderIcon--ASC': sortDirection === SortDirection.ASC,
            'ReactVirtualized__Table__sortableHeaderIcon--DESC': sortDirection === SortDirection.DESC,
        });
        return (
            <div className="header-container" ref={dataKey === HomeTableColumn.GROUP ? this.groupColumnRef : undefined}>
                <span
                    className="ReactVirtualized__Table__headerTruncatedText"
                    key={label ? label.toString() : ''}
                    title={typeof label === 'string' ? label : ''}
                >
                    {label}
                </span>
                {sortBy === dataKey && <img src={sortDown} alt={'Sort Indicator'} className={sortIndicatorClassNames} />}
            </div>
        );
    };
}

const AccessLevelRank: { [key in AccessLevel]: number } = {
    [AccessLevel.OWNER]: 3,
    [AccessLevel.ADMIN]: 2,
    [AccessLevel.USER]: 1,
    [AccessLevel.NONE]: 0,
};

const hasName = (owner: NonNullable<HomeView['ownerDetails']>): owner is SmartsheetUser => 'name' in owner;
const numberCompare = (a: number, b: number) => b - a;
const CompareFunctions: WeakHomeTableColumnInfo<(a: HomeView, b: HomeView) => number> = {
    [HomeTableColumn.NAME]: withNullChecks(compareStrings, (o) => o.name),
    [HomeTableColumn.OWNER]: withNullChecks(compareStrings, (o) => {
        if (!o.ownerDetails) {
            return '';
        }
        return hasName(o.ownerDetails) && (o.ownerDetails.name ? o.ownerDetails.name : o.ownerDetails.email);
    }),
    [HomeTableColumn.MY_PERMISSION]: withNullChecks(numberCompare, (o) => o.accessLevel && AccessLevelRank[o.accessLevel]),
    [HomeTableColumn.LAST_ACCESSED]: withNullChecks(numberCompare, (o) => o.lastAccessed && new Date(o.lastAccessed).getTime()),
    [HomeTableColumn.DESCRIPTION]: withNullChecks(compareStrings, (o) => o.description),
    [HomeTableColumn.GROUP]: withNullChecks(compareStrings, (o) =>
        o.sharedWithGroups ? o.sharedWithGroups.map((group) => group.name).join('') : ''
    ),
};

const mapState = createStructuredSelector({
    sortBy: Selectors.sortBy,
    sortDirection: Selectors.sortDirection,
});

const mapDispatch: DispatchProps = {
    setSortBy: Actions.setSortBy,
    setSortDirection: Actions.setSortDirection,
};

export default withLanguageElementsHOC(connect<StateProps, DispatchProps>(mapState, mapDispatch)(HomeTable));
