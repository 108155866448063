import { datadogRum } from '@datadog/browser-rum';
import { SyntheticEvent } from 'react';
import { UserActionDurationContext } from './UserActionDurationContext';
import { isGovSite } from '../utils/IsGovSite';

export enum ActionType {
    USER_ACTION = 'USER_ACTION',
    DURATION = 'DURATION',
}

export class UserAnalyticsAction {
    public static addFromEvent(event: SyntheticEvent, context?: object, nameOverride?: string): void {
        const dataClientId = event.currentTarget.getAttribute('data-client-id');
        const actionType = event.type;
        const actionName = dataClientId ? `${actionType} ${dataClientId}` : actionType;
        const actionContext = {
            dataClientId,
            'customAction.type': actionType,
            ...context,
        };
        this.add(ActionType.USER_ACTION, nameOverride ? nameOverride : actionName, actionContext);
    }

    public static recordSyncDuration<T>(
        name: string,
        fn: (actionDuration: UserActionDurationContext) => T,
        parentActionDurationContext?: UserActionDurationContext
    ): T {
        const actionDurationContext = new UserActionDurationContext(name, parentActionDurationContext);

        const start = Date.now();
        let hasError = false;

        try {
            return fn(actionDurationContext);
        } catch (error) {
            hasError = true;
            throw error;
        } finally {
            try {
                const duration = Date.now() - start;

                this.add(ActionType.DURATION, actionDurationContext.name, {
                    duration,
                    hasError,
                });
            } catch {
                // This is a call and forget type of request, ignore error and continue. We do not want to have errors logging info to impact
                // user experience.
            }
        }
    }

    public static async recordAsyncDuration<T>(
        name: string,
        fn: (actionDuration: UserActionDurationContext) => Promise<T>,
        parentActionDurationContext?: UserActionDurationContext
    ): Promise<T> {
        const actionDurationContext = new UserActionDurationContext(name, parentActionDurationContext);

        const start = Date.now();
        let hasError = false;

        try {
            return await fn(actionDurationContext);
        } catch (error) {
            hasError = true;
            throw error;
        } finally {
            try {
                const duration = Date.now() - start;

                this.add(ActionType.DURATION, actionDurationContext.name, {
                    duration,
                    hasError,
                });
            } catch {
                // This is a call and forget type of request, ignore error and continue. We do not want to have errors logging info to impact
                // user experience.
            }
        }
    }

    public static add(type: ActionType, name: string, context?: object): void {
        // disable for all gov domains
        if (isGovSite()) {
            return;
        }

        datadogRum.addAction(name, { ...context, type });
    }
}
