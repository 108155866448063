import { TransferResult } from '../common/classes';
import { OwnershipTransferStatus } from '../common/enums';
import { QueryStringKey } from '../common/enums/QueryStringKey.enum';
import * as dv from '../common/interfaces';
import { buildUrl } from '../common/utils';
import axiosInstance from './Axios.instance';

class OwnershipTransferClient {
    public static getInstance(): OwnershipTransferClient {
        if (!OwnershipTransferClient.instance) {
            OwnershipTransferClient.instance = new OwnershipTransferClient();
        }
        return OwnershipTransferClient.instance;
    }

    private static instance: OwnershipTransferClient;

    private static readonly url = '/transfers';

    private constructor() {}

    public async create(viewId: string, email: string): Promise<dv.IOwnershipTransfer> {
        const data = {
            viewId,
            email,
        };
        const response = await axiosInstance.post(`${OwnershipTransferClient.url}`, data);
        return response.data;
    }

    public async get(status?: OwnershipTransferStatus, vid?: string): Promise<dv.IPaginatedResult<dv.IOwnershipTransfer>> {
        const url = buildUrl(OwnershipTransferClient.url, {
            queryParams: {
                [QueryStringKey.STATUS]: status,
                [QueryStringKey.VID]: vid,
            },
        });

        const response = await axiosInstance.get(url);

        return response.data;
    }

    public async update(transferId: string, status: OwnershipTransferStatus): Promise<TransferResult> {
        const response = await axiosInstance.patch(`${OwnershipTransferClient.url}/${transferId}`, { status });
        return response.data;
    }
}
const ownershipTransferClient = OwnershipTransferClient.getInstance();
export default ownershipTransferClient;
