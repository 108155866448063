import * as React from 'react';
import { CellMeasurer, CellMeasurerCache, TableCellProps } from 'react-virtualized';

interface Props {
    tableCellProps: TableCellProps;
    rowHeightCache: CellMeasurerCache | undefined;
}

export const CellMeasurerWrapper: React.SFC<Props> = props => {
    return (
        <div
            className="wrapped-cell"
        >
            <CellMeasurer
                cache={props.rowHeightCache!}
                columnIndex={props.tableCellProps.columnIndex}
                key={props.tableCellProps.dataKey}
                parent={props.tableCellProps.parent}
                rowIndex={props.tableCellProps.rowIndex}
            >
                {props.children}
            </CellMeasurer>
        </div>
    );
};
