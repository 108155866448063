export function adjustHeight(element: HTMLElement | null): void {
    if (!element) {
        return;
    }

    const height = Math.max(element.scrollHeight, element.clientHeight);
    element.style.height = `${height}px`;
    /* To prevent scroll bar flickering as the textarea expands */
    if (height >= 500) {
        element.style.overflowY = 'auto';
    }
}
