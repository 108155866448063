import { AccessLevel } from '../../common/enums';
import * as React from 'react';
import { Link } from 'react-router-dom';
import viewIcon from '../../assets/images/icons/back_to_view_icon_blue.svg';
import { AutomationIds } from '../../common/enums/AutomationElements.enum';
import AdminOptionMenu from '../../components/AdminOptionMenu';
import { WithDataClientProps } from '../../components/hoc/WithDataClient';
import { useLanguageElements } from '../../language-elements/withLanguageElementsHOC';
import './AdminPanel.css';
import { AdminPanelTab, AdminPanelTabs } from './interfaces/AdminPanelTabs.interface';

interface AdminHeaderProps extends WithDataClientProps {
    viewId?: string;
    viewName?: string;
    activeSub: string;
    adminPanelTabs: AdminPanelTabs;
    onDeleteClick: () => Promise<void>;
    onSaveAsNewClick: (e: React.MouseEvent) => Promise<void>;
    onClickNavLink: (key: string) => void;
    onKeyDownNavLink: (e: any, key: string) => void;
    userAccessLevel: AccessLevel | undefined;
}

/**
 * Responsible for rendering the tabs within the Admin Panel as well as indicating
 * when a tab has been clicked, or selected via the keyboard.
 */
const AdminHeader = (props: AdminHeaderProps) => {
    const languageElements = useLanguageElements();

    const adminPanelTabs = Object.keys(props.adminPanelTabs).map((adminPanelTabName: string) => {
        const adminPanelTab: AdminPanelTab = props.adminPanelTabs[adminPanelTabName as keyof AdminPanelTabs];
        return (
            <li
                key={adminPanelTab.route}
                className={props.activeSub === adminPanelTab.route ? 'active' : ''}
                onClick={() => props.onClickNavLink(adminPanelTab.route)}
                onKeyDown={(e) => props.onKeyDownNavLink(e, adminPanelTab.route)}
                tabIndex={adminPanelTab.tabIndex}
                role="button"
                data-client-id={`${AutomationIds.ADMIN_HEADER_PANEL_INDEX}${adminPanelTab.tabIndex}`}
            >
                <span className="tab-title">{adminPanelTab.title}</span>
            </li>
        );
    });

    return (
        <div>
            {props.viewId && (
                <div className="admin-panel panel">
                    <Link to={`/views/${props.viewId!}`} className="admin-panel-grid-link">
                        <img src={viewIcon} alt={'view icon'} />
                        <span data-client-id={AutomationIds.ADMIN_HEADER_BACKLINK}>{languageElements.BACK_TO_VIEW}</span>
                    </Link>
                    <div className="admin-panel-title">
                        <span>
                            {languageElements.ADMIN_PANEL_HEADER_SETTINGS}
                            <span data-client-id={AutomationIds.ADMIN_HEADER_TITLE}>{props.viewName!}</span>
                        </span>
                    </div>
                    {(props.userAccessLevel === AccessLevel.OWNER || props.userAccessLevel === AccessLevel.ADMIN) && (
                        <AdminOptionMenu
                            classname="admin-panel-header-buttons"
                            dataClientId={AutomationIds.ADMIN_HEADER_OPTION_MENU}
                            deleteView={props.onDeleteClick}
                            saveAsNew={props.onSaveAsNewClick}
                        />
                    )}
                    <ul className="nav">{adminPanelTabs}</ul>
                </div>
            )}
        </div>
    );
};

export default AdminHeader;
