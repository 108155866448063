import { MAX_SOURCEAPP_CHARS, REGEX_PATTERN_SOURCE_APP } from '../../../../common/constants';
import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { AutomationIds } from '../../../../common/enums/AutomationElements.enum';
import Button from '../../../../components/Buttons/Button/Button';
import { CreateViewModal } from '../../../../components/CreateView/CreateViewModal';
import CreateViewRouteHandler from '../../../../components/CreateView/CreateViewRouteHandler';
import { LanguageElementsProp, withLanguageElementsHOC } from '../../../../language-elements/withLanguageElementsHOC';
import { ActionByType } from '../../../../store';
import { HomeSourceFilter } from '../../../App/Reducers';
import { homeSourceFilterSelector } from '../../../App/Selectors';
import { ActionTypes as HomeActionTypes, Actions as HomeActions } from '../../../Home/Actions';
import './ViewNewItem.css';

interface Props {
    text?: string;
    controlId: AutomationIds;
    class: string;
    tooltip?: string;
    icon?: any;
}

interface StateProps {
    homeSourceFilter: HomeSourceFilter;
}

interface DispatchProps {
    setShowPermissionsError: (showPermissionsError: boolean) => ActionByType<HomeActions, HomeActionTypes.SHOW_PERMISSIONS_ERROR_ON_CREATE>;
}

class ViewNewItem extends React.Component<Props & LanguageElementsProp & StateProps & DispatchProps> {
    public state = {
        isCreateViewModalOpen: false,
        displaySheetPicker: false,
    };
    private queryParams = new URLSearchParams(window.location.search);
    private sourceAppParamValue = this.queryParams.get('source');
    private sourceApp =
        this.sourceAppParamValue && this.sourceAppParamValue.length <= MAX_SOURCEAPP_CHARS && this.sourceAppParamValue.match(REGEX_PATTERN_SOURCE_APP)
            ? this.sourceAppParamValue
            : undefined;

    public render(): React.ReactNode {
        return (
            <>
                <Button
                    onClick={this.handleToggleCreateNewViewModal}
                    className={this.props.class}
                    dataClientId={this.props.controlId}
                    text={this.props.text}
                    icon={this.props.icon}
                    imageAltText={this.props.languageElements.CREATE_VIEW_ALT_TEXT}
                    tooltip={this.props.tooltip}
                />
                {this.state.isCreateViewModalOpen &&
                    (!this.state.displaySheetPicker && this.props.homeSourceFilter?.id && this.props.homeSourceFilter?.isReport !== undefined ? (
                        <CreateViewRouteHandler
                            viewSourceId={this.props.homeSourceFilter.id}
                            isReport={this.props.homeSourceFilter.isReport}
                            sourceApp={this.sourceApp}
                            displaySheetPicker={() => this.setState({ displaySheetPicker: true })}
                        />
                    ) : (
                        <CreateViewModal
                            toggleCreateViewModal={this.handleToggleCreateNewViewModal}
                            isCreateViewModalOpen={this.state.isCreateViewModalOpen}
                            displaySheetPicker={() => this.setState({ displaySheetPicker: false })}
                            sourceApp={this.sourceApp}
                        />
                    ))}
            </>
        );
    }

    /**
     * Simple method that changes app state to open/close the New View Modal
     */
    private handleToggleCreateNewViewModal = () => {
        this.setState({ isCreateViewModalOpen: !this.state.isCreateViewModalOpen });
        this.props.setShowPermissionsError(false);
    };
}

const mapState = createStructuredSelector({
    homeSourceFilter: homeSourceFilterSelector,
});

const mapDispatch: DispatchProps = {
    setShowPermissionsError: HomeActions.showPermissionsError,
};

export default withLanguageElementsHOC(connect<StateProps, DispatchProps>(mapState, mapDispatch)(ViewNewItem));
