import { SelectV2 as LsSelectV2 } from '@smartsheet/lodestar-core';
import { SupportedLocales } from '@smartsheet/lodestar-core/dist/esm/i18n/constants';
import { HandleContacts } from '../../../common/classes';
import {
    CURRENT_USER,
    CURRENT_USER_DEFAULT_EMAIL,
    CURRENT_USER_DEFAULT_NAME,
    MAX_CUSTOM_LABEL_CHARS,
    MAX_MULTI_CONTACTS,
} from '../../../common/constants';
import { ColumnType, SymbolSetName } from '../../../common/enums';
import { Column, Contact, FormFieldInterface, IPaginatedResult, PicklistItem, SelectV2Option, SmartsheetUser } from '../../../common/interfaces';
import { getObjectValueFromDefaultValue, isContactObjectValue, isStringNumber } from '../../../common/utils';
import * as React from 'react';
import { ChangeEvent } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import { Option } from 'react-select';
import 'reflect-metadata';
import { createStructuredSelector } from 'reselect';
import { AutomationIds, AutomationTypes } from '../../../common/enums/AutomationElements.enum';
import { disableFreeTextNewOptions } from '../../../common/utils/DisableFreeTextNewOptions';
import { getOptions, getSelectedItem } from '../../../common/utils/GetOptions';
import * as gsFormattingUtility from '../../../common/utils/GsFormattingUtility';
import { mapUserToContact, mapUsersToContacts } from '../../../common/utils/MapUserToContact';
import { checkIfOnDemandOptionsShouldBeEnabled } from '../../../components/ContactPicker/CheckIfOnDemandOptionsShouldBeEnabled';
import ContactPicker from '../../../components/ContactPicker/ContactPicker';
import DateSelector from '../../../components/DateSelector';
import FormFieldMultiPicklist from '../../../components/FormFieldWrapper/FormFieldMultiPicklist';
import FormFieldPicklist from '../../../components/FormFieldWrapper/FormFieldPicklist';
import FormFieldSymbolPicklist from '../../../components/FormFieldWrapper/FormFieldSymbolPicklist';
import TextNumber from '../../../components/TextNumber/TextNumber';
import ToggleWrapper from '../../../components/Toggle';
import { ToggleComponent } from '../../../components/Toggle/Toggle.interface';
import { WithDataClientProps } from '../../../components/hoc/WithDataClient';
import { LanguageElementsProp, withLanguageElementsHOC } from '../../../language-elements/withLanguageElementsHOC';
import { StoreState } from '../../../store';
import { gsGetColumnsFeatureSelector, isDisabledContactPickerOnDemandSelector } from '../../App/Selectors';
import { shortLocaleSelector } from '../../Auth/Selectors';

interface Props extends WithDataClientProps {
    field: FormFieldInterface;
    onFieldChange: (field: FormFieldInterface) => void;
    selectedColumn: Column;
    smartsheetUsers: IPaginatedResult<SmartsheetUser>;
    smartsheetUsersByEmails: IPaginatedResult<SmartsheetUser>;
}

type ComposedProps = Props & StateProps & LanguageElementsProp;

interface Toggles {
    requireToggle: ToggleComponent;
    readOnlyToggle: ToggleComponent;
    hideToggle: ToggleComponent;
}

interface State {
    showCustomLabel: boolean;
    contactOptions?: Contact[] | undefined;
}

/**
 * TODO: Update when we decide on message to display
 * TODO: saves do not persist after you change tabs.
 * TODO: try to fix the refreshing data. probably need to get data form the store in an update
 * Does not display most current data until you refresh. some thing needs to refresh data in the upper containers.
 */
export class FieldSettingsContainer extends React.Component<ComposedProps> {
    public toggles: Toggles;
    public state: State;
    private currentUser = {
        id: 0,
        name: CURRENT_USER_DEFAULT_NAME,
        email: CURRENT_USER_DEFAULT_EMAIL,
    };
    private handleContacts: HandleContacts;
    private defaultValue: any;

    public constructor(props: ComposedProps) {
        super(props);
        this.handleContacts = new HandleContacts();
        this.toggles = {
            requireToggle: {
                key: 'requireToggle',
                label: props.languageElements.ADMIN_PANEL_DETAILS_TOGGLE_LABEL_REQUIRE,
                onChange: this.onUpdateRequireToggle,
            },
            readOnlyToggle: {
                key: 'readOnlyToggle',
                label: props.languageElements.ADMIN_PANEL_DETAILS_TOGGLE_LABEL_READ_ONLY,
                onChange: this.onUpdateReadOnlyToggle,
            },
            hideToggle: {
                key: 'hideToggle',
                label: props.languageElements.ADMIN_PANEL_DETAILS_TOGGLE_LABEL_HIDE,
                onChange: this.onUpdateHideToggle,
            },
        };

        // Setting a private defaultValue property that is used throughout component instead of using props.field.defaultValue. This is to address a
        // bug when selecting a default value for a contact picker causing the name to not appear because the props.field.defaultValue is set to a
        // string containing the email address, but the contact picker component needs an value that is a contactObjectValue. In the future we need to
        // fix this to always pass a contact object value around.
        this.defaultValue = props.field.defaultValue;

        this.state = {
            showCustomLabel: false,
        };
    }

    public UNSAFE_componentWillUpdate(nextProps: ComposedProps): void {
        if (this.props.selectedColumn.id !== nextProps.selectedColumn.id) {
            this.defaultValue = nextProps.field.defaultValue;
        }
    }

    public render(): React.ReactNode {
        let displayAsInput: any;
        let defaultValue: any;
        const formFieldDefaultValue = getObjectValueFromDefaultValue(
            this.defaultValue,
            this.props.field.type!,
            CURRENT_USER_DEFAULT_EMAIL,
            CURRENT_USER_DEFAULT_NAME,
            true
        );
        // Check for enabled feature to route calls to GS and apply formatting
        const gsFormattingEnabled = this.props.gsGetColumnsFeature;
        // Check the flag for disable the contact picker on-depand in gov env
        const isDisabledContactPickerOnDemand = this.props.isDisabledContactPickerOnDemand;
        const getSelectedOption: PicklistItem = {
            value: this.props.field.multiline ? 'multiple' : 'single',
            label: this.props.field.multiline ? 'Multiple Line' : 'Single Line',
        };

        if (this.props.field) {
            switch (this.props.field.type) {
                case ColumnType.TEXT_NUMBER:
                    displayAsInput = (
                        <div className="form-entry" data-client-type={AutomationTypes.FIELD_SETTINGS_FORM_ENTRY}>
                            <b className="field-property-label" data-client-id={AutomationIds.FIELD_SETTINGS_PROP_LABEL}>
                                {this.props.languageElements.ADMIN_PANEL_DETAILS_DISPLAY_AS}
                            </b>
                            <div className="select-wrapper">
                                <span />
                                <LsSelectV2
                                    clientId={AutomationIds.FIELD_SETTINGS_SELECT}
                                    options={[
                                        {
                                            value: 'single',
                                            label: 'Single Line',
                                        },
                                        {
                                            value: 'multiple',
                                            label: 'Multiple Line',
                                        },
                                    ]}
                                    selectedOption={getSelectedOption}
                                    placeholder={'display as'}
                                    onChange={this.onDisplayAsChange}
                                    getOptionKey={(option: PicklistItem) => {
                                        return option.label ? option.label : '';
                                    }}
                                    locale={'en'}
                                />
                            </div>
                        </div>
                    );
                    defaultValue = (
                        <TextNumber
                            dataClientId={AutomationIds.FIELD_SETTINGS_TEXTNUMBER}
                            rows={this.props.field.multiline ? 3 : 1}
                            value={this.defaultValue != null ? (formFieldDefaultValue as string) : ''}
                            onChange={this.onTextNumberDefaultValueChange}
                            numberFormatObject={
                                this.props.selectedColumn && this.props.selectedColumn.format
                                    ? gsFormattingUtility.getNumberFormatObjectAll(this.props.selectedColumn.format, gsFormattingEnabled)
                                    : undefined
                            }
                            fadeSymbol={!isStringNumber(formFieldDefaultValue)}
                        />
                    );
                    break;

                case ColumnType.PICKLIST:
                    const options = getOptions(
                        this.props.selectedColumn.type,
                        this.props.selectedColumn.symbol as SymbolSetName,
                        this.props.selectedColumn.options
                    ) as PicklistItem[];

                    const selectedOption = getSelectedItem(
                        this.props.selectedColumn.options,
                        this.props.selectedColumn.symbol as SymbolSetName,
                        formFieldDefaultValue as string
                    ) as PicklistItem;

                    const isSymbol = options?.some((option: PicklistItem) => option.symbol) || selectedOption?.symbol;
                    if (isSymbol) {
                        defaultValue = (
                            <FormFieldSymbolPicklist
                                dataClientId={AutomationIds.FIELD_SETTINGS_PICKLIST}
                                inputIndex={0}
                                validation={this.props.selectedColumn.validation}
                                options={options}
                                selectedOption={selectedOption}
                                onChange={this.onPicklistDefaultValueChange}
                                isSettingsMode={false}
                            />
                        );
                    } else {
                        defaultValue = (
                            <FormFieldPicklist
                                dataClientId={AutomationIds.FIELD_SETTINGS_PICKLIST}
                                inputIndex={0}
                                validation={this.props.selectedColumn.validation}
                                options={options}
                                selectedOption={selectedOption}
                                onChange={this.onPicklistDefaultValueChange}
                                isSettingsMode={false}
                            />
                        );
                    }
                    break;

                case ColumnType.MULTI_PICKLIST:
                    defaultValue = (
                        <FormFieldMultiPicklist
                            inputIndex={0}
                            dataClientId={AutomationIds.FIELD_SETTINGS_MULTI_PICKLIST}
                            validation={this.props.selectedColumn.validation}
                            options={this.props.selectedColumn.options || []}
                            onChange={this.onMultiPicklistDefaultValueChange}
                            isSettingsMode={false}
                            value={this.props.field.defaultValue}
                        />
                    );
                    break;

                case ColumnType.DATE:
                    defaultValue = (
                        <DateSelector
                            date={formFieldDefaultValue as string}
                            onChange={this.handleDateChange}
                            dataClientId={AutomationIds.FIELD_SETTINGS_DATESELECTOR}
                            disabled={false}
                        />
                    );
                    break;
                case ColumnType.CHECKBOX:
                    // TODO: Using strings here instead of booleans. We should update view entity to allow booleans
                    defaultValue = (
                        <div className="select-wrapper">
                            <span />
                            <select
                                onChange={this.onCheckboxDefaultValueChange}
                                value={formFieldDefaultValue != null ? (formFieldDefaultValue as string) : 'false'}
                            >
                                <option value={'true'}>{this.props.languageElements.ADMIN_PANEL_DETAILS_CHECKED}</option>
                                <option value={'false'}>{this.props.languageElements.ADMIN_PANEL_DETAILS_NOT_CHECKED}</option>
                            </select>
                        </div>
                    );
                    break;

                // To find the correct contact value to display (users name, followed by email), first determine if the default value is
                // the current user value. If it is, set the formatted value to the Current User. If it is not the current user value,
                // and the fields validation is set to true, then search the contactOptions for the given field. If the fields validation
                // is set to false, search the provided Smartsheet users.
                case ColumnType.CONTACT_LIST:
                    let formattedContact: undefined | Contact[];
                    const contactEnableOnDemand =
                        !isDisabledContactPickerOnDemand && checkIfOnDemandOptionsShouldBeEnabled(this.state.contactOptions);
                    const contactDefaultValue = formFieldDefaultValue;

                    if (contactDefaultValue) {
                        if (Array.isArray(contactDefaultValue)) {
                            const stringInitialValue = this.handleContacts.getStringFromContactArray(contactDefaultValue, false);
                            defaultValue = (
                                <TextNumber
                                    dataClientId={AutomationIds.FIELD_SETTINGS_TEXTNUMBER}
                                    rows={this.props.field.multiline ? 3 : 1}
                                    value={stringInitialValue}
                                    disabled={true}
                                    onChange={this.onTextNumberDefaultValueChange}
                                />
                            );
                            break;
                        } else if (isContactObjectValue(contactDefaultValue)) {
                            formattedContact = [contactDefaultValue];
                        } else if (contactDefaultValue === CURRENT_USER_DEFAULT_EMAIL) {
                            formattedContact = [this.handleContacts.getFormattedContact(CURRENT_USER)];
                        } else {
                            if (this.props.selectedColumn.validation && this.props.selectedColumn.contactOptions) {
                                formattedContact = [
                                    this.handleContacts.getFormattedContact(
                                        contactDefaultValue as string,
                                        undefined,
                                        false,
                                        this.props.selectedColumn.contactOptions
                                    ),
                                ];
                            } else {
                                formattedContact = [
                                    this.handleContacts.getFormattedContact(
                                        contactDefaultValue as string,
                                        undefined,
                                        false,
                                        mapUsersToContacts(this.props.smartsheetUsersByEmails.data)
                                    ),
                                ];
                            }
                        }
                    }

                    defaultValue = (
                        <ContactPicker
                            dataClientId={AutomationIds.FIELD_SETTINGS_CONTACTPICKER}
                            allOptions={this.state.contactOptions}
                            selectedOptions={formattedContact}
                            isValidNewOption={this.props.selectedColumn.validation ? disableFreeTextNewOptions : undefined}
                            enableOnDemandOptions={contactEnableOnDemand}
                            allowClearing={true}
                            onChange={this.onContactPickerChange}
                            multi={false}
                            inPlace={true}
                        />
                    );
                    break;
                case ColumnType.MULTI_CONTACT_LIST:
                    // Handle special case if a single col was changed to multi because a previously saved default
                    // will be a string instead of an array, which is needed for multi-contacts.
                    const formDefaultValue = this.handleContacts.getMultiContactsIfValid(formFieldDefaultValue, undefined, false);
                    const mutliContactEnableOnDemand =
                        !isDisabledContactPickerOnDemand && checkIfOnDemandOptionsShouldBeEnabled(this.state.contactOptions);

                    defaultValue = (
                        <ContactPicker
                            dataClientId={AutomationIds.FIELD_SETTINGS_MULTI_CONTACT_LIST}
                            allOptions={this.state.contactOptions}
                            selectedOptions={formDefaultValue as Contact[]}
                            isValidNewOption={this.props.selectedColumn.validation ? disableFreeTextNewOptions : undefined}
                            enableOnDemandOptions={mutliContactEnableOnDemand}
                            allowClearing={true}
                            onChange={this.onContactPickerChange}
                            disabled={this.props.field.readOnly}
                            multi={true}
                            inPlace={true}
                        />
                    );
                    break;
                default:
                    defaultValue = (
                        <TextNumber
                            dataClientId={AutomationIds.FIELD_SETTINGS_TEXTNUMBER}
                            rows={this.props.field.multiline ? 3 : 1}
                            value={formFieldDefaultValue ? (formFieldDefaultValue as string) : ''}
                            onChange={this.onTextNumberDefaultValueChange}
                        />
                    );
                    break;
            }
        }
        return this.props.field ? (
            <div data-client-id={this.props.dataClientId}>
                <div className="form-entry" data-client-type={AutomationTypes.FIELD_SETTINGS_FORM_ENTRY}>
                    <b className="field-property-label" data-client-id={AutomationIds.FIELD_SETTINGS_PROP_LABEL}>
                        {this.props.languageElements.ADMIN_PANEL_DETAILS_FIELD_NAME}
                    </b>
                    <input type="text" value={this.props.field.title} disabled={true} data-clientid={AutomationIds.FIELD_SETTINGS_INPUT} />
                </div>
                <div className="form-entry" data-client-type={AutomationTypes.FIELD_SETTINGS_FORM_ENTRY}>
                    <div className="field-name">
                        <b className="field-property-label" data-client-id={AutomationIds.FIELD_SETTINGS_PROP_LABEL}>
                            {this.props.languageElements.ADMIN_PANEL_DETAILS_CUSTOM_LABEL}
                            <i
                                className={'icon-chevron-' + (this.state.showCustomLabel ? 'down' : 'right')}
                                onClick={this.showCustomLabel}
                                data-client-id={AutomationIds.FIELD_SETTINGS_CUSTOM_LABEL_EXPAND_COLLAPSE}
                            />
                        </b>
                        {this.state.showCustomLabel && (
                            <input
                                type="text"
                                value={this.props.field.customLabel}
                                maxLength={MAX_CUSTOM_LABEL_CHARS}
                                onChange={this.onLabelChange}
                                data-client-id={AutomationIds.FIELD_SETTINGS_CUSTOM_LABEL_INPUT}
                            />
                        )}
                    </div>
                </div>
                <div className="form-entry" data-client-type={AutomationTypes.FIELD_SETTINGS_FORM_ENTRY}>
                    <ToggleWrapper
                        key={this.toggles.requireToggle.key}
                        checked={this.props.field.required || false}
                        toggle={this.toggles.requireToggle}
                        dataClientId={AutomationIds.FIELD_SETTINGS_TOGGLE_WRAPPER_1}
                        dataClientType={AutomationTypes.TOGGLE_INPUT}
                    />
                    <ToggleWrapper
                        key={this.toggles.readOnlyToggle.key}
                        checked={this.props.field.readOnly || Boolean(this.props.selectedColumn.formula) || false}
                        disabled={Boolean(this.props.selectedColumn.formula)}
                        toggle={this.toggles.readOnlyToggle}
                        dataClientId={AutomationIds.FIELD_SETTINGS_TOGGLE_WRAPPER_2}
                        dataClientType={AutomationTypes.TOGGLE_INPUT}
                    />
                    <ToggleWrapper
                        key={this.toggles.hideToggle.key}
                        checked={this.props.field.hidden || false}
                        toggle={this.toggles.hideToggle}
                        dataClientId={AutomationIds.FIELD_SETTINGS_TOGGLE_WRAPPER_3}
                        dataClientType={AutomationTypes.TOGGLE_INPUT}
                    />
                </div>
                {displayAsInput}
                <div className="form-entry" data-client-type={AutomationTypes.FIELD_SETTINGS_FORM_ENTRY}>
                    <b className="field-property-label" data-client-id={AutomationIds.FIELD_SETTINGS_PROP_LABEL}>
                        {this.props.languageElements.ADMIN_PANEL_DETAILS_DESCRIPTION}
                    </b>
                    <input
                        type="text"
                        maxLength={4000}
                        value={this.props.field.description || ''}
                        data-field="description"
                        onChange={this.onDescriptionChange}
                        data-client-id={AutomationIds.FIELD_SETTINGS_DESCRIPTION_INPUT}
                    />
                </div>
                <div className="form-entry" data-client-type={AutomationTypes.FIELD_SETTINGS_FORM_ENTRY}>
                    <span className="d-block">
                        <b className="field-property-label" data-client-id={AutomationIds.FIELD_SETTINGS_PROP_LABEL}>
                            {this.props.languageElements.ADMIN_PANEL_DETAILS_INITIAL_VALUE}
                            <small> ({this.props.languageElements.ADMIN_PANEL_DETAILS_INITIAL_VALUE_DESCRIPTION})</small>
                        </b>
                    </span>
                    {defaultValue}
                </div>
            </div>
        ) : (
            <div />
        );
    }

    public componentDidMount(): void {
        this.defaultValue = this.props.field.defaultValue;
        if (this.props.selectedColumn.type === ColumnType.CONTACT_LIST || this.props.selectedColumn.type === ColumnType.MULTI_CONTACT_LIST) {
            const contactOptions = this.getContactOptions(this.defaultValue);
            this.setState({ contactOptions });
        }
    }

    public componentDidUpdate(prevProps: ComposedProps): void {
        // Update contactOptions when selected column is Contact List.
        if (this.props.selectedColumn.id !== prevProps.selectedColumn.id) {
            this.defaultValue = this.props.field.defaultValue;
            if (this.props.selectedColumn.type === ColumnType.CONTACT_LIST || this.props.selectedColumn.type === ColumnType.MULTI_CONTACT_LIST) {
                const contactOptions = this.getContactOptions(this.defaultValue);
                this.setState({ contactOptions });
            }
        }
    }

    /**
     * Handles the selection callback for the contact picker which can return undefined, single contact,
     * or an array of contacts.
     *
     * TODO: How do we alert the user that the limit is 20?
     */
    public onContactPickerChange = (contactValue: undefined | Contact | Contact[]) => {
        if (contactValue && !Array.isArray(contactValue)) {
            // Setting this.defaultValue to contact object value to fix bug when selecting a default value for a contact picker causing the name to
            // not appear because the props.field.defaultValue is set to a string containing the email address, but the contact picker component needs
            // an value that is a contactObjectValue. In the future we need to fix this to always pass a contact object value around.
            this.defaultValue = contactValue;

            this.props.field.defaultValue = this.defaultValue.email || this.defaultValue.name;
            if (contactValue.email === CURRENT_USER) {
                this.props.field.readOnly = true;
                if (this.props.field.required) {
                    this.props.field.required = false;
                }
            }
        } else if (contactValue && Array.isArray(contactValue)) {
            if (((contactValue as Contact[]) || []).length > MAX_MULTI_CONTACTS) {
                contactValue = (contactValue as Contact[]).splice(0, MAX_MULTI_CONTACTS);
            }
            this.props.field.defaultValue = this.defaultValue = contactValue.length ? contactValue : undefined;
        } else {
            this.props.field.defaultValue = this.defaultValue = contactValue;
        }
        this.setState({ contactOptions: this.getContactOptions(this.defaultValue) });
        this.props.onFieldChange(this.props.field);
    };

    public handleDateChange = (date: string | undefined): void => {
        this.props.field.defaultValue = this.defaultValue = date;
        this.props.onFieldChange(this.props.field);
    };

    public onUpdateRequireToggle = (): void => {
        this.props.field.required = !this.props.field.required;
        if (this.props.field.required) {
            if (this.props.field.hidden) {
                this.props.field.hidden = false;
            }
            if (this.props.field.readOnly) {
                this.props.field.readOnly = false;
            }
        }
        this.props.onFieldChange(this.props.field);
    };

    public onUpdateReadOnlyToggle = (): void => {
        this.props.field.readOnly = !this.props.field.readOnly;
        if (this.props.field.readOnly) {
            if (this.props.field.required) {
                this.props.field.required = false;
            }
        }
        this.props.onFieldChange(this.props.field);
    };

    public onUpdateHideToggle = (): void => {
        this.props.field.hidden = !this.props.field.hidden;
        if (this.props.field.hidden && this.props.field.required) {
            this.props.field.required = false;
        }
        this.props.onFieldChange(this.props.field);
    };

    private onDescriptionChange = (e: ChangeEvent<HTMLInputElement>): void => {
        this.props.field.description = e.currentTarget.value;
        this.props.onFieldChange(this.props.field);
    };

    private getContactOptions = (defaultValue: undefined | Contact | Contact[]): undefined | Contact[] => {
        const contactOptions = this.props.selectedColumn.validation
            ? [...(this.props.selectedColumn.contactOptions || [])]
            : mapUsersToContacts(this.props.smartsheetUsers.data);
        if (defaultValue) {
            if (Array.isArray(defaultValue) && !defaultValue.some((contact: Contact) => contact.name === CURRENT_USER_DEFAULT_NAME)) {
                contactOptions.unshift(mapUserToContact(this.currentUser));
            }
            if (!Array.isArray(defaultValue) && defaultValue.name !== CURRENT_USER_DEFAULT_NAME) {
                contactOptions.unshift(mapUserToContact(this.currentUser));
            }
        } else {
            contactOptions.unshift(mapUserToContact(this.currentUser));
        }
        return contactOptions;
    };

    private onTextNumberDefaultValueChange = (e: ChangeEvent<HTMLInputElement>): void => {
        this.props.field.defaultValue = this.defaultValue = e.currentTarget.value;
        this.props.onFieldChange(this.props.field);
    };

    private onLabelChange = (e: ChangeEvent<HTMLInputElement>): void => {
        this.props.field.customLabel = e.currentTarget.value;
        this.props.onFieldChange(this.props.field);
    };

    private onDisplayAsChange = (option: Option<string>): void => {
        this.props.field.multiline = option.value === 'multiple';
        this.props.onFieldChange(this.props.field);
    };

    private onPicklistDefaultValueChange = (item: string): void => {
        this.props.field.defaultValue = this.defaultValue = item ? item : undefined;
        this.props.onFieldChange(this.props.field);
    };

    private onMultiPicklistDefaultValueChange = (options: SelectV2Option[]): void => {
        if (options && options.values.length > 0) {
            this.props.field.defaultValue = this.defaultValue = options;
        } else {
            this.props.field.defaultValue = this.defaultValue = undefined;
        }
        this.props.onFieldChange(this.props.field);
    };

    private onCheckboxDefaultValueChange = (e: ChangeEvent<HTMLSelectElement>): void => {
        this.props.field.defaultValue = this.defaultValue = e.target.value;
        this.props.onFieldChange(this.props.field);
    };

    private showCustomLabel = (): void => {
        this.setState({ showCustomLabel: !this.state.showCustomLabel });
    };
}

interface StateProps {
    locale?: SupportedLocales;
    gsGetColumnsFeature: boolean;
    isDisabledContactPickerOnDemand: boolean;
}

const mapState = () =>
    createStructuredSelector<StoreState, StateProps>({
        locale: shortLocaleSelector,
        gsGetColumnsFeature: gsGetColumnsFeatureSelector,
        isDisabledContactPickerOnDemand: isDisabledContactPickerOnDemandSelector,
    });

export default withLanguageElementsHOC(connect<StateProps, null, ComposedProps>(mapState)(FieldSettingsContainer));
