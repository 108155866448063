import * as React from 'react';
import Toggle from 'react-toggle';
import { AutomationIds, AutomationTypes } from '../../common/enums/AutomationElements.enum';
import { WithDataClientProps } from '../hoc/WithDataClient';
import './Toggle.css';
import { ToggleComponent } from './Toggle.interface';

interface Props extends WithDataClientProps {
    checked: boolean;
    toggle: ToggleComponent;
    disabled?: boolean;
}

class ToggleWrapper extends React.Component<Props> {
    public constructor(props: Props) {
        super(props);
    }

    public componentDidUpdate(prevProps: Props): void {
        this.updateCheckedAttribute(document.getElementById(this.props.toggle.key) as HTMLInputElement);
    }

    public render(): React.ReactNode {
        return(
            <label
                className="app-toggle"
                key={this.props.toggle.key}
                data-client-type={AutomationTypes.TOGGLE_LABEL}
            >
                <Toggle
                    disabled={this.props.disabled}
                    id={this.props.toggle.key}
                    checked={this.props.checked}
                    onChange={e => this.onChange(e)}
                    data-client-id={AutomationIds.TOGGLE_WRAPPER_TOP}
                />
                <span
                    className="react-toggle-label"
                    data-client-id={this.props.dataClientId}
                >
                    {this.props.toggle.label}
                </span>
            </label>
        );
    }

    public onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.updateCheckedAttribute(e.target);
        this.props.toggle.onChange(e);
    }

    private updateCheckedAttribute(element: HTMLInputElement | null): void {
        if (element) {
            if (element.checked) {
                element.setAttribute('checked', '');
            } else {
                element.removeAttribute('checked');
            }
        }
    }
}

export default ToggleWrapper;
