/**
 * An item/row in a MenuList. Supports an icon, label, help text, and inline controls that are
 * visible when hovering.
 */

import { MouseEvent } from 'react';
import * as React from 'react';
import { AutomationIds } from '../../../../common/enums/AutomationElements.enum';

export interface MenuItemCaptionProps {
    caption?: string | React.ReactNode;
    captionStringId?: string;
    captionStringParameters?: string[];
}

export interface MenuItemProps extends Pick<MenuItemCaptionProps,
    'caption' |
    'captionStringId' |
    'captionStringParameters'> {

    controlId: string;
    controlType?: string;
    className?: string;
    isEnabled?: boolean;
    icon?: string;
    label?: string | React.ReactNode;
    labelStringId?: string;
    inlineControls?: React.ReactNode;
    onClick?: (value?: any) => void;
    onKeyDown?: (e: React.KeyboardEvent) => void;
    value?: any;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    hasFocus?: boolean;
}

export class MenuItem extends React.Component<MenuItemProps> {

    public static defaultProps = {
        isEnabled: true,
    };

    private refLi: HTMLLIElement | null;

    public componentDidMount(): void {
        if (this.refLi && this.props.hasFocus) {
            this.refLi.focus();
        }
    }

    public render(): React.ReactNode {
        const {
            controlId,
            controlType,
            isEnabled,
            icon,
            inlineControls,
            onMouseEnter,
            onMouseLeave,
            label,
            value,
        } = this.props;

        return (
            <li
                data-client-type={controlType}
                data-client-id={controlId}
                className={`menu-item ${this.props.className||''}`}
                onClick={isEnabled ? this.handleClick : undefined}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onKeyDown={this.onKeyDown}
                tabIndex={0}
                ref={c => { this.refLi = c; }}
            >
                {icon && value.shared && value.isValid &&
                <div
                    className={'icon'}
                    data-client-id={AutomationIds.FILTER_LIST_SHARING_ICON}
                >
                    {<img src={icon}/>}
                </div>
                }
                {icon && !value.isValid &&
                    <div
                        className={'icon'}
                        data-client-id={AutomationIds.FILTER_LIST_WARNING_ICON}
                    >
                        {<img src={icon}/>}
                    </div>
                }
                <div className="label-group">
                    <div
                        className="label"
                        data-client-id={AutomationIds.FILTER_LIST_FILTER_LABEL}
                    >
                        {label}
                    </div>
                </div>
                {inlineControls && (
                    <div className="controls">
                        {inlineControls}
                    </div>
                )}
            </li>
        );
    }

    private handleClick = (e: MouseEvent<HTMLElement> | null): void => {
        const { value, onClick } = this.props;

        if (onClick) {
            onClick(value);
        }
    }

    // Handle <Enter> or <Space> to select the current item. Else let parent <MenuList> handle navigation keys
    // TODO: (note from app-core) This implementation can't prevent events from getting to non-React world.
    //  See https://medium.com/@ericclemmons/react-event-preventdefault-78c28c950e46
    private onKeyDown = (e: React.KeyboardEvent): void => {
        if (this.props.isEnabled && (e.key === '13' || e.key === '32')) {
            this.handleClick(null);
        } else if (this.props.onKeyDown) {
            this.props.onKeyDown(e);
        }
    }
}
