import { FormFieldInterface } from '../../../interfaces';
import { IRowFieldValidator } from './IRowFieldValidator';
import { RowFieldBaseValidator, RowFieldValueIsValidResult } from './RowFieldBaseValidator';

export class CheckboxValidator extends RowFieldBaseValidator implements IRowFieldValidator {
    public validate(rowField: FormFieldInterface): RowFieldValueIsValidResult {
        let rowFieldValueIsValidResult = super.validateMandatoryProperties(rowField);
        if (rowFieldValueIsValidResult) {
            return rowFieldValueIsValidResult;
        }

        rowFieldValueIsValidResult = super.validatePrimitiveValue(rowField);
        if (!rowFieldValueIsValidResult.isValid) {
            return rowFieldValueIsValidResult;
        }

        if (rowField.validation) {
            // Allow null when restricted value check enable to ensure the DV user can clear values within a row field
            if (rowField.value === null) {
                return {
                    isValid: true,
                };
            }

            return {
                isValid: (typeof rowField.value === 'boolean'),
            };
        }

        return {
            isValid: true,
        };
    }
}
