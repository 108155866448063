import { ColumnType } from '../enums';

export const isDateColumnType = (columnType?: ColumnType): boolean => {
    if (!columnType) {
        return false;
    }

    return Boolean(
        columnType === ColumnType.DATE ||
            columnType === ColumnType.DATETIME ||
            columnType === ColumnType.ABSTRACTDATETIME ||
            columnType === ColumnType.ABSTRACT_DATETIME
    );
};
