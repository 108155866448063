import { Column, FormFieldInterface } from '../../../common/interfaces';
import * as React from 'react';
import { DragDropContext, DragStart, DropResult } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import { AutomationIds, AutomationTypes } from '../../../common/enums/AutomationElements.enum';
import DroppableContainer from '../../../components/DragAndDrop/DroppableContainer';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';
import { StoreState } from '../../../store';
import { makeSelectConfigDisplayAttachments, makeSelectConfigDisplayComments } from '../../Admin/Selectors';
import AvailableFormField from '../AvailableFormField';
import '../DroppableContainer/DroppableContainer.css';
import '../FormContainer.css';
import IncludedFormField from '../IncludedFormField/IncludedFormField';
import DetailsPanelLayout, { DetailsPanel } from './DetailsPanelLayout';
import './FormList.css';

export interface OwnProps {
    availableFields: Column[];
    includedFields: FormFieldInterface[];
    width: number;
    onChange: (included: FormFieldInterface[]) => void;
    itemClick: any;
    onDetailsPanelLayoutChange: (valuesToUpdate: Partial<DetailsPanel>) => void;
    detailsPanelLayout: DetailsPanel;
}

interface StateProps {
    displayAttachments: boolean;
    displayComments: boolean;
}

export type FormListProps = OwnProps & StateProps;

const FormList = (props: FormListProps) => {
    const languageElements = useLanguageElements();
    let includedItems: FormFieldInterface[] = [];
    const filteredAvailable: FormFieldInterface[] = props.availableFields
        .filter((availableField: Column) => {
            return !props.includedFields.find((includedField: FormFieldInterface) => includedField.columnId === availableField.id);
        })
        .map((availableField: Column, index: number) => {
            return {
                ordinal: index,
                columnId: availableField.id,
                title: availableField.title,
                type: availableField.type,
                contactOptions: availableField.contactOptions,
                format: availableField.format,
                formula: availableField.formula,
            } as FormFieldInterface;
        });

    props.includedFields.forEach((includedField: FormFieldInterface, index: number) => {
        const availableField = props.availableFields.find((field: Column) => field.id === includedField.columnId);
        if (availableField) {
            includedField.ordinal = index;
            includedItems.push(includedField);
        }
    });
    const showRequired = includedItems.some((includedField: FormFieldInterface) => includedField.required!);

    const reorder = (list: FormFieldInterface[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragStart = (initial: DragStart) => {};

    const onDragEnd = (result: DropResult) => {
        let includedChanged: boolean = false;

        // remove from availableItems list, add to includedItems list
        if (
            result.source &&
            result.source.droppableId === 'available-droppable' &&
            result.destination &&
            result.destination.droppableId === 'included-droppable'
        ) {
            addIncluded(result.source.index, result.destination.index);
            includedChanged = true;
        }

        // remove from includedItems list, add to availableItems list
        if (
            result.source &&
            result.source.droppableId === 'included-droppable' &&
            result.destination &&
            result.destination.droppableId === 'included-remove'
        ) {
            removeIncluded(result.source.index);
            includedChanged = true;
        }

        // reorder availableItems in includedItems list
        if (
            result.source &&
            result.source.droppableId === 'included-droppable' &&
            result.destination &&
            result.destination.droppableId === 'included-droppable'
        ) {
            includedItems = reorder(includedItems, result.source.index, result.destination.index);
            updateOrdinal(includedItems);
            includedChanged = true;
        }

        // only send change event if includedFields items change
        if (includedChanged) {
            const includedValues: FormFieldInterface[] = getIncludedValues();
            props.onChange(includedValues);
        }
    };

    const addIncluded = (availableIndex: number, includedIndex: number) => {
        const item = filteredAvailable.splice(availableIndex, 1)[0];
        updateOrdinal(filteredAvailable);

        includedItems.splice(includedIndex, 0, {
            ...item,
            required: false,
            readOnly: Boolean(item.formula),
            hidden: false,
        });
        updateOrdinal(includedItems);
    };

    const removeIncluded = (includedIndex: number) => {
        const item = includedItems.splice(includedIndex, 1)[0];
        updateOrdinal(includedItems);
        filteredAvailable.splice(0, 0, item);
        sortAvailableItems(filteredAvailable);
        updateOrdinal(filteredAvailable);
    };

    const onAddEventHandler = (index: number) => {
        addIncluded(index, includedItems.length);
        props.onChange(getIncludedValues());
    };

    const onRemoveEventHandler = (index: number) => {
        removeIncluded(index);
        props.onChange(getIncludedValues());
    };

    const updateOrdinal = (items: FormFieldInterface[]) => {
        let ordinal = 0;
        for (const item of items) {
            if (item) {
                item.ordinal = ordinal++;
            }
        }
    };

    const findAvailableItemIndex = (item: FormFieldInterface) => {
        let itemIndex: number = -1;
        props.availableFields.forEach((i, index) => {
            if (i.title === item.title) {
                itemIndex = index;
            }
        });
        return itemIndex;
    };

    const sortAvailableItems = (items: FormFieldInterface[]) => {
        items.sort((a: FormFieldInterface, b: FormFieldInterface) => {
            if (findAvailableItemIndex(a) < findAvailableItemIndex(b)) {
                return -1;
            } else {
                return 1;
            }
        });
    };

    const getIncludedValues = () => {
        const includedValues: FormFieldInterface[] = [];
        for (const item of includedItems) {
            if (item && item.type) {
                includedValues.push(item);
            }
        }
        return includedValues;
    };

    //  Prior to rendering, update the ordinals of the included items to handle cases
    //  where a column has been deleted from the sheet, and the indexes/ordinals are out of sync.
    updateOrdinal(includedItems);
    return (
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
            <div className="add-fields-container multi-display-fields source-items">
                <DroppableContainer
                    droppableId="available-droppable"
                    width={props.width}
                    className="display-fields"
                    isDropDisabled={true}
                    dataClientId={AutomationIds.FORM_LIST_DROPPABLE_CONTAINER_1}
                >
                    <p className="container-header">{languageElements.FORM_CONTAINER_ADD_FIELD_TITLE}</p>
                    <p className="container-sub-header">
                        {languageElements.AVAILABLE_FIELDS} ({filteredAvailable.length || 0})
                    </p>
                    {filteredAvailable.map((item, index) => (
                        <AvailableFormField
                            key={item.ordinal}
                            model={item}
                            dataClientType={AutomationTypes.AVAILABLE_FORM_FIELD_TYPE}
                            onAddEvent={() => {
                                onAddEventHandler(index);
                            }}
                        />
                    ))}
                </DroppableContainer>
                <DroppableContainer
                    droppableId="included-remove"
                    width={props.width}
                    style={{
                        visibility: 'hidden',
                    }}
                    className="display-fields drop-zone"
                    isDropDisabled={false}
                    dataClientId={AutomationIds.FORM_LIST_DROPPABLE_CONTAINER_2}
                >
                    <b/>
                </DroppableContainer>
            </div>
            <DroppableContainer
                droppableId="included-droppable"
                width={props.width}
                className="edit-form-container display-fields multi-display-fields target-items"
                isDropDisabled={false}
                dataClientId={AutomationIds.FORM_LIST_DROPPABLE_CONTAINER_3}
            >
                <DetailsPanelLayout
                    showRequired={showRequired}
                    onChange={props.onDetailsPanelLayoutChange}
                    detailsPanelLayout={props.detailsPanelLayout}
                    displayAttachments={props.displayAttachments}
                    displayComments={props.displayComments}
                />
                {includedItems.map((item: FormFieldInterface, index: number) => (
                    <IncludedFormField
                        key={item.ordinal}
                        model={item}
                        index={index}
                        dataClientType={AutomationTypes.FORM_LIST_INCLUDED_FORM_FIELD}
                        onRemoveEvent={() => {
                            onRemoveEventHandler(index);
                        }}
                        itemClick={props.itemClick.bind(null, item.columnId)}
                        readOnly={(item as FormFieldInterface).readOnly || false}
                    />
                ))}
                {!includedItems.length && (
                    <div className="empty-included-items">
                        <span>Add fields here</span>
                    </div>
                )}
            </DroppableContainer>
        </DragDropContext>
    );
};

const mapState = createStructuredSelector<StoreState, StateProps>({
    displayAttachments: createSelector(makeSelectConfigDisplayAttachments(), (displayAttachments) => displayAttachments),
    displayComments: createSelector(makeSelectConfigDisplayComments(), (displayComments) => displayComments),
});

export default connect(mapState)(FormList);
