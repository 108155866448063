import { ImageUrlDto as ImageUrl, ImageUrlMapDto } from '../../common/interfaces';
import { fromJS, Map } from 'immutable';
import { Actions, ActionTypes } from './Actions';

export enum StateProps {
    IMAGES = 'images',
    THUMBNAILS = 'thumbnails',
    GRID = 'grid',

    // TODO: Would be better for expiration to be part of the image / image url object. We don't currently have a good separation between objects
    //       returned by the DV API and objects used in the front-end app.  If we had different interfaces, we could add expiration dates to the
    //       image url objects returned by the DV API, but for now we'll track it separately.
    EXPIRATIONS = 'expirations',
}

export interface ImagesState {
    [StateProps.IMAGES]: Map<ImageUrl['imageId'], ImageUrl>;
    [StateProps.THUMBNAILS]: Map<ImageUrl['imageId'], ImageUrl>;
    [StateProps.GRID]: Map<ImageUrl['imageId'], ImageUrl>;
    [StateProps.EXPIRATIONS]: Map<ImageUrl['url'], Date>;
}

const initialState = fromJS({
    [StateProps.IMAGES]: Map<ImageUrl['imageId'], ImageUrl>(),
    [StateProps.THUMBNAILS]: Map<ImageUrl['imageId'], ImageUrl>(),
    [StateProps.GRID]: Map<ImageUrl['imageId'], ImageUrl>(),
    [StateProps.EXPIRATIONS]: Map<ImageUrl['url'], Date>(),
});

const reducer = (state = initialState, action: Actions): any => {
    switch (action.type) {
        case ActionTypes.STORE_THUMBNAIL_URLS:
            return storeImageUrls(action.payload as ImageUrlMapDto, StateProps.THUMBNAILS);

        case ActionTypes.CLEAR_THUMBNAIL_URL:
            return clearImageUrl(action.payload as string, StateProps.THUMBNAILS);

        case ActionTypes.STORE_IMAGE_URLS:
            return storeImageUrls(action.payload as ImageUrlMapDto, StateProps.IMAGES);

        case ActionTypes.CLEAR_IMAGE_URL:
            return clearImageUrl(action.payload as string, StateProps.IMAGES);

        case ActionTypes.STORE_GRID_URLS:
            return storeImageUrls(action.payload as ImageUrlMapDto, StateProps.GRID);

        case ActionTypes.CLEAR_GRID_URL:
            return clearImageUrl(action.payload as string, StateProps.GRID);
    }
    return state;

    /// region Helper Functions
    function storeImageUrls(
        { imageUrls, urlExpiresInMillis }: ImageUrlMapDto,
        stateProp: StateProps.IMAGES | StateProps.THUMBNAILS | StateProps.GRID
    ): any {
        // Update our map of image url expiration dates
        const expirationDate = new Date(Date.now() + urlExpiresInMillis - 1000); // Expire the image url 1 second early.
        const expirationsStateProp: ImagesState[StateProps.EXPIRATIONS] = state.get(StateProps.EXPIRATIONS);
        const updatedExpirationsState = expirationsStateProp.withMutations((mutable) =>
            imageUrls.forEach((imageUrl: ImageUrl) => mutable.set(imageUrl.url, expirationDate))
        );

        // Update our map of image urls
        const imageUrlsState: ImagesState[StateProps.IMAGES | StateProps.THUMBNAILS] = state.get(stateProp);
        const updatedImageUrlsState = imageUrlsState.withMutations((mutable) =>
            imageUrls.forEach((imageUrl: ImageUrl) => mutable.set(imageUrl.imageId, imageUrl))
        );

        // Replace the relevant state props with the updated image url and expiration date maps
        return state.set(StateProps.EXPIRATIONS, updatedExpirationsState).set(stateProp, updatedImageUrlsState);
    }

    function clearImageUrl(imageId: string, stateProp: StateProps.IMAGES | StateProps.THUMBNAILS | StateProps.GRID): any {
        // Look up the image url based on image id.
        const imageUrls: ImagesState[StateProps.IMAGES | StateProps.THUMBNAILS] = state.get(stateProp);
        const imageUrl = imageUrls.get(imageId);
        if (!imageUrl) {
            return state;
        }

        // Remove the image url object.
        const updatedImageUrls = imageUrls.delete(imageId);
        const updatedState = state.set(stateProp, updatedImageUrls);

        // Look up the expiration dates associated with the image url object.
        const expirations: ImagesState[StateProps.EXPIRATIONS] = state.get(StateProps.EXPIRATIONS);
        const expiration = expirations.get(imageUrl.url);
        if (!expiration) {
            return updatedState;
        }

        // Remove the expiration date for the cleaned up image url object.
        const updatedExpirations = expirations.delete(imageUrl.url);
        return updatedState.set(StateProps.EXPIRATIONS, updatedExpirations);
    }
    /// endregion
};

export default reducer;
