import { AccessLevel } from '../../common/enums';
import { HomeView } from '../../common/interfaces';
import { HomeFilter } from '../../common/enums/HomeFilter';
import { HomeViewFilter } from '../../common/utils';
import { getLocaleDate } from '../../common/utils/MomentDates';
import { LanguageElements } from '../../language-elements/LanguageElements';
import { HomeSourceFilter } from '../App/Reducers';
import { getAccessLevelLabels, HomeTableColumn, HomeTableColumnInfo } from './HomeTableColumnData';

const homeTableColumnSearchValue: HomeTableColumnInfo<(view: HomeView, languageElements: LanguageElements) => string | undefined> = {
    [HomeTableColumn.NAME]: (view) => view.name,
    [HomeTableColumn.OWNER]: (view) => view.ownerDetails && (('name' in view.ownerDetails && view.ownerDetails.name) || view.ownerDetails.email),
    [HomeTableColumn.MY_PERMISSION]: (view, languageElements) => getAccessLevelLabels(view.accessLevel as AccessLevel, languageElements),
    [HomeTableColumn.LAST_ACCESSED]: (view) => view.lastAccessed && getLocaleDate(view.lastAccessed),
    [HomeTableColumn.DESCRIPTION]: (view) => view.description,
    [HomeTableColumn.GROUP]: (view) => view.sharedWithGroups && view.sharedWithGroups.map((group) => group.name).join(','),
};

export const searchAndFilterViews = (
    views: HomeView[],
    filter: HomeFilter,
    sourceFilter: HomeSourceFilter | undefined,
    searchString: string,
    languageElements: LanguageElements
) => {
    let filteredViews = views.filter(HomeViewFilter[filter]);

    if (sourceFilter) {
        filteredViews = filteredViews.filter((filteredView) => filteredView.viewSourceId === sourceFilter.id);
    }

    const sanitizeSearchString = searchString.replace(/[-[\]{}()+?.,*\\^$|]/g, '\\$&').trim();

    if (!sanitizeSearchString) {
        return filteredViews;
    }
    const homeTableColumns = Object.keys(HomeTableColumn) as HomeTableColumn[];

    return filteredViews.filter((filteredView) => {
        return homeTableColumns.some((column) => {
            const cellValue = homeTableColumnSearchValue[column](filteredView, languageElements) || '';
            let regexp = false;
            // A new regEx instance is created each time because when the global flag set, test() will advance the lastIndex of the regex. Further
            // calls to test(sanitizeSearchString) will resume searching sanitizeSearchString starting from lastIndex. The lastIndex property will
            // continue to increase each time test() returns true.
            try {
                regexp = new RegExp(sanitizeSearchString, 'gi').test(cellValue);
            } finally {
                return regexp;
            }
        });
    });
};
