import styled from '@emotion/styled';
import { colors } from '@smartsheet/lodestar-core';
import * as React from 'react';
import { TableRowProps } from 'react-virtualized';
import { AdminControlsRowData } from './types';

interface Props extends TableRowProps {
    key: string;
    ownershipTransferRowData: AdminControlsRowData;
    style: object;
}

const AdminControlsRowElement: React.FC<Props> = ({ key, style, columns }) => {
    return (
        <RowData key={key} role="row" style={style}>
            {columns}
        </RowData>
    );
};

const RowData = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    background-color: ${colors.neutralLight40};
    font-size: 13px;
    color: ${colors.neutralDark40};
    border-bottom: 1px solid ${colors.neutral};
    box-sizing: border-box;
    height: 50px;

    & {
        p,
        .outer-cell {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        p {
            margin: 0;
            font-size: 13px;
        }
    }
`;

export default AdminControlsRowElement;
