import { AsyncStatus, HttpStatusCodes } from '../../../common/enums';
import { FormFieldInterface, Image } from '../../../common/interfaces';
import { AxiosError } from 'axios';
import { Epic, ofType } from 'redux-observable';
import { EMPTY, from, iif, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { ActionType, UserAnalyticsAction } from '../../../common/metrics/UserAnalyticsAction';
import { isAxiosErrorWithResponse } from '../../../common/utils/isAxiosErrorWithResponse';
import { loggingClient } from '../../../http-clients/Logging.client';
import viewClient from '../../../http-clients/View.client';
import { ActionByType } from '../../../store';
import { Actions as ImageActions } from '../../../store/images/Actions';
import { pollingDelayFeatureSelector } from '../../App/Selectors';
import { Actions as ViewActions } from '../Actions';
import { rowSheetIdSelector } from '../Selectors';
import { Actions, ActionTypes } from './Actions';
import { generateRowFieldsForUpdateRowRequest, generateTempTrackingId, pollingRowUpdate, TEMP_TRACKING_ID } from './EpicUtils';
import { rowUpsertsSelector, selectOverallStatusForRow } from './Selectors';

const FILENAME = 'Details/Epic.ts';

/*
 * This function subscribes to the UPDATE_VIEW_ROW action and performs the following:
 *
 * Calls the PATCH /views/:viewId/rows/:rowId endpoint to update the data
 * Calls the UPSERT_VIEW_ROW_IN_PROGRESS action using the returned tracking ID
 */
export const updateViewRowEpic: Epic<Actions | ViewActions> = (action$, state$) =>
    action$.pipe(
        ofType(ActionTypes.UPDATE_VIEW_ROW),
        mergeMap((action: ActionByType<Actions, ActionTypes.UPDATE_VIEW_ROW>) => {
            const start = Date.now();
            const tempTrackingId = generateTempTrackingId();
            const { viewId, rowId, cellImages, originalForm, submittedForm } = action.payload;
            const sheetId = rowSheetIdSelector(state$.value, rowId) ?? undefined;

            // If only cell images have changed and there are no other changes to the row's data, then no need to call viewClient.updateViewRow().
            if (submittedForm.length < 1) {
                return of(
                    Actions.upsertViewRowImages({
                        viewId,
                        rowId,
                        cellImages,
                        trackingId: tempTrackingId,
                        isNewSubmission: false,
                        updatedForm: originalForm,
                        startTimeForUpsert: start,
                        sheetId,
                    })
                );
            }

            const existingRowUpserts = rowUpsertsSelector(state$.value, rowId);
            const rowFieldsForUpdateRowRequest = generateRowFieldsForUpdateRowRequest(existingRowUpserts, submittedForm);

            return from(viewClient.updateViewRow(viewId, rowId, rowFieldsForUpdateRowRequest, sheetId)).pipe(
                map((formUpdate) => {
                    const trackingId = formUpdate.trackingId ?? tempTrackingId;

                    return Actions.upsertViewRowImages({
                        viewId,
                        rowId,
                        cellImages,
                        trackingId,
                        isNewSubmission: false,
                        updatedForm: formUpdate.form,
                        sheetId: formUpdate.sheetId,
                        startTimeForUpsert: start,
                    });
                }),
                catchError((error: AxiosError) =>
                    of(
                        Actions.upsertViewRowFailure({
                            viewId,
                            rowId,
                            isNewSubmission: false,
                            trackingId: tempTrackingId,
                            error,
                        })
                    )
                )
            );
        })
    );

/*
 * This function subscribes to the INSERT_VIEW_ROW action and performs the following:
 *
 * Calls the PUT /views/:viewId/rows/:rowId/tracking endpoint to update the data
 * Calls the UPSERT_VIEW_ROW_IN_PROGRESS action using the returned tracking ID
 */
export const insertViewRowEpic: Epic<Actions | ViewActions> = (action$) =>
    action$.pipe(
        ofType(ActionTypes.INSERT_VIEW_ROW),
        mergeMap((action: ActionByType<Actions, ActionTypes.INSERT_VIEW_ROW>) => {
            const start = Date.now();
            const tempTrackingId = generateTempTrackingId();
            const { viewId, submittedForm, cellImages } = action.payload;

            return from(viewClient.insertViewRow(viewId, submittedForm)).pipe(
                mergeMap((insertResponse) => {
                    const trackingId = insertResponse.trackingId ?? tempTrackingId;
                    const { rowId } = insertResponse;
                    const actions: Actions[] = [
                        Actions.upsertViewRowImages({
                            viewId,
                            rowId,
                            cellImages,
                            trackingId,
                            isNewSubmission: true,
                            sheetId: insertResponse.sheetId,
                            updatedForm: insertResponse.form,
                            startTimeForUpsert: start,
                        }),
                    ];

                    return actions;
                }),
                catchError((error: AxiosError) =>
                    of(Actions.upsertViewRowFailure({ viewId, trackingId: tempTrackingId, error, isNewSubmission: true }))
                )
            );
        })
    );

/*
 *
 */
export const insertCellImagesEpic: Epic<Actions | ImageActions | ViewActions> = (action$) =>
    action$.pipe(
        ofType(ActionTypes.UPSERT_VIEW_ROW_IMAGES),
        mergeMap((action: ActionByType<Actions, ActionTypes.UPSERT_VIEW_ROW_IMAGES>) => {
            const start = Date.now();
            const { viewId, rowId, cellImages, isNewSubmission, trackingId, sheetId, startTimeForUpsert } = action.payload;
            let { updatedForm } = action.payload;

            // clear out images from updatedForm if images are removed
            if (cellImages.scheduledForRemoval.size > 0 && updatedForm) {
                const copyForm: FormFieldInterface[] = [];
                updatedForm.forEach((field: FormFieldInterface) => {
                    copyForm.push({ ...field, image: cellImages.scheduledForRemoval.has(field.columnId) ? undefined : field.image });
                });
                updatedForm = copyForm;
            }

            // If there are no cell images to upload, then dispatch the final actions.
            if (cellImages.attachedForUpload.size < 1) {
                return of(Actions.upsertViewRowInProgress({ viewId, rowId, updatedForm, isNewSubmission, trackingId, sheetId, startTimeForUpsert }));
            }

            // Otherwise log that we're inserting cell images and continue.
            loggingClient.logInfo({
                file: FILENAME,
                message: 'Inserting cell image(s)',
                viewId,
                rowId,
                isNewSubmission,
                trackingId,
                sheetId,
                totalCellImages: cellImages.attachedForUpload.size,
            });

            // Each cell image that we insert is an independent HTTP request.
            // Set them all up to work in parallel, but save their responses into an array.
            // TODO: Think about whether this is a smart idea.  We may not be able to tell which image upload failed.
            const insertCellImageRequests = new Array<Promise<{ columnId: number; images: Image[] }>>();
            cellImages.attachedForUpload.forEach((file: File, columnId: number) =>
                insertCellImageRequests.push(
                    viewClient.insertCellImage(viewId, parseInt(rowId, 10), columnId, file, cellImages.altText.get(columnId), sheetId)
                )
            );

            const processCellImageResponses = (cellImageInsertions: Array<{ columnId: number; images: Image[] }>) =>
                cellImageInsertions.reduce((acc, { images }) => {
                    return acc.concat(images);
                }, new Array<Image>());

            return from(Promise.all(insertCellImageRequests)).pipe(
                tap(() => {
                    const duration = Date.now() - start;

                    loggingClient.logInfo({
                        file: FILENAME,
                        message: 'Cell image(s) inserted',
                        viewId,
                        rowId,
                        isNewSubmission,
                        trackingId,
                        sheetId,
                        totalCellImages: cellImages.attachedForUpload.size,
                        duration,
                    });

                    UserAnalyticsAction.add(ActionType.DURATION, 'InsertCellImages', {
                        duration,
                        hasError: false,
                        isNewSubmission,
                    });
                }),

                mergeMap((cellImageInsertions) => [
                    Actions.upsertViewRowInProgress({
                        viewId,
                        rowId,
                        updatedForm,
                        isNewSubmission,
                        trackingId,
                        sheetId,
                        startTimeForUpsert,
                    }),

                    // Dispatching the FETCH_IMAGE_URLS action will cause image urls to be generated for newly inserted cell images.
                    ImageActions.fetchImageUrls(processCellImageResponses(cellImageInsertions)),
                ]),

                catchError((error: AxiosError) => {
                    loggingClient.logError(FILENAME, 'insertCellImagesEpic', error, {
                        viewId,
                        rowId,
                        isNewSubmission,
                        trackingId,
                        sheetId,
                        totalCellImages: cellImages.attachedForUpload.size,
                    });

                    const actions: Array<Actions | ViewActions> = [
                        Actions.upsertViewRowFailure({
                            viewId,
                            rowId: rowId.toString(),
                            trackingId,
                            sheetId,
                            isNewSubmission,
                            error,
                        }),
                    ];

                    // Refresh view source data if error is Not Found & viewId exists in the viewRowUpserts map
                    if (isAxiosErrorWithResponse(error) && error.response.status === HttpStatusCodes.NOT_FOUND) {
                        actions.push(ViewActions.removeGridRow(viewId, rowId.toString()));
                    }

                    return actions;
                })
            );
        })
    );

/*
 * This function subscribes to the UPSERT_VIEW_ROW_IN_PROGRESS action and performs the following:
 *
 * Begins polling based on the tracking id until a response is returned from the grid service
 * Calls the UPSERT_VIEW_ROW_SUCCESS action to mark the update/insert as completed
 *
 * On Error calls the UPSERT_VIEW_ROW_FAILURE action to mark the status for the RowUpsert as Error
 */
export const upsertViewRowInProgressEpic: Epic<Actions | ViewActions> = (action$, state$) =>
    action$.pipe(
        ofType(ActionTypes.UPSERT_VIEW_ROW_IN_PROGRESS),
        tap((action: ActionByType<Actions, ActionTypes.UPSERT_VIEW_ROW_IN_PROGRESS>) => {
            const { viewId, rowId, isNewSubmission, trackingId, sheetId, startTimeForUpsert } = action.payload;
            const duration = Date.now() - startTimeForUpsert;

            loggingClient.logInfo({
                file: FILENAME,
                message: 'Upsert view row - Success',
                viewId,
                rowId,
                trackingId,
                sheetId,
                isNewSubmission,
                duration,
            });

            UserAnalyticsAction.add(ActionType.DURATION, 'UpsertRowComplete', {
                duration,
                hasError: false,
                isNewSubmission,
            });
        }),
        mergeMap((action: ActionByType<Actions, ActionTypes.UPSERT_VIEW_ROW_IN_PROGRESS>) => {
            const startTimeForSyncProcess = Date.now();
            const { viewId, rowId, isNewSubmission, trackingId, sheetId } = action.payload;

            // If only images were submitted, we have no tracking number and can skip this step
            if (trackingId.startsWith(TEMP_TRACKING_ID)) {
                return of(Actions.upsertViewRowSuccess({ viewId, rowId, isNewSubmission, trackingId, startTimeForSyncProcess }));
            }

            // Check if polling delay feature is enabled, then set initial polling delay based on setting
            const pollingDelayFeature = pollingDelayFeatureSelector(state$.value);
            let pollingDelay;
            if (pollingDelayFeature && pollingDelayFeature.isEnabled) {
                pollingDelay = pollingDelayFeature.value && typeof pollingDelayFeature.value === 'number' ? pollingDelayFeature.value : undefined;
            }

            return pollingRowUpdate({
                response: { trackingId, sheetId, errors: [] },
                viewId,
                rowId,
                isNewSubmission,
                pollingDelay,
            }).pipe(
                map(() => Actions.upsertViewRowSuccess({ viewId, rowId, isNewSubmission, trackingId, startTimeForSyncProcess })),
                catchError((error: AxiosError) => of(Actions.upsertViewRowFailure({ viewId, rowId, trackingId, error, isNewSubmission, sheetId })))
            );
        })
    );

/*
 * This function subscribes to the UPSERT_VIEW_ROW_SUCCESS action and performs the following:
 *
 * Checks the overall row status in the store. If all values are done, then fetch the row for the grid
 * If there are still updates in progress, then do nothing
 */
export const upsertViewRowSuccessEpic: Epic<Actions | ViewActions> = (action$, state$) =>
    action$.pipe(
        ofType(ActionTypes.UPSERT_VIEW_ROW_SUCCESS),
        mergeMap((action: ActionByType<Actions, ActionTypes.UPSERT_VIEW_ROW_SUCCESS>) => {
            const { viewId, rowId, isNewSubmission, startTimeForSyncProcess } = action.payload;

            // Get overall status of row
            const status = selectOverallStatusForRow(state$.value, rowId);

            if (status === AsyncStatus.DONE) {
                // Add reducer for fetchGridRow that clears out all "DONE" rowUpserts for this row ID
                return of(ViewActions.fetchGridRow(viewId, rowId, !isNewSubmission, startTimeForSyncProcess));
            } else {
                return EMPTY;
            }
        })
    );

/*
 * This function subscribes to the UPSERT_VIEW_ROW_FAILURE action and performs the following:
 *
 * Checks if the error status was NOT_FOUND, and if so, removes the row from the grid view
 */
export const upsertViewRowFailureEpic: Epic<Actions | ViewActions> = (action$) =>
    action$.pipe(
        ofType(ActionTypes.UPSERT_VIEW_ROW_FAILURE),
        tap((action: ActionByType<Actions, ActionTypes.UPSERT_VIEW_ROW_FAILURE>) => {
            const { viewId, rowId, isNewSubmission, trackingId, sheetId, error } = action.payload;

            loggingClient.logError(FILENAME, 'upsertViewRowFailureEpic', error, {
                viewId,
                rowId,
                trackingId,
                sheetId,
                isNewSubmission,
            });

            UserAnalyticsAction.add(ActionType.DURATION, 'UpsertRowFailed', {
                hasError: true,
                isNewSubmission,
            });
        }),
        mergeMap((action: ActionByType<Actions, ActionTypes.UPSERT_VIEW_ROW_FAILURE>) => {
            const { viewId, rowId, error } = action.payload;

            // What we're doing below is like returning a monad -- we always return something
            // but we let RXJS's `iif` function figure out if it should be the action we chose
            // or EMPTY (the default). See https://www.learnrxjs.io/learn-rxjs/operators/conditional/iif
            return iif(
                () => isAxiosErrorWithResponse(error) && error.response.status === HttpStatusCodes.NOT_FOUND,
                of(ViewActions.removeGridRow(viewId, rowId.toString()))
            );
        })
    );
