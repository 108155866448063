import { isValid, format, parse } from 'date-fns';

export const parseDateInput = (input?: string, dateFormat?: string): Date | undefined => {
    if (input === undefined || input === '') {
        return undefined;
    }
    // default date parsing if format not specified
    const today = new Date();
    let date;
    if (dateFormat) {
        // if we have the dateFormat, use that to parse the date
        date = parse(input, dateFormat, today);
    } else if (/\d{4}-\d{2}-\d{2}/.test(input) && !input.includes('T')) {
        // if we have yyyy-MM-dd format but no timezone specified...
        date = parse(input, 'yyyy-MM-dd', new Date());
        // use date-fns to parse. sets time to 00:00 in local timezone
    }

    if (isValid(date)) {
        return date;
    } else {
        // as a last ditch effort, try JS default parsing
        const lastTry = new Date(input);
        return isValid(lastTry) ? lastTry : undefined;
    }
};

export const formattedDateString = (input: string | undefined, dateFormat: string): string => {
    const date = parseDateInput(input);
    return date !== undefined ? format(date, dateFormat) : '';
};

export const isValidDateString = (input: string | undefined, dateFormat?: string): boolean => {
    if (input && dateFormat) {
        return isValid(parse(input, dateFormat, new Date()));
    }
    if (typeof input === 'string') {
        return isValid(new Date(input));
    }
    return false;
};

export const getLocaleDate = (date: Date) => {
    return new Date(date).toLocaleDateString([], {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
};

export const getLocaleTime = (date: Date) => {
    const time = new Date(date).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
    });

    // Drop leading zero if present
    return time.slice(0, 1) === '0' ? time.slice(1) : time;
};
