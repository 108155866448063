import * as React from 'react';
import AccountMenu from './AccountMenu/AccountMenu';
import DynamicViewTitle from './DynamicViewTitle/DynamicViewTitle';
import './Header.css';
import Help from './Help/Help';
import Notification from './Notification/Notification';
import OwnershipTransfersBadge from './OwnershipTransfer/OwnershipTransfersBadge';
import { TrialModeIndicator } from './TrialMode';

const Header: React.FC = () => (
    <header className="header">
        <Notification />
        <DynamicViewTitle />
        <div className="rightItems">
            <TrialModeIndicator />
            <Help />
            <OwnershipTransfersBadge />
            <AccountMenu />
        </div>
    </header>
);

export default Header;
