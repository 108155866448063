import { Bulletin } from '../../common/interfaces';
import { isAfter, isBefore } from 'date-fns';
import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as AppActions from '../../containers/App/Actions';
import { bulletinsSelector } from '../../containers/App/Selectors';
import { ActionByType } from '../../store';
import { SystemBulletin } from '../SystemBulletin';

interface StateProps {
    bulletins: Bulletin[];
}

interface DispatchProps {
    fetchBulletins: () => ActionByType<AppActions.Actions, AppActions.ActionTypes.FETCH_BULLETINS>;
    clearBulletin: (id: Bulletin['id']) => ActionByType<AppActions.Actions, AppActions.ActionTypes.CLEAR_BULLETIN>;
    setBulletinDisplayFlag: (isDisplayed: boolean) => ActionByType<AppActions.Actions, AppActions.ActionTypes.SET_BULLETIN_DISPLAY_FLAG>;
}

export type BulletinsProps = StateProps & DispatchProps;

export class BulletinsContainer extends React.Component<BulletinsProps, unknown> {
    public constructor(props: BulletinsProps) {
        super(props);
    }

    public componentDidMount(): void {
        this.props.fetchBulletins();
    }

    public render(): React.ReactNode {
        this.props.bulletins.sort((a, b) =>
            isBefore(new Date(a.start || ''), new Date(b.start || '')) ? -1 : isAfter(new Date(a.start || ''), new Date(b.start || '')) ? 1 : 0
        );
        const bulletin = this.props.bulletins.length > 0 ? this.props.bulletins[0] : undefined;
        if (bulletin == null || isBefore(new Date(), new Date(bulletin.start || ''))) {
            return null;
        }

        return <SystemBulletin bulletin={bulletin} onDisplay={this.setBulletinDisplayFlag} onClose={() => this.props.clearBulletin(bulletin.id)} />;
    }

    private setBulletinDisplayFlag = (): void => {
        this.props.setBulletinDisplayFlag(true);
    };
}

const mapState = createStructuredSelector({
    bulletins: bulletinsSelector,
});

const mapDispatch: DispatchProps = {
    fetchBulletins: AppActions.Actions.fetchBulletins,
    clearBulletin: AppActions.Actions.clearBulletin,
    setBulletinDisplayFlag: AppActions.Actions.setBulletinDisplayFlag,
};

export default connect<StateProps, DispatchProps>(mapState, mapDispatch)(BulletinsContainer);
