import './AddDisplayOptions.css';

import * as React from 'react';

import { AutomationIds } from '../../../common/enums/AutomationElements.enum';
import { Checkbox, Spacer } from '@smartsheet/lodestar-core';
import { WithDataClientProps } from '../../../components/hoc/WithDataClient';
import SectionTitle from '../SectionTitle';

interface Props extends WithDataClientProps {
    header: string;
    options: Array <{
        checked: boolean,
        label: string,
        onChange: (value:boolean) => void,
        checkboxKey: string,
        dataClientId: AutomationIds,
        disabled?: boolean,
    }>;
}

const addDisplayOptions: React.FC<Props> = props => {
    const addDisplayOptionsElement = props.options.map(element => {
        return (
            <li key={element.checkboxKey}>
                <Spacer orientation="row" space="xSmall">
                    <Checkbox
                        checkedState={element.checked ? "checked": "unchecked" }
                        label={element.label}
                        onClick={element.onChange}
                        clientId={element.dataClientId}
                        inputId={element.checkboxKey}
                        isDisabled={element.disabled ? true : false} />
                </Spacer>
            </li>
        );
    });

    return (
        <div className="add-display-options">
            <SectionTitle
                title={props.header}
            />
            <ul>
                {addDisplayOptionsElement}
            </ul>
        </div>
    );
};

export default addDisplayOptions;
