/* eslint @typescript-eslint/no-misused-promises:"off" */
import { HttpStatusCodes } from '../common/enums';
import { AxiosError, AxiosResponse } from 'axios';
import { userService } from '../services/User.service';
import { AxiosFactory } from './AxiosFactory';

const handleSuccessResponse = (response: AxiosResponse) => {
    return response;
};

const handleErrorResponse = (error: AxiosError) => {
    // Redirect to log in screen as the user is unauthorized
    if (error.response && error.response.status === HttpStatusCodes.UNAUTHORIZED) {
        userService.clearLocalStorage();
        window.location.replace(location.href);
    }
    return Promise.reject(error);
};

const axiosInstance = AxiosFactory.create({
    onSuccess: handleSuccessResponse,
    onError: handleErrorResponse,
});

export default axiosInstance;
