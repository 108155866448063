import classNames from 'classnames';
import * as React from 'react';
import { AutomationIds } from '../../common/enums/AutomationElements.enum';
import { OwnershipTransferTabOption } from '../../common/enums/OwnershipTransferTabOption';
import { useLanguageElements } from '../../language-elements/withLanguageElementsHOC';
import BasicLinkOut from '../Admin/Basic/BasicLinkOut';
import { Spacer, TypeRampV2 } from '@smartsheet/lodestar-core';

export interface OwnershipTransferHeaderProps {
    activeTab: OwnershipTransferTabOption;
    onClickTab: (tab: OwnershipTransferTabOption) => void;
    onKeyDownTab: (e: React.KeyboardEvent, tab: OwnershipTransferTabOption) => void;
    receivedCount: number;
    sentCount: number;
    orgViewsCount: number;
    isSysAdminOwnershipTransferAllowed: boolean;
}

/**
 * Renders tabs within the Ownership Transfer List as well as indicating
 * when a tab has been clicked, or selected via the keyboard.
 */
const OwnershipTransferHeader: React.SFC<OwnershipTransferHeaderProps> = (props) => {
    const languageElements = useLanguageElements();
    const handleClickTabReceived = () => props.onClickTab(OwnershipTransferTabOption.RECEIVED);
    const handleKeyDownTabReceived = (e: React.KeyboardEvent) => props.onKeyDownTab(e, OwnershipTransferTabOption.RECEIVED);
    const handleClickTabSent = () => props.onClickTab(OwnershipTransferTabOption.SENT);
    const handleKeyDownTabSent = (e: React.KeyboardEvent) => props.onKeyDownTab(e, OwnershipTransferTabOption.SENT);
    const handleClickTabOrgViews = () => props.onClickTab(OwnershipTransferTabOption.ORG_VIEWS);
    const handleKeyDownTabOrgViews = (e: React.KeyboardEvent) => props.onKeyDownTab(e, OwnershipTransferTabOption.ORG_VIEWS);

    return (
        <>
            <Spacer orientation="column" space="xSmall" className="transfer-header">
                <TypeRampV2 system="ursa" variant="TT-Norms-Pro--28px--400w">
                    {languageElements.OWNERSHIP_TRANSFER_REQUEST_TITLE}
                </TypeRampV2>
                <div className="subtitle">
                    {languageElements.OWNERSHIP_TRANSFER_SUBTITLE1}
                    <br />
                    {languageElements.OWNERSHIP_TRANSFER_SUBTITLE2}
                    <BasicLinkOut
                        dataClientId={AutomationIds.ADMIN_PANEL_PERMISSIONS_OWNER_TRANSFER_MODAL_LINK}
                        sourceDescription={languageElements.LEARN_MORE}
                        sourceLink="https://help.smartsheet.com/articles/2478391-dynamic-view-create-administrate-views#transfers"
                    />
                </div>
            </Spacer>
            <ul className="nav">
                <li
                    data-client-id={AutomationIds.OWNERSHIP_TRANSFER_RECEIVED}
                    key={OwnershipTransferTabOption.RECEIVED}
                    className={classNames('transfers-tab', { active: props.activeTab === OwnershipTransferTabOption.RECEIVED })}
                    onClick={handleClickTabReceived}
                    onKeyDown={handleKeyDownTabReceived}
                    tabIndex={0}
                    role="button"
                >
                    <span className="tab-title">{languageElements.OWNERSHIP_TRANSFER_LIST_RECEIVED}</span>
                    <span className="tab-count">{props.receivedCount}</span>
                </li>
                <li
                    data-client-id={AutomationIds.OWNERSHIP_TRANSFER_SENT}
                    key={OwnershipTransferTabOption.SENT}
                    className={classNames('transfers-tab', { active: props.activeTab === OwnershipTransferTabOption.SENT })}
                    onClick={handleClickTabSent}
                    onKeyDown={handleKeyDownTabSent}
                    tabIndex={0}
                    role="button"
                >
                    <span className="tab-title">{languageElements.OWNERSHIP_TRANSFER_LIST_SENT}</span>
                    <span className="tab-count">{props.sentCount}</span>
                </li>
                {props.isSysAdminOwnershipTransferAllowed && (
                    <li
                        data-client-id={AutomationIds.OWNERSHIP_TRANSFER_ORG_VIEWS}
                        key={OwnershipTransferTabOption.ORG_VIEWS}
                        className={classNames('transfers-tab', { active: props.activeTab === OwnershipTransferTabOption.ORG_VIEWS })}
                        onClick={handleClickTabOrgViews}
                        onKeyDown={handleKeyDownTabOrgViews}
                        tabIndex={0}
                        role="button"
                    >
                        <span className="tab-title">{languageElements.OWNERSHIP_TRANSFER_LIST_ORG_VIEWS}</span>
                        <span className="tab-count">{props.orgViewsCount}</span>
                    </li>
                )}
            </ul>
        </>
    );
};

export default OwnershipTransferHeader;
