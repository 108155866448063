/* eslint @typescript-eslint/unbound-method:"off" */
import { DisplayField } from '../../common/interfaces';
import * as React from 'react';
import { Draggable, DraggableProvided, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';
import { WithDataClientProps } from '../hoc/WithDataClient';

interface DraggableItemProps extends WithDataClientProps {
    model: DisplayField;
    style: any;
    className: string;
    itemClick?: any;
    draggableKey: string;
}

const DraggableItem: React.SFC<DraggableItemProps> = (props) => {
    const getItemStyle = (outerStyle: any, draggableStyle: DraggingStyle | NotDraggingStyle | undefined): any => {
        return {
            ...outerStyle,
            ...draggableStyle,
        };
    };

    const getItemClassName = (className: string, draggableStyle: NotDraggingStyle | DraggingStyle | undefined): string => {
        let extraClassName = '';
        if (draggableStyle && draggableStyle.transition === 'none') {
            extraClassName = ' dragging';
        }
        return className + extraClassName;
    };

    return (
        <Draggable key={props.draggableKey} draggableId={props.draggableKey} index={props.model.ordinal!}>
            {(dprovided: DraggableProvided) => (
                <div
                    data-client-type={props.dataClientType}
                    data-client-id={props.dataClientId}
                    ref={dprovided.innerRef}
                    {...dprovided.draggableProps}
                    {...dprovided.dragHandleProps}
                    style={getItemStyle(props.style, dprovided.draggableProps.style)}
                    className={getItemClassName(props.className, dprovided.draggableProps.style)}
                    onClick={props.itemClick}
                >
                    {props.children}
                </div>
            )}
        </Draggable>
    );
};

export default DraggableItem;
