import { MAX_FILTER_VALUE } from '../../../common/constants';
import { ColumnType } from '../../../common/enums';
import { Cell, CellObjectValue, Column, Contact, ContactObjectValue, Row } from '../../../common/interfaces';
import { isContactObjectValue, isMultiContactObjectValue, isMultiPicklistObjectValue } from '../../../common/utils';
import { SymbolLookup } from '../../../common/utils/ViewSource';
import { ViewSourceFactory } from '../../../common/utils/ViewSourceFactory';
import { GridCell, RowData } from '../../../components/Grid/Grid.interface';
import { MultiSelectItem } from '../../../components/MultiSelect/MultiSelect.interface';
import { LanguageElements } from '../../../language-elements/LanguageElements';
import { getFormattedCell } from './GetFormattedCell';

export const createGridInformation = (
    sheetColumnMap: Map<number, Column>,
    reportColumnMap: Map<number, Column>,
    picklistSymbolImageMap: Map<string, SymbolLookup | undefined>,
    rows: Row[] | undefined,
    languageElements: LanguageElements,
    userLocale?: string
): {
    gridRows: RowData[];
    gridCellMap: Map<string, Cell>;
    columnCellMap: Map<number, Map<string, MultiSelectItem>>;
} => {
    const gridRows: RowData[] = [];
    const gridCellMap = new Map();
    const columnCellMap = new Map();

    if (!rows) {
        return {
            gridRows,
            gridCellMap,
            columnCellMap,
        };
    }

    rows.forEach((row: Row) => {
        const gridRow: RowData = {
            columnData: {},
            id: row.id.toString(),
            index: row.rowNumber,
            conditionalFormat: row.conditionalFormat,
            format: row.format,
        };

        if (row.cells) {
            row.cells.forEach((cell: Cell) => {
                const column = getColumnFromCell(cell, sheetColumnMap, reportColumnMap);

                if (!column) {
                    return;
                }

                const columnId = cell.virtualColumnId || cell.columnId;

                // Set grid cell map for filter logic
                gridCellMap.set(`${row.id}:${columnId || ''}`, cell);

                // Format data for display and add to gridRow
                const gridCell = getFormattedCell(cell, column, picklistSymbolImageMap, row.conditionalFormat, row.format, userLocale);
                gridRow[columnId!] = gridCell;

                // Ensure that empty cells and values over the max filter value are not used as options
                if (gridCell.displayValue.length == null || gridCell.displayValue.length === 0 || gridCell.displayValue.length > MAX_FILTER_VALUE) {
                    return;
                }

                // Initialize map for columnId if it doesn't yet exist
                let columnCellValueMap = columnCellMap.get(columnId);
                if (!columnCellValueMap) {
                    columnCellValueMap = new Map();
                    columnCellMap.set(columnId, columnCellValueMap);
                }

                /*
                 * Update the map to include cell data that doesn't match any of the column options. Note that in the case of
                 * MULTI_CONTACT column, this function may add multiple values to the map for one cell since it may contain multiple contacts
                 * that aren't part of column contactOptions
                 */
                addToColumnCellValueMap(cell, gridCell, sheetColumnMap, picklistSymbolImageMap, column, columnCellValueMap, languageElements);
            });
        }
        gridRows.push(gridRow);
    });

    return {
        gridRows,
        gridCellMap,
        columnCellMap,
    };
};

/*
 * Updates a columnCellValueMap to include a MultiSelectItem for the cell value (if the value is not already include in column.options
 * or column.contactOptions).
 * Note that the value is generally gridCell.value but in the case of MULTI_CONTACT_LIST column type
 * where the cell contains multiple contacts, each contact is added separately to the map, using
 * all of the name props on the objectValue.values array.
 *
 * Note that we don't add an item to the map for an empty cell: the 'Blank' option is added to the
 * top of the filter drop-down for PICKLIST, MULTI_CONTACT_LIST and CONTACT_LIST type (whether or not there are
 * any empty cells in the data). 'Blank' doesn't apply for CHECKBOX type.
 */
const addToColumnCellValueMap = (
    cell: Cell,
    gridCell: GridCell,
    sheetColumnMap: Map<number, Column>,
    picklistSymbolImageMap: Map<string, SymbolLookup | undefined>,
    column: Column,
    columnCellValueMap: Map<string, MultiSelectItem>,
    languageElements: LanguageElements
): void => {
    let item: MultiSelectItem | undefined;
    // For a cell in MULTI_CONTACTS column, add an item to the map for every contact in objectValue.values array (if it exists)
    if (column.type === ColumnType.MULTI_CONTACT_LIST && isMultiContactObjectValue(cell.objectValue)) {
        cell.objectValue.values!.forEach((contact) => {
            item = getMultiSelectItemFormat(
                getOptionForMultiSelect(contact, cell.displayValue, cell.value, column.type),
                sheetColumnMap,
                picklistSymbolImageMap,
                column,
                languageElements
            );
            // ESC 6419. need to add to map using email as key instead of name of a contact
            updateColumnCellValueMap(contact.email!.toLowerCase(), columnCellValueMap, item);
        });
    }
    // ESC 6419. When a Column is assigned as contact list and only allows for single contact appcore returns
    // columnType=CONTACT_LIST and cellObjectType=CONTACT
    // In this case need to add to map using email as key instead of name of a contact
    else if (column.type === ColumnType.CONTACT_LIST && isContactObjectValue(cell.objectValue)) {
        item = getMultiSelectItemFormat(
            getOptionForMultiSelect(cell.objectValue, cell.displayValue, cell.value, column.type),
            sheetColumnMap,
            picklistSymbolImageMap,
            column,
            languageElements
        );
        updateColumnCellValueMap(cell.objectValue.email!.toLowerCase(), columnCellValueMap, item);
    }
    // For a cell in MULTI_PICKLIST column, add an item to the map for every contact in objectValue.values array (if it exists)
    else if (column.type === ColumnType.MULTI_PICKLIST && isMultiPicklistObjectValue(cell.objectValue)) {
        cell.objectValue.values!.forEach((picklistValue) => {
            item = getMultiSelectItemFormat(
                getOptionForMultiSelect(picklistValue, cell.displayValue, cell.value, column.type),
                sheetColumnMap,
                picklistSymbolImageMap,
                column,
                languageElements
            );

            updateColumnCellValueMap(picklistValue.toLowerCase(), columnCellValueMap, item);
        });

        // Otherwise add one item to the map if it isn't already there
    } else {
        item = getMultiSelectItemFormat(
            getOptionForMultiSelect(cell.objectValue, cell.displayValue, cell.value, column.type),
            sheetColumnMap,
            picklistSymbolImageMap,
            column,
            languageElements
        );
        updateColumnCellValueMap(gridCell.displayValue.toLowerCase(), columnCellValueMap, item);
    }
};

const updateColumnCellValueMap = (key: string, columnCellValueMap: Map<string, MultiSelectItem>, item?: MultiSelectItem): void => {
    if (item && !columnCellValueMap.has(key)) {
        columnCellValueMap.set(key, item);
    }
};

const getMultiSelectItemFormat = (
    option: string | Contact,
    sheetColumnMap: Map<number, Column>,
    picklistSymbolImageMap: Map<string, SymbolLookup | undefined>,
    column: Column,
    languageElements: LanguageElements
): MultiSelectItem | undefined => {
    let filterItem: MultiSelectItem | undefined;

    // If value is already in the options defined for the column, we don't need to define it here
    // because it isn't needed in the columnCellMap (which gets merged with column options)
    const isIncludedInOptions = ViewSourceFactory.getIsIncludedInOptions(column.type, option, column.id, sheetColumnMap);

    if (!isIncludedInOptions) {
        // At this point we know there's no symbol associated with the item because it didn't match any options for the column
        // so we don't need to pass in a 'key' prop  or the picklistSymbolImageMap
        filterItem = ViewSourceFactory.getFilterItem(column.type, option, languageElements);
    }

    return filterItem;
};

// TODO: this currently returns invalid objects for DATE and ABSTRACT_DATETIME column types.
// These types need to be handled correctly when we implement filters for them.
const getOptionForMultiSelect = (
    objectValue: CellObjectValue,
    displayValue: string | undefined,
    value: undefined | null | string | number | boolean,
    columnType: ColumnType
): string | Contact => {
    switch (columnType) {
        // If available, use email
        case ColumnType.CONTACT_LIST:
        case ColumnType.MULTI_CONTACT_LIST:
            const contactObjectValue = objectValue as ContactObjectValue;
            return contactObjectValue && contactObjectValue.email ? contactObjectValue : displayValue || String(objectValue) || String(value) || '';

        case ColumnType.MULTI_PICKLIST:
            const picklistValue = objectValue as string;
            return picklistValue ? picklistValue : displayValue || String(objectValue) || String(value) || '';

        case ColumnType.CHECKBOX:
        case ColumnType.PICKLIST:
        default:
            /*
             * Returns string based on the following:
             * 1) return cell.displayValue if it's available (not available on CHECKBOX columns)
             * 2) otherwise return String(value) (forcing to string since value might be an object for certain col types)
             */
            return displayValue || String(objectValue) || String(value) || '';
    }
};

const getColumnFromCell = (cell: Cell, sheetColumnMap: Map<number, Column>, reportColumnMap?: Map<number, Column>): Column | undefined => {
    // Get column first by sheet column id since the sheet column has more detailed information than a report column. Note that cells
    // from a report will have a sheet column id.
    let column = sheetColumnMap.get(cell.columnId);

    // Handle case where report cell does not have an source sheet column. This should only happen on "Sheet Name" columns in a report
    if (!column && reportColumnMap) {
        column = reportColumnMap.get(cell.virtualColumnId!);
    }

    return column;
};
