import { Contact, IOwnershipTransfer, ShareError } from '../../../common/interfaces';
import * as React from 'react';
import { useSelector } from 'react-redux';
import alertIcon from '../../../assets/images/alert/modal-warning-large.svg';
import { AdminPanelPermissionsModalType } from '../../../common/enums/AdminPanelPermissionsModalType';
import { AutomationIds } from '../../../common/enums/AutomationElements.enum';
import { disableFreeTextNewOptions } from '../../../common/utils/DisableFreeTextNewOptions';
import { checkIfOnDemandOptionsShouldBeEnabled } from '../../../components/ContactPicker/CheckIfOnDemandOptionsShouldBeEnabled';
import ContactPicker from '../../../components/ContactPicker/ContactPicker';
import InfoMessage from '../../../components/InfoMessage';
import ModalWrapper from '../../../components/Modal';
import GenericModalContent from '../../../components/Modal/Content/GenericModalContent';
import MultiSelect from '../../../components/MultiSelect';
import { MultiSelectItem } from '../../../components/MultiSelect/MultiSelect.interface';
import { isDisabledContactPickerOnDemandSelector } from '../../App/Selectors';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';
import { AdminPermissionsLanguage } from '../interfaces/AdminLanguage.interface';
import SectionTitle from '../SectionTitle';
import OwnershipTransfer from './AdminPanelOwnershipTransfer';
import EvaluationSharingBanner from './EvaluationSharingBanner';
import PermissionsOwner from './PermissionsOwner';
import { SharingErrorBanner } from './SharingErrorBanner';

interface Props {
    owner: MultiSelectItem;
    isOwner: boolean;
    selectedNewOwnerItem: Contact | undefined;
    onNewOwnerChange: (result: Contact | undefined) => void;
    availableAdminsItems: Contact[];
    selectedAdminsItems: Contact[];
    onAdminsChange: (result: Contact[] | undefined) => void;
    selectedUsersItems: Contact[];
    allUsersItems: Contact[];
    onUsersChange: (result: Contact[] | undefined) => void;
    onUserValidateNewOption: (newOption: string, allowFreeTextOptionCallback: (isValid: boolean) => void) => void;
    selectedGroupsItems: MultiSelectItem[];
    allGroupsItems: MultiSelectItem[];
    onGroupsChange: (result: MultiSelectItem[] | undefined) => void;
    selectedDomainsItems: MultiSelectItem[];
    allDomainsItems: MultiSelectItem[];
    onDomainsChange: (result: MultiSelectItem[] | undefined) => void;
    onWarnAdminCancel: () => void;
    onWarnAdminConfirm: () => void;
    showModalType: AdminPanelPermissionsModalType;
    readOnly: boolean;
    sheetFilterId?: string | null;
    pendingTransfer: IOwnershipTransfer | undefined;
    sharingErrors?: ShareError[];
}

const multiSelectItemsAreValid: (multiSelectItems: MultiSelectItem[]) => boolean = (multiSelectItems: MultiSelectItem[]) => {
    return multiSelectItems.every((item) => item.isValid);
};

const AdminPanelPermissions: React.FC<Props> = (props) => {
    const languageElements = useLanguageElements();
    const usersAreValid = props.selectedUsersItems.every((selectedUserItems: Contact) => selectedUserItems.isValid === true);
    const groupsAreValid = multiSelectItemsAreValid(props.selectedGroupsItems);
    const domainsAreValid = multiSelectItemsAreValid(props.selectedDomainsItems);

    const isDisabledContactPickerOnDemand = useSelector(isDisabledContactPickerOnDemandSelector);
    const usersEnableOnDemandOptions = !isDisabledContactPickerOnDemand && checkIfOnDemandOptionsShouldBeEnabled(props.allUsersItems);
    const adminPermissionsLanguage: AdminPermissionsLanguage = {
        owner: {
            title: languageElements.ADMIN_PANEL_PERMISSIONS_OWNER_TITLE,
        },
        admins: {
            title: languageElements.ADMIN_PANEL_PERMISSIONS_ADMINS_TITLE,
            subtitle: languageElements.ADMIN_PANEL_PERMISSIONS_ADMINS_SUBTITLE,
            placeholder: languageElements.ADMIN_PANEL_PERMISSIONS_ADMINS_PLACEHOLDER,
            prompt: languageElements.ADMIN_PANEL_PERMISSIONS_ADMINS_PROMPT,
        },
        users: {
            title: languageElements.ADMIN_PANEL_PERMISSIONS_USERS_TITLE,
            subtitle: languageElements.ADMIN_PANEL_PERMISSIONS_USERS_SUBTITLE,
            placeholder: languageElements.ADMIN_PANEL_PERMISSIONS_USERS_PLACEHOLDER,
            prompt: languageElements.ADMIN_PANEL_PERMISSIONS_USERS_PROMPT,
        },
        groups: {
            title: languageElements.ADMIN_PANEL_PERMISSIONS_GROUPS_TITLE,
            subtitle: languageElements.ADMIN_PANEL_PERMISSIONS_GROUPS_SUBTITLE,
            placeholder: languageElements.ADMIN_PANEL_PERMISSIONS_GROUPS_PLACEHOLDER,
            prompt: languageElements.ADMIN_PANEL_PERMISSIONS_GROUPS_PROMPT,
        },
        domains: {
            title: languageElements.ADMIN_PANEL_PERMISSIONS_DOMAINS_TITLE,
            subtitle: languageElements.ADMIN_PANEL_PERMISSIONS_DOMAINS_SUBTITLE,
            placeholder: languageElements.ADMIN_PANEL_PERMISSIONS_DOMAINS_PLACEHOLDER,
            prompt: languageElements.ADMIN_PANEL_PERMISSIONS_DOMAINS_PROMPT,
        },
    };

    return (
        <>
            <EvaluationSharingBanner />
            <SharingErrorBanner errors={props.sharingErrors} />
            <div className="admin-panel-permissions">
                {props.readOnly && (
                    <InfoMessage
                        dataClientId={AutomationIds.ADMIN_PANEL_PERMISSIONS_INFO_MESSAGE}
                        message={languageElements.ADMIN_PANEL_PERMISSIONS_READONLY_INFO}
                    />
                )}
                <SectionTitle title={adminPermissionsLanguage.owner.title!} subtitle="" />
                <PermissionsOwner
                    dataClientId={AutomationIds.ADMIN_PANEL_PERMISSIONS_OWNER}
                    owner={props.owner}
                    language={adminPermissionsLanguage.owner}
                />
                {props.isOwner && (
                    <OwnershipTransfer
                        dataClientId={AutomationIds.ADMIN_PANEL_PERMISSIONS_OWNER}
                        allOptions={props.availableAdminsItems}
                        selectedOptions={props.selectedNewOwnerItem}
                        onNewOwnerChange={props.onNewOwnerChange}
                        readOnly={props.readOnly}
                        sheetFilterId={props.sheetFilterId}
                        pendingTransfer={props.pendingTransfer}
                    />
                )}
                <SectionTitle
                    title={adminPermissionsLanguage.admins.title!}
                    subtitle={adminPermissionsLanguage.admins.subtitle!}
                    selectedCount={props.selectedAdminsItems.length}
                />
                <ContactPicker
                    dataClientId={AutomationIds.ADMIN_PANEL_PERMISSIONS_ADMINS}
                    allOptions={props.availableAdminsItems}
                    selectedOptions={props.selectedAdminsItems}
                    isValidNewOption={disableFreeTextNewOptions}
                    allowClearing={true}
                    onChange={props.onAdminsChange}
                    disabled={props.readOnly}
                    multi={true}
                    inPlace={true}
                    placeholder={adminPermissionsLanguage.admins.placeholder!}
                />
                <SectionTitle
                    title={adminPermissionsLanguage.users.title!}
                    subtitle={adminPermissionsLanguage.users.subtitle!}
                    selectedCount={props.selectedUsersItems.length}
                />
                <ContactPicker
                    dataClientId={AutomationIds.ADMIN_PANEL_PERMISSIONS_USERS}
                    allOptions={props.allUsersItems}
                    selectedOptions={props.selectedUsersItems}
                    isValidNewOption={props.onUserValidateNewOption}
                    enableOnDemandOptions={usersEnableOnDemandOptions}
                    allowClearing={true}
                    onChange={props.onUsersChange}
                    disabled={props.readOnly}
                    multi={true}
                    inPlace={true}
                    placeholder={adminPermissionsLanguage.users.placeholder!}
                />
                {!usersAreValid && (
                    <p className={'invalid'} data-client-id={AutomationIds.ADMIN_PANEL_PERMISSIONS_USERS_ERROR}>
                        {languageElements.ADMIN_PANEL_PERMISSIONS_ADD_VALID_EMAIL}
                    </p>
                )}
                <SectionTitle
                    title={adminPermissionsLanguage.groups.title!}
                    subtitle={adminPermissionsLanguage.groups.subtitle!}
                    selectedCount={props.selectedGroupsItems.length}
                />
                <MultiSelect
                    dataClientId={AutomationIds.ADMIN_PANEL_PERMISSIONS_GROUPS}
                    selectedItems={props.selectedGroupsItems}
                    options={props.allGroupsItems}
                    onChange={props.onGroupsChange}
                    placeholder={adminPermissionsLanguage.groups.placeholder!}
                    allowCustomOptions={false}
                    prompt={adminPermissionsLanguage.groups.prompt!}
                    isValid={groupsAreValid}
                    disabled={props.readOnly}
                />
                <SectionTitle
                    title={adminPermissionsLanguage.domains.title!}
                    subtitle={adminPermissionsLanguage.domains.subtitle!}
                    selectedCount={props.selectedDomainsItems.length}
                />
                <MultiSelect
                    dataClientId={AutomationIds.ADMIN_PANEL_PERMISSIONS_DOMAINS}
                    selectedItems={props.selectedDomainsItems}
                    options={[]}
                    onChange={props.onDomainsChange}
                    placeholder={adminPermissionsLanguage.domains.placeholder!}
                    allowCustomOptions={true}
                    prompt={adminPermissionsLanguage.domains.prompt!}
                    isValid={domainsAreValid}
                    disabled={props.readOnly}
                />
                {!domainsAreValid && (
                    <p className={'invalid'} data-client-id={AutomationIds.ADMIN_PANEL_PERMISSIONS_DOMAINS_ERROR}>
                        {languageElements.ADMIN_PANEL_PERMISSIONS_ADD_VALID_DOMAIN}
                    </p>
                )}
                {props.showModalType === AdminPanelPermissionsModalType.WARN_ADMIN && (
                    <ModalWrapper isModalOpen={true} onClose={props.onWarnAdminCancel}>
                        <GenericModalContent
                            title={languageElements.ADMIN_PANEL_MODAL_WARN_ADMIN_TITLE}
                            message={languageElements.ADMIN_PANEL_MODAL_WARN_ADMIN_MESSAGE}
                            primaryButtonText={languageElements.ADMIN_PANEL_MODAL_WARN_ADMIN_PRIMARY_BUTTON_TEXT}
                            secondaryButtonText={languageElements.ADMIN_PANEL_MODAL_WARN_ADMIN_SECONDARY_BUTTON_TEXT}
                            onClickPrimaryButton={props.onWarnAdminConfirm}
                            onClickSecondaryButton={props.onWarnAdminCancel}
                            icon={alertIcon}
                        />
                    </ModalWrapper>
                )}
            </div>
        </>
    );
};

export default AdminPanelPermissions;
