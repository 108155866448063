import { ColumnType } from '../../enums';
import { FormFieldInterface } from '../../interfaces';
import { RowField } from './RowField';
import * as TransformForSmarUpsert from './TransformForSmarUpsert';
import * as Validator from './Validator';

export class RowFieldFactory {
    public static create(
        originalRowField: FormFieldInterface,
        { value, objectValue }: Pick<FormFieldInterface, 'value' | 'objectValue'>,
        isContactListObjectValue = false
    ): RowField {
        let validator: Validator.IRowFieldValidator;
        let funcTransformForSmarUpsert: TransformForSmarUpsert.FuncTransformForSmarUpsert;
        const updatedRowField: FormFieldInterface = { ...originalRowField, value, objectValue };
        const originalRow: FormFieldInterface = { ...originalRowField };
        switch (updatedRowField.type) {
            case ColumnType.MULTI_CONTACT_LIST:
                validator = new Validator.MultiContactValidator(originalRow);
                funcTransformForSmarUpsert = TransformForSmarUpsert.transformContactListForSmarUpsert;
                break;
            case ColumnType.MULTI_PICKLIST:
                validator = new Validator.MultiPickListValidator(originalRow);
                funcTransformForSmarUpsert = TransformForSmarUpsert.transformMultiPickListForSmarUpsert;
                break;
            case ColumnType.CHECKBOX:
                validator = new Validator.CheckboxValidator(originalRow);
                funcTransformForSmarUpsert = TransformForSmarUpsert.transformDefaultForSmarUpsert;
                break;
            case ColumnType.PICKLIST:
                validator = new Validator.PickListValidator(originalRow);
                funcTransformForSmarUpsert = TransformForSmarUpsert.transformDefaultForSmarUpsert;
                break;
            case ColumnType.CONTACT_LIST:
                validator = new Validator.ContactListValidator(originalRow);
                if (isContactListObjectValue) {
                    funcTransformForSmarUpsert = TransformForSmarUpsert.transformContactListForSmarUpsert;
                } else {
                    funcTransformForSmarUpsert = TransformForSmarUpsert.transformDefaultForSmarUpsert;
                }
                break;
            case ColumnType.DATE:
            case ColumnType.ABSTRACT_DATETIME:
                validator = new Validator.DateValidator(originalRow);
                funcTransformForSmarUpsert = TransformForSmarUpsert.transformDefaultForSmarUpsert;
                break;
            default:
                validator = new Validator.RowFieldBaseValidator(originalRow);
                funcTransformForSmarUpsert = TransformForSmarUpsert.transformDefaultForSmarUpsert;
                break;
        }

        return new RowField(updatedRowField, validator, funcTransformForSmarUpsert);
    }
}
