import styled from '@emotion/styled';
import { MAX_ALT_TEXT_CHARS } from '../../common/constants/MaxCharacter.constants';
import { Image as SmarCellImage, ImageUrlDto as ImageUrl } from '../../common/interfaces';
import { useEffect, useState } from 'react';
import * as React from 'react';
import imageIcon from '../../assets/images/mime-types/image.svg';
import { AutomationIds, AutomationTypes } from '../../common/enums/AutomationElements.enum';
import { UserActions } from '../../common/enums/UserActions.enum';
import { UserAnalyticsAction } from '../../common/metrics/UserAnalyticsAction';
import { useLanguageElements } from '../../language-elements/withLanguageElementsHOC';
import CellImageModal from '../Modal/CellImageModal';

export type ImageProps = Pick<HTMLImageElement, 'src' | 'alt'> & Partial<Pick<HTMLImageElement, 'height' | 'width'>>;

interface Props {
    image?: SmarCellImage;
    attachedImage?: File;
    thumbnailUrl?: ImageUrl;
    imageUrl?: ImageUrl;
    onChangeAltText: (altText: string) => void;
}

/**
 * Returns a new object with height and width scaled down proportionally so that neither image dimension exceeds 200 pixels.
 * If height or width are undefined when passed in, they will also be undefined in the returned object.
 */
const generateThumbnailProps = (imageProps: ImageProps) => {
    const { src, height, width, alt } = imageProps;
    const scale = 200 / (height && width ? Math.max(height, width) : height || width || 200);
    return scale < 1 ? { src, height: height && height * scale, width: width && width * scale, alt } : imageProps;
};

/**
 * Loads the passed in file and then updates the component's state to store the image properties for a full-sized image an well
 * as a thumbnail.
 */
export const loadImage = async (image: File): Promise<HTMLImageElement> => {
    // Generate a data URL string for a File object.
    const dataUrl = await new Promise<string>((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onerror = () => reject('Could not read file');
        fileReader.onload = () => resolve(fileReader.result as string);
        fileReader.readAsDataURL(image);
    });

    // Loads an image into an HTML Image element, which will give us access to its height and width properties.
    const loadedImage = await new Promise<HTMLImageElement>((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = () => reject('Could not load image');
        img.src = dataUrl;
    });
    loadedImage.alt = image.name;
    return loadedImage;
};

const extractImageProps = ({ src, height, width, alt }: Partial<ImageProps> = {}): ImageProps => ({
    src: src || '',
    height: height || 0,
    width: width || 0,
    alt: alt || '',
});

const FormFieldImage = ({ image, attachedImage, thumbnailUrl, imageUrl, onChangeAltText }: Props) => {
    const languageElements = useLanguageElements();
    const [loadingError, setLoadingError] = useState<Error>();
    const [altText, setAltText] = useState<string>(attachedImage?.name ?? image?.altText ?? '');
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [loadedImage, setLoadedImage] = useState<HTMLImageElement>();
    const [disableAltText, setDisableAltText] = useState(false);

    useEffect(() => {
        if (!attachedImage) {
            return;
        }

        loadImage(attachedImage)
            .then((tmpLoadedImage) => {
                setLoadedImage(tmpLoadedImage);
                setAltText(tmpLoadedImage.alt);
                setDisableAltText(true);
            })
            .catch((error) => {
                setLoadingError(error);
            });
    }, [attachedImage]);

    useEffect(() => {
        setDisableAltText(false);
    }, [image, thumbnailUrl, imageUrl]);

    const handleClickImage = React.useCallback(
        (event: React.MouseEvent<HTMLAnchorElement>) => {
            if (!isImageModalOpen) {
                UserAnalyticsAction.addFromEvent(event, undefined, UserActions.CLICK_DETAILS_PANEL_IMAGE);
            }

            setIsImageModalOpen(!isImageModalOpen);
        },
        [isImageModalOpen]
    );

    const ThumbnailImage = React.useMemo(() => {
        let thumbnailProps;
        if (loadedImage) {
            thumbnailProps = generateThumbnailProps({
                src: loadedImage?.src,
                height: loadedImage?.height,
                width: loadedImage?.width,
                alt: loadedImage?.alt,
            });
        } else if (image && (thumbnailUrl || imageUrl)) {
            const alt = image.altText || '';
            const localImageProps = imageUrl && { src: imageUrl.url!, height: image.height, width: image.width, alt };
            thumbnailProps = thumbnailUrl && { src: thumbnailUrl.url!, alt };
            thumbnailProps = thumbnailProps || generateThumbnailProps(localImageProps!);
        }
        return (
            <a style={{ cursor: 'pointer' }} onClick={handleClickImage}>
                <img {...thumbnailProps} /> {/* The props object provides the alt attribute. */}
            </a>
        );
    }, [loadedImage?.src, loadedImage?.height, loadedImage?.width, loadedImage?.alt, handleClickImage]);

    const handleCloseModal = () => setIsImageModalOpen(false);

    const handleChangeAltText = (event: React.ChangeEvent<HTMLInputElement>) => {
        const changedAltText = event.target.value || attachedImage?.name || image?.altText || '';
        setAltText(changedAltText);

        onChangeAltText(changedAltText);
    };

    if (loadingError) {
        // If the browser couldn't load the attached image for some reason, then fall back to a text placeholder.
        return (
            <div>
                <img src={imageIcon} alt={''} /> <span>{languageElements.DETAILS_DATA_CELL_IMAGE_ATTACHED(attachedImage?.name || '')}</span>
            </div>
        );
    }
    let imageProps: ImageProps | undefined;

    if (loadedImage) {
        // If the browser can load the attached image, then use the imageProps for the thumbnail and full-size image modal.
        imageProps = loadedImage;
    } else if (image && (thumbnailUrl || imageUrl)) {
        // For already uploaded cell images, if we only have a thumbnail, use it in the details panel as well as for the full-size image model.
        // If we only have the full-size image, we can generate a scaled down version of it for use in the details panel.
        const alt = image.altText || '';

        // Height and width attributes are encoded in the url given to us by the image proxy service.
        imageProps = imageUrl && { src: imageUrl.url!, height: image.height, width: image.width, alt };
    } else {
        return (
            <div data-testid={AutomationIds.FORM_FIELD_CELL_IMAGE_LOADING}>
                <img src={imageIcon} alt={''} /> <span>{languageElements.DETAILS_DATA_CELL_IMAGE_ATTACHED(image?.altText ?? '')}</span>
            </div>
        );
    }

    return (
        <>
            <div data-client-type={AutomationTypes.TEXT_NUMBER_COMPONENT}>
                <input
                    placeholder={altText}
                    value={altText}
                    maxLength={MAX_ALT_TEXT_CHARS}
                    onChange={handleChangeAltText}
                    disabled={disableAltText}
                />
            </div>
            <CellImageContainer data-testid={AutomationIds.FORM_FIELD_CELL_IMAGE}>
                {ThumbnailImage}
                <CellImageModal
                    isOpen={isImageModalOpen}
                    onClose={handleCloseModal}
                    imageProps={{ ...extractImageProps(imageProps!), alt: altText ?? imageProps!.alt }}
                />
            </CellImageContainer>
        </>
    );
};

const CellImageContainer = styled.div`
    padding-top: 10px;
`;

export default FormFieldImage;
