import { Image, ImageUrlDto as ImageUrl, ImageUrlMapDto, ViewData } from '../../common/interfaces';
import { ActionsUnion } from '../types';
import { ActionWithPayload, createAction } from '../Utils/CreateAction';

export enum ActionTypes {
    // Image Objects
    FETCH_IMAGE_URLS = 'FETCH_IMAGE_URLS',
    FETCH_IMAGE_URLS_FROM_VIEW_DATA = 'FETCH_IMAGE_URLS_FROM_VIEW_DATA',

    // Image Urls
    STORE_THUMBNAIL_URLS = 'STORE_THUMBNAIL_URLS',
    CLEAR_THUMBNAIL_URL = 'CLEAR_THUMBNAIL_URL',
    STORE_IMAGE_URLS = 'STORE_IMAGE_URLS',
    CLEAR_IMAGE_URL = 'CLEAR_IMAGE_URL',
    STORE_GRID_URLS = 'STORE_GRID_URLS',
    CLEAR_GRID_URL = 'CLEAR_GRID_URL',
}

type ActionWithStringPayload = ActionWithPayload<ActionTypes, string>;
type ActionWithImageUrlMapDtoPayload = ActionWithPayload<ActionTypes, ImageUrlMapDto>;

export const Actions = {
    // Image Objects
    fetchImageUrls: (images: Image[]): ActionWithPayload<ActionTypes, Image[]> => createAction(ActionTypes.FETCH_IMAGE_URLS, images),
    fetchImageUrlsFromViewData: (viewData: ViewData) => createAction(ActionTypes.FETCH_IMAGE_URLS_FROM_VIEW_DATA, viewData),

    // Image Urls
    // eslint-disable-next-line max-len
    storeThumbnailUrls: (imageUrlsMap: ImageUrlMapDto): ActionWithImageUrlMapDtoPayload =>
        createAction(ActionTypes.STORE_THUMBNAIL_URLS, imageUrlsMap),
    clearThumbnailUrl: (imageId: ImageUrl['imageId']): ActionWithStringPayload => createAction(ActionTypes.CLEAR_THUMBNAIL_URL, imageId),
    storeImageUrls: (imageUrlsMap: ImageUrlMapDto): ActionWithImageUrlMapDtoPayload => createAction(ActionTypes.STORE_IMAGE_URLS, imageUrlsMap),
    clearImageUrl: (imageId: ImageUrl['imageId']): ActionWithStringPayload => createAction(ActionTypes.CLEAR_IMAGE_URL, imageId),
    storeGridUrls: (imageUrlsMap: ImageUrlMapDto): ActionWithImageUrlMapDtoPayload => createAction(ActionTypes.STORE_GRID_URLS, imageUrlsMap),
    clearGridUrls: (imageId: ImageUrl['imageId']): ActionWithStringPayload => createAction(ActionTypes.CLEAR_GRID_URL, imageId),
};

export type Actions = ActionsUnion<typeof Actions>;
