export enum FilterConditionOperators {
    IS_CHECKED = 'IS_CHECKED',
    IS_NOT_CHECKED = 'IS_NOT_CHECKED',
    IS_ONE_OF = 'IS_ONE_OF',
    IS_NOT_ONE_OF = 'IS_NOT_ONE_OF',
    IS_EQUAL_TO = 'IS_EQUAL_TO',
    IS_NOT_EQUAL_TO = 'IS_NOT_EQUAL_TO',
    CONTAINS = 'CONTAINS',
    DOES_NOT_CONTAIN = 'DOES_NOT_CONTAIN',
    IS_BLANK = 'IS_BLANK',
    IS_NOT_BLANK = 'IS_NOT_BLANK',
    HAS_ANY_OF = 'HAS_ANY_OF',
    HAS_NONE_OF = 'HAS_NONE_OF',
    HAS_ALL_OF = 'HAS_ALL_OF',
    DOES_NOT_HAVE_ALL_OF = 'DOES_NOT_HAVE_ALL_OF',
    IS_GREATER_THAN = 'IS_GREATER_THAN',
    IS_LESS_THAN = 'IS_LESS_THAN',
    IS_GREATER_THAN_OR_EQUAL_TO = 'IS_GREATER_THAN_OR_EQUAL_TO',
    IS_LESS_THAN_OR_EQUAL_TO = 'IS_LESS_THAN_OR_EQUAL_TO',
    IS_BETWEEN = 'IS_BETWEEN',
    IS_NOT_BETWEEN = 'IS_NOT_BETWEEN',
    IS_A_NUMBER = 'IS_A_NUMBER',
    IS_NOT_A_NUMBER = 'IS_NOT_A_NUMBER',
    IS_A_DATE = 'IS_A_DATE',
    IS_NOT_A_DATE = 'IS_NOT_A_DATE',
}
