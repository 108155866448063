import { FeatureFlag } from '../../common/interfaces';
import { Bulletin } from '../../common/interfaces';
import { AxiosError } from 'axios';
import { HomeFilter } from '../../common/enums/HomeFilter';
import { createAction } from '../../store/Utils/CreateAction';
import { ActionsUnion } from '../../store';
import LeftPanelType from '../LeftPanel/LeftPanelType';
import { HomeSourceFilter } from './Reducers';

export enum ActionTypes {
    // Side Panel
    TOGGLE_LEFT_SIDE_PANEL = 'TOGGLE_LEFT_SIDE_PANEL',
    OPEN_LEFT_SIDE_PANEL = 'OPEN_LEFT_SIDE_PANEL',
    SET_ACTIVE_SIDE_PANEL = 'SET_ACTIVE_SIDE_PANEL',

    // App Stage
    SET_APP_STAGE_ERROR = 'SET_APP_STAGE_ERROR',
    SET_APP_STAGE_ACTION_IN_PROGRESS = 'SET_APP_STAGE_ACTION_IN_PROGRESS',
    SET_APP_STAGE_REDIRECT = 'SET_APP_STAGE_REDIRECT',
    RESET_APP_STAGE = 'RESET_APP_STAGE',

    // Notifications
    ADD_NOTIFICATION = 'ADD_NOTIFICATION',
    REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION',

    // Feature Flags
    FETCH_FEATURE_FLAGS = 'FETCH_FEATURE_FLAGS',
    STORE_FEATURE_FLAGS = 'STORE_FEATURE_FLAGS',

    // Bulletins
    FETCH_BULLETINS = 'FETCH_BULLETINS',
    STORE_BULLETINS = 'STORE_BULLETINS',
    CLEAR_BULLETIN = 'CLEAR_BULLETIN',
    SET_BULLETIN_DISPLAY_FLAG = 'SET_BULLETIN_DISPLAY_FLAG',

    // Home
    SET_HOME_FILTER = 'SET_HOME_FILTER',
    SET_HOME_SOURCE_FILTER = 'SET_HOME_SOURCE_FILTER',

    // i18n
    SET_LANGUAGE = 'SET_LANGUAGE',
}

export const Actions = {
    // Side Panel
    toggleLeftSidePanel: () => createAction(ActionTypes.TOGGLE_LEFT_SIDE_PANEL),
    openLeftSidePanel: () => createAction(ActionTypes.OPEN_LEFT_SIDE_PANEL),
    setActiveSidePanel: (panel: LeftPanelType) => createAction(ActionTypes.SET_ACTIVE_SIDE_PANEL, panel),

    // Stage
    setAppStageActionInProgress: (message: string) => createAction(ActionTypes.SET_APP_STAGE_ACTION_IN_PROGRESS, message),
    setAppStageError: (error: Error | AxiosError) => createAction(ActionTypes.SET_APP_STAGE_ERROR, error),
    setAppStageRedirect: (redirectUrl: string) => createAction(ActionTypes.SET_APP_STAGE_REDIRECT, redirectUrl),
    resetAppStage: () => createAction(ActionTypes.RESET_APP_STAGE),

    // Notifications
    addNotification: () => createAction(ActionTypes.ADD_NOTIFICATION),
    removeNotification: () => createAction(ActionTypes.REMOVE_NOTIFICATION),

    // Feature Flags
    fetchFeatureFlags: () => createAction(ActionTypes.FETCH_FEATURE_FLAGS),
    storeFeatureFlags: (featureFlags: FeatureFlag[]) =>
        createAction<ActionTypes.STORE_FEATURE_FLAGS, FeatureFlag[]>(ActionTypes.STORE_FEATURE_FLAGS, featureFlags),

    // Bulletins
    fetchBulletins: () => createAction(ActionTypes.FETCH_BULLETINS),
    storeBulletins: (bulletins: Bulletin[]) => createAction<ActionTypes.STORE_BULLETINS, Bulletin[]>(ActionTypes.STORE_BULLETINS, bulletins),
    clearBulletin: (bulletinId: Bulletin['id']) => createAction<ActionTypes.CLEAR_BULLETIN, Bulletin['id']>(ActionTypes.CLEAR_BULLETIN, bulletinId),
    setBulletinDisplayFlag: (isDisplayed: boolean) =>
        createAction<ActionTypes.SET_BULLETIN_DISPLAY_FLAG, boolean>(ActionTypes.SET_BULLETIN_DISPLAY_FLAG, isDisplayed),

    // Home
    setHomeFilter: (filter: HomeFilter | null) => createAction(ActionTypes.SET_HOME_FILTER, filter),
    setHomeSourceFilter: (filter: HomeSourceFilter | undefined) => createAction(ActionTypes.SET_HOME_SOURCE_FILTER, filter),

    // i18n
    setLanguage: (lang: string) => createAction(ActionTypes.SET_LANGUAGE, lang),
};

export type Actions = ActionsUnion<typeof Actions>;
