import { ColumnType, FilterConditionOperators } from '../../../../common/enums';
import * as dv from '../../../../common/interfaces';
import { CellObjectValue } from '../../../../common/interfaces';
import {
    getNumberFromPercentStringOrOtherString,
    isContactObjectValue,
    isDateColumnType,
    isDateValid,
    isMultiContactObjectValue,
    isMultiPicklistObjectValue,
} from '../../../../common/utils';
import { CellForApplyCondition } from './TransformCellForApplyCondition';

export interface FilterConditionForApplyCondition extends dv.IFilterCondition {
    valueSet?: Set<string>;
}

const isDateCell = (cell: CellForApplyCondition): boolean => {
    return Boolean(cell.objectValue && dv.instanceOfDateObjectValue(cell.objectValue) && (cell.objectValue as dv.DateObjectValue).value);
};

const applyIsChecked = (cell: CellForApplyCondition, columnType: ColumnType): boolean => {
    if (columnType === ColumnType.CHECKBOX) {
        return cell.objectValue === true;
    }
    return false;
};

const applyIsUnchecked = (cell: CellForApplyCondition, columnType: ColumnType): boolean => {
    if (columnType === ColumnType.CHECKBOX) {
        return cell.objectValue === false || cell.objectValue == null; // or undefined
    }
    return false;
};

const applyIsOneOf = (condition: FilterConditionForApplyCondition, cell: CellForApplyCondition, columnType: ColumnType): boolean => {
    if (!(condition.valueSet instanceof Set)) {
        return false;
    }

    const conditionValueSet: Set<string> = condition.valueSet;
    if (conditionValueSet.size === 0) {
        return false;
    }

    const { objectValue, displayValue } = cell;

    switch (columnType) {
        case ColumnType.CHECKBOX:
            if (objectValue == null) {
                // Return true if condition value set contains '' OR 'false' since empty cell = unchecked box
                return conditionValueSet.has('') || conditionValueSet.has('false');
            }
            return isObjValueStringOrDisplayValueInSet(conditionValueSet, cell);

        case ColumnType.PICKLIST:
            if (objectValue == null && displayValue == null) {
                return conditionValueSet.has('');
            }
            return isObjValueStringOrDisplayValueInSet(conditionValueSet, cell);

        case ColumnType.CONTACT_LIST:
            if (objectValue == null) {
                return conditionValueSet.has('');
            }

            // If objectValue is a contact, filter on email. Otherwise filter on the objectValue string
            const value = isContactObjectValue(objectValue) && objectValue.email != null ? objectValue.email : String(objectValue).toLowerCase();
            return conditionValueSet.has(value);

        default:
            if (cell.objectValue == null) {
                return conditionValueSet.has('');
            }
            return isObjValueStringOrDisplayValueInSet(conditionValueSet, cell);
    }
};

const applyIsNotOneOf = (condition: FilterConditionForApplyCondition, cell: CellForApplyCondition, columnType: ColumnType): boolean => {
    const result = applyIsOneOf(condition, cell, columnType);
    return !result;
};

const applyHasAnyOf = (condition: FilterConditionForApplyCondition, cell: CellForApplyCondition, columnType: ColumnType): boolean => {
    if (!(condition.valueSet instanceof Set)) {
        return false;
    }

    const conditionValueSet: Set<string> = condition.valueSet;
    if (conditionValueSet.size === 0) {
        return false;
    }

    switch (columnType) {
        case ColumnType.MULTI_CONTACT_LIST:
            if (columnType === ColumnType.MULTI_CONTACT_LIST) {
                if (cell.objectValue == null) {
                    return conditionValueSet.has('');
                }

                if (isMultiContactObjectValue(cell.objectValue)) {
                    return cell.objectValue.values.some((contact) => {
                        return Boolean(contact.email && conditionValueSet.has(contact.email));
                    });
                }

                if (isContactObjectValue(cell.objectValue)) {
                    return Boolean(cell.objectValue.email && conditionValueSet.has(cell.objectValue.email));
                }

                return conditionValueSet.has(String(cell.objectValue).toLowerCase());
            }
            break;
        case ColumnType.MULTI_PICKLIST:
            if (cell.objectValue == null) {
                return conditionValueSet.has('');
            }

            if (isMultiPicklistObjectValue(cell.objectValue)) {
                return cell.objectValue.values.some((item: string) => conditionValueSet.has(item.toLowerCase()));
            }

            return isObjValueStringOrDisplayValueInSet(conditionValueSet, cell);
    }
    return false;
};

const applyHasNoneOf = (condition: FilterConditionForApplyCondition, cell: CellForApplyCondition, columnType: ColumnType): boolean => {
    const result = applyHasAnyOf(condition, cell, columnType);
    return !result;
};

const applyHasAllOf = (condition: FilterConditionForApplyCondition, cell: CellForApplyCondition, columnType: ColumnType): boolean => {
    if (!(condition.valueSet instanceof Set)) {
        return false;
    }

    const conditionValueSet: Set<string> = condition.valueSet;
    if (conditionValueSet.size === 0) {
        return false;
    }
    const conditionValues: string[] = Array.from(conditionValueSet);

    switch (columnType) {
        case ColumnType.MULTI_CONTACT_LIST:
            return conditionValues.every((conditionValue) => {
                if (isMultiContactObjectValue(cell.objectValue)) {
                    return cell.objectValue.values.some((contact) => {
                        return Boolean(contact.email && contact.email === conditionValue);
                    });
                }

                if (isContactObjectValue(cell.objectValue)) {
                    return Boolean(cell.objectValue.email && cell.objectValue.email === conditionValue);
                }
                return Boolean(cell.objectValue && String(cell.objectValue).toLowerCase() === conditionValue);
            });

        case ColumnType.MULTI_PICKLIST:
            return conditionValues.every((conditionValue) => {
                if (isMultiPicklistObjectValue(cell.objectValue)) {
                    return cell.objectValue.values.some((cellValue) => {
                        return conditionValue === cellValue.toLowerCase();
                    });
                }

                return false;
            });
    }

    return false;
};

const applyDoesNotHaveAllOf = (condition: FilterConditionForApplyCondition, cell: CellForApplyCondition, columnType: ColumnType): boolean => {
    const result = applyHasAllOf(condition, cell, columnType);
    return !result;
};

const applyContains = (condition: FilterConditionForApplyCondition, cell: CellForApplyCondition, columnType: ColumnType): boolean => {
    if (
        columnType === ColumnType.PICKLIST ||
        columnType === ColumnType.MULTI_PICKLIST ||
        columnType === ColumnType.CHECKBOX ||
        columnType === ColumnType.CONTACT_LIST ||
        columnType === ColumnType.MULTI_CONTACT_LIST ||
        columnType === ColumnType.TEXT_NUMBER
    ) {
        return isSubStringInObjValueStringOrDisplayValue(condition.value, cell, columnType);
    }
    return false;
};

const applyDoesNotContain = (condition: FilterConditionForApplyCondition, cell: CellForApplyCondition, columnType: ColumnType): boolean => {
    const result = applyContains(condition, cell, columnType);
    return !result;
};

const applyIsBlank = (condition: FilterConditionForApplyCondition, cell: CellForApplyCondition, columnType: ColumnType): boolean => {
    if (
        columnType === ColumnType.PICKLIST ||
        columnType === ColumnType.CONTACT_LIST ||
        columnType === ColumnType.MULTI_CONTACT_LIST ||
        columnType === ColumnType.MULTI_PICKLIST ||
        columnType === ColumnType.TEXT_NUMBER ||
        columnType === ColumnType.DATE ||
        columnType === ColumnType.DATETIME ||
        columnType === ColumnType.ABSTRACTDATETIME ||
        columnType === ColumnType.ABSTRACT_DATETIME
    ) {
        if (typeof cell.objectValue === 'number' && cell.objectValue === 0) {
            return false;
        }

        if (typeof cell.objectValue === 'string' || typeof cell.objectValue === 'number') {
            return !Boolean(cell.objectValue);
        }

        if (cell.objectValue && dv.instanceOfDateObjectValue(cell.objectValue)) {
            return !Boolean(cell.objectValue.value);
        }

        return !Boolean(cell.displayValue);
    }
    return false;
};

const applyIsNotBlank = (condition: FilterConditionForApplyCondition, cell: CellForApplyCondition, columnType: ColumnType): boolean => {
    const result = applyIsBlank(condition, cell, columnType);
    return !result;
};

/**
 * For equal to & not equal to operators:
 * If the objectValue is not a string or number, return false.
 * Match both strings and numbers, i.e. 32 will match '32' and 32. Blank rows should be included in NOT_EQUAL_TO.
 * Return a true or false based of the conditionValue and objectValue meet the selected operation criteria
 */
const applyIsEqualTo = (condition: FilterConditionForApplyCondition, cell: CellForApplyCondition, columnType: ColumnType): boolean => {
    if (isDateColumnType(columnType)) {
        if (!cell.objectValue || !(cell.objectValue as dv.DateObjectValue).value || !condition.value) {
            return false;
        }

        const cellValue = (cell.objectValue as dv.DateObjectValue).value.split('T')[0];

        return condition.value === cellValue;
    }

    if (columnType !== ColumnType.TEXT_NUMBER || !isStringOrNumber(cell.objectValue)) {
        return false;
    }

    const conditionNumber = getNumberFromPercentStringOrOtherString(condition.value);
    if (conditionNumber !== undefined && cell.objectValue === conditionNumber) {
        return true;
    }

    return cell.objectValue.toString().toLowerCase() === condition.value.toLowerCase();
};

const applyIsNotEqualTo = (condition: FilterConditionForApplyCondition, cell: CellForApplyCondition, columnType: ColumnType): boolean => {
    const result = applyIsEqualTo(condition, cell, columnType);
    return !result;
};

/**
 * For greater than, less than, greater than or equal to & less than or equal to operators:
 * If the objectValue is not a string or number, return false.
 * If the conditionValue is a string, and the objectValue type is a number, return false.
 * If the conditionValue is a number, and the objectValue type is a string, return false.
 * Return a true or false based of the conditionValue and objectValue meet the selected operation criteria
 */
const applyIsGreaterThan = (condition: FilterConditionForApplyCondition, cell: CellForApplyCondition, columnType: ColumnType): boolean => {
    if (isDateColumnType(columnType)) {
        if (!cell.objectValue || !(cell.objectValue as dv.DateObjectValue).value || !condition.value) {
            return false;
        }

        const cellValue = (cell.objectValue as dv.DateObjectValue).value.split('T')[0];

        return cellValue.localeCompare(condition.value) === 1;
    }

    if (!isStringOrNumber(cell.objectValue) || columnType !== ColumnType.TEXT_NUMBER) {
        return false;
    }

    const conditionNumber = getNumberFromPercentStringOrOtherString(condition.value);

    if (!doesTypeMatch(conditionNumber, cell.objectValue)) {
        return false;
    }

    return conditionNumber === undefined
        ? condition.value.toLowerCase() < cell.objectValue.toString().toLowerCase()
        : conditionNumber < Number(cell.objectValue);
};

const applyIsLessThanOrEqualTo = (condition: FilterConditionForApplyCondition, cell: CellForApplyCondition, columnType: ColumnType): boolean => {
    if (isDateColumnType(columnType)) {
        return applyIsLessThan(condition, cell, columnType) || applyIsEqualTo(condition, cell, columnType);
    }

    const conditionNumber = getNumberFromPercentStringOrOtherString(condition.value);
    if (!doesTypeMatch(conditionNumber, cell.objectValue)) {
        return false;
    }
    const result = applyIsGreaterThan(condition, cell, columnType);
    return !result;
};

const applyIsLessThan = (condition: FilterConditionForApplyCondition, cell: CellForApplyCondition, columnType: ColumnType): boolean => {
    if (isDateColumnType(columnType)) {
        if (!cell.objectValue || !(cell.objectValue as dv.DateObjectValue).value || !condition.value) {
            return false;
        }
        const cellValue = (cell.objectValue as dv.DateObjectValue).value.split('T')[0];

        return cellValue.localeCompare(condition.value) === -1;
    }

    if (!isStringOrNumber(cell.objectValue) || columnType !== ColumnType.TEXT_NUMBER) {
        return false;
    }

    const conditionNumber = getNumberFromPercentStringOrOtherString(condition.value);

    if (!doesTypeMatch(conditionNumber, cell.objectValue)) {
        return false;
    }

    return conditionNumber === undefined
        ? condition.value.toLowerCase() > cell.objectValue.toString().toLowerCase()
        : conditionNumber > Number(cell.objectValue);
};

const applyIsGreaterThanOrEqualTo = (condition: FilterConditionForApplyCondition, cell: CellForApplyCondition, columnType: ColumnType): boolean => {
    if (isDateColumnType(columnType)) {
        return applyIsGreaterThan(condition, cell, columnType) || applyIsEqualTo(condition, cell, columnType);
    }

    const conditionNumber = getNumberFromPercentStringOrOtherString(condition.value);
    if (!doesTypeMatch(conditionNumber, cell.objectValue)) {
        return false;
    }
    const result = applyIsLessThan(condition, cell, columnType);
    return !result;
};

const applyIsBetween = (condition: FilterConditionForApplyCondition, cell: CellForApplyCondition, columnType: ColumnType): boolean => {
    if (isDateColumnType(columnType)) {
        const startValue = { value: condition.value.startValue } as FilterConditionForApplyCondition;
        const endValue = { value: condition.value.endValue } as FilterConditionForApplyCondition;

        return applyIsGreaterThanOrEqualTo(startValue, cell, columnType) && applyIsLessThanOrEqualTo(endValue, cell, columnType);
    }

    if (columnType === ColumnType.TEXT_NUMBER) {
        return isObjValueBetweenStartEndValues(condition.value.startValue, condition.value.endValue, cell);
    }

    return false;
};

const applyIsNotBetween = (condition: FilterConditionForApplyCondition, cell: CellForApplyCondition, columnType: ColumnType): boolean => {
    const result = applyIsBetween(condition, cell, columnType);
    return !result;
};

const applyIsNumber = (cell: CellForApplyCondition, columnType: ColumnType): boolean => {
    return columnType === ColumnType.TEXT_NUMBER && typeof cell.objectValue === 'number';
};

const applyIsNotNumber = (cell: CellForApplyCondition, columnType: ColumnType): boolean => {
    const result = applyIsNumber(cell, columnType);
    return !result;
};

const applyIsDate = (cell: CellForApplyCondition, columnType: ColumnType): boolean => {
    return isDateColumnType(columnType) && isDateCell(cell) && isDateValid((cell.objectValue as dv.DateObjectValue).value);
};

const applyIsNotDate = (cell: CellForApplyCondition, columnType: ColumnType): boolean => {
    const result = applyIsDate(cell, columnType);
    return !result;
};

/**
 * If column type is not TEXT_NUMBER and objectValue is a number, we do not search for the string (just return false)
 * If objectValue is a string, search it for 'subString'
 * If displayValue contains 'SubString', return true
 * If column type is TEXT_NUMBER and objectValue is a number, we search BOTH the displayValue and the objectValue.toString()
 * to find matches. This addresses cases such as displayValue = "$3,456.12" and number = 3456.12. Given both, filtering will match '$'
 * as well as '34'. Note that it also will match '12' which may or may not be displayed in grid, depending on cell format.
 * Note that we search objectValue when it's a string because displayValue is undefined in the following cases:
 * 1) when column type = CHECKBOX
 * 2) when column type = CONTACT_LIST or MULTI_CONTACT_LIST and entry is not a valid contact/multi-contact
 */
const isSubStringInObjValueStringOrDisplayValue = (subString: string, cell: CellForApplyCondition, columnType: ColumnType): boolean => {
    if (columnType !== ColumnType.TEXT_NUMBER && typeof cell.objectValue === 'number') {
        return false;
    }

    const isStringFound = (searchString: string) => searchString.toLowerCase().includes(subString.toLowerCase());

    if (typeof cell.objectValue === 'string') {
        return isStringFound(cell.objectValue);
    }

    if (cell.displayValue && isStringFound(cell.displayValue)) {
        return true;
    }

    // Also search numeric object values if the string representation is different than the display value string.
    if (typeof cell.objectValue === 'number' && cell.objectValue.toString() !== cell.displayValue) {
        return isStringFound(cell.objectValue.toString());
    }

    return false;
};

const isObjValueStringOrDisplayValueInSet = (conditionValueSet: Set<string>, cell: CellForApplyCondition): boolean => {
    // Check if cell value exists in condition value Set
    if (cell.objectValue != null && conditionValueSet.has(cell.objectValue.toString().toLowerCase())) {
        return true;
    }

    // Check if cell display value exists in condition value Set
    if (cell.displayValue != null && conditionValueSet.has(cell.displayValue.toLowerCase())) {
        return true;
    }

    return false;
};

// If object is not a string or number, return true
const isStringOrNumber = (objectValue: CellObjectValue): objectValue is string | number =>
    typeof objectValue === 'string' || typeof objectValue === 'number';

// Both condition value & objectValue should be number or both should be string. Return false if not.
const doesTypeMatch = (conditionNumber: number | undefined, objectValue: dv.CellObjectValue): boolean => {
    if (conditionNumber !== undefined && typeof objectValue === 'number') {
        return true;
    }
    if (conditionNumber === undefined && typeof objectValue === 'string') {
        return true;
    }
    return false;
};

/**
 * If the objectValue is not a string or number, return false.
 * If all values are numbers, then use numeric filtering.
 * Use string filtering if the object value is a string, and at least one of the start or end values are strings.
 * Otherwise, return false
 */
const isObjValueBetweenStartEndValues = (startValue: string, endValue: string, cell: CellForApplyCondition): boolean => {
    // If object is not a string or number, return false
    if (!isStringOrNumber(cell.objectValue)) {
        return false;
    }

    const startValueNumber = getNumberFromPercentStringOrOtherString(startValue);
    const endValueNumber = getNumberFromPercentStringOrOtherString(endValue);

    // If all values are numbers, then use numeric filtering
    if (startValueNumber !== undefined && endValueNumber !== undefined && typeof cell.objectValue === 'number') {
        return startValueNumber <= cell.objectValue && cell.objectValue <= endValueNumber;
    }

    // Use string filtering if the object value is a string, and at least one of the start or end values are strings
    if ((startValueNumber === undefined || endValueNumber === undefined) && typeof cell.objectValue === 'string') {
        const objectValueString = cell.objectValue.toLowerCase();
        return startValue.toLowerCase() <= objectValueString && objectValueString <= endValue.toLowerCase();
    }

    // Return false for the following invalid filter situations:
    // - If the object value is a number and either the start value or the end value is a string.
    // - If the object value is a string but the start and end values are both numbers.
    return false;
};

export const applyFilterCondition = (condition: FilterConditionForApplyCondition, cell: CellForApplyCondition, columnType: ColumnType): boolean => {
    switch (condition.operator) {
        case FilterConditionOperators.IS_CHECKED:
            return applyIsChecked(cell, columnType);
        case FilterConditionOperators.IS_NOT_CHECKED:
            return applyIsUnchecked(cell, columnType);
        case FilterConditionOperators.IS_ONE_OF:
            return applyIsOneOf(condition, cell, columnType);
        case FilterConditionOperators.IS_NOT_ONE_OF:
            return applyIsNotOneOf(condition, cell, columnType);
        case FilterConditionOperators.HAS_ANY_OF:
            return applyHasAnyOf(condition, cell, columnType);
        case FilterConditionOperators.HAS_NONE_OF:
            return applyHasNoneOf(condition, cell, columnType);
        case FilterConditionOperators.HAS_ALL_OF:
            return applyHasAllOf(condition, cell, columnType);
        case FilterConditionOperators.DOES_NOT_HAVE_ALL_OF:
            return applyDoesNotHaveAllOf(condition, cell, columnType);
        case FilterConditionOperators.CONTAINS:
            return applyContains(condition, cell, columnType);
        case FilterConditionOperators.DOES_NOT_CONTAIN:
            return applyDoesNotContain(condition, cell, columnType);
        case FilterConditionOperators.IS_BLANK:
            return applyIsBlank(condition, cell, columnType);
        case FilterConditionOperators.IS_NOT_BLANK:
            return applyIsNotBlank(condition, cell, columnType);
        case FilterConditionOperators.IS_EQUAL_TO:
            return applyIsEqualTo(condition, cell, columnType);
        case FilterConditionOperators.IS_NOT_EQUAL_TO:
            return applyIsNotEqualTo(condition, cell, columnType);
        case FilterConditionOperators.IS_GREATER_THAN:
            return applyIsGreaterThan(condition, cell, columnType);
        case FilterConditionOperators.IS_LESS_THAN:
            return applyIsLessThan(condition, cell, columnType);
        case FilterConditionOperators.IS_GREATER_THAN_OR_EQUAL_TO:
            return applyIsGreaterThanOrEqualTo(condition, cell, columnType);
        case FilterConditionOperators.IS_LESS_THAN_OR_EQUAL_TO:
            return applyIsLessThanOrEqualTo(condition, cell, columnType);
        case FilterConditionOperators.IS_BETWEEN:
            return applyIsBetween(condition, cell, columnType);
        case FilterConditionOperators.IS_NOT_BETWEEN:
            return applyIsNotBetween(condition, cell, columnType);
        case FilterConditionOperators.IS_A_NUMBER:
            return applyIsNumber(cell, columnType);
        case FilterConditionOperators.IS_NOT_A_NUMBER:
            return applyIsNotNumber(cell, columnType);
        case FilterConditionOperators.IS_A_DATE:
            return applyIsDate(cell, columnType);
        case FilterConditionOperators.IS_NOT_A_DATE:
            return applyIsNotDate(cell, columnType);
    }

    return false;
};
