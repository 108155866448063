/**
 * The Button component used in Filter drop-down inline controls.
 */

import * as React from 'react';

import { FilterButton } from './FilterButton';

interface Props {
    icon: string;
    value: any;
    onClick?: (value: any) => Promise<boolean> | void;
    controlId?: string;
}

interface State {
    isDeletingFilter: boolean;
}

export class MenuItemButton extends React.Component<Props> {
    public state: State;

    public constructor(props: Props) {
        super(props);
        this.state = {isDeletingFilter: false};
    }

    private handleClick = (e: React.SyntheticEvent<HTMLButtonElement>): void => {
        e.stopPropagation();

        if (this.props.onClick) {
            this.setState({isDeletingFilter: true});
            this.props.onClick(this.props.value);
        }
    }

    public render(): React.ReactNode {
        const { controlId } = this.props;
        return (
            <FilterButton
                controlId={controlId}
                onClick={this.handleClick}
                className="menu-item-button"
                isEnabled={!this.state.isDeletingFilter}
            >
                <img src={this.props.icon} />
            </FilterButton>
        );
    }
}
