// Determine whether the selected filter exists. Returns true if selected filter no longer exists; false if it's undefined or still exists
import { SheetFilter } from '../interfaces';

export const selectedSheetFilterDeleted = (selectedSheetFilterId?: string | null, sheetFilters?: SheetFilter[]): boolean => {
    if (!selectedSheetFilterId) {
        return false;
    }

    // If a selected sheet filter exists but available filters are undefined, return true
    if (!sheetFilters) {
        return true;
    }

    return Boolean(!sheetFilters.find((sheetFilter) => selectedSheetFilterId === sheetFilter.id));
};
