import * as React from 'react';
import { DataFunc, Mention, MentionProps, MentionsInput, MentionsInputProps, SuggestionDataItem } from 'react-mentions';
import { MAX_COMMENTS_CHARS } from '../../../../common/constants';
import { UserActions } from '../../../../common/enums/UserActions.enum';
import { ContactDto } from '../../../../common/interfaces/ContactDto.interface';
import { ActionType, UserAnalyticsAction } from '../../../../common/metrics/UserAnalyticsAction';
import { AtMentionsClient } from '../../../../http-clients';
import { LanguageElementsProp, withLanguageElementsHOC } from '../../../../language-elements/withLanguageElementsHOC';
import { adjustHeight } from '../Comment/AdjustHeight';
import './AtMentions.css';
import { Person } from './Person';
import './ProfileListItem.css';

const Patterns = {
    userMention: /@(\S+@\S+\.\S+)/,
};

export interface AtMentionsProps {
    viewId: string;
    rowId: number;
    reportSheetId?: number;
    text?: string;
    onChange?: (comment: string) => void;
    onSubmit?: (comment: string) => void;
    onFocus?: () => void;
    onBlur?: () => void;
}

interface State {
    value: string;
}

export class AtMentions extends React.Component<AtMentionsProps & LanguageElementsProp, State> {
    public state: State;
    public inputRef = React.createRef<HTMLTextAreaElement>();
    private throttledRequests = new Map<string, ReturnType<typeof setTimeout>>();
    private suggestionsForQuery = new Map<string, SuggestionDataItem[]>();
    private renderedSuggestions = new Map<string, React.ReactNode>();
    private contacts = new Map<string, ContactDto>();

    public constructor(props: AtMentionsProps & LanguageElementsProp) {
        super(props);
        this.state = { value: this.props.text || '' };
    }

    public render = () => {
        return (
            <MentionsInput
                className="mentionsEditor"
                placeholder={this.props.languageElements.COMMENT_ADD}
                value={this.state.value}
                allowSuggestionsAboveCursor={true}
                suggestionsPortalHost={document.querySelector('.mentionsFloatingPortal')!}
                inputRef={this.inputRef}
                onChange={this.handleOnChange}
                onKeyDown={this.handleOnKeyDown}
                onSelect={() => this.props.onFocus && this.props.onFocus()}
                onBlur={() => this.props.onBlur && this.props.onBlur()}
            >
                <Mention
                    className="userMentionHighlight"
                    trigger="@"
                    data={this.getSuggestions(this.props.rowId, this.props.reportSheetId)}
                    appendSpaceOnAdd={true}
                    markup={'@__id__'}
                    displayTransform={(id) => `@${id}`}
                    renderSuggestion={this.renderSuggestion}
                    regex={Patterns.userMention}
                    onAdd={this.handleOnAdd}
                />
            </MentionsInput>
        );
    };

    public focus = () => this.inputRef.current && this.inputRef.current.focus();

    private handleOnChange: MentionsInputProps['onChange'] = (event) => {
        const value = event.target.value.substr(0, MAX_COMMENTS_CHARS);
        event.target.value = value;
        adjustHeight(this.inputRef.current);
        this.setState({ value });
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };

    private handleOnKeyDown: MentionsInputProps['onKeyDown'] = (event) => {
        if (this.props.onSubmit && event.key === 'Enter' && !event.shiftKey) {
            this.props.onSubmit(this.state.value);
            this.setState({ value: '' });
        }
    };

    /**
     * Add a small delay before fetching the list of contacts that can be tagged (mentioned) in a comment.
     * This is to throttle the calls to the API for fetching contacts.  If a user is typing someone's name,
     * fetch the list when there is a pause in typing.  We don't want to hammer the API for every keystroke.
     *
     * @param requestName The type of request being throttled. This name will be used to identify other pending requests of the same kind.
     * @param request The request that will be made after the specified delay
     * @param delayInMillis The delay in milliseconds to wait before performing the request
     */
    private throttleRequest = (requestName: string, delayInMillis: number, request: () => void) => {
        this.cancelRequest(requestName);
        const throttledRequest = setTimeout(request, delayInMillis);
        this.throttledRequests.set(requestName, throttledRequest);
    };

    /**
     * Cancels a pending (throttled) request.
     *
     * @param requestName The name of the request to cancel.
     */
    private cancelRequest = (requestName: string) => {
        const existingRequest = this.throttledRequests.get(requestName);
        if (existingRequest) {
            clearTimeout(existingRequest);
            this.throttledRequests.delete(requestName);
        }
    };

    /**
     * Calls the DV API to fetch the list of contacts that can be used in the @Mentions with the view source.
     *
     * @param rowId The target row for the at-mentions.
     * @param reportSheetId The sheet ID of the row if the row is a report row.
     * @param query Any text typed after the @ symbol.  Used to filter the list of contacts.
     */
    private fetchContacts = async (rowId: number, reportSheetId: number | undefined, query: string) => {
        const mentionableContacts = await AtMentionsClient.getContactsForAtMentions(this.props.viewId, rowId, reportSheetId, query);
        mentionableContacts.forEach((contact) => this.contacts.set(contact.email, contact));
        const suggestions = mentionableContacts.map((contact) => ({ id: contact.email, display: contact.name || '' }));
        this.suggestionsForQuery.set(query, suggestions);
        return suggestions;
    };

    /**
     * Checks the cache for suggestions (contacts) that have already been fetched for the passed in query string.
     * If we don't have any cached values for the query, then we setup a new request to fetch contacts for the query string.
     * This request will be executed after a small delay; we throttle requests to avoid unnecessary burden on the API.
     *
     * @param query Any text typed after the @ symbol.  Used to filter the list of contacts.
     * @param callback Used to provide the list of contacts to the react-mentions component.
     */
    private getSuggestions =
        (rowId: number, reportSheetId: number | undefined): DataFunc =>
        (query, callback) => {
            // First, cancel any pending requests, since the query string has changed.
            this.cancelRequest(this.fetchContacts.name);

            // Next, check our cache.  No need to fetch contacts a second time if we already have them.
            const suggestionsForQuery = this.suggestionsForQuery.get(query);
            if (suggestionsForQuery) {
                callback(suggestionsForQuery);
                return;
            }

            // If we don't have any suggestions in the cache, then setup a new request.  This request will be throttled (have a small delay).
            this.throttleRequest(this.fetchContacts.name, 180, () => {
                this.fetchContacts(rowId, reportSheetId, query)
                    .then((suggestions) => {
                        // Cache the suggestions before handing it off to the react-mentions component.
                        this.suggestionsForQuery.set(query, suggestions);
                        callback(suggestions);
                    })
                    .catch((ignore) => {
                        /* TODO: Log that an error happened? */
                    });
            });
        };

    private renderSuggestion: MentionProps['renderSuggestion'] = (suggestion, search, highlightedDisplay) => {
        const email = suggestion.id.toString();
        let renderedSuggestion = this.renderedSuggestions.get(email);
        if (renderedSuggestion != null) {
            return renderedSuggestion;
        }

        const avatarStyle: any = {
            backgroundColor: Person.getNodeBackgroundColorByEmail(email),
        };

        const contact = this.contacts.get(email);
        const hasProfilePic = contact && contact.profileImageUrl;
        if (hasProfilePic) {
            Object.assign(avatarStyle, {
                backgroundImage: `url(${contact!.profileImageUrl!})`,
                backgroundSize: 'cover',
            });
        }

        renderedSuggestion = (
            <div className="profileListItem">
                <div className="profilePicture">
                    <span className={`profileAvatar`} style={avatarStyle}>
                        {hasProfilePic ? '' : Person.getInitials(contact!, Person.AVATAR_DEFAULT_SIZE)}
                    </span>
                </div>
                <div className="profileText">
                    <div className="profilePrimaryText">{highlightedDisplay}</div>
                    {/* If user has only an email address (in which case .id is rendered as highlightedDisplay above) don't render email again: */}
                    {suggestion.display !== '' && <div className="profileSecondaryText">{suggestion.id}</div>}
                </div>
            </div>
        );

        this.renderedSuggestions.set(email, renderedSuggestion);
        return renderedSuggestion;
    };

    private handleOnAdd: MentionProps['onAdd'] = () => {
        UserAnalyticsAction.add(ActionType.USER_ACTION, UserActions.ADD_AT_MENTION, { viewId: this.props.viewId });
    };
}

export default withLanguageElementsHOC(AtMentions);
