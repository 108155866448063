import { FilterConditionOperators } from '../../enums';
import { Column, IFilter, IFilterCondition, IFilterUI } from '../../interfaces';
import { getNumberFromPercentStringOrOtherString } from '../../utils';
import { FilterCondition } from './FilterCondition';

export class Filter {
    private static mergeValue(value: any, valueToMerge: any): any[] {
        // Convert arguments to arrays if they aren't already arrays.
        const valueArray = Array.isArray(value) ? value : [value];
        const valueToMergeArray = Array.isArray(valueToMerge) ? valueToMerge : [valueToMerge];

        return Array.from(new Set(valueArray.concat(valueToMergeArray)));
    }

    private static getFilterName(filter: IFilter | undefined): string {
        if (filter && filter.name) {
            return filter.name;
        }

        // Leave empty here to simplify handling of state for the input field (but convert an empty name to 'Unnamed filter' on Apply)
        return '';
    }

    private _name: string;
    private _id?: string;
    private _isValid: boolean;
    private _shared: boolean;
    private _ownerUserId?: string | null;
    private _disableAddCondition: boolean;
    private _disableDeleteCondition: boolean;
    private _isFilterEmpty: boolean;
    private _consolidateConditions: boolean;
    private _conditions: FilterCondition[];
    private readonly _fields: Column[];

    public constructor(fields: Column[], filter?: IFilter, consolidateConditions = false) {
        this._fields = fields;
        this._conditions = [];
        this._id = filter ? filter.id : undefined;
        this._name = Filter.getFilterName(filter);
        this._consolidateConditions = consolidateConditions;
        this._shared = filter ? filter.shared : false;
        this._ownerUserId = filter ? filter.ownerUserId : undefined;

        if (!filter || !filter.conditions) {
            this._conditions.push(new FilterCondition(this._fields, undefined));

            /*
             * Use 'fields' and only the basic props on filter conditions - columnId, operator and value - to
             * create new instances for each filter condition. This refreshes the validity properties on the condition as well as the
             * conditionOperators[] and conditionFields[], all of which are used in the filter definition modal.consolidatedCondition
             * We can ignore the validity props (& others) that are passed in on 'filter' here because we update
             * all of these props based on the current 'fields'.
             */
        } else {
            filter.conditions.forEach((condition: IFilterCondition) => {
                this._conditions.push(new FilterCondition(this._fields, condition));
            });
        }

        this.runChecks();
    }

    public toDisplayModel(): IFilterUI {
        return {
            id: this._id,
            name: this._name,
            conditions: this.conditions.map((condition: FilterCondition) => {
                return typeof condition.toDisplayModel === 'function' ? condition.toDisplayModel() : condition;
            }),
            isValid: this._isValid,
            ownerUserId: this._ownerUserId,
            isFilterEmpty: this._isFilterEmpty,
            disableAddCondition: this._disableAddCondition,
            disableDeleteCondition: this._disableDeleteCondition,
            shared: this._shared,
        };
    }

    public toFilterInterface(): IFilter {
        return {
            id: this._id,
            name: this._name,
            conditions: this.conditions.map((condition: FilterCondition) => {
                return typeof condition.toConditionInterface === 'function' ? condition.toConditionInterface() : condition;
            }),
            ownerUserId: this._ownerUserId,
            shared: this._shared,
        };
    }

    public addNewCondition(): void {
        this._conditions.push(new FilterCondition(this._fields, undefined));
        this.runChecks();
    }

    public deleteCondition(conditionIndex: number): void {
        this._conditions.splice(conditionIndex, 1);
        this.runChecks();
    }

    public updateCondition(condition: FilterCondition, conditionIndex: number): void {
        this._conditions.splice(conditionIndex, 1, condition);
        this.runChecks();
    }

    public changeName(name: string): void {
        this._name = name;
    }

    public setSharing(shared: boolean): void {
        this._shared = shared;
    }

    public setName(name: string): void {
        this._name = name;
    }

    /*
     * This returns consolidated filter conditions based on the following:
     * 1) if two conditions for the same columnId use 'is one of' or 'is not one of', consolidate these & eliminate duplicates
     * 2) if two conditions for the same columnId both use 'checked' or 'unchecked' or 'blank' or 'not blank', eliminate duplicate
     * 3) if two conditions for the same columnId use 'contains' or 'does not contain', eliminate duplicates
     */
    public getConsolidatedConditions(): FilterCondition[] {
        const consolidatedConditions: FilterCondition[] = [];
        this._conditions.forEach((condition) => {
            const index = this.getColumnAndOperatorMatchingIndex(condition, consolidatedConditions);
            // If columnId + operator on condition is not yet in 'consolidatedConditions', just push it & continue - no comparisons needed
            if (index === -1) {
                if (condition.operator === FilterConditionOperators.IS_BETWEEN || condition.operator === FilterConditionOperators.IS_NOT_BETWEEN) {
                    this.checkBetweenValuesForOrder(condition);
                }
                consolidatedConditions.push(condition);

                // Otherwise compare current condition to what is already in consolidatedConditions & consolidate if possible
            } else {
                const consolidatedCondition = consolidatedConditions[index];

                switch (condition.operator) {
                    case FilterConditionOperators.IS_ONE_OF:
                    case FilterConditionOperators.IS_NOT_ONE_OF:
                    case FilterConditionOperators.HAS_ANY_OF:
                    case FilterConditionOperators.HAS_NONE_OF:
                    case FilterConditionOperators.HAS_ALL_OF:
                    case FilterConditionOperators.DOES_NOT_HAVE_ALL_OF:
                        consolidatedConditions[index].value = Filter.mergeValue(consolidatedCondition.value, condition.value);
                        break;
                    case FilterConditionOperators.CONTAINS:
                    case FilterConditionOperators.DOES_NOT_CONTAIN:
                    case FilterConditionOperators.IS_EQUAL_TO:
                    case FilterConditionOperators.IS_NOT_EQUAL_TO:
                    case FilterConditionOperators.IS_GREATER_THAN:
                    case FilterConditionOperators.IS_LESS_THAN:
                    case FilterConditionOperators.IS_GREATER_THAN_OR_EQUAL_TO:
                    case FilterConditionOperators.IS_LESS_THAN_OR_EQUAL_TO:
                        if (condition.value !== consolidatedCondition.value) {
                            consolidatedConditions.push(condition);
                        }
                        break;
                    case FilterConditionOperators.IS_BETWEEN:
                    case FilterConditionOperators.IS_NOT_BETWEEN:
                        this.checkBetweenValuesForOrder(condition);

                        if (
                            condition.value.startValue !== consolidatedCondition.value.startValue ||
                            condition.value.endValue !== consolidatedCondition.value.endValue
                        ) {
                            consolidatedConditions.push(condition);
                        }
                        break;
                    case FilterConditionOperators.IS_CHECKED:
                    case FilterConditionOperators.IS_NOT_CHECKED:
                    case FilterConditionOperators.IS_BLANK:
                    case FilterConditionOperators.IS_NOT_BLANK:
                    case FilterConditionOperators.IS_A_NUMBER:
                    case FilterConditionOperators.IS_NOT_A_NUMBER:
                        // No need to do anything because identical condition is already in cleanConditions
                        break;
                    default:
                        consolidatedConditions.push(condition);
                }
            }
        });

        return consolidatedConditions;
    }

    public get name(): string {
        return this._name;
    }

    public get isValid(): boolean {
        return this._isValid;
    }

    public get shared(): boolean {
        return this._shared;
    }

    /*
     * When a new filter is applied and saved, consolidate conditions that have the same columnIds & operators.
     * Otherwise don't consolidate conditions while the filter modal is open -
     * just keep the conditions however user has entered them, until they apply the filter.
     */
    public get conditions(): FilterCondition[] {
        return this._consolidateConditions ? this.getConsolidatedConditions() : this._conditions;
    }

    private runChecks(): void {
        this.checkValidity();
        this.setDisabledStatus();
        this.checkIsFilterEmpty();
    }

    private checkValidity(): void {
        const fieldsValid = this._fields.length > 0;
        const conditionIsValid = this._conditions.every((condition: FilterCondition) => condition.isValid);
        this._isValid = fieldsValid && conditionIsValid;
    }

    private setDisabledStatus(): void {
        this._disableAddCondition = !this._isValid || this._conditions.length > 5;
        this._disableDeleteCondition = this._conditions.length === 1;
    }

    private checkIsFilterEmpty = (): void => {
        this._isFilterEmpty =
            this.conditions.length === 0 ||
            this.conditions.every((condition) => condition.columnId === '' && (condition.value == null || condition.value.length === 0));
    };

    private checkBetweenValuesForOrder = (condition: FilterCondition): void => {
        let isOrderIncorrect: boolean;

        const startNumber = getNumberFromPercentStringOrOtherString(condition.value.startValue);
        const endNumber = getNumberFromPercentStringOrOtherString(condition.value.endValue);

        if (startNumber !== undefined && endNumber !== undefined) {
            isOrderIncorrect = endNumber < startNumber;
        } else {
            isOrderIncorrect = condition.value.endValue.localeCompare(condition.value.startValue) === -1;
        }

        if (isOrderIncorrect) {
            const newStart = condition.value.endValue;
            const newEnd = condition.value.startValue;
            condition.updateConditionOption({ startValue: newStart, endValue: newEnd });
        }
    };

    private getColumnAndOperatorMatchingIndex = (condition: FilterCondition, consolidatedConditions: FilterCondition[]): number => {
        return consolidatedConditions.findIndex((consolidatedCondition) => {
            return condition.columnId === consolidatedCondition.columnId && condition.operator === consolidatedCondition.operator;
        });
    };
}
