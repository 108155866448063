import {
    Currency,
    DataTransforms,
    DateFormat,
    DecodedFormat,
    EncodedFormat,
    FontFamily,
    FontWeight,
    HorizontalAlignment,
    ItalicStyle,
    Models,
    NegativeStyle,
    NumberFormat,
    TextWrap,
    ThousandsSeparator,
    UnderlineStyle,
    VerticalAlignment,
} from '@smartsheet/core-views-api-models';
import { MAX_FRACTION_DIGITS } from '../constants/Smartsheet.constants';
import { CurrencySymbolType, Locale } from '../enums';
import { Format, SmartsheetNumberFormatObject } from '../interfaces';
import { smartsheetFormatUtility } from '../utils';
import { getNumValidDecimalPlaces, getNumberFromString, isStringNumber, safeMultiplyByPowerOf10, safeNumberToString } from './NumbersUtility';
import 'reflect-metadata';
import { DateFnsFormat } from '../enums/DateFnsFormat.enum';
import { DecodedNumberFormatObject } from '../interfaces/DecodedNumberFormatObject';
import { getDefaultDateFnsFormatForLocale } from './DatesFns';
import { formattedDateString } from './MomentDates';

// region GENERICS
export const getFormattedDate = (date: string, format: EncodedFormat, locale?: string, applyGsFormatting = false): string => {
    if (applyGsFormatting) {
        const fullFormatObject = DataTransforms.toDecodedFormat(format);

        if (fullFormatObject.dateFormat !== DateFormat.DEFAULT_LOCALE_BASED) {
            const dateFormat = fullFormatObject.dateFormat as keyof typeof DateFnsFormat;
            return formattedDateString(date, DateFnsFormat[dateFormat]);
        }
    }

    return formattedDateString(date, getDefaultDateFnsFormatForLocale(locale));
};

export const getFormattedNumericValueAll = (inputValue: number, format?: Format, applyGsFormatting: boolean = false): string => {
    if (format && applyGsFormatting && typeof format !== 'string') {
        return getFormattedNumericValueGS(inputValue, format);
    }
    if (!format || typeof format === 'string') {
        return smartsheetFormatUtility.getFormattedNumericValue(inputValue, format);
    }
    return '';
};

export const getNumberFormatObjectAll = (
    format?: Format,
    applyGsFormatting: boolean = false
): SmartsheetNumberFormatObject | DecodedNumberFormatObject => {
    if (format && applyGsFormatting && typeof format !== 'string') {
        return getNumberFormatObjectGS(format);
    }
    if (format && typeof format === 'string') {
        return smartsheetFormatUtility.getNumberFormatObject(format);
    }
    return {} as SmartsheetNumberFormatObject;
};

export const getFormattedValueForEditAll = (input: string | number, format: Format | undefined, isDefaultValue: boolean = false): string | number => {
    if (format && typeof format !== 'string') {
        return getFormattedValueForEditGS(input, format, isDefaultValue);
    }
    return smartsheetFormatUtility.getFormattedValueForEdit(input, format, isDefaultValue);
};

/**
 * Returns a number if the number can be converted to a Smartsheet number and modifies it as needed based on a formatString
 * Treats scientific notation as a string on input, removes leading apostrophes unless isDefaultValueDefinition
 * is specified the value is a numeric string.
 */
export const getNumberFromInputAndFormatStringAll = (
    input: string | number,
    format: Format | undefined,
    isDefaultValueDefinition: boolean = false,
    skipGettingNumberFromString: boolean = false
): string | number => {
    if (format && typeof format !== 'string') {
        return getNumberFromInputAndFormatStringGS(input, format, isDefaultValueDefinition, skipGettingNumberFromString);
    }
    return smartsheetFormatUtility.getNumberFromInputAndFormatString(input, format, isDefaultValueDefinition, skipGettingNumberFromString);
};

/**
 * Returns an array of classnames based on a format used in cell formatting
 * CSS associated with these classes is in CellFormats.css
 */
export const getCellFormatClassNamesAll = (
    format?: Format,
    conditionalCellFormat?: Format,
    conditionalRowFormat?: Format,
    formatOnRow?: Format,
    applyGsFormatting: boolean = false
): string[] => {
    if (
        applyGsFormatting &&
        typeof format !== 'string' &&
        typeof conditionalCellFormat !== 'string' &&
        typeof conditionalRowFormat !== 'string' &&
        typeof formatOnRow !== 'string'
    ) {
        return getCellFormatClassNamesGS(format, conditionalCellFormat, conditionalRowFormat, formatOnRow);
    }

    if (
        (!format || typeof format === 'string') &&
        (!conditionalCellFormat || typeof conditionalCellFormat === 'string') &&
        (!conditionalRowFormat || typeof conditionalRowFormat === 'string') &&
        (!formatOnRow || typeof formatOnRow === 'string')
    ) {
        // Combine format strings from the cell and the row. Conditional formats take precedence over regular formats.
        const consolidatedFormatString = smartsheetFormatUtility.getConsolidatedFormatString(format, conditionalCellFormat, conditionalRowFormat);

        // Get an array of classnames that relate to the consolidatedFormatString in order to apply styles to the grid
        return smartsheetFormatUtility.getCellFormatClassNames(consolidatedFormatString);
    }

    return [];
};

/**
 * Returns a number if the number can be converted to a Smartsheet number and modifies it as needed based on a formatString
 * Treats scientific notation as a string on input, removes leading apostrophes unless isDefaultValueDefinition
 * is specified the value is a numeric string.
 */
export const getNumberFromTrimmedInputAndFormatStringAll = (
    input: string | number,
    format: Format | undefined,
    isDefaultValueDefinition: boolean = false
) => {
    let valueToGetNumberFrom = input;
    if (typeof valueToGetNumberFrom === 'string') {
        valueToGetNumberFrom = valueToGetNumberFrom.trim();
    }
    return getNumberFromInputAndFormatStringAll(valueToGetNumberFrom, format, isDefaultValueDefinition);
};

// endregion

// region Methods to apply GS formatting

/**
 * Returns a number if the number can be converted to a Smartsheet number and modifies it as needed based on a formatString
 * Treats scientific notation as a string on input, removes leading apostrophes unless isDefaultValueDefinition
 * is specified the value is a numeric string.
 */
export const getNumberFromInputAndFormatStringGS = (
    input: string | number,
    format?: EncodedFormat,
    isDefaultValueDefinition = false,
    skipGettingNumberFromString = false
): string | number => {
    const valueToGetNumberFrom = input;
    let numberFromInput;
    if (typeof valueToGetNumberFrom === 'string') {
        // value should be a string
        const inputWithoutApostrophe = removeSpecificLeadingCharacter(valueToGetNumberFrom, "'");
        if (inputWithoutApostrophe !== valueToGetNumberFrom) {
            // if we are not saving a default value, the leading apostrophe is removed
            return isDefaultValueDefinition ? valueToGetNumberFrom : inputWithoutApostrophe;
        }

        // Skip getting the number and formatting string. This is used primarily for rendering the input string in a component allowing for
        // spaces that would otherwise be trimmed
        if (skipGettingNumberFromString) {
            return valueToGetNumberFrom;
        }

        numberFromInput = getNumberFromString(valueToGetNumberFrom);
    } else {
        numberFromInput = valueToGetNumberFrom;
    }

    // input is not of type number and is not a string number
    if (numberFromInput === undefined) {
        return valueToGetNumberFrom;
    }

    if (format) {
        const numberFormatObject = getNumberFormatObjectGS(format);
        // Get value if format is percent
        if (numberFormatObject && numberFormatObject.numberFormat === Models.NumberFormat.PERCENT) {
            numberFromInput = safeMultiplyByPowerOf10(numberFromInput, -2);
        }
    }

    // Check for too many decimals
    const maxAllowedDecimals = getNumValidDecimalPlaces(numberFromInput);

    return parseFloat(numberFromInput.toFixed(maxAllowedDecimals));
};

/**
 * Returns an array of classnames based on a EncodedFormat used in cell formatting
 * CSS associated with these classes is in CellFormats.css
 */
export const getCellFormatClassNamesGS = (
    format?: EncodedFormat,
    conditionalCellFormat?: EncodedFormat,
    conditionalRowFormat?: EncodedFormat,
    formatOnRow?: EncodedFormat
): string[] => {
    const classNames: string[] = [];
    const fullFormatObject = format ? DataTransforms.toDecodedFormat(format) : DEFAULT_DECODED_FORMAT_VALUE;
    const fullConditionalCellFormat = conditionalCellFormat ? DataTransforms.toDecodedFormat(conditionalCellFormat) : DEFAULT_DECODED_FORMAT_VALUE;
    const fullConditionalRowFormat = conditionalRowFormat ? DataTransforms.toDecodedFormat(conditionalRowFormat) : DEFAULT_DECODED_FORMAT_VALUE;
    const fullRowFormatOnRow = formatOnRow ? DataTransforms.toDecodedFormat(formatOnRow) : DEFAULT_DECODED_FORMAT_VALUE;

    const mergedFormat = mergeFormats(fullFormatObject, fullConditionalCellFormat, fullConditionalRowFormat, fullRowFormatOnRow);

    if (mergedFormat.backgroundColor !== DEFAULT_DECODED_FORMAT_VALUE.backgroundColor) {
        classNames.push(`background-color-${mergedFormat.backgroundColor!.replace('#', '')}`);
    }

    if (mergedFormat.foregroundColor !== DEFAULT_DECODED_FORMAT_VALUE.foregroundColor) {
        classNames.push(`text-color-${mergedFormat.foregroundColor!.replace('#', '')}`);
    }

    if (mergedFormat.fontFamily !== DEFAULT_DECODED_FORMAT_VALUE.fontFamily) {
        classNames.push(`font-family-${mergedFormat.fontFamily!.toLocaleLowerCase().split(' ').join('-')}`);
    }

    if (mergedFormat.fontSize !== DEFAULT_DECODED_FORMAT_VALUE.fontSize) {
        classNames.push(`font-size-${mergedFormat.fontSize}`);
    }

    if (mergedFormat.fontWeight !== DEFAULT_DECODED_FORMAT_VALUE.fontWeight) {
        classNames.push(`font-weight-${mergedFormat.fontWeight}`);
    }

    if (mergedFormat.italicStyle !== DEFAULT_DECODED_FORMAT_VALUE.italicStyle) {
        classNames.push('italic');
    }

    if (mergedFormat.textWrap !== DEFAULT_DECODED_FORMAT_VALUE.textWrap) {
        classNames.push(`text-wrap-${mergedFormat.textWrap}`);
    }

    if (mergedFormat.underlineStyle !== DEFAULT_DECODED_FORMAT_VALUE.underlineStyle) {
        classNames.push(`text-underline-${mergedFormat.underlineStyle}`);
    }

    if (mergedFormat.verticalAlignment !== DEFAULT_DECODED_FORMAT_VALUE.verticalAlignment) {
        classNames.push(`align-items-${mergedFormat.verticalAlignment}`);
    }

    if (mergedFormat.horizontalAlignment !== DEFAULT_DECODED_FORMAT_VALUE.horizontalAlignment) {
        classNames.push(`text-align-${mergedFormat.horizontalAlignment}`);
    }

    return classNames;
};

/**
 * Returns a value formatted for editing
 */
export const getFormattedValueForEditGS = (input: string | number, format?: EncodedFormat, isDefaultValue: boolean = false): string | number => {
    let valueToBeFormatted = input;

    // Add leading apostrophe if needed
    if (typeof valueToBeFormatted === 'string') {
        valueToBeFormatted = valueToBeFormatted.trim();

        if (!isDefaultValue) {
            let inputWithoutApostrophe = removeSpecificLeadingCharacter(valueToBeFormatted, "'");

            while (inputWithoutApostrophe.length > 0 && inputWithoutApostrophe[0] === "'") {
                inputWithoutApostrophe = removeSpecificLeadingCharacter(inputWithoutApostrophe, "'");
            }

            if (isStringNumber(inputWithoutApostrophe)) {
                return `'${valueToBeFormatted}`;
            }
        }
    }

    // if the input is a number or if this is a new submission with a string number input
    if (format && valueToBeFormatted && (typeof valueToBeFormatted === 'number' || (isDefaultValue && isStringNumber(valueToBeFormatted)))) {
        const numberFormatObject = getNumberFormatObjectGS(format);

        // Update to handle percent values
        if (numberFormatObject && numberFormatObject.numberFormat === Models.NumberFormat.PERCENT) {
            return safeMultiplyByPowerOf10(valueToBeFormatted, 2);
        }
    }

    return valueToBeFormatted;
};

export const getFormattedNumericValueGS = (inputValue: number, format: EncodedFormat): string => {
    const numericFormatObject = getNumberFormatObjectGS(format);
    const maxAllowedDecimals = Math.min(MAX_FRACTION_DIGITS, getNumValidDecimalPlaces(inputValue));
    let formattedNumericValue = safeNumberToString(inputValue);
    if (numericFormatObject) {
        const maximumFractionDigits =
            numericFormatObject.decimalCount != null ? Math.min(maxAllowedDecimals, numericFormatObject.decimalCount) : maxAllowedDecimals;

        const minimumFractionDigits = numericFormatObject.decimalCount != null ? maximumFractionDigits : 0;
        switch (numericFormatObject.numberFormat) {
            case Models.NumberFormat.NUMBER:
                formattedNumericValue = inputValue.toLocaleString(Locale.EN_US, {
                    maximumFractionDigits,
                    minimumFractionDigits,
                    useGrouping: numericFormatObject.thousandSeparator,
                });

                break;

            case Models.NumberFormat.PERCENT:
                formattedNumericValue =
                    safeMultiplyByPowerOf10(inputValue, 2).toLocaleString(Locale.EN_US, {
                        maximumFractionDigits,
                        minimumFractionDigits,
                        useGrouping: numericFormatObject.thousandSeparator,
                    }) + '%';
                break;

            case Models.NumberFormat.CURRENCY:
                const currencySymbol = numericFormatObject.currencyObject ? numericFormatObject.currencyObject.symbol : CurrencySymbolType.NONE;
                const offsetSymbolRight = numericFormatObject.currencyObject ? numericFormatObject.currencyObject.offsetRight : false;
                const currencyNegativeSign = inputValue < 0 ? '-' : '';

                // NOTE: JPY never contains decimals per international standards and this is reflected in gs
                const currencyDisplayValue = Math.abs(inputValue).toLocaleString(Locale.EN_US, {
                    maximumFractionDigits: currencySymbol === CurrencySymbolType.JPY ? 0 : maximumFractionDigits,
                    minimumFractionDigits: currencySymbol === CurrencySymbolType.JPY ? 0 : minimumFractionDigits,
                    useGrouping: numericFormatObject.thousandSeparator,
                });
                formattedNumericValue = offsetSymbolRight
                    ? currencyNegativeSign + currencyDisplayValue + currencySymbol
                    : currencyNegativeSign + currencySymbol + currencyDisplayValue;

                break;

            default:
                formattedNumericValue = Number(formattedNumericValue).toLocaleString(Locale.EN_US, {
                    maximumFractionDigits,
                    minimumFractionDigits,
                    useGrouping: numericFormatObject.thousandSeparator,
                });
                break;
        }
    } else {
        formattedNumericValue = Number(formattedNumericValue).toLocaleString(undefined, {
            maximumFractionDigits: maxAllowedDecimals,
            minimumFractionDigits: 0,
            useGrouping: false,
        });
    }

    return formattedNumericValue;
};
export const getNumberFormatObjectGS = (format: EncodedFormat): DecodedNumberFormatObject => {
    const fullFormatObject = DataTransforms.toDecodedFormat(format);
    return {
        currencyObject: CURRENCY_SYMBOLS_MAP.get(fullFormatObject.currency),
        numberFormat: fullFormatObject.numberFormat || undefined,
        decimalCount: fullFormatObject.decimalCount !== 'default' ? fullFormatObject.decimalCount : undefined,
        thousandSeparator: fullFormatObject.thousandsSeparator !== ThousandsSeparator.NONE,
    };
};

// endregion

// region Helpers
/**
 * Helper Method: Removes specific leading character if it's present
 */
export const removeSpecificLeadingCharacter = (input: string, character: string): string => {
    return input.length && input[0] === character ? input.slice(1) : input;
};

/**
 * Helper Method: Combine decoded formats with the following priority order:
 * conditionalCellFormat => conditionalRowFormat => format (conditionalCellFormat takes precedence).
 */
export const mergeFormats = (
    format: DecodedFormat,
    conditionalCellFormat: DecodedFormat,
    conditionalRowFormat: DecodedFormat,
    rowFormat: DecodedFormat
): DecodedFormat => {
    const getMergedFormatProperty = (property: keyof DecodedFormat, defaultProperty?: string | number) =>
        getFormatProperty(format, conditionalCellFormat, conditionalRowFormat, rowFormat, property, defaultProperty);

    return {
        backgroundColor: getMergedFormatProperty('backgroundColor', DEFAULT_DECODED_FORMAT_VALUE.backgroundColor),
        currency: getMergedFormatProperty('currency', DEFAULT_DECODED_FORMAT_VALUE.currency),
        dateFormat: getMergedFormatProperty('dateFormat', DEFAULT_DECODED_FORMAT_VALUE.dateFormat),
        decimalCount: getMergedFormatProperty('decimalCount', DEFAULT_DECODED_FORMAT_VALUE.decimalCount),
        fontFamily: getMergedFormatProperty('fontFamily', DEFAULT_DECODED_FORMAT_VALUE.fontFamily),
        fontSize: getMergedFormatProperty('fontSize', DEFAULT_DECODED_FORMAT_VALUE.fontSize),
        fontWeight: getMergedFormatProperty('fontWeight', DEFAULT_DECODED_FORMAT_VALUE.fontWeight),
        foregroundColor: getMergedFormatProperty('foregroundColor', DEFAULT_DECODED_FORMAT_VALUE.foregroundColor),
        horizontalAlignment: getMergedFormatProperty('horizontalAlignment', DEFAULT_DECODED_FORMAT_VALUE.horizontalAlignment),
        italicStyle: getMergedFormatProperty('italicStyle', DEFAULT_DECODED_FORMAT_VALUE.italicStyle),
        negativeStyle: getMergedFormatProperty('negativeStyle', DEFAULT_DECODED_FORMAT_VALUE.negativeStyle),
        numberFormat: getMergedFormatProperty('numberFormat', DEFAULT_DECODED_FORMAT_VALUE.numberFormat),
        taskbarColor: getMergedFormatProperty('taskbarColor', DEFAULT_DECODED_FORMAT_VALUE.taskbarColor),
        textWrap: getMergedFormatProperty('textWrap', DEFAULT_DECODED_FORMAT_VALUE.textWrap),
        thousandsSeparator: getMergedFormatProperty('thousandsSeparator', DEFAULT_DECODED_FORMAT_VALUE.thousandsSeparator),
        underlineStyle: getMergedFormatProperty('underlineStyle', DEFAULT_DECODED_FORMAT_VALUE.underlineStyle),
        verticalAlignment: getMergedFormatProperty('verticalAlignment', DEFAULT_DECODED_FORMAT_VALUE.verticalAlignment),
    } as DecodedFormat;
};

/**
 * Helper Method: Retrieves a specific property from a set of decoded formats, prioritizing conditional formats over a default format.
 */
export const getFormatProperty = (
    format: DecodedFormat,
    conditionalCellFormat: DecodedFormat,
    conditionalRowFormat: DecodedFormat,
    rowFormat: DecodedFormat,
    property: keyof DecodedFormat,
    defaultProperty?: string | number
): string | number | undefined => {
    if (defaultProperty === undefined) {
        return conditionalCellFormat?.[property] ?? conditionalRowFormat?.[property] ?? format?.[property] ?? rowFormat?.[property];
    }

    if (conditionalCellFormat?.[property] !== defaultProperty) {
        return conditionalCellFormat[property];
    }

    if (conditionalRowFormat?.[property] !== defaultProperty) {
        return conditionalRowFormat[property];
    }

    if (format?.[property] !== defaultProperty) {
        return format[property];
    }

    return rowFormat?.[property];
};

// endregion

export const CURRENCY_SYMBOLS_MAP = new Map<string, { symbol: CurrencySymbolType; offsetRight: boolean }>([
    [Currency.NONE, { symbol: CurrencySymbolType.NONE, offsetRight: false }],
    [Currency.ARS, { symbol: CurrencySymbolType.ARS, offsetRight: false }], // AR$ 2.00
    [Currency.AUD, { symbol: CurrencySymbolType.AUD, offsetRight: false }], // A$3.00
    [Currency.SEK, { symbol: CurrencySymbolType.SEK, offsetRight: true }], // 1.00 kr
    [Currency.BRL, { symbol: CurrencySymbolType.BRL, offsetRight: true }], // R$4.00
    [Currency.CAD, { symbol: CurrencySymbolType.CAD, offsetRight: true }], // CA$1.00
    [Currency.CLP, { symbol: CurrencySymbolType.CLP, offsetRight: false }], // CLP$2.00
    [Currency.EUR, { symbol: CurrencySymbolType.EUR, offsetRight: false }], // €1.00
    [Currency.GBP, { symbol: CurrencySymbolType.GBP, offsetRight: false }], // £3.00
    [Currency.ILS, { symbol: CurrencySymbolType.ILS, offsetRight: false }], // ₪4.00
    [Currency.INR, { symbol: CurrencySymbolType.INR, offsetRight: false }], // ₹3.00
    [Currency.JPY, { symbol: CurrencySymbolType.JPY, offsetRight: false }], // ¥1
    [Currency.MXN, { symbol: CurrencySymbolType.MXN, offsetRight: false }], // MX$2.00
    [Currency.RUB, { symbol: CurrencySymbolType.RUB, offsetRight: true }], // 1.00 ₽
    [Currency.USD, { symbol: CurrencySymbolType.USD, offsetRight: false }], // $1.00
    [Currency.ZAR, { symbol: CurrencySymbolType.ZAR, offsetRight: false }], // R3.00
    [Currency.CHF, { symbol: CurrencySymbolType.CHF, offsetRight: false }], // CHF2.00
    [Currency.CNY, { symbol: CurrencySymbolType.CNY, offsetRight: false }], // CN¥3.00
    [Currency.DKK, { symbol: CurrencySymbolType.DKK, offsetRight: true }], // 4.00 kr.
    [Currency.HKD, { symbol: CurrencySymbolType.HKD, offsetRight: false }], // HK$2.00
    [Currency.KRW, { symbol: CurrencySymbolType.KRW, offsetRight: false }], // ₩4.00
    [Currency.NOK, { symbol: CurrencySymbolType.NOK, offsetRight: false }], // kr 4.00
    [Currency.NZD, { symbol: CurrencySymbolType.NZD, offsetRight: false }], // NZ$3.00
    [Currency.SGD, { symbol: CurrencySymbolType.SGD, offsetRight: false }], // SG$2.00
]);

export const DEFAULT_DECODED_FORMAT_VALUE: DecodedFormat = {
    backgroundColor: undefined,
    currency: Currency.NONE,
    dateFormat: DateFormat.DEFAULT_LOCALE_BASED,
    decimalCount: 'default',
    fontFamily: FontFamily.DEFAULT,
    fontSize: 'default',
    fontWeight: FontWeight.DEFAULT,
    foregroundColor: undefined,
    horizontalAlignment: HorizontalAlignment.DEFAULT,
    italicStyle: ItalicStyle.NONE,
    negativeStyle: NegativeStyle.NONE,
    numberFormat: NumberFormat.NONE,
    taskbarColor: undefined,
    textWrap: TextWrap.NONE,
    thousandsSeparator: ThousandsSeparator.NONE,
    underlineStyle: UnderlineStyle.NONE,
    verticalAlignment: VerticalAlignment.DEFAULT,
};
