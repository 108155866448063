import { HandleContacts } from '../classes';
import { CURRENT_USER } from '../constants';
import { ColumnType, ObjectType } from '../enums';
import { CellObjectValue } from '../interfaces';
import { isContactObjectValue } from './IsContactObjectValue';
import { isMultiPicklistObjectValue } from './IsMultiPicklistObjectValue';
import { smartsheetFormatUtility } from './SmartsheetFormatUtility';

export const getObjectValueFromDefaultValue = (
    defaultValue: any,
    columnType: ColumnType,
    userEmail: string,
    userName?: string,
    forDefaultValueDefn = false,
    isContactListObjectValue: boolean = false
): CellObjectValue => {
    if (defaultValue == null || defaultValue === '') {
        return undefined;
    }

    const handleContacts = new HandleContacts({ email: userEmail, name: userName });

    switch (columnType) {
        case ColumnType.MULTI_CONTACT_LIST:
            // handle multi-picklist column transformed to multi-contact
            if (isMultiPicklistObjectValue(defaultValue) && defaultValue.values) {
                return defaultValue.values.join(', ');
            }

            return handleContacts.getMultiContactObjectValue(defaultValue, true);
        case ColumnType.MULTI_PICKLIST:
            if (isMultiPicklistObjectValue(defaultValue)) {
                return defaultValue;
            }
            if (Array.isArray(defaultValue)) {
                return handleContacts.getMultiPicklistFromContactArray(defaultValue);
            }

            return {
                objectType: ObjectType.MULTI_PICKLIST,
                values: [getStringFromDefaultValue(defaultValue, userEmail, handleContacts)],
            };
        case ColumnType.PICKLIST:
            if (isMultiPicklistObjectValue(defaultValue)) {
                if (defaultValue.values.length > 1) {
                    return '';
                } else {
                    return defaultValue.values[0];
                }
            }
            return defaultValue;

        case ColumnType.CHECKBOX:
            // there is no default value or it is set to false
            if (defaultValue == null || (typeof defaultValue === 'string' && defaultValue.toLowerCase() === 'false')) {
                // convert to a boolean
                return false;
            }
            // Otherwise, convert the default value to a boolean
            if (typeof defaultValue === 'string' && defaultValue.toLowerCase() === 'true') {
                return true;
            }
            return getStringFromDefaultValue(defaultValue, userEmail, handleContacts);
        case ColumnType.TEXT_NUMBER:
            if (isMultiPicklistObjectValue(defaultValue) || Array.isArray(defaultValue)) {
                return getStringFromDefaultValue(defaultValue, userEmail, handleContacts);
            }

            let numberFromInputString;
            if (forDefaultValueDefn) {
                numberFromInputString = smartsheetFormatUtility.getNumberFromInputAndFormatString(defaultValue, undefined, forDefaultValueDefn, true);
            } else {
                numberFromInputString = smartsheetFormatUtility.getNumberFromTrimmedInputAndFormatString(defaultValue, undefined);
            }

            return typeof numberFromInputString === 'number'
                ? numberFromInputString
                : // will substitute current user if set
                  getStringFromDefaultValue(numberFromInputString, userEmail, handleContacts);
        case ColumnType.CONTACT_LIST:
            // Handle contact list column transform
            if (isContactObjectValue(defaultValue)) {
                return defaultValue;
            }

            // Return default value as contact object if requested (e.g. for use with Grid Service)
            if (isContactListObjectValue) {
                return handleContacts.getFormattedContact(defaultValue, undefined, true);
            }
            return getStringFromDefaultValue(defaultValue, userEmail, handleContacts);
        default:
            return getStringFromDefaultValue(defaultValue, userEmail, handleContacts);
    }
};

/**
 * if we do not want to replace the current user, do not send the userEmail
 */
const getStringFromDefaultValue = (defaultValue: any, userEmail: string, handleContacts: HandleContacts): string => {
    if (isMultiPicklistObjectValue(defaultValue)) {
        return defaultValue.values.join(', ');
    }

    if (!Array.isArray(defaultValue)) {
        if (defaultValue === CURRENT_USER) {
            return userEmail;
        }
        return String(defaultValue);
    }

    return handleContacts.getStringFromContactArray(defaultValue, true);
};
