export enum Locale {
    // English
    EN_US = 'en-US', // United States
    EN_AU = 'en-AU', // Australia
    EN_CA = 'en-CA', // Canada
    EN_IN = 'en-IN', // India
    EN_IE = 'en-IE', // Ireland
    EN_MT = 'en-MT', // Malta
    EN_NZ = 'en-NZ', // New Zealand
    EN_PH = 'en-PH', // Philippines
    EN_SG = 'en-SG', // Singapore
    EN_ZA = 'en-ZA', // South Africa
    EN_GB = 'en-GB', // United Kingdom
    // Albanian
    SQ_AL = 'sq-AL', // Albania
    // Arabic
    AR_DZ = 'ar-DZ', // Algeria
    AR_BH = 'ar-BH', // Bahrain
    AR_EG = 'ar-EG', // Egypt
    AR_IQ = 'ar-IQ', // Iraq
    AR_JO = 'ar-JO', // Jordan
    AR_KW = 'ar-KW', // Kuwait
    AR_LB = 'ar-LB', // Lebanon
    AR_LY = 'ar-LY', // Libya
    AR_MA = 'ar-MA', // Morocco
    AR_OM = 'ar-OM', // Oman
    AR_QA = 'ar-QA', // Qatar
    AR_SA = 'ar-SA', // Saudi Arabia
    AR_SD = 'ar-SD', // Sudan
    AR_SY = 'ar-SY', // Syria
    AR_TN = 'ar-TN', // Tunisia
    AR_AE = 'ar-AE', // United Arab Emirates
    AR_YE = 'ar-YE', // Yemen
    // Belarusian
    BE_BY = 'be-BY', // Belarus
    // Bulgarian
    BG_BG = 'bg-BG', // Bulgaria
    // Catalan
    CA_ES = 'ca-ES', // Spain
    // Chinese
    ZH_CN = 'zh-CN', // China
    ZH_HK = 'zh-HK', // Hong Kong
    ZH_SG = 'zh-SG', // Singapore
    ZH_TW = 'zh-TW', // Taiwan
    // Croatian
    HR_HR = 'hr-HR', // Croatia
    // Czech
    CS_CZ = 'cs-CZ', // Czech Republic
    // Danish
    DA_DK = 'da-DK', // Denmark
    // German
    DE_DE = 'de-DE', // Germany
    DE_LU = 'de-LU', // Luxembourg
    DE_AT = 'de-AT', // Austria
    DE_CH = 'de-CH', // Switzerland
    // Dutch
    NL_BE = 'nl-BE', // Belgium
    NL_NL = 'nl-NL', // Netherlands
    // Spanish
    ES_AR = 'es-AR', // Argentina
    ES_BO = 'es-BO', // Bolivia
    ES_CL = 'es-CL', // Chile
    ES_CO = 'es-CO', // Colombia
    ES_CR = 'es-CR', // Costa Rica
    ES_EC = 'es-EC', // Ecuador
    ES_SV = 'es-SV', // El Salvador
    ES_ES = 'es-ES', // Spain
    ES_US = 'es-US', // United States
    ES_GT = 'es-GT', // Guatemala
    ES_HN = 'es-HN', // Honduras
    ES_MX = 'es-MX', // Mexico
    ES_NI = 'es-NI', // Nicaragua
    ES_PA = 'es-PA', // Panama
    ES_PY = 'es-PY', // Paraguay
    ES_PE = 'es-PE', // Peru
    ES_PR = 'es-PR', // Puerto Rico
    ES_DO = 'es-DO', // Dominican Republic
    ES_UY = 'es-UY', // Uruguay
    ES_VE = 'es-VE', // Venezuela
    // Estonian
    ET_EE = 'et-EE', // Estonia
    // Finnish
    FI_FI = 'fi-FI', // Finland
    // French
    FR_BE = 'fr-BE', // Belgium
    FR_CA = 'fr-CA', // Canada
    FR_FR = 'fr-FR', // France
    FR_LU = 'fr-LU', // Luxembourg
    FR_CH = 'fr-CH', // Switzerland
    // Greek
    EL_CY = 'el-CY', // Cyprus
    EL_GR = 'el-GR', // Greece
    // Hebrew
    IW_IL = 'iw-IL', // Israel
    // Hindi
    HI_US = 'hi-US', // India
    // Hungarian
    HU_HU = 'hu-HU', // Hungary
    // Icelandic
    IS_IS = 'is-IS', // Iceland
    // Indonesian
    IN_ID = 'in-ID', // Indonesia
    // Irish
    GA_IE = 'ga-IE', // Ireland
    // Italian
    IT_IT = 'it-IT', // Italy
    IT_CH = 'it-CH', // Switzerland
    // Korean
    KO_KR = 'ko-KR', // South Korea
    // Latvian
    LV_LV = 'lv-LV', // Latvia
    // Lithuanian
    LT_LT = 'lt-LT', // Lithuania
    // Macedonian
    MK_MK = 'mk-MK', // Macedonia
    // Malay
    MS_MY = 'ms-MY', // Malaysia
    // Maltese
    MT_MT = 'mt-MT', // Malta
    // Norwegian
    NO_NO = 'no-NO', // Norway
    // Polish
    PL_PL = 'pl-PL', // Poland
    // Portuguese
    PT_BR = 'pt-BR', // Brazil
    PT_PT = 'pt-PT', // Portugal
    // Russian
    RU_RU = 'ru-RU', // Russia
    // Romanian
    RO_RO = 'ro-RO', // Romania
    // Serbian
    SR_BA = 'sr-BA', // Bosnian and Herzegovina
    SR_CS = 'sr-CS', // Serbia
    // Slovak
    SK_SK = 'sk-SK', // Slovakia
    // Slovenian
    SL_SI = 'sl-SI', // Slovenia
    // Swedish
    SV_SE = 'sv-SE', // Sweden
    // Thai
    TH_US = 'th-US', // United States
    // Turkish
    TR_TR = 'tr-TR', // Turkey
    // Ukrainian
    UK_UA = 'uk-UA', // Ukraine
    // Vietnamese
    VI_VN = 'vi-VN', // Vietnam
    // Japanese
    JA_JP = 'ja-JP', // Japan
    // Fake, dev test only
    ZZ_ZZ = 'zz-ZZ', // Psuedo-loc
}

export enum SUPPORTED_I18N_LOCALES {
    EN = 'en',
    DE = 'de',
    ES = 'es',
    FR = 'fr',
    IT = 'it',
    JA = 'ja',
    PT = 'pt',
    RU = 'ru',
}

export const FALLBACK_I18N_LOCALE = SUPPORTED_I18N_LOCALES.EN;
