export enum FontFamilyType {
    Arial = 'Arial',
    Tahoma = 'Tahoma',
    TimesNewRoman = 'TimesNewRoman',
    Verdana = 'Verdana',
}

export enum FontSizeType {
    size8pt  = 'size8pt',
    size9pt  = 'size9pt',
    size10pt = 'size10pt',
    size12pt = 'size12pt',
    size14pt = 'size14pt',
    size16pt = 'size16pt',
    size18pt = 'size18pt',
    size20pt = 'size20pt',
    size24pt = 'size24pt',
    size28pt = 'size28pt',
    size32pt = 'size32pt',
    size36pt = 'size36pt',
}

export enum HorizontalAlignType {
    default = 'default',
    left = 'left',
    center = 'center',
    right = 'right',
}

export enum VerticalAlignType {
    default = 'default',
    top = 'top',
    middle = 'middle',
    bottom = 'bottom',
}

export enum ColorFormatType {
    'none' = 'none',
    '#000000' = '#000000',
    '#ffffff' = '#ffffff',
    'transparent' = 'transparent',
    '#FFEBEE' = '#FFEBEE',
    '#FFF3DF' = '#FFF3DF',
    '#FFFEE6' = '#FFFEE6',
    '#E7F5E9' = '#E7F5E9',
    '#E2F2FE' = '#E2F2FE',
    '#F4E4F5' = '#F4E4F5',
    '#F2E8DE' = '#F2E8DE',
    '#FFCCD2' = '#FFCCD2',
    '#FFE1AF' = '#FFE1AF',
    '#FEFF85' = '#FEFF85',  // #FEFF85
    '#C6E7C8' = '#C6E7C8',   // #C6E7C8
    '#B9DDFC' = '#B9DDFC',    // #B9DDFC
    '#EBC7EF' = '#EBC7EF',    // #EBC7EF
    '#EEDCCA' = '#EEDCCA',   // #EEDCCA
    '#E5E5E5' = '#E5E5E5',       // #E5E5E5
    '#F87E7D' = '#F87E7D',          // #F87E7D
    '#FFCD7A' = '#FFCD7A',       // #FFCD7A
    '#FEFF00' = '#FEFF00',       // #FEFF00
    '#7ED085' = '#7ED085',        // #7ED085
    '#5FB3F9' = '#5FB3F9',         // #5FB3F9
    '#D190DA' = '#D190DA',         // #D190DA
    '#D0AF8F' = '#D0AF8F',        // #D0AF8F
    '#BDBDBD' = '#BDBDBD',       // #BDBDBD
    '#EA352E' = '#EA352E',      // #EA352E
    '#FF8D00' = '#FF8D00',   // #FF8D00
    '#FFED00' = '#FFED00',   // #FFED00
    '#40B14B' = '#40B14B',    // #40B14B
    '#1061C3' = '#1061C3',     // #1061C3
    '#9210AD' = '#9210AD',   // #9210AD
    '#974C00' = '#974C00',    // #974C00
    '#757575' = '#757575',       // #757575
    '#991310' = '#991310',    // #991310
    '#EA5000' = '#EA5000', // #EA5000
    '#EBC700' = '#EBC700', // #EBC700
    '#237F2E' = '#237F2E',  // #237F2E
    '#0B347D' = '#0B347D',   // #0B347D
    '#61058B' = '#61058B', // #61058B
    '#592C00' = '#592C00',   // #592C00
}

export enum CurrencyFormatType {
    NONE = 'NONE',
    ARS  = 'ARS',
    AUD  = 'AUD',
    BRL  = 'BRL',
    CAD  = 'CAD',
    CLP  = 'CLP',
    EUR  = 'EUR',
    GBP  = 'GBP',
    ILS  = 'ILS',
    INR  = 'INR',
    JPY  = 'JPY',
    MXN  = 'MXN',
    RUB  = 'RUB',
    USD  = 'USD',
    ZAR  = 'ZAR',
    CHF  = 'CHF',
    CNY  = 'CNY',
    DKK  = 'DKK',
    HKD  = 'HKD',
    KRW  = 'KRW',
    NOK  = 'NOK',
    NZD  = 'NZD',
    SEK  = 'SEK',
    SGD  = 'SGD',
}

export enum DecimalCountType {
    zero = 'zero',
    one = 'one',
    two = 'two',
    three = 'three',
    four = 'four',
    five = 'five',
}

export enum NumberFormatType {
    none = 'none',
    number = 'number', // eslint-disable-line id-denylist
    currency = 'currency',
    percent = 'percent',
}

export enum AppliedFormatType {
    none = 'none',      // '0'
    on = 'on',       // '1'
}

export enum CurrencySymbolType {
    NONE = '',
    ARS = 'AR$ ',
    AUD = 'A$',
    BRL = 'R$',
    CAD = 'CA$',
    CLP = 'CLP$',
    EUR = '€',
    GBP = '£',
    ILS = '₪',
    INR = '₹',
    JPY = '¥',
    MXN = 'MX$',
    RUB = ' ₽',
    USD = '$',
    ZAR = 'R',
    CHF = 'CHF',
    CNY = 'CN¥',
    DKK = ' kr.',
    HKD = 'HK$',
    KRW = '₩',
    NOK = 'kr ',
    NZD = 'NZ$',
    SEK = ' kr',
    SGD = 'SG$',
}

export enum DateFormatType {
    LOCALE_BASED = 'LOCALE_BASED',
    MMMM_D_YYYY = 'MMMM_D_YYYY',
    MMM_D_YYYY = 'MMM_D_YYYY',
    D_MMM_YYYY = 'D_MMM_YYYY',
    YYYY_MM_DD_HYPHEN = 'YYYY_MM_DD_HYPHEN',
    YYYY_MM_DD_DOT = 'YYYY_MM_DD_DOT',
    DWWWW_MMMM_D_YYYY = 'DWWWW_MMMM_D_YYYY',
    DWWW_DD_MMM_YYYY = 'DWWW_DD_MMM_YYYY',
    DWWW_MM_DD_YYYY = 'DWWW_MM_DD_YYYY',
    MMMM_D = 'MMMM_D',
    D_MMMM = 'D_MMMM',
}

export enum FormatMapKeys {
    fontFamily = 'fontFamily',
    fontSize = 'fontSize',
    bold = 'bold',
    italic = 'italic',
    underline = 'underline',
    strikethrough = 'strikethrough',
    horizontalAlign = 'horizontalAlign',
    verticalAlign = 'verticalAlign',
    textColor = 'textColor',
    backgroundColor = 'backgroundColor',
    taskbarColor = 'taskbarColor',
    currencyFormat = 'currencyFormat',
    decimalCount = 'decimalCount',
    thousandSeparator = 'thousandSeparator',
    numberFormat = 'numberFormat',
    textWrap = 'textWrap',
    dateFormat = 'dateFormat',
}
