import { RowFieldValidationError } from '../../enums';
import { CellObjectValue, FormFieldInterface } from '../../interfaces';
import { FuncTransformForSmarUpsert, ISmarCell } from './TransformForSmarUpsert';
import { IRowFieldValidator } from './Validator';

export class RowField {
    private readonly _columnId: number;
    private readonly _value?: any;
    private readonly _objectValue: CellObjectValue;
    private readonly _isValid: boolean;
    private readonly _validationError?: RowFieldValidationError;
    private readonly _funcTransformForSmarUpsert: FuncTransformForSmarUpsert;

    public get isValid(): boolean {
        return this._isValid;
    }

    public get validationError(): RowFieldValidationError | undefined {
        return this._validationError;
    }

    public constructor(rowField: FormFieldInterface, validator: IRowFieldValidator, funcTransformForSmarUpsert: FuncTransformForSmarUpsert) {
        this._columnId = rowField.columnId;
        this._value = rowField.value;
        this._objectValue = rowField.objectValue!;
        this._funcTransformForSmarUpsert = funcTransformForSmarUpsert;

        const rowFieldValueIsValidResult = validator.validate(rowField);

        this._isValid = rowFieldValueIsValidResult.isValid;
        this._validationError = rowFieldValueIsValidResult.validationError;
    }

    /**
     * Used on the backend to transform the row field into the correct structure for calling an SMAR API
     */
    public transformForSmarUpsert(): ISmarCell {
        return this._funcTransformForSmarUpsert({
            columnId: this._columnId,
            value: this._value,
            objectValue: this._objectValue,
        });
    }
}
