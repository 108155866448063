import {
    ChildLoadState,
    ContainerTreeData,
    convertFolderAPIResponse,
    convertPersonalFolderAPIResponse,
    convertSearchAPIResponse,
    convertWorkspaceAPIResponse,
    convertWorkspacesAPIResponse,
    TreeItemType,
} from '@smartsheet/container-tree';
import axiosInstance from './Axios.instance';
import { SearchResults, WorkspacesMap } from './interfaces/SearchResults';

class WorkspaceClient {
    public static getInstance(): WorkspaceClient {
        const instanceExists = WorkspaceClient.instance !== undefined;
        if (!instanceExists) {
            WorkspaceClient.instance = new WorkspaceClient();
        }
        return WorkspaceClient.instance;
    }

    private static instance: WorkspaceClient;
    private static readonly workspaceUrl: string = '/workspace';

    private constructor() { }

    public async getFolders(): Promise<ContainerTreeData> {
        const response = await axiosInstance.get(`${WorkspaceClient.workspaceUrl}/folder`);
        // Set ID to dummy value to match dummy value in getInitialData
        response.data.id = 0;
        return convertPersonalFolderAPIResponse(response.data);
    }

    public async getFolder(id: string): Promise<ContainerTreeData> {
        const response = await axiosInstance.get(`${WorkspaceClient.workspaceUrl}/folder/${id}`);
        return convertFolderAPIResponse(response.data);
    }

    public async getWorkspaces(): Promise<ContainerTreeData[]> {
        const response = await axiosInstance.get(`${WorkspaceClient.workspaceUrl}`);
        return convertWorkspacesAPIResponse(response.data).data;
    }

    public async getWorkspace(id: string): Promise<ContainerTreeData> {
        const response = await axiosInstance.get(`${WorkspaceClient.workspaceUrl}/${id}`);
        return convertWorkspaceAPIResponse(response.data);
    }

    public async getSearch(query: string, useWorspaceService: boolean = false): Promise<ContainerTreeData[]> {
        if (useWorspaceService) {
            const response = await axiosInstance.get(`${WorkspaceClient.workspaceUrl}/search?query=${query}`);
            return convertSearchAPIResponse(response.data).data;
        } else {
            const response = await axiosInstance.get(`/search/sheetpicker?query=${query}`);
            return this.convertSearchServiceAPIResponse(response.data);
        }
    }

    public async getInitialData(): Promise<ContainerTreeData[]> {
        const folders: ContainerTreeData[] = [];
        folders.push({
            id: '0',
            name: 'Sheets',
            isPersonalWorkspace: true,
            isTemplate: false,
            containerType: TreeItemType.FOLDER,
            childData: { loadState: ChildLoadState.not },
        });

        return folders.concat(await this.getWorkspaces());
    }

    private convertSearchServiceAPIResponse(results: SearchResults): ContainerTreeData[] {
        const treeData: ContainerTreeData[] = [];

        // Add workspaces (sorted)
        results.maskedWorkspaces
            .sort((a, b) => {
                return a.personalWorkspace ? 0 : 1 || a.name.localeCompare(b.name);
            })
            .forEach((workspaceMap) => treeData.push(this.getWorkspaceForTree(workspaceMap)));

        // Add search results to workspaces in tree
        for (const workspace of treeData) {
            // Add containers for personal workspace
            results.searchResults
                .filter((r) => r.maskedParentContainerInfo.maskedWorkspaceId.toString() === workspace.id)
                .forEach((result) => {
                    if (workspace.childData?.loadState === ChildLoadState.loaded) {
                        // Remove highlighting from name
                        const name = result.highLightSearchText.split('{{searchHitBegin}}').join('').split('{{searchHitEnd}}').join('');

                        // Add to tree
                        workspace.childData.childrenData.push({
                            id: result.maskedDisplayObjectId.toString(),
                            name,
                            containerType: result.apiResultType === 'REPORT' ? TreeItemType.REPORT : TreeItemType.SHEET,
                            isTemplate: false,
                        });
                    }
                });
        }

        return treeData;
    }

    private getWorkspaceForTree(input: WorkspacesMap): ContainerTreeData {
        return {
            id: input.maskedWorkspaceId.toString(),
            name: input.personalWorkspace ? 'Sheets' : input.name,
            isPersonalWorkspace: input.personalWorkspace,
            isTemplate: false,
            containerType: input.personalWorkspace ? TreeItemType.FOLDER : TreeItemType.WORKSPACE,
            childData: {
                loadState: ChildLoadState.loaded,
                childrenData: [],
            },
        };
    }
}

export const workspaceClient = WorkspaceClient.getInstance();
