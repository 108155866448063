import { TimeZone } from '../../../../common/enums';
import { SmartsheetUser } from '../../../../common/interfaces';
import * as React from 'react';
import { dateTimeFormat } from '../../../../common/DateTimeFormat';
import { AutomationIds } from '../../../../common/enums/AutomationElements.enum';
import CommentMenu from '../../../../components/Buttons/CommentMenu/CommentMenu';
import { DisplayComment, UserProfileImageId } from './Comment.interface';
import CommentInput from './CommentInput';
import { UserIcon } from './UserIcon';

export interface SubCommentProps {
    viewId: string;
    rowId: number;
    reportSheetId?: number;
    comment: DisplayComment;
    userProfileImageIds: UserProfileImageId;
    currentUser: SmartsheetUser;
    userLocale?: string;
    userTimeZone?: TimeZone;

    // Event handlers
    onClickDeleteComment: (commentId: number) => void;
    onChange: (commentId: number, text: string) => void;
    onSave: (commentId: number, text: string) => void;
}

interface SubCommentState {
    isEditing: boolean;
}

export class Reply extends React.Component<SubCommentProps, SubCommentState> {
    public constructor(props: SubCommentProps) {
        super(props);
        this.state = { isEditing: false };
    }

    public render = () => {
        const createdAt = dateTimeFormat(this.props.userLocale, this.props.userTimeZone).format(new Date(this.props.comment.createdAt));
        const createdByEmail = this.props.comment.createdBy.email.toLowerCase();
        const currentUserEmail = this.props.currentUser.email.toLowerCase();
        const currentUserIsCommentOwner = createdByEmail === currentUserEmail;
        return (
            <div data-client-type={AutomationIds.COMMENT_COMMENTS_SUB_ITEM} className={'comment-wrap sub-comment'}>
                {this.props.comment.createdBy && (
                    <UserIcon userProfileImageIds={this.props.userProfileImageIds} user={this.props.comment.createdBy} />
                )}
                <div className="comment-content">
                    <div className="comment-header">
                        <div>
                            <span data-client-id={AutomationIds.COMMENT_COMMENTS_CREATOR} className="comment-creator">
                                <b>{this.props.comment && this.props.comment.createdBy ? this.props.comment.createdBy.name : ''}</b>
                            </span>
                            {!this.state.isEditing && (
                                <span data-client-id={AutomationIds.COMMENT_COMMENTS_DATE} className="comment-date">
                                    {' '}
                                    | {createdAt}
                                </span>
                            )}
                        </div>
                        <CommentMenu
                            for={this.props.comment.text}
                            onClickEditComment={currentUserIsCommentOwner ? this.handleClickEditComment : undefined}
                            onClickDeleteComment={currentUserIsCommentOwner ? this.handleClickDeleteComment : undefined}
                        />
                    </div>

                    {/* Show the comment if the user is not editing the text.*/}
                    {!this.state.isEditing && (
                        <div className="comment-text" data-client-id={AutomationIds.COMMENT_COMMENTS_TEXT}>
                            {this.props.comment ? this.props.comment.text : ''}
                        </div>
                    )}

                    {/* Show the comment input control if the user is editing the text.*/}
                    {this.state.isEditing && (
                        <CommentInput
                            className={'add-comment-wrap'}
                            currentUser={this.props.currentUser}
                            viewId={this.props.viewId}
                            rowId={this.props.rowId}
                            reportSheetId={this.props.reportSheetId}
                            value={this.props.comment.text}
                            isEditing={true}
                            onChange={(text) => this.props.onChange(this.props.comment.id, text)}
                            onSave={(text) => this.props.onSave(this.props.comment.id, text)}
                            onCancel={() => {
                                this.props.onChange(this.props.comment.id, '');
                                this.setState({ isEditing: false });
                            }}
                        />
                    )}
                </div>
            </div>
        );
    };

    private handleClickEditComment = () => this.setState({ isEditing: true });
    private handleClickDeleteComment = () => this.props.onClickDeleteComment && this.props.onClickDeleteComment(this.props.comment.id);
}
