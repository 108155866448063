import { AsyncStatus } from '../../../common/enums';
import * as dv from '../../../common/interfaces';
import { AxiosError } from 'axios';
import { AsyncResult } from '../../../store';

export const getRowSheetIdFromViewData = (viewData: AsyncResult<dv.ViewData, AxiosError>, selectedRowId?: string, intakeSheetId?: number | null) => {
    if (!selectedRowId) {
        // when this is null, submit to intake sheet (new row insert)
        return intakeSheetId;
    }
    if (viewData.status !== AsyncStatus.DONE && viewData.status !== AsyncStatus.PARTIAL) {
        return undefined;
    }
    const rowIdAsNumber = parseInt(selectedRowId, 10);
    const foundRow = viewData.data.rows.find((row) => row.id === rowIdAsNumber);
    if (!foundRow) {
        return intakeSheetId;
    }
    return foundRow.sheetId;
};
