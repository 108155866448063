import { IDomPosition } from '../../common/interfaces';
import 'react-select/dist/react-select.css';
import '../Select/Select.css';

import * as React from 'react';
import Select, { Creatable, Option } from 'react-select';
import { getDropdownPosition } from '../../common/utils/GetDropdownPosition';
import { PureBaseComponent } from '../Base';

import { WithDataClientProps } from '../hoc/WithDataClient';
import { MultiSelectItem } from './MultiSelect.interface';
import MultiSelectOption from './MultiSelectOption';
import MultiSelectValue from './MultiSelectValue';

const ControlId = {
    CREATE: 'msi-1',
    SELECT: 'msi-2',
};

/**
 * Use id to target the input field so that we can get positioning for the dropdown when FIXED positioning is needed to force
 * the dropdown to fully display outside of a scrolled container (as in app-core filter def)
 */
interface Props extends WithDataClientProps {
    disabled?: boolean;
    selectedItems: MultiSelectItem[];
    options?: MultiSelectItem[];
    onChange: (value: MultiSelectItem[]) => void;
    placeholder: string;
    prompt?: string;
    isValid: boolean;
    allowCustomOptions: boolean;
    closeMenuOnSelect?: boolean;
    optionComponentOverride?: any;
    id?: string;
    updateActiveConditionIndex?: (index: number) => void;
    index?: number;
}

interface State {
    input: string;
    dropdownPosition: IDomPosition;
}

/**
 * TODO: We are waiting for the Grid team to develop their v2.x version of the Mutli-Select control
 *  before we update ours.
 */
class MultiSelect extends PureBaseComponent<Props> {
    public state: State;
    public selector: any;

    public constructor(props: Props) {
        super(props);
        this.state = {
            input: '',
            dropdownPosition: { top: 0, left: 0 },
        };
    }

    public render(): React.ReactNode {
        const classNames: string = this.props.isValid ? 'multi-select' : 'multi-select error';
        const optionComponent: any = this.props.optionComponentOverride || MultiSelectOption;

        return (
            <div className={classNames} data-client-id={this.props.dataClientId} id={this.props.id}>
                {this.props.allowCustomOptions ? (
                    <Creatable
                        disabled={this.props.disabled}
                        tabIndex={this.props.disabled ? -1 : 0}
                        ref={(s: any) => (this.selector = s)}
                        multi={true}
                        clearable={false}
                        closeOnSelect={this.props.closeMenuOnSelect}
                        placeholder={this.props.placeholder}
                        options={this.props.options}
                        optionComponent={optionComponent}
                        onChange={this.props.onChange}
                        value={this.props.selectedItems}
                        valueComponent={MultiSelectValue}
                        promptTextCreator={(): string => `${this.state.input} - ${this.props.prompt || ''}`}
                        onInputChange={(input: string) => {
                            // noinspection TypeScriptValidateTypes
                            this.setState({
                                input,
                            });
                            return input;
                        }}
                        shouldKeyDownEventCreateNewOption={({ keyCode }) => {
                            return [9, 13, 32, 186, 188].includes(keyCode);
                        }}
                        onBlur={() => (this.selector ? this.onBlurCreatable(this.selector.inputValue) : undefined)}
                        isOptionUnique={({ option, options }) => this.isOptionUnique(option, options)}
                        inputProps={{ 'data-client-id': ControlId.CREATE }}
                        menuStyle={{
                            top: this.state.dropdownPosition.top,
                            left: this.state.dropdownPosition.left,
                        }}
                        onOpen={this.onOpen}
                    />
                ) : (
                    <Select
                        disabled={this.props.disabled}
                        tabIndex={this.props.disabled ? -1 : 0}
                        ref={(s: any) => (this.selector = s)}
                        multi={true}
                        clearable={false}
                        placeholder={this.props.placeholder}
                        options={this.props.options}
                        optionComponent={optionComponent}
                        onChange={this.props.onChange}
                        value={this.props.selectedItems}
                        valueComponent={MultiSelectValue}
                        onBlur={() => this.onBlurSelect(this.selector ? this.selector.input.props : undefined)}
                        ignoreCase={true}
                        inputProps={{ 'data-client-id': ControlId.SELECT }}
                    />
                )}
            </div>
        );
    }

    public componentDidMount(): void {
        this.updateDropdownPosition();
    }

    public componentDidUpdate(): void {
        this.updateDropdownPosition();
    }

    private onBlurCreatable = (item: string): void => {
        if (item && !this.isDuplicate(this.props.selectedItems, item)) {
            this.props.selectedItems.push({
                value: item,
                isValid: false,
                controlIdPrefix: `${this.props.dataClientId || ''}`,
            });
            this.props.onChange(this.props.selectedItems);
        }
    };

    private onBlurSelect = (props: any | undefined): void => {
        const item = props ? props.value : undefined;
        if (item && !this.isDuplicate(this.props.selectedItems, item)) {
            if (this.props.options) {
                // If user entry matches an item in the options list, update selected items
                const index = this.props.options.findIndex((option) => option.value === item);
                if (index !== -1) {
                    this.props.selectedItems.push(this.props.options[index]);
                    this.props.onChange(this.props.selectedItems);
                }
            }
        }
    };

    // Check for duplicate by comparing newest entry to the other selected items
    private isDuplicate = (selectedItems: MultiSelectItem[], newItem: string): boolean => {
        return selectedItems.some((item) => item.value.toLowerCase() === newItem.toLowerCase());
    };

    private isOptionUnique = (option: Option<MultiSelectItem>, options: Option<MultiSelectItem[]>): boolean => {
        return !options.find((opt: MultiSelectItem) => option.value!.toString().toLowerCase() === opt.value.toString().toLowerCase());
    };

    private updateDropdownPosition = (): void => {
        if (this.props.id != null) {
            const dropdownPosition = getDropdownPosition(this.props.id);
            if (this.state.dropdownPosition.left !== dropdownPosition.left || this.state.dropdownPosition.top !== dropdownPosition.top) {
                this.setState({ dropdownPosition });
            }
        }
    };

    private onOpen = (): void => {
        if (this.props.updateActiveConditionIndex && this.props.index != null) {
            this.props.updateActiveConditionIndex(this.props.index);
        }

        this.updateDropdownPosition();
    };
}

export default MultiSelect;
