import { useEffect, useRef } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Actions as ActionsApp } from '../App/Actions';
import { getViewIdFromRoute } from '../App/AppUtils';
import { activeSidePanelSelector, leftSidePanelOpenedSelector } from '../App/Selectors';
import { userHasLicenseAccessSelector } from '../Auth/Selectors';
import LeftPanel from './LeftPanel';

const LeftPanelContainer: React.FC = () => {
    const activeSidePanel = useSelector(activeSidePanelSelector);
    const isUserLicensed = useSelector(userHasLicenseAccessSelector);
    const leftSidePanelOpened = useSelector(leftSidePanelOpenedSelector);
    const location = useLocation();
    const activeViewId = getViewIdFromRoute(location);
    const prevActiveViewIdRef = useRef<string>();

    const dispatch = useDispatch();

    useEffect(() => {
        if (activeViewId && prevActiveViewIdRef.current !== activeViewId) {
            if (leftSidePanelOpened) {
                // Close the left panel if a view is being rendered
                dispatch(ActionsApp.toggleLeftSidePanel());
            }
        }
        prevActiveViewIdRef.current = activeViewId;
    }, [activeViewId, dispatch, leftSidePanelOpened]);

    return (
        <LeftPanel
            activeSidePanel={activeSidePanel}
            activeViewId={activeViewId}
            leftSidePanelOpened={leftSidePanelOpened}
            userIsLicensed={isUserLicensed}
        />
    );
};

export default LeftPanelContainer;
