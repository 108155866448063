import { RowFieldValidationError } from '../../../enums';
import { Contact, FormFieldInterface } from '../../../interfaces';
import { isPrimitiveType } from '../../../utils/IsPrimitiveType';
import { IRowFieldValidator } from './IRowFieldValidator';

export interface RowFieldValueIsValidResult {
    isValid: boolean;
    validationError?: RowFieldValidationError;
}

export class RowFieldBaseValidator implements IRowFieldValidator {
    protected readonly originalRowField: FormFieldInterface;
    public constructor(originalRowField: FormFieldInterface) {
        this.originalRowField = originalRowField;
    }
    public validate(rowField: FormFieldInterface): RowFieldValueIsValidResult {
        const rowFieldValueIsValidResult = this.validateMandatoryProperties(rowField);
        if (rowFieldValueIsValidResult) {
            return rowFieldValueIsValidResult;
        }

        return this.validatePrimitiveValue(rowField);
    }

    protected validateMandatoryProperties(rowField: FormFieldInterface): void | RowFieldValueIsValidResult {
        if (rowField.readOnly || rowField.hidden) {
            return {
                isValid: true,
            };
        }

        // This check ensures a value of 'undefined' is not passed from the DV API to the SMAR API to update a row field. A value of 'undefined' is
        // not supported for representing an empty value for a row field, if 'undefined' was somehow passed from
        // the DV API to SMAR API, an exception would be thrown. NOTE: 'null' should be used to represent an empty value
        if (rowField.value === undefined && rowField.objectValue === undefined) {
            return {
                isValid: false,
                validationError: RowFieldValidationError.MISSING_REQUIRED_VALUE,
            };
        }

        return;
    }

    protected validatePrimitiveValue(rowField: FormFieldInterface): RowFieldValueIsValidResult {
        if (rowField.required) {
            if (this.isNotPrimitiveTypeOrEmpty(rowField.value)) {
                return {
                    isValid: false,
                    validationError: RowFieldValidationError.MISSING_REQUIRED_VALUE,
                };
            }
        }

        return {
            isValid: rowField.value === null || isPrimitiveType(rowField.value),
        };
    }

    protected isPicklistOption(value: any, options?: Array<string | number>): boolean {
        if (value == null || options == null || !Array.isArray(options)) {
            return false;
        }

        return options!.some(option => {
            const optionForComparison = typeof option === 'string' ? option.toLowerCase() : option;
            const valueForComparison = typeof value === 'string' ? value.toLowerCase() : value;
            return optionForComparison === valueForComparison;
        });
    }

    protected isNotPrimitiveTypeOrEmpty(value: any): boolean {
        if (!isPrimitiveType(value)) {
            return true;
        }

        return this.isEmptyString(value);
    }

    protected isEmptyString(value: any): boolean {
        return typeof value === 'string' && value.trim() === '';
    }

    protected isContactOption(contactOptions: Contact[] | undefined, value: any | undefined): boolean {
        if (value == null || typeof value !== 'string' || contactOptions == null || !Array.isArray(contactOptions)) {
            return false;
        }

        return contactOptions.some(contactOption => {
            //  if there is no value, check the name
            if (contactOption.email) {
                return contactOption.email.toString().toLowerCase() === value.toLowerCase();
            } else {
                return contactOption.name!.toLowerCase() === value.toLowerCase();
            }
        });
    }
}
