import classNames from 'classnames';
import * as React from 'react';
import './AdminPanel.css';
import { useLanguageElements } from '../../language-elements/withLanguageElementsHOC';

interface Props {
    isDirty: boolean;
    isValid: boolean;
    onCancel: () => void;
    onSave: () => void;
}

const ControlId = {
    SAVE: 'apf-1',
    CANCEL: 'apf-2',
};

const AdminFooter: React.SFC<Props> = (props) => {
    const languageElements = useLanguageElements();
    return (
        <div className={classNames('state-panel panel', { open: props.isDirty || !props.isValid })}>
            <button
                id="adminFooterSaveBtn"
                onClick={() => props.onSave()}
                className="btn state-button btn-primary"
                disabled={!props.isValid}
                data-client-id={ControlId.SAVE}
                tabIndex={props.isDirty ? 1 : -1}
            >
                {languageElements.ADMIN_PANEL_FOOTER_PRIMARY_BUTTON_TEXT}
            </button>
            <button
                id="adminFooterCancelBtn"
                onClick={() => props.onCancel()}
                className="btn state-button btn-secondary"
                data-client-id={ControlId.CANCEL}
                tabIndex={props.isDirty ? 0 : -1}
            >
                {languageElements.ADMIN_PANEL_FOOTER_SECONDARY_BUTTON_TEXT}
            </button>
        </div>
    );
};

export default AdminFooter;
