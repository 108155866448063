import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { colors, sizes } from '@smartsheet/lodestar-core';
import { AsyncStatus } from '../../../../common/enums';
import * as FocusTrap from 'focus-trap-react';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { AutomationIds, AutomationTypes } from '../../../../common/enums/AutomationElements.enum';
import { CheckThinIcon } from '@smartsheet/lodestar-icons';
import Spinner, { Color, Size } from '../../../../components/Spinner';
import { useLanguageElements } from '../../../../language-elements/withLanguageElementsHOC';

interface Props {
    rowId?: string;
    saveStatus?: AsyncStatus;
    isNewSubmission: boolean;
}

export const SavingData = ({ rowId, saveStatus, isNewSubmission }: Props) => {
    const languageElements = useLanguageElements();
    const [rowChanged, setRowChanged] = useState(true);
    const [isAnimationComplete, setIsAnimationComplete] = useState(true);

    useEffect(() => {
        // The rowChanged state prop is set to true when the row ID changes. However, due to a new submission causing the rowID to change after the
        // successful insert request, we take the isNewSubmission into consideration
        setRowChanged(!isNewSubmission);
    }, [rowId, isNewSubmission]);

    // Reset animation
    useEffect(() => {
        const isCurrentUpsertComplete = isUpsertComplete(isNewSubmission, saveStatus);

        if (rowChanged) {
            // If user navigates to a row that has just completed a successful save request (AsyncStatus.IN_PROGRESS), then ensure the post save
            // affordance does not show in this case.
            setIsAnimationComplete(isCurrentUpsertComplete);

            setRowChanged(false);
        } else if (isUpsertInProgress(isNewSubmission, saveStatus)) {
            // Reset animation when saveStatus is reset to being in progress, this is to allow subsequent submits to start animation again
            setIsAnimationComplete(false);
        }
    }, [rowChanged, saveStatus, isNewSubmission]);

    const handleAnimationEnd = () => setIsAnimationComplete(true);
    const isInProgress = isUpsertInProgress(isNewSubmission, saveStatus);
    const isCompleteAndAnimationNotStarted = isUpsertComplete(isNewSubmission, saveStatus) && !isAnimationComplete;

    if (!isInProgress && !isCompleteAndAnimationNotStarted) {
        return null;
    }

    /**
     * To use FocusTrap, we need to provide at least 1 focusable element, so we have created a hidden
     * input element to take the focus, such that users cannot tab through other elements while this
     * component is rendered.
     */
    return (
        <Container data-client-type={AutomationTypes.OVERLAY}>
            <FocusTrap focusTrapOptions={{ clickOutsideDeactivates: true, initialFocus: false }}>
                <CenteredDiv
                    isInProgress={isInProgress}
                    onAnimationEnd={handleAnimationEnd}
                    data-clientid={AutomationIds.SAVING_DATA_MODAL}
                    data-testid={AutomationIds.SAVING_DATA_MODAL}
                >
                    <HiddenInput type="text" />
                    {isInProgress && (
                        <>
                            <TextAffordance>{languageElements.SAVING_DATA_MODAL_IN_PROGRESS}</TextAffordance>
                            <SpinnerFlex color={Color.BLUE} size={Size.SMALL} />
                        </>
                    )}

                    {!isInProgress && (
                        <>
                            <TextAffordance>{languageElements.SAVING_DATA_MODAL_DONE}</TextAffordance>
                            <CheckThinIcon color={colors.visGreenDark10} size="large" />
                        </>
                    )}
                </CenteredDiv>
            </FocusTrap>
        </Container>
    );
};

const isUpsertComplete = (isNewSubmission: boolean, saveStatus?: AsyncStatus) => {
    return (!isNewSubmission && saveStatus === AsyncStatus.IN_PROGRESS) || (isNewSubmission && saveStatus === AsyncStatus.DONE);
};

const isUpsertInProgress = (isNewSubmission: boolean, saveStatus?: AsyncStatus) => {
    return saveStatus === AsyncStatus.NOT_STARTED || (isNewSubmission && saveStatus === AsyncStatus.IN_PROGRESS);
};

const Container = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: not-allowed;
    z-index: 20;
    user-select: none;
    background: #ffffff99;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const postSaveStyles = (props: { isInProgress: boolean }) => {
    if (props.isInProgress) {
        return;
    }

    return css`
        animation-delay: 1000ms;
        animation-duration: 250ms;
        animation-name: slide-out;

        @keyframes slide-out {
            from {
                opacity: 1;
            }

            to {
                opacity: 0;
            }
        }
    `;
};

const CenteredDiv = styled.div`
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 72px;
    min-width: 260px;
    background-color: ${colors.neutralLight40};
    border: 1px solid ${colors.neutralDark10};
    border-radius: 2px;
    flex-direction: row;
    padding: ${sizes.xSmall}px;
    margin: ${sizes.large}px;

    ${postSaveStyles};
`;

const SpinnerFlex = styled(Spinner)`
    position: relative;
    width: 50px;
    height: 50px;
`;

const TextAffordance = styled.span`
    font-size: 20px;
    font-weight: 400;
    padding-right: 9px;
    text-align: center;
`;

const HiddenInput = styled.input`
    opacity: 0;
    width: 0;
    height: 0;
    padding: 0;
`;
