export enum AttachmentTypeEnum {
    BOX_COM = 'BOX_COM',
    DROPBOX = 'DROPBOX',
    EVERNOTE = 'EVERNOTE',
    FILE = 'FILE',
    LINK = 'LINK',
    ONEDRIVE = 'ONEDRIVE',
}

// Currently no existing way to extend an enum so using work-around here
export enum AttachmentSubType {
    FOLDER = 'FOLDER',
    DOCUMENT = 'DOCUMENT',
    DRAWING = 'DRAWING',
    PDF = 'PDF',
    PRESENTATION = 'PRESENTATION',
    SPREADSHEET  = 'SPREADSHEET',
}
