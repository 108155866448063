import styled from '@emotion/styled';
import { colors, sizes } from '@smartsheet/lodestar-core';
import { AsyncStatus } from '../../common/enums';
import { AdminControlsData } from '../../common/interfaces';
import * as React from 'react';
import { AutoSizer, SortDirection, SortDirectionType, Table } from 'react-virtualized';
import { AutomationIds } from '../../common/enums/AutomationElements.enum';
import { RowGetter } from '../../components/Grid/Grid.interface';
import Spinner, { Color, Size } from '../../components/Spinner';
import { WithDataClientProps } from '../../components/hoc/WithDataClient';
import { useLanguageElements } from '../../language-elements/withLanguageElementsHOC';
import AdminControlsRowElement from './AdminControlsRowElement';
import AdminControlsTableColumns from './AdminControlsTableColumns';
import { compareFunctions } from './AdminControlsUtils';
import { ColumnTotalWidths } from './constants';
import { AdminControlsRowData } from './types';

interface Props extends WithDataClientProps {
    views: Map<string, AdminControlsData>;
    selectedViews: Set<string>;
    viewsLoadingStatus: AsyncStatus;
    onSelectView: (viewId: string) => void;
    onSingleDelete: (id: string) => void;
    onSingleTransferOwnership: (id: string) => void;
    onViewsUpdate: (views: Map<string, AdminControlsData>) => void;
}

const AdminControlsTable: React.FC<Props> = ({
    views,
    viewsLoadingStatus,
    selectedViews,
    onSelectView,
    onSingleDelete,
    onViewsUpdate,
    onSingleTransferOwnership,
}) => {
    const [tableSortBy, setTableSortBy] = React.useState<string | undefined>(undefined);
    const [tableSortDirection, setTableSortDirection] = React.useState<SortDirectionType | undefined>(undefined);

    const languageElements = useLanguageElements();

    const rowData: Map<number, AdminControlsRowData> = React.useMemo(() => {
        /**
         * Adds an index prop and empty columnData object to the array so that the
         * shape conforms to shape needed for react-virtualized table use
         */
        const newData = new Map<number, AdminControlsRowData>();
        let index = 0;

        views.forEach((transfer) => {
            newData.set(index, { ...transfer, index });
            index++;
        });

        return newData;
    }, [views]);

    const getRowData = (index: number): AdminControlsRowData => {
        return rowData.get(index % rowData.size)!;
    };

    const sortViews = ({ sortBy, sortDirection }: { sortBy: string; sortDirection: SortDirectionType }) => {
        const isDescendingOrder = sortDirection === SortDirection.DESC;
        const sortedViews = new Map([...views.entries()].sort(compareFunctions[sortBy](isDescendingOrder)));

        if (sortBy !== tableSortBy) {
            setTableSortBy(sortBy);
        }

        if (sortDirection !== tableSortDirection) {
            setTableSortDirection(sortDirection);
        }

        onViewsUpdate(sortedViews);
    };

    // Calculation for height here is to account for space headers
    const autoSizerStyle = {
        // margin-top = 20px, padding-bottom = 15px, 102px = headline (for horizontal scrollbar)
        height: `calc(100vh - var(--headerHeight) - 24px - 15px - 102px)`,
        width: ColumnTotalWidths,
    };

    return (
        <AdminControlsContainer data-testid={AutomationIds.ADMIN_CONTROLS_TRANSFER_TABLE}>
            <AutoSizer className="autosizer" disableWidth={false} style={autoSizerStyle}>
                {({ height, width }: { height: number; width: number }) => (
                    <Table
                        disableHeader={false}
                        headerClassName="column-title"
                        headerHeight={50}
                        height={height}
                        overscanRowCount={10}
                        gridClassName="transfer-listing"
                        rowClassName="list-header"
                        rowHeight={50}
                        rowGetter={(getter: RowGetter) => getRowData(getter.index)}
                        rowCount={rowData.size}
                        rowRenderer={AdminControlsRowElement}
                        scrollToIndex={1}
                        sort={sortViews}
                        sortBy={tableSortBy}
                        sortDirection={tableSortDirection}
                        width={width}
                        noRowsRenderer={() => {
                            if (viewsLoadingStatus !== AsyncStatus.DONE) {
                                return (
                                    <SpinnerWrapper>
                                        <Spinner color={Color.BLUE} size={Size.SMALL} />
                                    </SpinnerWrapper>
                                );
                            }

                            return <DataCellNoRows className="data-cell">{languageElements.ADMIN_CONTROLS_LIST_ORG_VIEWS_EMPTY}</DataCellNoRows>;
                        }}
                    >
                        {AdminControlsTableColumns({
                            selectedViews,
                            languageElements,
                            onSelectView,
                            onSingleDelete,
                            onSingleTransferOwnership,
                        })}
                    </Table>
                )}
            </AutoSizer>
        </AdminControlsContainer>
    );
};

const AdminControlsContainer = styled.div`
    & {
        .data-cell {
            display: flex;
            align-items: center;
            margin-left: 0;
            cursor: default;
            height: 49px;
        }
        .list-header {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            background-color: ${colors.neutralLight40};
            font-size: 13px;
            color: ${colors.neutralDark40};
            border-bottom: 1px solid ${colors.neutral};
            box-sizing: border-box;
            font-weight: bold;
            height: 28px;
            padding: 0;
            margin: 0;
        }
        .column-title {
            margin-left: 0;
            text-transform: none;
        }
        .list-header .column-title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            box-sizing: border-box;
            padding-left: 10px;
            padding-right: 10px;
            position: relative;
        }
        .list-header .column-title:nth-of-type(1) {
            margin-left: 0;
        }
    }
`;

const SpinnerWrapper = styled.div`
    padding: ${sizes.xxSmall}px;
`;

const DataCellNoRows = styled.div`
    font-size: 13px;
`;

export default AdminControlsTable;
