export const MAX_TITLE_CHARS = 50;
export const MAX_CUSTOM_LABEL_CHARS = 300;
export const MAX_DESCRIPTION_CHARS = 5000;
export const MAX_EMAIL_CHARS = 254;
export const MAX_MULTI_CONTACTS = 20; // we set this to avoid the 4000 character limit per cell imposed by app-core
export const MAX_FILTER_VALUE = 100;
export const MAX_FILTER_NAME = 50;
export const VIEW_NAME_MAX_LENGTH = 50;
export const MAX_ALT_TEXT_CHARS = 50;
export const MAX_DETAILS_PANEL_DESCRIPTION_CHARS = 500;
export const MAX_SOURCEAPP_CHARS = 50;
