import * as React from 'react';
import { Link } from 'react-router-dom';
import './SettingsLinkButton.css';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';

export interface SettingsButtonProps {
    route: string;
    controlId: string;
    text?: string;
    className?: string;
    image?: string;
    imageAltText?: string;
}

export const SettingsLinkButton = (props: SettingsButtonProps) => {
    const languageElements = useLanguageElements();
    const {
        text = languageElements.VIEW_TABLE_EMPTYSTATE_SETTINGS,
        className = 'settings-link-button',
        imageAltText = languageElements.VIEW_TABLE_EMPTYSTATE_SETTINGS_ALT_TEXT,
    } = props;

    return (
        <div className={props.image ? 'settings-link btn btn-primary' : ''}>
            {props.image && <img src={props.image} alt={imageAltText} />}
            <Link to={props.route} className={className} data-client-id={props.controlId}>
                {text}
            </Link>
        </div>
    );
};

export default SettingsLinkButton;
