import { MAX_COLUMN_WIDTH, MIN_COLUMN_WIDTH } from '../constants';

export interface ColumnWidth {
    columnId: string;
    width: number;
}

export class ColumnWidthRepository {
    public applyMinMaxToWidth = (width: number) => Math.max(MIN_COLUMN_WIDTH, Math.min(MAX_COLUMN_WIDTH, width));

    public getColumnWidths(viewId: string): ColumnWidth[] {
        const key = this.getLocalStorageKey(viewId);
        return localStorage.getItem(key) != null ? JSON.parse(localStorage.getItem(key)!) : [];
    }

    public saveColumnWidths(viewId: string, columnId: string, width: number): void {
        width = this.applyMinMaxToWidth(width);

        const columnWidths = this.getColumnWidths(viewId);
        const columnIndex = columnWidths.findIndex((column) => column.columnId === columnId);

        // If columnId is not yet defined in local storage, add it
        if (columnIndex === -1) {
            columnWidths.push({
                columnId,
                width,
            });

            // Otherwise just update the width for it
        } else {
            columnWidths[columnIndex].width = width;
        }

        if (columnWidths.length > 0) {
            localStorage.setItem(this.getLocalStorageKey(viewId), JSON.stringify(columnWidths));
        }
    }

    private getLocalStorageKey(viewId: string): string {
        return `viewColumnWidths_${viewId}`;
    }
}
