import styled from '@emotion/styled';
import { sizes, TypeRampV2 } from '@smartsheet/lodestar-core';
import { useEffect, useRef } from 'react';
import * as React from 'react';
import { FallbackProps, useErrorBoundary } from 'react-error-boundary';
import { AutomationTypes } from '../../common/enums/AutomationElements.enum';
import { useLanguageElements } from '../../language-elements/withLanguageElementsHOC';
import Button from '../Buttons/Button/Button';
import { useLocation } from 'react-router-dom';

const MainFallback: React.FC<FallbackProps> = ({ resetErrorBoundary }: FallbackProps) => {
    const languageElements = useLanguageElements();
    const { resetBoundary } = useErrorBoundary();
    const location = useLocation();
    const locationPathnameRef = useRef(location.pathname);

    useEffect(() => {
        if (locationPathnameRef.current !== location.pathname) {
            resetBoundary();
        }
    }, [location.pathname, resetBoundary]);

    return (
        <MainFallbackContainer data-client-type={AutomationTypes.MAIN_FALLBACK}>
            <TypeRampV2 system="lodestar" variant="headlineMedium">
                {languageElements.ERRORSTATE_CALLBACK_ERROR_TITLE}
            </TypeRampV2>
            <TypeRampV2 system="lodestar" variant="bodyText">
                {languageElements.GENERIC_FATAL_ERROR}
            </TypeRampV2>
            <Button onClick={resetErrorBoundary} className="btn btn-primary" text={languageElements.BUTTON_TEXT_REFRESH} />
        </MainFallbackContainer>
    );
};

export default MainFallback;

const MainFallbackContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: ${sizes.small}px;
    padding: 0 ${sizes.small}px;
`;
