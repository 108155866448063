import { MAIN_HEADER_HEIGHT } from '../../../common/constants';
import { HomeView } from '../../../common/interfaces';
import * as React from 'react';
import { getUrlBarHeight } from '../../../common/utils';
import { dynamicSort } from '../../../common/utils/DynamicSort';
import EmptyStateLeftRail from '../EmptyStateLeftRail/EmptyStateLeftRail';
import { FavoritesListItem, InteractionStatus } from './FavoritesListItem';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';

export interface FavoritesListProps {
    activeViewId?: string;
    favoriteViews: HomeView[] | undefined;
}

export const FavoritesList: React.SFC<FavoritesListProps> = (props) => {
    const { activeViewId, favoriteViews } = props;
    const languageElements = useLanguageElements();

    if (!favoriteViews || favoriteViews.length < 1) {
        return <EmptyStateLeftRail message={languageElements.NO_FAVORITES_TO_DISPLAY} classNames="no-favorites" />;
    }

    favoriteViews!.sort(dynamicSort('name'));

    const favoriteViewsList = favoriteViews.map((item) => {
        return (
            <FavoritesListItem
                key={item.id}
                interactionStatus={activeViewId === item.id ? InteractionStatus.ACTIVE : InteractionStatus.NORMAL}
                item={item}
            />
        );
    });

    // In-line styling used for ul max-height to solve mobile display issues related to vh
    return (
        <div className="left-nav-list">
            <ul className="favorite-items" style={{ maxHeight: `calc(100vh - ${MAIN_HEADER_HEIGHT + getUrlBarHeight()}px)` }}>
                {favoriteViewsList}
            </ul>
        </div>
    );
};

export default FavoritesList;
