/* eslint @typescript-eslint/no-unnecessary-type-assertion:"off" */
import { FilterConditionOperators } from '../../../../common/enums';
import { IFilter } from '../../../../common/interfaces';
import * as dv from '../../../../common/interfaces';
import { RowData } from '../../../../components/Grid/Grid.interface';
import { applyFilterCondition, FilterConditionForApplyCondition } from './ApplyFilterCondition';
import { transformCellForApplyCondition } from './TransformCellForApplyCondition';

/**
 * Applies the filterConditions to the rows and returns the rows that have passed all filterConditions
 *
 * cellData should be a map of the cells with the key as a string = rowId:columnId.
 * columns should be a map of columns with the key as the columnId
 */
export const applyFilter = (
    rows: RowData[],
    cellDataMap: Map<string, dv.Cell>,
    columnMap: Map<number, dv.Column>,
    filter: dv.IFilter | undefined
): RowData[] => {
    if (!Array.isArray(rows) || !rows.length) {
        return [];
    }

    if (!filter || !filter.conditions || !filter.conditions.length) {
        return rows;
    }

    const conditionsForApplyCondition: FilterConditionForApplyCondition[] = transformFilterConditions(filter);
    const filteredRows: RowData[] = [];

    rows.forEach((row: RowData) => {
        if (applyFilterToRow(row.id, cellDataMap, columnMap, conditionsForApplyCondition)) {
            filteredRows.push(row);
        }
    });

    return filteredRows;
};

/**
 * Return an array of transformed filter conditions to include valueSet prop of lower case strings for operators: IS_ONE_OF, IS_NOT_ONE_OF
 * HAS_ANY_OF/HAS_NONE_OF... and HAS_ALL_OF/DOES_NOT_HAVE_ALL_OF
 */
const transformFilterConditions = (filter: dv.IFilter): FilterConditionForApplyCondition[] => {
    const clonedFilter = Object.assign({}, filter) as IFilter;
    return clonedFilter.conditions.map((condition: dv.IFilterCondition): FilterConditionForApplyCondition => {
        const conditionForApplyCondition = Object.assign({ __proto__: (condition as any).__proto__ }, condition) as FilterConditionForApplyCondition;
        switch (condition.operator) {
            case FilterConditionOperators.IS_ONE_OF:
            case FilterConditionOperators.IS_NOT_ONE_OF:
            case FilterConditionOperators.HAS_ANY_OF:
            case FilterConditionOperators.HAS_NONE_OF:
            case FilterConditionOperators.HAS_ALL_OF:
            case FilterConditionOperators.DOES_NOT_HAVE_ALL_OF:
                if (Array.isArray(conditionForApplyCondition.value)) {
                    conditionForApplyCondition.valueSet = new Set(
                        conditionForApplyCondition.value.map((value: any) => {
                            return value.toString().toLowerCase();
                        })
                    );
                }
                break;
            default:
        }
        return conditionForApplyCondition;
    });
};

/**
 * Apply filter conditions to each row of the grid
 */
const applyFilterToRow = (
    rowId: string,
    gridCellMap: Map<string, dv.Cell>,
    columnMap: Map<number, dv.Column>,
    filterConditions: FilterConditionForApplyCondition[]
): boolean => {
    return filterConditions.every((condition: FilterConditionForApplyCondition) => {
        const cell = gridCellMap.get(`${rowId}:${condition.columnId}`);
        if (!cell) {
            return false;
        }

        const column = columnMap.get(cell.virtualColumnId || cell.columnId);
        const cellApplyConditionInput = transformCellForApplyCondition(cell);
        return applyFilterCondition(condition, cellApplyConditionInput, column!.type);
    });
};
