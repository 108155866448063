import { SelectV2Option } from '../interfaces';

/**
 * Remove duplicate SelectV2 options by value
 */
export const removeDuplicateOptions = <T extends SelectV2Option>(options: T[]) => {
    return options.reduce((acc: T[], curr: T) => {
        const isDup = acc.find((option) => option.value === curr.value);
        return isDup ? acc : [...acc, curr];
    }, []);
};
