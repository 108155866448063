import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Pseudo from 'i18next-pseudo';
import * as de from './locales/de.json';
import * as en from './locales/en.json';
import * as es from './locales/es.json';
import * as fr from './locales/fr.json';
import * as it from './locales/it.json';
import * as ja from './locales/ja.json';
import * as pt from './locales/pt.json';
import * as ru from './locales/ru.json';

import { FALLBACK_I18N_LOCALE, SUPPORTED_I18N_LOCALES } from './common/enums';

void i18n
    .use(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        new Pseudo({
            enabled: false,
        })
    )
    .use(initReactI18next)
    .init({
        debug: false,
        supportedLngs: Object.values(SUPPORTED_I18N_LOCALES),
        interpolation: {
            escapeValue: false,
        },
        keySeparator: false,
        nsSeparator: false,

        resources: {
            de: { translation: de },
            en: { translation: en },
            es: { translation: es },
            fr: { translation: fr },
            it: { translation: it },
            ja: { translation: ja },
            pt: { translation: pt },
            ru: { translation: ru },
        },
        postProcess: ['pseudo'],
        fallbackLng: FALLBACK_I18N_LOCALE,
    });

export default i18n;
