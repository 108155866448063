import * as React from 'react';
import { MultiSelectItem } from '../../../components/MultiSelect/MultiSelect.interface';
import Pill from '../../../components/Pill';
import { AdminLanguage } from '../interfaces/AdminLanguage.interface';
// TODO: Image stuff left in because we plan to reinstate the images/colored circles
// import defaultIcon from '../../../assets/images/toolbar/accountMenuIconDefault/default-profile-pic.png';

interface Props {
    dataClientId: string;
    owner: MultiSelectItem;
    language: AdminLanguage;
}

const permissionsOwner: React.SFC<Props> = props => {
    // TODO: Image stuff left in because we plan to reinstate the images/colored circles
    // const profileImgUrl = this.props.owner.profileImgUrl || defaultIcon;
    return (
        <div className="owner">
            <div data-client-id={props.dataClientId}>
                <Pill
                    value={props.owner.label!}
                    label={props.owner.label!}
                    isValid={true}
                />
            </div>
        </div>
    );
};
export default permissionsOwner;
