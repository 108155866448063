import * as ReactDOM from 'react-dom';
import { BaseComponent } from '../Base';

export const TOOLTIP_ROOT_ID = 'tooltip-root';

export class TooltipPortal extends BaseComponent<any> {
    private tooltipRoot: HTMLElement | null;
    private tooltip: HTMLDivElement;

    public constructor(props: any) {
        super(props);
        this.tooltip = document.createElement('div');
    }

    public componentDidMount(): void {
        this.tooltipRoot = document.getElementById(TOOLTIP_ROOT_ID);
        if (this.tooltipRoot) {
            this.tooltipRoot.appendChild(this.tooltip);
        }
    }

    public componentWillUnmount(): void {
        if (this.tooltipRoot) {
            this.tooltipRoot.removeChild(this.tooltip);
        }
    }

    public render = () => ReactDOM.createPortal(this.props.children, this.tooltip);
}
