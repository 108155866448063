import { User } from './types';
import { ActionsUnion } from '../../store';
import { createAction } from '../../store/Utils/CreateAction';

export enum ActionTypes {
    LOG_IN = 'LOG_IN',
}

export const Actions = {
    logIn: (user: User) => createAction(ActionTypes.LOG_IN, user),
};

export type Actions = ActionsUnion<typeof Actions>;
