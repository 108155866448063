// Note that all contactObjectValues that come from app-core will contain an email. However, a cell with a contact column may only have a name and
// no email associated. In that case, app-core will send the objectValue as a string.
import { ObjectType } from '../enums';
import { ContactObjectValue } from '../interfaces';
import { isGenericObjectValue } from './IsGenericObjectValue';

export const isContactObjectValue = (objectValue: any): objectValue is ContactObjectValue => {
    return isGenericObjectValue(objectValue)
        && objectValue.objectType === ObjectType.CONTACT;
};
