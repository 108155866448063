import { AxiosPromise } from 'axios';
import axiosInstance from './Axios.instance';

class AuthClient {
    public static getInstance(): AuthClient {
        const instanceExists = AuthClient.instance !== undefined;
        if (!instanceExists) {
            AuthClient.instance = new AuthClient();
        }
        return AuthClient.instance;
    }

    private static instance: AuthClient;
    private static readonly url: string = '/auth';

    private constructor() {}

    public logOut(): AxiosPromise<void> {
        return axiosInstance.post(`${AuthClient.url}/logout`, {});
    }
}

export const authClient = AuthClient.getInstance();
