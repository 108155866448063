import * as React from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { createStructuredSelector } from 'reselect';
import { BaseProps, PureBaseComponent } from '../../../components/Base';
import { ActionByType } from '../../../store/types';
import { Actions, ActionTypes } from '../../App/Actions';
import { leftSidePanelOpenedSelector } from '../../App/Selectors';
import { CloseLeftPanelButton } from './CloseLeftPanelButton';
import './PanelFrame.css';

interface OwnProps extends BaseProps {
    titleLabel: string;
    children: React.ReactNode;
    className?: string;
}

interface DispatchProps {
    toggleLeftSidePanel: () => ActionByType<Actions, ActionTypes.TOGGLE_LEFT_SIDE_PANEL>;
}

interface StateProps {
    leftSidePanelOpened: boolean;
}

export type PanelFrameProps = DispatchProps & OwnProps & StateProps;

export class PanelFrame extends PureBaseComponent<PanelFrameProps> {
    public render(): React.ReactNode {
        return (
            <div className="main side-panel-container">
                <CSSTransition
                    in={this.props.leftSidePanelOpened}
                    timeout={150}
                    classNames={{
                        enter: 'animateSidePanelEnter',
                        enterActive: 'animateSidePanelEnterActive',
                        exit: 'animateSidePanelLeave',
                        exitActive: 'animateSidePanelLeaveActive',
                        exitDone: 'animateSidePanelLeaveDone',
                    }}
                    unmountOnExit={true}
                >
                    <div className="panel-frame side-panel">
                        <div className="header">
                            <div className="title" data-client-id={this.props.dataClientId}>
                                <span>{this.props.titleLabel}</span>
                            </div>
                            <CloseLeftPanelButton onClick={() => this.props.toggleLeftSidePanel()} />
                        </div>
                        <div className="content">{this.props.children}</div>
                    </div>
                </CSSTransition>
            </div>
        );
    }
}

const mapState = createStructuredSelector({
    leftSidePanelOpened: leftSidePanelOpenedSelector,
});

const mapDispatch = {
    toggleLeftSidePanel: Actions.toggleLeftSidePanel,
};

export default connect<StateProps, DispatchProps, OwnProps>(mapState, mapDispatch)(PanelFrame);
