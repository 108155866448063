export enum DateFnsFormat {
    D_MMM_DASH = 'd-MMM',
    D_MMM_YYYY = 'd-MMM-yyyy',
    D_MMMM = 'd MMMM',

    DD_MM_YY_DASH = 'dd-MM-yy',
    DD_MM_YY_DOT = 'dd.MM.yy',
    DD_MM_YY_EXTRA_DOT = 'dd.MM.yy.',
    DD_MM_YY_SLASH = 'dd/MM/yy',

    DD_MM_YYYY_DASH = 'dd-MM-yyyy',
    DD_MM_YYYY_DOT = 'dd.MM.yyyy',
    DD_MM_YYYY_SLASH = 'dd/MM/yyyy',
    DWWW_DD_MMM_YYYY = 'EE dd-MMM-yyyy',

    M_D_YY_DASH = 'M-d-yy',
    M_D_YY_DOT = 'M.d.yy',
    M_D_YY_SLASH = 'M/d/yy',

    M_D_YYYY_DASH = 'M-d-yyyy',
    M_D_YYYY_DOT = 'M.d.yyyy',
    M_D_YYYY_SLASH = 'M/d/yyyy',

    M_YY_DASH = 'M-yy',
    M_YY_DOT = 'M.yy',
    M_YY_SLASH = 'M/yy',

    M_YYYY_DASH = 'M-yyyy',
    M_YYYY_DOT = 'M.yyyy',
    M_YYYY_SLASH = 'M/yyyy',

    MM_DD_YY_DASH = 'MM-dd-yy',
    MM_DD_YY_SLASH = 'MM/dd/yy',

    MM_DD_YYYY_SLASH = 'MM/dd/yyyy',
    DWWW_MM_DD_YYYY = 'EE MM/dd/yyyy',

    MM_YY_DASH = 'MM-yy',
    MM_YY_SPACE = 'MM yy',

    MM_YYYY_DASH = 'MM-yyyy',
    MM_YYYY_SPACE = 'MM yyyy',

    MMM = 'MMM',

    MMM_D_DASH = 'MMM-d',
    MMM_D_SPACE = 'MMM d',
    MMM_D_YYYY = 'MMM d, yyyy',

    MMM_YY_DASH = 'MMM-yy',
    MMM_YY_SPACE = 'MMM yy',

    MMM_YYYY_DASH = 'MMM-yyyy',
    MMM_YYYY_SPACE = 'MMM yyyy',

    MMMM = 'MMMM',

    MMMM_D_SPACE = 'MMMM d',
    MMMM_D = 'MMMM d',

    MMMM_D_YYYY_COMMA_SPACE = 'MMMM d, yyyy',
    MMMM_D_YYYY_SPACE = 'MMMM d yyyy',
    MMMM_D_YYYY = 'MMMM d, yyyy',
    DWWWW_MMMM_D_YYYY = 'EEEE, MMMM d, yyyy',

    MMMM_DD_YYYY_COMMA_SPACE = 'MMMM dd, yyyy',
    MMMM_DD_YYYY_SPACE = 'MMMM dd yyyy',

    MMMM_YY_DASH = 'MMMM-yy',
    MMMM_YY_SPACE = 'MMMM yy',

    MMMM_YYYY_DASH = 'MMMM-yyyy',
    MMMM_YYYY_SPACE = 'MMMM yyyy',

    YY_DD_MM_DOT = 'yy.dd.MM',

    YY_MM_DD_DASH = 'yy-MM-dd',
    YY_MM_DD_DOT = 'yy.MM.dd',
    YY_MM_DD_DOT_SPACE = 'yy. MM. dd',
    YY_MM_DD_SLASH = 'yy/MM/dd',
    YY_MM_DD_KANJI = 'yy年MM月dd日',

    YYYY_MM_DD_HYPHEN = 'yyyy-MM-dd',
    YYYY_MM_DD_DASH = 'yyyy-MM-dd',
    YYYY_MM_DD_DOT = 'yyyy.MM.dd',
    YYYY_MM_DD_EXTRA_DOT = 'yyyy.MM.dd.',
    YYYY_MM_DD_SLASH = 'yyyy/MM/dd',

    YYYY_MM_DD_THH_MM_SS_DATE_TIME = 'yyyy-MM-ddTHH:mm:ss',
}
