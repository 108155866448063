import * as React from 'react';

import { WithDataClientProps } from '../../../components/hoc/WithDataClient';
import SectionTitle from '../SectionTitle';

export interface BasicViewSourceLinkProps extends WithDataClientProps {
    title?: string;
    sourceDescription?: string;
    sourceLink: string;
    idName?: string;
}

const BasicLinkOut: React.SFC<BasicViewSourceLinkProps> = props => {
    return (
        <div id={props.idName ? props.idName : ''}>
            {props.title &&
            <SectionTitle title={props.title}/>
            }
            <a
                className="sheet-link-out"
                href={props.sourceLink}
                target="_blank"
                data-client-id={props.dataClientId}
                rel="noreferrer"
            >
                {props.sourceDescription ? props.sourceDescription : props.sourceLink}
            </a>
        </div>
    );
};

export default BasicLinkOut;
