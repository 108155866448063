import { Button } from '@smartsheet/lodestar-core';
import { BaseOptionType } from '@smartsheet/lodestar-core/dist/esm/components/selectV2/types';
import { CloseIcon } from '@smartsheet/lodestar-icons';
import { FilterCondition } from '../../../common/classes/Filter/FilterCondition';
import { MAX_FILTER_VALUE } from '../../../common/constants';
import { ColumnType, FilterConditionOperators, InputTypeToDisplay } from '../../../common/enums';
import { Column, DualInput, IFilterCondition } from '../../../common/interfaces';
import { isDateColumnType, isDateValid, isNullOrEmptyString } from '../../../common/utils';
import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import alert from '../../../assets/images/alert/icon-info-circle-red.svg';
import { AutomationIds, AutomationTypes } from '../../../common/enums/AutomationElements.enum';
import { PureBaseComponent } from '../../../components/Base';
import { MenuButton } from '../../../components/Buttons';
import ContactPickerOption from '../../../components/ContactPicker/ContactPickerOption/ContactPickerOption';
import DateSelector from '../../../components/DateSelector';
import MultiSelect from '../../../components/MultiSelect';
import { MultiSelectItem } from '../../../components/MultiSelect/MultiSelect.interface';
import { WithDataClientProps } from '../../../components/hoc/WithDataClient';
import { shortLocaleSelector } from '../../Auth/Selectors';
import { LanguageElementsProp, withLanguageElementsHOC } from '../../../language-elements/withLanguageElementsHOC';
import { SelectOption } from '../../Form/Interfaces/SelectOption.interface';
import './FilterCondition.css';
import FilterMultiSelect from './FilterMultiSelect';

type Selection = MultiSelectItem[] | string | DualInput | undefined;

interface Props extends WithDataClientProps {
    condition: FilterCondition;
    fields: Column[];
    onUpdateFilterCondition: (event: any, index: number) => void;
    onDeleteFilterCondition: (event: any) => void;
    index: number;
    showErrors: boolean;
    disableDeleteCondition: boolean;
    conditionFieldOptions: SelectOption[];
    conditionOperatorOptions: SelectOption[];
    conditionMultiSelectOptions: MultiSelectItem[];
    selection: Selection;
    isFocused: boolean;
    updateActiveConditionIndex: (index: number) => void;
}

interface StateProps {
    userLocale: string;
}

type DateSelectorOnChange = (date: string) => void;

export class FilterConditionBase extends PureBaseComponent<Props & LanguageElementsProp & StateProps> {
    private selector: any;
    private editingEndInput: boolean;
    private blankString = this.props.languageElements.SELECTION_FOR_BLANK_ENTRY;

    public constructor(props: Props & LanguageElementsProp & StateProps) {
        super(props);
        this.selector = React.createRef();
        this.editingEndInput = false;
    }

    public render(): React.ReactNode {
        let dualInputStartError: JSX.Element | null = null;
        let dualInputEndError: JSX.Element | null = null;
        let conditionError: JSX.Element | null = null;

        const {
            condition,
            index,
            showErrors,
            disableDeleteCondition,
            conditionFieldOptions,
            conditionOperatorOptions,
            conditionMultiSelectOptions,
            selection,
        } = this.props;

        const inputType = this.getInputTypeToDisplay(condition);
        const errorMessage = this.getErrorMessage(condition);

        if (showErrors || isDateColumnType(this.props.condition.columnType)) {
            if (
                InputTypeToDisplay.DUAL_INPUT === inputType &&
                errorMessage === this.props.languageElements.ADHOC_FILTER_INVALID_FILTER_VALUE_PROMPT
            ) {
                if (isNullOrEmptyString(condition.value.startValue)) {
                    dualInputStartError = this.getErrorJSX(errorMessage);
                }
                if (isNullOrEmptyString(condition.value.endValue)) {
                    dualInputEndError = this.getErrorJSX(errorMessage);
                }
            } else if (inputType === InputTypeToDisplay.DUAL_DATE) {
                if (isNullOrEmptyString(condition.value.startValue) || !isDateValid(condition.value.startValue)) {
                    dualInputStartError = this.getErrorJSX(errorMessage!);
                }
                if (isNullOrEmptyString(condition.value.endValue) || !isDateValid(condition.value.endValue)) {
                    dualInputEndError = this.getErrorJSX(errorMessage!);
                }
            } else if (errorMessage) {
                conditionError = this.getErrorJSX(errorMessage);
            }
        }

        // Show error conditions on only the first invalid property (if field is invalid, show error there  & not on others)
        const showInvalidField = !condition.isFieldValid && showErrors;
        const showInvalidOperator = condition.isFieldValid && !condition.isOperatorValid && showErrors;
        const showInvalidValue = condition.isFieldValid && condition.isOperatorValid && !condition.isValueValid && showErrors;
        const operatorValue = condition.isOperatorValid && condition.operator ? condition.operator : '';

        return (
            <div
                className={`filter-condition-container ${!condition.isValid ? 'invalid-condition' : ''}`}
                data-client-type={AutomationTypes.VIEW_FILTER_CRITERIA_CONTAINER}
            >
                <div className={`filter-condition ${showErrors || errorMessage ? 'show-errors' : ''}`} ref={this.selector}>
                    <p>{index === 0 ? this.props.languageElements.FILTER_SHOW_ROWS_WHERE : this.props.languageElements.FILTER_AND}</p>
                    <div className="filter-options">
                        <MenuButton
                            value={condition.columnId.toString()}
                            placeholder={this.props.languageElements.ADHOC_FILTER_SELECT_FIELD_PLACEHOLDER}
                            disabled={false}
                            onClick={(e: SelectOption) => this.handleSelectConditionField(e, index)}
                            options={conditionFieldOptions}
                            dataClientId={AutomationIds.FILTER_SELECT_FIELD}
                            classNames={showInvalidField ? 'field-button error' : 'field-button'}
                            id={`field-selector-${index.toString()}`}
                        />
                        <MenuButton
                            value={operatorValue}
                            placeholder={this.props.languageElements.ADHOC_FILTER_SELECT_OPERATOR_PLACEHOLDER}
                            disabled={!condition.isFieldValid}
                            onClick={(e: SelectOption) => this.handleSelectConditionOperator(e, index)}
                            options={conditionOperatorOptions}
                            dataClientId={AutomationIds.FILTER_SELECT_OPERATOR}
                            classNames={showInvalidOperator ? 'operator-button error' : 'operator-button'}
                            id={`operator-selector-${index.toString()}`}
                        />
                    </div>
                    <div className={`condition-input ${disableDeleteCondition ? 'disable-delete' : ''}`}>
                        {inputType === InputTypeToDisplay.MULTI_SELECT &&
                            (this.props.condition.columnType === ColumnType.CONTACT_LIST ||
                            this.props.condition.columnType === ColumnType.MULTI_CONTACT_LIST ? (
                                <MultiSelect
                                    dataClientId={AutomationIds.FILTER_SELECT_INPUT}
                                    disabled={!condition.isFieldValid || !condition.isOperatorValid || !condition.enableValue}
                                    selectedItems={selection as MultiSelectItem[]}
                                    options={conditionMultiSelectOptions}
                                    onChange={this.handleMultiSelectConditionOption}
                                    placeholder={this.props.languageElements.PLACEHOLDER_MULTI_SELECT}
                                    allowCustomOptions={true}
                                    isValid={!showInvalidValue}
                                    optionComponentOverride={ContactPickerOption}
                                    id={`value-input-${index.toString()}`}
                                    updateActiveConditionIndex={() => this.props.updateActiveConditionIndex(this.props.index)}
                                    index={index}
                                />
                            ) : (
                                <FilterMultiSelect
                                    dataClientId={AutomationIds.FILTER_SELECT_INPUT}
                                    inputIndex={index}
                                    options={conditionMultiSelectOptions}
                                    selectedItems={this.getSelectedItemsForConditionField(selection as MultiSelectItem[])}
                                    blankString={this.blankString}
                                    onChange={this.handleMultiSelectConditionOption}
                                    placeholder={this.props.languageElements.PLACEHOLDER_MULTI_SELECT}
                                    readOnly={!condition.isFieldValid || !condition.isOperatorValid || !condition.enableValue}
                                    allowCustom={true}
                                />
                            ))}
                        {inputType === InputTypeToDisplay.BASIC_INPUT && (
                            <div className={'user-input'}>
                                <input
                                    type="text"
                                    maxLength={MAX_FILTER_VALUE}
                                    onChange={(e) => this.handleSingleInputConditionOption(e)}
                                    placeholder={this.props.languageElements.PLACEHOLDER_BASIC_INPUT}
                                    value={typeof selection === 'string' ? selection : ''}
                                    data-client-id={AutomationIds.FILTER_CONDITION_INPUT}
                                    disabled={!condition.isFieldValid || !condition.isOperatorValid || !condition.enableValue}
                                    className={showInvalidValue ? 'error' : ''}
                                    onClick={() => this.props.updateActiveConditionIndex(this.props.index)}
                                />
                            </div>
                        )}
                        {inputType === InputTypeToDisplay.DUAL_INPUT && (
                            <div className={'dual'}>
                                <div className={'user-input'}>
                                    <input
                                        type="text"
                                        onChange={this.handleStartInputConditionOption}
                                        placeholder={this.props.languageElements.PLACEHOLDER_DUAL_INPUT_START_VALUE}
                                        value={selection != null && (selection as DualInput).startValue ? (selection as DualInput).startValue : ''}
                                        data-client-id={AutomationIds.FILTER_CONDITION_DUAL_INPUT_START}
                                        disabled={!condition.isFieldValid || !condition.isOperatorValid || !condition.enableValue}
                                        onClick={() => this.updateActiveInputForDualInput(this.props.index, false)}
                                    />
                                    {dualInputStartError}
                                </div>
                                <div className={'user-input'}>
                                    <input
                                        type="text"
                                        onChange={this.handleEndInputConditionOption}
                                        placeholder={this.props.languageElements.PLACEHOLDER_DUAL_INPUT_END_VALUE}
                                        value={selection != null && (selection as DualInput).endValue ? (selection as DualInput).endValue : ''}
                                        data-client-id={AutomationIds.FILTER_CONDITION_DUAL_INPUT_END}
                                        disabled={!condition.isFieldValid || !condition.isOperatorValid || !condition.enableValue}
                                        onClick={() => this.updateActiveInputForDualInput(this.props.index, true)}
                                    />
                                    {dualInputEndError}
                                </div>
                            </div>
                        )}
                        {inputType === InputTypeToDisplay.BASIC_DATE && (
                            <DateSelector
                                datePickerProps={{
                                    portalId: 'date-selector-portal',
                                    popperClassName: 'date-selector-popper',
                                }}
                                containerClassName={`date-picker-filter ${errorMessage ? 'error' : ''}`}
                                date={typeof selection === 'string' ? selection : ''}
                                onChange={this.handleSingleInputConditionOption as DateSelectorOnChange}
                                dataClientId={AutomationIds.FILTER_BASIC_DATE_INPUT}
                                disabled={!condition.isFieldValid || !condition.isOperatorValid || !condition.enableValue}
                            />
                        )}
                        {inputType === InputTypeToDisplay.DUAL_DATE && (
                            <div className={'dual'}>
                                <div className={'user-input'}>
                                    <DateSelector
                                        datePickerProps={{
                                            portalId: 'date-selector-portal',
                                            popperClassName: 'date-selector-popper',
                                        }}
                                        containerClassName={`date-picker-filter-dual ${dualInputStartError ? 'error' : ''}`}
                                        date={selection != null && (selection as DualInput).startValue ? (selection as DualInput).startValue : ''}
                                        onChange={this.handleStartInputConditionOption as DateSelectorOnChange}
                                        dataClientId={AutomationIds.FILTER_CONDITION_DUAL_INPUT_START}
                                        disabled={!condition.isFieldValid || !condition.isOperatorValid || !condition.enableValue}
                                    />
                                    {dualInputStartError}
                                </div>
                                <div className={'user-input'}>
                                    <DateSelector
                                        datePickerProps={{
                                            portalId: 'date-selector-portal',
                                            popperClassName: 'date-selector-popper',
                                        }}
                                        containerClassName={`date-picker-filter-dual ${dualInputEndError ? 'error' : ''}`}
                                        date={selection != null && (selection as DualInput).endValue ? (selection as DualInput).endValue : ''}
                                        onChange={this.handleEndInputConditionOption as DateSelectorOnChange}
                                        dataClientId={AutomationIds.FILTER_CONDITION_DUAL_INPUT_END}
                                        disabled={!condition.isFieldValid || !condition.isOperatorValid || !condition.enableValue}
                                    />
                                    {dualInputEndError}
                                </div>
                            </div>
                        )}
                        {!disableDeleteCondition && (
                            <Button
                                appearance="secondary-empty"
                                data-id={index}
                                data-client-id={AutomationIds.FILTER_SELECT_DELETE}
                                onClick={this.handleDeleteCondition}
                                iconBefore={<CloseIcon size="small" />}
                                size="small"
                                aria-label={this.props.languageElements.ADHOC_FILTER_DELETE}
                            />
                        )}
                    </div>
                    {conditionError}
                </div>
            </div>
        );
    }

    /**
     * Set focus to single or multi-select input field if either applies (will not apply to CHECKED, IS BLANK...)
     */
    public componentDidUpdate(prevProps: Props): void {
        const focusIndex = this.editingEndInput ? 1 : 0;
        if (this.props.isFocused) {
            const focusElement = (this.selector.current as HTMLElement).getElementsByTagName('input');
            if (focusElement.length > focusIndex) {
                (focusElement[focusIndex] as HTMLElement).focus();
            }
        }
    }

    private getSelectedItemsForConditionField(selection: MultiSelectItem[] | null | undefined): BaseOptionType[] {
        return selection
            ? selection.map((item) => ({
                  value: item.value,
                  label: item.value || this.blankString,
                  symbol: item.symbol,
              }))
            : [];
    }

    public handleDeleteCondition = (event: React.MouseEvent<HTMLElement>): void => {
        this.props.onDeleteFilterCondition(event.currentTarget.getAttribute('data-id'));
    };

    public handleSelectConditionField = (e: SelectOption, index: number): void => {
        const filterCondition = new FilterCondition(this.props.fields, this.props.condition as IFilterCondition);
        filterCondition.setConditionField(Number(e.value));
        this.props.onUpdateFilterCondition(filterCondition.toDisplayModel(), index);
    };

    public handleSelectConditionOperator = (e: SelectOption, index: number): void => {
        const filterCondition = new FilterCondition(this.props.fields, this.props.condition as IFilterCondition);
        filterCondition.setConditionOperator(e.value);
        this.props.onUpdateFilterCondition(filterCondition.toDisplayModel(), index);
    };

    public handleMultiSelectConditionOption = (e: MultiSelectItem[]): void => {
        const filterCondition = new FilterCondition(this.props.fields, this.props.condition as IFilterCondition);
        filterCondition.clearConditionOptions();
        if (Array.isArray(e)) {
            e.forEach((item: MultiSelectItem) => filterCondition.updateConditionOption(item.value));
        }
        this.props.onUpdateFilterCondition(filterCondition.toDisplayModel(), this.props.index);
    };

    public handleSingleInputConditionOption = (e: React.ChangeEvent<HTMLInputElement> | string): void => {
        const value = typeof e === 'string' || !e ? e : e.target.value;
        const filterCondition = new FilterCondition(this.props.fields, this.props.condition as IFilterCondition);
        filterCondition.updateConditionOption(value);
        this.props.onUpdateFilterCondition(filterCondition.toDisplayModel(), this.props.index);
    };

    public handleStartInputConditionOption = (e: React.ChangeEvent<HTMLInputElement> | string): void => {
        const startValue = typeof e === 'string' || !e ? e : e.target.value;
        const filterCondition = new FilterCondition(this.props.fields, this.props.condition as IFilterCondition);
        this.editingEndInput = false;
        const newDualInput: DualInput = {
            startValue,
            endValue: this.props.selection ? (this.props.selection as DualInput).endValue : '',
        };
        filterCondition.updateConditionOption(newDualInput);
        this.props.onUpdateFilterCondition(filterCondition.toDisplayModel(), this.props.index);
    };

    public handleEndInputConditionOption = (e: React.ChangeEvent<HTMLInputElement> | string): void => {
        const endValue = typeof e === 'string' || !e ? e : e.target.value;
        const filterCondition = new FilterCondition(this.props.fields, this.props.condition as IFilterCondition);
        this.editingEndInput = true;
        const newDualInput: DualInput = {
            startValue: this.props.selection ? (this.props.selection as DualInput).startValue : '',
            endValue,
        };
        filterCondition.updateConditionOption(newDualInput);
        this.props.onUpdateFilterCondition(filterCondition.toDisplayModel(), this.props.index);
    };

    private getInputTypeToDisplay = (condition: FilterCondition): InputTypeToDisplay => {
        // For one of..., has any..., has all..., allow multi-selection
        if (
            condition.operator === FilterConditionOperators.IS_ONE_OF ||
            condition.operator === FilterConditionOperators.IS_NOT_ONE_OF ||
            condition.operator === FilterConditionOperators.HAS_ANY_OF ||
            condition.operator === FilterConditionOperators.HAS_NONE_OF ||
            condition.operator === FilterConditionOperators.HAS_ALL_OF ||
            condition.operator === FilterConditionOperators.DOES_NOT_HAVE_ALL_OF
        ) {
            return InputTypeToDisplay.MULTI_SELECT;
        }

        if (condition.operator === FilterConditionOperators.IS_BETWEEN || condition.operator === FilterConditionOperators.IS_NOT_BETWEEN) {
            if (condition.isDateType) {
                return InputTypeToDisplay.DUAL_DATE;
            }

            return InputTypeToDisplay.DUAL_INPUT;
        }

        if (condition.isDateType) {
            return InputTypeToDisplay.BASIC_DATE;
        }

        // Otherwise allow basic input
        return InputTypeToDisplay.BASIC_INPUT;
    };

    private getErrorMessage = (condition: FilterCondition): string | undefined => {
        if (isDateColumnType(this.props.condition.columnType) && !condition.isValueValid) {
            const inputType = this.getInputTypeToDisplay(condition);

            if (inputType === InputTypeToDisplay.DUAL_DATE && (!isDateValid(condition.value.startValue) || !isDateValid(condition.value.endValue))) {
                return this.props.languageElements.ADHOC_FILTER_INVALID_FILTER_DATE_VALUE_PROMPT;
            }

            if (inputType === InputTypeToDisplay.BASIC_DATE && !isDateValid(condition.value)) {
                return this.props.languageElements.ADHOC_FILTER_INVALID_FILTER_DATE_VALUE_PROMPT;
            }
        }

        if (!condition.columnId) {
            return this.props.languageElements.ADHOC_FILTER_INVALID_COLUMN_TYPE_PROMPT;
        } else if (!condition.doesFieldExist) {
            return this.props.languageElements.ADHOC_FILTER_DELETED_COLUMN_PROMPT;
        } else if (!condition.isFieldValid) {
            return this.props.languageElements.ADHOC_FILTER_INVALID_COLUMN_TYPE_PROMPT;
        } else if (!condition.isOperatorValid) {
            return this.props.languageElements.ADHOC_FILTER_INVALID_FILTER_OPERATOR_PROMPT;
        } else if (!condition.isValueValid) {
            return this.props.languageElements.ADHOC_FILTER_INVALID_FILTER_VALUE_PROMPT;
        }
        return;
    };

    private updateActiveInputForDualInput = (index: number, editingEndInput: boolean): void => {
        this.editingEndInput = editingEndInput;
        this.props.updateActiveConditionIndex(index);
    };

    private getErrorJSX = (errorMessage: string): JSX.Element => (
        <div className={'error'} data-client-id={AutomationIds.FILTER_CONDITION_ERROR}>
            <img src={alert} alt="" />
            <p>{errorMessage}</p>
        </div>
    );
}

const mapState = createStructuredSelector({
    userLocale: shortLocaleSelector,
});

export default withLanguageElementsHOC(connect<StateProps>(mapState)(FilterConditionBase));
