import { v4 as uuid } from 'uuid';
import { FormActionType } from '../enums';
import { FormActionInterface, FormFieldInterface } from '../interfaces';

export class FormAction {

    public get id(): string {
        return this._id;
    }

    public get actionType(): FormActionType {
        return this._actionType;
    }

    public get fields(): number[] {
        return this._fields;
    }

    public get isValid(): boolean {
        return this._isValid;
    }

    private readonly _id: string;
    private _actionType: FormActionType;
    private _fields: number[];
    private _isValid: boolean;
    private readonly _includedFields: FormFieldInterface[];

    public constructor(includedFields: FormFieldInterface[], value?: FormActionInterface) {
        this._includedFields = includedFields;
        if (!value) {
            this._id = uuid();
            this._fields = [];
        } else {
            this._id = value.id!;
            this._actionType = value.actionType;
            this._fields = value.fields;
        }
        this.checkValidity();
    }

    public toWireModel(): FormActionInterface {
        return {
            id: this._id,
            actionType: this._actionType,
            fields: this._fields,
            isValid: this._isValid,
        };
    }

    public setActionType(actionType: FormActionType): void {
        this._actionType = actionType;
        this.checkValidity();
    }

    public addField(id: number): void {
        if (!this._fields) {
            this._fields = [];
        }
        this._fields.push(id);
        this.checkValidity();
    }

    public removeField(id: number): void {
        if (!this._fields) {
            this._fields = [];
        }
        const index = this._fields.indexOf(id);
        if (index !== -1) {
            this._fields.splice(index, 1);
        }
        this.checkValidity();
    }

    public clearFields(): void {
        this._fields = [];
        this.checkValidity();
    }

    private checkValidity(): void {
        const unavailableIncludedFields = this.fields.filter((field: number) => {
            const matchingIncludedField = this._includedFields.find((includedField: FormFieldInterface) => includedField.columnId === field);
            return matchingIncludedField ? undefined : field;
        });
        unavailableIncludedFields.forEach((field: number) => this.removeField(field));
        this._isValid = this.actionType && this.fields.length > 0;
    }
}
