import { CellObjectValue, Contact, Format, IHyperlink, Image, ImageUrlDto as ImageUrl, PicklistItem, SelectV2Option } from '.';
import { ColumnType, SystemColumnType } from '../enums';

export interface FormFieldInterface extends Record<string, unknown> {
    columnId: number;
    index?: number;
    title?: string;
    type?: ColumnType;
    id?: string;
    options?: string[];
    longText?: boolean;
    multiline?: boolean;
    description?: string;
    value?: any;
    displayValue?: string;
    objectValue?: CellObjectValue;
    selected?: boolean;
    required?: boolean;
    readOnly?: boolean;
    hidden?: boolean;
    ordinal?: number;
    customLabel?: string;
    defaultValue?: any; // The DTO for this field only allows string
    checked?: boolean;
    strict?: boolean;
    placeholder?: string;
    overrideValidation?: boolean;
    format?: Format;
    formula?: string;
    hyperlink?: IHyperlink;
    systemColumnType?: SystemColumnType;
    image?: Image;
    validationOnly?: boolean;

    // Used for rendering component on data tab within details component
    symbol?: string;
    contactOptions?: Contact[];
    validation?: boolean;
    picklistOptions?: PicklistItem[];
    selectedPicklistOption?: PicklistItem;
    multiSelectOptions?: SelectV2Option[];
    selectedMultiSelectOptions?: SelectV2Option[];
    thumbnailUrl?: ImageUrl;
    imageUrl?: ImageUrl;
    attachedCellImage?: File;
}

export const clearCellImageFields = (field: FormFieldInterface) => {
    field.image = undefined;
    field.imageUrl = undefined;
    field.thumbnailUrl = undefined;
};
