import * as React from 'react';
import { selectStatusForCurrentRow, selectedRowIdSelector } from '../Selectors';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../../../store';
import { AsyncStatus } from '../../../../common/enums';
import { Toast, ToastAction, ToastActions, ToastContent, colors, sizes } from '@smartsheet/lodestar-core';
import styled from '@emotion/styled';
import { Actions } from '../Actions';
import { useLanguageElements } from '../../../../language-elements/withLanguageElementsHOC';
import { INSERT_ROW_ID } from '../Reducers';
import { AutomationIds } from '../../../../common/enums/AutomationElements.enum';

interface OwnProps {
    getRowData: () => void;
    isNewSubmission: boolean;
}

const StyledToast = styled(Toast)({
    margin: '13px',
    borderRadius: `${sizes.small}px`,
    boxShadow: `0 2px 12px 0 ${colors.shadowMenu}`,
});

export const DetailsDataError = (props: OwnProps) => {
    const status = useSelector<StoreState, AsyncStatus | undefined>((state) => selectStatusForCurrentRow(state));
    let selectedRowId = useSelector<StoreState, string | undefined>((state) => selectedRowIdSelector(state));
    const dispatch = useDispatch();
    const languageElements = useLanguageElements();

    if (status !== AsyncStatus.ERROR) {
        return null;
    }

    if (props.isNewSubmission) {
        selectedRowId = INSERT_ROW_ID;
    }

    if (selectedRowId == null) {
        return null;
    }
    const refreshRow = () => {
        dispatch(Actions.dismissErrorsForRow(selectedRowId!));
        if (!props.isNewSubmission) {
            props.getRowData();
        }
    };

    return (
        <StyledToast type={'warning'} data-client-id={AutomationIds.DETAILS_TAB_ERROR_TOAST}>
            <ToastContent>{languageElements.DETAILS_PANEL_UNABLE_SAVE_TRY_AGAIN}</ToastContent>
            <ToastActions>
                <ToastAction onClick={refreshRow} aria-label={languageElements.DETAILS_PANEL_REFRESH}>
                    {languageElements.DETAILS_PANEL_REFRESH}
                </ToastAction>
            </ToastActions>
        </StyledToast>
    );
};
