import { Column, SourceColumn, ViewConfig, ViewData } from '../interfaces';
import { ColumnHeader } from '../../components/Grid/Grid.interface';
import { MultiSelectItem } from '../../components/MultiSelect/MultiSelect.interface';
import { Omit } from './Omit';
import { ColumnId, RowId } from './ViewSourceFactory';

export interface SymbolLookup {
    symbol: string | undefined;
}

export type ViewSourceOptions = Readonly<Omit<ViewSource, 'viewConfig' | 'getColumnMap'>>;

export class ViewSource {
    public readonly viewConfig: ViewConfig;
    public readonly viewData: ViewData;
    public readonly filterColumnIndex: number;
    // Column id is the source sheet column id, returns the source column
    public readonly sheetColumnMap: Map<number, Column>;
    // Column id is the report column id, returns the report column
    public readonly reportColumnMap: Map<number, Column>;
    public readonly picklistSymbolImageMap: Map<string, SymbolLookup | undefined>;
    public readonly allColumnOptionsMap: Map<number, Map<string, MultiSelectItem>>;
    public readonly totalRows: number;
    public readonly columnHeaders: ColumnHeader[];

    // Mapping for row-id -> report-column-id -> sheet-column-object (only defined for views based on reports)
    public readonly rowIdToReportColumnIdToSheetColumn: Map<RowId, Map<ColumnId, SourceColumn | undefined>>;

    public constructor(viewConfig: ViewConfig, options?: ViewSourceOptions) {
        this.viewConfig = viewConfig;
        if (options) {
            this.viewData = options.viewData;
            this.filterColumnIndex = options.filterColumnIndex;
            this.sheetColumnMap = options.sheetColumnMap;
            this.reportColumnMap = options.reportColumnMap;
            this.picklistSymbolImageMap = options.picklistSymbolImageMap;
            this.allColumnOptionsMap = options.allColumnOptionsMap;
            this.totalRows = options.totalRows;
            this.columnHeaders = options.columnHeaders;
            this.rowIdToReportColumnIdToSheetColumn = options.rowIdToReportColumnIdToSheetColumn;
        }
    }

    public getColumnMap = () => (this.reportColumnMap && this.reportColumnMap.size > 0 ? this.reportColumnMap : this.sheetColumnMap);
}
