import { EligibilityStatus } from '../containers/Auth/types';
import axiosInstance from './Axios.instance';

class LicensingClient {
    public static getInstance(): LicensingClient {
        if (!LicensingClient.instance) {
            LicensingClient.instance = new LicensingClient();
        }
        return LicensingClient.instance;
    }

    private static instance: LicensingClient;
    private static url = '/licensing';

    private constructor() {}

    public async getEligibility(): Promise<EligibilityStatus> {
        const response = await axiosInstance.get(`${LicensingClient.url}/eligibility`);
        return response.data;
    }
}
const licensingClient = LicensingClient.getInstance();
export default licensingClient;
