import { CurrencySymbolType } from '../enums/FormatTypes.enum';
import { CurrencyObject } from '../interfaces/SmartsheetFormatObject.interface';
import { MAX_EMAIL_CHARS } from './MaxCharacter.constants';

export const MAX_NUMBER_VALUE = 9007199254740991;
export const MIN_NUMBER_VALUE = -9007199254740991;
export const MAX_CELL_VALUE_CHARS = 4000;
// Calculated as SMAR character limit - max email size - prepend language element length)
export const MAX_COMMENTS_CHARS = 5000 - MAX_EMAIL_CHARS - 30;
// Enter file types as lower case
export const FORBIDDEN_FILE_TYPES = new Set(['exe']);
export const MAX_FILE_SIZE_IN_BYTES = 30000000;
export const MAX_FRACTION_DIGITS = 5;
export const EXPECTED_FORMAT_STRING_PARTS = 16;
export const REGEX_PATTERN_SOURCE_APP = '^[a-zA-Z 0-9\\_\\-]*$';
// Used to quickly convert color format attributes to corresponding hex code string
export const FORMAT_COLOR_LOOKUP = {
    none: 'DEFAULT',
    black: '#000000',
    white: '#ffffff',
    transparent: 'n/a',
    lighterRed: '#FFEBEE',
    lighterOrange: '#FFF3DF',
    lighterYellow: '#FFFEE6',
    lighterGreen: '#E7F5E9',
    lighterBlue: '#E2F2FE',
    lighterPink: '#F4E4F5',
    lighterBrown: '#F2E8DE',
    lightRed: '#FFCCD2',
    lightOrange: '#FFE1AF',
    lightYellow: '#FEFF85',
    lightGreen: '#C6E7C8',
    lightBlue: '#B9DDFC',
    lightPink: '#EBC7EF',
    lightBrown: '#EEDCCA',
    gray90: '#E5E5E5',
    red: '#F87E7D',
    orange: '#FFCD7A',
    yellow: '#FEFF00',
    green: '#7ED085',
    blue: '#5FB3F9',
    pink: '#D190DA',
    brown: '#D0AF8F',
    gray74: '#BDBDBD',
    darkRed: '#EA352E',
    darkOrange: '#FF8D00',
    darkYellow: '#FFED00',
    darkGreen: '#40B14B',
    darkBlue: '#1061C3',
    darkPurple: '#9210AD',
    darkBrown: '#974C00',
    gray46: '#757575',
    darkerRed: '#991310',
    darkerOrange: '#EA5000',
    darkerYellow: '#EBC700',
    darkerGreen: '#237F2E',
    darkerBlue: '#0B347D',
    darkerPurple: '#61058B',
    darkerBrown: '#592C00',
};

// Used to quickly access currency attributes: symbol and offsetRight
export const FORMAT_CURRENCY_LOOKUP: CurrencyObject = {
    NONE: { symbol: CurrencySymbolType.NONE, offsetRight: false },
    ARS: { symbol: CurrencySymbolType.ARS, offsetRight: false }, // AR$ 2.00
    AUD: { symbol: CurrencySymbolType.AUD, offsetRight: false }, // A$3.00
    BRL: { symbol: CurrencySymbolType.BRL, offsetRight: false }, // R$4.00
    CAD: { symbol: CurrencySymbolType.CAD, offsetRight: false }, // CA$1.00
    CLP: { symbol: CurrencySymbolType.CLP, offsetRight: false }, // CLP$2.00
    EUR: { symbol: CurrencySymbolType.EUR, offsetRight: false }, // €1.00
    GBP: { symbol: CurrencySymbolType.GBP, offsetRight: false }, // £3.00
    ILS: { symbol: CurrencySymbolType.ILS, offsetRight: false }, // ₪4.00
    INR: { symbol: CurrencySymbolType.INR, offsetRight: false }, // ₹3.00
    JPY: { symbol: CurrencySymbolType.JPY, offsetRight: false }, // ¥1
    MXN: { symbol: CurrencySymbolType.MXN, offsetRight: false }, // MX$2.00
    RUB: { symbol: CurrencySymbolType.RUB, offsetRight: true }, // 1.00 ₽
    USD: { symbol: CurrencySymbolType.USD, offsetRight: false }, // $1.00
    ZAR: { symbol: CurrencySymbolType.ZAR, offsetRight: false }, // R3.00
    CHF: { symbol: CurrencySymbolType.CHF, offsetRight: false }, // CHF2.00
    CNY: { symbol: CurrencySymbolType.CNY, offsetRight: false }, // CN¥3.00
    DKK: { symbol: CurrencySymbolType.DKK, offsetRight: true }, // 4.00 kr.
    HKD: { symbol: CurrencySymbolType.HKD, offsetRight: false }, // HK$2.00
    KRW: { symbol: CurrencySymbolType.KRW, offsetRight: false }, // ₩4.00
    NOK: { symbol: CurrencySymbolType.NOK, offsetRight: false }, // kr 4.00
    NZD: { symbol: CurrencySymbolType.NZD, offsetRight: false }, // NZ$3.00
    SEK: { symbol: CurrencySymbolType.SEK, offsetRight: true }, // 1.00 kr
    SGD: { symbol: CurrencySymbolType.SGD, offsetRight: false }, // SG$2.00
};
