import * as React from 'react';
import { RailItem } from './RailItem.interface';
import RightRail from './RightRail';
import './RightRail.css';

interface Props {
    activeItemKey: string;
    items: RailItem[];
    onActiveItemChange: (key: string) => void;
}

interface State {
    activeItemKey: string;
}

class RightRailContainer extends React.Component<Props> {
    public state: State;

    public constructor(props: Props) {
        super(props);
        this.state = {
            activeItemKey: props.activeItemKey,
        };
    }

    public render(): React.ReactNode {
        return(
            <RightRail items={this.props.items} activeItemKey={this.props.activeItemKey} onActiveItemChange={this.onClickMenuItem}/>
        );
    }

    private onClickMenuItem = (item: RailItem): void => {
        this.setState({
            activeItemKey: item.key,
        }, this.onActiveItemChange.bind(this));
    }

    private onActiveItemChange(): void {
        const activeItem = this.props.items.find((item: RailItem) => item.key === this.state.activeItemKey);
        if (activeItem) {
            this.props.onActiveItemChange(activeItem.key);
        }
    }
}

export default RightRailContainer;
