import { AccessLevel } from '../enums';

const Levels = {
    [AccessLevel.OWNER]: 0,
    [AccessLevel.ADMIN]: 1,
    [AccessLevel.USER]: 2,
    [AccessLevel.NONE]: 3,
};

export const getHighestAccessLevel = (a: AccessLevel, b?: AccessLevel) => {
    if (!b) { return a; }
    return Levels[a] < Levels[b] ? a : b;
};
