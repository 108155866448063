import classnames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { HomeFilter } from '../../../common/enums/HomeFilter';
import { LanguageElementsProp, withLanguageElementsHOC } from '../../../language-elements/withLanguageElementsHOC';
import { ActionByType } from '../../../store';
import { Actions as AppActions, ActionTypes as AppActionTypes } from '../../App/Actions';
import { homeFilterSelector } from '../../App/Selectors';
import { isUserSystemAdmin } from '../../Auth/Selectors';

interface StateProps {
    activeFilter: HomeFilter;
    isUserSystemAdmin: boolean;
}

interface DispatchProps {
    setHomeFilter: (filter: HomeFilter | null) => ActionByType<AppActions, AppActionTypes.SET_HOME_FILTER>;
}

type HomeFilterPanelAdminRowProps = StateProps & DispatchProps & RouteComponentProps<any> & LanguageElementsProp;

const HomeFilterPanelAdminRow: React.FC<HomeFilterPanelAdminRowProps> = (props) => {
    const onClick = () => {
        props.setHomeFilter(null);
        props.history.push('/admin');
    };

    const classes = classnames('container-list-item', { active: props.activeFilter === null });

    return props.isUserSystemAdmin ? (
        <li className={classes} onClick={onClick}>
            <div className="label">{props.languageElements.HOME_TITLE.ADMIN_CONTROLS_LABEL}</div>
        </li>
    ) : null;
};

const mapState = createStructuredSelector({
    activeFilter: homeFilterSelector,
    isUserSystemAdmin,
});

const mapDispatch: DispatchProps = {
    setHomeFilter: AppActions.setHomeFilter,
};

export default withLanguageElementsHOC(withRouter(connect<StateProps, DispatchProps>(mapState, mapDispatch)(HomeFilterPanelAdminRow)));
