import styled from '@emotion/styled';
import { Button, colors, sizes } from '@smartsheet/lodestar-core';
import { AttachmentsCompactIcon } from '@smartsheet/lodestar-icons';
import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FORBIDDEN_FILE_TYPES, MAX_FILE_SIZE_IN_BYTES } from '../../../../common/constants';
import { HttpStatusCodes } from '../../../../common/enums';
import { AutomationIds } from '../../../../common/enums/AutomationElements.enum';
import { isAxiosErrorWithResponse } from '../../../../common/utils/isAxiosErrorWithResponse';
import { LoadingData } from '../LoadingData';
import { Attachment as AttachmentInterface } from './types';
import { getFileExtension } from '../../../../common/utils';
import { WithDataClientProps } from '../../../../components/hoc/WithDataClient';
import ModalWrapper from '../../../../components/Modal';
import Spinner, { Color, Size } from '../../../../components/Spinner';
import attachmentClient from '../../../../http-clients/Attachment.client';
import { useLanguageElements } from '../../../../language-elements/withLanguageElementsHOC';
import * as AppActions from '../../../App/Actions';
import { userFirstLastNameSelector, userLocaleSelector, userTimeZoneSelector } from '../../../Auth/Selectors';
import { currentRowSheetIdSelector } from '../../Selectors';
import AttachmentItem from './AttachmentItem';

export interface AttachmentProps extends WithDataClientProps {
    rowId: number;
    viewId: string;
    width: number;
    addAttachments: boolean;
    onSetAttachmentTotal: (total: number) => void;
    activeTab: boolean;
}

enum ComponentState {
    LOADING,
    SAVING,
    IDLE,
}

const Attachment: React.FC<AttachmentProps> = ({ viewId, rowId, onSetAttachmentTotal, addAttachments, activeTab }) => {
    const dispatch = useDispatch();
    const attachmentInputRef = useRef<HTMLInputElement>(null);
    const languageElements = useLanguageElements();
    const userLocale = useSelector(userLocaleSelector);
    const userTimeZone = useSelector(userTimeZoneSelector);
    const sheetId = useSelector(currentRowSheetIdSelector);
    const userFullName = useSelector(userFirstLastNameSelector);
    const [attachments, setAttachments] = useState<AttachmentInterface[]>([]);
    const [componentState, setComponentState] = useState<ComponentState>(ComponentState.IDLE);

    useEffect(() => {
        // Get attachments
        const getAttachments = async () => {
            setComponentState(ComponentState.LOADING);
            try {
                const fetchedAttachments = await attachmentClient.getRowAttachments(viewId, rowId, sheetId);
                setAttachments(fetchedAttachments);
                onSetAttachmentTotal(fetchedAttachments.length);

                setComponentState(ComponentState.IDLE);
            } catch (error) {
                if (
                    isAxiosErrorWithResponse(error) &&
                    (error.response?.status === HttpStatusCodes.FORBIDDEN || error.response?.status === HttpStatusCodes.NOT_FOUND)
                ) {
                    // If a 403 (Forbidden) or 404 (Not found) error is returned, then the user no longer has access to the row. Ignore the error
                    // because the data tab will throw the appropriate error message.
                    return;
                }

                setComponentState(ComponentState.IDLE);
                dispatch(AppActions.Actions.setAppStageError(new Error(languageElements.GENERIC_FATAL_ERROR)));
            }
        };

        getAttachments();
    }, [dispatch, rowId, viewId, sheetId, onSetAttachmentTotal]);

    const handleInputClick = (): void => {
        if (attachmentInputRef.current) {
            attachmentInputRef.current.click();
        }
    };

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const uploadAttachment = async (file: File) => {
            setComponentState(ComponentState.SAVING);
            try {
                const addedAttachment = await attachmentClient.addRowAttachmentAtt(viewId, rowId!, file, sheetId);
                setComponentState(ComponentState.IDLE);
                // Add new attachment to the start of the array
                const newAttachments = [{ ...addedAttachment, createdByUser: addedAttachment.createdByUser ?? userFullName }, ...attachments];
                onSetAttachmentTotal(newAttachments.length);
                setAttachments(newAttachments);
            } catch (error) {
                setComponentState(ComponentState.IDLE);
                dispatch(AppActions.Actions.setAppStageError(new Error(languageElements.GENERIC_FATAL_ERROR)));
            }
        };

        if (e.target && e.target.files && e.target.files.length > 0) {
            const file: File = e.target.files[0];

            // Check file extension before upload
            const fileExtension = getFileExtension(file.name);

            if (FORBIDDEN_FILE_TYPES.has(fileExtension)) {
                dispatch(AppActions.Actions.setAppStageError(new Error(languageElements.INVALID_ATTACHMENT_TYPE)));
            } else if (file.size > MAX_FILE_SIZE_IN_BYTES) {
                dispatch(AppActions.Actions.setAppStageError(new Error(languageElements.FILE_SIZE_EXCEEDS_LIMITS)));
            } else if (file.size < 1) {
                dispatch(AppActions.Actions.setAppStageError(new Error(languageElements.FILE_REQUIRED)));
            } else {
                uploadAttachment(file);
                e.target.value = '';
            }
        }
    };
    const handleOpenAttachment = async (attachmentId: number, url: string | undefined) => {
        // If the attachment URL is already available, open it in a new tab
        if (url) {
            return;
        }

        try {
            // Fetch the attachment URL and open it the current tab
            const attachmentUrl = await attachmentClient.getRowAttachmentAtt(viewId, rowId, attachmentId, sheetId);

            setAttachments(attachments.map((att) => (att.id === attachmentId ? { ...att, url: attachmentUrl } : att)));
            window.open(attachmentUrl, '_self');
        } catch (error) {
            dispatch(AppActions.Actions.setAppStageError(new Error(languageElements.GENERIC_FATAL_ERROR)));
        }
    };

    // Render components when the tab is active. We still want the component to execute the code above this point to determine the total number of
    // attachments to show in the tab title, just not render anything.
    if (!activeTab) {
        return null;
    }

    const hasAttachments = attachments.length > 0;

    return (
        <>
            {componentState === ComponentState.LOADING && <LoadingData />}
            {componentState === ComponentState.SAVING && (
                <ModalWrapper isModalOpen={true} onClose={() => {}} hideCloseButton={true} focusTrap={false}>
                    <Spinner label={languageElements.SPINNER_SAVING_LABEL} color={Color.BLUE} size={Size.MEDIUM} />
                </ModalWrapper>
            )}
            {(componentState === ComponentState.IDLE || componentState === ComponentState.SAVING) && (
                <AttachmentContainer
                    key={AutomationIds.ATTACHMENT_WRAP}
                    data-client-id={AutomationIds.ATTACHMENT_WRAP}
                    data-testid={AutomationIds.ATTACHMENT_WRAP}
                >
                    <>
                        {hasAttachments && (
                            <AttachmentItemsContainer key={AutomationIds.ATTACHMENT_LIST_CONTAINER}>
                                {attachments.map((att, index) => (
                                    <AttachmentItem
                                        key={att.id}
                                        name={att.name}
                                        url={att.url}
                                        createdBy={att.createdByUser}
                                        sizeInKb={att.contentLengthKb}
                                        createdAt={att.creationTimestamp}
                                        mimeType={att.contentMimeType}
                                        userLocale={userLocale}
                                        userTimeZone={userTimeZone}
                                        onClick={(url) => {
                                            handleOpenAttachment(attachments[index].id, url);
                                        }}
                                    />
                                ))}
                            </AttachmentItemsContainer>
                        )}
                        {!hasAttachments && (
                            <EmptyWrapper key={AutomationIds.ATTACHMENT_EMPTY}>{languageElements.DETAIL_ATTACHMENTS_EMPTY}</EmptyWrapper>
                        )}
                        {addAttachments && (
                            <FileUploadContainer key={AutomationIds.ATTACHMENT_ADD_CONTAINER}>
                                <Button appearance="primary" shouldFitContainer="true" onClick={handleInputClick}>
                                    <AttachmentsCompactIconStyled size="xSmall" color="currentColor" />
                                    {languageElements.DETAIL_ATTACHMENTS_ATTACH_FILE}
                                </Button>
                                <FileUploadInput data-testid="file-upload-input" type="file" onChange={handleFileUpload} ref={attachmentInputRef} />
                            </FileUploadContainer>
                        )}
                    </>
                </AttachmentContainer>
            )}
        </>
    );
};

export default Attachment;

const AttachmentContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
`;

const AttachmentItemsContainer = styled.div`
    bottom: 60px;
    padding-top: ${sizes.xSmall}px;
    padding-bottom: ${sizes.medium}px;
    overflow: auto;
    flex: 1;
`;

const EmptyWrapper = styled.div`
    bottom: 60px;
    padding-top: ${sizes.medium}px;
    padding-bottom: ${sizes.medium}px;
    overflow: auto;
    flex: 1;
    text-align: center;
    color: ${colors.neutralAccessible};
    background-color: ${colors.neutralLight40};
`;

const FileUploadContainer = styled.div`
    align-items: flex-end;
    position: relative;
    overflow: hidden;
    display: inline-block;
    padding: ${sizes.small}px ${sizes.large}px;
    border-top: 1px solid ${colors.neutralLight20};
    box-sizing: border-box;
`;

const FileUploadInput = styled.input`
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
`;

const AttachmentsCompactIconStyled = styled(AttachmentsCompactIcon)`
    padding-right: ${sizes.xSmall}px;
`;
