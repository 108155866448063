import { SFC } from 'react';
import * as React from 'react';
import closePanel from '../../../assets/images/icons/ollapse-blue.svg';
import { AutomationIds } from '../../../common/enums/AutomationElements.enum';
import Button from '../../../components/Buttons/Button/Button';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';

export interface CloseLeftPanelButtonProps {
    onClick: () => void;
}

export const CloseLeftPanelButton: SFC<CloseLeftPanelButtonProps> = (props: CloseLeftPanelButtonProps) => {
    const { onClick } = props;
    const languageElements = useLanguageElements();

    return (
        <Button
            onClick={onClick}
            className="icon"
            icon={closePanel}
            dataClientId={AutomationIds.LEFT_PANEL_CLOSE_BUTTON}
            imageAltText={languageElements.LEFT_PANEL_CLOSE}
        />
    );
};
