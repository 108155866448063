import classNames from 'classnames';
import * as React from 'react';
import { WithDataClientProps } from '../hoc/WithDataClient';
import './NumberFormatInput.css';

interface NumberFormatInputProps extends WithDataClientProps {
    symbolUrl: string;
    symbolAltText: string;
    shouldDisplayRightSymbol: boolean;
    tooltipText?: string;
    fadeSymbol?: boolean;
    inputValue?: string | number;
    inputDisabled?: boolean;
    inputPlaceholder?: string;
    inputMaxLength?: number;
    inputDefaultValue?: string;
    inputOnChange?: (e: React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement>) => void;
}

const NumberFormatInput: React.SFC<NumberFormatInputProps> = props => (
    <div className={classNames('number-format-input', {
        'input-icon-right': props.shouldDisplayRightSymbol,
        'input-icon-faded': props.fadeSymbol,
    })}>
        <input
            className={'input-text'}
            value={props.inputValue}
            disabled={props.inputDisabled}
            placeholder={props.inputPlaceholder}
            maxLength={props.inputMaxLength}
            defaultValue={props.inputDefaultValue}
            onChange={props.inputOnChange}
        />
        {props.symbolUrl && <div className={'input-img'}>
            <img src={props.symbolUrl} alt={props.symbolAltText}/>
            {props.tooltipText && <div className={'tooltip'}>
                <div>{props.tooltipText}</div>
            </div>}
        </div>}
    </div>
)

export default NumberFormatInput;
