import { SourceSheet } from '../../../common/interfaces';
import * as React from 'react';
import { Option, Options } from 'react-select';
import { AutomationIds, AutomationTypes } from '../../../common/enums/AutomationElements.enum';
import { WithDataClientProps } from '../../../components/hoc/WithDataClient';
import { default as Select } from '../../../components/Select';
import { LanguageElementsProp, withLanguageElementsHOC } from '../../../language-elements/withLanguageElementsHOC';
import SectionTitle from '../SectionTitle';

export interface BasicNewItemSubmissionProps extends WithDataClientProps {
    onNewItemSubmissionChange: () => void;
    userCanSubmit: boolean;
    sourceSheets?: SourceSheet[];
    onIntakeSheetChange: (option: Option<string>) => void;
    intakeSheetId?: number | null;
    hasValidIntakeSheet: boolean;
    requiresIntakeSheet: boolean;
}

interface NewItemSubmissionState {
    hasValidIntakeSheet: boolean;
    userCanSubmit: boolean;
    classNames: string[];
    placeholder: string;
    requiresIntakeSheet: boolean;
    intakeSheetId?: number | null;
    noValidSourceSheets: boolean;
}

// TODO: determine if classNames value on state has any impact on rendering & remove if not needed.
// (DZ- I think other boolean props now drive the render.)
export class BasicNewItemSubmission extends React.Component<BasicNewItemSubmissionProps & LanguageElementsProp, NewItemSubmissionState> {
    public constructor(props: BasicNewItemSubmissionProps & LanguageElementsProp) {
        super(props);
        this.state = {
            hasValidIntakeSheet: props.hasValidIntakeSheet,
            requiresIntakeSheet: props.requiresIntakeSheet,
            userCanSubmit: Boolean(props.userCanSubmit),
            intakeSheetId: props.intakeSheetId || undefined,
            placeholder: props.hasValidIntakeSheet ? '' : this.props.languageElements.ADMIN_PANEL_BASIC_NEW_DESTINATION_PLACEHOLDER_ERROR,
            classNames: this.props.userCanSubmit
                ? this.props.hasValidIntakeSheet
                    ? []
                    : ['disabled', 'newDestination']
                : ['error', 'newDestination'],
            noValidSourceSheets : this.props.requiresIntakeSheet && (this.props.sourceSheets == null || !this.props.sourceSheets.length),
        };
    }

    public componentDidUpdate(prevProps: BasicNewItemSubmissionProps): void {
        if (prevProps.userCanSubmit !== this.props.userCanSubmit) {
            this.setState({
                hasValidIntakeSheet: this.props.hasValidIntakeSheet,
                requiresIntakeSheet: this.props.requiresIntakeSheet,
                userCanSubmit: Boolean(this.props.userCanSubmit),
                classNames: this.props.userCanSubmit
                    ? this.props.hasValidIntakeSheet
                        ? ['disabled', 'newDestination']
                        : []
                    : ['error', 'newDestination'],
                intakeSheetId: this.props.intakeSheetId || undefined,
                placeholder: this.props.hasValidIntakeSheet ? '' : this.props.languageElements.ADMIN_PANEL_BASIC_NEW_DESTINATION_PLACEHOLDER_ERROR,
            });
        }

        if (prevProps.hasValidIntakeSheet !== this.props.hasValidIntakeSheet) {
            this.setState({
                hasValidIntakeSheet: this.props.hasValidIntakeSheet,
                classNames: this.props.userCanSubmit
                    ? this.props.hasValidIntakeSheet
                        ? ['disabled', 'newDestination']
                        : []
                    : ['error', 'newDestination'],
                placeholder: this.props.hasValidIntakeSheet ? '' : this.props.languageElements.ADMIN_PANEL_BASIC_NEW_DESTINATION_PLACEHOLDER_ERROR,
            });
        }
        if (prevProps.intakeSheetId !== this.props.intakeSheetId) {
            this.setState({
                intakeSheetId: this.props.intakeSheetId,
            });
        }
    }

    public render(): React.ReactNode {
        return (
            <div className="newItemSubmission" id="basicNewItemSubmission">
                <SectionTitle
                    title={this.props.languageElements.NEW_ITEM_SUBMISSION_TITLE}
                    subtitle={this.props.languageElements.NEW_ITEM_SUBMISSION_SUBTITLE}
                />
                <div className="control-group">
                    <label
                        className="control control--radio"
                        data-client-id={AutomationIds.BASIC_NEW_ITEM_SUBMISSION_NO}
                    >
                        {this.props.languageElements.RADIO_DO_NOT_ALLOW}
                        <input
                            type="radio"
                            name="submit"
                            checked={!this.state.userCanSubmit}
                            onChange={this.props.onNewItemSubmissionChange}
                        />
                        <div className="control__indicator"/>
                    </label>
                    <label
                        className="control control--radio"
                        data-client-id={AutomationIds.BASIC_NEW_ITEM_SUBMISSION_YES}
                    >
                        {this.state.requiresIntakeSheet ?
                            this.props.languageElements.NEW_ITEM_SUBMISSION_TEXT : this.props.languageElements.RADIO_ALLOW}
                        <input
                            type="radio"
                            name="submit"
                            disabled={this.state.noValidSourceSheets}
                            checked={this.state.userCanSubmit}
                            onChange={this.props.onNewItemSubmissionChange}
                        />
                        <div className="control__indicator"/>
                    </label>
                </div>
                {this.state.requiresIntakeSheet &&
                    <div
                        className={this.state.classNames.join(' ')}
                    >
                        {this.state.noValidSourceSheets &&
                        <p className={'invalid'} data-client-id={AutomationIds.ADMIN_PANEL_BASIC_DESTINATION_ERROR}>
                            {this.props.languageElements.ADMIN_PANEL_BASIC_NEW_DESTINATION_NO_SOURCE_SHEETS}
                        </p>
                        }
                        {this.state.userCanSubmit && !this.state.noValidSourceSheets &&
                        <Select
                            id="basicNewIntakeSheet"
                            placeholder={this.state.placeholder}
                            dataClientId={AutomationIds.BASIC_NEW_ITEM_SUBMISSION_SELECT}
                            dataClientType={AutomationTypes.BASIC_NEW_ITEM_SUBMISSION_SELECT}
                            options={this.getIntakeSheetOptions()}
                            selectedOption={this.state.intakeSheetId ? this.state.intakeSheetId.toString() : undefined}
                            onSelect={this.props.onIntakeSheetChange}
                        />
                        }
                        {!this.state.hasValidIntakeSheet && !this.state.noValidSourceSheets &&
                            <p className={'invalid'} data-client-id={AutomationIds.ADMIN_PANEL_BASIC_DESTINATION_ERROR}>
                            {this.props.languageElements.ADMIN_PANEL_BASIC_NEW_DESTINATION_ERROR}
                            </p>
                        }
                    </div>}
            </div>
        );
    }

    private getIntakeSheetOptions(): Options<string> {
        return this.props.sourceSheets != null ? this.props.sourceSheets.map(sheet => ({
            value: String(sheet.id), label: String(sheet.name),
        })) : [];
    }
}

export default withLanguageElementsHOC(BasicNewItemSubmission);