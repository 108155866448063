import { ContactDto } from '../common/interfaces/ContactDto.interface';
import { buildUrl } from '../common/utils';
import axiosInstance from './Axios.instance';

export class AtMentionsClient {
    public static async getContactsForAtMentions(viewId: string, rowId: number, reportSheetId?: number, filter?: string): Promise<ContactDto[]> {
        const url = buildUrl('', {
            path: `/views/${viewId}/rows/${rowId}/atmentions/contacts`,
            queryParams: { filter },
        });

        const response = await axiosInstance.get<ContactDto[]>(url, {
            params: {
                rsid: reportSheetId,
            },
        });
        return response.data;
    }
}
