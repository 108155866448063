import * as React from 'react';
import { useCallback } from 'react';
import iconFilterDisabled from '../../../assets/images/icon-filter-disabled.svg';
import iconFilter from '../../../assets/images/icon-filter.svg';
import arrowDown from '../../../assets/images/icons/black.svg';
import { Filter } from '../../../common/classes/Filter/Filter';
import { MAX_FILTER_NAME } from '../../../common/constants';
import { AccessLevel } from '../../../common/enums';
import { AutomationIds, AutomationTypes } from '../../../common/enums/AutomationElements.enum';
import { IFilterUI, ViewColumn } from '../../../common/interfaces';
import { SymbolLookup } from '../../../common/utils/ViewSource';
import { Button, ButtonGroup } from '../../../components/Buttons';
import ModalWrapper from '../../../components/Modal';
import { MultiSelectItem } from '../../../components/MultiSelect/MultiSelect.interface';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';
import FilterMenu from './FilterMenu/FilterMenu';
import FilterWrapper from './FilterWrapper';

export interface Props {
    fields: ViewColumn[];
    filters: IFilterUI[];
    filterModalOpen: boolean;
    activeFilterIndex: number;
    editFilterIndex: number;
    filterDisabled: boolean;
    totalRows: number;
    filteredRowCount: number;
    onClickFilterIcon: () => void;
    onClickFilterMenu: () => void;
    onCancelFilter: () => void;
    onDeleteFilter: (filterId?: string) => Promise<boolean>;
    onApplyFilter: (filter: IFilterUI) => Promise<boolean>;
    onFilterModalClose: () => void;
    picklistSymbolImageMap: Map<string, SymbolLookup | undefined>;
    allFilterItemsMap: Map<number, Map<string, MultiSelectItem>>;
    filterMenuOpen: boolean;
    onNewFilter: () => void;
    onFilterOff: () => void;
    onFilterSelect: (filter: IFilterUI) => void;
    onFilterEdit: (filter: IFilterUI) => void;
    onFilterDuplicate: (filter: IFilterUI) => void;
    isNewFilter: boolean;
    isDuplicateFilter: boolean;
    currentUserAccessLevel: AccessLevel;
    isLoadingData: boolean;
    isVisibleLoadingIndicator: boolean;
    onClickFilterButton: () => void;
}

const FilterContainer: React.FC<Props> = (props) => {
    const {
        fields,
        filters,
        picklistSymbolImageMap,
        allFilterItemsMap,
        filterModalOpen,
        filterMenuOpen,
        currentUserAccessLevel,
        filterDisabled,
        isLoadingData,
        isVisibleLoadingIndicator,
    } = props;

    const languageElements = useLanguageElements();

    const getFilterForEditing = useCallback((): IFilterUI => {
        const { editFilterIndex, isNewFilter, isDuplicateFilter } = props;

        if (isNewFilter) {
            return new Filter(fields, undefined, false).toDisplayModel();
        }

        if (isDuplicateFilter) {
            const filterToCopy = new Filter(fields, filters[editFilterIndex], false);
            filterToCopy.setName(languageElements.ADHOC_FILTER_COPY_OF(filterToCopy.name).slice(0, MAX_FILTER_NAME));
            filterToCopy.setSharing(currentUserAccessLevel === AccessLevel.USER ? false : filterToCopy.shared);

            return new Filter(fields, filterToCopy, false).toDisplayModel();
        }

        return filters[editFilterIndex];
    }, [props, fields, filters, languageElements, currentUserAccessLevel]);

    const getFilterButtonName = useCallback((): string => {
        const { activeFilterIndex } = props;

        return filterDisabled || activeFilterIndex === -1 ? languageElements.ADHOC_FILTER_OFF : filters[activeFilterIndex]?.name || '';
    }, [props, filters, languageElements, filterDisabled]);

    const getFilterButtonCount = useCallback((): string => {
        const { filteredRowCount, totalRows, activeFilterIndex } = props;

        return !filterDisabled && activeFilterIndex !== -1 ? languageElements.TEMPLATE.SHOWING_TOTAL(filteredRowCount, totalRows) : '';
    }, [props, filterDisabled, languageElements]);

    const handleFilterButtonClick = () => {
        if (isLoadingData) {
            props.onClickFilterButton();
        } else {
            props.onClickFilterIcon();
        }
    };

    const handleFilterMenuClick = () => {
        if (isLoadingData) {
            props.onClickFilterButton();
        } else {
            props.onClickFilterMenu();
        }
    };

    return (
        <div className={`filter-container${isVisibleLoadingIndicator && isLoadingData ? ' disabled' : ''}`}>
            {filterModalOpen && (
                <ModalWrapper focusTrap={false} isModalOpen onClose={props.onFilterModalClose} classes={'filter-wrapper'}>
                    <FilterWrapper
                        onDeleteFilter={props.onDeleteFilter}
                        onCancelFilter={props.onCancelFilter}
                        onApplyFilter={(filterToApply: IFilterUI) => props.onApplyFilter(filterToApply)}
                        filter={getFilterForEditing()}
                        fields={fields}
                        picklistSymbolImageMap={picklistSymbolImageMap}
                        allFilterItemsMap={allFilterItemsMap}
                        currentUserAccessLevel={currentUserAccessLevel}
                    />
                </ModalWrapper>
            )}
            {!filters || !filters.length ? (
                <div style={{ position: 'relative' }}>
                    <Button
                        className="view-filter-button"
                        dataClientId={AutomationIds.FILTER_MODAL_BUTTON}
                        dataClientType={AutomationTypes.BUTTON}
                        icon={iconFilter}
                        imageAltText={languageElements.FILTER_ALT_TEXT}
                        text={languageElements.VIEW_FILTER}
                        onClick={handleFilterButtonClick}
                    />
                </div>
            ) : (
                <div>
                    <ButtonGroup
                        dataClientId={AutomationIds.FILTER_BUTTON_GROUP}
                        dataClientType={AutomationTypes.BUTTON_GROUP}
                        className={`view-header-button-group ${filterDisabled ? '' : 'selected'}`}
                    >
                        <Button
                            dataClientId={AutomationIds.FILTER_DISABLE_BUTTON}
                            dataClientType={AutomationTypes.BUTTON}
                            icon={filterDisabled ? iconFilterDisabled : iconFilter}
                            className="filter-button disable-filter-button"
                            onClick={handleFilterButtonClick}
                        />
                        <Button
                            dataClientId={AutomationIds.FILTER_MODAL_BUTTON}
                            dataClientType={AutomationTypes.BUTTON}
                            className="filter-button"
                            text={getFilterButtonName()}
                            secondaryText={getFilterButtonCount()}
                            onClick={handleFilterMenuClick}
                            icon={arrowDown}
                        />
                    </ButtonGroup>
                </div>
            )}
            {filterMenuOpen && !isLoadingData && (
                <FilterMenu
                    filterList={filters}
                    onNewFilter={props.onNewFilter}
                    onFilterOff={props.onFilterOff}
                    onFilterSelect={props.onFilterSelect}
                    onFilterEdit={props.onFilterEdit}
                    onFilterDuplicate={props.onFilterDuplicate}
                    onFilterDelete={props.onDeleteFilter}
                    currentUserAccessLevel={currentUserAccessLevel}
                />
            )}
        </div>
    );
};

export default FilterContainer;
