import { EncodedFormat, isEncodedFormat } from '@smartsheet/core-views-api-models';
import { ColumnType } from '../../../common/enums';
import { Cell, CellObjectValue, Column, Format } from '../../../common/interfaces';
import { isAbstractDatetimeObjectValue, nullish } from '../../../common/utils';
import * as gsFormattingUtility from '../../../common/utils/GsFormattingUtility';
import { isDateObjectValue } from '../../../common/utils/IsDateObjectValue';
import { SymbolLookup } from '../../../common/utils/ViewSource';
import { innerCell } from '../../../components/Grid/Cells/InnerCell';
import { GridCell } from '../../../components/Grid/Grid.interface';
import * as viewUtils from './'; // This needs to be import * for unit testing purposes

export const getFormattedValueAndSortingValue = (
    { displayValue, value, objectValue, format }: Pick<Cell, 'displayValue' | 'value' | 'objectValue' | 'format'>,
    feature?: boolean
): { displayValue: string; value: CellObjectValue } => {
    const cellObjectValue = objectValue || value;
    // If the cell is populated and is of type number, get its numeric format, else stringify its content and return
    if (viewUtils.canCellValueBeFormattedAsNumber({ value, objectValue, format })) {
        displayValue = gsFormattingUtility.getFormattedNumericValueAll(Number(cellObjectValue), format, feature);
    } else {
        displayValue = String(nullish(displayValue, value, ''));
    }

    return { displayValue, value: cellObjectValue };
};

export const getFormattedCell = (
    cell: Cell,
    column: Column,
    picklistSymbolImageMap: Map<string, SymbolLookup | undefined> | undefined,
    conditionalFormatOnRow?: Format,
    formatOnRow?: Format,
    userLocale?: string
): GridCell => {
    let displayValue: string;
    let symbol: string | undefined;
    let value: CellObjectValue;

    const applyGsFormatting = !!(cell.format && typeof cell.format !== 'string' && isEncodedFormat(cell.format));
    const cellFormatClassNames: string[] = gsFormattingUtility.getCellFormatClassNamesAll(
        cell.format,
        cell.conditionalFormat,
        conditionalFormatOnRow,
        formatOnRow,
        applyGsFormatting
    );

    const hyperlink = cell.hyperlink;

    ({ displayValue, value } = getFormattedValueAndSortingValue(cell, applyGsFormatting));

    switch (column.type) {
        case ColumnType.PICKLIST:
            if (cell.value != null) {
                const symbolSetName = column.symbol;
                const optionKey = `${symbolSetName || ''}|${cell.displayValue || ''}`;

                // Use the picklistSymbolImageMap to get the symbol path instead of the getOption utility for better
                // performance. (without the map we'd have to find the indexOf in the column options for every cell in the grid)
                if (picklistSymbolImageMap && picklistSymbolImageMap.has(optionKey)) {
                    symbol = picklistSymbolImageMap.get(optionKey)!.symbol;
                }
            }
            break;

        case ColumnType.CHECKBOX:
            if (cell.columnId != null) {
                // For mapping, handle null as false
                if (cell.value == null) {
                    displayValue = 'false';
                } else {
                    // For cover the case where the value is a string and not a boolean
                    displayValue = String(cell.value).toLowerCase() === 'true' ? 'true' : String(cell.value);
                }
                const symbolSetName = column.symbol;
                const optionKey = `${symbolSetName || ''}|${displayValue}`;

                // Use the picklistSymbolImageMap to get the symbol path instead of the getOption utility for better
                // performance. (without the map we'd have to find the indexOf in the column options for every cell in the grid)
                if (picklistSymbolImageMap && picklistSymbolImageMap.has(optionKey)) {
                    symbol = picklistSymbolImageMap.get(optionKey)!.symbol;
                }

                value = value || false;
            }
            break;

        case ColumnType.ABSTRACT_DATETIME:
        case ColumnType.DATE:
            if (isDateObjectValue(cell.objectValue) || isAbstractDatetimeObjectValue(cell.objectValue)) {
                displayValue = gsFormattingUtility.getFormattedDate(
                    cell.objectValue.value,
                    cell.format as EncodedFormat,
                    userLocale,
                    applyGsFormatting
                );
            }
            break;
    }

    const containerId = cell.containerId;
    const innerCellComponent = innerCell(displayValue, cellFormatClassNames, symbol, hyperlink, cell.image, containerId);
    /*
     * Include symbol, cellFormatClassnames and hyperlink so that innerCellComponent
     * can be generated at time of render if it isn't generated here (at load time)
     */
    return { displayValue, symbol, value, cellFormatClassNames, hyperlink, innerCellComponent };
};
