/**
 * DynamicSort should be used as the callback function in an array.sort
 */
export const dynamicSort = (property: string): any => {
    return (a: any, b: any) => {
        let comparator = a[property].toLowerCase().localeCompare(b[property].toLowerCase());
        if (comparator === 0) {
            comparator = a[property].localeCompare(b[property]) * -1;
        }
        return comparator;
    };
};
