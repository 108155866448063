import { AxiosError } from 'axios';
import { Epic, ofType } from 'redux-observable';
import { concat, from, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { loggingClient } from '../../http-clients/Logging.client';
import viewClient from '../../http-clients/View.client';
import { Actions, ActionTypes } from './Actions';

const FILENAME = 'Home/Epic.ts';

/**
 * This function subscribes to the FETCH_HOME_DATA action and performs the following:
 * - Calls DV GET /api/views
 *
 * On success:
 * - Dispatch the FETCH_HOME_DATA_SUCCESS action. This action updates the status to 'DONE' and the data to the response for both views & recents.
 *
 * On errors:
 * - Dispatch the FETCH_HOME_DATA_FAILURE action. This action updates the status to ERROR, and the data is NOT cleared.
 *   Note that this allows us to continue to display views in the left panel if the data previously loaded successfully.
 */
const fetchHomeDataEpic: Epic<Actions> = (action$) =>
    action$.pipe(
        ofType(ActionTypes.FETCH_HOME_DATA),
        /*
         * Note we use switchMap here in order to cancel any previous requests because we only care about the most recent fetchViews request.
         * More info here: https://www.learnrxjs.io/learn-rxjs/operators/transformation/switchmap
         */
        switchMap(() => {
            loggingClient.logInfo({
                file: FILENAME,
                message: 'Fetch home data - In progress',
            });
            // Using concat here is not necessary in this particular case, but here if needed. Concat creates an output Observable which sequentially
            // emits all values from given Observable and then moves on to the next(more info:
            // https://rxjs-dev.firebaseapp.com/api/index/function/concat)
            return concat(
                from(viewClient.getViews(true, true, true, false, true)).pipe(
                    tap(() => loggingClient.logInfo({ file: FILENAME, message: 'Fetch home data - Success' })),
                    map((views) => Actions.fetchHomeDataSuccess(views)),
                    catchError((error: AxiosError) => {
                        loggingClient.logError(FILENAME, 'fetchHomeDataEpic', error);
                        return of(Actions.fetchHomeDataFailure());
                    })
                )
            );
        })
    );

export default fetchHomeDataEpic;
