import { IToggleIcon } from '../../../common/interfaces/IToggleIcon.interface';
import { SyntheticEvent } from 'react';
import * as React from 'react';
import iconUseCellFormatting from '../../../assets/images/showFormats/use-cell-formatting.svg';
import { AutomationIds, AutomationTypes } from '../../../common/enums/AutomationElements.enum';
import { Button } from '../../../components/Buttons';
import '../../../components/Toggle/Toggle.css';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';

interface Props {
    onClick: (e: SyntheticEvent) => void;
    showFormats: boolean;
}

const ToggleFormats: React.FC<Props> = (props) => {
    const languageElements = useLanguageElements();
    const showFormatsIcon: IToggleIcon = {
        src: iconUseCellFormatting,
        alt: languageElements.VIEW_SHOW_FORMATS_ALT_TEXT,
        tooltip: languageElements.VIEW_SHOW_FORMATS,
    };
    const classes = `toggle-button ${props.showFormats ? 'selected' : ''}`;
    return (
        <Button
            onClick={props.onClick}
            id={'cell-formats-option'}
            className={classes}
            icon={showFormatsIcon.src}
            tooltip={showFormatsIcon.tooltip}
            imageAltText={showFormatsIcon.alt}
            dataClientId={AutomationIds.VIEW_SHOW_FORMATS}
            dataClientType={AutomationTypes.TOGGLE_ICON_CONTAINER}
        />
    );
};

export default ToggleFormats;
