import * as React from 'react';
import { AutomationIds } from '../../common/enums/AutomationElements.enum';
import { isValidDateString } from '../../common/utils/MomentDates';
import DateSelector from '../DateSelector';

export interface Props {
    inputIndex: number;
    value?: any;
    defaultValue?: any; // The DTO for this field only allows string
    onChange?: (value: string) => void;
    readOnly?: boolean;
    validation?: boolean;
}

/**
 * For forms: Use defaultValue if validation is not required, OR if validation is req'd and it is a valid date
 * For details panel: use value
 */

const FormFieldDate = ({ inputIndex, value, defaultValue, validation, onChange, readOnly }: Props) => {
    const handleChange = (date: string) => {
        if (onChange) {
            onChange(date);
        }
    };

    let defaultDateValue: string | undefined;
    if (value == null && defaultValue != null) {
        defaultDateValue = !validation || (validation && isValidDateString(defaultValue)) ? defaultValue : undefined;
    }
    return (
        <DateSelector
            key={`dsi-${inputIndex}`}
            date={value || defaultDateValue}
            onChange={handleChange}
            dataClientId={AutomationIds.FORM_FIELD_WRAPPER_DATE}
            disabled={readOnly}
        />
    );
};

export default FormFieldDate;
