import { Modal } from '@smartsheet/lodestar-core/';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router';
import { HttpStatusCodes } from '../../../../common/enums';
import { isEmpty } from '../../../../common/utils';
import { isAxiosErrorWithResponse } from '../../../../common/utils/isAxiosErrorWithResponse';
import ModalWrapper from '../../../../components/Modal';
import Spinner, { Color, Size } from '../../../../components/Spinner';
import conversationClient from '../../../../http-clients/ConversationClient';
import { useLanguageElements } from '../../../../language-elements/withLanguageElementsHOC';
import { StoreState } from '../../../../store';
import * as AppActions from '../../../App/Actions';
import { currentRowSheetIdSelector } from '../../Selectors';
import { Actions } from '../Actions';
import { UnsavedChangesModalContent } from '../DetailsData/UnsavedChangesModalContent';
import { LoadingData } from '../LoadingData';
import { selectDetailsIsDirty } from '../Selectors';
import Comment from './Comment';
import CommentInput from './CommentInput';
import { addUserObjectToComment, formatCurrentUser, isNewComment, isNewReply, isTopLevelComment, sortCommentsByDate } from './ConversationUtils';
import { Comment as CommentType } from './types';

export interface ConversationProps {
    viewId: string;
    rowId: string;
    width: number;
    updateCommentsTotal: (total: number) => void;
    updateLoading: (loading: boolean) => void;
    addComments?: boolean;
    showModal: boolean;
    activeTab: boolean;
}

enum ComponentState {
    LOADING,
    SAVING,
    IDLE,
}

export interface UnsavedComment {
    commentId: string;
    parentCommentId: string;
    text: string;
}

type UnsavedComments = UnsavedComment[];

const Conversation: React.FC<ConversationProps> = ({
    rowId,
    viewId,
    updateCommentsTotal,
    updateLoading,
    addComments = false,
    showModal,
    activeTab,
}) => {
    const [comments, setComments] = useState<CommentType[]>([]);
    const [unsavedReplyOrComment, setUnsavedReplyOrComment] = useState<UnsavedComments>([]);
    const [componentState, setComponentState] = useState<ComponentState>(ComponentState.IDLE);

    const languageElements = useLanguageElements();
    const dispatch = useDispatch();
    const user = useSelector((state: StoreState) => state.auth.user);
    const reportSheetId = useSelector(currentRowSheetIdSelector);

    const isDirty = useSelector(selectDetailsIsDirty);
    const setIsDirty = useCallback((target: boolean) => dispatch(Actions.setDetailsDirtyState(target)), [dispatch]);
    // format the current user object to match the User Service interface
    // can be removed after complete migration to User Service
    const formattedCurrentUser = formatCurrentUser(user);

    const fetchComments = useCallback(async () => {
        updateLoading(true);
        setIsDirty(false);
        setComponentState(ComponentState.LOADING);
        try {
            const response = await conversationClient.getRowConversationComments(viewId, rowId, reportSheetId);
            const sortedComments = response?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            setComments(sortedComments);
            updateCommentsTotal(sortedComments.length);
            setUnsavedReplyOrComment([]);
            setComponentState(ComponentState.IDLE);
        } catch (error) {
            if (
                isAxiosErrorWithResponse(error) &&
                (error.response?.status === HttpStatusCodes.FORBIDDEN || error.response?.status === HttpStatusCodes.NOT_FOUND)
            ) {
                // If a 403 (Forbidden) or 404 (Not found) error is returned, then the user no longer has access to the row. Ignore the error
                // because the data tab will throw the appropriate error message.
                return;
            }
            setComponentState(ComponentState.IDLE);
            dispatch(AppActions.Actions.setAppStageError(new Error(languageElements.GENERIC_FATAL_ERROR)));
        } finally {
            updateLoading(false);
        }
    }, [dispatch, languageElements.GENERIC_FATAL_ERROR, reportSheetId, rowId, updateCommentsTotal, updateLoading, viewId]);

    useEffect(() => {
        setUnsavedReplyOrComment([]);
        fetchComments();
    }, [rowId, reportSheetId]);

    const handleCloseModal = () => dispatch(Actions.closeModalDetailsPanel());

    const handleChangeUnsavedReplyOrComment = (unsavedComment: UnsavedComment) => {
        const { commentId, parentCommentId, text } = unsavedComment;

        setUnsavedReplyOrComment((prev) => {
            const updatedState = [...prev];
            const commentIndex = updatedState.findIndex((comment) => comment.commentId === commentId && comment.parentCommentId === parentCommentId);

            if (text.trim()) {
                if (commentIndex > -1) {
                    updatedState[commentIndex].text = text;
                } else {
                    updatedState.push({ commentId, parentCommentId, text });
                }
            } else if (commentIndex > -1) {
                updatedState.splice(commentIndex, 1);
            }
            setIsDirty(updatedState.length > 0);
            return updatedState;
        });
    };

    const handleDeleteUnsavedComments = (commentId: string, parentCommentId: string, deleteThread: boolean) => {
        setUnsavedReplyOrComment((prev) => {
            const updatedState = deleteThread
                ? prev.filter((comment) => comment.parentCommentId !== parentCommentId && comment.commentId !== commentId)
                : prev.filter((comment) => comment.parentCommentId !== parentCommentId || comment.commentId !== commentId);

            setIsDirty(updatedState.length > 0);
            return updatedState;
        });
    };

    const handleDiscardUnsavedChanges = () => {
        setComponentState(ComponentState.IDLE);
        setUnsavedReplyOrComment([]);
        handleCloseModal();
        fetchComments();
    };

    const handleSaveAllUnsavedChanges = async (): Promise<void> => {
        if (!isDirty) {
            handleCloseModal();
            return;
        }
        setComponentState(ComponentState.SAVING);
        try {
            await Promise.all(unsavedReplyOrComment.map(handleSave));
            handleDiscardUnsavedChanges();
        } catch {
            dispatch(AppActions.Actions.setAppStageError(new Error(languageElements.GENERIC_FATAL_ERROR)));
        } finally {
            fetchComments();
        }
    };

    const saveNewTopLevelComment = async (localComments: CommentType[], text: string): Promise<CommentType[]> => {
        const newComment = await conversationClient.addConversationComment(viewId, rowId, text, undefined, reportSheetId);
        const formattedNewComment = addUserObjectToComment(formattedCurrentUser, newComment);
        updateCommentsTotal(localComments.length + 1);

        return [...localComments, formattedNewComment];
    };

    const saveEditedTopLevelComment = async (localComments: CommentType[], commentId: string, text: string): Promise<CommentType[]> => {
        const editedComment = await conversationClient.updateConversationComment(viewId, rowId, text, commentId, reportSheetId);

        return localComments.map((comment) =>
            comment.id === editedComment.id ? { ...comment, text: editedComment.text, modifiedAt: editedComment.modifiedAt } : comment
        );
    };

    const saveNewReply = async (localComments: CommentType[], parentCommentId: string, text: string): Promise<CommentType[]> => {
        const newReply = await conversationClient.addConversationComment(viewId, rowId, text, parentCommentId, reportSheetId);
        const formattedNewReply = addUserObjectToComment(formattedCurrentUser, newReply);

        const updatedComments = [...localComments];
        const parentCommentIndex = updatedComments.findIndex((comment) => comment.id === parentCommentId);

        if (parentCommentIndex > -1) {
            const parentComment = updatedComments[parentCommentIndex];
            updatedComments[parentCommentIndex] = {
                ...parentComment,
                replies: [...(parentComment.replies ?? []), formattedNewReply],
            };
        }

        return updatedComments;
    };

    const updateReplies = (comment: CommentType, editedReplyId: string, editedText: string, editedDateAt: string) => {
        const updatedReplies = comment.replies?.map((reply) =>
            reply.id === editedReplyId ? { ...reply, text: editedText, modifiedAt: editedDateAt } : reply
        );

        return {
            ...comment,
            replies: updatedReplies,
        };
    };

    const saveEditedReply = async (
        localComments: CommentType[],
        parentCommentId: string,
        commentId: string,
        text: string
    ): Promise<CommentType[]> => {
        const editedReply = await conversationClient.updateConversationComment(viewId, rowId, text, commentId, reportSheetId);

        return localComments.map((comment) => {
            if (comment.id === parentCommentId) {
                return updateReplies(comment, editedReply.id, editedReply.text, editedReply.modifiedAt);
            }
            return comment;
        });
    };

    const handleSave = async (unsavedItem: UnsavedComment) => {
        const { text, commentId, parentCommentId } = unsavedItem;
        const trimmedText = text.trim();
        if (!trimmedText) {
            return;
        }
        setComponentState(ComponentState.SAVING);

        try {
            let updatedComments: CommentType[] = [];

            if (isNewComment(commentId, parentCommentId)) {
                // Adding a new top-level comment
                updatedComments = await saveNewTopLevelComment(comments, trimmedText);
            } else if (isTopLevelComment(commentId, parentCommentId)) {
                // Editing a top-level comment
                updatedComments = await saveEditedTopLevelComment(comments, commentId, trimmedText);
            } else if (isNewReply(commentId, parentCommentId)) {
                // Adding a new reply
                updatedComments = await saveNewReply(comments, parentCommentId, trimmedText);
            } else {
                // Editing an existing reply
                updatedComments = await saveEditedReply(comments, parentCommentId, commentId, trimmedText);
            }

            // Update the state with sorted comments
            setComments(sortCommentsByDate(updatedComments));
        } catch {
            setComponentState(ComponentState.IDLE);
            dispatch(AppActions.Actions.setAppStageError(new Error(languageElements.GENERIC_FATAL_ERROR)));
        } finally {
            setComponentState(ComponentState.IDLE);
        }
    };

    // Helper function to make the first reply of a deleted top-level comment the new top-level comment
    const makeFirstReplyTopLevelComment = (localComments: CommentType[], deletedComment: CommentType) => {
        if (!deletedComment.replies) {
            return;
        }

        const [firstReply, ...remainingReplies] = deletedComment.replies;
        firstReply.parentCommentId = firstReply.id;

        // Update parentCommentId for remaining replies
        remainingReplies.forEach((reply) => {
            reply.parentCommentId = firstReply.id;
        });

        // Make the first reply to a top-level comment and assign remaining replies to it
        firstReply.replies = remainingReplies;
        localComments.push(firstReply);
    };

    // Helper function to handle deletion of a top-level comment
    const handleTopLevelCommentDeletion = (localComments: CommentType[], commentId: string) => {
        const commentIndex = comments.findIndex((comment) => comment.id === commentId);

        if (commentIndex > -1) {
            const [deletedComment] = localComments.splice(commentIndex, 1);
            if (deletedComment.replies && deletedComment.replies.length > 0) {
                makeFirstReplyTopLevelComment(localComments, deletedComment);
            }
        }
    };

    // Helper function to handle deletion of a reply
    const handleReplyDeletion = (localComments: CommentType[], commentId: string, parentCommentId: string) => {
        const parentComment = localComments.find((comment) => comment.id === parentCommentId);

        if (parentComment) {
            // Filter out the deleted reply from the parent's replies
            parentComment.replies = parentComment.replies?.filter((reply) => reply.id !== commentId);
        }
    };

    // Function to handle deletion of a single comment or reply
    const handleDeleteComment = async (commentId: string, parentCommentId: string) => {
        setComponentState(ComponentState.SAVING);

        try {
            await conversationClient.deleteConversationComment(viewId, rowId, commentId, reportSheetId);

            setComments((prev) => {
                const updatedComments = [...prev];

                if (isTopLevelComment(commentId, parentCommentId)) {
                    handleTopLevelCommentDeletion(updatedComments, commentId);
                    handleDeleteUnsavedComments(commentId, parentCommentId, false);
                } else {
                    handleReplyDeletion(updatedComments, commentId, parentCommentId);
                    handleDeleteUnsavedComments(commentId, parentCommentId, false);
                }
                updateCommentsTotal(updatedComments.length);
                // Update the state with sorted comments
                return sortCommentsByDate(updatedComments);
            });
        } catch {
            setComponentState(ComponentState.IDLE);
            dispatch(AppActions.Actions.setAppStageError(new Error(languageElements.GENERIC_FATAL_ERROR)));
        } finally {
            setComponentState(ComponentState.IDLE);
        }
    };

    // Function to handle deletion of an entire comment thread
    const handleDeleteCommentThread = async (commentId: string) => {
        setComponentState(ComponentState.SAVING);
        try {
            await conversationClient.deleteConversationComment(viewId, rowId, commentId, reportSheetId, true);
            // Update the state with sorted comments
            setComments((prev) => sortCommentsByDate(prev.filter((comment) => comment.id !== commentId)));
            handleDeleteUnsavedComments(commentId, commentId, true);
        } catch {
            setComponentState(ComponentState.IDLE);
            dispatch(AppActions.Actions.setAppStageError(new Error(languageElements.GENERIC_FATAL_ERROR)));
        } finally {
            setComponentState(ComponentState.IDLE);
            updateCommentsTotal(comments.length - 1);
        }
    };

    const getUnsavedItemsForParent = (parentCommentId: string) => {
        return unsavedReplyOrComment.filter((item) => item.parentCommentId === parentCommentId);
    };

    const getTextOfUnsavedComment = (commentId: string, parentCommentId: string) => {
        return unsavedReplyOrComment.find((item) => item.commentId === commentId && item.parentCommentId === parentCommentId)?.text || '';
    };

    // Render components when the tab is active. We still want the component to execute the code above this point to determine the total number of
    // comments to show in the tab title, just not render anything.
    if (!activeTab) {
        return null;
    }

    const style = {};

    return (
        <>
            {componentState === ComponentState.LOADING && <LoadingData />}
            {componentState === ComponentState.SAVING && (
                <ModalWrapper isModalOpen={true} onClose={() => {}} hideCloseButton={true} focusTrap={false}>
                    <Spinner label={languageElements.SPINNER_SAVING_LABEL} color={Color.BLUE} size={Size.MEDIUM} />
                </ModalWrapper>
            )}
            {(componentState === ComponentState.IDLE || componentState === ComponentState.SAVING) && (
                <div className="comments-panel" style={style}>
                    <>
                        <div className="comments" style={style}>
                            {!isEmpty(comments) &&
                                comments.map((comment) => {
                                    const unsavedReplies = getUnsavedItemsForParent(comment.id);
                                    return (
                                        <Comment
                                            key={comment.id}
                                            viewId={viewId}
                                            rowId={rowId}
                                            comment={comment}
                                            unsavedValue={unsavedReplies}
                                            reportSheetId={reportSheetId}
                                            currentUser={formattedCurrentUser}
                                            newCommentsAllowed={addComments}
                                            onSave={(unsavedComment: UnsavedComment) => handleSave(unsavedComment)}
                                            onClickDeleteComment={(commentId: string, parentCommentId: string) =>
                                                handleDeleteComment(commentId, parentCommentId)
                                            }
                                            onClickDeleteCommentThread={(commentId: string) => handleDeleteCommentThread(commentId)}
                                            onChangeUnsavedReplyOrComment={(unsavedComment: UnsavedComment) =>
                                                handleChangeUnsavedReplyOrComment(unsavedComment)
                                            }
                                        />
                                    );
                                })}
                            {isEmpty(comments) && <div className="comments empty">{languageElements.DETAIL_COMMENTS_EMPTY}</div>}
                            <div style={{ float: 'left', clear: 'both' }} />
                        </div>

                        {addComments && (
                            <CommentInput
                                className={'add-comment-wrap comment-wrap'}
                                viewId={viewId}
                                rowId={rowId}
                                reportSheetId={reportSheetId}
                                currentUser={formattedCurrentUser}
                                commentId={'0'}
                                parentCommentId={'0'}
                                value={getTextOfUnsavedComment('0', '0')}
                                onSave={(unsavedItem: UnsavedComment) => handleSave(unsavedItem)}
                                onChangeUnsavedReplyOrComment={(unsavedItem: UnsavedComment) => handleChangeUnsavedReplyOrComment(unsavedItem)}
                                isEditing={false}
                            />
                        )}
                    </>

                    <Modal isOpen={showModal} onCloseRequested={handleDiscardUnsavedChanges}>
                        <UnsavedChangesModalContent
                            onCloseModalDetailsPanel={handleCloseModal}
                            onCancel={handleDiscardUnsavedChanges}
                            onSave={() => {
                                void handleSaveAllUnsavedChanges();
                            }}
                        />
                    </Modal>

                    {!showModal && isDirty && <Prompt message={languageElements.ADMIN_PANEL_PROMPT_UNSAVED_CHANGES_MESSAGE} />}
                </div>
            )}
        </>
    );
};

export default Conversation;
