import { Contact } from '../interfaces';
import { SmartsheetUser } from '../interfaces';

export const mapUserToContact = (user: SmartsheetUser): Contact => {
    return {
        id: String(user.id),
        email: user.email,
        name: user.name,
    } as Contact;
};

export const mapUsersToContacts = (users: SmartsheetUser[]): Contact[] => {
    return users.map((user: SmartsheetUser) => mapUserToContact(user));
};
