import fetchHomeDataEpic from './Epic';
import home from './Home';
import homeReducer from './Reducers';

export {
    fetchHomeDataEpic,
    homeReducer,
};
export { Actions, ActionTypes } from './Actions';
export * from './Selectors';

export default home;
