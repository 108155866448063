import * as React from 'react';
import { useState } from 'react';
import alert from '../../../../assets/images/alert/icon-info-circle-red.svg';
import deleteIcon from '../../../../assets/images/filter/delete.svg';
import copyIcon from '../../../../assets/images/filter/duplicate.svg';
import editIcon from '../../../../assets/images/filter/edit.svg';
import share from '../../../../assets/images/filter/share.svg';
import { AccessLevel } from '../../../../common/enums';
import { AutomationIds, AutomationTypes } from '../../../../common/enums/AutomationElements.enum';
import { IFilterUI } from '../../../../common/interfaces';
import ModalWrapper from '../../../../components/Modal';
import GenericModalContent from '../../../../components/Modal/Content/GenericModalContent';
import { useLanguageElements } from '../../../../language-elements/withLanguageElementsHOC';
import './FilterMenu.css';
import { MenuDivider } from './MenuDivider';
import { MenuItem } from './MenuItem';
import { MenuItemButton } from './MenuItemButton';
import { MenuList } from './MenuList';

interface ConnectedFilterMenuProps {
    filterList: IFilterUI[];
    onNewFilter: () => void;
    onFilterOff: () => void;
    onFilterSelect: (filter: IFilterUI) => void;
    onFilterEdit: (filter: IFilterUI) => void;
    onFilterDuplicate: (filter: IFilterUI) => void;
    onFilterDelete: (filterId: string) => Promise<boolean>;
    currentUserAccessLevel: AccessLevel;
}

const FilterMenu = ({
    filterList,
    onNewFilter,
    onFilterOff,
    onFilterSelect,
    onFilterEdit,
    onFilterDuplicate,
    onFilterDelete,
    currentUserAccessLevel,
}: ConnectedFilterMenuProps) => {
    const [showDeleteFilterModal, setShowDeleteFilterModal] = useState(false);
    const [selectedFilterId, setSelectedFilterId] = useState<string | undefined>(undefined);
    const languageElements = useLanguageElements();

    const confirmDeleteFilterModal = (filterId: string | undefined) => {
        setShowDeleteFilterModal(true);
        setSelectedFilterId(filterId);
    };

    const deleteFilter = () => {
        if (!selectedFilterId) {
            return;
        }

        onFilterDelete(selectedFilterId).catch((_) => {});
        setShowDeleteFilterModal(false);
        setSelectedFilterId(undefined);
    };

    const getCanUserEditAndDeleteFilter = (ownerUserId: string | null | undefined, accessLevel: AccessLevel) => {
        return ownerUserId != null || accessLevel === AccessLevel.OWNER || accessLevel === AccessLevel.ADMIN;
    };

    const getMenuItemIcon = (filter: IFilterUI): string | undefined => {
        if (!filter.isValid) {
            return alert;
        }

        return filter.ownerUserId ? undefined : share;
    };

    const renderFilterMenuItem = (filter: IFilterUI): React.ReactElement<MenuItem> => {
        const canUserEditAndDeleteFilter = getCanUserEditAndDeleteFilter(filter.ownerUserId, currentUserAccessLevel);
        const canUserDuplicateFilter = filter.isValid;

        return (
            <React.Fragment key={filter.id}>
                <MenuItem
                    className={!filter.isValid ? 'error' : ''}
                    onClick={() => onFilterSelect(filter)}
                    value={filter}
                    controlId={AutomationIds.FILTER_LIST_SELECT_FILTER}
                    controlType={AutomationTypes.FILTER_LIST_SELECT_FILTER}
                    label={filter.name}
                    icon={getMenuItemIcon(filter)}
                    inlineControls={
                        <React.Fragment>
                            {canUserEditAndDeleteFilter && (
                                <MenuItemButton
                                    controlId={AutomationIds.FILTER_LIST_EDIT_FILTER}
                                    onClick={onFilterEdit}
                                    value={filter}
                                    icon={editIcon}
                                />
                            )}
                            {canUserDuplicateFilter && (
                                <MenuItemButton
                                    controlId={AutomationIds.FILTER_LIST_DUPLICATE_FILTER}
                                    onClick={onFilterDuplicate}
                                    value={filter}
                                    icon={copyIcon}
                                />
                            )}
                            {canUserEditAndDeleteFilter && (
                                <MenuItemButton
                                    controlId={AutomationIds.FILTER_LIST_DELETE_FILTER}
                                    onClick={() => confirmDeleteFilterModal(filter.id)}
                                    value={filter.id}
                                    icon={deleteIcon}
                                />
                            )}
                        </React.Fragment>
                    }
                />
                {showDeleteFilterModal && (
                    <ModalWrapper
                        isModalOpen={showDeleteFilterModal}
                        hideCloseButton={true}
                        classes="delete-filter-modal"
                        onClose={() => setShowDeleteFilterModal(false)}
                    >
                        <GenericModalContent
                            title=""
                            message={languageElements.MODAL_DELETE_FILTER_TEXT}
                            onClickPrimaryButton={deleteFilter}
                            onClickSecondaryButton={() => setShowDeleteFilterModal(false)}
                            primaryButtonText={languageElements.MODAL_DELETE_BUTTON_TEXT}
                            secondaryButtonText={languageElements.MODAL_CANCEL_BUTTON_DEFAULT_TEXT}
                        />
                    </ModalWrapper>
                )}
            </React.Fragment>
        );
    };

    const selectedMenuItemIndex = 0;

    return (
        <MenuList defaultFocusedIndex={selectedMenuItemIndex} dataClientType={AutomationTypes.FILTER_LIST}>
            <MenuItem
                controlId={AutomationIds.FILTER_LIST_TURN_OFF_FILTER}
                controlType={AutomationTypes.FILTER_LIST_TURN_OFF_FILTER}
                labelStringId="lbl_filterMenuFilterOff"
                onClick={onFilterOff}
                label={languageElements.ADHOC_FILTER_OFF}
            />
            {filterList.map(renderFilterMenuItem)}
            <MenuDivider />
            <MenuItem
                controlId={AutomationIds.FILTER_LIST_CREATE_FILTER}
                controlType={AutomationTypes.FILTER_LIST_CREATE_FILTER}
                labelStringId="lbl_newFilter"
                onClick={onNewFilter}
                label={languageElements.ADHOC_FILTER_NEW}
            />
        </MenuList>
    );
};

export default FilterMenu;
