import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Diff } from 'utility-types';
import * as AppActions from '../../../containers/App/Actions';

export interface WithSetAppActionInProgressProps {
    onSetAppStageActionInProgress: (message: string) => void;
    onResetAppStage: () => void;
}

interface DispatchProps {
    setAppStageActionInProgress: (message: string) => AppActions.Actions;
    resetAppStage: () => AppActions.Actions;
}

const withSetAppActionInProgress = <BaseProps extends WithSetAppActionInProgressProps>(BaseComponent: React.ComponentType<BaseProps>) => {
    type HocProps = Diff<BaseProps, WithSetAppActionInProgressProps>;

    class WithSetAppActionInProgress extends React.Component<DispatchProps> {
        // Enhance component name for debugging and React-Dev-Tools
        public static displayName = `withSetAppActionInProgress(${BaseComponent.name})`;
        public render(): React.ReactNode {
            return (
                <BaseComponent
                    {...(this.props as unknown as BaseProps)}
                    onSetAppStageActionInProgress={this.props.setAppStageActionInProgress}
                    onResetAppStage={this.props.resetAppStage}
                />
            );
        }
    }

    const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
        setAppStageActionInProgress: (message: string) => dispatch(AppActions.Actions.setAppStageActionInProgress(message)),
        resetAppStage: () => dispatch(AppActions.Actions.resetAppStage()),
    });

    return connect<null, DispatchProps, HocProps>(null, mapDispatch)(WithSetAppActionInProgress);
};

export default withSetAppActionInProgress;
