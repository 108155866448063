import { Attachment } from '../containers/View/Details/Attachment/types';
import axiosInstance from './Axios.instance';

class AttachmentClient {
    public static getInstance(): AttachmentClient {
        if (!AttachmentClient.instance) {
            AttachmentClient.instance = new AttachmentClient();
        }
        return AttachmentClient.instance;
    }

    private static instance: AttachmentClient;
    private static url = '/views';

    private constructor() {}

    public async addRowAttachmentAtt(viewId: string, rowId: number, file: any, reportSheetId?: number): Promise<Attachment> {
        const formData = new FormData();
        formData.append('file', file);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'x-file-size': file.size,
            },
            params: {
                rsid: reportSheetId,
            },
        };
        const response = await axiosInstance.post(`${AttachmentClient.url}/${viewId}/rows/${rowId}/v1/attachments`, formData, config);

        return response.data;
    }
    public async getRowAttachmentAtt(viewId: string, rowId: number, attachmentId: number, reportSheetId?: number): Promise<string> {
        const response = await axiosInstance.get(`${AttachmentClient.url}/${viewId}/rows/${rowId}/v1/attachments/${attachmentId}`, {
            params: {
                rsid: reportSheetId,
            },
        });

        return response.data;
    }

    public async getRowAttachments(viewId: string, rowId: number, reportSheetId?: number): Promise<Attachment[]> {
        const response = await axiosInstance.get(`${AttachmentClient.url}/${viewId}/rows/${rowId}/attachments`, {
            params: {
                rsid: reportSheetId,
            },
        });

        return response.data;
    }
}

const attachmentClient = AttachmentClient.getInstance();
export default attachmentClient;
