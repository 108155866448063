export enum ColumnType {
    NONE = '',
    ABSTRACT_DATETIME = 'ABSTRACT_DATETIME',
    CHECKBOX = 'CHECKBOX',
    CONTACT_LIST = 'CONTACT_LIST',
    DATE = 'DATE',
    DATETIME = 'DATETIME',
    DURATION = 'DURATION',
    MULTI_CONTACT_LIST = 'MULTI_CONTACT_LIST',
    MULTI_PICKLIST = 'MULTI_PICKLIST',
    PICKLIST = 'PICKLIST',
    PREDECESSOR = 'PREDECESSOR',
    TEXT_NUMBER = 'TEXT_NUMBER',
    TEXTNUMBER = 'TEXTNUMBER',
    CONTACT = 'CONTACT',
    RYG = 'RYG',
    HARVEY = 'HARVEY',
    PRIORITY = 'PRIORITY',
    STAR = 'STAR',
    FLAG = 'FLAG',
    SYS_INSERTBY = 'SYS_INSERTBY',
    SYS_INSERTDATE = 'SYS_INSERTDATE',
    SYS_MODIFYBY = 'SYS_MODIFYBY',
    SYS_MODIFYDATE = 'SYS_MODIFYDATE',
    SYS_UNIQUEID = 'SYS_UNIQUEID',
    ABSTRACTDATETIME = 'ABSTRACTDATETIME',
    PRIORITYHML = 'PRIORITYHML',
    DECISIONSYMBOLS = 'DECISIONSYMBOLS',
    DECISIONSHAPES = 'DECISIONSHAPES',
    DECISIONVCR = 'DECISIONVCR',
    RYGB = 'RYGB',
    RYGG = 'RYGG',
    WEATHER = 'WEATHER',
    PROGRESS5 = 'PROGRESS5',
    ARROWS3 = 'ARROWS3',
    ARROWS4 = 'ARROWS4',
    ARROWS5 = 'ARROWS5',
    DIRECTIONS3 = 'DIRECTIONS3',
    DIRECTIONS4 = 'DIRECTIONS4',
    DIFFICULTY4 = 'DIFFICULTY4',
    SIGNAL5 = 'SIGNAL5',
    STAR6 = 'STAR6',
    HEART6 = 'HEART6',
    MONEY6 = 'MONEY6',
    EFFORT6 = 'EFFORT6',
    PAIN6 = 'PAIN6',
    MULTICONTACT = 'MULTICONTACT',
    MULTIPICKLIST = 'MULTIPICKLIST',
}
