// Used in AtMentions.tsx. Copied from app-core: dev2/web/javascript/legacyApp/src/app1/Person.ts

import { ContactDto } from '../../../../common/interfaces/ContactDto.interface';
import { hashCode, StringUtil } from './AtMentionsUtils';

/**
 * Person represents a person and their associated profile data. Persons can include Smartsheet users,
 * personal contacts, e-mail message recipients, or simply values entered into a contact list column.
 */
export class Person {
    public static readonly AvatarShape = {
        SQUARE: 0,
        CIRCLE: 1,
    };

    public static AvatarNodes = {};

    public static AVATAR_DEFAULT_SIZE = 30;
    public static AVATAR_RELATIVE_SIZE = -1;
    public static AVATAR_RELATIVE_RATIO = 1.5; // needs to stay in sync with $avatarRelativeSize variable in common.scss

    // Standard sizes
    public static AVATAR_SMALL = 30;
    public static AVATAR_MEDIUM = 60;
    public static AVATAR_LARGE = 120;

    // One-off sizes
    public static AVATAR_DESKTOP = 26;
    public static AVATAR_LODESTAR = 32;
    public static AVATAR_SKYPE = 40;

    public static AVATAR_COLORS = [
        '#993D3D',
        '#CC5151',
        '#FF7F7F',
        '#995C3D',
        '#CC7A51',
        '#FFAA7F',
        '#995C1E',
        '#F38B24',
        '#FFAC59',
        '#99701F',
        '#D99B20',
        '#FFBF3F',
        '#666628',
        '#99993D',
        '#3D6628',
        '#5C993D',
        '#88CC66',
        '#28663D',
        '#3D995C',
        '#66CC88',
        '#286666',
        '#3D9999',
        '#66CCCC',
        '#285166',
        '#3D7A99',
        '#66AACC',
        '#553F7F',
        '#7759B3',
        '#A88AE6',
        '#7F3F7F',
        '#B359B3',
        '#E68AE6',
        '#7F3366',
        '#B3478F',
        '#E673BF',
        '#515C66',
        '#7A8A99',
    ];

    /**
     * Construct initials based on displayName or emailAddress
     *
     * @param contact
     * @param size - Size of avatar
     * @returns person's initials
     */
    public static getInitials(contact: ContactDto | undefined, size: number = Person.AVATAR_DEFAULT_SIZE): string {
        let names;
        let initials = '';
        if (contact?.name) {
            initials = Person.getDisplayNameInitials(contact.name, size >= Person.AVATAR_SMALL);
        } else if (contact?.email) {
            names = contact.email.split('@');
            if (names[0].length === 1) {
                initials = names[0];
            } else if (names[0].length > 1) {
                initials = names[0].substring(0, 2);
                if (!StringUtil.isAstralCharacter(initials)) {
                    initials = initials.charAt(0);
                }
            }
        }
        return initials;
    }

    public static getDisplayNameInitials(displayName: string, showTwoInitials: boolean): string {
        const names = displayName.split(' ');
        let firstInitial = names[0].substring(0, 2);
        let lastInitial = '';
        if (!StringUtil.isAstralCharacter(firstInitial)) {
            firstInitial = firstInitial.charAt(0);
        }
        if (names.length > 1 && showTwoInitials) {
            lastInitial = names[1].substring(0, 2);
            if (!StringUtil.isAstralCharacter(lastInitial)) {
                lastInitial = lastInitial.charAt(0);
            }
        }
        return firstInitial + lastInitial;
    }

    /**
     * Computes background color of avatar nodes by emailAddress as a parameter
     *
     * @returns background color hex code as a string
     */
    public static getNodeBackgroundColorByEmail(email: string): string {
        const colorIndex = email ? Math.abs(hashCode(email)) % Person.AVATAR_COLORS.length : 0;
        return Person.AVATAR_COLORS[colorIndex];
    }
}
