import * as dv from '../../common/interfaces';
import { AxiosError } from 'axios';
import { ActionsUnion } from '../../store';
import { createAction } from '../../store/Utils/CreateAction';

export enum ActionTypes {
    RESET_VIEW_STATE = 'RESET_VIEW_STATE',

    // View data
    FETCH_VIEW_DATA = 'FETCH_VIEW_DATA',
    FETCH_VIEW_DATA_ALL = 'FETCH_VIEW_DATA_ALL',
    STORE_VIEW_DATA = 'STORE_VIEW_DATA',
    FETCHING_ERROR_VIEW_DATA = 'FETCHING_ERROR_VIEW_DATA',

    // Container links
    FETCH_CONTAINER_LINKS = 'FETCH_CONTAINER_LINKS',
    STORE_CONTAINER_LINKS = 'STORE_CONTAINER_LINKS',
    FETCHING_ERROR_CONTAINER_LINKS = 'FETCH_ERROR_CONTAINER_LINKS',

    // Grid row
    FETCH_GRID_ROW = 'FETCH_GRID_ROW',
    UPDATE_GRID_ROW = 'UPDATE_GRID_ROW',
    ADD_GRID_ROW = 'ADD_GRID_ROW',
    REMOVE_GRID_ROW = 'REMOVE_GRID_ROW',
    FETCHING_ERROR_GRID_ROW = 'FETCHING_ERROR_GRID_ROW',

    // Config
    FETCH_VIEW_CONFIG = 'FETCH_VIEW_CONFIG',
    STORE_CONFIG = 'STORE_CONFIG',
    FETCHING_ERROR_VIEW_CONFIG = 'FETCHING_ERROR_VIEW_CONFIG',
}

export const Actions = {
    // Action to reset view state
    resetViewState: () => createAction(ActionTypes.RESET_VIEW_STATE),

    // View data
    fetchViewData: (viewId: string, pagination: boolean) => createAction(ActionTypes.FETCH_VIEW_DATA, { viewId, pagination }),
    fetchViewDataAll: (viewId: string, totalRowCount: number, viewData: dv.ViewData) =>
        createAction(ActionTypes.FETCH_VIEW_DATA_ALL, { viewId, totalRowCount, viewData }),
    storeViewData: (viewData: dv.ViewData) => createAction(ActionTypes.STORE_VIEW_DATA, { viewData }),
    fetchingErrorViewData: (error: AxiosError) => createAction(ActionTypes.FETCHING_ERROR_VIEW_DATA, { error }),

    // Container links
    fetchContainerLinks: (viewId: string, containerIds: number[]) => createAction(ActionTypes.FETCH_CONTAINER_LINKS, { viewId, containerIds }),
    storeContainerLinks: (viewId: string, containerLinkMap: Map<number, string>) =>
        createAction(ActionTypes.STORE_CONTAINER_LINKS, { viewId, containerLinkMap }),
    fetchingErrorContainerLinks: (viewId: string, containerIds: number[]) =>
        createAction(ActionTypes.FETCHING_ERROR_CONTAINER_LINKS, { viewId, containerIds }),

    // Grid row
    fetchGridRow: (viewId: string, rowId: string, isUpdate: boolean, startTimeForSyncProcess: number) =>
        createAction(ActionTypes.FETCH_GRID_ROW, { viewId, rowId, isUpdate, startTimeForSyncProcess }),
    addGridRow: (viewId: string, row: dv.Row) => createAction(ActionTypes.ADD_GRID_ROW, { viewId, row }),
    updateGridRow: (viewId: string, row: dv.Row) => createAction(ActionTypes.UPDATE_GRID_ROW, { viewId, row }),
    removeGridRow: (viewId: string, rowId: string) => createAction(ActionTypes.REMOVE_GRID_ROW, { viewId, rowId }),
    fetchingErrorGridRow: (viewId: string, rowId: string) => createAction(ActionTypes.FETCHING_ERROR_GRID_ROW, { viewId, rowId }),

    // Config
    fetchConfig: (viewId: string) => createAction(ActionTypes.FETCH_VIEW_CONFIG, { viewId }),
    storeConfig: (config: dv.ViewWithOwnerAndUserDetails) => createAction(ActionTypes.STORE_CONFIG, { config }),
    fetchingErrorConfig: (error: AxiosError) => createAction(ActionTypes.FETCHING_ERROR_VIEW_CONFIG, { error }),
};

export type Actions = ActionsUnion<typeof Actions>;
