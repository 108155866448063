import { AccessLevel, AccessType } from '../enums';
import { HomeView } from '../interfaces';
import { HomeFilter } from '../enums/HomeFilter';

export const HomeViewFilter: { [key in HomeFilter]: (view: HomeView) => boolean } = {
    [HomeFilter.ALL]: (view: HomeView) => true,
    [HomeFilter.OWNED_BY_ME]: (view: HomeView) => view.accessLevel === AccessLevel.OWNER,
    [HomeFilter.SHARED_WITH_ME]: (view: HomeView) => {
        if (view.accessLevel === AccessLevel.OWNER || !view.accessType) {
            return false;
        }
        return view.accessType.some((accessType) => accessType === AccessType.INDIVIDUAL);
    },
    [HomeFilter.SHARED_WITH_GROUP]: (view: HomeView) => {
        const accessTypes = new Set(view.accessType);
        return accessTypes.has(AccessType.GROUP) || accessTypes.has(AccessType.DOMAIN);
    },
};
