import * as React from 'react';
import { ErrorInfo, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { HttpStatusCodes } from '../../common/enums';
import { UserAnalyticsGlobalContext } from '../../common/metrics/UserAnalyticsGlobalContext';
import { getUrlBarHeight } from '../../common/utils';
import BulletinsContainer from '../../components/Bulletins/BulletinsContainer';
import HamburgerMenu from '../../components/HamburgerMenu/HamburgerMenu';
import Header from '../../components/Header';
import Main from '../../components/Main';
import MainFallback from '../../components/Main/MainFallback';
import { TOOLTIP_ROOT_ID } from '../../components/TooltipLegacy';
import featureFlagsClient from '../../http-clients/FeatureFlagsClient';
import licensingClient from '../../http-clients/LicensingClient';
import { loggingClient } from '../../http-clients/Logging.client';
import { changeLanguage } from '../../language-elements/LanguageElements';
import { userService } from '../../services/User.service';
import { Actions as ActionsAuth } from '../Auth/Actions';
import { User } from '../Auth/types';
import { isAuthenticatedSelector } from '../Auth/Selectors';
import LeftPanelContainer from '../LeftPanel/LeftPanelContainer';
import { Actions as ActionsApp } from './Actions';
import { createUrlPath, handleAppErrors } from './AppUtils';
import './Media.css';
import './App.css';
import { iframeStatusSelector } from './Selectors';

const App: React.FC = () => {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(isAuthenticatedSelector);
    const inIframe = useSelector(iframeStatusSelector);

    const loadFeatureFlags = async () => {
        const featureFlagsResponse = await featureFlagsClient.get();
        dispatch(ActionsApp.storeFeatureFlags(featureFlagsResponse));
    };

    const loadUserData = async (): Promise<void> => {
        // Since the DV session cookie is HttpOnly, we need to make a request to the DV api to see if the user already has a valid DV session.
        try {
            // Issue a GET request to an auth endpoint to check the user's session information.
            // If the endpoint returns a 401 Unauthorized, the axios library will convert it and throw an error.
            const user: User = { ...(await userService.fetchCurrentUser()) };

            const shortLocale = user.locale ? user.locale.slice(0, 2) : undefined;
            if (shortLocale) {
                await changeLanguage(shortLocale);
                loggingClient.logInfo({
                    file: 'App.tsx',
                    message: 'Set user locale',
                    newLocale: shortLocale,
                });
            }

            // Get the user's eligibility status. This includes a status of ALREADY_PURCHASED if the user has a license.
            user.eligibility = await licensingClient.getEligibility();

            // Set the user details used throughout the user's session for user analytics.
            // If a user had previously been logged in, their user details would be overridden
            UserAnalyticsGlobalContext.setUser(user);

            // Load the feature flags for the user
            await loadFeatureFlags();

            // If no error is thrown, then the user's session cookies were valid, and we can consider them logged into the application.
            dispatch(ActionsAuth.logIn(user));
        } catch (error) {
            if (error.response?.status === HttpStatusCodes.UNAUTHORIZED) {
                // Redirect to SMAR log in screen as the user is unauthenticated
                const encodedPath: string = encodeURIComponent(location.pathname);

                // Encode query params with the `?` excluded
                const encodedQueryParams = encodeURIComponent(location.search.substring(1));

                window.location.replace(`/b/home?dlp=${encodedPath}&dlq=${encodedQueryParams}`);
            }
        }
    };

    useEffect(() => {
        window.onerror = handleAppErrors;
    }, []);

    // the useEffect hook is used to load the user data and feature flags when the app is first loaded only
    useEffect(() => {
        if (!isAuthenticated) {
            loadUserData();
        }
    }, [isAuthenticated, loadUserData]);

    const handleError = (error: Error, info: ErrorInfo) => {
        loggingClient.logError('App.tsx', 'handleError', error, { componentStack: info.componentStack });
    };

    if (!isAuthenticated) {
        return null;
    }
    return (
        <BrowserRouter basename={createUrlPath()}>
            <ErrorBoundary FallbackComponent={MainFallback} onError={handleError}>
                <BulletinsContainer />
                <div id="control-main-height" />
                <div className="main" style={{ height: `calc(100vh - ${getUrlBarHeight()}px)` }}>
                    {!inIframe && (
                        <>
                            <HamburgerMenu />
                            <LeftPanelContainer />
                        </>
                    )}

                    <div className="main-content">
                        {!inIframe && <Header />}
                        <Main />
                    </div>
                </div>
                {/* This is necessary to render the Tooltip component outside of its parent */}
                <div id={TOOLTIP_ROOT_ID} />
            </ErrorBoundary>
        </BrowserRouter>
    );
};

export default App;
