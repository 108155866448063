/** A horizontal divider for MenuList */

import * as React from 'react';

export interface MenuDividerProps {

    // Should there be a horizontal line below the text? Defaults to true
    hasHorizontalLine?: boolean;
}

export const MenuDivider = (props: MenuDividerProps) => {
    const {
        hasHorizontalLine = true,
    } = props;

    return (
        <div className={'menu-divider'}>
            {hasHorizontalLine && <div className="dividerLine" />}
        </div>
    );
};
