import { AccessLevel } from '../../../common/enums';
import { ViewOwner } from '../../../common/interfaces';
import * as React from 'react';
import descriptionIcon from '../../../assets/images/group-24.svg';
import '../../../assets/styles/buttons.css';
import { AutomationIds } from '../../../common/enums/AutomationElements.enum';
import { SettingsLinkButton } from '../../../components/Buttons';
import { WithDataClientProps } from '../../../components/hoc/WithDataClient';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';

interface Props extends WithDataClientProps {
    viewDescription?: string;
    viewOwner: ViewOwner;
    viewId: string;
    isLicensedUser: boolean;
    currentUserAccessLevel: AccessLevel;
    dataClientId?: AutomationIds;
}

const ControlId = {
    ADD: 'vda-1',
    CREATED_BY: 'vda-2',
};

const Description: React.FC<Props> = (props) => {
    const languageElements = useLanguageElements();
    return (
        <div className="view-description" id="basicViewDescription">
            <div className="view-description-header">
                <h3>{languageElements.DETAILS_PANEL_DESCRIPTION}</h3>
            </div>
            <div className="view-description-content" data-client-id={props.dataClientId}>
                {props.viewDescription && <pre>{props.viewDescription}</pre>}
                {!props.viewDescription &&
                props.isLicensedUser &&
                (props.currentUserAccessLevel === AccessLevel.OWNER || props.currentUserAccessLevel === AccessLevel.ADMIN) ? (
                    <div className={'view-description-empty-state'}>
                        <img src={descriptionIcon} />
                        <p className={'title'}>{languageElements.VIEW_DESCRIPTION_EMPTY_STATE_TITLE}</p>
                        <p>{languageElements.VIEW_DESCRIPTION_EMPTY_STATE_DESCRIPTION}</p>
                        <SettingsLinkButton
                            route={`/views/${props.viewId}/admin/basic`}
                            controlId={ControlId.CREATED_BY}
                            className={'btn btn-primary'}
                            text={languageElements.VIEW_DESCRIPTION_EMPTY_STATE_BUTTON}
                        />
                        <small className={'view-creator-permitted'} data-client-id={ControlId.CREATED_BY}>
                            {`${languageElements.DETAILS_PANEL_VIEW_CREATOR} ${props.viewOwner.email}`}
                        </small>
                    </div>
                ) : (
                    <small className={'view-creator-restricted'} data-client-id={ControlId.CREATED_BY}>
                        {`${languageElements.DETAILS_PANEL_VIEW_CREATOR} ${props.viewOwner.email}`}
                    </small>
                )}
            </div>
        </div>
    );
};

export default Description;
