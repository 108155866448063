import { useEffect, useRef } from 'react';

export const useInitializeComponent = (rowId: string | undefined, isNewSubmission: boolean, funcToInitialize: (mounting: boolean) => void) => {
    const currentRowIdRef = useRef<string | undefined>(rowId);
    const componentMountRef = useRef(false);

    // Determine if the component needs to re-initialize if the rowId or isNewSubmission changes
    useEffect(() => {
        if (!componentMountRef.current) {
            // Initialize the component on mount
            componentMountRef.current = true;
            funcToInitialize(true);
            return;
        }

        if (currentRowIdRef.current !== rowId) {
            currentRowIdRef.current = rowId;

            // Skip initializing again when isNewSubmission is true. This is because new row submissions that are saved, the row ID will
            // change from undefined to an actual row ID.
            if (!isNewSubmission) {
                funcToInitialize(false);
                return;
            }
        }
    }, [rowId, isNewSubmission, funcToInitialize]);
};
