import { VIEW_NAME_MAX_LENGTH } from '../../../common/constants';
import { ColumnType, ViewSourceType } from '../../../common/enums';
import { Column, ViewConfig, ViewSourceMetaData, ViewWithOwnerAndUserDetails } from '../../../common/interfaces';
import { fromJS } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { Option } from 'react-select';
import { createSelector, createStructuredSelector } from 'reselect';
import { AutomationIds, AutomationTypes } from '../../../common/enums/AutomationElements.enum';
import { LanguageElementsProp, withLanguageElementsHOC } from '../../../language-elements/withLanguageElementsHOC';
import { AdminPanelComponentProps } from '../interfaces/AdminPanelComponentProps.interface';
import { AdminPanelOnChange } from '../interfaces/AdminPanelOnChange.interface';
import { makeSelectViewSourceMetaData } from '../Selectors';
import './AdminPanelBasic.css';
import BasicDescription from './BasicDescription';
import { BasicDownloadOption } from './BasicDownladOption';
import BasicLinkOut from './BasicLinkOut';
import BasicName from './BasicName';
import BasicNewItemSubmission from './BasicNewItemSubmission';
import BasicSheetFilter, { NO_RESTRICTION_SHEET_FILTER_VALUE } from './BasicSheetFilter';
import BasicUserColumn from './BasicUserColumn';

interface OwnProps extends AdminPanelComponentProps {
    config: ViewConfig;
    view: ViewWithOwnerAndUserDetails;
}

interface StateProps {
    viewSourceMetaData: ViewSourceMetaData;
}

export type AdminPanelBasicProps = StateProps & OwnProps;

interface State {
    name: string | undefined;
    description: string | undefined;
    userColumnData?: Column;
    userCanSubmit: boolean;
    sheetFilterId?: string | null;
    selectedSheetFilterDeleted: boolean;
    intakeSheetId?: number | null;
    isIntakeSheetValid: boolean;
    userCanDownload: boolean;
}

export class AdminPanelBasic extends React.Component<AdminPanelBasicProps & LanguageElementsProp, State> {
    public state: State;
    private readonly dropDownColumnOptions: Column[];

    private readonly NO_RESTRICTION_CURRENT_USER_OPTION: Column;
    private readonly FILTER_COLUMNS: string[];

    public constructor(props: AdminPanelBasicProps & LanguageElementsProp) {
        super(props);

        this.NO_RESTRICTION_CURRENT_USER_OPTION = {
            id: 0,
            title: this.props.languageElements.ADMIN_PANEL_BASIC_USER_COLUMN_NO_RESTRICTION,
            type: ColumnType.NONE,
        };
        this.FILTER_COLUMNS = [ColumnType.CONTACT_LIST, ColumnType.MULTI_CONTACT_LIST];

        this.dropDownColumnOptions = this.mapUserDropDownColumnsToOptions(props.viewSourceMetaData.columns);
        this.state = this.getInitialState();

        if (!this.state.isIntakeSheetValid) {
            this.onContainerChange();
        }
    }

    public render(): React.ReactNode {
        return (
            <div className="adminPanelBasic">
                <BasicName
                    dataClientId={AutomationIds.ADMIN_PANEL_BASIC_NAME}
                    onNameChange={(e) => this.handleOnNameChange(e)}
                    viewName={this.state.name!}
                />
                <BasicDescription
                    dataClientId={AutomationIds.ADMIN_PANEL_BASIC_DESCRIPTION}
                    onDescriptionChange={this.handleOnDescriptionChange}
                    viewDescription={this.state.description!}
                />
                <BasicLinkOut
                    dataClientId={AutomationIds.ADMIN_PANEL_BASIC_VIEWSOURCELINK}
                    title={this.props.languageElements.ADMIN_PANEL_BASIC_VIEW_SOURCE}
                    sourceLink={this.props.viewSourceMetaData.sourceLink}
                    sourceDescription={this.props.viewSourceMetaData.sourceName}
                    idName="basicNewIntakeSheet"
                />
                <BasicUserColumn
                    dataClientId={AutomationIds.ADMIN_PANEL_BASIC_USER_COLUMN}
                    dropDownColumns={this.dropDownColumnOptions}
                    userColumnData={this.state.userColumnData}
                    onUserColumnChange={this.handleOnUserColumnChange}
                />
                {this.props.view.viewSourceType === ViewSourceType.SHEET && (
                    <BasicSheetFilter
                        dataClientId={AutomationIds.ADMIN_PANEL_BASIC_SHEET_FILTER}
                        sheetFilters={this.props.viewSourceMetaData.sheetFilters}
                        selectedOption={this.state.sheetFilterId || '0'}
                        onSheetFilterChange={this.handleOnSheetFilterChange}
                        sheetFilterDeleted={this.state.selectedSheetFilterDeleted}
                    />
                )}
                <BasicNewItemSubmission
                    userCanSubmit={this.state.userCanSubmit}
                    intakeSheetId={this.state.intakeSheetId}
                    hasValidIntakeSheet={this.state.isIntakeSheetValid}
                    sourceSheets={this.props.viewSourceMetaData.viewSourceSheets}
                    requiresIntakeSheet={this.props.viewSourceMetaData.viewSourceType !== ViewSourceType.SHEET}
                    onIntakeSheetChange={(selectedOption) => this.handleOnReportIntakeSheetChange(selectedOption)}
                    onNewItemSubmissionChange={() => this.handleOnNewItemSubmissionChange()}
                />
                <BasicDownloadOption
                    userCanDownload={this.state.userCanDownload}
                    onDownloadOptionChange={() => this.handleOnDownloadOptionChange()}
                    dataClientType={AutomationTypes.BASIC_DOWNLOAD_OPTION}
                    className="download-option"
                    radioName="download"
                />
            </div>
        );
    }

    public componentDidUpdate(prevProps: AdminPanelBasicProps): void {
        if ((this.props.cancelChanges && this.props.cancelChanges !== prevProps.cancelChanges) || this.props.viewId !== prevProps.viewId) {
            this.mapUserDropDownColumnsToOptions();
            this.setState(this.getInitialState());
        }
    }

    public handleOnNameChange = (event: React.FormEvent<HTMLInputElement>): void => {
        const viewNameFromInput = event.currentTarget.value;

        this.setState(
            {
                name: viewNameFromInput,
            },
            this.onContainerChange
        );
    };

    public handleOnDescriptionChange = (event: React.FormEvent<HTMLTextAreaElement>): void => {
        this.setState(
            {
                description: event.currentTarget.value,
            },
            this.onContainerChange
        );
    };

    public handleOnUserColumnChange = (selectedOption: Option<number>): void => {
        this.setState(
            {
                userColumnData: this.dropDownColumnOptions.find((column) => column.id === selectedOption.value!),
            },
            this.onContainerChange
        );
    };

    public handleOnSheetFilterChange = (option: Option<string>): void => {
        this.setState(
            {
                // Set default option to null
                sheetFilterId: option.value === NO_RESTRICTION_SHEET_FILTER_VALUE ? null : option.value,
                // The user cannot select a deleted filter
                selectedSheetFilterDeleted: false,
            },
            this.onContainerChange
        );
    };

    public handleOnNewItemSubmissionChange = (): void => {
        const intakeSheetId = this.getIntakeSheetId(!this.state.userCanSubmit);
        const userCanSubmit = !this.state.userCanSubmit;
        this.setState(
            {
                userCanSubmit,
                intakeSheetId,
                isIntakeSheetValid: this.getIsIntakeSheetValid(intakeSheetId),
            },
            this.onContainerChange
        );
    };

    public handleOnDownloadOptionChange = (): void => {
        const userCanDownload = !this.state.userCanDownload;
        this.setState(
            {
                userCanDownload,
            },
            this.onContainerChange
        );
    };

    public handleOnReportIntakeSheetChange = (selectedOption: Option | null): void => {
        this.setState(
            {
                intakeSheetId: selectedOption != null ? Number(selectedOption!.value) : undefined,
                isIntakeSheetValid: this.props.viewSourceMetaData.viewSourceType === ViewSourceType.SHEET || selectedOption != null,
            },
            this.onContainerChange
        );
    };

    private isContainerValid = (): boolean => {
        const viewNameLength = this.state.name!.length;

        return viewNameLength > 0 && viewNameLength <= VIEW_NAME_MAX_LENGTH && this.state.isIntakeSheetValid;
    };

    private onContainerChange = (): void => {
        // If no user column selected, set id to undefined
        const userColumnId = !this.state.userColumnData || !this.state.userColumnData.id ? null : this.state.userColumnData.id;

        const value: AdminPanelOnChange = {
            isValid: this.isContainerValid(),
            data: fromJS({
                name: this.state.name,
                description: this.state.description,
                config: {
                    userColumnId,
                    intakeSheetId: this.state.intakeSheetId,
                    sheetFilterId: this.state.selectedSheetFilterDeleted ? undefined : this.state.sheetFilterId,
                    canDownload: this.state.userCanDownload,
                },
            }),
        };
        this.props.onChange(value);
    };

    private mapUserDropDownColumnsToOptions = (dropDownColumns?: Column[]): Column[] => {
        // Populate default value
        const options: Column[] = [
            {
                id: this.NO_RESTRICTION_CURRENT_USER_OPTION.id,
                title: this.NO_RESTRICTION_CURRENT_USER_OPTION.title,
                type: this.NO_RESTRICTION_CURRENT_USER_OPTION.type,
            },
        ];

        if (dropDownColumns) {
            dropDownColumns.forEach((column: Column) => {
                if (this.FILTER_COLUMNS.includes(column.type)) {
                    options.push(column);
                }
            });
        }

        return options;
    };

    private getUserColumnData = (): Column | undefined => {
        const userColumnId = this.props.config.userColumnId;
        let userColumnData: Column | undefined = this.dropDownColumnOptions.find((column) => userColumnId === column.id);

        // Handle deleted filter
        if (userColumnId && !userColumnData) {
            userColumnData = { id: userColumnId, title: this.props.languageElements.ADMIN_PANEL_BASIC_USER_COLUMN_DELETED, type: ColumnType.NONE };
            this.dropDownColumnOptions.push(userColumnData);
        }

        return userColumnData ? userColumnData : undefined;
    };

    // Determine whether the selected filter exists returns false if selected filter is undefined
    private selectedSheetFilterDeleted = (): boolean => {
        const selectedSheetFilterId = this.props.config.sheetFilterId;

        return selectedSheetFilterId
            ? Boolean(!this.props.viewSourceMetaData.sheetFilters?.find((option) => selectedSheetFilterId === option.id))
            : false;
    };

    private getInitialState = (): State => {
        return {
            userColumnData: this.getUserColumnData(),
            sheetFilterId: this.props.config.sheetFilterId,
            selectedSheetFilterDeleted: this.selectedSheetFilterDeleted(),
            userCanSubmit: Boolean(this.props.config.intakeSheetId),
            intakeSheetId: this.props.config.intakeSheetId,
            name: this.props.view.name,
            description: this.props.view.description,
            isIntakeSheetValid: this.getIsIntakeSheetValid(this.props.config.intakeSheetId), // set this to true the first time since the component
            userCanDownload: this.props.config.canDownload || false,
        };
    };

    private getIsIntakeSheetValid(intakeSheetId?: number | null): boolean {
        return (
            !Boolean(intakeSheetId) ||
            this.props.viewSourceMetaData.viewSourceType === ViewSourceType.SHEET ||
            (this.props.viewSourceMetaData.viewSourceType === ViewSourceType.REPORT &&
                [...(this.props.viewSourceMetaData.viewSourceSheets || [])].some((value) => value.id === intakeSheetId))
        );
    }

    private getIntakeSheetId(userCanSubmit: boolean): number | null | undefined {
        if (!userCanSubmit) {
            return null;
        }

        if (this.props.viewSourceMetaData.viewSourceType === ViewSourceType.SHEET) {
            return this.props.viewSourceMetaData.id;
        }

        if (this.state.intakeSheetId == null) {
            if (this.props.config.intakeSheetId != null) {
                return this.props.config.intakeSheetId;
            }

            const firstSourceSheet = [...(this.props.viewSourceMetaData.viewSourceSheets || [])].slice(0);
            return Boolean(firstSourceSheet) && firstSourceSheet.length ? firstSourceSheet[0].id : undefined;
        }

        return this.state.intakeSheetId;
    }
}

const mapState = createStructuredSelector({
    viewSourceMetaData: createSelector(makeSelectViewSourceMetaData(), (stateViewSourceMetaData) => stateViewSourceMetaData!),
});

export default withLanguageElementsHOC(connect<StateProps>(mapState)(AdminPanelBasic));
