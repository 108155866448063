export const areArraysEqual = (array1: string[], array2: string[]): boolean => {
    if (!Array.isArray(array1) || !Array.isArray(array2)) {
        return false;
    }

    if (array1.length !== array2.length) {
        return false;
    }

    const sortedArray1 = array1.sort();
    const sortedArray2 = array2.sort();

    return sortedArray1.every((element, index) => sortedArray2[index] === element);
};

export const isEmpty = (array: any[]) => !array || array.length < 1;
