import { datadogRum } from '@datadog/browser-rum';
import { User, View } from '../interfaces';

export class UserAnalyticsGlobalContext {
    /**
     * Set the user ID (SMAR user profile ID) and org ID (SMAR account ID) of the DataDog RUM global context. NOTE: The function should be called when
     * the user signs into DV, associating all RUM event with the user attributes
     */
    public static setUser(user: User): void {
        const shortLocale = user.locale?.slice(0, 2);
        if (user.smartsheetId != null) {
            datadogRum.setUser({
                id: user.smartsheetId.toString(),
                locale: shortLocale,
            });
        }

        if (user.smartsheetAccountId != null) {
            datadogRum.setGlobalContext({
                orgId: user.smartsheetAccountId,
            });
        }
    }

    /**
     * Remove user data from the DataDog RUM global context.
     */
    public static removeUser(): void {
        datadogRum.removeGlobalContextProperty('orgId');
        datadogRum.setUser({});
    }

    /**
     * Add view data to the DataDog RUM global context. This associating all RUM event after this function call to include the view data as custom
     * attributes.
     *
     * IMPORTANT: This function should be called when the user starts interaction with a single view, see ViewContatiner.ts for an example. Once the
     * user finishes interacting with the view, then removeViewData function MUST be called
     */
    public static addViewData(view: View): void {
        datadogRum.setGlobalContextProperty('view', {
            id: view.id,
            viewSourceType: view.viewSourceType,
            viewSourceId: view.viewSourceId,
        });
    }

    public static addViewDataFromId(id: string): void {
        datadogRum.setGlobalContextProperty('view', {
            id,
            viewSourceType: undefined,
            viewSourceId: undefined,
        });
    }

    /**
     * Remove view data from the DataDog RUM global context.
     *
     * IMPORTANT: This function MUST be called when the addViewData function was previously called
     * at the start of a user interaction with a single view, see ViewContatiner.ts for an example. Not calling this function would result in all
     * subsequent DataDog RUM events that are not related to a view, having the view data as custom attributes.
     */
    public static removeViewData(): void {
        datadogRum.removeGlobalContextProperty('view');
    }

    /**
     * Remove all data from the DataDog RUM global context. IMPORTANT: This function MUST be called when the user logs out of DV
     */
    public static removeAll(): void {
        UserAnalyticsGlobalContext.removeViewData();
        UserAnalyticsGlobalContext.removeUser();
    }
}
