import { Avatar } from '@smartsheet/lodestar-core';
import classnames from 'classnames';
import * as React from 'react';
import { AutomationTypes } from '../../../common/enums/AutomationElements.enum';
import { Contact, SmartsheetUser } from '../../../common/interfaces';
import { getUserInitials } from '../../../common/utils/GetUserInitials';
import { CellRenderer } from '../CellRenderer';

interface Props {
    contact: Contact | SmartsheetUser;
    className?: string;
    showNameAndEmail?: boolean;
}

export const CellRendererUser: React.FC<Props> = (props) => {
    const userInitials: string = getUserInitials(props.contact.name || props.contact.email || '');
    const tooltipContent = (
        <>
            <span className="tooltip-title">{props.contact.name}</span>
            <span className="tooltip-subtitle">{props.contact.email}</span>
        </>
    );
    return (
        <CellRenderer tooltip={props.showNameAndEmail ? undefined : tooltipContent} className={classnames('single-contact', props.className)}>
            <Avatar
                size="medium"
                clientId={AutomationTypes.CONTACT_ICON}
                initials={userInitials}
                email={props.contact.email}
                src={'profileImage' in props.contact && props.contact.profileImage!.url ? props.contact.profileImage!.url! : ''}
                aria-label="Profile Image"
            />
            <div className="name-and-email" data-client-type={AutomationTypes.CONTACT_NAME_AND_EMAIL}>
                {props.showNameAndEmail ? (
                    <>
                        <label className="option-label">{props.contact.name}</label>
                        <label className="option-sub-label">{props.contact.email}</label>
                    </>
                ) : (
                    <label className="option-label">{props.contact.name ? props.contact.name : props.contact.email}</label>
                )}
            </div>
        </CellRenderer>
    );
};
