import { FormFieldInterface } from '../../../interfaces';
import { isNullOrEmptyString } from '../../../utils';
import { IRowFieldValidator } from './IRowFieldValidator';
import { RowFieldBaseValidator, RowFieldValueIsValidResult } from './RowFieldBaseValidator';

export class PickListValidator extends RowFieldBaseValidator implements IRowFieldValidator {
    public validate(rowField: FormFieldInterface): RowFieldValueIsValidResult {
        let rowFieldValueIsValidResult = super.validateMandatoryProperties(rowField);
        if (rowFieldValueIsValidResult) {
            return rowFieldValueIsValidResult;
        }

        rowFieldValueIsValidResult = super.validatePrimitiveValue(rowField);
        if (!rowFieldValueIsValidResult.isValid) {
            return rowFieldValueIsValidResult;
        }

        if (rowField.validation) {
            if (isNullOrEmptyString(rowField.value)) {
                return {
                    isValid: true,
                };
            }

            return {
                isValid: super.isPicklistOption(rowField.value, rowField.options) || rowField.value === rowField.defaultValue,
            };
        }

        return {
            isValid: true,
        };
    }
}
