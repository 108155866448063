import styled from '@emotion/styled';
import { Contact, ModalFooter, ModalFooterButton, ModalHeader, sizes } from '@smartsheet/lodestar-core';
import { ErrorType, OwnershipTransferStatus } from '../../common/enums';
import { AdminControlsData } from '../../common/interfaces';
import { isEmailValid } from '../../common/utils';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { AutomationIds } from '../../common/enums/AutomationElements.enum';
import { disableFreeTextNewOptions } from '../../common/utils/DisableFreeTextNewOptions';
import ContactPicker from '../../components/ContactPicker';
import { checkIfOnDemandOptionsShouldBeEnabled } from '../../components/ContactPicker/CheckIfOnDemandOptionsShouldBeEnabled';
import { ErrorMessage } from '../../components/ErrorMessage';
import ownershipTransferClient from '../../http-clients/OwnershipTransfer.client';
import viewSourceAdminClient from '../../http-clients/ViewSourceAdmin.client';
import { useLanguageElements } from '../../language-elements/withLanguageElementsHOC';
import { isDisabledContactPickerOnDemandSelector } from '../App/Selectors';
import { allUsersSelector } from '../OwnershipTransfer/Selectors';
import { AdminControlsMapper } from './AdminControlsMapper';

interface Props {
    itemsToTransfer: Set<string>;
    views: Map<string, AdminControlsData>;
    onHideModal: () => void;
    onUpdateSelectedViews: () => void;
    onUpdateViews: (views: Map<string, AdminControlsData>) => void;
}

const AdminControlsTransferOwnershipModalBody: React.FC<Props> = ({ views, itemsToTransfer, onHideModal, onUpdateSelectedViews, onUpdateViews }) => {
    const [transferError, setTransferError] = React.useState('');
    const [selectedContact, setSelectedContact] = React.useState<Contact | undefined>(undefined);
    const [isAllContacts, setIsAllContacts] = React.useState(false);
    const [transferContacts, setTransferContacts] = React.useState<Contact[] | undefined>(undefined);

    const allUsers = useSelector(allUsersSelector);
    const languageElements = useLanguageElements();

    const handleConfirm = async () => {
        try {
            if (selectedContact) {
                // Currently we only support transferring one view at a time
                // We need to change next line to support multiple views
                const viewId = itemsToTransfer.values().next().value;

                await ownershipTransferClient.create(viewId, selectedContact.email as string);

                const newData = new Map(views);
                // Currently we only support transferring one view at a time
                // We need to change next line to support multiple views
                newData.delete(viewId);

                onUpdateViews(newData);
                onUpdateSelectedViews();
                onHideModal();
            }
        } catch (error) {
            const errorCode = error?.response?.data?.errorCode;
            const errorMessage =
                errorCode === ErrorType.OWNERSHIP_TRANSFER_NOT_ADMIN_OF_VIEW_SOURCE
                    ? languageElements.ADMIN_CONTROLS_NOT_ADMIN_OF_VIEW_SOURCE
                    : languageElements.ADMIN_CONTROLS_TRY_AGAIN_MESSAGE;

            setTransferError(errorMessage);
        }
    };

    const handleOnContactChange = (contact: Contact) => {
        setSelectedContact(contact);
    };

    const getActiveTransfer = React.useCallback(async () => {
        const viewId = itemsToTransfer.values().next().value;

        try {
            const transferResponse = await ownershipTransferClient.get(OwnershipTransferStatus.PENDING, viewId);

            return transferResponse?.data?.length ? transferResponse.data[0] : views.get(viewId);
        } catch (error) {
            setTransferError(error.response?.data?.message);
            return null;
        }
    }, [itemsToTransfer, views]);

    React.useEffect(() => {
        const initTransferOwnershipModalBody = async () => {
            const activeTransferResponse = await getActiveTransfer();

            if (activeTransferResponse) {
                let isAllContactsShouldBeUsed;
                let transferContactOptions;

                // If we fail to fetch the view source admins for the view
                // (this can happen when the current owner has left the company and their token no longer works),
                // then we will show all users from the current org in the transferModalOptions instead.
                try {
                    const viewId = itemsToTransfer.values().next().value;
                    const adminsResponse = await viewSourceAdminClient.getViewSourceAdmins(viewId);

                    transferContactOptions = adminsResponse.data.filter((admin) => {
                        return !(admin.email.toLowerCase() === activeTransferResponse.currentOwner.email.toLowerCase());
                    });
                    isAllContactsShouldBeUsed = false;
                } catch {
                    transferContactOptions = allUsers?.data.filter((user) => {
                        return !(user.email.toLowerCase() === activeTransferResponse.currentOwner.email.toLowerCase());
                    });
                    isAllContactsShouldBeUsed = true;
                }

                setIsAllContacts(isAllContactsShouldBeUsed);
                setTransferContacts(transferContactOptions as any);
            }
        };

        initTransferOwnershipModalBody();
    }, [itemsToTransfer, getActiveTransfer, allUsers]);
    const isDisabledContactPickerOnDemand = useSelector(isDisabledContactPickerOnDemandSelector);
    const isEnableOnDemandOptions =
        isAllContacts &&
        !isDisabledContactPickerOnDemand &&
        checkIfOnDemandOptionsShouldBeEnabled([
            AdminControlsMapper.fromAdminControlsDataToContact(views.get(itemsToTransfer.values().next().value)!),
        ]);

    const validateNewOption = isAllContacts
        ? (newOption: string, allowFreeTextOptionCallback: (isValid: boolean) => void): void => {
              allowFreeTextOptionCallback(isEmailValid(newOption));
          }
        : disableFreeTextNewOptions;

    return (
        <div data-testid={AutomationIds.ADMIN_CONTROLS_OWNERSHIP_TRANSFER_MODAL}>
            <ModalHeader title={languageElements.ADMIN_CONTROLS_TRANSFER_OWNERSHIP_BUTTON_TEXT} onCloseRequested={onHideModal} />
            <ContactContainer>
                <Subtitle>{languageElements.ADMIN_CONTROLS_OWNERSHIP_TRANSFER_MODAL_MESSAGE}</Subtitle>
                <ContactPicker
                    dataClientId={AutomationIds.ADMIN_CONTROLS_OWNERSHIP_TRANSFER_USERS}
                    allOptions={transferContacts}
                    selectedOptions={selectedContact}
                    onChange={handleOnContactChange}
                    enableOnDemandOptions={isEnableOnDemandOptions}
                    isValidNewOption={validateNewOption}
                    allowClearing={false}
                    multi={false}
                    inPlace
                    placeholder={languageElements.ADMIN_PANEL_PERMISSIONS_TRANSFER_OWNERSHIP_PLACEHOLDER}
                />

                {transferError && <ErrorMessage message={transferError} dataClientId={AutomationIds.OWNERSHIP_TRANSFER_ERROR_ON_TRANSFER} />}
            </ContactContainer>
            <ModalFooter>
                <ModalFooterButton appearance="primary-empty" onClick={onHideModal}>
                    {languageElements.ADMIN_CONTROLS_CANCEL_BUTTON_TEXT}
                </ModalFooterButton>
                <ModalFooterButton
                    appearance="primary"
                    onClick={handleConfirm}
                    clientId={AutomationIds.ADMIN_CONTROLS_MODAL_CONFIRM_BUTTON}
                    isDisabled={selectedContact === undefined}
                >
                    {languageElements.ADMIN_CONTROLS_APPLY_BUTTON_TEXT}
                </ModalFooterButton>
            </ModalFooter>
        </div>
    );
};

const ContactContainer = styled.div`
    padding: 0 ${sizes.xLarge}px;
    display: flex;
    flex-direction: column;
    gap: ${sizes.small}px;
`;

const Subtitle = styled.h4`
    display: block;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: ${sizes.xxSmall}px;
`;

export default AdminControlsTransferOwnershipModalBody;
