import css from '@emotion/css';
import styled from '@emotion/styled';
import { SelectV2, TypeRampV2, colors, sizes } from '@smartsheet/lodestar-core';
import { BaseOptionType, FormatSelectValue, OptionSpreadProps } from '@smartsheet/lodestar-core/dist/esm/components/selectV2/types';
import { FALLBACK_I18N_LOCALE } from '../../common/enums';
import { PicklistItem } from '../../common/interfaces';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useResizeHeight } from '../../common/hooks/useResizeHeight';
import { shortLocaleSelector } from '../../containers/Auth/Selectors';
import { INIT_HEIGHT, Z_INDEX_SELECT, calculateMaxMenuHeight } from './formFieldUtils';

export interface Props {
    dataClientId: string;
    inputIndex: number;
    options?: PicklistItem[];
    selectedOption: PicklistItem;
    placeholder?: string;
    onChange?: (value: unknown) => void;
    readOnly?: boolean;
    validation?: boolean;
    isSettingsMode: boolean;
    detailsDataRef?: React.RefObject<HTMLElement>;
}

const FormFieldSymbolPicklist: React.FC<Props> = ({
    dataClientId,
    inputIndex,
    options,
    selectedOption,
    placeholder,
    onChange,
    readOnly,
    validation,
    isSettingsMode,
    detailsDataRef,
}: Props) => {
    const [maxMenuHeight, setMaxMenuHeight] = React.useState<number>(INIT_HEIGHT);

    const resizeHeight = (height: number) => {
        setMaxMenuHeight(calculateMaxMenuHeight(height));
    };

    useResizeHeight({ resizeHeightCallback: resizeHeight, targetRef: detailsDataRef });

    const formattedOptionCustomRender = (
        option: PicklistItem,
        index: number,
        highlightedIndex: number,
        optionSpreadProps: OptionSpreadProps<HTMLDivElement>,
        isOptionSelected: boolean
    ): React.ReactElement => {
        const isHighlighted = index === highlightedIndex;
        return (
            <StyledSelectSymbolMenu {...optionSpreadProps} isSelected={isHighlighted || isOptionSelected}>
                {option.symbol && (
                    <SelectSymbol>
                        <img src={option.symbol} alt={option.label} />
                    </SelectSymbol>
                )}
                <SelectLabel system="lodestar" variant="bodyText" lineClamp={1}>
                    {option.label}
                </SelectLabel>
            </StyledSelectSymbolMenu>
        );
    };

    const customValueCustomRender: FormatSelectValue<BaseOptionType> = () => {
        return selectedOption ? (
            <StyledSelectSymbolValue isSelected={false}>
                {selectedOption.symbol && (
                    <SelectSymbol>
                        <img src={selectedOption.symbol} alt={selectedOption.label} />
                    </SelectSymbol>
                )}
                <SelectLabel system="lodestar" variant="bodyText" lineClamp={1}>
                    {selectedOption.label}
                </SelectLabel>
            </StyledSelectSymbolValue>
        ) : (
            <div />
        );
    };

    const locale = useSelector(shortLocaleSelector) ?? FALLBACK_I18N_LOCALE;
    const updatedOptions = options || [];

    const handleChange = (changedOption: PicklistItem | null) => {
        const newUpdatedOption = changedOption
            ? { value: changedOption.value, label: changedOption.label, symbol: changedOption.symbol }
            : { value: '', label: '', symbol: '' };
        if (onChange) {
            onChange(newUpdatedOption.label);
        }
    };

    const handleGetOptionKey = (option: PicklistItem) => {
        return option.label || '';
    };

    const handleCreateOption = (newOption: string) => {
        if (onChange) {
            const newSelectedOption = {
                value: newOption,
                label: newOption,
            };
            handleChange(newSelectedOption);
        }
    };

    // Needed to use it to prevent Add {value} displayed when validation is enabled
    const onCreateOption = validation ? undefined : handleCreateOption;

    return (
        <StyledSelectV2
            id={`pli-${inputIndex}`}
            clientId={dataClientId}
            formatSelectedValue={customValueCustomRender}
            formatOption={formattedOptionCustomRender}
            tempFormatSelectedValueWrapperFix
            isSearchable={!isSettingsMode}
            isClearable
            isReadonly={readOnly}
            highlightFirstOptionAutomatically
            placeholder={placeholder}
            getOptionKey={handleGetOptionKey}
            options={updatedOptions}
            selectedOption={selectedOption}
            onChange={handleChange}
            onCreateOption={onCreateOption}
            locale={locale}
            portalProps={Z_INDEX_SELECT}
            isSettingsMode={isSettingsMode}
            useFuzzySearch
            maxMenuHeight={maxMenuHeight}
        />
    );
};

export default FormFieldSymbolPicklist;

// Needed to void the interaction with SelectV2 in the Details Panel Layout settings
const isSettingsModeCss = (props: { isSettingsMode: boolean }) => css`
    pointer-events: ${props.isSettingsMode ? 'none' : 'auto'};
`;

const StyledSelectV2 = styled(SelectV2)`
    ${isSettingsModeCss}
`;

const StyledSelectSymbolMenu = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${sizes.small}px;
    &:hover {
        background-color: ${colors.neutralLight20};
    }
    ${(props: { isSelected: boolean }) => props.isSelected && `background-color: ${colors.neutralLight20};`}
`;

const StyledSelectSymbolValue = styled(StyledSelectSymbolMenu)`
    padding-left: 0;
    display: flex;
    width: 75%;
`;

const SelectSymbol = styled.div`
    margin-right: ${sizes.small}px;
`;

const SelectLabel = styled(TypeRampV2)`
    font-size: 13px;
`;
