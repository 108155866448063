import { TimeZone } from '../../../../common/enums';
import { SmartsheetUser } from '../../../../common/interfaces';
import * as React from 'react';
import { dateTimeFormat } from '../../../../common/DateTimeFormat';
import { AutomationIds } from '../../../../common/enums/AutomationElements.enum';
import CommentMenu from '../../../../components/Buttons/CommentMenu/CommentMenu';
import { LanguageElementsProp, withLanguageElementsHOC } from '../../../../language-elements/withLanguageElementsHOC';
import { DiscussionModel, DisplayComment, UserProfileImageId } from './Comment.interface';
import CommentInput from './CommentInput';
import { Reply } from './Reply';
import { UserIcon } from './UserIcon';

export interface DiscussionProps {
    // Used for AtMentions for fetching mentionable contacts.
    viewId: string;
    rowId: number;
    reportSheetId?: number;

    discussion: DiscussionModel;
    userProfileImageIds: UserProfileImageId;
    currentUser: SmartsheetUser;
    currentUserIcon: JSX.Element;

    // User date settings
    userLocale?: string;
    userTimeZone?: TimeZone;

    // Event handlers for comment menu options
    onClickDeleteComment: (commentId: number) => void;
    onClickDeleteDiscussion: (discussionId: number) => void;

    // Event handlers for editing comments
    onChangeExistingComment: (commentId: number, text: string) => void;
    onSaveExistingComment: (commentId: number, text: string) => void;

    // Event handlers for adding comments
    newCommentsAllowed: boolean;
    onChangeNewComment: (discussionId: number, text: string) => void;
    onSaveNewComment: (discussionId: number, text: string) => void;
}

interface State {
    isCollapsed: boolean;
    isReplying: boolean;
    isEditing: boolean;
}

export class Discussion extends React.Component<DiscussionProps & LanguageElementsProp, State> {
    public constructor(props: DiscussionProps & LanguageElementsProp) {
        super(props);
        this.state = {
            isCollapsed: true,
            isReplying: false,
            isEditing: false,
        };
    }

    public render = () => {
        const [comment, ...replies] = this.props.discussion.comments;
        const createdAt = dateTimeFormat(this.props.userLocale, this.props.userTimeZone).format(new Date(comment.createdAt));
        const mostRecentReplies = replies.slice(replies.length - 2);
        const leastRecentReplies = replies.slice(0, replies.length - 2);

        const currentUserEmail = this.props.currentUser.email.toLowerCase();
        const commentCreatedByEmail = comment.createdBy.email.toLowerCase();
        const discussionCreatedByEmail = this.props.discussion.createdBy.email.toLowerCase();

        const currentUserIsCommentOwner = commentCreatedByEmail === currentUserEmail;
        const currentUserIsDiscussionOwner = discussionCreatedByEmail === currentUserEmail;

        return (
            <div>
                <div className="comment-wrap" data-client-type={AutomationIds.COMMENT_COMMENTS_ITEM}>
                    {comment.createdBy && <UserIcon user={comment.createdBy} userProfileImageIds={this.props.userProfileImageIds} />}

                    <div className="comment-content">
                        <div className="comment-header">
                            <div>
                                <span className="comment-creator" data-client-id={AutomationIds.COMMENT_COMMENTS_CREATOR}>
                                    <b>{comment.createdBy.name}</b>
                                </span>

                                {/* Hide the created-at date if the comment is being edited. */}
                                {!this.state.isEditing && (
                                    <span className="comment-date" data-client-id={AutomationIds.COMMENT_COMMENTS_DATE}>
                                        | {createdAt}
                                    </span>
                                )}
                            </div>
                            <CommentMenu
                                for={comment.text}
                                onClickEditComment={currentUserIsCommentOwner ? this.handleClickEditComment : undefined}
                                onClickDeleteComment={currentUserIsCommentOwner ? this.handleClickDeleteComment : undefined}
                                onClickDeleteDiscussion={currentUserIsDiscussionOwner ? this.handleClickDeleteDiscussion : undefined}
                            />
                        </div>

                        {/* Show the comment if the user is not editing the text.*/}
                        {!this.state.isEditing && (
                            <div className="comment-text" data-client-id={AutomationIds.COMMENT_COMMENTS_TEXT}>
                                {comment.text}
                            </div>
                        )}

                        {/* Show the comment input control if the user is editing the text.*/}
                        {this.state.isEditing && (
                            <CommentInput
                                className={'add-comment-wrap'}
                                currentUser={this.props.currentUser}
                                viewId={this.props.viewId}
                                rowId={this.props.rowId}
                                reportSheetId={this.props.reportSheetId}
                                value={comment.text}
                                isEditing={true}
                                onChange={(text) => this.props.onChangeExistingComment(comment.id, text)}
                                onSave={(text) => this.props.onSaveExistingComment(comment.id, text)}
                                onCancel={() => {
                                    this.props.onChangeExistingComment(comment.id, '');
                                    this.setState({ isEditing: false });
                                }}
                            />
                        )}

                        {/* By default, we only show the first comment in a discussion plus the two most recent replies. */}
                        {/* If there are more than two replies, hide the least recent and show a link to expand them. */}

                        {leastRecentReplies.length > 0 && this.state.isCollapsed && (
                            <div style={{ display: 'block' }}>
                                <div>
                                    <a
                                        data-client-id={AutomationIds.COMMENT_COMMENTS_VIEW_PREVIOUS}
                                        className="view-previous-comments"
                                        onClick={() => {
                                            this.setState({ isCollapsed: false });
                                        }}
                                    >
                                        <div className="item-icon icon-message" />
                                        {this.props.languageElements.TEMPLATE.COMMENT_ITEM_VIEW_PREVIOUS_REPLIES(leastRecentReplies.length)}
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>

                    {leastRecentReplies.length > 0 && !this.state.isCollapsed && (
                        <div style={{ display: 'block' }}>{this.renderComments(leastRecentReplies)}</div>
                    )}

                    <div>{this.renderComments(mostRecentReplies)}</div>

                    {/* Controls for replying to a discussion */}
                    {this.props.newCommentsAllowed && (
                        <div>
                            {this.state.isReplying && (
                                <CommentInput
                                    className={'add-comment-wrap sub-comment'}
                                    currentUser={this.props.currentUser}
                                    currentUserIcon={this.props.currentUserIcon!}
                                    onSave={(text) => this.props.onSaveNewComment(this.props.discussion.id, text)}
                                    onChange={(text) => this.props.onChangeNewComment(this.props.discussion.id, text)}
                                    viewId={this.props.viewId}
                                    rowId={this.props.rowId}
                                    reportSheetId={this.props.reportSheetId}
                                />
                            )}

                            {!this.state.isReplying && (
                                <div className="reply-comment-link sub-comment">
                                    <a
                                        data-client-id={AutomationIds.COMMENT_COMMENTS_REPLY}
                                        className="reply-comment-link-text"
                                        onClick={() => {
                                            this.setState({ isReplying: true }); /* e.preventDefault(); */
                                        }}
                                    >
                                        <div className="item-icon icon-reply" />
                                        {this.props.languageElements.COMMENT_ITEM_REPLY}
                                    </a>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className="divider" />
            </div>
        );
    };

    private renderComments = (comments: DisplayComment[]) =>
        comments.map((comment, index) => (
            <Reply
                key={index}
                viewId={this.props.viewId}
                rowId={this.props.rowId}
                reportSheetId={this.props.reportSheetId}
                comment={comment}
                userProfileImageIds={this.props.userProfileImageIds}
                currentUser={this.props.currentUser}
                userLocale={this.props.userLocale}
                userTimeZone={this.props.userTimeZone}
                onClickDeleteComment={this.props.onClickDeleteComment}
                onChange={this.props.onChangeExistingComment}
                onSave={this.props.onSaveExistingComment}
            />
        ));

    private handleClickEditComment = () => this.setState({ isEditing: true });
    private handleClickDeleteComment = () => this.props.onClickDeleteComment && this.props.onClickDeleteComment(this.props.discussion.comments[0].id);
    private handleClickDeleteDiscussion = () => this.props.onClickDeleteDiscussion && this.props.onClickDeleteDiscussion(this.props.discussion.id);
}

export default withLanguageElementsHOC(Discussion);
