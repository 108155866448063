/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ColumnType, FilterConditionOperators } from '../../enums';
import { Column, DualInput, IFilterCondition, IFilterConditionUI } from '../../interfaces';
import { isDateColumnType, isDateValid } from '../../utils';

export class FilterCondition {
    public get columnId(): string {
        return this._columnId;
    }

    public get operator(): FilterConditionOperators | undefined {
        return this._operator;
    }

    public get value(): any {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return this._value;
    }

    public set value(val: any) {
        this._value = val;
    }

    public get isValid(): boolean {
        return this._isValid;
    }

    public get isFieldValid(): boolean {
        return this._isFieldValid;
    }

    public get isOperatorValid(): boolean {
        return this._isOperatorValid;
    }

    public get isValueValid(): boolean {
        return this._isValueValid;
    }

    public get doesFieldExist(): boolean {
        return this._doesFieldExist;
    }

    public get columnType(): ColumnType | undefined {
        return this._columnType;
    }

    public get isDateType(): boolean {
        return isDateColumnType(this._columnType);
    }

    public get conditionOperators(): FilterConditionOperators[] {
        return this._conditionOperators;
    }

    public get enableValue(): boolean {
        return this._enableValue;
    }

    public get conditionFields(): Column[] {
        return this._conditionFields;
    }

    public get fields(): Column[] {
        return this._fields;
    }

    private _columnId: string;
    private _operator: FilterConditionOperators | undefined;
    private _value: any;
    private _isValid: boolean;
    private _isFieldValid: boolean;
    private _isOperatorValid: boolean;
    private _isValueValid: boolean;
    private _doesFieldExist: boolean;
    private _columnType: ColumnType | undefined;
    private _conditionOperators: FilterConditionOperators[];
    private _enableValue: boolean;
    private readonly _conditionFields: Column[];
    private readonly _fields: Column[];

    public constructor(fields: Column[], condition?: IFilterCondition) {
        this._fields = fields;
        this._conditionFields = this.getConditionFields(this._fields);

        if (condition) {
            this._columnId = condition.columnId;
            this._operator = condition.operator;
            this._value = condition.value;
        } else {
            this._columnId = '';
            this._operator = undefined;
            this._value = undefined;
        }

        /*
         * All other info related to the filter condition depends on the current columns in the viewData.
         * It's possible that the filter column no longer exists, or that it is of a type that doesn't apply for filters.
         * It's also possible that the operator defined on the condition is no longer valid if the column type has changed
         * (i.e. if column was CHECKBOX and operator was CHECKED, then operator will be invalid if column type changes to PICKLIST)
         */
        this.setTypeAndValidOperators(this._columnId, this._fields);

        // Set booleans to indicate whether the field and/or operator are still valid
        this.checkValidity();
    }

    public toDisplayModel(): IFilterConditionUI {
        return {
            columnId: this._columnId,
            operator: this._operator,
            value: this._value,
            enableValue: this._enableValue,
            isValid: this._isValid,
            isFieldValid: this._isFieldValid,
            isOperatorValid: this._isOperatorValid,
            isValueValid: this._isValueValid,
            doesFieldExist: this._doesFieldExist,
            conditionFields: this._conditionFields,
            conditionOperators: this._conditionOperators,
            columnType: this._columnType,
            isDateType: this.isDateType,
        };
    }

    public toConditionInterface(): IFilterCondition {
        return {
            columnId: this._columnId,
            columnType: this._columnType,
            operator: this._operator,
            value: this._value,
        };
    }

    public setConditionField(columnId: number): void {
        const columnIdAsString = String(columnId);
        if (this._columnId !== columnIdAsString) {
            this._columnId = columnIdAsString;
            this._value = undefined; // clear multi-select/input fields when condition field is changed
            this.setTypeAndValidOperators(this._columnId, this._fields);
            this._operator = this._conditionOperators[0];
            this.checkValidity();
        }
    }

    public setConditionOperator(operator: FilterConditionOperators): void {
        this._operator = operator;

        switch (operator) {
            case FilterConditionOperators.IS_ONE_OF:
            case FilterConditionOperators.IS_NOT_ONE_OF:
            case FilterConditionOperators.HAS_ANY_OF:
            case FilterConditionOperators.HAS_NONE_OF:
            case FilterConditionOperators.HAS_ALL_OF:
            case FilterConditionOperators.DOES_NOT_HAVE_ALL_OF:
                this._value = [];
                break;
            case FilterConditionOperators.IS_BETWEEN:
            case FilterConditionOperators.IS_NOT_BETWEEN:
                this._value = {};
                break;
            case FilterConditionOperators.CONTAINS:
            case FilterConditionOperators.DOES_NOT_CONTAIN:
            case FilterConditionOperators.IS_CHECKED:
            case FilterConditionOperators.IS_NOT_CHECKED:
            case FilterConditionOperators.IS_BLANK:
            case FilterConditionOperators.IS_NOT_BLANK:
            default:
                this._value = undefined;
        }
        this.checkValidity();
    }

    public updateConditionOption(option: string | DualInput): void {
        switch (this._operator) {
            case FilterConditionOperators.IS_ONE_OF:
            case FilterConditionOperators.IS_NOT_ONE_OF:
            case FilterConditionOperators.HAS_ANY_OF:
            case FilterConditionOperators.HAS_NONE_OF:
            case FilterConditionOperators.HAS_ALL_OF:
            case FilterConditionOperators.DOES_NOT_HAVE_ALL_OF:
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                this._value.push(option);
                break;
            case FilterConditionOperators.CONTAINS:
            case FilterConditionOperators.DOES_NOT_CONTAIN:
            case FilterConditionOperators.IS_EQUAL_TO:
            case FilterConditionOperators.IS_NOT_EQUAL_TO:
            case FilterConditionOperators.IS_GREATER_THAN:
            case FilterConditionOperators.IS_LESS_THAN:
            case FilterConditionOperators.IS_GREATER_THAN_OR_EQUAL_TO:
            case FilterConditionOperators.IS_LESS_THAN_OR_EQUAL_TO:
            case FilterConditionOperators.IS_BETWEEN:
            case FilterConditionOperators.IS_NOT_BETWEEN:
                this._value = option;
                break;
            default:
        }

        this.checkValidity();
    }

    // This is called to clear the multi-select options (not called by single-select option)
    public clearConditionOptions(): void {
        this._value = [];
        this.checkValidity();
    }

    public getColumnFromFields(columnId: string, fields: Column[]): Column | undefined {
        const id = parseInt(columnId, 10);
        return fields
            ? fields.find((field: Column) => {
                  return field.id === id || field.virtualId === id;
              })
            : undefined;
    }

    public getConditionOperators(column?: Column): FilterConditionOperators[] {
        let conditions: FilterConditionOperators[] = [];

        // Set operators depending on column type
        if (column) {
            switch (column.type) {
                case ColumnType.CHECKBOX:
                    conditions = [
                        FilterConditionOperators.IS_CHECKED,
                        FilterConditionOperators.IS_NOT_CHECKED,
                        FilterConditionOperators.IS_ONE_OF,
                        FilterConditionOperators.IS_NOT_ONE_OF,
                        FilterConditionOperators.CONTAINS,
                        FilterConditionOperators.DOES_NOT_CONTAIN,
                    ];
                    break;

                case ColumnType.CONTACT_LIST:
                case ColumnType.PICKLIST:
                    conditions = [
                        FilterConditionOperators.IS_ONE_OF,
                        FilterConditionOperators.IS_NOT_ONE_OF,
                        FilterConditionOperators.CONTAINS,
                        FilterConditionOperators.DOES_NOT_CONTAIN,
                        FilterConditionOperators.IS_BLANK,
                        FilterConditionOperators.IS_NOT_BLANK,
                    ];
                    break;

                case ColumnType.MULTI_CONTACT_LIST:
                case ColumnType.MULTI_PICKLIST:
                    conditions = [
                        FilterConditionOperators.HAS_ANY_OF,
                        FilterConditionOperators.HAS_NONE_OF,
                        FilterConditionOperators.HAS_ALL_OF,
                        FilterConditionOperators.DOES_NOT_HAVE_ALL_OF,
                        FilterConditionOperators.CONTAINS,
                        FilterConditionOperators.DOES_NOT_CONTAIN,
                        FilterConditionOperators.IS_BLANK,
                        FilterConditionOperators.IS_NOT_BLANK,
                    ];
                    break;
                case ColumnType.TEXT_NUMBER:
                    conditions = [
                        FilterConditionOperators.CONTAINS,
                        FilterConditionOperators.DOES_NOT_CONTAIN,
                        FilterConditionOperators.IS_BLANK,
                        FilterConditionOperators.IS_NOT_BLANK,
                        FilterConditionOperators.IS_EQUAL_TO,
                        FilterConditionOperators.IS_NOT_EQUAL_TO,
                        FilterConditionOperators.IS_GREATER_THAN,
                        FilterConditionOperators.IS_LESS_THAN,
                        FilterConditionOperators.IS_GREATER_THAN_OR_EQUAL_TO,
                        FilterConditionOperators.IS_LESS_THAN_OR_EQUAL_TO,
                        FilterConditionOperators.IS_BETWEEN,
                        FilterConditionOperators.IS_NOT_BETWEEN,
                        FilterConditionOperators.IS_A_NUMBER,
                        FilterConditionOperators.IS_NOT_A_NUMBER,
                    ];
                    break;
                case ColumnType.DATE:
                case ColumnType.DATETIME:
                case ColumnType.ABSTRACTDATETIME:
                case ColumnType.ABSTRACT_DATETIME:
                    conditions = [
                        FilterConditionOperators.IS_BLANK,
                        FilterConditionOperators.IS_NOT_BLANK,
                        FilterConditionOperators.IS_EQUAL_TO,
                        FilterConditionOperators.IS_NOT_EQUAL_TO,
                        FilterConditionOperators.IS_GREATER_THAN,
                        FilterConditionOperators.IS_GREATER_THAN_OR_EQUAL_TO,
                        FilterConditionOperators.IS_LESS_THAN,
                        FilterConditionOperators.IS_LESS_THAN_OR_EQUAL_TO,
                        FilterConditionOperators.IS_BETWEEN,
                        FilterConditionOperators.IS_NOT_BETWEEN,
                        FilterConditionOperators.IS_A_DATE,
                        FilterConditionOperators.IS_NOT_A_DATE,
                    ];
                    break;
            }
        }

        return conditions;
    }

    private setTypeAndValidOperators = (columnId: string, fields: Column[]): void => {
        const filterColumn = this.getColumnFromFields(columnId, fields);
        this._columnType = filterColumn ? filterColumn.type : undefined;
        this._conditionOperators = this.getConditionOperators(filterColumn);
    };

    private checkValidity = (): void => {
        this._doesFieldExist = Boolean(this._columnType);
        this._isFieldValid = this.checkFilterColumnType(this._columnType);
        this._isOperatorValid = this.checkOperator(this._operator, this._conditionOperators);
        this._enableValue = this.shouldValueBeEnabled();
        this._isValueValid = this.checkIsValueValid(this._operator, this._enableValue, this._value);
        this._isValid = this._isFieldValid && this._isOperatorValid && this._isValueValid && this._doesFieldExist;
    };

    private checkIsValueValid = (operator: FilterConditionOperators | undefined, enableValue: boolean, value: any): boolean => {
        if (!operator) {
            return false;
        }
        let isValueValid: boolean;

        if (this.isDateType) {
            if (operator === FilterConditionOperators.IS_BETWEEN || operator === FilterConditionOperators.IS_NOT_BETWEEN) {
                return Boolean(value.startValue && value.endValue && isDateValid(value.startValue) && isDateValid(value.endValue));
            }

            return Boolean(!enableValue || (value && isDateValid(value)));
        }

        switch (operator) {
            case FilterConditionOperators.IS_BETWEEN:
            case FilterConditionOperators.IS_NOT_BETWEEN:
                isValueValid = value.startValue != null && value.startValue !== '' && value.endValue != null && value.endValue !== '';
                break;
            default:
                isValueValid = Boolean(!enableValue || (value && value.length > 0));
        }

        return isValueValid;
    };

    // If the column type is one of the following, the column is a valid filterColumn
    private checkFilterColumnType = (columnType: ColumnType | undefined): boolean => {
        return (
            isDateColumnType(columnType) ||
            columnType === ColumnType.CONTACT_LIST ||
            columnType === ColumnType.MULTI_CONTACT_LIST ||
            columnType === ColumnType.PICKLIST ||
            columnType === ColumnType.MULTI_PICKLIST ||
            columnType === ColumnType.CHECKBOX ||
            columnType === ColumnType.TEXT_NUMBER
        );
    };

    private checkOperator = (operator: FilterConditionOperators | undefined, conditionOperators: FilterConditionOperators[]): boolean => {
        return conditionOperators.some((option) => option === operator);
    };

    private getConditionFields(fields: Column[]): Column[] {
        return fields.filter((field: Column) => {
            return this.checkFilterColumnType(field.type);
        });
    }

    // Used to disable the multi-select input when the operator is CHECKED, UNCHECKED, BLANK or NOT BLANK
    private shouldValueBeEnabled(): boolean {
        return !(
            this._operator === FilterConditionOperators.IS_CHECKED ||
            this._operator === FilterConditionOperators.IS_NOT_CHECKED ||
            this._operator === FilterConditionOperators.IS_BLANK ||
            this._operator === FilterConditionOperators.IS_NOT_BLANK ||
            this._operator === FilterConditionOperators.IS_A_NUMBER ||
            this._operator === FilterConditionOperators.IS_NOT_A_NUMBER ||
            this._operator === FilterConditionOperators.IS_A_DATE ||
            this._operator === FilterConditionOperators.IS_NOT_A_DATE
        );
    }
}
