import styled from '@emotion/styled';
import * as React from 'react';
import alertIcon from '../../../../assets/images/alert/icon-info-circle-bluebigger.svg';
import { ModalBody, ModalFooter, ModalFooterButton, ModalHeader } from '@smartsheet/lodestar-core/';
import { useLanguageElements } from '../../../../language-elements/withLanguageElementsHOC';

interface UnsavedChangesModalContentProps {
    onCloseModalDetailsPanel: () => void;
    onCancel: () => void;
    onSave: () => void;
}

const alertImg = <img src={alertIcon} alt="alert" />;

export const UnsavedChangesModalContent = (props: UnsavedChangesModalContentProps) => {
    const languageElements = useLanguageElements();
    return (
        <>
            <ModalHeader
                title={languageElements.DETAILS_DATA_MODAL_UNSAVED_CHANGES_TITLE}
                titleIcon={alertImg}
                onCloseRequested={props.onCloseModalDetailsPanel}
            />
            <ModalBody>
                <p>{languageElements.DETAILS_DATA_MODAL_UNSAVED_CHANGES_MESSAGE}</p>
            </ModalBody>
            <FullWidthFooter>
                <LeftSideButton appearance={'secondary'} onClick={props.onCloseModalDetailsPanel}>
                    {languageElements.BUTTON_TEXT_CANCEL}
                </LeftSideButton>
                <SpecialFooterButton
                    appearance={'secondary-destructive'}
                    onClick={() => {
                        props.onCancel();
                        props.onCloseModalDetailsPanel();
                    }}
                >
                    {languageElements.BUTTON_TEXT_DISCARD_CHANGES}
                </SpecialFooterButton>
                <SpecialFooterButton onClick={props.onSave}>{languageElements.BUTTON_TEXT_SAVE}</SpecialFooterButton>
            </FullWidthFooter>
        </>
    );
};

const FullWidthFooter = styled(ModalFooter)`
    & > div {
        width: 100%;
    }
`;
const SpecialFooterButton = styled(ModalFooterButton)`
    flex: 0 0 auto;
`;
const LeftSideButton = styled(ModalFooterButton)`
    flex: 0 0 auto;
    margin-right: auto;
    margin-left: 0;
`;
