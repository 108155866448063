import { User } from '../../../../common/interfaces';
import { Comment, User as ConversationUser, UpsertCommentDto } from './types';

// formated user for conversation service, when the userService decupling will be complete,
// this will be removed
export const formatCurrentUser = (user: User): ConversationUser => {
    return {
        id: user.id ?? 'default-id',
        uniqueUserId: user.smartsheetAccountId ?? 'default-id',
        emailAddress: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        locale: user.locale,
        timezone: user.timeZone,
        profileImage: user.profileImgUrl
            ? {
                  url: user.profileImgUrl,
                  size: 'small',
                  profileImageSource: 'global',
                  scope: 'global',
                  metadata: null,
              }
            : undefined,
    };
};

export const addUserObjectToComment = (user: ConversationUser, comment: UpsertCommentDto): Comment => {
    return {
        id: comment.id,
        text: comment.text,
        metadata: comment.metadata,
        isDeleted: comment.isDeleted,
        isUnread: comment.isUnread,
        parentCommentId: comment.parentCommentId,
        createdAt: comment.createdAt,
        createdByUser: user,
        insertedAt: comment.insertedAt,
        insertedByUser: user,
        modifiedAt: comment.modifiedAt,
        modifiedByUser: user,
    };
};

export const getInitials = (firstName: string = '', lastName: string = '') => {
    const firstNameTrimmed = firstName?.trim();
    const lastNameTrimmed = lastName?.trim();
    return `${firstNameTrimmed[0]?.toUpperCase() ?? ''}${lastNameTrimmed[0]?.toUpperCase() ?? ''}`;
};

export const getUserNameOrEmail = (user: ConversationUser): string => {
    const fullName = `${user.firstName ?? ''} ${user.lastName ?? ''}`.trim();
    return fullName; // uncomment if we decide to display email when fulname is empty => || user.emailAddress;
};

export const sortCommentsByDate = (comments: Comment[]): Comment[] =>
    [...comments].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

export const isNewComment = (commentId: string, parentCommentId: string) => parentCommentId === '0' && commentId === '0';

export const isTopLevelComment = (commentId: string, parentCommentId: string) => commentId === parentCommentId;

export const isNewReply = (commentId: string, parentCommentId: string) => commentId === '0' && parentCommentId !== '0';
