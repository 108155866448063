import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CSSTransition } from 'react-transition-group';
import favoriteIcon from '../../../assets/images/toolbar/leftRail/activeFavorite.svg';
import newIcon from '../../../assets/images/toolbar/leftRail/combined-shape.svg';
import homeIcon from '../../../assets/images/toolbar/leftRail/home.svg';
import recentsIcon from '../../../assets/images/toolbar/leftRail/recents.svg';
import { AutomationIds } from '../../../common/enums/AutomationElements.enum';
import { HomeFilter } from '../../../common/enums/HomeFilter';
import { UserActions } from '../../../common/enums/UserActions.enum';
import { UserAnalyticsAction } from '../../../common/metrics/UserAnalyticsAction';
import { LanguageElementsProp, withLanguageElementsHOC } from '../../../language-elements/withLanguageElementsHOC';
import { ActionWithPayload } from '../../../store/Utils/CreateAction';
import { ActionByType } from '../../../store/types';
import { ActionTypes, Actions } from '../../App/Actions';
import { ActionTypes as HomeActionTypes, Actions as HomeActions } from '../../Home/Actions';
import ViewNewItem from '../HomePanel/ViewNewItem';
import LeftPanelType from '../LeftPanelType';
import './SkinnyRail.css';

interface OwnProps {
    leftSidePanelOpened: boolean;
    activeSidePanel: LeftPanelType;
    userIsLicensed: boolean;
}

interface DispatchProps {
    setActiveSidePanel: (panel: LeftPanelType) => ActionWithPayload<ActionTypes.SET_ACTIVE_SIDE_PANEL, LeftPanelType>;
    setHomeFilter: (filter: HomeFilter | null) => ActionWithPayload<ActionTypes.SET_HOME_FILTER, HomeFilter | null>;
    fetchHomeData: () => ActionByType<HomeActions, HomeActionTypes.FETCH_HOME_DATA>;
}

interface NavButtonProps {
    activeSidePanel: LeftPanelType;
    panelType: LeftPanelType;
    icon: string;
    description: string;
    clientId: string;
    onButtonClick: (e: any, panelType: LeftPanelType) => void;
}

const NavButton = (props: NavButtonProps) => (
    <button
        className={props.activeSidePanel === props.panelType ? 'active' : ''}
        onClick={(e) => props.onButtonClick(e, props.panelType)}
        data-client-id={props.clientId}
        value={props.description}
    >
        <img src={props.icon} alt="" />
        <div className="tooltip">
            <div>{props.description}</div>
        </div>
    </button>
);

// Empty alt-text on image because button has value prop for accessibility
const SkinnyRail = (props: OwnProps & DispatchProps & RouteComponentProps<any> & LanguageElementsProp) => {
    const { languageElements, activeSidePanel } = props;
    const onButtonClick = (e: React.MouseEvent<HTMLButtonElement>, panelType: LeftPanelType) => {
        props.setActiveSidePanel(panelType);
        if (panelType === LeftPanelType.HOME && props.location.pathname !== '/') {
            props.setHomeFilter(HomeFilter.ALL);
            props.fetchHomeData();
            props.history.push('/');
        }
        UserAnalyticsAction.addFromEvent(e, { panel: panelType }, UserActions.CLICK_LEFT_PANEL);
    };

    return (
        <CSSTransition
            in={props.leftSidePanelOpened}
            timeout={150}
            classNames={{
                enter: 'left-rail-enter',
                enterActive: '.left-rail-enter-active',
                exit: 'left-rail-exit',
                exitActive: 'left-rail-exit-active',
                exitDone: 'left-rail-exit-done',
            }}
            unmountOnExit={true}
        >
            <div className="left-rail">
                <NavButton
                    activeSidePanel={activeSidePanel}
                    panelType={LeftPanelType.HOME}
                    icon={homeIcon}
                    description={languageElements.HOME_ICON_ALT_TEXT}
                    clientId={AutomationIds.SKINNY_RAIL_HOME_BUTTON}
                    onButtonClick={onButtonClick}
                />
                <NavButton
                    activeSidePanel={activeSidePanel}
                    panelType={LeftPanelType.RECENTS}
                    icon={recentsIcon}
                    description={languageElements.RECENTS_ICON_ALT_TEXT}
                    clientId={AutomationIds.SKINNY_RAIL_RECENTS_BUTTON}
                    onButtonClick={onButtonClick}
                />
                <NavButton
                    activeSidePanel={activeSidePanel}
                    panelType={LeftPanelType.FAVORITES}
                    icon={favoriteIcon}
                    description={languageElements.FAVORITE_ICON_ALT_TEXT}
                    clientId={AutomationIds.SKINNY_RAIL_FAVORITE_BUTTON}
                    onButtonClick={onButtonClick}
                />
                {props.userIsLicensed && (
                    <ViewNewItem controlId={AutomationIds.SKINNY_RAIL_NEW_BUTTON} class="button" tooltip={languageElements.NEW_VIEW} icon={newIcon} />
                )}
            </div>
        </CSSTransition>
    );
};

const mapDispatch: DispatchProps = {
    setActiveSidePanel: Actions.setActiveSidePanel,
    setHomeFilter: Actions.setHomeFilter,
    fetchHomeData: HomeActions.fetchHomeData,
};
export default withLanguageElementsHOC(withRouter(connect<null, DispatchProps, OwnProps>(null, mapDispatch)(SkinnyRail)));
