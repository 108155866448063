import * as React from 'react';
import { OptionComponentProps } from 'react-select';
import { AutomationIds } from '../../../common/enums/AutomationElements.enum';
import { getUserInitialCssClass } from '../../../common/utils/GetUserInitialCssClass';
import { getUserInitials } from '../../../common/utils/GetUserInitials';
import { ContactPickerItem } from '../ContactPicker.interface';
import './ContactPickerOption.css';
import { withLanguageElementsHOC, LanguageElementsProp } from '../../../language-elements/withLanguageElementsHOC';

// Note: removed alt text in image because it crowds the drop-down. We should default to initials later so it shouldn't be an issue.
class ContactPickerOption extends React.PureComponent<OptionComponentProps<ContactPickerItem> & LanguageElementsProp> {
    public render(): React.ReactNode {
        const controlType = `${this.props.option.controlIdPrefix}Item`;
        const controlId1 = `${controlType}-1`;
        const controlId2 = `${controlType}-2`;
        let userIcon: JSX.Element | undefined;
        let labelJSX: JSX.Element;
        let subLabelJSX: JSX.Element | undefined;

        /**
         * Implement userIcon if there is a subLabel. At this point, subLabel is only used for emails.
         */
        if (this.props.option.subLabel != null) {
            const userInitials: string = getUserInitials(this.props.option.label || this.props.option.subLabel);
            userIcon = (
                <div data-client-type={AutomationIds.COMMENT_COMMENTS_ICON} className={'icon-user initials ' + getUserInitialCssClass(userInitials)}>
                    {userInitials}
                </div>
            );
            subLabelJSX = (
                <label className="subLabel" data-client-id={controlId2}>
                    {this.props.option.subLabel}
                </label>
            );
        }

        const label = this.props.option.label ? this.props.option.label : this.props.option.value;

        // If label is empty, use 'Blank' language element
        if (label === '') {
            labelJSX = (
                <label className={'heading italic'} data-client-id={controlId1}>
                    {this.props.languageElements.SELECTION_FOR_BLANK_ENTRY}
                </label>
            );
        } else {
            labelJSX = (
                <label className={'heading'} data-client-id={controlId1}>
                    {label}
                </label>
            );
        }

        return (
            <div
                className="multi-select-option"
                data-client-type={controlType}
                onMouseDown={this.handleMouseDown}
                onMouseEnter={this.handleMouseEnter}
                onMouseMove={this.handleMouseMove}
            >
                <span className={`contact-picker-value ${this.props.option.subLabel ? '' : 'heading-solo'}`}>
                    {userIcon}
                    <div className="contact-picker-v1-label-container">
                        {labelJSX}
                        {subLabelJSX}
                    </div>
                </span>
            </div>
        );
    }

    private handleMouseDown = (event: React.FormEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (this.props.onSelect) {
            this.props.onSelect(this.props.option, event);
        }
    };

    private handleMouseEnter = (event: React.FormEvent<HTMLDivElement>) => {
        if (this.props.onFocus) {
            this.props.onFocus(this.props.option, event);
        }
    };

    private handleMouseMove = (event: React.FormEvent<HTMLDivElement>) => {
        if (this.props.isFocused) {
            return;
        }
        if (this.props.onFocus) {
            this.props.onFocus(this.props.option, event);
        }
    };
}

export default withLanguageElementsHOC(ContactPickerOption);
