export class UserActionDurationContext {
    private readonly _name: string;

    public constructor(name: string, parentActionDurationContext?: UserActionDurationContext) {
        const parentActionDurationContextName = parentActionDurationContext?.name ? `${parentActionDurationContext?.name}.` : '';
        this._name = `${parentActionDurationContextName}${name}`;
    }

    public get name(): string {
        return this._name;
    }
}
