import { isValid } from 'date-fns';

/**
 * Checks if a given value is a valid date.
 */
export const isDateValid = (value?: string | number | Date | null): boolean => {
    if (!value) {
        return false;
    }

    const dateObject = new Date(value);
    return isValid(dateObject);
};
