import { IOwnershipTransfer, User } from '../../../common/interfaces';
import { List } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import transfersIcon from '../../../assets/images/ownershipTransferIcons/2-yes.svg';
import { getPendingReceivedTransfers } from '../../../common/utils/GetPendingTransfers';
import * as AdminActions from '../../../containers/Admin/Actions';
import { userSelector, isUserSystemAdmin } from '../../../containers/Auth/Selectors';
import * as OwnershipTransferActions from '../../../containers/OwnershipTransfer/Actions';
import { Actions } from '../../../containers/OwnershipTransfer/Actions';
import { pendingTransfersSelector } from '../../../containers/OwnershipTransfer/Selectors';
import { Badge } from '../../Badge/Badge';
import './OwnershipTransfersBadge.css';
import { withLanguageElementsHOC, LanguageElementsProp } from '../../../language-elements/withLanguageElementsHOC';

interface StateProps {
    pendingTransfers: List<IOwnershipTransfer>;
    user: User;
    isUserSystemAdmin: boolean;
}

interface DispatchProps {
    fetchingOwnershipTransfers: () => OwnershipTransferActions.Actions;
    fetchOrgViews: () => OwnershipTransferActions.Actions;
    fetchAllUsers: () => AdminActions.Actions;
}

export class OwnershipTransfersBadge extends React.Component<StateProps & DispatchProps & LanguageElementsProp> {
    public componentDidMount(): void {
        this.props.fetchingOwnershipTransfers();
    }

    public componentDidUpdate(prevProps: StateProps): void {
        if (!this.isSysAdminOwnershipTransferAllowed(prevProps) && this.isSysAdminOwnershipTransferAllowed(this.props)) {
            this.props.fetchOrgViews();
            this.props.fetchAllUsers();
        }
    }

    public render(): React.ReactNode {
        const pendingReceivedTransfers = getPendingReceivedTransfers(this.props.pendingTransfers.toJS(), this.props.user.email);
        const count = pendingReceivedTransfers.length;
        const hasPendingTransfers = count > 0;
        return (
            <Link to="/transfers" onClick={this.handleClick} className="ownership-transfers-badge-link">
                <div className={'ownership-transfers-badge'}>
                    <img src={transfersIcon} alt="Ownership Transfers Notification Badge" />
                    {hasPendingTransfers && <Badge count={count} />}
                    <div className="tooltip">
                        <div>
                            <span>{this.props.languageElements.OWNERSHIP_TRANSFER_BADGE_TOOLTIP}</span>
                            {count > 0 ? ` (${count})` : ''}
                        </div>
                    </div>
                </div>
            </Link>
        );
    }

    private handleClick = () => {
        this.props.fetchingOwnershipTransfers();
        if (this.isSysAdminOwnershipTransferAllowed(this.props)) {
            this.props.fetchOrgViews();
            this.props.fetchAllUsers();
        }
    };

    private isSysAdminOwnershipTransferAllowed = (props: StateProps) => {
        return props.isUserSystemAdmin;
    };
}

const mapState = createStructuredSelector({
    pendingTransfers: pendingTransfersSelector,
    user: userSelector,
    isUserSystemAdmin,
});

const mapDispatch = {
    fetchingOwnershipTransfers: Actions.fetchingOwnershipTransfers,
    fetchOrgViews: Actions.fetchOrgViews,
    fetchAllUsers: AdminActions.Actions.fetchSmartsheetUsers,
};

export default withLanguageElementsHOC(connect<StateProps, DispatchProps>(mapState, mapDispatch)(OwnershipTransfersBadge));
