import { RowFieldValidationError } from '../../../enums';
import { FormFieldInterface } from '../../../interfaces';
import { isContactObjectValue, isNullOrEmptyString } from '../../../utils';
import { isPrimitiveType } from '../../../utils/IsPrimitiveType';
import { IRowFieldValidator } from './IRowFieldValidator';
import { RowFieldBaseValidator, RowFieldValueIsValidResult } from './RowFieldBaseValidator';

export class ContactListValidator extends RowFieldBaseValidator implements IRowFieldValidator {

    public constructor(originalRowField: FormFieldInterface) {
        super(originalRowField);
    }

    public validate(rowField: FormFieldInterface): RowFieldValueIsValidResult {
        const rowFieldValueIsValidResult = super.validateMandatoryProperties(rowField);
        if (rowFieldValueIsValidResult) {
            return rowFieldValueIsValidResult;
        }

        // Required Checks
        if (rowField.required) {
            if (
                (!isPrimitiveType(rowField.value) && !isContactObjectValue(rowField.value) && !isContactObjectValue(rowField.objectValue)) ||
                (typeof rowField.value === 'string' && rowField.value.trim() === '') ||
                (typeof rowField.value === 'object' && rowField.value.email.trim() === '') ||
                (typeof rowField.objectValue === 'object' && !isContactObjectValue(rowField.objectValue))
            ) {
                return {
                    isValid: false,
                    validationError: RowFieldValidationError.MISSING_REQUIRED_VALUE,
                };
            }
        }

        if (
            !isContactObjectValue(rowField.value) &&
            !isPrimitiveType(rowField.value) &&
            !isNullOrEmptyString(rowField.value) &&
            !isContactObjectValue(rowField.objectValue)
        ) {
            // This occurs when a value is an array or an object other than contact object value.
            return {
                isValid: false,
            };
        }

        // Restricted Values Check
        if (rowField.validation) {
            // Only validate the contact if it has been updated.

            // If a Contact object value is passed via the `objectValue` field, validate that one first
            // Validation here means a check whether the value in `objectValue` is either the default value for the field, or one of the valid options
            // Implementation is similar to the one lower in this block for the value field
            if (isContactObjectValue(rowField.objectValue) && this.hasContactFieldBeenUpdated(rowField.objectValue.email || rowField.objectValue.name)) {
                return {
                    isValid:
                        this.checkObjectValueEqualToDefaultContactObjectValue(rowField) ||
                        this.isContactOption(rowField.contactOptions, rowField.objectValue.email || rowField.objectValue.name),
                };
            }

            // Allow null when restricted value check enable to ensure the DV user can clear values within a row field
            if (isNullOrEmptyString(rowField.value)) {
                return {
                    isValid: true,
                };
            }

            if (isContactObjectValue(rowField.value) && this.hasContactFieldBeenUpdated(rowField.value.email || rowField.value.name)) {
                return {
                    isValid:
                        this.checkValueEqualToDefaultContactObjectValue(rowField) ||
                        this.isContactOption(rowField.contactOptions, rowField.value.email || rowField.value.name),
                };
            }

            if (isPrimitiveType(rowField.value) && this.hasContactFieldBeenUpdated(rowField.value)) {
                // Currently we are supporting 2 formats for contact value. ContactObjectValue and string depending if you are on the app or the API,
                // at some point in the near future we need to consolidate to using the ContactObjectValue format.
                // NOTE: This logic specifically applies to the API.
                return {
                    isValid:
                        this.checkValueEqualToDefaultValue(rowField.value, rowField.defaultValue) ||
                        this.isContactOption(rowField.contactOptions, rowField.value),
                };
            }
        }

        return { isValid: true };
    }

    private checkValueEqualToDefaultContactObjectValue(rowField: FormFieldInterface): boolean {
        if (!isContactObjectValue(rowField.value) || !isContactObjectValue(rowField.defaultValue)) {
            return false;
        }

        return this.checkValueEqualToDefaultValue(rowField.value.email, rowField.defaultValue.email);
    }

    private checkObjectValueEqualToDefaultContactObjectValue(rowField: FormFieldInterface): boolean {
        if (!isContactObjectValue(rowField.objectValue) || !isContactObjectValue(rowField.defaultValue)) {
            return false;
        }

        return this.checkValueEqualToDefaultValue(rowField.objectValue.email, rowField.defaultValue.email);
    }

    private checkValueEqualToDefaultValue(value: string | undefined, defaultValue: string | undefined): boolean {
        if (typeof value !== 'string' || typeof defaultValue !== 'string') {
            return false;
        }
        return value.toLowerCase() === defaultValue.toLowerCase();
    }

    private hasContactFieldBeenUpdated(value: string | undefined): boolean {
        if (value == null) {
            return Boolean(this.originalRowField.objectValue);
        }

        if (!this.originalRowField.objectValue) {
            return true;
        }

        if (isContactObjectValue(this.originalRowField.objectValue)) {
            if (this.originalRowField.objectValue.email) {
                return this.originalRowField.objectValue.email.toLowerCase() !== value!.toLowerCase();
            } else if (this.originalRowField.objectValue.name) {
                return this.originalRowField.objectValue.name.toLowerCase() !== value!.toLowerCase();
            }
        }

        return false;
    }
}
