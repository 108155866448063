import { AccessLevel } from '../../common/enums';
import * as React from 'react';
import { HomeFilter } from '../../common/enums/HomeFilter';
import { CellRendererDateTime } from '../../common/utils/CellRenderer';
import { CellRendererPill, CellRendererText, CellRendererUser } from '../../components/CellRenderer';
import { LanguageElements } from '../../language-elements/LanguageElements';
import { HomeTableRowData } from './HomeTable';

// Column Setup
export enum HomeTableColumn {
    NAME = 'NAME',
    OWNER = 'OWNER',
    MY_PERMISSION = 'MY_PERMISSION',
    LAST_ACCESSED = 'LAST_ACCESSED',
    DESCRIPTION = 'DESCRIPTION',
    GROUP = 'GROUP',
}

export type HomeTableColumnInfo<T> = { [key in HomeTableColumn]: T };
export type WeakHomeTableColumnInfo<T> = { [key in HomeTableColumn]?: T };

// Column Configuration
export const homeTableColumnWidths: HomeTableColumnInfo<number[]> = {
    // [width, flexGrow]
    [HomeTableColumn.NAME]: [150, 2.5],
    [HomeTableColumn.OWNER]: [175, 1.2],
    [HomeTableColumn.MY_PERMISSION]: [100, 1],
    [HomeTableColumn.LAST_ACCESSED]: [140, 1],
    [HomeTableColumn.DESCRIPTION]: [350, 5],
    [HomeTableColumn.GROUP]: [300, 3],
};

export const getAccessLevelLabels = (accessLevel: AccessLevel, languageElements: LanguageElements) => {
    const accessLevelLabels = {
        [AccessLevel.ADMIN]: languageElements.HOME_TABLE_ACCESS_LEVEL.ADMIN,
        [AccessLevel.OWNER]: languageElements.HOME_TABLE_ACCESS_LEVEL.OWNER,
        [AccessLevel.USER]: languageElements.HOME_TABLE_ACCESS_LEVEL.COLLABORATOR,
        [AccessLevel.NONE]: '',
    };
    return accessLevelLabels[accessLevel];
};

interface GroupCellRendererOptions {
    columnWidth: number;
    updateWidth: () => void;
}

export const homeTableColumnCellRenderer: HomeTableColumnInfo<
    (rowData: HomeTableRowData, languageElements: LanguageElements, options?: unknown) => JSX.Element
> = {
    [HomeTableColumn.NAME]: (rowData) => <CellRendererText content={rowData.name} />,
    [HomeTableColumn.OWNER]: (rowData) =>
        rowData.ownerDetails ? <CellRendererUser contact={rowData.ownerDetails} showNameAndEmail={false} /> : <></>,
    [HomeTableColumn.MY_PERMISSION]: (rowData, languageElements) => (
        <CellRendererText content={getAccessLevelLabels(rowData.accessLevel as AccessLevel, languageElements)} hideTooltip={true} />
    ),
    [HomeTableColumn.LAST_ACCESSED]: (rowData) => CellRendererDateTime(rowData.lastAccessed),
    [HomeTableColumn.DESCRIPTION]: (rowData) => <CellRendererText content={rowData.description} />,
    [HomeTableColumn.GROUP]: (rowData, languageElements, options: GroupCellRendererOptions) =>
        rowData.sharedWithGroups ? (
            <CellRendererPill content={rowData.sharedWithGroups} columnWidth={options.columnWidth} updateWidth={options.updateWidth} />
        ) : (
            <></>
        ),
};

export const ColumnsByHomeFilter: { [key in HomeFilter]: HomeTableColumn[] } = {
    [HomeFilter.ALL]: [
        HomeTableColumn.NAME,
        HomeTableColumn.OWNER,
        HomeTableColumn.MY_PERMISSION,
        HomeTableColumn.LAST_ACCESSED,
        HomeTableColumn.DESCRIPTION,
    ],
    [HomeFilter.OWNED_BY_ME]: [HomeTableColumn.NAME, HomeTableColumn.LAST_ACCESSED, HomeTableColumn.DESCRIPTION],
    [HomeFilter.SHARED_WITH_ME]: [
        HomeTableColumn.NAME,
        HomeTableColumn.OWNER,
        HomeTableColumn.MY_PERMISSION,
        HomeTableColumn.LAST_ACCESSED,
        HomeTableColumn.DESCRIPTION,
    ],
    [HomeFilter.SHARED_WITH_GROUP]: [
        HomeTableColumn.NAME,
        HomeTableColumn.OWNER,
        HomeTableColumn.GROUP,
        HomeTableColumn.LAST_ACCESSED,
        HomeTableColumn.DESCRIPTION,
    ],
};

// Column Utilities
export const getColumnWidthsByFilter = (filter: HomeFilter) => {
    const widths: WeakHomeTableColumnInfo<number[]> = {};
    ColumnsByHomeFilter[filter].forEach((column) => {
        widths[column] = homeTableColumnWidths[column];
    });
    return widths;
};
