import { Image, ImageUrlDto as ImageUrl } from '../../../common/interfaces';
import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import imageIcon from '../../../assets/images/mime-types/image.svg';
import { UserActions } from '../../../common/enums/UserActions.enum';
import { UserAnalyticsAction } from '../../../common/metrics/UserAnalyticsAction';
import { Omit } from '../../../common/utils';
import { Actions, ActionTypes } from '../../../store/images/Actions';
import { imageExpirationsSelector, imageGridThumbnailsSelector, imageUrlsSelector } from '../../../store/images/Selectors';
import { ActionByType } from '../../../store';
import { BaseComponent } from '../../Base';
import { ImageProps } from '../../FormFieldWrapper/FormFieldImage';
import { default as CellImageModal } from '../../Modal/CellImageModal';

interface OwnProps {
    image: Image;
}

interface StateProps {
    imageUrls: Map<ImageUrl['imageId'], ImageUrl>;
    imageThumbnails: Map<ImageUrl['imageId'], ImageUrl>;
    imageExpirations: Map<ImageUrl['url'], Date>;
}

interface DispatchProps {
    clearImageUrl: (imageId: ImageUrl['imageId']) => ActionByType<Actions, ActionTypes.CLEAR_IMAGE_URL>;
    clearThumbnailUrl: (imageId: ImageUrl['imageId']) => ActionByType<Actions, ActionTypes.CLEAR_GRID_URL>;
}

type Props = OwnProps & StateProps & DispatchProps;

interface State {
    isModalOpen: boolean;
    imageUrl?: ImageUrl;
    thumbnailUrl?: ImageUrl;
}

export const CELL_IMAGE_MAX_HEIGHT = 40;

export class CellImage extends BaseComponent<Props, State> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            isModalOpen: false,
        };
    }

    public componentDidMount(): void {
        this.setState(this.getCurrentImageUrls(this.props.image));
    }

    public componentDidUpdate(prevProps: Readonly<Props>): void {
        if (
            this.props.image !== prevProps.image ||
            this.props.imageUrls !== prevProps.imageUrls ||
            this.props.imageThumbnails !== prevProps.imageThumbnails
        ) {
            this.setState(this.getCurrentImageUrls(this.props.image));
        }
    }

    public render = () => (
        <div className="grid-image">
            {this.state.thumbnailUrl && this.state.imageUrl ? (
                <>
                    <a style={{ cursor: 'pointer' }} onClick={this.handleCellImageClick}>
                        <img {...this.generateThumbnailProps(this.props.image)} />
                    </a>
                    <CellImageModal
                        isOpen={this.state.isModalOpen}
                        onClose={this.closeImageModal}
                        imageProps={this.generateImageProps(this.props.image)}
                    />
                </>
            ) : (
                <img src={imageIcon} width={25} height={25} />
            )}
        </div>
    );

    private closeImageModal = () => this.setState({ isModalOpen: false });

    private handleCellImageClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.stopPropagation();
        UserAnalyticsAction.addFromEvent(event, undefined, UserActions.CLICK_CELL_IMAGE);
        this.setState({ isModalOpen: true });
    };

    private getPropsWithoutSrc = ({ height, width, altText }: Image): Omit<ImageProps, 'src'> => ({ height, width, alt: altText });

    private generateImageProps = (image: Image): ImageProps => ({
        ...this.getPropsWithoutSrc(image),
        src: this.state.imageUrl ? this.state.imageUrl.url || '' : '',
    });

    private generateThumbnailProps = (image: Image): ImageProps => {
        const imageProps = this.getPropsWithoutSrc(image);
        const { height, width, alt } = imageProps;
        const src = this.state.thumbnailUrl ? this.state.thumbnailUrl.url || '' : '';
        // Divide the max height by the largest dimension
        const scale = CELL_IMAGE_MAX_HEIGHT / (height && width ? Math.max(height, width) : height || width || CELL_IMAGE_MAX_HEIGHT);
        return scale < 1 ? { src, height: height && height * scale, width: width && width * scale, alt } : { ...imageProps, src };
    };

    private getCurrentImageUrls = (image: Image): Pick<State, 'thumbnailUrl' | 'imageUrl'> => {
        if (!image.id) {
            return {};
        }
        const thumbnailUrl = this.getCurrentImageUrl(image.id, this.props.imageThumbnails, this.props.clearThumbnailUrl);
        const imageUrl = this.getCurrentImageUrl(image.id, this.props.imageUrls, this.props.clearImageUrl);
        return { thumbnailUrl, imageUrl };
    };

    // TODO: This was taken directly from DetailsData.tsx and should be converted into a util
    private getCurrentImageUrl = (imageId: string, imageUrls: Map<ImageUrl['imageId'], ImageUrl>, expiredUrlHandler: (imageId: string) => void) => {
        const imageUrl = imageUrls.get(imageId);
        if (!imageUrl) {
            return;
        }

        // Image urls are temporary, so we need to make sure that the url has not expired.
        const urlExpiration = this.props.imageExpirations.get(imageUrl.url!);
        const isExpired = urlExpiration && new Date() > urlExpiration;
        if (isExpired) {
            expiredUrlHandler(imageId);
            return;
        }

        return imageUrl;
    };
}

const mapState = createStructuredSelector({
    imageUrls: imageUrlsSelector,
    imageThumbnails: imageGridThumbnailsSelector,
    imageExpirations: imageExpirationsSelector,
});

const mapDispatch: DispatchProps = {
    clearThumbnailUrl: Actions.clearGridUrls,
    clearImageUrl: Actions.clearImageUrl,
};

export default connect<StateProps, DispatchProps>(mapState, mapDispatch)(CellImage);
