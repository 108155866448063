import { colors, Grid, Spacer, TypeRampV2 } from '@smartsheet/lodestar-core';
import { CheckIcon } from '@smartsheet/lodestar-icons';
import * as React from 'react';
import { AutomationIds } from '../../common/enums/AutomationElements.enum';
import ViewNewItem from '../../containers/LeftPanel/HomePanel/ViewNewItem';
import { useLanguageElements } from '../../language-elements/withLanguageElementsHOC';

export const HomeEmptyState: React.SFC = () => {
    const languageElements = useLanguageElements();
    const YOUTUBE_VIDEO_LINK = 'https://www.youtube.com/embed/2foUFy4d64I';
    const DV_HELP_PAGE = 'https://help.smartsheet.com/learning-track/integrations-and-add-ons/dynamic-view';
    const YOUTUBE_VIDEO_TITLE = 'YouTube video player';
    return (
        <div className="landing-page-container white">
            <div className="landing-page-contents">
                <Grid
                    gap="medium"
                    template={{
                        type: 'static',
                        columns: 1,
                    }}
                >
                    <Spacer space="medium" orientation="column">
                        <TypeRampV2 system="lodestar" variant="headlineMedium">
                            {languageElements.DSC_NO_DYNAMIC_VIEWS_YET}
                        </TypeRampV2>
                        <TypeRampV2 system="lodestar" variant="headerLarge">
                            {languageElements.DSC_HOME_LIST_VIEWS_EMPTY}
                        </TypeRampV2>
                        <TypeRampV2 system="lodestar" variant="titlesEmphasis">
                            <ViewNewItem
                                text={languageElements.CREATE_VIEW_TEXT}
                                controlId={AutomationIds.MAIN_NEW_VIEW}
                                class="btn-primary landing-page-button"
                            />
                        </TypeRampV2>
                        <TypeRampV2 system="lodestar" variant="bodyText">
                            <a
                                className="sheet-link-out-noflex"
                                href={DV_HELP_PAGE}
                                target="_blank"
                                data-client-id={AutomationIds.HOME_CONTACT_SALES}
                                rel="noreferrer"
                            >
                                {languageElements.DSC_LEARN_HOW_TO_USE_DV}
                            </a>
                        </TypeRampV2>
                        <TypeRampV2 system="lodestar" variant="headerLarge">
                            <hr className={'item-bottom'} />
                        </TypeRampV2>
                        <TypeRampV2 system="lodestar" variant="headerLarge" />
                    </Spacer>
                </Grid>
                <Spacer space="xLarge" orientation="row" />
                <div style={{ textAlign: 'left' }}>
                    <Grid
                        gap="large"
                        template={{
                            type: 'static',
                            columns: 2,
                        }}
                    >
                        <Spacer space="medium" orientation="column">
                            <TypeRampV2 system="lodestar" variant="headerLarge">
                                {languageElements.DSC_NO_DYNAMIC_VIEWS_HEADER}
                            </TypeRampV2>
                            <Spacer space="xxSmall" orientation="column">
                                <Spacer space="xxSmall" orientation="row">
                                    <CheckIcon size={'small'} color={colors.buttonUpgrade} />
                                    <TypeRampV2 system="lodestar" variant="bodyText">
                                        {languageElements.DSC_INFO_BULLETS_ONE}
                                    </TypeRampV2>
                                </Spacer>
                                <Spacer space="xxSmall" orientation="row">
                                    <CheckIcon size={'small'} color={colors.buttonUpgrade} />
                                    <TypeRampV2 system="lodestar" variant="bodyText">
                                        {languageElements.DSC_INFO_BULLETS_TWO}
                                    </TypeRampV2>
                                </Spacer>
                                <Spacer space="xxSmall" orientation="row">
                                    <CheckIcon size={'small'} color={colors.buttonUpgrade} />
                                    <TypeRampV2 system="lodestar" variant="bodyText">
                                        {languageElements.DSC_INFO_BULLETS_THREE}
                                    </TypeRampV2>
                                </Spacer>
                            </Spacer>
                        </Spacer>
                        <iframe
                            width="340"
                            height="191"
                            src={YOUTUBE_VIDEO_LINK}
                            title={YOUTUBE_VIDEO_TITLE}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        />
                    </Grid>
                </div>
            </div>
        </div>
    );
};
