import styled from '@emotion/styled';
import * as FocusTrap from 'focus-trap-react';
import * as React from 'react';
import { AutomationIds, AutomationTypes } from '../../../common/enums/AutomationElements.enum';
import Spinner, { Color, Size } from '../../../components/Spinner';

export const LoadingData = () => {
    /**
     * To use FocusTrap, we need to provide at least 1 focusable element, so we have created a hidden
     * input element to take the focus, such that users cannot tab through other elements while this
     * component is rendered.
     */
    return (
        <Container data-client-type={AutomationTypes.OVERLAY}>
            <FocusTrap focusTrapOptions={{ clickOutsideDeactivates: true, initialFocus: false }}>
                <div data-clientid={AutomationIds.LOADING_DATA_MODAL} data-testid={AutomationIds.LOADING_DATA_MODAL}>
                    <HiddenInput type="text" />
                    <Spinner color={Color.BLUE} size={Size.MEDIUM} />
                </div>
            </FocusTrap>
        </Container>
    );
};

const Container = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: not-allowed;
    z-index: 20;
    user-select: none;
    background: #ffffff99;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const HiddenInput = styled.input`
    opacity: 0;
    width: 0;
    height: 0;
    padding: 0;
`;
