import { FormFieldInterface } from '../../../interfaces';
import { FuncTransformForSmarUpsert } from './FuncTransformForSmarUpsert';
import { ISmarCell } from './ISmarCell';

export const transformDefaultForSmarUpsert: FuncTransformForSmarUpsert = (rowField: FormFieldInterface): ISmarCell => {
    return {
        columnId: rowField.columnId,
        value: rowField.value,
        objectValue: undefined,
        strict: false,
    };
};
