import * as React from 'react';
import { AutomationIds } from '../../common/enums/AutomationElements.enum';
import { useLanguageElements } from '../../language-elements/withLanguageElementsHOC';

export interface EmptyStateProps {
    header?: string;
    message?: string;
    applyError?: boolean;
    displayButton?: boolean;
    buttonComponent?: JSX.Element;
}

// export class EmptyState extends PureBaseComponent<EmptyStateProps> {
export const EmptyState = (props: EmptyStateProps) => {
    const languageElements = useLanguageElements();
    const { message = languageElements.NOTHING_TO_SHOW, applyError = false } = props;

    return (
        <div className={`empty-state ${applyError ? 'error' : ''}`} data-client-id={`${AutomationIds.GRID_NO_ROW}`}>
            {props.header && <h3>{props.header}</h3>}
            <pre>{message}</pre>
            {props.displayButton && props.buttonComponent}
        </div>
    );
};

export default EmptyState;
