import { domainBlocklist } from './domain-blocklist';

const isDomainBlocklisted = (domain: string): boolean => {
    if (domain === '' || !domain.includes('.')) {
        return true;
    }

    const formattedDomain = domain
        .substring(domain.indexOf('@') + 1, domain.length)
        .replace(/["']/g, '')
        .toLowerCase();

    return domainBlocklist.has(formattedDomain);
};

export { isDomainBlocklisted };
