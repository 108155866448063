import * as React from 'react';
import alertIcon from '../../assets/images/alert/modal-warning-large.svg';
import './ErrorMessage.css';

interface Props {
    message: string;
    dataClientId: string;
}
export const ErrorMessage: React.SFC<Props> = props => (
    <div className="error-message" data-client-id={props.dataClientId}>
        <img className="error-icon" src={alertIcon} alt="error" />
        <span className="error-text">{props.message}</span>
    </div>
);
