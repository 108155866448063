/* eslint react/no-find-dom-node:"off" */
import { CURRENT_USER, CURRENT_USER_DEFAULT_EMAIL } from '../../common/constants';
import { ObjectType } from '../../common/enums';
import { Contact, SmartsheetUser } from '../../common/interfaces';
import { cloneDeep, isEmailValid } from '../../common/utils';
import * as debounce from 'debounce-promise';
import * as React from 'react';
import { ReactFragment } from 'react';
import * as ReactDOM from 'react-dom';
import StateManager from 'react-select-v3';
import Select, { components } from 'react-select-v3';
import AsyncSelect from 'react-select-v3/async';
import { MouseOrTouchEvent } from 'react-select-v3/base';
import { IndicatorProps } from 'react-select-v3/src/components/indicators';
import { MenuListComponentProps } from 'react-select-v3/src/components/Menu';
import { MultiValueProps, MultiValueRemoveProps } from 'react-select-v3/src/components/MultiValue';
import { OptionProps } from 'react-select-v3/src/components/Option';
import { SingleValueProps } from 'react-select-v3/src/components/SingleValue';
import { Option } from 'react-select-v3/src/filters';
import { ActionMeta, InputActionMeta } from 'react-select-v3/src/types';
import 'react-select/dist/react-select.css';
import closeButtonSvg from '../../assets/images/selectV2/close-buttonV2.svg';
import dropDownSvg from '../../assets/images/selectV2/dropDown.svg';
import alertIcon from '../../assets/images/selectV2/icon-alert.svg';
import selectedIcon from '../../assets/images/selectV2/selectedV2.svg';
import unselectedIcon from '../../assets/images/selectV2/unSelectedV2.svg';
import { AutomationIds } from '../../common/enums/AutomationElements.enum';
import { getUserInitialCssClass } from '../../common/utils/GetUserInitialCssClass';
import { getUserInitials } from '../../common/utils/GetUserInitials';
import { removeDuplicateOptions } from '../../common/utils/RemoveDuplicateOptions';
import usersClient from '../../http-clients/Users.client';
import { AriaProps, DataClientProps, PureBaseComponent } from '../Base';
import ModalWrapper from '../Modal';
import './ContactPicker.css';
import { ContactPickerOption } from './ContactPickerOption.interface';
import { withLanguageElementsHOC, LanguageElementsProp } from '../../language-elements/withLanguageElementsHOC';

interface ContactPickerProps extends DataClientProps, AriaProps {
    inPlace?: boolean; // indicates whether we render the control in place with other controls in the same tab context
    multi: boolean;
    disabled?: boolean;
    allowClearing: boolean;
    enableOnDemandOptions?: boolean; // when true, loads an async-select and will dynamically load/filter Smartsheet contacts based on input string

    // when true, validates props.selectedOptions to ensure emails are valid, and selectedOptions are all part of allOptions
    validateExistingSelections?: boolean;
    maxLength?: number;
    placeholder?: string;
    classNames?: string;
    allOptions?: Contact[];
    selectedOptions?: Contact | Contact[];
    isValidNewOption?: (
        newOption: string,
        allowFreeTextOptionCallback: (isValid?: boolean) => void,
        cancelFreeTextOptionCallback: () => void
    ) => void;
    onChange: (value?: Contact | Contact[]) => void;
    onEntryStop?: (direction: MoveDirection) => void;
    validateSelection?: (selectedOptions: ContactPickerOption[]) => SelectionValidationResult;
    setFocusRef?: (ref: Element | null) => void; // Used by Adapter to deal with BbControl focus/input management.
}

interface ContactPickerState {
    inputValue: string;
    hasError: boolean;
    localizedError?: string;
    menuIsOpen: boolean;
    allOptions: ContactPickerOption[];
    selectedOptions: ContactPickerOption[];
    showDuplicateOptionWarning: boolean;
}

interface SelectType {
    value: string;
    label: string;
}

export enum MoveDirection {
    RIGHT,
    DOWN,
}

export const SELECT_AUTO_CLOSE_MILLIS = 5000;

export interface SelectionValidationResult {
    isValid: boolean;
    localizedError: string | null;
}

export const INPUT_CHANGE = 'input-change';
export const TAB = 'Tab';
export const ENTER = 'Enter';
export const CONTACT_PICKER = 'contact-picker';
export const SELECT_OPTION = 'select-option';

export class ContactPicker extends PureBaseComponent<ContactPickerProps & LanguageElementsProp> {
    public state: ContactPickerState;
    private selectRef: StateManager<ContactPickerOption> | null;
    private errorAutoCloseTimeout: number;
    private debounceOnChange = debounce(async (input: string): Promise<ContactPickerOption[]> => {
        const users = await this.getUsersByEmail(input);
        this.setState({ allOptions: users });
        return users;
    }, 400);

    public constructor(props: ContactPickerProps & LanguageElementsProp) {
        super(props);
        this.state = this.buildStateFromProps(props);
    }

    public render(): React.ReactNode {
        const { allOptions, selectedOptions, inputValue, menuIsOpen } = this.state;
        const { classNames, dataClientId, disabled, enableOnDemandOptions } = this.props;
        const config = this.getConfiguration();
        const defaultFilter = this.getDefaultFilter();

        return (
            <div className={classNames} data-client-id={dataClientId} data-testid={dataClientId}>
                {!enableOnDemandOptions && (
                    <Select
                        {...config}
                        ref={(ref: any) => {
                            this.selectRef = ref;
                        }}
                        value={selectedOptions}
                        onChange={this.handleChange}
                        options={allOptions}
                        inputValue={inputValue}
                        onInputChange={this.handleInputChange}
                        onKeyDown={this.handleKeyDown}
                        menuIsOpen={menuIsOpen}
                        noOptionsMessage={() => this.props.languageElements.NO_RESULTS_FOUND}
                        onMenuOpen={this.handleMenuOpen}
                        onMenuClose={this.handleMenuClose}
                        filterOption={defaultFilter}
                        isDisabled={disabled}
                        isClearable={this.props.allowClearing}
                        components={{
                            MenuList: this.optionListRenderer,
                            Option: this.optionRenderer,
                            IndicatorSeparator: null,
                            MultiValue: this.multiValueRenderer,
                            SingleValue: this.singleValueRenderer,
                            MultiValueRemove: this.closeButtonRenderer,
                            DropdownIndicator: this.dropdownIndicatorRenderer,
                        }}
                    />
                )}
                {enableOnDemandOptions && (
                    <AsyncSelect
                        {...config}
                        ref={(ref: any) => {
                            this.selectRef = ref == null ? ref : ref.select;
                        }}
                        value={selectedOptions}
                        onChange={this.handleChange}
                        options={allOptions}
                        inputValue={inputValue}
                        onInputChange={this.handleInputChange}
                        onKeyDown={this.handleKeyDown}
                        menuIsOpen={menuIsOpen}
                        onMenuOpen={this.handleMenuOpen}
                        onMenuClose={this.handleMenuClose}
                        isDisabled={disabled}
                        defaultOptions={allOptions}
                        loadOptions={this.handleLoadOnDemandOptions}
                        isClearable={this.props.allowClearing}
                        components={{
                            MenuList: this.optionListRenderer,
                            Option: this.optionRenderer,
                            IndicatorSeparator: null,
                            MultiValue: this.multiValueRenderer,
                            SingleValue: this.singleValueRenderer,
                            MultiValueRemove: this.closeButtonRenderer,
                            DropdownIndicator: this.dropdownIndicatorRenderer,
                        }}
                    />
                )}
                {this.state.showDuplicateOptionWarning && this.renderDuplicateOptionWarning()}
            </div>
        );
    }

    public componentDidMount(): void {
        const { setFocusRef, inPlace } = this.props;

        if (inPlace) {
            if (setFocusRef) {
                // Get DOM node of SelectV2 so we can use typical DOM methods
                const node = ReactDOM.findDOMNode(this);
                if (node instanceof HTMLElement) {
                    const inputRef = node.querySelector(`.${CONTACT_PICKER}__input input`);
                    setFocusRef(inputRef);
                }
            }
        } else {
            if (this.selectRef) {
                const internalSelect = this.selectRef.select;
                // need to override original third-party control which closes dropdown on input click
                internalSelect.onControlMouseDown = (event: MouseOrTouchEvent) => {
                    event.preventDefault();
                };
            }
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps: Readonly<ContactPickerProps & LanguageElementsProp>, nextContext: any): void {
        const newState = this.buildStateFromProps(nextProps);
        this.setState(newState);
    }

    public handleInputChange = (inputValue: string, actionMeta: InputActionMeta) => {
        let isValidInput = true;
        if (actionMeta.action === INPUT_CHANGE) {
            const newOption = { label: inputValue, value: inputValue, isCurrentUser: false };
            isValidInput = this.isNewOptionSelectionValid(newOption);
        }

        if (isValidInput) {
            this.setState({
                hasError: false,
                inputValue,
            });
        }
    };

    public handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        let moveDirection: MoveDirection = MoveDirection.DOWN;
        switch (event.key) {
            case TAB:
                this.handleSelectNewOption(event);
                moveDirection = MoveDirection.RIGHT;
                if (this.state.menuIsOpen) {
                    this.setState({ menuIsOpen: false });
                }
                break;
            case ENTER:
                this.handleSelectNewOption(event);
                if (this.state.menuIsOpen) {
                    this.setState({ menuIsOpen: false });
                }
                if (this.props.inPlace) {
                    return;
                }
                break;
        }

        if (this.props.onEntryStop) {
            this.props.onEntryStop(moveDirection);
        }
    };

    public handleSelectNewOption = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (!this.selectRef) {
            return;
        }

        const internalSelect = this.selectRef.select;
        const hasFocusedOption = internalSelect.state.focusedOption;

        // handleChange() will take responsibility if an option is focusing
        if (hasFocusedOption) {
            return;
        }

        const option = (event.target as HTMLInputElement).value.trim();

        if (!option) {
            return;
        }

        // Prevent default behavior.
        // E.g: - Blur input box when press Tab to create token
        //      - Put focus on next node when press Tab to commit value to cell
        event.preventDefault();

        // Don't process new items if the value is already in the selected options.
        if (this.state.selectedOptions.some((selectedOption) => selectedOption.value === option)) {
            this.setState({ showDuplicateOptionWarning: true });
            return;
        }

        // new free text option will be created
        const allowFreeTextOption = (isValid: boolean) => {
            this.addFreeTextOption(option, isValid);
        };

        // new free text option will not be created
        const cancelFreeTextOption = () => {
            this.setState({ menuIsOpen: true });
            this.selectRef!.select.setState({ focusedOption: null });
        };
        if (this.props.isValidNewOption) {
            // consumer will validate new token and execute one of the callbacks
            this.props.isValidNewOption(option, allowFreeTextOption, cancelFreeTextOption);
        } else {
            this.addFreeTextOption(option, true);
        }
    };

    /**
     * takes the passed in input value, and creates a new option for it such that the option
     * can be displayed in both as a selectedValue, and in the dropdown list of available
     * options.
     */
    public addFreeTextOption = (inputValue: string, isValid: boolean) => {
        if (!inputValue) {
            return;
        }

        let { allOptions, selectedOptions } = this.state;
        const freeTextOption: ContactPickerOption = {
            label: inputValue,
            value: inputValue,
            isFreeText: true,
            isValid,
            isCurrentUser: false,
        };

        allOptions = [...allOptions, freeTextOption];
        selectedOptions = this.props.multi ? [...selectedOptions, freeTextOption] : [freeTextOption];

        this.setState(
            {
                allOptions,
                selectedOptions,
                inputValue: '',
                menuIsOpen: true,
            },
            () => this.handleAfterSetState(selectedOptions)
        );
    };

    public handleMenuOpen = () => {
        this.setState({ menuIsOpen: true });
    };

    public handleMenuClose = () => {
        this.setState({
            hasError: false,
            menuIsOpen: false,
        });
    };

    /**
     * called by the async-select component when the input changes, passes off the input
     * value to our debounce function which in turns calls to DV to retrieve Smartsheet users
     * by their email address.
     */
    public handleLoadOnDemandOptions = (inputValue: string) => {
        return this.debounceOnChange(inputValue);
    };

    private renderDuplicateOptionWarning = () => (
        <ModalWrapper isModalOpen={true} onClose={() => this.setState({ showDuplicateOptionWarning: false })}>
            {this.props.languageElements.ADMIN_PANEL_PERMISSIONS_USERS_DUPLICATE_SELECTION}
        </ModalWrapper>
    );

    /**
     * provides a case-insensitive, multi-word match filter where each word in the
     * input string is split, combined into an array, and then each options value, and labels are
     * searched for matches of any of the words in the input string.
     */
    private getDefaultFilter(): (option: Option, rawInput: string) => boolean {
        return (option, rawInput) => {
            const words = rawInput.split(' ');
            return words.reduce(
                (acc, cur) =>
                    acc && (option.label.toLowerCase().includes(cur.toLowerCase()) || option.value.toLowerCase().includes(cur.toLowerCase())),
                true
            );
        };
    }

    /**
     * get configuration values for both Select and AsyncSelect
     */
    private getConfiguration(): any {
        return {
            classNamePrefix: CONTACT_PICKER, // all inner elements will be given a className with 'selectV2__' prefix.
            className: `${CONTACT_PICKER}-wrapper`,
            isMulti: this.props.multi,
            closeMenuOnSelect: !this.props.multi,
            openMenuOnClick: true,
            hideSelectedOptions: false,
            isClearable: true,
            placeholder: this.props.placeholder || '',
            tabSelectsValue: false,
        };
    }

    /**
     * take in component props and construct internal state from it
     */
    private buildStateFromProps(props: ContactPickerProps & LanguageElementsProp): ContactPickerState {
        return {
            inputValue: '',
            allOptions: this.buildAllOptions(props.allOptions),
            selectedOptions: this.buildSelectedOptions(props.selectedOptions || [], props.allOptions || [], props.validateExistingSelections),
            hasError: false,
            menuIsOpen: this.state ? this.state.menuIsOpen : false,
            showDuplicateOptionWarning: false,
        };
    }

    /**
     * convert the array of contact provided by props to ContactPickerOption[]
     */
    private buildAllOptions = (options?: Contact[]): ContactPickerOption[] => {
        if (!options) {
            return [];
        }

        return this.mapContactsToSelectOptions(options);
    };

    /**
     * takes the passed in options (contact object, or contacts array) and creates an array of ContactPickerOptions
     * from the options array, then removes duplicates.
     */
    private buildSelectedOptions = (
        options: Contact | Contact[],
        allOptions: Contact[],
        validateSelectedOptions: boolean = false
    ): ContactPickerOption[] => {
        if (!options) {
            return [];
        }

        if (!Array.isArray(options)) {
            options = [options];
        }

        const selectOptions = this.mapContactsToSelectOptions(options as Contact[], allOptions, validateSelectedOptions);

        return removeDuplicateOptions<ContactPickerOption>(cloneDeep(selectOptions));
    };

    /**
     * maps an array of Contacts to ContactPickerOptions. We pass in allContacts, and validateSelectedOptions
     * such that we can determine if a given contact is valid (validateSelectedOptions = false,
     * or validateSelectedOptions = true, and the contact is contained within allContacts)
     */
    private mapContactsToSelectOptions = (
        contacts: Contact[],
        allContacts?: Contact[],
        validateSelectedOptions: boolean = false
    ): ContactPickerOption[] => {
        return contacts.map((contact: Contact) => this.mapContactToSelectOption(contact, allContacts, validateSelectedOptions));
    };

    /**
     * maps an array of SmartsheetUsers to Contacts
     */
    private mapSmartsheetUsersToContacts = (users: SmartsheetUser[]): Contact[] => {
        return users.map((user: SmartsheetUser) => this.mapSmartsheetUserToContact(user));
    };

    /**
     * maps a SmartsheetUser to a Contact. This is necessary because SmartsheetUser's are returned with
     * an ID of type number, and Contacts are returned with an ID of type string.
     */
    private mapSmartsheetUserToContact = (user: SmartsheetUser): Contact => {
        return {
            id: String(user.id),
            name: user.name,
            email: user.email,
            objectType: ObjectType.CONTACT,
        };
    };

    /**
     * maps a Contact to a ContactPickerOption. Since the contact picker needs some additional information
     * to properly present its values (pills), we calculate those there. Specifically we determine if the given contact
     * is valid (column validation enabled, and the user is not present in the given columns contact options), as well as
     * if the contact is a special Current User value, lastly validating if the given contact has a valid email.
     */
    private mapContactToSelectOption = (
        contact: Contact,
        allContacts: Contact[] = [],
        validateSelectedContacts: boolean = false
    ): ContactPickerOption => {
        let isValid = false;
        let isCurrentUser = false;
        if (contact.email && contact.email === CURRENT_USER_DEFAULT_EMAIL) {
            isValid = true;
            isCurrentUser = true;
        }
        if (contact.email && contact.email !== CURRENT_USER_DEFAULT_EMAIL) {
            isValid = isEmailValid(contact.email);
        }
        if (validateSelectedContacts) {
            isValid = allContacts.some((currentContact: Contact) => {
                if (currentContact.email || contact.email) {
                    return currentContact.email === contact.email;
                }

                if (currentContact.name || contact.name) {
                    return (currentContact.name || '').toLowerCase() === (contact.name || '').toLowerCase();
                }

                return false;
            });
        }

        return {
            value: contact.email || contact.name || '',
            label: contact.name || contact.email || '',
            isValid,
            isCurrentUser,
        };
    };

    /**
     * maps an array of ContactPickerOptions, to an array of Contacts (including objectType)
     */
    private mapSelectOptionsToContacts = (options: ContactPickerOption[]): Contact[] => {
        if (!options) {
            return [];
        }

        return options.map((option: ContactPickerOption) => this.mapSelectOptionToContact(option));
    };

    /**
     * maps the singular output of the contact picker (ContactPickerOption) to a Contact interface
     * containing objectType
     */
    private mapSelectOptionToContact = (option: ContactPickerOption): Contact => {
        let email = option.value;
        if (!this.props.multi && option.isCurrentUser) {
            // Currently, on the form builder for a single contact picker we store the initial value as "**CURRENT_USER**" for current user. However,
            // for multi-contact picker we store "current user's email" for the default value. Ideally we need to have consistency approach for both
            // single and multi contact pickers. However, to reduce the scope this should be taken on as a separate story and needs to take
            // backwards compatiblity into consideration
            email = CURRENT_USER;
        }

        return {
            email,
            name: option.label,
            objectType: ObjectType.CONTACT,
            isValid: option.isValid,
        };
    };

    /**
     * retrieves the current value of the component to pass to props.onChange. Depending on the mode
     * of the component (single/multi), return a single contact, or an array of contacts.
     */
    private getOnChangeSelectValue = (
        options?: ContactPickerOption | ContactPickerOption[],
        multi: boolean = false
    ): undefined | Contact | Contact[] => {
        if (!options || (Array.isArray(options) && !options.length) || (Array.isArray(options) && !options[0])) {
            return multi ? [] : undefined;
        }

        if (!multi) {
            const selectedOptions = Array.isArray(options) ? options[0] : options;
            return this.mapSelectOptionToContact(selectedOptions);
        }

        return this.mapSelectOptionsToContacts(options as ContactPickerOption[]);
    };

    /**
     * renders a circular div with a users initials as the center text
     */
    private userIconRenderer = (option: ContactPickerOption): ReactFragment => {
        const userInitials: string = getUserInitials(option.label || option.value);
        return (
            <div
                data-client-type={AutomationIds.COMMENT_COMMENTS_ICON}
                className={'icon-user initials ' + getUserInitialCssClass(userInitials)}
                title={option.value}
            >
                {userInitials}
            </div>
        );
    };

    /**
     * renders a singular option for use in a dropdown list of options
     */
    private optionRenderer = (props: OptionProps<SelectType>) => {
        const checkbox = props.isSelected ? (
            <img className="optionCheckbox" alt={`Select ${props.label}`} src={selectedIcon} />
        ) : (
            <img className="optionCheckbox" alt={`Unselect ${props.label}`} src={unselectedIcon} />
        );

        const userIcon = this.userIconRenderer(props.data);

        return (
            <components.Option data-client-type={AutomationIds.CONTACT_PICKER_OPTION} {...props}>
                {props.isMulti && checkbox}
                <div className="icon-user-container">{userIcon}</div>
                <div className="optionContainer" data-client-type={AutomationIds.CONTACT_PICKER_OPTION}>
                    <label className="optionLabel">{props.label}</label>
                    <label className="optionSubLabel">{props.data.value}</label>
                </div>
            </components.Option>
        );
    };

    /**
     * renders the dropdown list of options
     */
    private optionListRenderer = (props: MenuListComponentProps<SelectType>) => {
        const optionsToRender = props.children;
        return (
            <React.Fragment>
                {this.errorBoxRenderer()}
                <components.MenuList {...props}>{optionsToRender}</components.MenuList>
            </React.Fragment>
        );
    };

    /**
     * renders a dropdown indicator
     */
    private dropdownIndicatorRenderer = (props: IndicatorProps<ContactPickerOption>) => {
        const {
            innerProps: { ref, ...restInnerProps },
        } = props;

        return (
            <div ref={ref} {...restInnerProps} data-client-type={AutomationIds.CONTACT_PICKER_CLOSE_BUTTON}>
                <img className="dropdownIndicator" src={dropDownSvg} />
            </div>
        );
    };

    /**
     * renders a box to display an error message
     */
    private errorBoxRenderer = () => {
        const { hasError, localizedError } = this.state;

        if (hasError && localizedError) {
            return (
                <div className="errorBox">
                    <img className="errorIcon" src={alertIcon} />
                    <span className="errorText">{localizedError}</span>
                </div>
            );
        }

        return;
    };

    /**
     * renders a single-value contact, without the pill background or close button
     */
    private singleValueRenderer = (props: SingleValueProps<ContactPickerOption>) => {
        const userIcon = this.userIconRenderer(props.data);

        return (
            <div
                className="contact-picker__multi-value__wrapper"
                data-client-type={AutomationIds.CONTACT_PICKER_SINGLE_SELECTED_OPTION}
                onClick={() => this.selectToken(props.data)}
            >
                <components.SingleValue {...props}>
                    {userIcon}
                    <label>{props.data.label}</label>
                </components.SingleValue>
            </div>
        );
    };

    /**
     * renders a multi-value contact pill
     */
    private multiValueRenderer = (props: MultiValueProps<ContactPickerOption>) => {
        const { isFocused } = props;
        let className = isFocused ? 'contact-picker__token-selected' : '';
        if (props.data.isValid === false) {
            className += ` ${CONTACT_PICKER}-token-invalid`;
        }
        const userIcon = this.userIconRenderer(props.data);

        return (
            <div
                className="contact-picker__multi-value__wrapper"
                onClick={() => this.selectToken(props.data)}
                data-client-type={AutomationIds.CONTACT_PICKER_MULTI_SELECTED_OPTION}
            >
                <components.MultiValue {...props} className={className}>
                    {userIcon}
                    <label title={props.data.value} data-client-type={AutomationIds.CONTACT_PICKER_SELECTED_OPTION_LABEL}>
                        {props.data.label}
                    </label>
                </components.MultiValue>
            </div>
        );
    };

    /**
     * renders close button for a multi-value contact pill
     */
    private closeButtonRenderer = (props: MultiValueRemoveProps<ContactPickerProps & LanguageElementsProp>) => {
        return (
            <components.MultiValueRemove {...props}>
                <span className="closeButton" data-client-type={AutomationIds.CONTACT_PICKER_SELECTED_OPTION_CLOSE_BUTTON}>
                    <img src={closeButtonSvg} />
                </span>
            </components.MultiValueRemove>
        );
    };

    /**
     * selects a token by changing state of react-select component
     */
    private selectToken = (focusedValue: ContactPickerOption) => {
        if (this.selectRef) {
            const internalSelect = this.selectRef.select;
            internalSelect.setState({ focusedValue });
        }
    };

    /**
     * Handler for when a contact option is selected in either a single contact, or multi contact column. If the selected option/
     * options contain the current user selection, set the value to the current user before calling on change.
     */
    private handleChange = (selectedValue: ContactPickerOption[], actionMeta: ActionMeta & { option: ContactPickerOption }): void => {
        if (actionMeta.action === SELECT_OPTION && !this.isNewOptionSelectionValid(actionMeta.option)) {
            return;
        }

        if (!Array.isArray(selectedValue)) {
            selectedValue = [selectedValue];
        }

        // validation passed -> good to update the state
        this.setState(
            {
                hasError: false,
                selectedValue,
            },
            () => this.handleAfterSetState(selectedValue)
        );
    };

    /**
     * handle calling on change after setstate is done processing
     */
    private handleAfterSetState = (selectedValue?: ContactPickerOption[]) => {
        if (this.props.onChange) {
            const onChangeContacts = this.getOnChangeSelectValue(selectedValue, this.props.multi);
            this.props.onChange(onChangeContacts);
        }
    };

    /**
     * validates newly selected options
     */
    private isNewOptionSelectionValid = (newOption: ContactPickerOption) => {
        if (!newOption) {
            return true;
        }

        const { validateSelection } = this.props;
        if (!validateSelection) {
            return true;
        }

        const selectedOptions = this.state.selectedOptions.length > 0 ? [...this.state.selectedOptions, newOption] : [newOption];

        const { isValid, localizedError } = validateSelection(selectedOptions);
        if (!isValid) {
            this.setState({
                hasError: true,
                localizedError,
            });

            clearTimeout(this.errorAutoCloseTimeout);
            this.errorAutoCloseTimeout = window.setTimeout(() => {
                this.setState({ hasError: false });
            }, SELECT_AUTO_CLOSE_MILLIS);
        }

        return isValid;
    };

    /**
     * retrieve users from dv api using current input from select
     */
    private getUsersByEmail = async (email: string): Promise<ContactPickerOption[]> => {
        const result = await usersClient.queryUsers(email);
        const contacts = this.mapSmartsheetUsersToContacts(result.data);
        return this.buildAllOptions(contacts);
    };
}

export default withLanguageElementsHOC(ContactPicker);
