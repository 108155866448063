import { OwnershipTransferStatus } from '../../common/enums';
import * as dv from '../../common/interfaces';
import { ActionsUnion } from '../../store';
import { createAction } from '../../store/Utils/CreateAction';

export enum ActionTypes {
    FETCHING_OWNERSHIP_TRANSFERS = 'FETCHING_OWNERSHIP_TRANSFERS',
    STORE_OWNERSHIP_TRANSFERS = 'STORE_OWNERSHIP_TRANSFERS',
    FETCHING_ERROR_OWNERSHIP_TRANSFERS = 'FETCHING_ERROR_OWNERSHIP_TRANSFERS',
    SET_STATUS_FOR_TRANSFER = 'SET_STATUS_FOR_TRANSFER',
    STORE_OWNERSHIP_TRANSFER_ERROR = 'STORE_OWNERSHIP_TRANSFER_ERROR',
    CLEAR_OWNERSHIP_TRANSFER_ERROR = 'CLEAR_OWNERSHIP_TRANSFER_ERROR',
    FETCH_ORG_VIEWS = 'FETCH_ORG_VIEWS',
    STORE_ORG_VIEWS = 'STORE_ORG_VIEWS',
    FETCH_ORG_VIEWS_ERROR = 'FETCH_ORG_VIEWS_ERROR',
    SET_STATUS_FOR_ORG_VIEW = 'SET_STATUS_FOR_ORG_VIEW',
}

export const Actions = {
    fetchingOwnershipTransfers: () => createAction(ActionTypes.FETCHING_OWNERSHIP_TRANSFERS),
    storeOwnershipTransfers: (pendingOwnershipTransferData: dv.IPaginatedResult<dv.IOwnershipTransfer>) =>
        createAction(ActionTypes.STORE_OWNERSHIP_TRANSFERS, pendingOwnershipTransferData),
    fetchingErrorOwnershipTransfers: () => createAction(ActionTypes.FETCHING_ERROR_OWNERSHIP_TRANSFERS),
    setStatusForTransfer: (transferId: string, status: OwnershipTransferStatus) =>
        createAction(ActionTypes.SET_STATUS_FOR_TRANSFER, { transferId, status }),
    storeOwnershipTransferError: () => createAction(ActionTypes.STORE_OWNERSHIP_TRANSFER_ERROR),
    clearOwnershipTransferError: () => createAction(ActionTypes.CLEAR_OWNERSHIP_TRANSFER_ERROR),
    fetchOrgViews: () => createAction(ActionTypes.FETCH_ORG_VIEWS),
    storeOrgViews: (orgViews: dv.IOwnershipTransfer[]) => createAction(ActionTypes.STORE_ORG_VIEWS, orgViews),
    fetchOrgViewsError: () => createAction(ActionTypes.FETCH_ORG_VIEWS_ERROR),
    setStatusForOrgView: (viewId: string, status: OwnershipTransferStatus) => createAction(ActionTypes.SET_STATUS_FOR_ORG_VIEW, { viewId, status }),
};

export type Actions = ActionsUnion<typeof Actions>;
