import { IDomPosition } from '../../../common/interfaces';
import * as React from 'react';
import onClickOutside, { InjectedOnClickOutProps } from 'react-onclickoutside';
import { AutomationTypes } from '../../../common/enums/AutomationElements.enum';
import { getDropdownPosition } from '../../../common/utils/GetDropdownPosition';
import { SelectOption } from '../../../containers/Form/Interfaces/SelectOption.interface';
import { BaseComponent, DataClientProps } from '../../Base';
import arrowDown from '../assets/black.svg';
import './MenuButton.css';

/**
 * Use id to target the input field so that we can get positioning for the dropdown when FIXED positioning is needed to force
 * the dropdown to fully display outside of a scrolled container (as in app-core filter def)
 */
// TODO: note that id prop is currently used to drive positioning of the drop-down menu.
// Consider adding a boolean prop to trigger positioning instead, or better yet revise design in order to eliminate need for
// dynamic positioning.
// The dynamic positioning addresses problems with dropdown visibility inside a scrollable modal (for filter definition) but
// the problem could be eliminated by allowing the container to grow to full screen height before scrolling.
export type Props = {
    icon?: any;
    hideChevron?: boolean;
    placeholder: string;
    disabled: boolean;
    menuOpen?: boolean;
    value: string;
    options: SelectOption[];
    onClick: (option: SelectOption) => void;
    classNames?: string;
    id?: string;
    iconAltText?: string;
} & InjectedOnClickOutProps &
    DataClientProps;

interface State {
    value: string;
    placeholder: string;
    menuOpen?: boolean;
    focusIndex: number;
    dropdownPosition?: IDomPosition;
    options: SelectOption[];
}

class MenuButton extends BaseComponent<Props> {
    public state: State;

    public constructor(props: Props) {
        super(props);
        this.state = {
            placeholder: this.getPlaceholder(props.value, props.options, props.placeholder),
            menuOpen: props!.menuOpen,
            value: props.value,
            focusIndex: -1,
            dropdownPosition: undefined,
            options: props.options,
        };
    }

    public render(): React.ReactNode {
        const buttonClassNames = 'menu-button ' + (this.props.disabled ? 'disabled ' : '') + (this.state.menuOpen ? 'active ' : '');

        return (
            <div
                className={`menu-button-container ${this.props.classNames ? this.props.classNames : ''}`}
                data-client-id={this.props.dataClientId}
                tabIndex={0}
                onKeyDown={(e) => this.handleKeyDown(e)}
                id={this.props.id}
            >
                <a
                    className={buttonClassNames}
                    onClick={(e) => (this.props.disabled ? undefined : this.onClickMenuButton(e))}
                    data-client-id={this.props.dataClientId}
                >
                    {this.props.icon ? <img src={this.props.icon} alt={this.props.iconAltText || ''} /> : <span>{this.state.placeholder}</span>}
                    {!this.props.hideChevron && <img className="chevron" src={arrowDown} alt="" />}
                </a>
                {this.state.menuOpen && (
                    <div
                        className="dropdown-menu"
                        style={
                            this.state.dropdownPosition
                                ? {
                                      top: this.state.dropdownPosition.top,
                                      left: this.state.dropdownPosition.left,
                                  }
                                : undefined
                        }
                    >
                        {this.props.options &&
                            this.props.options.map((option: SelectOption, index: number) => {
                                return (
                                    <span
                                        key={option.value}
                                        className={this.state.focusIndex === index ? 'focused' : ''}
                                        data-client-type={AutomationTypes.DROP_DOWN_MENU_ITEM}
                                        onClick={() => this.onClickMenuOption(option)}
                                        onMouseEnter={() => this.handleMouseEnterMenuOption(index)}
                                    >
                                        {option.label}
                                    </span>
                                );
                            })}
                    </div>
                )}
            </div>
        );
    }

    public componentDidMount(): void {
        this.updateDropdownPosition();
    }

    public componentDidUpdate(prevProps: Props): void {
        if (this.props.value !== prevProps.value || this.props.options !== prevProps.options) {
            this.setState({
                placeholder: this.getPlaceholder(this.props.value, this.props.options, this.props.placeholder),
                menuOpen: this.props!.menuOpen,
                value: this.props.value,
                options: this.props.options,
            });
        }
        this.updateDropdownPosition();
    }

    // This event handler is used by the onClickOutside higher order component that wraps the FormFieldMenu.
    // See the following for more info: https://github.com/Pomax/react-onclickoutside#ensuring-there-is-a-click-handler
    // noinspection JSUnusedGlobalSymbols
    public handleClickOutside = (evt: any) => {
        this.setState({
            menuOpen: false,
        });
    };

    private handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        const arrowKeyCodes = [38, 40];
        const spaceKeyCode = 32;
        const enterKeyCode = 13;
        const tabKeyCode = 9;

        // If menu is open, tab key should close it and clear focusIndex
        if (this.state.menuOpen && event.keyCode === tabKeyCode) {
            this.closeMenu();
            this.setState({
                focusIndex: -1,
            });
        }

        // Space-bar key should toggle menu and clear focusIndex
        if (event.keyCode === spaceKeyCode) {
            // this.onClickMenuOption(this.props.options[focusIndex]);
            const menuOpen = !this.state.menuOpen;
            this.setState({
                menuOpen,
                focusIndex: -1,
            });
        }

        // If menu is open, up & down arrow keys should adjust focusIndex
        if (this.state.menuOpen && arrowKeyCodes.includes(event.keyCode)) {
            const focusIndex = this.getFocusIndex(this.state.focusIndex, event.keyCode, this.props.options.length);
            this.setState({
                focusIndex,
            });
        }

        // If menu is open, enter key should select that option, close menu and reset focusIndex
        if (this.state.menuOpen && event.keyCode === enterKeyCode) {
            this.onClickMenuOption(this.props.options[this.state.focusIndex]);
        }
    };

    private handleMouseEnterMenuOption = (index: number) => {
        this.setState({
            focusIndex: index,
        });
    };

    private closeMenu = () => {
        if (this.state.menuOpen) {
            this.setState({
                menuOpen: false,
            });
        }
    };

    private getFocusIndex(currentFocusIndex: number, keyCode: number, optionsListLength: number): number {
        let calculatedFocusIndex = 0;
        if (currentFocusIndex === -1 && keyCode === 40) {
            calculatedFocusIndex = currentFocusIndex + 1;
        } else if ((currentFocusIndex === -1 || currentFocusIndex === 0) && keyCode === 38) {
            calculatedFocusIndex = optionsListLength - 1;
        } else if (currentFocusIndex > -1 && keyCode === 40 && currentFocusIndex + 1 < optionsListLength) {
            calculatedFocusIndex = currentFocusIndex + 1;
        } else if (currentFocusIndex > -1 && keyCode === 38 && currentFocusIndex - 1 > 0) {
            calculatedFocusIndex = currentFocusIndex - 1;
        }

        return calculatedFocusIndex;
    }

    private getPlaceholder(value: string, options: SelectOption[], placeholder: string): string {
        let currentPlaceholder = placeholder;
        const currentOption = options.find((option: SelectOption) => {
            return (option.value ? option.value.toString() : '') === value;
        });
        if (currentOption) {
            currentPlaceholder = currentOption.label;
        }
        return currentPlaceholder;
    }

    private onClickMenuButton(e: any): void {
        if (this.props.disabled) {
            e.preventDefault();
        }
        const menuOpen = !this.state.menuOpen;
        this.setState({
            menuOpen,
        });
    }

    private onClickMenuOption(value: SelectOption): void {
        const menuOpen = !this.state.menuOpen;
        let placeholder = this.state.placeholder;
        if (value) {
            placeholder = value.label;
        }
        this.setState({
            menuOpen,
            placeholder,
            focusIndex: -1,
        });
        this.props.onClick(value);
    }

    private updateDropdownPosition = (): void => {
        if (this.props.id != null) {
            const dropdownPosition = getDropdownPosition(this.props.id);
            if (
                this.state.dropdownPosition == null ||
                this.state.dropdownPosition.left !== dropdownPosition.left ||
                this.state.dropdownPosition.top !== dropdownPosition.top
            ) {
                this.setState({ dropdownPosition });
            }
        }
    };
}

export default onClickOutside(MenuButton);
