import { AsyncStatus } from '../../common/enums';
import { IOwnershipTransfer } from '../../common/interfaces';
import { fromJS } from 'immutable';
import { IAsyncResult } from '../../store';
import { Actions as AdminActions, ActionTypes as AdminActionTypes } from '../Admin/Actions';
import { Actions, ActionTypes } from './Actions';

const initialState = fromJS({
    pendingOwnershipTransfers: {
        status: AsyncStatus.NOT_STARTED,
        data: [],
    },
    orgViews: {
        status: AsyncStatus.NOT_STARTED,
        data: [],
    },
});

const reducer = (state = initialState, action: Actions | AdminActions) => {
    switch (action.type) {
        case AdminActionTypes.STORE_OWNERSHIP_TRANSFER:
            const transfers = state.get('pendingOwnershipTransfers').get('data').toJS();
            transfers.push(action.payload);
            return state.setIn(['pendingOwnershipTransfers', 'data'], fromJS(transfers));

        case ActionTypes.FETCHING_OWNERSHIP_TRANSFERS:
            return state.setIn(['pendingOwnershipTransfers', 'status'], AsyncStatus.IN_PROGRESS);

        case ActionTypes.STORE_OWNERSHIP_TRANSFERS:
            const data = Boolean(action) && Boolean(action.payload) ? action.payload.data : [];

            // TODO: this should not be converted to an immutableJS Map with fromJS. from JS should be removed
            // so don't use this as a reference
            return state.set(
                'pendingOwnershipTransfers',
                fromJS({
                    data,
                    status: AsyncStatus.DONE,
                })
            );

        case ActionTypes.FETCHING_ERROR_OWNERSHIP_TRANSFERS:
            return state.setIn(['pendingOwnershipTransfers', 'status'], AsyncStatus.ERROR);

        case ActionTypes.SET_STATUS_FOR_TRANSFER:
            const transferId = action.payload.transferId;
            const status = action.payload.status;
            const pendingOwnershipTransfers = state.get('pendingOwnershipTransfers').toJS();
            const transferIndex = getTransferIndex(transferId, pendingOwnershipTransfers);

            if (transferIndex !== -1) {
                pendingOwnershipTransfers.data[transferIndex].status = status;
            }

            return state.set('pendingOwnershipTransfers', fromJS(pendingOwnershipTransfers));

        case ActionTypes.STORE_OWNERSHIP_TRANSFER_ERROR:
            return state.set('error', true);

        case ActionTypes.CLEAR_OWNERSHIP_TRANSFER_ERROR:
            return state.delete('error');

        case ActionTypes.FETCH_ORG_VIEWS:
            return state.setIn(['orgViews', 'status'], AsyncStatus.IN_PROGRESS);

        case ActionTypes.STORE_ORG_VIEWS:
            return state.set(
                'orgViews',
                fromJS({
                    data: action.payload,
                    status: AsyncStatus.DONE,
                })
            );

        case ActionTypes.FETCH_ORG_VIEWS_ERROR:
            return state.setIn(['orgViews', 'status'], AsyncStatus.ERROR);

        case ActionTypes.SET_STATUS_FOR_ORG_VIEW:
            const viewId = action.payload.viewId;
            const orgViewsState = state.get('orgViews').toJS();
            const viewIndex = getViewIndex(viewId, orgViewsState);

            if (viewIndex !== -1) {
                orgViewsState.data[viewIndex].status = action.payload.status;
            }

            return state.set('orgViews', fromJS(orgViewsState));
    }

    return state;
};

export const getTransferIndex = (transferId: string, pendingOwnershipTransfers: IAsyncResult<IOwnershipTransfer[]>): number => {
    return pendingOwnershipTransfers.data!.findIndex((transfer: IOwnershipTransfer) => transfer.id === transferId);
};

export const getViewIndex = (viewId: string, orgViews: IAsyncResult<IOwnershipTransfer[]>): number => {
    return orgViews.data!.findIndex((transfer: IOwnershipTransfer) => transfer.view.id === viewId);
};

export default reducer;
