import * as React from 'react';
import { dateTimeFormat } from '../../../../common/DateTimeFormat';
import { AutomationIds, AutomationTypes } from '../../../../common/enums/AutomationElements.enum';
import { LanguageElementsProp, withLanguageElementsHOC } from '../../../../language-elements/withLanguageElementsHOC';
import './Attachment.css';
import { AttachmentItemProps, AttachmentItemState } from './Attachment.interface';

const availableMimeTypes = [
    'application/octet-stream',
    'application/msword',
    'application/vnd.ms-excel',
    'application/vnd.ms-powerpoint',
    'application/pdf',
    'text/plain',
    'application/zip',
    'application/vnd.ms-project',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'image/png',
    'image/jpeg',
    'image/gif',
    'image',
    'text/html',
    'text/x-vcard',
    'application/msoutlook',
    'audio/mp3',
    'video/x-ms-wmv',
    'application/vnd.google-apps.document',
    'application/vnd.google-apps.spreadsheet',
    'application/vnd.google-apps.presentation',
    'text/calendar;charset=UTF-8',
    'image/svg+xml',
    'text/xml',
    'application/vnd.google-apps.drawing',
    'application/vnd.google-apps.folder',
    'text/csv',
];

export class AttachmentItemLegacy extends React.Component<AttachmentItemProps & LanguageElementsProp, AttachmentItemState> {
    public constructor(props: AttachmentItemProps & LanguageElementsProp) {
        super(props);
        this.progressListener = this.progressListener.bind(this);

        this.state = {
            percent: -1,
        };
    }

    public render(): JSX.Element {
        const createdAt = dateTimeFormat(this.props.userLocale, this.props.userTimeZone).format(new Date(this.props.createdAt));

        let style;
        if (this.state.percent >= 0) {
            if (this.state.percent < 50) {
                style = {
                    // eslint-disable-next-line max-len
                    backgroundImage: `linear-gradient(90deg, #fff 50%, transparent 50%, transparent),linear-gradient(${
                        90 + this.state.percent * 3.6
                    }deg, #63b203 50%, #fff 50%, #fff)`,
                };
            } else {
                style = {
                    // eslint-disable-next-line max-len
                    backgroundImage: `linear-gradient(${
                        (270 + (this.state.percent - 50) * 3.6) % 360
                    }deg, #63b203 50%, transparent 50%, transparent),linear-gradient(270deg, #63b203 50%, #fff 50%, #fff)'`,
                };
            }
        } else {
            style = {
                display: 'none',
            };
        }

        if (this.props.uploader) {
            this.props.uploader.attachProgressListener((percent) => this.progressListener(percent));
        }
        let cssClass: string;
        const mimeType = this.props.mimeType || 'text/plain';

        if (availableMimeTypes.indexOf(mimeType) !== -1) {
            cssClass = mimeType.replace(/[\/.=+;]/g, '-');
        } else {
            cssClass = '';
        }
        let createByStamp = createdAt;
        createByStamp += this.props.createdBy ? `${this.props.languageElements.DETAIL_ATTACHMENTS_BY}${this.props.createdBy.name || ''}` : '';
        createByStamp += ` (${this.props.sizeInKb}k)`;
        return (
            <div data-client-type={AutomationTypes.ATTACHMENT_ITEM_TYPE} className="attachment-item">
                <div data-client-id={AutomationIds.ATTACHMENT_ITEM_ATTACHMENT_ICON} className={'attachment-icon mime-type-' + cssClass} />
                <div className="attachment-details">
                    <a
                        data-client-id={AutomationIds.ATTACHMENT_ITEM_ATTACHMENT_NAME}
                        className="attachment-name"
                        href={this.props.url}
                        target="_blank"
                        rel="noreferrer"
                        onClick={(url) => this.props.onClick(this.props.url)}
                        title={this.props.name}
                    >
                        {this.props.name}
                        <div data-client-id={AutomationIds.ATTACHMENT_ITEM_ATTACHMENT_INFO} className="attachment-info">
                            <small>{createByStamp}</small>
                        </div>
                    </a>
                </div>
                <div className="attachment-loading" style={style}>
                    {this.state.percent === 100 ? <div className="item-icon icon-check" /> : ''}
                </div>
            </div>
        );
    }

    private progressListener(percent: number): void {
        this.setState({
            percent,
        });
    }
}

export default withLanguageElementsHOC(AttachmentItemLegacy);
