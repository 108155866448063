import {
    FormInterface,
    Group,
    IPaginatedResult,
    ShareError,
    SmartsheetUser,
    ViewConfig,
    ViewShareBasic,
    ViewSourceMetaData,
    ViewWithOwnerAndUserDetails,
} from '../../common/interfaces';
import { createSelector } from 'reselect';
import { StoreState } from '../../store';
import convertImmutableObjectToJS from '../../store/Utils/ConvertImmutableObjectToJS';
import hasData from '../../store/Utils/HasData';

const selectView = (state: StoreState) => state.admin.get('view');
const selectConfig = (state: StoreState) => state.admin.get('config');
const selectShares = (state: StoreState) => state.admin.get('shares');
const selectShareErrors = (state: StoreState) => state.admin.get('shareErrors');
const selectForm = (state: StoreState) => state.admin.get('form');
const selectViewSourceMetaData = (state: StoreState) => state.admin.get('viewSourceMetaData');
const selectSmartsheetGroups = (state: StoreState) => state.admin.get('smartsheetGroups');
const selectSmartsheetUsers = (state: StoreState) => state.admin.get('smartsheetUsers');
const selectSmartsheetAdmins = (state: StoreState) => state.admin.get('smartsheetAdmins');
const selectSmartsheetUsersByEmails = (state: StoreState) => state.admin.get('smartsheetUsersByEmails');
const selectViewId = (state: StoreState) => state.admin.get('view').get('id');
const selectConfigDisplayAttachments = (state: StoreState) => state.admin.get('config').get('displayAttachments');
const selectConfigDisplayComments = (state: StoreState) => state.admin.get('config').get('displayComments');

// Selectors
const makeSelectView = () => createSelector(selectView, (stateItem) => convertImmutableObjectToJS<ViewWithOwnerAndUserDetails>(stateItem));
const makeSelectConfig = () => createSelector(selectConfig, (stateItem) => convertImmutableObjectToJS<ViewConfig>(stateItem));
const makeSelectShares = () => createSelector(selectShares, (stateItem) => convertImmutableObjectToJS<ViewShareBasic[]>(stateItem));
const makeSelectShareErrors = () => createSelector(selectShareErrors, (stateItem) => convertImmutableObjectToJS<ShareError[]>(stateItem));
const makeSelectForm = () => createSelector(selectForm, (stateItem) => convertImmutableObjectToJS<FormInterface>(stateItem));
const makeSelectViewSourceMetaData = () =>
    createSelector(selectViewSourceMetaData, (stateItem) => convertImmutableObjectToJS<ViewSourceMetaData>(stateItem));
const makeSelectSmartsheetGroups = () => createSelector(selectSmartsheetGroups, (stateItem) => convertImmutableObjectToJS<Group[]>(stateItem));
const makeSelectSmartsheetUsers = () =>
    createSelector(selectSmartsheetUsers, (stateItem) => convertImmutableObjectToJS<IPaginatedResult<SmartsheetUser>>(stateItem));
const makeSelectSmartsheetUsersByEmails = () =>
    createSelector(selectSmartsheetUsersByEmails, (stateItem) => convertImmutableObjectToJS<IPaginatedResult<SmartsheetUser>>(stateItem));
const makeSelectSmartsheetAdmins = () =>
    createSelector(selectSmartsheetAdmins, (stateItem) => convertImmutableObjectToJS<SmartsheetUser[]>(stateItem));
const makeSelectHasAllAdminPanelContainerData = () =>
    createSelector(
        selectView,
        selectViewSourceMetaData,
        selectSmartsheetGroups,
        selectSmartsheetUsers,
        selectSmartsheetAdmins,
        selectConfig,
        selectShares,
        selectSmartsheetUsersByEmails,
        (
            stateView,
            stateViewSourceMetaData,
            stateSmartsheetGroups,
            stateSmartsheetUsers,
            stateSmartsheetAdmins,
            stateConfig,
            stateShares,
            stateSmartsheetUsersByEmails
        ) =>
            hasData(stateView) &&
            hasData(stateViewSourceMetaData) &&
            hasData(stateSmartsheetUsers) &&
            hasData(stateSmartsheetAdmins) &&
            hasData(stateConfig) &&
            hasData(stateShares) &&
            hasData(stateSmartsheetUsersByEmails) &&
            // groups received
            Boolean(stateSmartsheetGroups)
    );
const makeSelectConfigDisplayAttachments = () => createSelector(selectConfigDisplayAttachments, (stateItem) => stateItem);
const makeSelectConfigDisplayComments = () => createSelector(selectConfigDisplayComments, (stateItem) => stateItem);

export {
    makeSelectView,
    makeSelectConfig,
    makeSelectShares,
    makeSelectShareErrors,
    makeSelectForm,
    makeSelectViewSourceMetaData,
    makeSelectSmartsheetGroups,
    makeSelectSmartsheetUsers,
    makeSelectSmartsheetAdmins,
    makeSelectHasAllAdminPanelContainerData,
    makeSelectSmartsheetUsersByEmails,
    selectViewId,
    makeSelectConfigDisplayAttachments,
    makeSelectConfigDisplayComments,
};
