import classnames from 'classnames';
import * as React from 'react';
import { BaseComponent } from '../Base';
import './Pill.css';
import { withLanguageElementsHOC, LanguageElementsProp } from '../../language-elements/withLanguageElementsHOC';

const ControlId = {
    PILL: 'pil',
    TEXT: 'pil-1',
    REMOVE: 'pil-2',
};

interface Props {
    onRemove?: (() => void) | undefined;
    label: string;
    value: string;
    isValid: boolean;
    symbol?: React.ReactNode;
    subLabel?: string;
    updateWidth?: (width: number) => void;
    classes?: string;
}

export class Pill extends BaseComponent<Props & LanguageElementsProp> {
    public ref: React.RefObject<HTMLDivElement>;
    public constructor(props: Props & LanguageElementsProp) {
        super(props);
        this.ref = React.createRef();
    }
    public render(): JSX.Element {
        const classes = classnames('pill', this.props.classes, { invalid: !this.props.isValid });
        let labelJSX: JSX.Element;

        // If label is empty, use 'Blank' language element
        if (this.props.label === '') {
            labelJSX = (
                <span className="pill-value italic" data-client-id={ControlId.TEXT}>
                    {this.props.languageElements.SELECTION_FOR_BLANK_ENTRY}
                </span>
            );
        } else {
            labelJSX = (
                <span className="pill-value" data-client-id={ControlId.TEXT}>
                    {this.props.label}
                </span>
            );
        }

        const subLabelJSX = <span className="pill-sublabel">{this.props.subLabel || ''}</span>;

        const symbolJSX = <span className="pill-symbol">{this.props.symbol}</span>;

        return (
            <div className={classes} data-client-type={ControlId.PILL} ref={this.ref}>
                {this.props.symbol && symbolJSX}
                {labelJSX}
                {subLabelJSX}
                {this.props.onRemove && (
                    <span className="cancel" onClick={() => this.props.onRemove!()} data-client-id={ControlId.REMOVE}>
                        ×
                    </span>
                )}
            </div>
        );
    }

    public componentDidUpdate(): void {
        if (this.ref.current && this.props.updateWidth) {
            this.props.updateWidth(this.ref.current.offsetWidth);
        }
    }
}

export default withLanguageElementsHOC(Pill);
