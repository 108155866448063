import defaultIcon from '../../assets/images/toolbar/accountMenuIconDefault/default-profile-pic.png';
import { loggingClient } from '../../http-clients/Logging.client';
import { Actions, ActionTypes } from './Actions';
import { User, EligibilityStatus } from './types';

const FILENAME = 'Auth.Reducers.ts';

export interface AuthState {
    isAuthenticated: boolean;
    user: User;
}

const initialState: AuthState = {
    isAuthenticated: false,
    user: {
        email: '',
        firstName: '',
        lastName: '',
        profileImgUrl: defaultIcon,
        eligibility: EligibilityStatus.PLAN_NOT_FOUND,
        locale: '',
    },
};

const reducer = (state = initialState, action: Actions) => {
    switch (action.type) {
    case ActionTypes.LOG_IN:
        const user = action.payload;
        loggingClient.logInfo({ file: FILENAME, message: 'User logged in with EligibilityStatus', eligibility: user.eligibility })
        return {
            ...state,
            isAuthenticated: true,
            user: {
                admin: user.admin,
                email: user.email,
                firstName: user.firstName || '',
                lastName: user.lastName || '',
                profileImgUrl: user.profileImgUrl || '', // TODO: Update profileImgUrl to be profileImgId
                eligibility: user.eligibility,
                locale: user.locale,
                timeZone: user.timeZone,
            },
        };
    default:
        return state;
    }
};

export default reducer;
