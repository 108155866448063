/**
 * Returns true if the string is null, undefined or white space characters only.
 * Space, tab, form feed, line feed are considered white space characters.
 * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions/Character_Classes for more information.
 */
export function isNullOrWhiteSpace(str: any): boolean {
    if (str == null) {
        return true;
    }

    if (typeof str !== 'string') {
        return false;
    }

    return isWhiteSpace(str);
}

export function isWhiteSpace(str: string): boolean {
    return str.match(/^\s*$/) !== null;
}

export const isNullOrEmptyString = (value: any): value is null => value == null || (typeof value === 'string' && value.trim() === '');

/**
 * This method will truncate a piece of text on a space before adding an ellipses in an attempt to avoid
 * breaking in the middle of a word. If a length of zero is provided, then an empty string is returned.
 *
 * 'Eeny, meeny, miny, moe'.substring(0, 15) would return 'Eeny, meeny, mi'
 * truncateOnSpaceWithEllipses('Eeny, meeny, miny, moe', 15) would return "Eeny, meeny, ..."
 *
 * If there is no space in the section of the string prior to the truncation point,
 * then this method will simply truncate at the length indicated before appending the ellipses.
 *
 * truncateOnSpaceWithEllipses('abcdefghijklmnopqrstuvwxyz', 15) would return "abcdefghijklmno..."
 */
export const truncateOnSpaceWithEllipses = (text: string, length: number) => {
    if (length < 1) {
        return '';
    }

    const trimmed = text.trim();
    const lastSpace = trimmed.lastIndexOf(' ', length - 1);
    const truncated = trimmed.substring(0, lastSpace < 0 ? length : lastSpace + 1); // Exclude anything after the space.
    return truncated + '...';
};
