import { FormFieldInterface } from '../../../interfaces';
import { FuncTransformForSmarUpsert } from './FuncTransformForSmarUpsert';
import { ISmarCell } from './ISmarCell';

export const transformMultiPickListForSmarUpsert: FuncTransformForSmarUpsert = (rowField: FormFieldInterface): ISmarCell => {
    return {
        columnId: rowField.columnId,
        value: undefined,
        objectValue: rowField.objectValue,
        strict: false,
    };
};
