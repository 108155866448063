import { DisplayField } from '../../../common/interfaces';

import * as React from 'react';

import { AutomationIds } from '../../../common/enums/AutomationElements.enum';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';
import AddDisplayOptions from './AddDisplayOptions';
import './AdminPanelDisplay.css';
import FieldSelector from './FieldSelector';

interface Props {
    displayAttachments: boolean;
    addAttachments: boolean;
    onDisplayAttachmentsChange: () => void;
    onAddAttachmentsChange: () => void;
    displayComments: boolean;
    addComments: boolean;
    onDisplayCommentsChange: () => void;
    onAddCommentsChange: () => void;
    availableFields: DisplayField[];
    displayedFields: DisplayField[];
    onSelectedFieldsChange: (e: React.FormEvent<HTMLInputElement>) => void;
    onDisplayedFieldsChange: (displayFields: DisplayField[]) => void;
}

const AdminPanelDisplay: React.FC<Props> = props => {
    const languageElements = useLanguageElements();
    const ATTACHMENTS = languageElements.ADMIN_PANEL_DISPLAY_ATTACHMENTS_OPTIONS_HEADER;
    const DISPLAY_ATTACHMENTS = languageElements.ADMIN_PANEL_DISPLAY_ATTACHMENTS_OPTIONS_DISPLAY_ATTACHMENTS_LABEL;
    const ADD_ATTACHMENTS = languageElements.ADMIN_PANEL_DISPLAY_ATTACHMENTS_OPTIONS_ADD_ATTACHMENTS_LABEL;
    const COMMENTS = languageElements.ADMIN_PANEL_DISPLAY_COMMENTS_OPTIONS_HEADER;
    const DISPLAY_COMMENTS = languageElements.ADMIN_PANEL_DISPLAY_COMMENTS_OPTIONS_DISPLAY_COMMENTS_LABEL;
    const ADD_COMMENTS = languageElements.ADMIN_PANEL_DISPLAY_COMMENTS_OPTIONS_ADD_COMMENTS_LABEL;

    return (
            <div className="admin-panel-display">
                <div className="attachments-comments">
                    <AddDisplayOptions
                        header={ATTACHMENTS}
                        options={[
                            {
                                checked: props.displayAttachments,
                                label: DISPLAY_ATTACHMENTS,
                                onChange: props.onDisplayAttachmentsChange,
                                dataClientId: AutomationIds.ADMIN_PANEL_DISPLAY_DISPLAY_ATTACH,
                                checkboxKey: 'displayAttachments',
                            },
                            {
                                checked: props.addAttachments,
                                label: ADD_ATTACHMENTS,
                                onChange: props.onAddAttachmentsChange,
                                dataClientId: AutomationIds.ADMIN_PANEL_DISPLAY_ADD_ATTACH,
                                checkboxKey: 'addAttachments',
                            },
                        ]}
                    />
                    <AddDisplayOptions
                        header={COMMENTS}
                        options={[
                            {
                                checked: props.displayComments,
                                label: DISPLAY_COMMENTS,
                                onChange: props.onDisplayCommentsChange,
                                dataClientId: AutomationIds.ADMIN_PANEL_DISPLAY_DISPLAY_COMMENTS,
                                checkboxKey: 'displayComments',
                            },
                            {
                                checked: props.addComments,
                                label: ADD_COMMENTS,
                                onChange: props.onAddCommentsChange,
                                dataClientId: AutomationIds.ADMIN_PANEL_DISPLAY_ADD_COMMENTS,
                                checkboxKey: 'addComments',
                            },
                        ]}
                    />
                </div>
                <FieldSelector
                    onChange={props.onSelectedFieldsChange}
                    availableFields={props.availableFields}
                    displayedFields={props.displayedFields}
                    onDisplayedFieldsChange={props.onDisplayedFieldsChange}
                />

            </div>
    );
};

export default AdminPanelDisplay;
