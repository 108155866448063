import 'reflect-metadata';
import { Models } from '@smartsheet/core-views-api-models';
import { MAX_CELL_VALUE_CHARS } from '../../common/constants';
import { NumberFormatType } from '../../common/enums/FormatTypes.enum';
import { SmartsheetNumberFormatObject } from '../../common/interfaces';
import { safeNumberToString } from '../../common/utils/NumbersUtility';
import * as React from 'react';
import percentSvg from '../../assets/images/icons/percent.svg';
import { AutomationIds } from '../../common/enums/AutomationElements.enum';
import { DecodedNumberFormatObject } from '../../common/interfaces/DecodedNumberFormatObject';
import { CurrencySymbolUtility } from '../../common/utils/CurrencySymbolUtility';
import { useLanguageElements } from '../../language-elements/withLanguageElementsHOC';
import { Button } from '../Buttons';
import { WithDataClientProps } from '../hoc/WithDataClient';
import NumberFormatInput from '../NumberFormatInput/NumberFormatInput';
import TextArea from '../TextArea';

interface TextNumberProps extends WithDataClientProps {
    disabled?: boolean;
    fadeSymbol?: boolean;
    maxLength?: number;
    placeholder?: string;
    rows?: number;
    defaultValue?: string;
    numberFormatObject?: SmartsheetNumberFormatObject | DecodedNumberFormatObject;
    className?: string;
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement>) => void;
    value: string | number;
    preview?: string;
    clearable?: boolean;
    onClearContent?: (columnId: number) => void;
}

const TextNumber: React.FC<TextNumberProps> = (props) => {
    const languageElements = useLanguageElements();

    const {
        disabled,
        fadeSymbol,
        maxLength,
        placeholder,
        rows,
        defaultValue,
        numberFormatObject,
        className,
        onChange,
        value,
        dataClientId,
        dataClientType,
        onClearContent,
    } = props;

    const numberFormat = props.numberFormatObject ? props.numberFormatObject.numberFormat : undefined;
    const currencyObject = props.numberFormatObject ? props.numberFormatObject.currencyObject : undefined;

    let displaySymbolUrl;
    let displaySymbolAltText = '';
    let displaySymbolToolTip;
    let shouldDisplaySymbolRight = false;

    switch (numberFormat) {
        case Models.NumberFormat.PERCENT:
        case NumberFormatType.percent:
            displaySymbolUrl = percentSvg;
            displaySymbolAltText = '%';
            shouldDisplaySymbolRight = true;
            break;
        case Models.NumberFormat.CURRENCY:
        case NumberFormatType.currency:
            const currencySymbolUtility = new CurrencySymbolUtility(languageElements);
            displaySymbolUrl = currencyObject ? currencySymbolUtility.getCurrencyIconUrlFromSymbol(currencyObject.symbol) : undefined;
            displaySymbolAltText = currencyObject ? currencyObject.symbol : '';
            displaySymbolToolTip = currencyObject ? currencySymbolUtility.getCurrencyNameFromSymbol(currencyObject.symbol) : undefined;
            shouldDisplaySymbolRight = currencyObject ? currencyObject.offsetRight : false;
            break;
    }

    // Handles scientific notation.
    const stringValue: string = typeof value === 'number' ? safeNumberToString(value) : value;

    return (
        <div
            data-client-id={dataClientId}
            data-testid={dataClientId || dataClientType}
            data-client-type={dataClientType}
            className={className ? className : ''}
        >
            {numberFormatObject && displaySymbolUrl ? (
                <NumberFormatInput
                    symbolUrl={displaySymbolUrl}
                    symbolAltText={displaySymbolAltText}
                    tooltipText={displaySymbolToolTip}
                    shouldDisplayRightSymbol={shouldDisplaySymbolRight}
                    fadeSymbol={fadeSymbol}
                    inputValue={stringValue}
                    inputDisabled={disabled}
                    inputPlaceholder={placeholder}
                    inputMaxLength={maxLength || MAX_CELL_VALUE_CHARS}
                    inputDefaultValue={defaultValue}
                    inputOnChange={onChange}
                />
            ) : rows && rows > 1 ? (
                <TextArea
                    value={stringValue}
                    disabled={disabled}
                    placeholder={placeholder}
                    rows={rows}
                    maxLength={maxLength || MAX_CELL_VALUE_CHARS}
                    defaultValue={defaultValue}
                    onChange={onChange}
                />
            ) : (
                <input
                    value={stringValue}
                    disabled={disabled}
                    placeholder={placeholder}
                    maxLength={maxLength || MAX_CELL_VALUE_CHARS}
                    defaultValue={defaultValue}
                    onChange={onChange}
                />
            )}
            {props.clearable && (
                <Button
                    onClick={(event: any) => (onClearContent ? onClearContent(event) : undefined)}
                    className="close-input"
                    text="×"
                    data-client-id={AutomationIds.DETAILS_TAB_CLEAR_BUTTON}
                    tooltip={languageElements.DETAIL_PANEL_CLEAR_TOOLTIP}
                />
            )}
            {props.preview && <p>{`${languageElements.FORM_FIELD_MENU_PREVIEW_LABEL}: ${props.preview}`}</p>}
        </div>
    );
};

export default TextNumber;
