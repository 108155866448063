export class OptionRepository {

    public static getOption(viewId: string, optionPrefix: string, defaultState = false): boolean {
        const key = `${optionPrefix}_${viewId}`;
        const item = localStorage.getItem(key);
        let optionState = defaultState;

        if (item != null) {
            try {
                optionState = JSON.parse(item);
            } catch {
            }
        }
        return optionState;
    }

    public static setOption(viewId: string, optionPrefix: string, enabled: boolean): void {
        try {
            localStorage.setItem(`${optionPrefix}_${viewId}`, JSON.stringify(enabled));
        } catch {
            // catch error if local storage is full
        }
    }
}
