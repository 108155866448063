/* eslint @typescript-eslint/no-misused-promises:"off" */
import { ErrorType, OwnershipTransferStatus } from '../../../common/enums';
import { Contact, IOwnershipTransfer, SheetFilterType, ViewSourceMetaData } from '../../../common/interfaces';
import * as React from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { createSelector, createStructuredSelector } from 'reselect';
import alertIcon from '../../../assets/images/alert/modal-warning-large.svg';
import iconClose from '../../../assets/images/close-button-dark.svg';
import { AutomationIds } from '../../../common/enums/AutomationElements.enum';
import { disableFreeTextNewOptions } from '../../../common/utils/DisableFreeTextNewOptions';
import { selectedSheetFilterDeleted } from '../../../common/utils/SelectedSheetFilterDeleted';
import { BaseProps } from '../../../components/Base';
import ContactPicker from '../../../components/ContactPicker';
import RenderModal, { ModalProps } from '../../../components/Modal/RenderModal';
import ownershipTransferClient from '../../../http-clients/OwnershipTransfer.client';
import { LanguageElementsProp, withLanguageElementsHOC } from '../../../language-elements/withLanguageElementsHOC';
import { Actions, ActionTypes } from '../../OwnershipTransfer/Actions';
import { ownershipTransferErrorSelector } from '../../OwnershipTransfer/Selectors';
import BasicLinkOut from '../Basic/BasicLinkOut';
import { makeSelectViewSourceMetaData } from '../Selectors';

interface OwnProps extends BaseProps {
    allOptions: Contact[];
    selectedOptions?: Contact;
    onNewOwnerChange: (result?: Contact) => void;
    readOnly: boolean;
    sheetFilterId?: string | null;
    pendingTransfer: IOwnershipTransfer | undefined;
}

interface StateProps {
    hasOwnershipTransferError: boolean;
    viewSourceMetaData: ViewSourceMetaData;
}

interface State {
    showModalType: AdminPanelOwnershipTransferModalType;
    showSelectNewOwnerContactPicker: boolean;
}

interface DispatchProps {
    clearOwnershipTransferError: () => Action<ActionTypes.CLEAR_OWNERSHIP_TRANSFER_ERROR>;
    setStatusForTransfer: (transferId: string, status: OwnershipTransferStatus) => Actions;
}

export type AdminPanelOwnershipTransferProps = StateProps & DispatchProps & OwnProps;

export class AdminPanelOwnershipTransfer extends React.Component<AdminPanelOwnershipTransferProps & LanguageElementsProp> {
    private static getNewOwnerName(pendingTransfer: IOwnershipTransfer): string {
        if (pendingTransfer.newOwner.name) {
            return pendingTransfer.newOwner.name;
        }

        return pendingTransfer.newOwner.email;
    }

    public state: State;

    public constructor(props: AdminPanelOwnershipTransferProps & LanguageElementsProp) {
        super(props);
        this.state = {
            showModalType: AdminPanelOwnershipTransferModalType.NONE,
            showSelectNewOwnerContactPicker: false,
        };
    }

    public render(): React.ReactNode {
        const pendingTransfer = this.props.pendingTransfer;

        // Only allow transfer creation if the sheet filter is valid
        const allowTransferCreation = !selectedSheetFilterDeleted(this.props.sheetFilterId, this.props.viewSourceMetaData.sheetFilters);

        return (
            <div className="create-ownership-transfer">
                {/* If there is a pending transfer and I am the owner, then give me the ability to cancel. */}
                {pendingTransfer && this.renderCancelLink(pendingTransfer)}

                {/* If there is no pending transfer, and I am the view owner, and I haven't clicked */}
                {/* the "Transfer Ownership" link, then show me the link to start an ownership transfer. */}
                {allowTransferCreation && !pendingTransfer && !this.state.showSelectNewOwnerContactPicker && this.renderTransferOwnershipLink()}

                {/* If there is no pending transfer, and I am the view owner, and I have already clicked the */}
                {/* "Transfer Ownership" link, then show me the contact picker for selecting the new owner. */}
                {allowTransferCreation && !pendingTransfer && this.state.showSelectNewOwnerContactPicker && this.renderNewOwnerContactPicker()}

                {/* If there is no pending transfer, and transfer is disallowed due to invalid filter */}
                {/* then show me a message to explain issue. */}
                {!allowTransferCreation && !pendingTransfer && this.renderInvalidFilterMessage()}

                {/* Error modal */}
                {this.state.showModalType !== AdminPanelOwnershipTransferModalType.NONE && <RenderModal {...this.getModalProps()} />}
            </div>
        );
    }

    public componentDidMount(): void {
        if (this.props.hasOwnershipTransferError) {
            this.setState({ showModalType: AdminPanelOwnershipTransferModalType.ERROR_GENERAL });
        }
    }

    public componentDidUpdate(prevProps: Readonly<AdminPanelOwnershipTransferProps>): void {
        if (this.props.hasOwnershipTransferError && this.props.hasOwnershipTransferError !== prevProps.hasOwnershipTransferError) {
            this.setState({ showModalType: AdminPanelOwnershipTransferModalType.ERROR_GENERAL });
        }
    }

    public handleClickCreateTransferOwnershipButton = (): void => {
        if (this.isPrivateSheetFilterApplied()) {
            this.setState({ showModalType: AdminPanelOwnershipTransferModalType.PRIVATE_FILTER });
        } else {
            this.setState({ showSelectNewOwnerContactPicker: true });
        }
    };

    private getModalProps = () => {
        // Specify any customized props needed for the modal
        const modalSpecificProps: ModalProps = {
            onClickPrimaryButton: this.handleCloseModal,
            icon: alertIcon,
            closeWrapper: this.handleCloseModal,
            wrapperClasses: 'ownership-transfer-warning',
        };

        switch (this.state.showModalType) {
            case AdminPanelOwnershipTransferModalType.ERROR_ON_CANCEL_NOT_PENDING:
                modalSpecificProps.title = this.props.languageElements.OWNERSHIP_TRANSFER_ALREADY_ACCEPTED_OR_DECLINED;
                break;

            case AdminPanelOwnershipTransferModalType.ERROR_GENERAL:
                modalSpecificProps.title = this.props.languageElements.OWNERSHIP_TRANSFER_TRY_AGAIN_TITLE;
                modalSpecificProps.message = this.props.languageElements.OWNERSHIP_TRANSFER_TRY_AGAIN_MESSAGE;
                break;

            // The modal for private filter warning requires add'l props in order to display link and 2 buttons
            case AdminPanelOwnershipTransferModalType.PRIVATE_FILTER:
                // Implement Continue & Cancel buttons (replaces OK)
                modalSpecificProps.primaryButtonText = this.props.languageElements.BUTTON_TEXT_CONTINUE;
                modalSpecificProps.secondaryButtonText = this.props.languageElements.BUTTON_TEXT_CANCEL;
                modalSpecificProps.onClickPrimaryButton = this.handleWarnPrivateFilterContinue;
                modalSpecificProps.onClickSecondaryButton = this.handleCloseModal;
                modalSpecificProps.title = this.props.languageElements.ADMIN_PANEL_MODAL_WARN_PRIVATE_FILTER_TITLE;
                modalSpecificProps.message = this.props.languageElements.ADMIN_PANEL_MODAL_WARN_PRIVATE_FILTER_MESSAGE;
                modalSpecificProps.hideSecondaryButton = false;

                // Implement child component for 'Learn more' link to display in modal
                modalSpecificProps.children = (
                    <BasicLinkOut
                        dataClientId={AutomationIds.ADMIN_PANEL_PERMISSIONS_OWNER_TRANSFER_MODAL_LINK}
                        sourceDescription={this.props.languageElements.LEARN_MORE}
                        sourceLink="https://help.smartsheet.com/articles/2478391#privatefilter"
                    />
                );
                break;

            case AdminPanelOwnershipTransferModalType.NONE:
            default:
                throw new Error(
                    `${this.state.showModalType} is not a supported modal type when generating props for the GenericModelContent component.`
                );
        }

        return modalSpecificProps;
    };

    private renderCancelLink(pendingTransfer: IOwnershipTransfer): JSX.Element {
        return (
            <div className="cancel-ownership-transfer">
                <div className="orange-dot" />
                <p>
                    {this.props.languageElements.ADMIN_PANEL_PERMISSIONS_TRANSFER_OWNERSHIP_CANCEL_PLACEHOLDER +
                        ' ' +
                        AdminPanelOwnershipTransfer.getNewOwnerName(pendingTransfer)}
                </p>
                <button
                    className="cancel-transfer-button"
                    onClick={() => this.handleClickCancelOwnershipTransferButton(pendingTransfer.id)}
                    data-client-id={AutomationIds.ADMIN_PANEL_PERMISSIONS_OWNER_TRANSFER_CANCEL_BUTTON}
                >
                    {this.props.languageElements.BUTTON_TEXT_CANCEL}
                </button>
            </div>
        );
    }

    private renderTransferOwnershipLink(): JSX.Element {
        return (
            <button
                className="create-transfer-button"
                onClick={this.handleClickCreateTransferOwnershipButton}
                data-client-id={AutomationIds.ADMIN_PANEL_PERMISSIONS_OWNER_TRANSFER_BUTTON}
            >
                {this.props.languageElements.TRANSFER_OWNERSHIP_BUTTON_TEXT}
            </button>
        );
    }

    private renderNewOwnerContactPicker(): JSX.Element {
        const { allOptions, selectedOptions, onNewOwnerChange, readOnly } = this.props;
        return (
            <div className="select-new-owner">
                <ContactPicker
                    dataClientId={AutomationIds.ADMIN_PANEL_PERMISSIONS_OWNER_TRANSFER}
                    allOptions={allOptions}
                    selectedOptions={selectedOptions}
                    isValidNewOption={disableFreeTextNewOptions}
                    allowClearing={false}
                    onChange={onNewOwnerChange}
                    disabled={readOnly}
                    multi={false}
                    inPlace={true}
                    classNames="new-owner-dropdown"
                    placeholder={this.props.languageElements.ADMIN_PANEL_PERMISSIONS_TRANSFER_OWNERSHIP_PLACEHOLDER}
                />
                <button
                    data-client-id={AutomationIds.ADMIN_PANEL_PERMISSIONS_OWNER_TRANSFER_CLOSE_BUTTON}
                    className={'transfer-owner-close-button'}
                    onClick={this.handleCloseOwnershipTransferContactPicker}
                >
                    <img src={iconClose} alt={'icon-close'} className={'icon-close'} />
                </button>
            </div>
        );
    }

    private renderInvalidFilterMessage(): JSX.Element {
        return (
            <p className="create-transfer-warning" data-client-id={AutomationIds.ADMIN_PANEL_PERMISSIONS_OWNER_TRANSFER_WARNING}>
                {this.props.languageElements.ADMIN_PANEL_PERMISSIONS_TRANSFER_OWNERSHIP_INVALID_FILTER}
            </p>
        );
    }

    private handleCloseModal = (): void => {
        this.setState({ showModalType: AdminPanelOwnershipTransferModalType.NONE });
        if (this.props.hasOwnershipTransferError) {
            this.props.clearOwnershipTransferError();
        }
    };

    private handleWarnPrivateFilterContinue = (): void => {
        this.setState({
            showModalType: AdminPanelOwnershipTransferModalType.NONE,
            showSelectNewOwnerContactPicker: true,
        });
    };

    private handleCloseOwnershipTransferContactPicker = (): void => {
        this.setState({
            showSelectNewOwnerContactPicker: false,
        });
        this.props.onNewOwnerChange(undefined);
    };

    private isPrivateSheetFilterApplied = (): boolean => {
        if (this.props.sheetFilterId == null) {
            return false;
        }

        return this.props.viewSourceMetaData.sheetFilters!.some(
            (filter) => this.props.sheetFilterId === filter.id && filter.filterType === SheetFilterType.PERSONAL
        );
    };

    private handleClickCancelOwnershipTransferButton = async (transferId: string): Promise<void> => {
        try {
            await ownershipTransferClient.update(transferId, OwnershipTransferStatus.CANCELED);

            // Update the status on the transfer to canceled in Redux store ownershipTransfer property
            this.props.setStatusForTransfer(transferId, OwnershipTransferStatus.CANCELED);

            this.setState({
                showSelectNewOwnerContactPicker: false,
            });
        } catch (error) {
            // Show a different error message depending on what failed.
            const errorCode = error.response && error.response.data && error.response.data.errorCode;
            const showModalType =
                errorCode === ErrorType.OWNERSHIP_TRANSFER_STATUS_NOT_PENDING
                    ? AdminPanelOwnershipTransferModalType.ERROR_ON_CANCEL_NOT_PENDING
                    : AdminPanelOwnershipTransferModalType.ERROR_GENERAL;
            this.setState({ showModalType });
        }
    };
}

export enum AdminPanelOwnershipTransferModalType {
    NONE,
    PRIVATE_FILTER,
    ERROR_ON_CANCEL_NOT_PENDING,
    ERROR_GENERAL,
}

const mapState = createStructuredSelector({
    hasOwnershipTransferError: ownershipTransferErrorSelector,
    viewSourceMetaData: createSelector(makeSelectViewSourceMetaData(), (stateViewSourceMetaData) => stateViewSourceMetaData!),
});

const mapDispatch = {
    clearOwnershipTransferError: Actions.clearOwnershipTransferError,
    setStatusForTransfer: Actions.setStatusForTransfer,
};

export default withLanguageElementsHOC(connect<StateProps, DispatchProps, OwnProps, State>(mapState, mapDispatch)(AdminPanelOwnershipTransfer));
