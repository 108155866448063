import * as React from 'react';
import { useCallback, useRef } from 'react';
import { DataFunc, Mention, MentionProps, MentionsInput, MentionsInputProps, SuggestionDataItem } from 'react-mentions';
import { MAX_COMMENTS_CHARS } from '../../../../common/constants';
import { UserActions } from '../../../../common/enums/UserActions.enum';
import { ContactDto } from '../../../../common/interfaces/ContactDto.interface';
import { ActionType, UserAnalyticsAction } from '../../../../common/metrics/UserAnalyticsAction';
import { AtMentionsClient } from '../../../../http-clients';
import { loggingClient } from '../../../../http-clients/Logging.client';
import { useLanguageElements } from '../../../../language-elements/withLanguageElementsHOC';
import { adjustHeight } from '../Comment/AdjustHeight';
import './AtMentions.css';
import RenderedSuggestion from './RenderedSuggestion';

const Patterns = {
    userMention: /@([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/,
};

const FILENAME = 'AtMentions.tsx';

export interface AtMentionsProps {
    viewId: string;
    rowId: number;
    reportSheetId?: number;
    text?: string;
    onChange?: (comment: string) => void;
    onSubmit: (comment: string) => void;
    onFocus?: () => void;
    onBlur?: () => void;
}

const AtMentions: React.FC<AtMentionsProps> = ({ viewId, rowId, reportSheetId, text, onChange, onSubmit, onFocus, onBlur }: AtMentionsProps) => {
    const inputRef = useRef<HTMLTextAreaElement | null>(null);
    const throttledRequests = useRef<Map<string, ReturnType<typeof setTimeout>>>(new Map());
    const suggestionsForQuery = useRef<Map<string, SuggestionDataItem[]>>(new Map());
    const contacts = useRef<Map<string, ContactDto>>(new Map());
    const languageElements = useLanguageElements();

    const handleOnChange: MentionsInputProps['onChange'] = (event) => {
        const updatedValue = event.target.value.substring(0, MAX_COMMENTS_CHARS);
        adjustHeight(inputRef.current);
        onChange?.(updatedValue);
    };

    const handleOnKeyDown: MentionsInputProps['onKeyDown'] = (event) => {
        if (event.key === 'Enter') {
            if (event.shiftKey) {
                return;
            }
            onSubmit(text ?? '');
        }
    };

    const handleAdd: MentionProps['onAdd'] = () => {
        UserAnalyticsAction.add(ActionType.USER_ACTION, UserActions.ADD_AT_MENTION, { viewId });
    };

    /**
     * Cancels a pending (throttled) request.
     *
     * @param requestName The name of the request to cancel.
     */
    const cancelRequest = useCallback((requestName: string) => {
        const existingRequest = throttledRequests.current.get(requestName);
        if (existingRequest) {
            clearTimeout(existingRequest);
            throttledRequests.current.delete(requestName);
        }
    }, []);

    /**
     * Add a small delay before fetching the list of contacts that can be tagged (mentioned) in a comment.
     * This is to throttle the calls to the API for fetching contacts.  If a user is typing someone's name,
     * fetch the list when there is a pause in typing.  We don't want to hammer the API for every keystroke.
     *
     * @param requestName The type of request being throttled. This name will be used to identify other pending requests of the same kind.
     * @param request The request that will be made after the specified delay
     * @param delayInMillis The delay in milliseconds to wait before performing the request
     */
    const throttleRequest = useCallback(
        (requestName: string, delay: number, request: () => void) => {
            cancelRequest(requestName);
            const throttledRequest = setTimeout(request, delay);
            throttledRequests.current.set(requestName, throttledRequest);
        },
        [cancelRequest]
    );

    /**
     * Calls the DV API to fetch the list of contacts that can be used in the @Mentions with the view source.
     *
     * @param rowId The target row for the at-mentions.
     * @param reportSheetId The sheet ID of the row if the row is a report row.
     * @param query Any text typed after the @ symbol.  Used to filter the list of contacts.
     */
    const fetchContacts = useCallback(
        async (query: string) => {
            const mentionableContacts = await AtMentionsClient.getContactsForAtMentions(viewId, rowId, reportSheetId, query);
            mentionableContacts.forEach((contact) => contacts.current.set(contact.email, contact));
            const suggestions = mentionableContacts.map((contact) => ({
                id: contact.email,
                display: contact.name ?? '',
            }));
            suggestionsForQuery.current.set(query, suggestions);
            return suggestions;
        },
        [viewId, rowId, reportSheetId]
    );

    /**
     * Checks the cache for suggestions (contacts) that have already been fetched for the passed in query string.
     * If we don't have any cached values for the query, then we setup a new request to fetch contacts for the query string.
     * This request will be executed after a small delay; we throttle requests to avoid unnecessary burden on the API.
     *
     * @param query Any text typed after the @ symbol.  Used to filter the list of contacts.
     * @param callback Used to provide the list of contacts to the react-mentions component.
     */
    const getSuggestions = useCallback(
        (): DataFunc => (query, callback) => {
            // First, cancel any pending requests, since the query string has changed.
            cancelRequest(fetchContacts.name);

            // Next, check our cache.  No need to fetch contacts a second time if we already have them.
            const cachedSuggestions = suggestionsForQuery.current.get(query);
            if (cachedSuggestions) {
                callback(cachedSuggestions);
                return;
            }

            // If we don't have any suggestions in the cache, then setup a new request. This request will be throttled (have a small delay).
            throttleRequest(fetchContacts.name, 180, () => {
                (async () => {
                    try {
                        // Cache the suggestions before handing it off to the react-mentions component.
                        const suggestions = await fetchContacts(query);
                        suggestionsForQuery.current.set(query, suggestions);
                        callback(suggestions);
                    } catch (error) {
                        loggingClient.logInfo({
                            file: FILENAME,
                            message: error.message,
                        });
                    }
                })();
            });
        },
        [cancelRequest, throttleRequest, fetchContacts]
    );

    return (
        <MentionsInput
            className="mentionsEditor"
            placeholder={languageElements.COMMENT_ADD}
            value={text ?? ''}
            allowSuggestionsAboveCursor
            suggestionsPortalHost={document.querySelector('.mentionsFloatingPortal')!}
            inputRef={inputRef}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            onSelect={onFocus}
            onBlur={onBlur}
        >
            <Mention
                className="userMentionHighlight"
                trigger="@"
                data={getSuggestions()}
                appendSpaceOnAdd
                markup="@__id__"
                displayTransform={(id) => `@${id}`}
                renderSuggestion={(suggestion) => (
                    <RenderedSuggestion suggestion={suggestion} contact={contacts.current.get(suggestion.id.toString())} />
                )}
                regex={Patterns.userMention}
                onAdd={handleAdd}
            />
        </MentionsInput>
    );
};

export default AtMentions;
