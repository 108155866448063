/* eslint react/jsx-no-target-blank:"off" */
import * as React from 'react';
import { useSelector } from 'react-redux';
import { shortLocaleSelector } from '../../../containers/Auth/Selectors';
import './Help.css';

const ControlId = {
    HELP: 'thm-1',
};

const Help = () => {
    const locale = useSelector(shortLocaleSelector);
    const localeParameter = locale === 'en' ? '' : '/' + locale;
    return (
        <a href={`https://help.smartsheet.com${localeParameter}/articles/2477821`} target="_blank" className="helpContainer">
            <div className="help" data-client-id={ControlId.HELP}>
                <p>?</p>
            </div>
        </a>
    );
};

export default Help;
