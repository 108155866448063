import { v4 as uuid } from 'uuid';
import { ColumnType, FormActionType, FormCriteriaComparator, FormRuleMenuOption } from '../enums';
import { Column, FormActionInterface, FormFieldInterface, FormRuleCriteriaInterface, FormRuleInterface } from '../interfaces';
import { FormAction } from './FormAction';

export class FormRule {

    private static initializeFormCriteria(): FormRuleCriteriaInterface {
        return {
            columnId: -1,
            comparator: FormCriteriaComparator.HAS_ANY_OF,
            options: [],
        };
    }

    private static getAvailableCriteriaComparators(): FormCriteriaComparator[] {
        return [
            FormCriteriaComparator.HAS_ANY_OF,
            FormCriteriaComparator.HAS_NONE_OF,
        ];
    }

    private static getActions(): FormActionType[] {
        return [
            FormActionType.HIDE,
            FormActionType.READ_ONLY,
            FormActionType.EDITABLE,
            FormActionType.REQUIRE,
        ];
    }

    public get id(): string {
        return this._id;
    }

    public get name(): string {
        return this._name;
    }

    public get disabled(): boolean {
        return this._disabled;
    }

    public get isValid(): boolean {
        return this._isValid;
    }

    public get hidden(): boolean {
        return this._hidden;
    }

    public get editMode(): boolean {
        return this._editMode;
    }

    public get actions(): FormAction[] {
        return this._actions;
    }

    public get criteria(): FormRuleCriteriaInterface {
        return this._criteria;
    }

    private readonly _id: string;
    private _name: string;
    private _actions: FormAction[];
    private _criteria: FormRuleCriteriaInterface;
    private _disabled: boolean;
    private _hidden: boolean;
    private _editMode: boolean;
    private _isValid: boolean;
    private readonly _availableActions: FormActionType[];
    private readonly _criteriaComparators: FormCriteriaComparator[];
    private readonly _fields: Column[];
    private readonly _includedFields: FormFieldInterface[];
    private readonly _availableIncludedFields: FormFieldInterface[];

    public constructor(fields: Column[], includedFields: FormFieldInterface[], value?: FormRuleInterface) {
        this._fields = fields;
        this._includedFields = includedFields;
        this._availableIncludedFields = this.getAvailableIncludedFields();
        if (!value) {
            this._id = uuid();
            this._name = '';
            this._disabled = false;
            this._hidden = false;
            this.addAction();
            this._availableActions = FormRule.getActions();
            this._criteriaComparators = FormRule.getAvailableCriteriaComparators();
            this._criteria = {
                columnId: -1,
                comparator: FormCriteriaComparator.HAS_ANY_OF,
                options: [],
            };
            this._editMode = true;
        } else {
            this._id = value?.id ?? uuid();
            this._name = value.name ? value.name : '';
            this._actions = this.initializeActions(value.actions);
            this._criteria = value.criteria;
            this._disabled = value.disabled;
            this._availableActions = FormRule.getActions();
            this._criteriaComparators = FormRule.getAvailableCriteriaComparators();
            this._editMode = value.editMode || false;
            this._hidden = value.hidden || false;
        }
        this.checkValidity();
    }

    public toWireModel(): FormRuleInterface {
        return {
            id: this._id,
            name: this._name,
            actions: this._actions.map((action: FormAction) => action.toWireModel()),
            criteria: this._criteria,
            disabled: this._disabled,
            isValid: this._isValid,
            hidden: this._hidden || false,
            editMode: this._editMode,
        };
    }

    public getCriteriaFields(): Column[] {
        return this._fields.filter((field: Column) => {
            return field.type === ColumnType.PICKLIST || field.type === ColumnType.MULTI_PICKLIST;
        });
    }

    public getCriteriaComparators(): FormCriteriaComparator[] {
        return this._criteriaComparators;
    }

    public getCriteriaFieldOptions(): string[] {
        const field = this._fields.find((column: Column) => column.id === this._criteria.columnId);
        if (!this._criteria.columnId || !field || !field.options) {
            return [];
        }
        return field.options;
    }

    public setCriteriaOptions(options: string[]): void {
        this.criteria.options = options;
    }

    public clearCriteriaOptionsMultiselect(): void {
        this.criteria.options = [];
    }

    public setCriteriaField(columnId: number): void {
        if (!this._criteria) {
            this._criteria = {
                columnId,
                comparator: FormCriteriaComparator.HAS_ANY_OF,
                options: [],
            };
        } else {
            this._criteria.columnId = columnId;
        }
        this.checkValidity();
    }

    public setCriteriaComparator(comparator: FormCriteriaComparator): void {
        if (!this._criteria) {
            this._criteria = {
                columnId: -1,
                comparator,
                options: [],
            };
        } else {
            this._criteria.comparator = comparator;
        }
        this.checkValidity();
    }

    public addCriteriaOption(option: string): void {
        if (!this._criteria) {
            this._criteria = FormRule.initializeFormCriteria();
        } else {
            this._criteria.options.push(option);
        }
        this.checkValidity();
    }

    public clearCriteriaOptions(): void {
        if (this._criteria) {
            this._criteria.options = [];
        }
        this.checkValidity();
    }

    public getAvailableFields(): Column[] {
        return this._fields.filter((field: Column) => {
            const availableField = this._includedFields.find((includedField: FormFieldInterface) => field.id === includedField.columnId);
            return availableField !== undefined;
        });
    }

    public getAvailableActions(): FormActionType[] {
        return this._availableActions;
    }

    public actionsValid(): boolean {
        return this._actions.every((action: FormAction) => action.isValid);
    }

    public setAction(actionId: string, actionType: FormActionType): void {
        const action = this._actions.find((x: FormAction) => x.id === actionId);
        if (action) {
            action.setActionType(actionType);
        }
        this.checkValidity();
    }

    /**
     * addAction
     */
    public addAction(): void {
        if (!this._actions) {
            this._actions = [];
        }
        this._actions.push(new FormAction(this._availableIncludedFields));
        this.checkValidity();
    }

    /**
     * addField
     */
    public addField(actionId: string, fieldId: number): void {
        const action = this._actions.find((a: FormAction) => a.id === actionId);
        if (action) {
            if (action.fields.indexOf(fieldId) === -1) {
                action.addField(fieldId);
            }
        }
        this.checkValidity();
    }

    public getFieldsForAction(actionId: string): Column[] {
        const action = this._actions.find((a: FormAction) => a.id === actionId);
        const fields: Column[] = [];
        if (action) {
            action.fields.forEach((fieldId: number) => {
               const field = this._fields.find((f: Column) => f.id === fieldId);
               if (field) {
                   fields.push({
                       id: field.id,
                       title: field.title,
                       type: field.type,
                       options: field.options,
                   }) ;
               }
            });
        }
        return fields;
    }

    public updateFormRuleName(name: string): void {
        this._name = name;
        this.checkValidity();
    }

    public clearFieldsForAction(actionId: string): void {
        const action = this._actions.find((a: FormAction) => a.id === actionId);
        if (action) {
            action.clearFields();
        }
        this.checkValidity();
    }

    public criteriaFieldIsValid(): boolean {
        return this._criteria.columnId !== -1;
    }

    public criteriaIsValid(): boolean {
        return this._criteria.columnId !== -1 && this._criteria.options.length > 0;
    }

    public toggleIsHidden(): void {
        this._hidden = !this._hidden;
        this.checkValidity();
    }

    public toggleEditMode(): void {
        this._editMode = !this._editMode;
        this.checkValidity();
    }

    public toggleDisabled(): void {
        this._disabled = !this._disabled;
        this.checkValidity();
    }

    public getMenuOptions(): FormRuleMenuOption[] {
        if (this._editMode) {
            return [
                FormRuleMenuOption.DELETE,
            ];
        } else {
            if (this._isValid) {
                if (this._disabled) {
                    return [
                        FormRuleMenuOption.EDIT_NAME,
                        FormRuleMenuOption.ENABLE,
                        FormRuleMenuOption.DELETE,
                    ];
                } else {
                    return [
                        FormRuleMenuOption.EDIT_NAME,
                        FormRuleMenuOption.DISABLE,
                        FormRuleMenuOption.DELETE,
                    ];
                }
            } else {
                return [
                    FormRuleMenuOption.EDIT_NAME,
                    FormRuleMenuOption.DELETE,
                ];
            }
        }
    }

    private checkValidity(): void {
        const usedFormActionFields = new Set();

        // For each action in the rule
        const actionsValid = this.actions.every((action: FormAction) => {
            // Check that every action Field is not re-used
            const actionFieldsUnique = action.fields.every((actionField: number) => {
                if (usedFormActionFields.has(actionField)) {
                    return false;
                }
                usedFormActionFields.add(actionField);
                return true;
            });

            // Check that each individual action is valid
            return actionFieldsUnique && action.isValid;
        });
        const nameValid = this.name != null && this.name.length > 0 && !this._editMode;
        const fieldsValid = this._fields.length > 0;
        this._isValid = (actionsValid && fieldsValid && this.criteriaIsValid() && nameValid);
    }

    private initializeActions(actions: FormActionInterface[]): FormAction[] {
        return actions.map((action: FormActionInterface) => {
            return new FormAction(this._availableIncludedFields, action);
        });
    }

    private getAvailableIncludedFields(): FormFieldInterface[] {
        return this._includedFields.filter((includedField: FormFieldInterface) => {
            return this._fields.some((field: Column) => field.id === includedField.columnId);
        });
    }
}
