export const getUserInitials = (name: string): string => {
    if (!name) {
        return '';
    }
    const trimmedName = name.trim().toUpperCase();
    const parts = trimmedName.split(' ');

    if (parts[0] && parts[1]) {
        return parts[0][0] + parts[parts.length - 1][0];
    } else {
        return trimmedName[0];
    }
};
