import { FormFieldInterface } from '../../../common/interfaces';
import { SubmittedForm } from '../SubmittedForm';

export const mapFormFieldsToSubmittedForm = (formFields: FormFieldInterface[] | undefined): SubmittedForm | undefined => {
    if (!formFields) {
        return;
    }

    const submittedForm: SubmittedForm = {};

    formFields.forEach((formField) => {
        // Use objectValue even when null because that is used to clear a cell value.
        submittedForm[formField.columnId] = formField.objectValue !== undefined ? formField.objectValue : formField.value;
    });

    return submittedForm;
};
