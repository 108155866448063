import classNames from 'classnames';
import * as React from 'react';
import onClickOutside, { InjectedOnClickOutProps } from 'react-onclickoutside';
import { MenuDivider } from '../../../containers/View/Filter/FilterMenu/MenuDivider';
import { DataClientProps } from '../../Base';
import { FormFieldMenuItem } from './FormFieldMenuItem';

import imageIcon from '../../../assets/images/mime-types/image.svg';
import verticalEllipsis from '../../../containers/Form/FormRule/assets/vertical-ellipsis.svg';
import './FormFieldMenu.css';
import { withLanguageElementsHOC, LanguageElementsProp } from '../../../language-elements/withLanguageElementsHOC';

export enum FormFieldMenuItemType {
    CLEAR_CONTENTS = 'CLEAR_CONTENTS',
    INSERT_IMAGE = 'INSERT_IMAGE',
}

interface OwnProps {
    fieldName?: string;
    onMenuItemRender?: (menuItemType: FormFieldMenuItemType) => boolean;
    onSelectImage?: () => void;
    onClearContents?: () => void;
    validation?: boolean;
    isNewSubmission: boolean;
}

interface State {
    menuOpen?: boolean;
}

export type FormFieldMenuProps = OwnProps & InjectedOnClickOutProps & DataClientProps & LanguageElementsProp;

class FormFieldMenu extends React.Component<FormFieldMenuProps, State> {
    public constructor(props: FormFieldMenuProps) {
        super(props);
        this.state = { menuOpen: false };
    }

    public render = (): React.ReactNode => {
        const shouldRenderClearContentsMenuItem = this.shouldMenuItemDisplay(FormFieldMenuItemType.CLEAR_CONTENTS);
        const shouldRenderInsertImageMenuItem = this.shouldMenuItemDisplay(FormFieldMenuItemType.INSERT_IMAGE);
        const shouldRenderMenuDivider = shouldRenderClearContentsMenuItem && shouldRenderInsertImageMenuItem;
        return (
            <div
                tabIndex={0}
                className={classNames('form-field-menu', { open: this.state.menuOpen })}
                data-client-id={this.props.dataClientId}
                data-testid={this.props.dataClientId}
            >
                <a
                    className={classNames('form-field-menu-button', { active: this.state.menuOpen })}
                    data-client-id={this.props.dataClientId}
                    onClick={() => this.setState({ menuOpen: !this.state.menuOpen })}
                >
                    <img src={verticalEllipsis} alt={`${this.props.languageElements.FORM_FIELD_MENU_ALT_TEXT} ${this.props.fieldName || ''}`} />
                </a>
                {this.state.menuOpen && (
                    <div className="form-field-menu-dropdown">
                        {shouldRenderClearContentsMenuItem && (
                            <FormFieldMenuItem onClick={this.handleClearContents}>
                                {this.props.languageElements.FORM_FIELD_MENU_CLEAR_CONTENTS}
                            </FormFieldMenuItem>
                        )}
                        {shouldRenderMenuDivider && <MenuDivider />}
                        {shouldRenderInsertImageMenuItem && (
                            <FormFieldMenuItem onClick={this.handleInsertImage} icon={imageIcon}>
                                {this.props.languageElements.FORM_FIELD_MENU_INSERT_IMAGE}
                            </FormFieldMenuItem>
                        )}
                    </div>
                )}
            </div>
        );
    };

    // This event handler is used by the onClickOutside higher order component that wraps the FormFieldMenu.
    // See the following for more info: https://github.com/Pomax/react-onclickoutside#ensuring-there-is-a-click-handler
    // noinspection JSUnusedGlobalSymbols
    public handleClickOutside = () => this.closeMenu();

    private shouldMenuItemDisplay = (menuItemType: FormFieldMenuItemType) => {
        if (menuItemType === FormFieldMenuItemType.INSERT_IMAGE) {
            return !this.props.validation && !this.props.isNewSubmission;
        }
        return true;
    };

    private closeMenu = () => this.state.menuOpen && this.setState({ menuOpen: false });

    private handleInsertImage = () => {
        if (this.props.onSelectImage) {
            this.props.onSelectImage();
        }
        this.closeMenu();
    };

    private handleClearContents = () => {
        if (this.props.onClearContents) {
            this.props.onClearContents();
        }
        this.closeMenu();
    };
}

export default withLanguageElementsHOC(onClickOutside(FormFieldMenu));
