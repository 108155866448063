import * as React from 'react';
import './Badge.css';

export interface BadgeProps {
    count: number;
}

export const Badge: React.SFC<BadgeProps> = props => {
    if (props.count < 0) {
        throw new Error('Negative counts are not supported.');
    }

    return (
        <div className={`badge`}>
            <div className="badge-number"><p>{props.count > 999 ? '999+' : props.count}</p></div>
        </div>
    );
};
