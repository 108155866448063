import { AsyncStatus } from '../../common/enums';
import { HomeView } from '../../common/interfaces';
import { fromJS, Map as ImmutableMap } from 'immutable';
import { SortDirection } from 'react-virtualized';
import { Actions, ActionTypes } from './Actions';
import { HomeTableColumn } from './HomeTableColumnData';

const initialState = fromJS({
    status: AsyncStatus.NOT_STARTED,
    data: ImmutableMap<string, HomeView>(),
    sortBy: HomeTableColumn.LAST_ACCESSED,
    sortDirection: SortDirection.ASC,
    showPermissionsError: false,
});

const homeReducer = (state = initialState, action: Actions) => {
    switch (action.type) {
        case ActionTypes.FETCH_HOME_DATA:
            return state.set('status', AsyncStatus.IN_PROGRESS);

        case ActionTypes.FETCH_HOME_DATA_SUCCESS:
            const views = action.payload;
            // Create a map for storing views, so that we can do the following efficiently:
            // 1) look up a view name by view id (needed for Recents and Favorites panels)
            // 2) find and update view store based on view id
            const viewsById = ImmutableMap<string, HomeView>().withMutations((map) => views.forEach((view) => map.set(view.id!, view)));
            return state.merge({
                data: viewsById,
                status: AsyncStatus.DONE,
            });

        case ActionTypes.FETCH_HOME_DATA_FAILURE:
            return state.set('status', AsyncStatus.ERROR);

        case ActionTypes.REMOVE_VIEW:
            return state.removeIn(['data', action.payload]);

        case ActionTypes.STORE_FAVORITE_VIEW:
            return state.setIn(['data', action.payload, 'favorite'], true);

        case ActionTypes.REMOVE_FAVORITE_VIEW:
            return state.setIn(['data', action.payload, 'favorite'], false);

        case ActionTypes.STORE_RECENT_VIEW:
            return state.setIn(['data', action.payload, 'lastAccessed'], new Date(Date.now()).toISOString());

        case ActionTypes.STORE_PINNED_VIEW:
            return state.setIn(['data', action.payload, 'pinned'], true);

        case ActionTypes.REMOVE_PINNED_VIEW:
            return state.setIn(['data', action.payload, 'pinned'], false);

        case ActionTypes.SET_SORT_BY:
            return state.set('sortBy', action.payload);

        case ActionTypes.SET_SORT_DIRECTION:
            return state.set('sortDirection', action.payload);

        case ActionTypes.SHOW_PERMISSIONS_ERROR_ON_CREATE:
            return state.set('showPermissionsError', action.payload);
    }
    return state;
};

export default homeReducer;
