import { CURRENT_USER_DEFAULT_EMAIL } from '../../../constants';
import { RowFieldValidationError } from '../../../enums';
import { ContactObjectValue, FormFieldInterface } from '../../../interfaces';
import { isContactObjectValue, isEmailValid, isMultiContactObjectValue } from '../../../utils';
import { IRowFieldValidator } from './IRowFieldValidator';
import { RowFieldBaseValidator, RowFieldValueIsValidResult } from './RowFieldBaseValidator';

export class MultiContactValidator extends RowFieldBaseValidator implements IRowFieldValidator {
    public constructor(originalRowField: FormFieldInterface) {
        super(originalRowField);
    }

    public validate(rowField: FormFieldInterface): RowFieldValueIsValidResult {
        const rowFieldValueIsValidResult = super.validateMandatoryProperties(rowField);
        if (rowFieldValueIsValidResult) {
            return rowFieldValueIsValidResult;
        }

        // Required Checks
        if (rowField.required) {
            if ((typeof rowField.objectValue !== 'string' && typeof rowField.objectValue !== 'object') ||
                (typeof rowField.objectValue === 'string' && rowField.objectValue.trim() === '') ||
                (typeof rowField.objectValue === 'object' && !isMultiContactObjectValue(rowField.objectValue)) ||
                (typeof rowField.objectValue === 'object' && this.multiContactListValuesAreEmpty(rowField.objectValue.values))) {
                return {
                    isValid: false,
                    validationError: RowFieldValidationError.MISSING_REQUIRED_VALUE,
                };
            }
        }

        // Regardless if multi-contact has restricted value enabled, return false if an email isn't valid syntax
        if (isMultiContactObjectValue(rowField.objectValue)) {
            for (const value of rowField.objectValue.values) {
                if (value.email !== CURRENT_USER_DEFAULT_EMAIL && !isEmailValid(value.email)) {
                    return {
                        isValid: false,
                    };
                }
            }
        }

        // Restricted Values Check
        if (rowField.validation) {
            // Allow null when restricted value check enable to ensure the DV user can clear values within a row field
            if (rowField.objectValue === null) {
                return {
                    isValid: true,
                };
            }

            // Check that value is not equal to the default value
            if (!isMultiContactObjectValue(rowField.objectValue)) {
                return {
                    isValid: rowField.defaultValue === rowField.objectValue,
                };
            }

            // Check if objectValue contact emails exactly match default contact emails
            if (this.checkIsObjectValueEqualToDefaultValue(rowField)) {
                return {
                    isValid: true,
                };
            }
            // Validate newly selected contacts only. Original contacts are excluded from the validation as these values
            // are already part of the source sheet and are already considered valid in the sheet.
            // ESC-9321: https://app.smartsheet.com/sheets/4x6mQ3PWvFXMrGcCgHjQP2JhC58w4g2fxMPQmFq1?rowId=7611455472619396
            const originalContacts = isMultiContactObjectValue(this.originalRowField.objectValue) ? this.originalRowField.objectValue.values.map(value => value.email || value.name) : [];
            const newContacts = rowField.objectValue.values.filter((value) => originalContacts.indexOf(value.email || value.name) === -1);
            // Check if objectValue contact emails match a contact option
            return {
                isValid: newContacts.every(value => super.isContactOption(rowField.contactOptions, value.email || value.name)),
            };
        }

        return {
            isValid: true,
        };
    }

    private checkIsObjectValueEqualToDefaultValue(rowField: FormFieldInterface): boolean {
        if (!isMultiContactObjectValue(rowField.defaultValue) || !isMultiContactObjectValue(rowField.objectValue)) {
            return false;
        }

        const defaultValuesForObjectValue = rowField.defaultValue.values;
        const rowFieldValuesForObjectValue = rowField.objectValue.values;

        if (defaultValuesForObjectValue.length !== rowFieldValuesForObjectValue.length) {
            return false;
        }

        return defaultValuesForObjectValue.every((value: ContactObjectValue, index: number) => {
            const rowFieldEmail = rowFieldValuesForObjectValue[index].email;
            if (value.email == null || rowFieldEmail == null) {
                return false;
            }

            return value.email.toLowerCase() === rowFieldEmail.toLowerCase();
        });
    }

    private multiContactListValuesAreEmpty(values: any): boolean {
        if (!Array.isArray(values)) {
            return true;
        }

        return values.every((value: any) => {
            if (typeof value === 'object' && isContactObjectValue(value)) {
                if (value.name && typeof value.name === 'string' && value.name.trim() !== '') {
                    return false;
                }
                if (value.email && typeof value.email === 'string' && value.email.trim() !== '') {
                    return false;
                }
            }
            return true;
        });
    }
}
