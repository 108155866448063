import { Bulletin } from '../common/interfaces';
import axiosInstance from './Axios.instance';

class BulletinsClient {
    private static url = 'bulletins';

    public async get(): Promise<Bulletin[]> {
        const response = await axiosInstance.get<Bulletin[]>(`${BulletinsClient.url}`);
        return response.data;
    }
}

const bulletinsClient = new BulletinsClient();
export default bulletinsClient;
