import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import * as React from 'react';
import { AutomationIds } from '../../common/enums/AutomationElements.enum';
import Syncing from '../../assets/images/Syncing.svg';
import { HTMLProps } from 'react';

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const SpinContainer = styled.div`
    animation: ${spin} 1s linear infinite;
    width: 100%;
    height: 24px;
`;

export const SyncingSpinner = (props: HTMLProps<HTMLDivElement>) => (
    <SpinContainer data-client-id={AutomationIds.ROW_STATUS_IN_PROGRESS} {...props}>
        <img src={Syncing} />
    </SpinContainer>
);

export default SyncingSpinner;
