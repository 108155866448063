import { FormRule } from '../../common/classes/';
import { CURRENT_USER, CURRENT_USER_DEFAULT_NAME } from '../../common/constants';
import { ColumnType } from '../../common/enums';
import * as dv from '../../common/interfaces';
import { FormFieldInterface } from '../../common/interfaces';
import { isContactObjectValue, isEmailValid } from '../../common/utils';
import { fromJS } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import 'reflect-metadata';
import { createSelector, createStructuredSelector } from 'reselect';
import logicIcon from '../../assets/images/toolbar/rightRail/branch.svg';
import infoIcon from '../../assets/images/toolbar/rightRail/toggles.svg';
import * as gsFormattingUtility from '../../common/utils/GsFormattingUtility';
import { LanguageElementsProp, withLanguageElementsHOC } from '../../language-elements/withLanguageElementsHOC';
import { StoreState } from '../../store';
import { AdminPanelComponentProps } from '../Admin/interfaces/AdminPanelComponentProps.interface';
import { AdminPanelOnChange } from '../Admin/interfaces/AdminPanelOnChange.interface';
import { makeSelectSmartsheetUsers, makeSelectSmartsheetUsersByEmails, makeSelectViewSourceMetaData } from '../Admin/Selectors';
import FieldPropertiesContainer from './FieldProperties/FieldPropertiesContainer';
import './FormContainer.css';
import { mapFromFormContainerData } from './formContainerMapper';
import { DetailsPanel } from './FormList/DetailsPanelLayout';
import FormList from './FormList/FormList';
import FormLogicContainer from './FormLogic/FormLogicContainer';
import './RightRail/RightRail.css';
import RightRailContainer from './RightRail/RightRailContainer';

export enum FormContainerTab {
    INFO = 'INFO',
    LOGIC = 'LOGIC',
}

export function fromStringToEnum(value: string): FormContainerTab {
    if (value.toLowerCase() === FormContainerTab.LOGIC.toLowerCase()) {
        return FormContainerTab.LOGIC;
    }

    if (value.toLowerCase() === FormContainerTab.INFO.toLowerCase()) {
        return FormContainerTab.INFO;
    }

    throw new Error(`${value} is not a known FormContainerTab value.`);
}

interface OwnProps extends AdminPanelComponentProps {
    form: dv.FormInterface;
    config: dv.ViewConfig;
    isDirty: boolean;
}

interface StateProps {
    viewSourceMetaData: dv.ViewSourceMetaData;
    smartsheetUsers: dv.IPaginatedResult<dv.SmartsheetUser>;
    smartsheetUsersByEmails: dv.IPaginatedResult<dv.SmartsheetUser>;
}

export type FormContainerProps = OwnProps & StateProps;

export interface FormContainerState {
    form: dv.FormInterface;
    formRulesValid: boolean;
    activeTab: FormContainerTab;
    includedFields: dv.FormFieldInterface[];
    selectedField?: dv.FormFieldInterface;
    // TODO: Move to common
    detailsPanelLayout: DetailsPanel;
}

export class FormContainer extends React.Component<FormContainerProps & LanguageElementsProp, FormContainerState> {
    private selectedColumn: dv.Column | undefined;

    public constructor(props: FormContainerProps & LanguageElementsProp) {
        super(props);
        this.state = this.getInitialState(props);
    }

    public componentDidUpdate(prevProps: FormContainerProps, prevState: FormContainerState): void {
        if ((this.props.cancelChanges && this.props.cancelChanges !== prevProps.cancelChanges) || this.props.viewId !== prevProps.viewId) {
            this.setState(this.getInitialState(this.props, prevState));
        }
    }

    public render(): React.ReactNode {
        this.selectedColumn = this.getSelectedColumn();

        return (
            <div className={`forms-container ${this.props.isDirty ? 'dirty' : ''}`}>
                <FormList
                    availableFields={this.props.viewSourceMetaData.columns}
                    includedFields={this.state.includedFields}
                    width={300}
                    onChange={this.formListChangeHandler}
                    itemClick={(i: number) => {
                        this.onFieldClick(i);
                    }}
                    onDetailsPanelLayoutChange={this.handleDetailsPanelLayoutChange}
                    detailsPanelLayout={this.state.detailsPanelLayout}
                />
                {this.state.activeTab === FormContainerTab.INFO && (
                    <FieldPropertiesContainer
                        viewId={this.props.viewId}
                        field={this.state.selectedField}
                        onFieldChange={this.onFieldChange}
                        selectedColumn={this.selectedColumn}
                        smartsheetUsers={this.props.smartsheetUsers}
                        smartsheetUsersByEmails={this.props.smartsheetUsersByEmails}
                    />
                )}
                {this.state.activeTab === FormContainerTab.LOGIC && (
                    <FormLogicContainer
                        onClickAddRule={this.handleClickAddRule}
                        onUpdateRule={this.handleUpdateFormRule}
                        onDeleteRule={this.handleDeleteFormRule}
                        rules={this.state.form.rules}
                        fields={this.props.viewSourceMetaData.columns}
                        includedFields={this.state.includedFields}
                    />
                )}
                <RightRailContainer
                    activeItemKey={this.state.activeTab}
                    onActiveItemChange={(key) => this.handleActiveItemChange(key)}
                    items={[
                        {
                            key: FormContainerTab.INFO,
                            icon: infoIcon,
                            text: this.props.languageElements.DETAIL_PANEL_INFO_ALT_TEXT,
                        },
                        {
                            key: FormContainerTab.LOGIC,
                            icon: logicIcon,
                            text: this.props.languageElements.DETAIL_PANEL_LOGIC_ALT_TEXT,
                        },
                    ]}
                />
            </div>
        );
    }

    private getSelectedColumn = (): dv.Column | undefined => {
        if (this.state.selectedField) {
            return this.props.viewSourceMetaData.columns.find((column) => column.id === this.state.selectedField!.columnId)!;
        }
        return undefined;
    };

    private handleContainerChange = (): void => {
        const form = this.getFormData();
        const config = this.getConfigData();
        const value: AdminPanelOnChange = {
            isValid:
                this.state.form &&
                this.state.form.rules! &&
                this.state.form.rules!.every((rule: dv.FormRuleInterface) => {
                    return new FormRule(this.props.viewSourceMetaData.columns, this.state.includedFields, rule).isValid;
                }) &&
                this.state.form.fields! &&
                this.state.form
                    .fields!.filter((field: dv.FormFieldInterface) => field.type === ColumnType.MULTI_CONTACT_LIST)
                    .every((field: dv.FormFieldInterface) => {
                        if (!field.defaultValue) {
                            return true;
                        } else if (Array.isArray(field.defaultValue)) {
                            return field.defaultValue.every((defaultValue: dv.Contact) => {
                                return defaultValue.name === CURRENT_USER_DEFAULT_NAME ? true : isEmailValid(defaultValue.email!);
                            });
                        } else if (typeof field.defaultValue === 'string') {
                            if (field.defaultValue === CURRENT_USER) {
                                return true;
                            }
                            return isEmailValid(field.defaultValue);
                        } else {
                            return false;
                        }
                    }) &&
                this.state.detailsPanelLayout.detailsPanelDescription !== '',

            data: fromJS({
                form,
                config,
            }),
        };

        this.props.onChange(value);
    };

    private handleActiveItemChange(key: string): void {
        this.setState({
            activeTab: fromStringToEnum(key),
        });
    }

    private handleUpdateFormRule = (rule: dv.FormRuleInterface): void => {
        const form = fromJS(this.state.form).toJS();
        const index = form.rules.findIndex((r: dv.FormRuleInterface) => r.id === rule.id);
        if (index !== -1) {
            form.rules[index] = rule;
        }
        this.setState(
            {
                form,
            },
            this.handleContainerChange
        );
    };

    private handleDeleteFormRule = (rule: dv.FormRuleInterface): void => {
        const form = fromJS(this.state.form).toJS();
        const index = form.rules.findIndex((r: dv.FormRuleInterface) => r.id === rule.id);
        if (index !== -1) {
            form.rules.splice(index, 1);
        }
        this.setState(
            {
                form,
            },
            this.handleContainerChange
        );
    };

    private handleClickAddRule = (): void => {
        const form = fromJS(this.state.form).toJS();
        if (!form.rules) {
            form.rules = [];
        }
        form.rules.push(new FormRule(this.props.viewSourceMetaData.columns, this.state.includedFields));
        this.setState(
            {
                form,
            },
            this.handleContainerChange
        );
    };

    /**
     * For percent-formatted TEXT_NUMBER fields, the defaultValue property contains different values for FormField objects in the state.form.fields
     * array compared with the state.includedFields array. FormField objects in the state.includedFields array have a defaultValue of "10" for 10%,
     * while the FormField objects in the state.form.fields array have a defaultValue of "0.1" for "10%. Replacing the entire state.form.fields array
     * with the state.includedFields array will cause the defaultValue for percent-formatted TEXT_NUMBER fields to increase 100x each time a new field
     * is added.
     *
     * The state.includedFields array is built from the state.form.fields array in the getInitialState() method. This is where 0.1 is converted to 10.
     * When new fields are added to a form, the formListChangeHandler() method will add new FormField objects to the state.form.fields array.
     * defaultValue properties are undefined by default.  When the user enters a default value for a field, the onFieldChange() method makes a copy of
     * the FormField object in the state.includedFields array, converts "10" to "0.1", and then replaces the corresponding FormField object in the
     * state.form.fields array. The end result is that the defaultValue property for FormField objects in the state.form.fields have a different value
     * the corresponding FormField objects in the state.includedFields array.
     *
     * FormField objects that are not part of the includedFields array will be dropped from formFields array. New FormField objects in the
     * includedFields array will be added to the formFields array.  The formFields version of the FormField objects will be preserved for form fields
     * that have not been added nor removed.
     *
     * @param formFields - The array of FormField objects containing defaultProperties values converted for saving in the backend. (0.1 for 10%)
     * @param includedFields - The array of FormField objects containing defaultValue property values converted for display in the UI. (10 for 10%)
     */
    private getUpdatedFormFieldsArray(formFields: FormFieldInterface[], includedFields: FormFieldInterface[]): FormFieldInterface[] {
        const newFormFields: dv.FormFieldInterface[] = [];
        const formFieldMap = formFields.reduce((map, field) => map.set(field.columnId, field), new Map<number, dv.FormFieldInterface>());
        // For newIncluded objects, use the form.field version if it exists.
        includedFields.forEach((field) => {
            const formField = formFieldMap.get(field.columnId);
            newFormFields.push(formField ? formField : field);
        });
        return newFormFields;
    }

    private formListChangeHandler = (newIncluded: dv.FormFieldInterface[]): void => {
        const form = fromJS(this.state.form).toJS() as dv.FormInterface;
        form.fields = this.getUpdatedFormFieldsArray(form.fields!, newIncluded);

        let selectedField;
        if (this.state.selectedField) {
            selectedField = newIncluded.find((includedField: dv.FormFieldInterface) => includedField.columnId === this.state.selectedField!.columnId);
            for (const item of newIncluded) {
                item.selected = item.title === this.state.selectedField.title!;
            }
        }

        this.setState(
            {
                form,
                selectedField,
                includedFields: newIncluded,
            },
            this.handleContainerChange
        );
    };

    private onFieldClick = (columnId: number): void => {
        const state = {
            ...this.state,
            activeTab: FormContainerTab.INFO,
        };
        state.includedFields.forEach((includedField: dv.FormFieldInterface) => {
            if (includedField.columnId !== columnId || includedField.selected) {
                if (includedField.columnId === columnId && includedField.selected) {
                    state.selectedField = undefined;
                }
                includedField.selected = false;
            } else {
                includedField.selected = true;
                state.selectedField = this.state.includedFields.find((field: dv.FormFieldInterface) => field.columnId === columnId);
            }
        });
        this.setState(state, this.handleContainerChange);
    };

    private onFieldChange = (inboundField: dv.FormFieldInterface): void => {
        const form = fromJS(this.state.form).toJS() as dv.FormInterface;
        const includedFields: dv.FormFieldInterface[] = fromJS(this.state.includedFields).toJS();
        let selectedField: dv.FormFieldInterface = fromJS(this.state.selectedField).toJS();
        const includedFieldIndex = includedFields.findIndex((field: dv.FormFieldInterface) => field.columnId === inboundField.columnId);
        const includedFormFieldIndex = form.fields!.findIndex((field: dv.FormFieldInterface) => field.columnId === inboundField.columnId);
        if (includedFieldIndex !== -1 && includedFormFieldIndex !== -1) {
            selectedField = fromJS(inboundField).toJS();
            includedFields[includedFieldIndex] = fromJS(inboundField).toJS();

            // Handle number formatting
            const updatedInboundField = fromJS(inboundField).toJS();
            if (updatedInboundField.defaultValue != null) {
                switch (updatedInboundField.type) {
                    case ColumnType.TEXT_NUMBER:
                        updatedInboundField.defaultValue = gsFormattingUtility
                            .getNumberFromTrimmedInputAndFormatStringAll(updatedInboundField.defaultValue, updatedInboundField.format, true)
                            .toString();
                        break;
                    case ColumnType.MULTI_CONTACT_LIST:
                        if (Array.isArray(updatedInboundField.defaultValue)) {
                            updatedInboundField.defaultValue = updatedInboundField.defaultValue.map((value: any) => {
                                if (isContactObjectValue(value)) {
                                    return {
                                        name: value.name,
                                        email: value.email,
                                        objectType: value.objectType,
                                    };
                                }

                                return value;
                            });
                        }
                        break;
                }
            }

            form.fields![includedFormFieldIndex] = updatedInboundField;
        }
        this.setState(
            {
                selectedField,
                includedFields,
                form,
            },
            this.handleContainerChange
        );
    };

    private handleDetailsPanelLayoutChange = (valuesToUpdate: Partial<DetailsPanel>): void => {
        let detailsPanelLayout = fromJS(this.state.detailsPanelLayout).toJS();

        detailsPanelLayout = { ...detailsPanelLayout, ...valuesToUpdate };

        this.setState(
            {
                detailsPanelLayout,
            },
            this.handleContainerChange
        );
    };

    private getInitialState = (props: FormContainerProps, prevState?: FormContainerState): FormContainerState => {
        const includedFields: dv.FormFieldInterface[] = [];
        if (props.form && props.form.fields) {
            for (const includedItem of props.form.fields) {
                const iField: dv.FormFieldInterface = Object.assign({ type: '' as any, title: '', ordinal: 0 }, includedItem);
                for (const availableItem of props.viewSourceMetaData.columns) {
                    if (availableItem.id === includedItem.columnId) {
                        iField.type = availableItem.type;
                        iField.title = availableItem.title;
                        iField.symbol = availableItem.symbol;
                        iField.options = availableItem.options;

                        if (availableItem.format) {
                            iField.format = availableItem.format;
                        }

                        if (availableItem.type === ColumnType.TEXT_NUMBER && iField.defaultValue != null) {
                            iField.defaultValue = gsFormattingUtility.getFormattedValueForEditAll(iField.defaultValue, availableItem.format, true);
                            iField.defaultValue = iField.defaultValue + ''; // The back-end (DV API) expects this to be a string.
                        }
                    }
                }
                includedFields.push(iField);
            }
        }

        return {
            form: props.form || { rules: [], fields: [] },
            activeTab: prevState ? prevState.activeTab : FormContainerTab.INFO,
            formRulesValid: true,
            includedFields,
            selectedField: undefined,
            detailsPanelLayout: {
                detailsPanelDescription: props.config.detailsPanelDescription,
                detailsPanelInitialTab: props.config.detailsPanelInitialTab,
            },
        };
    };

    private getFormData = () => {
        // The original form (props.form) is undefined when no form specified. However, the form container data contain an object with empty fields
        // and rules arrays when no form specified. Return undefined if both original and updated forms are equivalent to an empty form
        if (
            !this.props.form &&
            (!Array.isArray(this.state.form.fields) || this.state.form.fields.length === 0) &&
            (!Array.isArray(this.state.form.rules) || this.state.form.rules.length === 0)
        ) {
            return;
        }

        return mapFromFormContainerData(this.state.form);
    };

    private getConfigData = () => {
        const { detailsPanelLayout } = this.state;

        if (
            this.props.config &&
            this.props.config.detailsPanelDescription === detailsPanelLayout.detailsPanelDescription &&
            this.props.config.detailsPanelInitialTab === detailsPanelLayout.detailsPanelInitialTab
        ) {
            return;
        }

        return {
            detailsPanelDescription: detailsPanelLayout.detailsPanelDescription,
            detailsPanelInitialTab: detailsPanelLayout.detailsPanelInitialTab,
        };
    };
}

const mapState = createStructuredSelector<StoreState, StateProps>({
    viewSourceMetaData: createSelector(makeSelectViewSourceMetaData(), (stateViewSourceMetaData) => stateViewSourceMetaData!),
    smartsheetUsers: createSelector(makeSelectSmartsheetUsers(), (smartsheetUsers) => smartsheetUsers!),
    smartsheetUsersByEmails: createSelector(makeSelectSmartsheetUsersByEmails(), (smartsheetUsersByEmails) => smartsheetUsersByEmails!),
});

export default withLanguageElementsHOC(connect<StateProps, null, OwnProps, StoreState>(mapState)(FormContainer));
