import { ImageUrlDto } from '../../common/interfaces';
import { Epic, ofType, StateObservable } from 'redux-observable';
import { EMPTY, from, merge } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { StoreState } from '..';
import { CELL_IMAGE_MAX_HEIGHT } from '../../components/Grid/Cells/CellImage';
import { ImageUrlsClient } from '../../http-clients/ImageUrlsClient';
import { ActionByType } from '../types';
import { Actions, ActionTypes } from './Actions';
import { imageGridThumbnailsSelector, imageThumbnailsSelector, imageUrlsSelector } from './Selectors';

export const fetchImageUrlsEpic: Epic<Actions> = (action$, state$: StateObservable<StoreState>) =>
    action$.pipe(
        ofType(ActionTypes.FETCH_IMAGE_URLS),
        concatMap((action: ActionByType<Actions, ActionTypes.FETCH_IMAGE_URLS>) => {
            const storeState = state$.value;
            const thumbnailUrls = imageThumbnailsSelector(storeState);
            const imageUrls = imageUrlsSelector(storeState);
            const gridUrls = imageGridThumbnailsSelector(storeState);

            const images = action.payload as Array<{ width?: number; height?: number; id?: string }>;
            // Set a target height or width of the thumbnail. The image proxy will scale the image appropriately.
            const requestedThumbnails = images
                .filter((image) => !thumbnailUrls.has(image.id!))
                .map(
                    (image): ImageUrlDto => (image.height! > image.width! ? { imageId: image.id!, height: 200 } : { imageId: image.id!, width: 200 })
                );
            const requestedImageUrls = images.filter((image) => !imageUrls.has(image.id!)).map((image): ImageUrlDto => ({ imageId: image.id! }));
            const requestedGridThumbnails = images
                .filter((image) => !gridUrls.has(image.id!))
                .map(
                    (image): ImageUrlDto =>
                        image.height! > image.width!
                            ? { imageId: image.id!, height: CELL_IMAGE_MAX_HEIGHT }
                            : { imageId: image.id!, width: CELL_IMAGE_MAX_HEIGHT }
                );
            return merge(
                requestedThumbnails.length
                    ? from(ImageUrlsClient.generateImageUrls(requestedThumbnails)).pipe(map(Actions.storeThumbnailUrls))
                    : EMPTY,
                requestedImageUrls.length ? from(ImageUrlsClient.generateImageUrls(requestedImageUrls)).pipe(map(Actions.storeImageUrls)) : EMPTY,
                requestedGridThumbnails.length
                    ? from(ImageUrlsClient.generateImageUrls(requestedGridThumbnails)).pipe(map(Actions.storeGridUrls))
                    : EMPTY
            );
        })
    );
