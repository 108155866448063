/** @jsx jsx */
import { jsx } from '@emotion/core';
import * as React from 'react';
import { Fragment } from 'react';
import { AsyncStatus } from '../../../common/enums';
import { AutomationIds } from '../../../common/enums/AutomationElements.enum';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../store';
import { selectStatusForView } from '../Details/Selectors';
import { CSSTransition } from 'react-transition-group';
import { colors, useTooltip } from '@smartsheet/lodestar-core';
import { VerticalRule } from '../VerticalRule';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';
import { StyledTooltip } from '../../../components/DvStyledTooltip/StyledTooltip';

export const ViewStatus = () => {
    const languageElements = useLanguageElements();
    const status = useSelector<StoreState, AsyncStatus | undefined>((state) => selectStatusForView(state));
    const [showDone, setShowDone] = useState(false);
    useEffect(() => {
        if (status === AsyncStatus.DONE) {
            setShowDone(true);
        }
    }, [status]);

    let content = null;
    const transitionNodeRef = React.useRef(null);
    const ANIMATION_DELAY = 250;
    const fadingStyles = {
        '&.header-status-enter': { opacity: 0 },
        '&.header-status-enter-active': { opacity: 1 },
        '&.header-status-exit': { opacity: 1 },
        '&.header-status-exit-active': { opacity: 0, transition: `opacity ${ANIMATION_DELAY}ms` },
    };
    const containerStyles = {
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'center',
        height: '100%',
    };

    const doneContent = (
        <CSSTransition
            in={showDone}
            nodeRef={transitionNodeRef}
            classNames="header-status"
            timeout={ANIMATION_DELAY}
            mountOnEnter
            unmountOnExit
            onEnter={() => {
                setTimeout(() => {
                    setShowDone(false);
                }, 3000);
            }}
        >
            <div
                ref={transitionNodeRef}
                css={[
                    {
                        color: colors.visGreenDark10,
                    },
                    containerStyles,
                    fadingStyles,
                ]}
                data-client-id={AutomationIds.ROW_STATUS_DONE}
            >
                {languageElements.VIEW_STATUS_SYNC_COMPLETE}
            </div>
        </CSSTransition>
    );

    const { tooltipProps, targetProps } = useTooltip<HTMLDivElement>();

    switch (status) {
        case AsyncStatus.ERROR:
            // todo: handle differently
            content = <div css={{ alignSelf: 'center' }}>{languageElements.VIEW_STATUS_SYNC_ERROR}</div>;
            break;
        case AsyncStatus.DONE:
            content = doneContent;
            break;
        case AsyncStatus.IN_PROGRESS:
            content = (
                <Fragment>
                    <div {...targetProps} css={containerStyles}>
                        {languageElements.VIEW_STATUS_SYNCING}
                    </div>
                    <StyledTooltip {...tooltipProps} hasArrow={false}>
                        <div css={{ fontWeight: 'bold' }}>{languageElements.VIEW_STATUS_SYNCING}</div>
                        <div>{languageElements.VIEW_STATUS_SYNCING_TIP_TEXT}</div>
                    </StyledTooltip>
                </Fragment>
            );
            break;
    }

    return (
        content && (
            <Fragment>
                <VerticalRule />
                <div css={containerStyles}>{content}</div>
            </Fragment>
        )
    );
};
