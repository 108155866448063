export enum QueryStringKey {
    INCLUDE = 'include',
    EXCLUDE = 'exclude',
    FILTER_ID = 'filterId',
    REFRESH = 'refresh',
    LEVEL = 'level',
    ROW_IDS = 'rowIds',
    PAGE_SIZE = 'pageSize',
    INCLUDE_ALL = 'includeAll',
    ALLOW_PARTIAL_SUCCESS = 'allowPartialSuccess',
    PAGE = 'page',
    QUERY = 'query',
    MAXIMUM = 'maximum',
    SCOPES = 'scopes',
    COLUMN_IDS = 'columnIds',
    STATUS = 'status',
    VID = 'vid',
    USE_MASKED_IDS_FOR_S2RAPI = 'maskedIds',
}
