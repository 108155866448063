/**
 * Reverses the sorting result based on the given flag.
 * If the result is 0, it remains unchanged.
 * If the flag is true, the result is multiplied by -1.
 *
 * @param result - The sorting result to be reversed.
 * @param isReversed - A flag indicating whether the sorting result should be reversed.
 * @returns The reversed sorting result.
 */
const reverseSortingResult = (result: number, isReversed: boolean): number => {
    if (result === 0) {
        return 0;
    }

    return isReversed ? result * -1 : result;
};

/**
 * Returns a comparator function that performs null checks on the selected values of two items before comparing them.
 *
 * @template T The type of the items being compared.
 * @template U The type of the selected values being compared.
 * @param compareFn A function that compares two non-nullable values of type U and returns a number indicating their order.
 * @param selector A function that selects a value of type U from an item of type T.
 * @returns A comparator function that compares two items of type T based on their selected values of type U.
 */
export const withNullChecks = <T, U>(
    compareFn: (a: NonNullable<U>, b: NonNullable<U>) => number,
    selector: (item: T) => U,
    isReversed: boolean = false
): ((a: T, b: T) => number) => {
    return (a: T, b: T) => {
        const valA = selector(a);
        const valB = selector(b);
        const result = Boolean(valA) ? (Boolean(valB) ? compareFn(valA as NonNullable<U>, valB as NonNullable<U>) : -1) : 1;

        return reverseSortingResult(result, isReversed);
    };
};
