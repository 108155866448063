import styled from '@emotion/styled';
import { Button, sizes } from '@smartsheet/lodestar-core/';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLanguageElements } from '../../language-elements/withLanguageElementsHOC';
import { Actions as AppActions } from '../App/Actions';

const OwnershipTransferAdminControlRedirect: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const languageElements = useLanguageElements();

    const redirectToAdminControl = () => {
        dispatch(AppActions.setHomeFilter(null));
        history.push('/admin');
    };

    return (
        <Container>
            <InnerContainer>
                <Headline>{languageElements.OWNERSHIP_TRANSFER_REDIRECT_HEADLINE}</Headline>
                <Text>{languageElements.OWNERSHIP_TRANSFER_REDIRECT_TEXT}</Text>
                <Button onClick={redirectToAdminControl}>{languageElements.OWNERSHIP_TRANSFER_REDIRECT_BUTTON}</Button>
            </InnerContainer>
        </Container>
    );
};

export default OwnershipTransferAdminControlRedirect;

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
`;

const InnerContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
`;

const Headline = styled.h5`
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 ${sizes.small}px 0;
`;

const Text = styled.p`
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    max-width: 225px;
    margin: 0 0 ${sizes.large}px 0;
`;
