export enum SymbolSetName {
    PRIORITY = 'PRIORITY',
    PRIORITY_HML = 'PRIORITY_HML',
    DECISION_SHAPES = 'DECISION_SHAPES',
    DECISION_SYMBOLS = 'DECISION_SYMBOLS',
    VCR = 'VCR',
    RYG = 'RYG',
    RYGB = 'RYGB',
    RYGG = 'RYGG',
    WEATHER = 'WEATHER',
    HARVEY_BALLS = 'HARVEY_BALLS',
    PROGRESS = 'PROGRESS',
    ARROWS_3_WAY = 'ARROWS_3_WAY',
    ARROWS_4_WAY = 'ARROWS_4_WAY',
    ARROWS_5_WAY = 'ARROWS_5_WAY',
    DIRECTIONS_3_WAY = 'DIRECTIONS_3_WAY',
    DIRECTIONS_4_WAY = 'DIRECTIONS_4_WAY',
    SKI = 'SKI',
    SIGNAL = 'SIGNAL',
    STAR_RATING = 'STAR_RATING',
    HEARTS = 'HEARTS',
    MONEY = 'MONEY',
    EFFORT = 'EFFORT',
    PAIN = 'PAIN',
    NONE = 'NONE',
    FLAG = 'FLAG',
    STAR = 'STAR',
}
