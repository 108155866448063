import { IHyperlink } from '../../common/interfaces';
import * as React from 'react';

interface TextSegment {
    text?: string;
    link?: string;
}

interface ValueWithSpansAndLinksProps {
    displayValue?: string;
    hyperlink?: IHyperlink;
}

export const ValueWithSpansAndLinks: React.SFC<ValueWithSpansAndLinksProps> = (
    props: { displayValue: string | undefined; hyperlink?: IHyperlink },
    ) => {
    const {
        displayValue,
        hyperlink,
    } = props;

    if (displayValue == null || displayValue.trim() === '') {
        return null;
    } else if (hyperlink && hyperlink.url) {
        return getLinkFromHyperlinkObject({ displayValue, url: hyperlink.url });
    } else {
        return splitDisplayValueIntoSpansAndLinks({ displayValue});
    }
};

const getLinkFromHyperlinkObject: React.SFC<any> = (props: {displayValue: string, url: string}) => {
    const {
        displayValue,
        url,
    } = props;
    if (props.displayValue === '') {
        return null;
    }

    return (
        <div>
            <a
                href={prependHttp(url)}
                target={'_blank'}
                rel={'noopener noreferrer'}
                onClick={e => {
                    e.stopPropagation();
                }}
            >
                {displayValue}
            </a>
        </div>
    );
};

/**
 * This parses a string and returns JSX with <span> tags around text segments that do not include URLs and <a> tags around URLs.
 * Regex taken from app-core containLinks() in .../legacyApp/src/core/Common.ts (added global flag)
 */
const splitDisplayValueIntoSpansAndLinks: React.SFC<any> = (props: { displayValue: string }) => {
    if (props.displayValue === '') {
        return null;
    }

    const linkLocations: number[][] = [];
    const parsedText: TextSegment[] = [];
    const textLength: number = props.displayValue.length;
    let match: any;
    let stringIndex: number = 0;

    // eslint-disable-next-line max-len
    const re = /\b(sip:)([0-9]+)\b|\b((https?:\/\/|www\.)([a-zA-Z0-9!#$%&'*+-\/=?_`{|}~]+(:[^ @:]+)?@)?((([a-zA-Z0-9\-_]{1,255}|xn--[a-zA-Z0-9\-]+)\.)+(xn--[a-zA-Z0-9\-]+|[a-zA-Z]{2,63}|\d{1,3})|(%[0-9a-fA-F]{2})+|[0-9]+)(:[0-9]{1,5})?([\/?][^ \s\/,]*)*)/gi;

    while (true) {
        match = re.exec(props.displayValue);
        if (!match) {
            break;
        }
        linkLocations.push([match.index, re.lastIndex]);
    }

    linkLocations.forEach(item => {

        // If index is less than starting point of link, assign a section as TEXT props in parsedLine
        if (stringIndex < item[0]) {
            parsedText.push({text: getSection(stringIndex, item[0], props.displayValue)});
        }

        // Use start and end locations in 'item' to assign section of string to LINK prop in parsedLine
        parsedText.push({link: getSection(item[0], item[1], props.displayValue)});
        stringIndex = item[1];
    });

    // Get remainder of line after last url, if any
    if (stringIndex < textLength) {
        parsedText.push({text: getSection(stringIndex, textLength, props.displayValue)});
    }

    return (
        <div>
            {(() => {
                const content: JSX.Element[] = [];
                parsedText.forEach((segment, index) => {
                    if (segment.text != null) {
                        content.push(
                            <span key={index}>
                                {segment.text}
                            </span>);
                    } else if (segment.link) {
                        content.push(
                            <a
                                key={index}
                                href={prependHttp(segment.link)}
                                target={'_blank'}
                                rel={'noopener noreferrer'}
                                onClick={e => {
                                    e.stopPropagation();
                                }}
                            >
                                {segment.link}
                            </a>);
                    }
                });
                return content;
            })()}
        </div>
    );
};

const getSection = (start: number, end: number, line: string): string => {
    return line.slice(start, end);
};

/**
 * Prepends 'http://' to a string if it doesn't already have it.
 * from app-core
 */
const prependHttp = (url: string): string => {
    if ((url.length > 0) && (!/(^sip|^http)/i.test(url))) {
        return ('http://' + url);
    } else {
        return url;
    }
};
