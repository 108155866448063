const availableMimeTypes = new Set([
    'application/octet-stream',
    'application/msword',
    'application/vnd.ms-excel',
    'application/vnd.ms-powerpoint',
    'application/pdf',
    'text/plain',
    'application/zip',
    'application/vnd.ms-project',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'image/png',
    'image/jpeg',
    'image/gif',
    'image',
    'text/html',
    'text/x-vcard',
    'application/msoutlook',
    'audio/mp3',
    'video/x-ms-wmv',
    'application/vnd.google-apps.document',
    'application/vnd.google-apps.spreadsheet',
    'application/vnd.google-apps.presentation',
    'text/calendar;charset=UTF-8',
    'image/svg+xml',
    'text/xml',
    'application/vnd.google-apps.drawing',
    'application/vnd.google-apps.folder',
    'text/csv',
]);

export const getMimeTypeCssClassName = (mimeType: string | undefined = 'text/plain'): string => {
    if (availableMimeTypes.has(mimeType)) {
        return `mime-type-${mimeType.replace(/[/.=+;]/g, '-')}`;
    } else {
        return '';
    }
};
