import { FormAction, FormRule } from '../../../common/classes';
import { ColumnType, FormActionType, FormCriteriaComparator, FormRuleMenuOption } from '../../../common/enums';
import { Column, FormFieldInterface, FormRuleInterface } from '../../../common/interfaces';

import { BaseOptionType } from '@smartsheet/lodestar-core/dist/esm/components/selectV2/types';
import * as React from 'react';
import { AutomationIds, AutomationTypes } from '../../../common/enums/AutomationElements.enum';
import { BaseComponent } from '../../../components/Base';
import { MenuButton } from '../../../components/Buttons';
import { MultiSelectItem } from '../../../components/MultiSelect/MultiSelect.interface';
import { WithDataClientProps } from '../../../components/hoc/WithDataClient';
import { LanguageElements } from '../../../language-elements/LanguageElements';
import { LanguageElementsProp, withLanguageElementsHOC } from '../../../language-elements/withLanguageElementsHOC';
import FilterMultiSelect from '../../View/Filter/FilterMultiSelect';
import { SelectOption } from '../Interfaces/SelectOption.interface';
import './FormRule.css';
import chevron from './assets/chevron.svg';
import verticalEllipsis from './assets/vertical-ellipsis.svg';

interface Props extends WithDataClientProps {
    rule: FormRuleInterface;
    fields: Column[];
    includedFields: FormFieldInterface[];
    onUpdateRule: (rule: FormRuleInterface) => void;
    onDeleteRule: (rule: FormRuleInterface) => void;
    index: number;
}

interface State {
    rule: FormRule;
    criteriaColumnType: ColumnType;
}

class FormRuleBase extends BaseComponent<Props & LanguageElementsProp> {
    private static getFormRuleMenuOptionLabel(option: FormRuleMenuOption, languageElements: LanguageElements): string {
        switch (option) {
            case FormRuleMenuOption.EDIT_NAME:
                return languageElements.FORM_EDIT_MENU__OPTION;
            case FormRuleMenuOption.ENABLE:
                return languageElements.FORM_ENABLE_MENU_OPTION;
            case FormRuleMenuOption.DISABLE:
                return languageElements.FORM_DISABLE_MENU_OPTION;
            case FormRuleMenuOption.DELETE:
                return languageElements.FORM_DELETE_MENU_OPTION;
            default:
                return '';
        }
    }

    private static getFormCriteriaComparatorLabel(
        formCriteriaComparator: FormCriteriaComparator,
        formFieldType: ColumnType,
        languageElements: LanguageElements
    ): string {
        switch (formCriteriaComparator) {
            case FormCriteriaComparator.HAS_ANY_OF:
                return formFieldType === ColumnType.MULTI_PICKLIST
                    ? languageElements.FORM_CRITERIA_COMPARATOR_HAS_ANY_OF_OPTION
                    : languageElements.FORM_CRITERIA_COMPARATOR_IS_ONE_OF_OPTION;
            case FormCriteriaComparator.HAS_NONE_OF:
                return formFieldType === ColumnType.MULTI_PICKLIST
                    ? languageElements.FORM_CRITERIA_COMPARATOR_HAS_NONE_OF_OPTION
                    : languageElements.FORM_CRITERIA_COMPARATOR_IS_NOT_ONE_OF_OPTION;
            default:
                return '';
        }
    }

    private static getFormActionLabel(formAction: FormActionType, languageElements: LanguageElements): string {
        switch (formAction) {
            case FormActionType.HIDE:
                return languageElements.FORM_ACTION_HIDE_OPTION;
            case FormActionType.READ_ONLY:
                return languageElements.FORM_ACTION_READ_ONLY_OPTION;
            case FormActionType.EDITABLE:
                return languageElements.FORM_ACTION_EDITABLE_OPTION;
            case FormActionType.REQUIRE:
                return languageElements.FORM_ACTION_REQUIRE_OPTION;
            default:
                return '';
        }
    }
    public state: State;
    private fieldTypeMap: Map<number, ColumnType>;

    public constructor(props: Props & LanguageElementsProp) {
        super(props);
        const rule = new FormRule(props.fields, props.includedFields, props.rule);
        this.fieldTypeMap = this.createFieldColumnTypeMap(props.fields);
        if (!this.props.fields.find((field: Column) => field.id === rule.criteria.columnId)) {
            rule.clearCriteriaOptions();
        }
        this.state = {
            rule,
            criteriaColumnType: this.fieldTypeMap.get(rule.criteria.columnId) || ColumnType.PICKLIST,
        };
    }

    public UNSAFE_componentWillReceiveProps(nextProps: Props): void {
        const rule = new FormRule(nextProps.fields, nextProps.includedFields, nextProps.rule);
        this.setState({
            rule,
            criteriaColumnType: this.fieldTypeMap.get(rule.criteria.columnId) || ColumnType.PICKLIST,
        });
    }

    public render(): React.ReactNode {
        return (
            <div
                key={this.state.rule.id}
                className={`form-rule ${!this.state.rule.isValid && !this.state.rule.editMode ? 'invalid-rule' : ''}`}
                data-client-type={AutomationTypes.FORM_RULE_NODE}
                data-client-id={`${this.props.dataClientId || ''}${this.props.index}`}
            >
                <div className="form-rule-header">
                    {this.state.rule.editMode ? (
                        <input
                            type="text"
                            maxLength={50}
                            onChange={(e) => this.handleUpdateFormRuleName(e)}
                            onKeyDown={(e) => this.handleKeyDownFormRuleInput(e)}
                            onBlur={(e) => this.handleBlurFormRuleInput(e)}
                            placeholder={'Rule #1'}
                            aria-label={'Rule name'}
                            value={this.state.rule.name}
                            data-client-id={AutomationIds.FORM_RULE_NAME}
                        />
                    ) : (
                        <span className="form-rule-header-title" data-client-id={AutomationIds.FORM_RULE_BASE_HEADER_TITLE}>
                            {this.state.rule.name} <small>{this.state.rule.disabled ? '(disabled)' : ''}</small>
                        </span>
                    )}
                    <MenuButton
                        icon={verticalEllipsis}
                        iconAltText={this.props.languageElements.FORM_LOGIC_CHANGE_RULE}
                        hideChevron={true}
                        value={this.state.rule.criteria.comparator.toString()}
                        placeholder={'Select'}
                        disabled={false}
                        onClick={this.handleSelectFormRuleMenuOption}
                        classNames={'right-aligned'}
                        options={this.state.rule.getMenuOptions().map((option: FormRuleMenuOption) => {
                            return {
                                value: option,
                                label: FormRuleBase.getFormRuleMenuOptionLabel(option, this.props.languageElements),
                            } as SelectOption;
                        })}
                        dataClientId={AutomationIds.FORM_RULE_MENU_BUTTON_1}
                    />
                    <span className="form-rule-header-icon" onClick={this.handleSelectHide} data-client-id={AutomationIds.FORM_RULE_CHEVRON_ICON}>
                        <img className={this.state.rule.hidden ? '' : 'open'} src={chevron} alt="" />
                    </span>
                </div>
                {!this.state.rule.hidden && (
                    <div className="form-rule-body" data-client-type={AutomationTypes.FORM_RULE_BODY}>
                        <div className="form-criteria-container">
                            <div className="form-criteria">
                                <p>When:</p>
                                <MenuButton
                                    value={this.state.rule.criteria.columnId.toString()}
                                    placeholder={'Select column..'}
                                    disabled={false}
                                    onClick={this.handleSelectFormCriteriaField}
                                    options={this.state.rule.getCriteriaFields().map((field: Column) => {
                                        return {
                                            value: field.id,
                                            label: field.title,
                                        } as SelectOption;
                                    })}
                                    dataClientId={AutomationIds.FORM_RULE_MENU_BUTTON_2}
                                />
                                <MenuButton
                                    value={this.state.rule.criteria.comparator}
                                    placeholder={'Select'}
                                    disabled={false}
                                    onClick={this.handleSelectFormCriteriaComparator}
                                    options={this.state.rule.getCriteriaComparators().map((comparator: FormCriteriaComparator) => {
                                        return {
                                            value: comparator,
                                            label: FormRuleBase.getFormCriteriaComparatorLabel(
                                                comparator,
                                                this.state.criteriaColumnType,
                                                this.props.languageElements
                                            ),
                                        } as SelectOption;
                                    })}
                                    dataClientId={AutomationIds.FORM_RULE_MENU_BUTTON_3}
                                    classNames={'form-criteria-comparator-label'}
                                />
                                <FilterMultiSelect
                                    dataClientId={AutomationIds.FORM_RULE_MULTISELECT_2}
                                    readOnly={!this.state.rule.criteriaFieldIsValid()}
                                    selectedItems={this.getSelectedItemsForCriteriaField()}
                                    options={this.getOptionsForCriteriaField()}
                                    onChange={this.handleSelectFormCriteriaOption}
                                    placeholder={this.props.languageElements.PLACEHOLDER_MULTI_SELECT}
                                    allowCustom={false}
                                />
                                <span className="break" />
                            </div>
                        </div>
                        <div className="form-action-container" data-client-type={AutomationTypes.FORM_RULE_CONTAINER}>
                            {this.state.rule.actions.map((action: FormAction) => {
                                return (
                                    <div key={action.id}>
                                        <div className="form-action">
                                            <MenuButton
                                                value={action.actionType}
                                                placeholder={'Action'}
                                                disabled={!this.state.rule.criteriaIsValid()}
                                                onClick={(e: SelectOption) => this.handleSelectFormActionType(e, action.id)}
                                                options={this.state.rule.getAvailableActions().map((availableAction: FormActionType) => {
                                                    return {
                                                        value: availableAction,
                                                        label: FormRuleBase.getFormActionLabel(availableAction, this.props.languageElements),
                                                    } as SelectOption;
                                                })}
                                                dataClientId={AutomationIds.FORM_RULE_MENU_BUTTON_4}
                                            />
                                            <FilterMultiSelect
                                                dataClientId={AutomationIds.FORM_RULE_MULTISELECT_1}
                                                readOnly={!action.actionType}
                                                selectedItems={this.getSelectedItemsForActionField(this.state.rule.getFieldsForAction(action.id))}
                                                options={this.getOptionsForActionField(this.state.rule.getAvailableFields())}
                                                onChange={(e) => this.handleSelectFormActionField(action.id, e)}
                                                placeholder={this.props.languageElements.PLACEHOLDER_MULTI_SELECT}
                                                allowCustom={false}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
        );
    }

    private getOptionsForCriteriaField(): MultiSelectItem[] {
        return this.state.rule.getCriteriaFieldOptions().map((option: string) => ({
            value: option,
            label: option,
            subheading: '',
            isValid: true,
            controlIdPrefix: 'ctl',
        }));
    }

    private getSelectedItemsForCriteriaField(): BaseOptionType[] {
        return this.state.rule.criteria.options
            ? this.state.rule.criteria.options.map((option: string) => ({
                  value: option,
                  label: option,
                  subheading: '',
                  isValid: true,
                  controlIdPrefix: 'ctl',
              }))
            : [];
    }

    private getOptionsForActionField(fields: Column[]): MultiSelectItem[] {
        return fields.map((field: Column) => ({
            id: field.id,
            value: field.title,
            label: field.title,
            subheading: '',
            isValid: true,
            controlIdPrefix: 'ctl',
        }));
    }

    private getSelectedItemsForActionField(fields: Column[]): BaseOptionType[] {
        return fields.map((field: Column) => ({
            id: field.id,
            value: field.title,
            label: field.title,
            heading: field.title,
            subheading: '',
            isValid: true,
            controlIdPrefix: 'ctl',
        }));
    }

    private handleKeyDownFormRuleInput = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        const rule = this.state.rule;
        if ((e.key === 'Enter' || e.key === 'Tab') && rule.name) {
            rule.toggleEditMode();
        }
        this.props.onUpdateRule(rule.toWireModel());
    };

    private handleBlurFormRuleInput = (e: React.SyntheticEvent): void => {
        const rule = this.state.rule;
        if (rule.name) {
            rule.toggleEditMode();
        }
        this.props.onUpdateRule(rule.toWireModel());
    };

    private handleUpdateFormRuleName = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.state.rule.updateFormRuleName(e.currentTarget.value);
        this.props.onUpdateRule(this.state.rule.toWireModel());
    };

    private handleSelectFormRuleMenuOption = (e: SelectOption): void => {
        switch (e.value) {
            case FormRuleMenuOption.EDIT_NAME:
                this.state.rule.toggleEditMode();
                this.props.onUpdateRule(this.state.rule.toWireModel());
                break;
            case FormRuleMenuOption.ENABLE:
                this.state.rule.toggleDisabled();
                this.props.onUpdateRule(this.state.rule.toWireModel());
                break;
            case FormRuleMenuOption.DISABLE:
                this.state.rule.toggleDisabled();
                this.props.onUpdateRule(this.state.rule.toWireModel());
                break;
            case FormRuleMenuOption.DELETE:
                this.props.onDeleteRule(this.state.rule.toWireModel());
                break;
        }
    };

    private handleSelectFormCriteriaField = (e: SelectOption): void => {
        this.state.rule.setCriteriaField(Number(e.value));

        this.setState({ criteriaColumnType: this.fieldTypeMap.get(Number(e.value)) || ColumnType.PICKLIST });
        this.props.onUpdateRule(this.state.rule.toWireModel());
    };

    private handleSelectFormCriteriaComparator = (e: SelectOption): void => {
        this.state.rule.setCriteriaComparator(e.value);
        this.props.onUpdateRule(this.state.rule.toWireModel());
    };

    private handleSelectFormCriteriaOption = (e: MultiSelectItem[] | []): void => {
        const selectedOptions = e ? e.map((item: MultiSelectItem) => item.value) : [];
        this.state.rule.clearCriteriaOptions();
        this.state.rule.setCriteriaOptions(selectedOptions);
        this.props.onUpdateRule(this.state.rule.toWireModel());
    };

    private handleSelectFormActionType(e: SelectOption, actionId: string): void {
        this.state.rule.setAction(actionId, e.value);
        this.props.onUpdateRule(this.state.rule.toWireModel());
    }

    private handleSelectFormActionField(actionId: string, selectedItems: MultiSelectItem[]): void {
        this.state.rule.clearFieldsForAction(actionId);
        selectedItems.forEach((item: MultiSelectItem) => {
            this.state.rule.addField(actionId, item.id || 0);
        });
        this.props.onUpdateRule(this.state.rule.toWireModel());
    }

    private handleSelectHide = (): void => {
        this.state.rule.toggleIsHidden();
        this.props.onUpdateRule(this.state.rule.toWireModel());
    };

    private createFieldColumnTypeMap(fields: Column[]): Map<number, ColumnType> {
        const fieldsMap = new Map();

        if (!fields) {
            return fieldsMap;
        }

        fields.forEach((field: Column) => {
            fieldsMap.set(field.id, field.type);
        });

        return fieldsMap;
    }
}

export default withLanguageElementsHOC(FormRuleBase);
