import { AccessLevel, ColumnType, ViewSourceType } from '../../common/enums';
import {
    Column,
    FormInterface,
    Group,
    IOwnershipTransfer,
    IPaginatedResult,
    SmartsheetUser,
    ViewConfig,
    ViewShareBasic,
    ViewShareResult,
    ViewSourceMetaData,
    ViewWithOwnerAndUserDetails,
} from '../../common/interfaces';
import { isEmailValid } from '../../common/utils';
import { AxiosError, AxiosResponse } from 'axios';
import { Epic, ofType, StateObservable } from 'redux-observable';
import { concat, EMPTY, from, merge, of } from 'rxjs';
import { catchError, flatMap, map, switchMap } from 'rxjs/operators';
import groupsClient from '../../http-clients/Groups.client';
import { loggingClient } from '../../http-clients/Logging.client';
import ownershipTransferClient from '../../http-clients/OwnershipTransfer.client';
import usersClient from '../../http-clients/Users.client';
import viewClient from '../../http-clients/View.client';
import viewSourceAdminClient from '../../http-clients/ViewSourceAdmin.client';
import LanguageElements from '../../language-elements/LanguageElements';
import { ActionByType } from '../../store';
import convertImmutableObjectToJS from '../../store/Utils/ConvertImmutableObjectToJS';
import { forkEpic } from '../../store/Utils/ForkEpic';
import * as AppActions from '../App/Actions';
import { isDisabledContactPickerOnDemandSelector } from '../App/Selectors';
import * as HomeActions from '../Home/Actions';
import * as OwnershipTransferActions from '../OwnershipTransfer/Actions';
import { Actions, ActionTypes } from './Actions';

export const fetchAllAdminDataEpic: Epic<Actions | AppActions.Actions> = (action$, state$) =>
    action$.pipe(
        ofType(ActionTypes.FETCH_ALL_ADMIN_DATA),
        switchMap((action: ActionByType<Actions, ActionTypes.FETCH_ALL_ADMIN_DATA>) =>
            concat(
                // 1) Set stage to Action in Progress (shows spinner modal)
                of(AppActions.Actions.setAppStageActionInProgress(LanguageElements.SPINNER_LOADING_LABEL)),

                // 2) Trigger the various fetch epics to get and store prerequisite data.
                merge(
                    forkEpic(
                        fetchAdminViewDataEpic,
                        state$,
                        Actions.fetchAdminViewData(
                            action.payload.viewId,
                            action.payload.includeConfig,
                            action.payload.includeShares,
                            action.payload.includeForm,
                            action.payload.includeFilters
                        )
                    ),
                    forkEpic(fetchSmartsheetGroupsEpic, state$, Actions.fetchSmartsheetGroups()),
                    forkEpic(fetchSmartsheetUsersEpic, state$, Actions.fetchSmartsheetUsers()),
                    forkEpic(fetchSmartsheetAdminsEpic, state$, Actions.fetchSmartsheetAdmins(action.payload.viewId)),
                    forkEpic(fetchViewSourceMetaDataEpic, state$, Actions.fetchViewSourceMetaData(action.payload.viewId))
                ),

                // 3) Fetch the Smartsheet users by emails. This is depended on view and view source metadata
                // to determine what shares and form columns to fetch users which is why it happens afterwards
                of(Actions.fetchSmartsheetUsersByEmails()),

                // 4) Clean form data
                of(Actions.cleanForm()),

                // 5) Reset stage - Concat ensures this observable happens after the others have completed
                of(AppActions.Actions.resetAppStage())
            )
        )
    );

export const fetchAdminViewDataEpic: Epic<Actions | AppActions.Actions> = (action$) =>
    action$.pipe(
        ofType(ActionTypes.FETCH_ADMIN_VIEW_DATA),
        switchMap((action: ActionByType<Actions, ActionTypes.FETCH_ADMIN_VIEW_DATA>) =>
            from(
                viewClient.getView(
                    action.payload.viewId,
                    action.payload.includeConfig,
                    action.payload.includeShares,
                    action.payload.includeForm,
                    action.payload.includeFilters
                )
            ).pipe(
                flatMap((response: AxiosResponse<ViewWithOwnerAndUserDetails>) => {
                    const view = response.data;
                    const actions: Actions[] = [Actions.storeAdminViewData(view)];

                    if (action.payload.includeConfig) {
                        actions.push(Actions.storeViewConfig(view.config));
                        delete view.config;
                    }

                    if (action.payload.includeShares) {
                        actions.push(Actions.storeShares(view.shares));
                        delete view.shares;
                    }

                    if (action.payload.includeForm) {
                        actions.push(Actions.storeForm(view.form));
                        delete view.form;
                    }

                    return actions;
                })
            )
        )
    );

export const updateAdminViewDataEpic: Epic<Actions | AppActions.Actions | HomeActions.Actions> = (action$, state$) =>
    action$.pipe(
        ofType(ActionTypes.UPDATE_ADMIN_VIEW_DATA),
        switchMap((action: ActionByType<Actions, ActionTypes.UPDATE_ADMIN_VIEW_DATA>) =>
            concat(
                // 1) Set stage to Action in Progress (shows spinner modal)
                of(AppActions.Actions.setAppStageActionInProgress(LanguageElements.SPINNER_SAVING_LABEL)),

                // 2) Update view and dispatch actions to store response
                from(viewClient.update(action.payload.viewId, action.payload.view)).pipe(
                    flatMap((response: AxiosResponse<ViewWithOwnerAndUserDetails>) => {
                        const view = response.data;
                        const actions: Actions[] = [Actions.storeAdminViewData(view)];

                        if (view.config) {
                            actions.push(Actions.storeViewConfig(view.config));
                            delete view.config;
                        }

                        if (view.form) {
                            actions.push(Actions.storeForm(view.form));
                            delete view.form;
                        }

                        return actions;
                    })
                ),

                // 3) Fetch view source meta data for report
                fetchViewSourceMetaDataForReport(action, state$),

                // 4) Fetch the Smartsheet users by emails
                of(Actions.fetchSmartsheetUsersByEmails()),

                // 5) Update name and description
                // Note, fetching all the data from DB is overkill, can simplify by adding new action to update single view directly
                of(HomeActions.Actions.fetchHomeData()),

                // 6) Reset stage or redirect - Concat ensures this observable happens after the others have completed
                of(
                    action.payload.redirectUrl
                        ? AppActions.Actions.setAppStageRedirect(action.payload.redirectUrl)
                        : AppActions.Actions.resetAppStage()
                )
            )
        )
    );

export const updateSharesEpic: Epic<Actions | AppActions.Actions> = (action$) =>
    action$.pipe(
        ofType(ActionTypes.UPDATE_SHARES),
        switchMap((action: ActionByType<Actions, ActionTypes.UPDATE_SHARES>) =>
            concat(
                of(AppActions.Actions.setAppStageActionInProgress(LanguageElements.SPINNER_SAVING_LABEL)),

                from(viewClient.updateShares(action.payload.viewId, action.payload.viewShares)).pipe(
                    flatMap((response: AxiosResponse<ViewShareResult>) => {
                        const actions: Actions[] = [Actions.storeShares(response.data.shares), Actions.storeShareErrors(response.data.errors)];
                        return actions;
                    })
                ),

                // Create the transfer ownership
                of(Actions.createOwnershipTransfer(action.payload.viewId, action.payload.email)),

                // Fetch the Smartsheet users by emails
                of(Actions.fetchSmartsheetUsersByEmails()),

                of(
                    action.payload.redirectUrl
                        ? AppActions.Actions.setAppStageRedirect(action.payload.redirectUrl)
                        : AppActions.Actions.resetAppStage()
                )
            )
        )
    );

export const fetchViewSourceMetaDataEpic: Epic<Actions | AppActions.Actions> = (action$) =>
    action$.pipe(
        ofType(ActionTypes.FETCH_VIEW_SOURCE_META_DATA),
        switchMap((action: ActionByType<Actions, ActionTypes.FETCH_VIEW_SOURCE_META_DATA>) =>
            from(viewClient.getSourceMetaData(action.payload)).pipe(
                map((response: AxiosResponse<ViewSourceMetaData>) => Actions.storeViewSourceMetaData(response.data))
            )
        )
    );

export const fetchSmartsheetGroupsEpic: Epic<Actions> = (action$) =>
    action$.pipe(
        ofType(ActionTypes.FETCH_SMARTSHEET_GROUPS),
        switchMap(() =>
            from(groupsClient.getOrgGroups()).pipe(map((response: AxiosResponse<Group[]>) => Actions.storeSmartsheetGroups(response.data)))
        )
    );

export const fetchSmartsheetUsersEpic: Epic<Actions> = (action$, state$) =>
    action$.pipe(
        ofType(ActionTypes.FETCH_SMARTSHEET_USERS),
        switchMap(() => {
            const storeState = state$.value;
            const isDisabledContactPickerOnDemand = isDisabledContactPickerOnDemandSelector(storeState);

            return from(usersClient.getUsers(isDisabledContactPickerOnDemand)).pipe(
                map((users: IPaginatedResult<SmartsheetUser>) => Actions.storeSmartsheetUsers(users))
            );
        })
    );

export const fetchSmartsheetAdminsEpic: Epic<Actions> = (action$) =>
    action$.pipe(
        ofType(ActionTypes.FETCH_SMARTSHEET_ADMINS),
        switchMap((action: ActionByType<Actions, ActionTypes.FETCH_SMARTSHEET_ADMINS>) =>
            from(viewSourceAdminClient.getViewSourceAdmins(action.payload)).pipe(
                map((response: AxiosResponse<SmartsheetUser[]>) => Actions.storeSmartsheetAdmins(response.data))
            )
        )
    );

export const createOwnershipTransferEpic: Epic<Actions | OwnershipTransferActions.Actions> = (action$) =>
    action$.pipe(
        ofType(ActionTypes.CREATE_OWNERSHIP_TRANSFER),
        switchMap((action: ActionByType<Actions, ActionTypes.CREATE_OWNERSHIP_TRANSFER>) => {
            if (!action.payload.email) {
                return EMPTY;
            }

            return from(ownershipTransferClient.create(action.payload.viewId, action.payload.email)).pipe(
                map((ownershipTransfer: IOwnershipTransfer) => Actions.storeOwnershipTransfer(ownershipTransfer)),
                catchError((error: AxiosError) => {
                    loggingClient.logError('Admin/Epic.ts', 'createOwnershipTransferEpic', error);

                    return of(OwnershipTransferActions.Actions.storeOwnershipTransferError());
                })
            );
        })
    );

const fetchViewSourceMetaDataForReport = (action: ActionByType<Actions, ActionTypes.UPDATE_ADMIN_VIEW_DATA>, state$: StateObservable<any>) => {
    const adminState = convertImmutableObjectToJS<any>(state$.value.admin)!;
    const viewFromState: ViewWithOwnerAndUserDetails = adminState.view;
    const viewFromAction = action.payload.view;
    const configFromState: ViewConfig = adminState.config;

    // Only dispatch the action to fetch view source meta data if the source is a report and the intake sheet ID prop changes
    if (
        !viewFromState ||
        viewFromState.viewSourceType !== ViewSourceType.REPORT ||
        !configFromState ||
        !viewFromAction.config ||
        configFromState.intakeSheetId === viewFromAction.config.intakeSheetId ||
        (configFromState.intakeSheetId == null && viewFromAction.config.intakeSheetId == null)
    ) {
        return EMPTY;
    }

    return forkEpic(fetchViewSourceMetaDataEpic, state$, Actions.fetchViewSourceMetaData(action.payload.viewId));
};

export const fetchSmartsheetUsersByEmailsEpic: Epic<Actions | AppActions.Actions> = (action$, state$) =>
    action$.pipe(
        ofType(ActionTypes.FETCH_SMARTSHEET_USERS_BY_EMAILS),
        switchMap(() => {
            // 1) Fetch the form, share and view source metadata from the Redux store
            const adminState = convertImmutableObjectToJS<any>(state$.value.admin)!;
            const formState: FormInterface = adminState.form;
            const sharesState: ViewShareBasic[] = adminState.shares;
            const viewSourceMetaDataStore: ViewSourceMetaData = adminState.viewSourceMetaData;
            const columnMap: Map<number, Column> = new Map();
            const emails: Set<string> = new Set();

            // 2) Get a list of email addresses by finding all contact form fields with a default value that is a valid email address
            if (formState && formState.fields) {
                viewSourceMetaDataStore.columns.forEach((column) => {
                    if (column.type !== ColumnType.CONTACT_LIST && column.type !== ColumnType.MULTI_CONTACT_LIST) {
                        return;
                    }
                    columnMap.set(column.id, column);
                });

                formState.fields!.forEach((field) => {
                    if (!columnMap.get(field.columnId)) {
                        return;
                    }

                    if (columnMap.get(field.columnId)!.type === ColumnType.CONTACT_LIST) {
                        if (isEmailValid(field.defaultValue)) {
                            emails.add(field.defaultValue.toLowerCase());
                        }
                    } else if (columnMap.get(field.columnId)!.type === ColumnType.MULTI_CONTACT_LIST) {
                        if (Array.isArray(field.defaultValue)) {
                            field.defaultValue.forEach((value) => {
                                if (isEmailValid(value.email)) {
                                    emails.add(value.email!.toLowerCase());
                                }
                            });
                        }
                    }
                });
            }

            // 3) Get another list of email addresses from the share data for users, admins, and the view owner
            if (sharesState) {
                sharesState.forEach((share) => {
                    if (!share.email) {
                        return;
                    }

                    if (
                        share.accessLevel !== AccessLevel.ADMIN &&
                        share.accessLevel !== AccessLevel.USER &&
                        share.accessLevel !== AccessLevel.OWNER
                    ) {
                        return;
                    }

                    emails.add(share.email!.toLowerCase());
                });
            }

            if (emails.size === 0) {
                return EMPTY;
            }

            // 4) Make a request DV API POST /internal/getusers with a list of email address and store the response user data in the Redux store
            return from(usersClient.getUsersByEmail(Array.from(emails))).pipe(
                map((users: IPaginatedResult<SmartsheetUser>) => Actions.storeSmartsheetUsersByEmails(users))
            );
        })
    );
