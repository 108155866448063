import { IDomPosition } from '../interfaces';

// Returns top and left coordinates needed to align a FIXED dropdown with the input field
export const getDropdownPosition = (id: string): IDomPosition => {
    const inputElement = document.getElementById(id);
    let top = 0;
    let left = 0;

    if (inputElement) {
        const rect = inputElement.getBoundingClientRect();

        top = rect.bottom;
        left = rect.left;
    }
    return {
        top,
        left,
    };
};
