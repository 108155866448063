import { FormFieldInterface } from '../../../common/interfaces';
import { ActionsUnion } from '../../../store';
import { createAction } from '../../../store/Utils/CreateAction';
import { CellImages } from './CellImage/CellImages';

export enum ActionTypes {
    // Reset all view props
    RESET_DETAILS_PANEL_STATE = 'RESET_DETAILS_PANEL_STATE',

    // Actions for detailsPanelShowModal prop
    SHOW_MODAL_DETAILS_PANEL = 'SHOW_MODAL_DETAILS_PANEL',
    CLOSE_MODAL_DETAILS_PANEL = 'CLOSE_MODAL_DETAILS_PANEL',

    // Actions for selectedRowId prop
    SELECT_ROW = 'SELECT_ROW',
    UNSELECT_ROW = 'UNSELECT_ROW',

    // Actions for rowUpserts
    UPDATE_VIEW_ROW = 'UPDATE_VIEW_ROW',
    INSERT_VIEW_ROW = 'INSERT_VIEW_ROW',
    UPSERT_VIEW_ROW_IN_PROGRESS = 'UPSERT_VIEW_ROW_IN_PROGRESS',
    UPSERT_VIEW_ROW_IMAGES = 'UPSERT_VIEW_ROW_IMAGES',
    UPSERT_VIEW_ROW_SUCCESS = 'UPSERT_VIEW_ROW_SUCCESS',
    UPSERT_VIEW_ROW_FAILURE = 'UPSERT_VIEW_ROW_FAILURE',
    DISMISS_ERRORS = 'DISMISS_ERRORS',
    DISMISS_ERRORS_FOR_ROW = 'DISMISS_ERRORS_FOR_ROW',
    UPSERT_VIEW_ROW_REMOVE_DONE = 'UPSERT_VIEW_ROW_REMOVE_DONE',

    // Actions for tracking modified state
    DETAILS_SET_DIRTY = 'DETAILS_SET_DIRTY',
}

export const Actions = {
    // Action to reset details panel state
    resetDetailsPanelState: () => createAction(ActionTypes.RESET_DETAILS_PANEL_STATE),

    // Actions for detailsPanelShowModal prop
    showModalDetailsPanel: () => createAction(ActionTypes.SHOW_MODAL_DETAILS_PANEL),
    closeModalDetailsPanel: () => createAction(ActionTypes.CLOSE_MODAL_DETAILS_PANEL),

    // Actions for selectedRowId prop
    selectRow: (selectedRowId: string) => createAction(ActionTypes.SELECT_ROW, selectedRowId),
    unselectRow: () => createAction(ActionTypes.UNSELECT_ROW),

    // Actions for rowUpsert
    updateViewRow: (params: {
        viewId: string;
        rowId: string;
        originalForm: FormFieldInterface[];
        submittedForm: FormFieldInterface[];
        cellImages?: CellImages;
    }) => createAction(ActionTypes.UPDATE_VIEW_ROW, params),

    insertViewRow: (params: { viewId: string; submittedForm: FormFieldInterface[]; cellImages?: CellImages }) =>
        createAction(ActionTypes.INSERT_VIEW_ROW, params),

    upsertViewRowInProgress: (params: {
        viewId: string;
        rowId: string;
        isNewSubmission: boolean;
        updatedForm?: FormFieldInterface[];
        trackingId: string;
        sheetId?: number;
        startTimeForUpsert: number;
    }) => createAction(ActionTypes.UPSERT_VIEW_ROW_IN_PROGRESS, params),

    upsertViewRowImages: (params: {
        viewId: string;
        rowId?: string;
        cellImages: CellImages;
        isNewSubmission: boolean;
        updatedForm?: FormFieldInterface[];
        trackingId: string;
        sheetId?: number;
        startTimeForUpsert: number;
    }) => createAction(ActionTypes.UPSERT_VIEW_ROW_IMAGES, params),

    upsertViewRowSuccess: (params: {
        viewId: string;
        rowId: string;
        isNewSubmission: boolean;
        trackingId: string;
        sheetId?: number;
        startTimeForSyncProcess: number;
    }) => createAction(ActionTypes.UPSERT_VIEW_ROW_SUCCESS, params),

    upsertViewRowFailure: (params: {
        viewId: string;
        rowId?: string;
        error: Error;
        trackingId: string;
        sheetId?: number;
        isNewSubmission: boolean;
    }) => createAction(ActionTypes.UPSERT_VIEW_ROW_FAILURE, params),

    dismissErrors: () => createAction(ActionTypes.DISMISS_ERRORS),
    dismissErrorsForRow: (rowId: string) => createAction(ActionTypes.DISMISS_ERRORS_FOR_ROW, rowId),

    upsertViewRowRemoveDone: (params: { viewId: string; rowId: string }) => createAction(ActionTypes.UPSERT_VIEW_ROW_REMOVE_DONE, params),

    setDetailsDirtyState: (isDirty: boolean) => createAction(ActionTypes.DETAILS_SET_DIRTY, isDirty),
};

export type Actions = ActionsUnion<typeof Actions>;
