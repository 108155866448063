import styled from '@emotion/styled';
import { Checkbox, sizes } from '@smartsheet/lodestar-core';
import { TransferOwnershipIcon, TrashIcon } from '@smartsheet/lodestar-icons';
import * as React from 'react';
import { Column as ColumnRV } from 'react-virtualized';
import { AutomationIds, AutomationTypes } from '../../common/enums/AutomationElements.enum';
import { CellRendererText } from '../../common/utils/CellRenderer';
import SingleContact from '../../components/SingleContact/SingleContact';
import { LanguageElementsProp } from '../../language-elements/withLanguageElementsHOC';
import AdminControlsActionButton from './AdminControlsActionButton';
import AdminControlsTableHeader from './AdminControlsTableHeader';
import { ColumnWidths } from './constants';

interface Props {
    selectedViews: Set<string>;
    onSelectView: (viewId: string) => void;
    onSingleDelete: (id: string) => void;
    onSingleTransferOwnership: (id: string) => void;
}

const AdminControlsTableColumns = ({
    selectedViews,
    languageElements,
    onSelectView,
    onSingleDelete,
    onSingleTransferOwnership,
}: Props & LanguageElementsProp): JSX.Element[] => [
    <ColumnRV
        key="checkbox"
        width={ColumnWidths[0]}
        dataKey="checkbox"
        className="outer-cell"
        disableSort
        headerRenderer={AdminControlsTableHeader({ languageElements })}
        cellRenderer={({ rowData }) => (
            <CheckboxContainer>
                <Checkbox checkedState={selectedViews.has(rowData.id) ? 'checked' : 'unchecked'} onClick={() => onSelectView(rowData.id)} />
            </CheckboxContainer>
        )}
    />,
    <ColumnRV
        key="viewName"
        width={ColumnWidths[1]}
        label={languageElements.ADMIN_CONTROLS_COLUMN_HEADERS[0]}
        dataKey="name"
        className="outer-cell"
        headerRenderer={AdminControlsTableHeader({ languageElements })}
        cellRenderer={({ rowData }) => CellRendererText(rowData.name)}
        flexGrow={2}
    />,
    <ColumnRV
        key="owner"
        width={ColumnWidths[2]}
        label={languageElements.ADMIN_CONTROLS_COLUMN_HEADERS[1]}
        dataKey="owner"
        className="outer-cell"
        headerRenderer={AdminControlsTableHeader({ languageElements })}
        cellRenderer={({ rowData }) => (
            <SingleContact
                contact={rowData.currentOwner}
                dataClientId={AutomationIds.CONTACT_WITH_ICON_AND_USER}
                dataClientType={AutomationTypes.ADMIN_CONTROLS_CELL}
                showNameAndEmail={false}
            />
        )}
        flexGrow={1}
    />,
    <ColumnRV
        key="description"
        width={ColumnWidths[3]}
        label={languageElements.ADMIN_CONTROLS_COLUMN_HEADERS[2]}
        dataKey="description"
        className="outer-cell"
        headerRenderer={AdminControlsTableHeader({ languageElements })}
        cellRenderer={({ rowData }) => CellRendererText(rowData.description)}
        flexGrow={2}
    />,
    <ColumnRV
        key="actions"
        width={ColumnWidths[4]}
        disableSort
        label={languageElements.ADMIN_CONTROLS_COLUMN_HEADERS[3]}
        dataKey="action"
        className="outer-cell"
        headerRenderer={AdminControlsTableHeader({ languageElements })}
        cellRenderer={({ rowData }) => (
            <AdminControlsActions>
                <AdminControlsActionButton
                    dataTestId={AutomationIds.ADMIN_CONTROLS_ROW_OWNERSHIP_TRANSFER_BUTTON}
                    icon={<TransferOwnershipIcon size="large" />}
                    flyoutText={languageElements.ADMIN_CONTROLS_TRANSFER_OWNERSHIP_BUTTON_TEXT}
                    onClick={() => onSingleTransferOwnership(rowData.id)}
                />
                <AdminControlsActionButton
                    dataTestId={AutomationIds.ADMIN_CONTROLS_ROW_DELETE_BUTTON}
                    icon={<TrashIcon size="medium" />}
                    flyoutText={languageElements.ADMIN_CONTROLS_DELETE_BUTTON_TEXT}
                    onClick={() => onSingleDelete(rowData.id)}
                />
            </AdminControlsActions>
        )}
    />,
];

const AdminControlsActions = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    column-gap: ${sizes.small}px;
    padding-left: ${sizes.xxSmall}px;
`;

const CheckboxContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: ${sizes.small}px;
`;

export default AdminControlsTableColumns;
