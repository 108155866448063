/** @jsx jsx */ import { jsx } from '@emotion/core';
import { AsyncStatus } from '../../../common/enums';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { CellMeasurerCache, TableCellProps } from 'react-virtualized';
import alert from '../../../assets/images/alert/icon-info-circle-red.svg';
import { AutomationIds } from '../../../common/enums/AutomationElements.enum';
import { CellMeasurerWrapper } from './CellMeasurerWrapper';
import styled from '@emotion/styled';
import { CSSTransition } from 'react-transition-group';
import { CheckIcon } from '@smartsheet/lodestar-icons';
import { useSelector } from 'react-redux';
import { selectOverallStatusForRow } from '../../../containers/View/Details/Selectors';
import { StoreState } from '../../../store';
import { colors } from '@smartsheet/lodestar-core';
import SyncingSpinner from '../../Syncing/SyncingSpinner';
import { SYNC_COMPLETE_ANIMATION_DURATION } from '../../../containers/View/Constants';

interface Props {
    tableCellProps: TableCellProps;
    wrapText: boolean;
    rowHeightCache: CellMeasurerCache | undefined;
}

const RowStatusSpacingWrapper = styled.div`
    padding-left: 10px;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
`;

const ANIMATION_DELAY = 250;

const FadingStyles = {
    '&.row-check-enter': { opacity: 0 },
    '&.row-check-enter-active': {
        opacity: 1,
        transition: `opacity ${ANIMATION_DELAY}ms`,
    },
    '&.row-check-exit': { opacity: 1 },
    '&.row-check-exit-active': { opacity: 0, transition: `opacity ${ANIMATION_DELAY}ms` },
};

export const RowStatus: React.FC<Props> = (props) => {
    const status = useSelector<StoreState>((state) => selectOverallStatusForRow(state, props.tableCellProps.rowData.id));
    const [showDone, setShowDone] = useState(false);
    useEffect(() => {
        if (status === AsyncStatus.DONE) {
            setShowDone(true);
        }
    }, [status]);

    const nodeRef = React.useRef(null);
    const doneContent = (
        <CSSTransition
            in={showDone}
            nodeRef={nodeRef}
            classNames="row-check"
            timeout={ANIMATION_DELAY}
            mountOnEnter
            unmountOnExit
            onEnter={() => {
                setTimeout(() => {
                    setShowDone(false);
                }, SYNC_COMPLETE_ANIMATION_DURATION);
            }}
        >
            <CheckIcon ref={nodeRef} css={FadingStyles} color={colors.visGreenDark10} data-client-id={AutomationIds.ROW_STATUS_DONE} />
        </CSSTransition>
    );

    const inProgressContent = <SyncingSpinner data-client-id={AutomationIds.ROW_STATUS_IN_PROGRESS} />;

    const errorContent = (
        <div className="row-status" data-client-id={AutomationIds.ROW_STATUS_ERROR}>
            <img src={alert} />
        </div>
    );

    // Note that the wrapping div here resolves a problem where all rows collapse into one if a user resizes a column on wrapped cells
    const innerContent = (
        <div className="cell-content" data-client-id={AutomationIds.GRID_ROW_STATUS_CELL}>
            <RowStatusSpacingWrapper>
                {status === AsyncStatus.IN_PROGRESS && inProgressContent}
                {status === AsyncStatus.ERROR && errorContent}
                {doneContent}
            </RowStatusSpacingWrapper>
        </div>
    );

    return props.wrapText && props.rowHeightCache ? (
        <CellMeasurerWrapper tableCellProps={props.tableCellProps} rowHeightCache={props.rowHeightCache}>
            {innerContent}
        </CellMeasurerWrapper>
    ) : (
        innerContent
    );
};
