import { Group } from '../common/interfaces';
import { AxiosPromise } from 'axios';
import axiosInstance from './Axios.instance';

class GroupsClient {
    public static getInstance(): GroupsClient {
        if (!GroupsClient.instance) {
            GroupsClient.instance = new GroupsClient();
        }
        return GroupsClient.instance;
    }

    private static instance: GroupsClient;

    private static readonly url = '/groups';

    private constructor() {}

    public getOrgGroups(): AxiosPromise<Group[]> {
        return axiosInstance.get(GroupsClient.url);
    }
}
const groupsClient = GroupsClient.getInstance();
export default groupsClient;
