import { AxiosPromise } from 'axios';
import axiosInstance from './Axios.instance';

class ViewSourceClient {
    public static getInstance(): ViewSourceClient {
        const instanceExists = ViewSourceClient.instance !== undefined;
        if (!instanceExists) {
            ViewSourceClient.instance = new ViewSourceClient();
        }
        return ViewSourceClient.instance;
    }

    private static instance: ViewSourceClient;
    private static readonly viewSourceUrl: string = '/viewsources';

    private constructor() {}

    /**
     * Gets name given sheet or report id
     *
     * @param viewSourceId
     * @param isReport
     */
    public getViewSourceName(viewSourceId: number, isReport: boolean): AxiosPromise<string> {
        const isReportQueryParameter = `?isReport=${String(isReport)}`;
        return axiosInstance.get(`${ViewSourceClient.viewSourceUrl}/${viewSourceId}${isReportQueryParameter}`);
    }
}

export const viewSourceClient = ViewSourceClient.getInstance();
