import styled from '@emotion/styled';
import { sizes } from '@smartsheet/lodestar-core';
import * as React from 'react';
import { default as Skeleton } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { CellMeasurerCache, TableCellProps } from 'react-virtualized';
import { CellMeasurerWrapper } from './CellMeasurerWrapper';

interface Props {
    tableCellProps: TableCellProps;
    wrapText: boolean;
    rowHeightCache: CellMeasurerCache | undefined;
}

const CellText: React.FC<Props> = (props) => {
    // Note that the wrapping div here resolves a problem where all rows collapse into one if a user resizes a column on wrapped cells
    let innerContent: JSX.Element;
    if (props.tableCellProps.cellData !== undefined) {
        innerContent = <div className="cell-content">{props.tableCellProps.cellData.innerCellComponent}</div>;
    } else {
        innerContent = (
            <SkeletonWrapper wrapText={props.wrapText}>
                <Skeleton data-testid="skeleton" width="90%" />
            </SkeletonWrapper>
        );
    }
    return props.wrapText && props.rowHeightCache ? (
        <CellMeasurerWrapper tableCellProps={props.tableCellProps} rowHeightCache={props.rowHeightCache}>
            {innerContent}
        </CellMeasurerWrapper>
    ) : (
        innerContent
    );
};

const SkeletonWrapper = styled.div<{ wrapText: boolean }>`
    display: flex;
    flex-direction: column;
    padding: ${sizes.small}px;
    white-space: ${(props) => (props.wrapText ? 'normal' : 'nowrap')};
`;

export default CellText;
