import * as React from 'react';
import { CellRenderer } from '../CellRenderer';

interface Props {
    content: string | undefined;
    className?: string;
    hideTooltip?: boolean;
}
export const CellRendererText: React.SFC<Props> = props => {
    const content = props.content || '';
    return (
        <CellRenderer tooltip={!props.hideTooltip && content} className={props.className}>
            {content}
        </CellRenderer>
    );
};
