import * as React from 'react';
import '../../../assets/styles/buttons.css';
import './GenericModalContent.css';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';

export interface GenericModalContentProps {
    title: string;
    message: string;
    primaryButtonText?: string;
    secondaryButtonText?: string;
    onClickPrimaryButton: () => void;
    onClickSecondaryButton: () => void;
    hidePrimaryButton?: boolean;
    hideSecondaryButton?: boolean;
    icon?: any;
}

const ControlId = {
    MAIN: 'gmc-1',
    PRIMARY: 'gmc-2',
    SECONDARY: 'gmc-3',
    CLOSE: 'gmc-4',
};

const GenericModalContent: React.FC<GenericModalContentProps> = (props) => {
    const languageElements = useLanguageElements();
    return (
        <div data-client-id={ControlId.MAIN} className="generic-modal-container">
            <div className="generic-modal-left">
                {/* The alt text of this icon inappropriately assumes it's an alert icon. */}
                {props.icon && <img src={props.icon} alt="alert" />}
            </div>
            <div className="generic-modal-right">
                <p className="generic-modal-title">{props.title}</p>
                <p className="generic-modal-message">{props.message}</p>
                {props.children}
                {!props.hidePrimaryButton && (
                    <button className="btn btn-primary state-button" onClick={() => props.onClickPrimaryButton()} data-client-id={ControlId.PRIMARY}>
                        {props.primaryButtonText || languageElements.MODAL_PRIMARY_BUTTON_DEFAULT_TEXT}
                    </button>
                )}
                {!props.hideSecondaryButton && (
                    <button
                        className="btn state-button btn-secondary"
                        onClick={() => props.onClickSecondaryButton()}
                        data-client-id={ControlId.SECONDARY}
                    >
                        {props.secondaryButtonText || languageElements.MODAL_SECONDARY_BUTTON_DEFAULT_TEXT}
                    </button>
                )}
            </div>
        </div>
    );
};

export default GenericModalContent;
