import * as React from 'react';
import { OptionComponentProps } from 'react-select';
import { MultiSelectItem } from './MultiSelect.interface';
import './MultiSelectOption.css';

class MultiSelectOption extends React.PureComponent<OptionComponentProps<MultiSelectItem>> {
    public render(): React.ReactNode {
        const controlType = `${this.props.option.controlIdPrefix}Item`;
        const controlId1 = `${controlType}-1`;
        const controlId2 = `${controlType}-2`;
        return (
            <div
                className="multi-select-option"
                data-client-type={controlType}
                onMouseDown={this.handleMouseDown}
                onMouseEnter={this.handleMouseEnter}
                onMouseMove={this.handleMouseMove}
            >
                <span className="heading" data-client-id={controlId1}>
                    {this.props.option.label ?
                        this.props.option.label : this.props.option.value
                    }
                </span>
                {this.props.option.subLabel &&
                    <span className="subheading" data-client-id={controlId2}>{this.props.option.subLabel}</span>
                }
            </div>
        );
    }

    private handleMouseDown = (event: React.FormEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (this.props.onSelect) {
            this.props.onSelect(this.props.option, event);
        }
    }

    private handleMouseEnter = (event: React.FormEvent<HTMLDivElement>) => {
        if (this.props.onFocus) {
            this.props.onFocus(this.props.option, event);
        }
    }

    private handleMouseMove = (event: React.FormEvent<HTMLDivElement>) => {
        if (this.props.isFocused) {
            return;
        }
        if (this.props.onFocus) {
            this.props.onFocus(this.props.option, event);
        }
    }
}

export default MultiSelectOption;
