import classnames from 'classnames';
import * as React from 'react';
import { AutomationTypes } from '../../common/enums/AutomationElements.enum';
import Tooltip from '../TooltipLegacy';
import { Offset } from '../TooltipLegacy';

interface Props {
    className?: string;
    tooltip?: React.ReactNode;
    tooltipOffset?: Offset;
}

export const CellRenderer: React.SFC<Props> = (props) => {
    const className = classnames('cell-content', props.className);
    const content = typeof props.children === 'string' ? <p title="">{props.children}</p> : props.children;
    return (
        <div className={className} data-client-type={AutomationTypes.OWNERSHIP_TRANSFER_CELL}>
            <div className="data-cell" title="">
                {props.tooltip ? (
                    <Tooltip content={props.tooltip} offset={props.tooltipOffset}>
                        {content}
                    </Tooltip>
                ) : (
                    content
                )}
            </div>
        </div>
    );
};
