import styled from '@emotion/styled';
import * as React from 'react';
import rowRemovedIcon from '../../../assets/images/row-not-in-view.svg';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';
import { sizes } from '@smartsheet/lodestar-core';
import { AutomationIds } from '../../../common/enums/AutomationElements.enum';

const ViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
`;
const CenterText = styled.div`
    max-width: 290px;
    text-align: center;
`;
const Icon = styled.img`
    max-width: 180px;
    margin-bottom: ${sizes.xSmall}px;
`;
export const RowRemovedFromView = () => {
    const languageElements = useLanguageElements();
    return (
        <ViewContainer data-client-id={AutomationIds.DETAILS_TAB_ROW_REMOVED} data-testid={AutomationIds.DETAILS_TAB_ROW_REMOVED}>
            <Icon src={rowRemovedIcon} />
            <h2>{languageElements.DETAIL_PANEL_ROW_NOT_IN_VIEW_TITLE}</h2>
            <CenterText>{languageElements.DETAIL_PANEL_ROW_NOT_IN_VIEW_MESSAGE}</CenterText>
        </ViewContainer>
    );
};
