import { OwnershipTransferStatus } from '../../common/enums';
import { HomeView, IOwnershipTransfer } from '../../common/interfaces';

export class OwnershipTransferMapper {
    public static fromHomeView(view: HomeView): IOwnershipTransfer {
        const name = view.ownerDetails && 'name' in view.ownerDetails ? view.ownerDetails.name : undefined;
        const profileImage = view.ownerDetails && 'profileImage' in view.ownerDetails ? view.ownerDetails.profileImage : undefined;
        return {
            id: '',
            status: OwnershipTransferStatus.NONE,
            created: new Date(2000, 1, 1),
            modified: new Date(2000, 1, 1),
            view: {
                id: view.id,
                name: view.name,
                description: view.description,
                viewSourceId: view.viewSourceId,
                viewSourceType: view.viewSourceType,
                smartsheetAccountId: view.smartsheetAccountId,
                config: view.config,
                form: view.form,
                shares: view.shares,
                filters: view.filters,
                accessLevel: view.accessLevel,
                accessType: view.accessType,
                sharedWithGroups: view.sharedWithGroups,
            },
            currentOwner: {
                email: view.ownerDetails ? view.ownerDetails.email : '',
                name,
                profileImage,
            },
            newOwner: {
                email: '',
            },
        };
    }
}
