import { datadogRum, DefaultPrivacyLevel, RumInitConfiguration } from '@datadog/browser-rum';
import { isGovSite } from '../utils/IsGovSite';

const DATADOG_RUM_APPLICATION_ID = 'f328baa9-a33d-4e9d-8abe-957221992da0';
const DATADOG_RUM_CLIENT_TOKEN = 'pub115ba008ffe97986a4069e704d8e1881';
const DATADOG_SITE = 'datadoghq.com';
const DATADOG_SERVICE_NAME = 'svc-dynamic-view-web-app';

export const initializeUserAnalytics = (): void => {
    // disable for all gov domains
    if (isGovSite()) {
        return;
    }

    const datadogRumConfiguration: RumInitConfiguration = {
        applicationId: DATADOG_RUM_APPLICATION_ID,
        clientToken: DATADOG_RUM_CLIENT_TOKEN,
        site: DATADOG_SITE,
        service: DATADOG_SERVICE_NAME,
        version: process.env.FRONTEND_VERSION_TAG,
        env: process.env.SMARTSHEET_ENV,
        traceSampleRate: 100,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 0,
        startSessionReplayRecordingManually: true,
        defaultPrivacyLevel: DefaultPrivacyLevel.MASK,
        // Explicitly disable automatic action tracking as it transmits Customer Information (e.g. cell content) to DataDog
        trackUserInteractions: false,
        trackResources: true,
        trackLongTasks: true,
        allowedTracingUrls: [
            // Staging
            'https://dynamicview.test.smartsheet.com',
            'https://app.test.smartsheet.com/dynamicview',

            // Production
            'https://dynamicview.smartsheet.com',
            'https://app.smartsheet.com/dynamicview',
            'https://app.smartsheet.eu/dynamicview',
        ],
        telemetrySampleRate: 100,
        useSecureSessionCookie: true,
        usePartitionedCrossSiteSessionCookie: true,
    };
    datadogRum.init(datadogRumConfiguration);
};
