import { MAIN_HEADER_HEIGHT } from '../../../common/constants';
import { HomeView } from '../../../common/interfaces';
import * as React from 'react';
import { AutomationTypes } from '../../../common/enums/AutomationElements.enum';
import { getUrlBarHeight } from '../../../common/utils';
import EmptyStateLeftRail from '../EmptyStateLeftRail/EmptyStateLeftRail';
import RecentsListItem, { InteractionStatus } from './RecentsListItem';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';

const MAX_RECENTS = 30;

export interface RecentsListProps {
    activeViewId?: string;
    unpinnedViews: HomeView[];
    pinnedViews: HomeView[];
}

export const RecentsList: React.SFC<RecentsListProps> = (props) => {
    const { activeViewId, unpinnedViews, pinnedViews } = props;
    const languageElements = useLanguageElements();

    if ((!unpinnedViews || unpinnedViews.length < 1) && (!pinnedViews || pinnedViews.length < 1)) {
        return <EmptyStateLeftRail message={languageElements.NO_RECENTS_TO_DISPLAY} classNames="no-recents" />;
    }

    // Sorts views in reverse chronological order by lastAccessed. Puts views with undefined lastAccessed at end
    const compareLastAccessed = (a: HomeView, b: HomeView) => {
        if (!b.lastAccessed) {
            return -1;
        }
        if (!a.lastAccessed) {
            return 1;
        }

        const valA = a.lastAccessed.valueOf();
        const valB = b.lastAccessed.valueOf();
        if (valA === valB) {
            return 0;
        }
        if (valA > valB) {
            return -1;
        }
        return 1;
    };

    const getRecentViewItem = (isPinned: boolean) => {
        return function RecentViewItem(item: HomeView) {
            return (
                <RecentsListItem
                    key={item.id}
                    interactionStatus={activeViewId === item.id ? InteractionStatus.ACTIVE : InteractionStatus.NORMAL}
                    item={item}
                    isPinned={isPinned}
                />
            );
        };
    };

    const unpinnedViewsList = unpinnedViews.sort(compareLastAccessed).slice(0, MAX_RECENTS).map(getRecentViewItem(false));

    const pinnedViewsList = pinnedViews.sort(compareLastAccessed).map(getRecentViewItem(true));

    const showDivider = pinnedViews.length > 0 && unpinnedViews.length > 0;

    // In-line styling used for ul max-height to solve mobile display issues related to vh
    return (
        <div className="left-nav-list">
            {pinnedViews.length > 0 && (
                <ul
                    className="recent-items pinned"
                    data-client-type={AutomationTypes.RECENT_PINNED_LIST}
                    style={{ maxHeight: `calc(100vh - ${MAIN_HEADER_HEIGHT + getUrlBarHeight()}px)` }}
                >
                    {pinnedViewsList}
                </ul>
            )}
            {showDivider && <hr />}
            {unpinnedViews.length > 0 && (
                <ul className="recent-items unpinned" data-client-type={AutomationTypes.RECENT_UNPINNED_LIST}>
                    {unpinnedViewsList}
                </ul>
            )}
        </div>
    );
};

export default RecentsList;
