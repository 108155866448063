import * as React from 'react';
import saveAsNewIcon from '../../assets/images/icons/copy.svg';
import trashCanIcon from '../../assets/images/symbolIcons/BlueTrashCan.svg';
import { AutomationIds } from '../../common/enums/AutomationElements.enum';
import { LanguageElementsProp, withLanguageElementsHOC } from '../../language-elements/withLanguageElementsHOC';
import { Button } from '../Buttons';
import ModalWrapper from '../Modal';
import GenericModalContent from '../Modal/Content/GenericModalContent';
import { WithDataClientProps } from '../hoc/WithDataClient';
import './AdminOptionMenu.css';

interface Props extends WithDataClientProps {
    classname: string;
    deleteView: () => Promise<void>;
    saveAsNew: (e: React.MouseEvent) => Promise<void>;
}

interface State {
    showDeleteModal: boolean;
}

// TODO: Make into a more generic component. Possibly use this component for the AccountMenu as well.
class AdminOptionMenu extends React.Component<Props & LanguageElementsProp> {
    public state: State = {
        showDeleteModal: false,
    };

    public toggleDeleteModal = () => {
        const newModalDisplayValue = !this.state.showDeleteModal;
        this.setState({
            showDeleteModal: newModalDisplayValue,
        });
    };

    public deleteView = () => {
        this.setState({
            showDeleteModal: false,
        });
        this.props.deleteView();
    };

    public render(): React.ReactNode {
        return (
            <React.Fragment>
                <div className={this.props.classname}>
                    <Button
                        className={`btn`}
                        icon={saveAsNewIcon}
                        imageAltText="new view item"
                        onClick={this.props.saveAsNew}
                        text={this.props.languageElements.SAVE_AS_NEW_TEXT}
                        dataClientId={AutomationIds.ADMIN_OPTION_MENU_SAVE_AS_NEW}
                    />
                    <Button
                        className={`btn`}
                        icon={trashCanIcon}
                        imageAltText="trash can"
                        onClick={this.toggleDeleteModal}
                        text={this.props.languageElements.DELETE_VIEW_TEXT}
                        dataClientId={AutomationIds.ADMIN_OPTION_MENU_DELETE_VIEW}
                    />
                </div>
                {this.state.showDeleteModal && (
                    <ModalWrapper
                        isModalOpen={this.state.showDeleteModal}
                        hideCloseButton={true}
                        classes="deleteModal"
                        onClose={() =>
                            this.setState({
                                showDeleteModal: false,
                            })
                        }
                    >
                        <GenericModalContent
                            title=""
                            message={this.props.languageElements.MODAL_DELETE_VIEW_TEXT}
                            onClickPrimaryButton={this.deleteView}
                            onClickSecondaryButton={() =>
                                this.setState({
                                    showDeleteModal: false,
                                })
                            }
                            primaryButtonText={this.props.languageElements.MODAL_DELETE_BUTTON_TEXT}
                            secondaryButtonText={this.props.languageElements.MODAL_CANCEL_BUTTON_DEFAULT_TEXT}
                        />
                    </ModalWrapper>
                )}
            </React.Fragment>
        );
    }
}

export default withLanguageElementsHOC(AdminOptionMenu);
