import styled from '@emotion/styled';
import { Avatar } from '@smartsheet/lodestar-core';
import * as React from 'react';
import { SuggestionDataItem } from 'react-mentions';
import { ContactDto } from '../../../../common/interfaces/ContactDto.interface';
import { getInitials } from '../Conversation/ConversationUtils';
import './AtMentions.css';

interface RenderedSuggestionProps {
    suggestion: SuggestionDataItem;
    contact: ContactDto | undefined;
}

const RenderedSuggestion: React.FC<RenderedSuggestionProps> = ({ suggestion, contact }) => {
    const firstName = contact?.name?.split(' ')[0] ?? '';
    const lastName = contact?.name?.split(' ')[1] ?? '';

    return (
        <ProfileListItem>
            <ProfilePicture>
                <Avatar size="small" src={contact?.profileImageUrl} initials={getInitials(firstName, lastName)} email={contact?.email} />
            </ProfilePicture>
            <ProfileText>
                <ProfilePrimaryText>{suggestion.display === '' ? suggestion.id : suggestion.display}</ProfilePrimaryText>
                {suggestion.display && <ProfileSecondaryText>{suggestion.id}</ProfileSecondaryText>}
            </ProfileText>
        </ProfileListItem>
    );
};

export default RenderedSuggestion;

const ProfileListItem = styled.div`
    display: flex;
    align-items: flex-start;
`;

const ProfilePicture = styled.div`
    display: inline-block;
    width: 30px;
    vertical-align: top;
    margin-right: 10px;
`;

const ProfileText = styled.div`
    display: inline-block;
    margin-top: 2px;
    width: 250px;
`;

const ProfilePrimaryText = styled.div`
    margin-right: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const ProfileSecondaryText = styled.div`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;
