// DEPRECATED component to be changed or removed
import { SelectV2 } from '@smartsheet/lodestar-core';
import { SupportedLocales } from '@smartsheet/lodestar-core/dist/esm/i18n/constants';
import * as React from 'react';
import { connect } from 'react-redux';
import { Option, OptionRendererHandler, Options, ValueRendererHandler } from 'react-select';
import { createStructuredSelector } from 'reselect';
import { userLocaleSelector } from '../../containers/Auth/Selectors';
import { StoreState } from '../../store';
import { WithDataClientProps } from '../hoc/WithDataClient';
import './Select.css';
import styled from '@emotion/styled';
import { PicklistItem } from '../../common/interfaces';

interface StateProps {
    userLocale?: string;
}

interface Props<TValue = string> extends WithDataClientProps {
    id?: string;
    selectedOption?: TValue;
    placeholder?: string;
    options: Options<TValue>;
    onSelect: (option: Option<TValue>) => void;
    tabIndex?: number;
    disabled?: boolean;
    valueRenderer?: ValueRendererHandler<TValue>;
    optionRenderer?: OptionRendererHandler<TValue>;
    isValid?: boolean;
}

type SelectComponentProps = Props & StateProps;

function getSupportedLocale(userLocale: string): SupportedLocales {
    const allLocales = ['en', 'es', 'de', 'fr', 'it', 'ja', 'pt', 'ru'];
    const userLocalePrefix = userLocale.slice(0, 2).toLocaleLowerCase();
    const foundLocale: SupportedLocales | undefined = allLocales.find((el) => el === userLocalePrefix) as SupportedLocales;

    return foundLocale ? foundLocale : 'en';
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const Select = (props: SelectComponentProps) => {
    const selectedOption = props.options.find((o) => o.value === props.selectedOption) || null;
    const validationState = props.isValid ? undefined : 'error';

    const handleGetOptionKey = (option: PicklistItem) => {
        return option.label || '';
    };

    let userLocale: SupportedLocales = 'en';

    if (typeof props.userLocale === 'string') {
        userLocale = getSupportedLocale(props.userLocale);
    }

    return (
        <StyledLodestarSelect
            id={props.id}
            locale={userLocale}
            options={props.options}
            selectedOption={selectedOption}
            getOptionKey={handleGetOptionKey}
            onChange={props.onSelect}
            isClearable={false}
            isSearchable
            isReadonly={props.disabled}
            clientId={props.dataClientId}
            aria-label={props.placeholder}
            validationState={validationState}
            highlightFirstOptionAutomatically
        />
    );
};

Select.defaultProps = {
    isValid: true,
};

const mapState = () =>
    createStructuredSelector<StoreState, StateProps>({
        userLocale: userLocaleSelector,
    });

export default connect<StateProps, null, Props>(mapState)(Select);

const StyledLodestarSelect = styled(SelectV2)`
    text-align: center;
    font-size: 13px;
`;
