import styled from '@emotion/styled';
import { colors, sizes } from '@smartsheet/lodestar-core';
import { AccessLevel } from '../../../common/enums';
import { ViewShareBasic } from '../../../common/interfaces';
import * as React from 'react';
import { useMemo, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import usersClient from '../../../http-clients/Users.client';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';
import { isUserEligibleOrInTrial, isUserLicensedSelector } from '../../Auth/Selectors';
import { makeSelectShares } from '../Selectors';

const areViewShareBasicObjectsEqual = (a: ViewShareBasic, b: ViewShareBasic) =>
    a.grantedToGroupId === b.grantedToGroupId && a.grantedToDomain === b.grantedToDomain && a.email === b.email && a.accessLevel === b.accessLevel;

const isNotViewOwner = (share: ViewShareBasic) => share.accessLevel === AccessLevel.ADMIN || share.accessLevel === AccessLevel.USER;

const getViewShareBasicValue = (share: ViewShareBasic) => share.email ?? share.grantedToGroupId ?? share.grantedToDomain;

export default function EvaluationSharingBanner() {
    const languageElements = useLanguageElements();
    const isUserLicensed = useSelector(isUserLicensedSelector);
    const shares = useSelector(makeSelectShares());
    const refExistingShares = useRef<ViewShareBasic[]>(shares ?? []);
    const [sharesAdded, setSharesAdded] = useState(false);
    const [adminEmail, setAdminEmail] = useState<string>();
    const isNotEligible = !useSelector(isUserEligibleOrInTrial);

    // Deep compare existing shares (refExistingShares.current) with shares in redux store and return if they are different
    const hasSharesChanged = useMemo(() => {
        if (isUserLicensed || isNotEligible) {
            return false;
        }

        const userShares = shares ?? [];
        const existingShares = refExistingShares.current;

        if (existingShares.length !== userShares.length) {
            return true;
        }

        for (let i = 0; i < existingShares.length; i++) {
            if (!areViewShareBasicObjectsEqual(existingShares[i], userShares[i])) {
                return true;
            }
        }

        return false;
    }, [isNotEligible, isUserLicensed, shares]);

    // If shares in the redux store have changed, then check if new shares where added, in which case the evaluation banner will show
    useEffect(() => {
        if (!hasSharesChanged) {
            return;
        }

        const currentNonOwnerShares = refExistingShares.current.filter(isNotViewOwner).map(getViewShareBasicValue);
        const currentNonOwnerSharesSet = new Set(currentNonOwnerShares);
        const userShares = shares ?? [];
        const sharesAddedTmp = userShares.some((share) => isNotViewOwner(share) && !currentNonOwnerSharesSet.has(getViewShareBasicValue(share)));

        setSharesAdded(sharesAddedTmp);
        refExistingShares.current = userShares;
    }, [hasSharesChanged, shares]);

    // Fetch sys-admin email for eligible user
    useEffect(() => {
        if (isUserLicensed || isNotEligible) {
            return;
        }
        usersClient
            .getAdminEmail()
            .then((email) => setAdminEmail(email))
            .catch(() => setAdminEmail(undefined));
    }, [isUserLicensed, isNotEligible]);

    if (isUserLicensed || isNotEligible || !sharesAdded) {
        return null;
    }

    return (
        <Banner>
            <ContentContainer>
                <StyledH5>{languageElements.EVALUATION_SHARING_BANNER_TITLE}</StyledH5>
                <StyledUl>
                    <li>{languageElements.EVALUATION_SHARING_BANNER_BULLET_SYS_ADMIN_NOTIFIED}</li>
                    <li>
                        {adminEmail
                            ? languageElements.EVALUATION_SHARING_BANNER_BULLET_CONTACT_SYS_ADMIN_EMAIL_ADDRESS(adminEmail)
                            : languageElements.EVALUATION_SHARING_BANNER_BULLET_CONTACT_SYS_ADMIN}
                    </li>
                </StyledUl>
            </ContentContainer>
        </Banner>
    );
}

const Banner = styled.div`
    padding: ${sizes.large - sizes.xxSmall}px ${sizes.large - sizes.xxSmall}px ${sizes.small}px ${sizes.large - sizes.xxSmall}px;
    margin-bottom: ${sizes.small}px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${colors.visGreenLight30};
    color: ${colors.neutralDark40};
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: left;
`;

const StyledH5 = styled.h5`
    margin: 0;
    font-size: 15px;
    font-weight: 600;
`;

const StyledUl = styled.ul`
    margin-top: ${sizes.small}px;
    padding-left: ${sizes.large}px;
    font-size: 13px;
    font-weight: 400;
`;
