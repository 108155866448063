import * as React from 'react';
import { AutomationIds } from '../../../common/enums/AutomationElements.enum';
import { WithDataClientProps } from '../../../components/hoc/WithDataClient';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';
import SectionTitle from '../SectionTitle';

export interface BasicDownloadOptionProps extends WithDataClientProps {
    onDownloadOptionChange: () => void;
    userCanDownload: boolean;
    className: string;
    radioName: string;
}

export const BasicDownloadOption: React.SFC<BasicDownloadOptionProps> = props => {
    const languageElements = useLanguageElements();
    return (
        <div className={props.className} data-client-id={props.dataClientType}>
            <SectionTitle
                title={languageElements.DOWNLOAD_OPTION_TITLE}
                subtitle={languageElements.DOWNLOAD_OPTION_SUBTITLE}
            />
            <div className="control-group">
                <label
                    className="control control--radio"
                    data-client-id={AutomationIds.RADIO_NO}
                >
                    {languageElements.RADIO_DO_NOT_ALLOW}
                    <input
                        type="radio"
                        name={props.radioName}
                        checked={!props.userCanDownload}
                        onChange={props.onDownloadOptionChange}
                    />
                    <div className="control__indicator"/>
                </label>
                <label
                    className="control control--radio"
                    data-client-id={AutomationIds.RADIO_YES}
                >
                    {languageElements.RADIO_ALLOW}
                    <input
                        type="radio"
                        name={props.radioName}
                        checked={props.userCanDownload}
                        onChange={props.onDownloadOptionChange}
                    />
                    <div className="control__indicator"/>
                </label>
            </div>
        </div>
    );
}