const isEmailValid = (email?: string): boolean => {
    if (!email) {
        return false;
    }

    // TODO: Used concatenation instead of string literal because string literal with '\' for line continuation doesn't work with req'd indents
    const regexString: string = '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))' +
        '@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';

    const regex: RegExp = new RegExp(regexString);

    return regex.test(email);
};

export { isEmailValid };
