export const domainBlocklist = new Set([
    '000gmail.com',
    '002gmail.com',
    '007gmail.com',
    '007yahoo.com',
    '00gmail.com',
    '0103yahoo.com',
    '012.net.il',
    '0120.net.il',
    '013.net.il',
    '014.net.il',
    '015.net.il',
    '017.net.il',
    '01gmail.com',
    '02.gmail.com',
    '02gmail.com',
    '034gmail.com',
    '03gmail.com',
    '042gmail.com',
    '04gmail.com',
    '04yahoo.com',
    '04yahoo.com.br',
    '054gmail.com',
    '05gmail.com',
    '0605gmail.com',
    '06gmail.com',
    '0733gmail.com',
    '075gmail.com',
    '07gmail.com',
    '07yahoo.com',
    '081203gmail.com',
    '08gmail.com',
    '098765gmail.com',
    '0gmail.com',
    '1010gmail.com',
    '1013gmail.com',
    '101gmail.com',
    '104.yahoo.con',
    '1092gmail.com',
    '10gen.com',
    '10gmail.com',
    '11gmail.com',
    '11yahoo.com',
    '12.net.il',
    '120100gmail.com',
    '123.com',
    '123.gmail.com',
    '12345gmail.com',
    '1234gmail.com',
    '1234yahoo.com',
    '12377gmail.com',
    '123comcast.net',
    '123gmail.com',
    '123yahoo.com',
    '1241gmail.com',
    '126.com',
    '127.gmail.com',
    '129gmail.com',
    '12gmail.com',
    '12yahoo.com.mx',
    '13091985gmail.com',
    '1311.gmail.com',
    '139.com',
    '13gmail.com',
    '13yahoo.com',
    '140gmail.com',
    '1457.gmail.com.br',
    '147gmail.com',
    '1484aolgmail.com',
    '14gmail.com',
    '156gmail.com',
    '15gmail.com',
    '15yahoo.com.mx',
    '1607gmail.com',
    '163.com',
    '164gmail.com',
    '16gmail.com',
    '1708yahoo.com',
    '173gmail.com',
    '17gmail.com',
    '17yahoo.com',
    '189.gmail.com',
    '189gmail.com',
    '19458gmail.com',
    '1970gmail.com',
    '1971gmail.com',
    '1981gmail.com',
    '1984.yahoo.com',
    '1987.yahoo.com',
    '1988yahoo.com',
    '1990gmail.com',
    '1992gmail.com',
    '1994gmail.com',
    '1997gmail.com',
    '1999gmail.com',
    '19gmail.com',
    '19yahoo.com',
    '1gmail.com',
    '1yahoo.co.uk',
    '1yahoo.com',
    '1yahoo.com.hk',
    '2000gmail.com',
    '2001gmail.com',
    '2001yahoo.co.uk',
    '2008gmail.com',
    '2009yahoo.com',
    '200gmail.com',
    '2011.gmail.com',
    '2012gmail.com',
    '2013.gmail.com',
    '2013.yahoo.com',
    '2013gmail.com',
    '2013yahoo.com',
    '2015gmail.com',
    '2016gmail.com',
    '2016yahoo.com',
    '20694025gmail.com',
    '20byahoo.es',
    '20gmail.com',
    '210.gmail.com',
    '2122gmail.com',
    '213gmail.com',
    '21gmail.com',
    '21yahoo.com',
    '222008gmail.com',
    '223gmail.com',
    '22gmail.com',
    '22yahoo.co.in',
    '22yahoo.com',
    '23gmail.com',
    '24.yahoo.com.ph',
    '24530yahoo.com',
    '245gmail.com',
    '2477yahoo.com',
    '24gmail.com',
    '24yahoo.com',
    '25gmail.com',
    '25yahoo.com.mx',
    '26372.gmail.com',
    '263gmail.com',
    '2695gmail.com',
    '26gmail.com',
    '26yahoo.ie',
    '2725gmail.com',
    '274yahoo.com.mx',
    '27gmail.com',
    '27yahoo.com',
    '285gmail.com',
    '28gmail.com',
    '28yahoo.com',
    '29gmail.com',
    '2ggmail.com',
    '2gmail.com',
    '2k11gmail.com',
    '2yahoo.ca',
    '2yahoo.com',
    '2yahoo.ie',
    '300gmail.com',
    '3029gmail.com',
    '3030gmail.com',
    '304yahoo.com',
    '305gmail.com',
    '308gmail.com',
    '30gmail.com',
    '31gmail.com',
    '321gmail.com',
    '322gmail.com',
    '32gmail.com',
    '32yahoo.com',
    '32yahoo.es',
    '333gmail.com',
    '33gmail.com',
    '33yahoo.com',
    '3435gmail.com',
    '34yahoo.co.uk',
    '350gmail.com',
    '357yahoo.com',
    '35gmail.com',
    '361gmail.com',
    '3668gmail.com',
    '36gmail.com',
    '383gmail.com',
    '38482gmail.com',
    '399gmail.com',
    '3gmail.com',
    '3gmail.com.ua',
    '3yahoo.co.uk',
    '3yahoo.com',
    '3ymyahoo.com',
    '4.gmail.com',
    '40gmail.com',
    '40yahoo.com',
    '410gmail.com',
    '4187gmail.com',
    '41yahoo.com',
    '420gmail.com',
    '42gmail.com',
    '4328gmail.com',
    '43gmail.com',
    '441gmail.com',
    '449gmail.com',
    '44gmail.com',
    '44yahoo.com',
    '453yahoo.com',
    '4586gmail.com',
    '45gmail.com',
    '45yahoo.com',
    '46gmail.com',
    '46yahoo.es',
    '476gmail.com',
    '47yahoo.com',
    '48gmail.com',
    '49.yahoo.com',
    '4905comcast.net',
    '49gmail.com',
    '4gmail.com',
    '4wegmail.com',
    '4yahoo.com',
    '50gmail.com',
    '5150gmail.com',
    '51gmail.com',
    '521gmail.com',
    '52gmail.com',
    '52yahoo.com',
    '53comcast.net',
    '53gmail.com',
    '54.gmail.com',
    '54comcast.net',
    '54gmail.com',
    '55gmail.com',
    '55yahoo.com',
    '56.gmail.com',
    '56yahoo.com',
    '572.gmail.com',
    '5757yahoo.com',
    '5758.yahoo.com',
    '578gmail.com',
    '579yahoo.com.mx',
    '57gmail.com',
    '586gmail.com',
    '5871gmail.com',
    '58gmail.com',
    '59.gmail.com',
    '598.gmail.com',
    '59yahoo.com',
    '5gmail.com',
    '5gmail.com.br',
    '5yahoo.com',
    '6021gmail.com',
    '603gmail.com',
    '60gmail.com',
    '610gmail.com',
    '615gmail.com',
    '618.gmail.com',
    '61gmail.com',
    '61yahoo.co.uk',
    '61yahoo.com',
    '624yahoo.com',
    '625gmail.com',
    '62gmail.com',
    '636gmail.com',
    '63yahoo.com',
    '63yahoo.com.mx',
    '64gmail.com',
    '65gmail.com',
    '661gmail.com',
    '66gmail.com',
    '66yahoo.com',
    '66yahoo.fr',
    '674gmail.com',
    '67gmail.com',
    '686gmail.com',
    '68gmail.com',
    '69gmail.com',
    '69yahoo.com',
    '6gmail.com',
    '6yahoo.com',
    '6yahoo.fr',
    '7.gmail.com',
    '703gmail.com',
    '704gmail.com',
    '71gmail.com',
    '72gmail.com',
    '73gmail.com',
    '74gmail.com',
    '75gmail.com',
    '777.gmail.com',
    '777gmail.com',
    '77yahoo.com',
    '780yahoo.com',
    '786110yahoo.com',
    '786786gmail.com',
    '786gmail.com',
    '78gmail.com',
    '78yahoo.es',
    '792gmail.com',
    '793yahoo.com',
    '79gmail.com',
    '79yahoo.com',
    '7agmail.com',
    '7days.gmail.com',
    '7gmail.com',
    '7twcny.rr.com',
    '7yahoo.co.uk',
    '7yahoo.com',
    '7yahoo.com.br',
    '802gmail.com',
    '81gmail.com',
    '821gmail.com',
    '82gmail.com',
    '83gmail.com.pg',
    '85.gmail.com',
    '85gmail.com',
    '85yahoo.com',
    '863yahoo.com',
    '86gmail.com',
    '87gmail.com',
    '87yahoo.com.br',
    '88.gmail.com',
    '888888888888888gmail.com',
    '88gmail.com',
    '89gmail.com',
    '8gmail.com',
    '90.yahoo.co.uk',
    '907gmail.com',
    '9098gmail.com',
    '90gmail.com',
    '914479035gmail.com',
    '914gmail.com',
    '916.gmail.com',
    '91gmail.com',
    '920gmail.com',
    '927yahoo.com',
    '92gmail.com',
    '934gmail.com',
    '9354gmail.com',
    '93gmail.com',
    '93yahoo.com',
    '94gmail.com',
    '95gmail.com',
    '9612gmail.com',
    '96yahoo.com.br',
    '974gmail.com',
    '975gmail.com',
    '97gmail.com',
    '987gmail.com',
    '98yahoo.com',
    '999gmail.com',
    '99gmail.com',
    '9gmail.com',
    'a.gmail.com',
    'a1gmail.com',
    'aagmail.com',
    'aasgmail.com',
    'aaustin.rr.com',
    'abelfence.comcastbiz.net',
    'absamail.co.za',
    'abv.bg',
    'acaciaglass.comcastbiz.net',
    'accesswireless.gmail.com',
    'accom.net.il',
    'account.edit.yahoo.co.jp',
    'accountant.com',
    'acomcast.net',
    'actcom.net.il',
    'actual.gmail.com',
    'adgmail.com',
    'adinet.com.uy',
    'adv.gmail.com',
    'aestheticadayspa.comcastbiz.net',
    'aflactucson.comcastbiz.net',
    'africamail.com',
    'agmail.com',
    'aguiargmail.com',
    'aim.com',
    'airgmail.com',
    'alasonelec.comcastbiz.net',
    'alcockgmail.com',
    'alerts.comcast.net',
    'alice.it',
    'al-ittihad.gmail.com',
    'all1.gmail.com',
    'alphals.comcastbiz.net',
    'altius.gmail.com.uy',
    'altiusdev.comcastbiz.net',
    'amazingmail.com',
    'amitnet.net.il',
    'amss.comcastbiz.net',
    'amzuumazi.gmail.com',
    'andics.gmail.com',
    'antonioluiz.gmail.com',
    'aol.co',
    'aol.co.ik',
    'aol.co.in',
    'aol.co.jk',
    'aol.co.nz',
    'aol.co.uk',
    'aol.co.ukk',
    'aol.co.ukl',
    'aol.cod',
    'aol.coim',
    'aol.coin',
    'aol.cok',
    'aol.cokm',
    'aol.col',
    'aol.col.uk',
    'aol.colm',
    'aol.com',
    'aol.com.au',
    'aol.com.br',
    'aol.com.com',
    'aol.com.gwia.bsuprim',
    'aol.com.jp',
    'aol.com.mx',
    'aol.com.na',
    'aol.com.readnotify.com',
    'aol.com.returnreceipt.com',
    'aol.com.ve',
    'aol.coma',
    'aol.comadtambunga.at',
    'aol.comascl',
    'aol.comb',
    'aol.combfc',
    'aol.comc',
    'aol.comce.com',
    'aol.comcgraw',
    'aol.comcom',
    'aol.comd',
    'aol.comddy',
    'aol.comdyf',
    'aol.come',
    'aol.comf',
    'aol.comffc',
    'aol.comfmd',
    'aol.comg',
    'aol.comgfy',
    'aol.comgipper',
    'aol.comglo',
    'aol.comhoo.com',
    'aol.comj',
    'aol.comk',
    'aol.coml',
    'aol.comlee',
    'aol.comm',
    'aol.commaintancem',
    'aol.commmmm',
    'aol.comn',
    'aol.comnab',
    'aol.como',
    'aol.comq',
    'aol.comry',
    'aol.coms',
    'aol.comscuba',
    'aol.comskype',
    'aol.comss',
    'aol.comverizon.net',
    'aol.comx',
    'aol.comxex',
    'aol.comy',
    'aol.comyew',
    'aol.con',
    'aol.conm',
    'aol.coom',
    'aol.copm',
    'aol.couk',
    'aol.gmail.com',
    'aol1484gmail.com',
    'aolgmail.com',
    'apac.gmail.com',
    'apc446.comcastbiz.net',
    'aracajuyahoo.com.br',
    'arup.gmail.com',
    'asgmail.com',
    'asif1985yahoo.om',
    'ass.yahoo.fr',
    'astin.rr.com',
    'att.net',
    'atyahoo.com',
    'au.yahoo.com',
    'ausitn.rr.com',
    'austi.rr.com',
    'austin.rr.com',
    'autin.rr.com',
    'ayahoo.co.id',
    'ayahoo.co.uk',
    'ayahoo.com',
    'ayahoo.com.br',
    'ayahoo.com.mx',
    'ayahoo.com.ph',
    'ayahoo.es',
    'bak.rr.com',
    'barak.net.il',
    'barid.com',
    'bell.net',
    'bellsouth.net',
    'berkshire.rr.com',
    'bersin.comcast',
    'bersin.comcast.net',
    'bex.net',
    'bezeqinet.net.il',
    'bezeqint.net.il',
    'bgmail.com',
    'bham.rr.com',
    'bhgmail.com',
    'bigmail.com.br',
    'bigpond.com',
    'bigpond.com.au',
    'bigpond.net.au',
    'bingmail.com.au',
    'bista.gmail.com',
    'biu.013.net.il',
    'biz.rr.com',
    'bizec.rr.com',
    'bizer.rr.com',
    'bizlaredo.rr.com',
    'biznycap.rr.com',
    'bizrgv.rr.com',
    'bizstx.rr.com',
    'bizwi.rr.com',
    'bizwoh.rr.com',
    'bk.ru',
    'bluegmail.com',
    'bluewin.ch',
    'blueyonder.co.uk',
    'bogmail.com.au',
    'bol.com.br',
    'bp.yahoo.com',
    'brentpct.nhs.uk.yahoo.com',
    'brooksdesign.yahoo.com',
    'btconnect.com',
    'btinternet.com',
    'btopenworld.com',
    'btyahoo.co.uk',
    'btyahoo.com',
    'builtritesrv.comcastbiz.net',
    'bumerangmail.com.br',
    'c.rr.com',
    'ca.rr.com',
    'caap-ecuador.gmail.com',
    'caarolina.rr.com',
    'cable.comcast',
    'cable.comcast.net',
    'cableone.net',
    'cabuilder.comcastbiz.net',
    'caffeconcerto.gmail.com',
    'cantv.net',
    'caolina.rr.com',
    'capecodpestpros.comcastbiz.net',
    'caroilina.rr.com',
    'carolilna.rr.com',
    'carolina.rr.com',
    'caroliona.rr.com',
    'carolna.rr.com',
    'carolona.rr.com',
    'castro.yahoo.com',
    'cc.rr.com',
    'ccccomcast.net',
    'ccgmail.com',
    'ccomcast.net',
    'cegmail.com',
    'centexbiz.rr.com',
    'centurylink.net',
    'centurytel.net',
    'cfcl.rr.com',
    'cfl.rr.com',
    'cflr.rr.com',
    'cft.rr.com',
    'cfz.rr.com',
    'cgmail.com',
    'charter.net',
    'chaudharigmail.com',
    'cinci.rr.com',
    'cincin.rr.com',
    'cinciu.rr.com',
    'cincy.rr.com',
    'cinei.rr.com',
    'cini.rr.com',
    'claudia.gmail.com',
    'clear.net.nz',
    'clf.rr.com',
    'clinicas.gmail.com',
    'cms-india.yahoo.com',
    'cngmail.com',
    'cogeco.ca',
    'colubus.rr.com',
    'columbus.rr.com',
    'com.gmail.com',
    'comcast.bet',
    'comcast.cable.com',
    'comcast.cet',
    'comcast.cnet',
    'comcast.con',
    'comcast.delivery.net',
    'comcast.ent',
    'comcast.et',
    'comcast.gov',
    'comcast.hhh',
    'comcast.met',
    'comcast.ndt',
    'comcast.ne',
    'comcast.neet',
    'comcast.neg',
    'comcast.negt',
    'comcast.nei',
    'comcast.ner',
    'comcast.nert',
    'comcast.net',
    'comcast-.net',
    'comcast.net.com',
    'comcast.net.net',
    'comcast.net.readnotify.com',
    'comcast.net.returnreceipt.com',
    'comcast.netb',
    'comcast.netd',
    'comcast.netdecember',
    'comcast.nete',
    'comcast.neter',
    'comcast.netf',
    'comcast.netfbd',
    'comcast.netg',
    'comcast.nethenr',
    'comcast.netl',
    'comcast.netmd',
    'comcast.netnet',
    'comcast.netq',
    'comcast.netr',
    'comcast.netsammiric',
    'comcast.nett',
    'comcast.netxmy',
    'comcast.nety',
    'comcast.netyxy',
    'comcast.netyyc',
    'comcast.netz',
    'comcast.ney',
    'comcast.nmet',
    'comcast.nnet',
    'comcast.nret',
    'comcast.nrt',
    'comcast.nst',
    'comcast.nt',
    'comcast.nte',
    'comcast.ntt',
    'comcast.nuet',
    'comcast.nwet',
    'comcast.nwt',
    'comcast.org',
    'comcast5.net',
    'comcastarenaeverett.com',
    'comcastbb.com',
    'comcastbusiness.com',
    'comcastcast.net',
    'comcastdigital.com',
    'comcaster.net',
    'comcastnets.com',
    'comcastnets.net',
    'comcastpc.com',
    'comcastphotos.net',
    'comcastpsortsnet.com',
    'comcastr.net',
    'comcastsales.com',
    'comcastspectacor.com',
    'comcast-spectacor.com',
    'comcastspectacorpayroll.kayako.com',
    'comcastspectator.com',
    'comcast-spectrum.com',
    'comcastsportsnet.com',
    'comcastt.net',
    'comcasty.net',
    'comgmail.com',
    'communications.yahoo.com',
    'conci.rr.com',
    'conserco.comcastbiz.net',
    'consult',
    'consultan',
    'consultant.com',
    'coralina.rr.com',
    'cougars.csusm.gmail.com',
    'counsellor.com',
    'cox.net',
    'cox.rr.com',
    'crconstructionservices.gmail.com',
    'crl.rr.com',
    'cs.rr.com',
    'csas.cz',
    'csgmail.com',
    'cxolumbus.rr.com',
    'cyahoo.co.id',
    'cyahoo.com',
    'dc.rr.com',
    'dcemail.com',
    'deloitte.com',
    'demo.mbfcorp.com',
    'designsoundnw.comcastbiz.net',
    'dffa.comcastbiz.net',
    'dfsdf.gmail.com',
    'dftarch.comcastbiz.net',
    'dgmail.com',
    'diplomats.com',
    'disclosure.gmail.com',
    'dk.gmail.com',
    'dm.net.il',
    'do.gmail.com',
    'dodo.com',
    'dodo.com.ao',
    'dodo.com.au',
    'domain',
    'doo.gmail.com',
    'dpbuildersinc.comcastbiz.net',
    'dpi.net.il',
    'dtgmail.com',
    'dtss.gmail.com',
    'dummy.yahoo.com',
    'duncandirect.comcastbiz.net',
    'dyahoo.com',
    'dyahoo.com.mx',
    'dyahoo.fr',
    'earthlink.net',
    'eastlink.ca',
    'eboy.yahoo.com',
    'ec.rr.com',
    'efyahoo.com',
    'egmail.com',
    'e-gmail.com',
    'eips.gmail.com',
    'eircom.net',
    'elmore.rr.com',
    'elp.rr.com',
    'email.com',
    'email.yahoo.com',
    'embargmail.com',
    'embarqmail.com',
    'e-mile.co.uk',
    'engineer.com',
    'entertrainingmail.com',
    'erogen.comcastbiz.net',
    'escalante.gmail.com',
    'esg-gmail.com',
    'esgmail.com',
    'etherstorm.net',
    'etrog.net.il',
    'etsgmail.com',
    'eufaula.rr.com',
    'europe.com',
    'excite.com',
    'fairlie.gmail.com',
    'famarine.comcastbiz.net',
    'fastmail.fm',
    'fastservice.com',
    'fdgmail.com',
    'fds.gmail.com',
    'fet.gmail.com',
    'fgmail.com',
    'financier.com',
    'firegmail.com',
    'fl.rr.com',
    'fljgmail.com',
    'flooringmail.com',
    'floridagmail.com',
    'flowerpowerchicago.comcastbiz.net',
    'fngmail.com',
    'foogmail.com',
    'forza-doorsgmail.com',
    'fotoplenka.ru',
    'foxmail.com',
    'fr.yahoo.com',
    'free.fr',
    'freemail.hu',
    'freenet.de',
    'frgmail.com',
    'fromru.com',
    'frontier.com',
    'frontiernet.net',
    'fsmail.net',
    'fx.rr.com',
    'fyahoo.com',
    'g.gmail.com',
    'gabiano.gmail.com',
    'gadosgmail.com',
    'gagllc.comcastbiz.net',
    'galitzercpa.gmail.com',
    'gamil.com',
    'garnatillagmail.com',
    'gersgmail.com',
    'ggggmail.com',
    'ggmail.com',
    'ggmail.comm',
    'globo.com',
    'globomail.com',
    'globyahoo.comlconductor.com',
    'gmagmail.com',
    'gmai.com',
    'gmail.co',
    'gmail.com',
    'gmail.com.af',
    'gmail.com.ak',
    'gmail.com.ar',
    'gmail.com.at',
    'gmail.com.au',
    'gmail.com.bd',
    'gmail.com.be',
    'gmail.com.bg',
    'gmail.com.bo',
    'gmail.com.bom',
    'gmail.com.br',
    'gmail.com.br.parreira',
    'gmail.com.bt',
    'gmail.com.ca',
    'gmail.com.centerblog.net',
    'gmail.com.cn',
    'gmail.com.co',
    'gmail.com.co.za',
    'gmail.com.com',
    'gmail.com.com.com',
    'gmail.com.confirm.to',
    'gmail.com.docx',
    'gmail.com.edu',
    'gmail.com.eg',
    'gmail.com.et',
    'gmail.com.fj',
    'gmail.com.gjerdeis-grp.com',
    'gmail.com.gmail.com.uko.kr',
    'gmail.com.gmail.com.vay.kr',
    'gmail.com.grand',
    'gmail.com.gt',
    'gmail.com.hk',
    'gmail.com.hr',
    'gmail.com.if',
    'gmail.com.il',
    'gmail.com.in',
    'gmail.com.kh',
    'gmail.com.mc',
    'gmail.com.misaotra',
    'gmail.com.mobil',
    'gmail.com.mt',
    'gmail.com.mu',
    'gmail.com.mv',
    'gmail.com.mx',
    'gmail.com.my',
    'gmail.com.na',
    'gmail.com.ni',
    'gmail.com.np',
    'gmail.com.nz',
    'gmail.com.om',
    'gmail.com.on',
    'gmail.com.oom',
    'gmail.com.org',
    'gmail.com.pa',
    'gmail.com.pe',
    'gmail.com.pg',
    'gmail.com.ph',
    'gmail.com.pk',
    'gmail.com.pl',
    'gmail.com.pleas',
    'gmail.com.pt',
    'gmail.com.py',
    'gmail.com.qa',
    'gmail.com.re',
    'gmail.com.readnotify.com',
    'gmail.com.returnreceipt.com',
    'gmail.com.ru',
    'gmail.com.sa',
    'gmail.com.sg',
    'gmail.com.so',
    'gmail.com.sou',
    'gmail.com.steve',
    'gmail.com.sv',
    'gmail.com.thestreet',
    'gmail.com.tn',
    'gmail.com.tr',
    'gmail.com.tw',
    'gmail.com.ua',
    'gmail.com.uk',
    'gmail.com.unaprijed',
    'gmail.com.uy',
    'gmail.com.ve',
    'gmail.com.vn',
    'gmail.com.vu',
    'gmail.com.whatsapp',
    'gmail.com.xm',
    'gmail.com.yahoo.com',
    'gmail.com.ye',
    'gmail.com.za',
    'gmail.com.zip',
    'gmail.com.zm',
    'gmail.com3.showroom',
    'gmail.com6',
    'gmail.coma',
    'gmail.comabdulwahabkolo',
    'gmail.comabhishe',
    'gmail.comaddress',
    'gmail.comadmi',
    'gmail.comag',
    'gmail.comagricolapilquicura',
    'gmail.comairfield',
    'gmail.comajivid',
    'gmail.comak',
    'gmail.comalan',
    'gmail.comalaukikv',
    'gmail.comale',
    'gmail.comalix',
    'gmail.comambitieux',
    'gmail.coman',
    'gmail.comanna',
    'gmail.comapa',
    'gmail.comapagaiopirikito',
    'gmail.comar',
    'gmail.comara',
    'gmail.comard',
    'gmail.comari',
    'gmail.comartsheet',
    'gmail.comau',
    'gmail.comay',
    'gmail.comb',
    'gmail.combb',
    'gmail.combbnnnnnnn',
    'gmail.combcm',
    'gmail.combe',
    'gmail.comben',
    'gmail.combetsyfuq',
    'gmail.combgb',
    'gmail.combr',
    'gmail.combw',
    'gmail.combxw',
    'gmail.combym',
    'gmail.comc',
    'gmail.comcall',
    'gmail.comcape',
    'gmail.comcarlos',
    'gmail.comcarver',
    'gmail.comcasseyjohn',
    'gmail.comcast.net',
    'gmail.comcc',
    'gmail.comccc',
    'gmail.comccm',
    'gmail.comceci',
    'gmail.comces',
    'gmail.comcfc',
    'gmail.comcgx',
    'gmail.comciao',
    'gmail.comcle',
    'gmail.comcmy',
    'gmail.comco',
    'gmail.comcoaching',
    'gmail.comcom',
    'gmail.comcomq',
    'gmail.comcont',
    'gmail.comcontact',
    'gmail.comcu',
    'gmail.comd',
    'gmail.comdang',
    'gmail.comdbm',
    'gmail.comddress',
    'gmail.comdenne',
    'gmail.comderlesstrade',
    'gmail.comdetroit',
    'gmail.comdhajgs',
    'gmail.comdiane',
    'gmail.comdie',
    'gmail.comdies',
    'gmail.comdiese',
    'gmail.comdjallen',
    'gmail.comdmytriy.dmytriievgmail.com',
    'gmail.comdoug',
    'gmail.comdress',
    'gmail.come',
    'gmail.comea',
    'gmail.comebw',
    'gmail.comech.jdvu.ac.in',
    'gmail.comed',
    'gmail.comedrl',
    'gmail.comefm',
    'gmail.comegm',
    'gmail.comeme',
    'gmail.comen',
    'gmail.comeng',
    'gmail.comenglish',
    'gmail.comenter',
    'gmail.comer',
    'gmail.comern',
    'gmail.comes',
    'gmail.comesource.com',
    'gmail.comesprivadosportugal',
    'gmail.cometroha',
    'gmail.comevimar',
    'gmail.comf',
    'gmail.comfax',
    'gmail.comfbw',
    'gmail.comfc',
    'gmail.comfcc',
    'gmail.comfce',
    'gmail.comfcx',
    'gmail.comfestiv',
    'gmail.comformulas',
    'gmail.comfr',
    'gmail.comfxx',
    'gmail.comg',
    'gmail.comgbth',
    'gmail.comgc',
    'gmail.comgcy',
    'gmail.comgiovan',
    'gmail.comgmail.com',
    'gmail.comgmb',
    'gmail.comgmg',
    'gmail.comgostaria',
    'gmail.comgym',
    'gmail.comgyx',
    'gmail.comh',
    'gmail.comhar',
    'gmail.comhas',
    'gmail.comhemis',
    'gmail.comher',
    'gmail.comhi',
    'gmail.comhnrj',
    'gmail.comhotmail.com',
    'gmail.comhr',
    'gmail.comhttp',
    'gmail.comi',
    'gmail.comikeblumenstein',
    'gmail.comil',
    'gmail.cominfo',
    'gmail.coming',
    'gmail.comipi',
    'gmail.comit',
    'gmail.comj',
    'gmail.comja',
    'gmail.comjaime',
    'gmail.comjeevan',
    'gmail.comjl',
    'gmail.comjohn',
    'gmail.comjon',
    'gmail.comjtabuya',
    'gmail.comk',
    'gmail.comkavita',
    'gmail.comkindly',
    'gmail.comkn',
    'gmail.comknow',
    'gmail.coml',
    'gmail.comlaptop',
    'gmail.comlaye',
    'gmail.comleo',
    'gmail.comley',
    'gmail.comlk',
    'gmail.comlll',
    'gmail.comm',
    'gmail.commai.com',
    'gmail.commail',
    'gmail.commail.com',
    'gmail.commaill.com',
    'gmail.commanal',
    'gmail.commark',
    'gmail.commartin',
    'gmail.commcm',
    'gmail.comme',
    'gmail.commelectric',
    'gmail.comment',
    'gmail.commentaar',
    'gmail.commes',
    'gmail.commgd',
    'gmail.commgf',
    'gmail.commicro.com',
    'gmail.commirez',
    'gmail.commistytrill',
    'gmail.committee',
    'gmail.commnv',
    'gmail.commv',
    'gmail.commwy',
    'gmail.commx',
    'gmail.commy',
    'gmail.commyy',
    'gmail.commz',
    'gmail.commzuni',
    'gmail.comn',
    'gmail.comnatalee',
    'gmail.comnationalgeogr',
    'gmail.comnb',
    'gmail.comncu',
    'gmail.comnn',
    'gmail.comnnek',
    'gmail.comnu',
    'gmail.comnz',
    'gmail.como',
    'gmail.como.com',
    'gmail.comoa',
    'gmail.comoguar',
    'gmail.comoguard',
    'gmail.comokoo',
    'gmail.comom',
    'gmail.comon',
    'gmail.comonart',
    'gmail.comonlynn',
    'gmail.comopentext',
    'gmail.comor',
    'gmail.comorettasca',
    'gmail.comort',
    'gmail.comos',
    'gmail.comovelasquezlozada',
    'gmail.comp',
    'gmail.compage',
    'gmail.company',
    'gmail.compc',
    'gmail.compe',
    'gmail.compl',
    'gmail.compp',
    'gmail.comppp',
    'gmail.computer',
    'gmail.comq',
    'gmail.comqualitydrywallinc.com',
    'gmail.comque',
    'gmail.comquick',
    'gmail.comr',
    'gmail.comra',
    'gmail.comraimun',
    'gmail.comrew',
    'gmail.comrexm.commercialsystemsgmail.com',
    'gmail.comrhssmudging',
    'gmail.comrobwrobw',
    'gmail.comrs',
    'gmail.coms',
    'gmail.comsa',
    'gmail.comself',
    'gmail.comsmartsheet',
    'gmail.comsmtpcraigthigpen',
    'gmail.comsq',
    'gmail.comss',
    'gmail.comstifanazzo',
    'gmail.comt',
    'gmail.comthank',
    'gmail.comthanks',
    'gmail.comthe',
    'gmail.comtheresa.ab112gmail.com',
    'gmail.comthis',
    'gmail.comti',
    'gmail.comtt',
    'gmail.comu',
    'gmail.comungrg',
    'gmail.comus',
    'gmail.comuseti',
    'gmail.comust',
    'gmail.comustor',
    'gmail.comv',
    'gmail.comw',
    'gmail.comwccww',
    'gmail.comweb',
    'gmail.comwere',
    'gmail.comwfxssprobe',
    'gmail.comwoodard',
    'gmail.comwshawnqq',
    'gmail.comww.arb',
    'gmail.comwxm',
    'gmail.comx',
    'gmail.comxbe',
    'gmail.comxcg',
    'gmail.comxf',
    'gmail.comxge',
    'gmail.comxmx',
    'gmail.comxx',
    'gmail.comy',
    'gmail.comybw',
    'gmail.comyuburenatus',
    'gmail.comyy',
    'gmail.comz',
    'gmail.comzam',
    'gmail.comzy',
    'gmail.gmail.com',
    'gmail.gmail.comcom',
    'gmail.yahoo.com',
    'gmailgmail.com',
    'gmailgmailgmail.com',
    'gmailyahoo.com',
    'gmgmail.com',
    'gmial.com',
    'gmx.co.uk',
    'gmx.com',
    'gmx.de',
    'gmx.net',
    'go.gmail.com',
    'gogogmail.com',
    'golden-gmail.com',
    'goodtrans.comcastbiz.net',
    'googlegmail.com',
    'googlemail.com',
    'googmail.com',
    'gracefwi.comcast.net',
    'graylingmail.com',
    'greenscapesaustin.rr.com',
    'gruposyahoo.com',
    'gruposyahoo.com.ar',
    'gt.rr.com',
    'gwu.gmail.com',
    'gyahoo.com',
    'gyahoo.com.mx',
    'gyahoo.fr',
    'hachis.gmail.com',
    'hafnet.dk',
    'hanmail.net',
    'hanmigmail.com',
    'hawai.rr.com',
    'hawaii.rr.com',
    'hawwaii.rr.com',
    'hbagtagmail.com',
    'hgmail.com',
    'hh.yahoo.com',
    'higmail.com',
    'hiot.rr.com',
    'hkyahoo.com',
    'hmgmail.com',
    'hoc.rr.com',
    'hogmail.com',
    'holinessgmail.com',
    'homail.com',
    'homeinfusion.comcastbiz.net',
    'hot.net.il',
    'hot.rr.com',
    'hotgmail.com',
    'hotmai.com',
    'hotmail..com',
    'hotmail.1.co.uk',
    'hotmail.9.com',
    'hotmail.ac',
    'hotmail.ac.uk',
    'hotmail.acom',
    'hotmail.ao.com',
    'hotmail.aom',
    'hotmail.ar',
    'hotmail.ar.com',
    'hotmail.as',
    'hotmail.at',
    'hotmail.au',
    'hotmail.be',
    'hotmail.bet',
    'hotmail.biz',
    'hotmail.bo',
    'hotmail.bom',
    'hotmail.br',
    'hotmail.c.om',
    'hotmail.c.uk',
    'hotmail.c0.uk',
    'hotmail.c28bo.uk',
    'hotmail.ca',
    'hotmail.cai',
    'hotmail.cam',
    'hotmail.cammy',
    'hotmail.can',
    'hotmail.caom',
    'hotmail.carrizoza',
    'hotmail.cas',
    'hotmail.cc',
    'hotmail.cccom',
    'hotmail.ccm',
    'hotmail.ccom',
    'hotmail.cdom',
    'hotmail.cem',
    'hotmail.ces',
    'hotmail.cfom',
    'hotmail.ch',
    'hotmail.chin',
    'hotmail.ci',
    'hotmail.ci.uk',
    'hotmail.cim',
    'hotmail.cimn',
    'hotmail.cin',
    'hotmail.cinm',
    'hotmail.ciom',
    'hotmail.cit',
    'hotmail.cjm',
    'hotmail.cjom',
    'hotmail.ck',
    'hotmail.ck.um',
    'hotmail.ckm',
    'hotmail.ckuo',
    'hotmail.cl',
    'hotmail.clib',
    'hotmail.cll',
    'hotmail.clm',
    'hotmail.clol',
    'hotmail.clom',
    'hotmail.cm',
    'hotmail.cm.br',
    'hotmail.cm.hk',
    'hotmail.cml',
    'hotmail.cmo',
    'hotmail.cmom',
    'hotmail.cn',
    'hotmail.co',
    'hotmail.co.ar',
    'hotmail.co.au',
    'hotmail.co.br',
    'hotmail.co.ca',
    'hotmail.co.ch',
    'hotmail.co.co',
    'hotmail.co.com',
    'hotmail.co.cu',
    'hotmail.co.cuk',
    'hotmail.co.ha',
    'hotmail.co.id',
    'hotmail.co.ik',
    'hotmail.co.il',
    'hotmail.co.in',
    'hotmail.co.jp',
    'hotmail.co.kk',
    'hotmail.co.kr',
    'hotmail.co.ku',
    'hotmail.co.kuk',
    'hotmail.co.mz',
    'hotmail.co.nz',
    'hotmail.co.ouk',
    'hotmail.co.th',
    'hotmail.co.ttth',
    'hotmail.co.ui',
    'hotmail.co.uik',
    'hotmail.co.uj',
    'hotmail.co.ujk',
    'hotmail.co.uk',
    'hotmail.co.uk.co.uk',
    'hotmail.co.ukc',
    'hotmail.co.ukegx',
    'hotmail.co.uker',
    'hotmail.co.uki',
    'hotmail.co.ukj',
    'hotmail.co.ukk',
    'hotmail.co.ukl',
    'hotmail.co.ukm',
    'hotmail.co.ukq',
    'hotmail.co.uky',
    'hotmail.co.ul',
    'hotmail.co.um',
    'hotmail.co.uuk',
    'hotmail.co.uyk',
    'hotmail.co.yj',
    'hotmail.co.yk',
    'hotmail.co.za',
    'hotmail.co.zam',
    'hotmail.coam',
    'hotmail.cob',
    'hotmail.cobcmm',
    'hotmail.coc',
    'hotmail.cocm',
    'hotmail.codm',
    'hotmail.coim',
    'hotmail.cojm',
    'hotmail.cok',
    'hotmail.cokm',
    'hotmail.coku',
    'hotmail.col',
    'hotmail.col.uk',
    'hotmail.colm',
    'hotmail.coluk',
    'hotmail.com',
    'hotmail.com.ae',
    'hotmail.com.ai',
    'hotmail.com.ar',
    'hotmail.com.ar.net',
    'hotmail.com.arb',
    'hotmail.com.arhaydeeblancoitalia',
    'hotmail.com.arxcf',
    'hotmail.com.asr',
    'hotmail.com.au',
    'hotmail.com.bh',
    'hotmail.com.bo',
    'hotmail.com.br',
    'hotmail.com.bt',
    'hotmail.com.bu',
    'hotmail.com.cn',
    'hotmail.com.co',
    'hotmail.com.co.uk',
    'hotmail.com.com',
    'hotmail.com.con',
    'hotmail.com.cy',
    'hotmail.com.dan',
    'hotmail.com.do',
    'hotmail.com.ec',
    'hotmail.com.eg',
    'hotmail.com.eru',
    'hotmail.com.es',
    'hotmail.com.fr',
    'hotmail.com.hk',
    'hotmail.com.hl',
    'hotmail.com.hn',
    'hotmail.com.il',
    'hotmail.com.ind.br',
    'hotmail.com.it',
    'hotmail.com.jj',
    'hotmail.com.m.ba',
    'hotmail.com.mc',
    'hotmail.com.mx',
    'hotmail.com.my',
    'hotmail.com.ni',
    'hotmail.com.nz',
    'hotmail.com.obs',
    'hotmail.com.pa',
    'hotmail.com.pe',
    'hotmail.com.pee',
    'hotmail.com.ph',
    'hotmail.com.pk',
    'hotmail.com.pt',
    'hotmail.com.py',
    'hotmail.com.readnotify.com',
    'hotmail.com.returnreceipt.com',
    'hotmail.com.rj',
    'hotmail.com.rw',
    'hotmail.com.sa',
    'hotmail.com.se',
    'hotmail.com.sg',
    'hotmail.com.sr',
    'hotmail.com.sv',
    'hotmail.com.tn',
    'hotmail.com.tr',
    'hotmail.com.trf',
    'hotmail.com.tw',
    'hotmail.com.uk',
    'hotmail.com.uy',
    'hotmail.com.ve',
    'hotmail.com.vn',
    'hotmail.com.vr',
    'hotmail.coma',
    'hotmail.comaaaaaaaaaaaaaaa',
    'hotmail.comaddress',
    'hotmail.comal',
    'hotmail.comand',
    'hotmail.comar',
    'hotmail.comarez',
    'hotmail.comari',
    'hotmail.comaris',
    'hotmail.comb',
    'hotmail.combashkiakrume',
    'hotmail.combby',
    'hotmail.combe',
    'hotmail.combfe',
    'hotmail.combfg',
    'hotmail.combm',
    'hotmail.combr',
    'hotmail.combwm',
    'hotmail.combx',
    'hotmail.comc',
    'hotmail.comcbm',
    'hotmail.comccy',
    'hotmail.comcd',
    'hotmail.comcdg',
    'hotmail.comcm',
    'hotmail.comcom',
    'hotmail.comcom.br',
    'hotmail.comcx',
    'hotmail.comcxm',
    'hotmail.comcyb',
    'hotmail.comcye',
    'hotmail.comcyw',
    'hotmail.comd',
    'hotmail.comdanninha',
    'hotmail.comdavidgallegos',
    'hotmail.comdbg',
    'hotmail.comdg',
    'hotmail.comdo',
    'hotmail.comdyc',
    'hotmail.come',
    'hotmail.comebd',
    'hotmail.comedw',
    'hotmail.comee',
    'hotmail.comefb',
    'hotmail.comeff',
    'hotmail.comegf',
    'hotmail.comeid',
    'hotmail.comemb',
    'hotmail.comeme',
    'hotmail.comempleo',
    'hotmail.comert',
    'hotmail.comesta',
    'hotmail.comewb',
    'hotmail.comeyc',
    'hotmail.comeyf',
    'hotmail.comeyg',
    'hotmail.comf',
    'hotmail.comfcx',
    'hotmail.comfdw',
    'hotmail.comfex',
    'hotmail.comff',
    'hotmail.comffffffffffff',
    'hotmail.comfio',
    'hotmail.comfm',
    'hotmail.comfyg',
    'hotmail.comg',
    'hotmail.comga',
    'hotmail.comgbb',
    'hotmail.comgee',
    'hotmail.comgfw',
    'hotmail.comgonzateamo',
    'hotmail.comgy',
    'hotmail.comh',
    'hotmail.comhanlafitte',
    'hotmail.comhg',
    'hotmail.comhot',
    'hotmail.comhotmail',
    'hotmail.comhurtado',
    'hotmail.comi',
    'hotmail.comisc',
    'hotmail.comituran',
    'hotmail.comj',
    'hotmail.comjose',
    'hotmail.comjuanchocolo',
    'hotmail.comk',
    'hotmail.comkiba',
    'hotmail.comkkkkkkkkkkkkkkkkkkkkkkkkkkk',
    'hotmail.coml',
    'hotmail.coml.br',
    'hotmail.coml.com',
    'hotmail.comla',
    'hotmail.comlaelhagri',
    'hotmail.comlastra',
    'hotmail.comlu',
    'hotmail.comlugr',
    'hotmail.comluigi',
    'hotmail.comm',
    'hotmail.commayte',
    'hotmail.commbosrubbish',
    'hotmail.commc',
    'hotmail.commdf',
    'hotmail.commentaar',
    'hotmail.commfd',
    'hotmail.commission',
    'hotmail.commita',
    'hotmail.commmmmmmmmmm',
    'hotmail.commromeo',
    'hotmail.commx',
    'hotmail.comn',
    'hotmail.comnho',
    'hotmail.comnimalija',
    'hotmail.como',
    'hotmail.comom',
    'hotmail.comor',
    'hotmail.comp',
    'hotmail.compersona',
    'hotmail.comq',
    'hotmail.comqcomodoro',
    'hotmail.comr',
    'hotmail.comro',
    'hotmail.coms',
    'hotmail.comsantarosa',
    'hotmail.comsinmob',
    'hotmail.comsk',
    'hotmail.comsree',
    'hotmail.comss',
    'hotmail.comstatus',
    'hotmail.comt',
    'hotmail.comted',
    'hotmail.comthe',
    'hotmail.comtomdenecke',
    'hotmail.comttandrea',
    'hotmail.comu',
    'hotmail.comuk',
    'hotmail.comunidade',
    'hotmail.comv',
    'hotmail.comva',
    'hotmail.comw',
    'hotmail.comweg',
    'hotmail.comwerdf',
    'hotmail.comwew',
    'hotmail.comwg',
    'hotmail.comwwwwwww',
    'hotmail.comwx',
    'hotmail.comwyw',
    'hotmail.comx',
    'hotmail.comxbb',
    'hotmail.comxcd',
    'hotmail.comxd',
    'hotmail.comxfm',
    'hotmail.comxm',
    'hotmail.comxme',
    'hotmail.comxwb',
    'hotmail.comy',
    'hotmail.comybd',
    'hotmail.comyfy',
    'hotmail.comywwy',
    'hotmail.comywx',
    'hotmail.comz',
    'hotmail.con',
    'hotmail.con.ar',
    'hotmail.con.au',
    'hotmail.con.mx',
    'hotmail.con.ve',
    'hotmail.conm',
    'hotmail.conm.br',
    'hotmail.conp',
    'hotmail.conuk',
    'hotmail.conz',
    'hotmail.coom',
    'hotmail.cop',
    'hotmail.cop.uk',
    'hotmail.copm',
    'hotmail.cor',
    'hotmail.corn',
    'hotmail.cou',
    'hotmail.couk',
    'hotmail.coul',
    'hotmail.coum',
    'hotmail.coz',
    'hotmail.coza',
    'hotmail.cp',
    'hotmail.cp.jp',
    'hotmail.cp.nz',
    'hotmail.cp.uk',
    'hotmail.cpk',
    'hotmail.cpm',
    'hotmail.cpo',
    'hotmail.cpom',
    'hotmail.cpp',
    'hotmail.cruz',
    'hotmail.cs',
    'hotmail.cse',
    'hotmail.cu.uk',
    'hotmail.cum',
    'hotmail.cvo.uk',
    'hotmail.cvom',
    'hotmail.cw',
    'hotmail.cxom',
    'hotmail.cz',
    'hotmail.czom',
    'hotmail.czrshamsi',
    'hotmail.dcom',
    'hotmail.dde',
    'hotmail.de',
    'hotmail.dfr',
    'hotmail.die',
    'hotmail.dinis',
    'hotmail.dk',
    'hotmail.do',
    'hotmail.dom',
    'hotmail.dr',
    'hotmail.ea',
    'hotmail.ec',
    'hotmail.ed',
    'hotmail.edu',
    'hotmail.ee',
    'hotmail.ees',
    'hotmail.eom',
    'hotmail.er',
    'hotmail.es',
    'hotmail.es.es',
    'hotmail.esb',
    'hotmail.esc',
    'hotmail.esd',
    'hotmail.esf',
    'hotmail.esmail.es',
    'hotmail.ess',
    'hotmail.esxem',
    'hotmail.esy',
    'hotmail.ew',
    'hotmail.fcom',
    'hotmail.fd',
    'hotmail.fe',
    'hotmail.ff',
    'hotmail.ffr',
    'hotmail.fi',
    'hotmail.fo.uk',
    'hotmail.fom',
    'hotmail.fr',
    'hotmail.fr.fr',
    'hotmail.fr.you',
    'hotmail.framadaleboss',
    'hotmail.frbap',
    'hotmail.fre',
    'hotmail.frferme',
    'hotmail.frr',
    'hotmail.frt',
    'hotmail.ft',
    'hotmail.gcom',
    'hotmail.gm',
    'hotmail.gmail',
    'hotmail.gmail.com',
    'hotmail.gorditopachonsito',
    'hotmail.gov',
    'hotmail.gov.sa',
    'hotmail.gov.uk',
    'hotmail.gr',
    'hotmail.gt',
    'hotmail.hom',
    'hotmail.hotmail',
    'hotmail.hotmail.com',
    'hotmail.html',
    'hotmail.hu',
    'hotmail.icyiim',
    'hotmail.ie',
    'hotmail.il',
    'hotmail.in',
    'hotmail.irt',
    'hotmail.it',
    'hotmail.itn',
    'hotmail.itnt',
    'hotmail.iy',
    'hotmail.jill',
    'hotmail.jp',
    'hotmail.kd',
    'hotmail.kg',
    'hotmail.kom',
    'hotmail.kz',
    'hotmail.lco',
    'hotmail.lco.uk',
    'hotmail.lcom',
    'hotmail.live',
    'hotmail.live.com',
    'hotmail.live.fr',
    'hotmail.livecom',
    'hotmail.love',
    'hotmail.lt',
    'hotmail.lv',
    'hotmail.lw',
    'hotmail.ma',
    'hotmail.mail',
    'hotmail.mail.com',
    'hotmail.mil',
    'hotmail.mom',
    'hotmail.msn',
    'hotmail.msn.co.uk',
    'hotmail.msn.com',
    'hotmail.mx',
    'hotmail.my',
    'hotmail.ne',
    'hotmail.net',
    'hotmail.nl',
    'hotmail.no',
    'hotmail.nom',
    'hotmail.npm',
    'hotmail.o.nz',
    'hotmail.o.th',
    'hotmail.o.uk',
    'hotmail.o.za',
    'hotmail.oc',
    'hotmail.oc.uk',
    'hotmail.ocm',
    'hotmail.ocom',
    'hotmail.om',
    'hotmail.om.ar',
    'hotmail.omc',
    'hotmail.onm',
    'hotmail.oom',
    'hotmail.org',
    'hotmail.outlook',
    'hotmail.ov',
    'hotmail.ove',
    'hotmail.pcom',
    'hotmail.pe',
    'hotmail.pf',
    'hotmail.ph',
    'hotmail.pt',
    'hotmail.py',
    'hotmail.rd',
    'hotmail.ro',
    'hotmail.rs',
    'hotmail.ru',
    'hotmail.sa',
    'hotmail.sc',
    'hotmail.se',
    'hotmail.sfr',
    'hotmail.sg',
    'hotmail.sk',
    'hotmail.sld.pe',
    'hotmail.som',
    'hotmail.sv',
    'hotmail.th.co',
    'hotmail.th.com',
    'hotmail.tk',
    'hotmail.tn',
    'hotmail.tom',
    'hotmail.tr',
    'hotmail.uk',
    'hotmail.uk.co',
    'hotmail.uk.com',
    'hotmail.ut',
    'hotmail.vcom',
    'hotmail.vo.uk',
    'hotmail.vom',
    'hotmail.wcom',
    'hotmail.xcom',
    'hotmail.xo',
    'hotmail.xo.uk',
    'hotmail.xom',
    'hotmail.xon',
    'hotmail.yaho.com',
    'hotmail.yahoo',
    'hotmail.yahoo.com',
    'hotmail.zom',
    'hotmailgmail.com',
    'hotmal.com',
    'hotmil.com',
    'hottmail.gmail.com',
    'hotufi.net.il',
    'houston.rr.com',
    'houtson.rr.com',
    'huji.013.net.il',
    'hushmail.com',
    'hvc.rr.com',
    'hvs.rr.com',
    'hxsgmail.com',
    'hyahoo.co.uk',
    'hyahoo.com',
    'hyahoo.com.br',
    'hyahoo.fr',
    'hyahoo.sv',
    'i3gmail.com',
    'i879yahoo.com',
    'iafrica.com',
    'ibest.com.br',
    'ibrgroup.gmail.com',
    'icloud.com',
    'ig.com.br',
    'igmail.com',
    'igmail.com.br',
    'ihb.comcastbiz.net',
    'iinet.net.au',
    'im.wlgmail.com',
    'impson.comcastbiz.net',
    'ims.gmail.com',
    'in.com',
    'inbox.com',
    'inbox.lv',
    'inbox.ru',
    'indry.rr.com',
    'indy.rr.com',
    'insertgmail.com',
    'insight.rr.com',
    'insightbb.com',
    'insurer.com',
    'integratedgmail.com',
    'inter.net.il',
    'internode.on.net',
    'iol.pt',
    'iprimus.com.au',
    'iron.comcastbiz.net',
    'isaacson.gmail.com',
    'isdn.net.il',
    'it.gmail.com',
    'ivgmail.com',
    'iyahoo.com',
    'jackgmail.com',
    'jalany.yahoo.com.my',
    'jalna71gmail.com',
    'jam.rr.com',
    'janssen.gmail.com',
    'jbgmail.com',
    'jdgmail.com',
    'jdsunlimited.comcastbiznet.com',
    'jgmail.com',
    'jh.gmail.com',
    'jhonyahoo.es',
    'jinguhousesagmail.com',
    'joana.gmail.com',
    'johnboettcher.comcastbiz.net',
    'joko.yahoo.com',
    'juno.com',
    'jws11123.mail.ir2.yahoo.com',
    'jyahoo.com',
    'k12.edu.gmail.com',
    'kc.rr.com',
    'keatingmail.com',
    'keitayahoo.fr',
    'kesiaolinda.gmail.com',
    'kgcompareproductgmail.com',
    'khgmail.com',
    'kidscountkelvinroad.gmail.com',
    'kimo.com',
    'kits.gmail.com',
    'kk.rr.com',
    'klarna.com',
    'krovatka.su',
    'kuhlmangmail.com',
    'kyahoo.com',
    'ladpc.net.il',
    'lakehouse.comcastbiz.net',
    'lakehousewest.comcastbiz.net',
    'laposte.net',
    'latinmail.com',
    'lax.gmail.com',
    'ldjcapital.com',
    'legislator.com',
    'legmail.com',
    'lgmail.com',
    'lhsgmail.com',
    'liberdade.gmail.com',
    'libero.it',
    'live.abertay.ac.uk',
    'live.abertay.live.ac.uk',
    'live.ac.me',
    'live.ac.rs',
    'live.ac.uk',
    'live.aftenskolerne.dk',
    'live.anpier.ac.uk',
    'live.aol.co.uk',
    'live.ar',
    'live.aston.ac.uk',
    'live.at',
    'live.athenian.org',
    'live.au',
    'live.aucb.ac.uk',
    'live.aul.edu.lb',
    'live.ba',
    'live.barnsley.ac.uk',
    'live.be',
    'live.bemidjistate.edu',
    'live.biu.ac.il',
    'live.bl',
    'live.blackburn.ac.uk',
    'live.bluefieldstate',
    'live.bluefieldstate.com',
    'live.bluefieldstate.edu',
    'live.blufieldstate.edu',
    'live.bmetc.ac.uk',
    'live.bracknell.ac.uk',
    'live.bracknell.co.uk',
    'live.bridgwater.ac.uk',
    'live.brock.ac.uk',
    'live.bucks.edu',
    'live.byron.k12.mi.us',
    'live.byron.mi.k12.us',
    'live.c.om',
    'live.c.uk',
    'live.c0.uk',
    'live.ca',
    'live.can',
    'live.carlow.edu',
    'live.cawye',
    'live.cb',
    'live.cca',
    'live.ccom',
    'live.ccom.mx',
    'live.cdom',
    'live.cdom.ph',
    'live.ce',
    'live.celf.dk',
    'live.celinaschools.org',
    'live.ceom',
    'live.ceti.mx',
    'live.cik.au',
    'live.cim',
    'live.cim.mx',
    'live.cl',
    'live.clm',
    'live.clom',
    'live.clom.mx',
    'live.clq',
    'live.cm',
    'live.cm.au',
    'live.cm.mx',
    'live.cmo.au',
    'live.cn',
    'live.cna',
    'live.co',
    'live.co.au',
    'live.co.co.uk',
    'live.co.dk',
    'live.co.ik',
    'live.co.jk',
    'live.co.juk',
    'live.co.kr',
    'live.co.m.mx',
    'live.co.mx',
    'live.co.nz',
    'live.co.ue',
    'live.co.uik',
    'live.co.uj',
    'live.co.uk',
    'live.co.uk.ca',
    'live.co.uk.co',
    'live.co.uk.co.uk',
    'live.co.ukdxd',
    'live.co.ukq',
    'live.co.ulk',
    'live.co.up',
    'live.co.usa',
    'live.co.yj',
    'live.co.yk',
    'live.co.za',
    'live.co.zaa',
    'live.cocm',
    'live.cok',
    'live.cokm',
    'live.col.uk',
    'live.com',
    'live.com.aar',
    'live.com.ae',
    'live.com.ai',
    'live.com.ar',
    'live.com.ar.ab',
    'live.com.art',
    'live.com.at',
    'live.com.au',
    'live.com.auccf',
    'live.com.aui',
    'live.com.ay',
    'live.com.bd',
    'live.com.br',
    'live.com.bu',
    'live.com.cau',
    'live.com.co',
    'live.com.com',
    'live.com.ec',
    'live.com.es',
    'live.com.hk',
    'live.com.le',
    'live.com.mc',
    'live.com.mcmx',
    'live.com.mcx',
    'live.com.mnx',
    'live.com.ms',
    'live.com.mu',
    'live.com.mx',
    'live.com.mx.mx',
    'live.com.mxe',
    'live.com.mxn',
    'live.com.mxx',
    'live.com.my',
    'live.com.mz',
    'live.com.nx',
    'live.com.oh',
    'live.com.ox',
    'live.com.pe',
    'live.com.ph',
    'live.com.pi',
    'live.com.pk',
    'live.com.po',
    'live.com.pr',
    'live.com.pt',
    'live.com.ptar',
    'live.com.ptcocombatentes',
    'live.com.py',
    'live.com.readnotify.com',
    'live.com.sg',
    'live.com.sr',
    'live.com.su',
    'live.com.tw',
    'live.com.uk',
    'live.com.ve',
    'live.com.xm',
    'live.com.zu',
    'live.coma.ar',
    'live.comar',
    'live.comau',
    'live.comcdm',
    'live.comcom',
    'live.comcwd',
    'live.comd',
    'live.come',
    'live.come.au',
    'live.come.mx',
    'live.comemail',
    'live.comficha',
    'live.comh',
    'live.comhotmail.com',
    'live.comj',
    'live.coml',
    'live.comm',
    'live.comm.mx',
    'live.commx',
    'live.commx.jm',
    'live.comn',
    'live.comn.mx',
    'live.como',
    'live.como.mx',
    'live.comom',
    'live.comq',
    'live.comtka',
    'live.comurquart.au',
    'live.comurrfj',
    'live.comx',
    'live.con',
    'live.con.ar',
    'live.con.au',
    'live.con.mx',
    'live.concordia.ca',
    'live.conm',
    'live.conuk',
    'live.coom',
    'live.coom.mx',
    'live.copm',
    'live.copm.mx',
    'live.cornwall.ac.uk',
    'live.couk',
    'live.coventry.ac.uk',
    'live.coza',
    'live.cp',
    'live.cpm',
    'live.cpm.mxx',
    'live.crescent.edu.sg',
    'live.croydon.ac.uk',
    'live.cs',
    'live.cuny.edu',
    'live.cvesd.org',
    'live.cxom.mx',
    'live.cz',
    'live.de',
    'live.de.de',
    'live.delhi.edu',
    'live.dk',
    'live.dkl',
    'live.dl',
    'live.dom',
    'live.dominic.tas.edu.au',
    'live.edu',
    'live.edu.me',
    'live.edu.mx',
    'live.ee',
    'live.eicc.edu',
    'live.eom',
    'live.epcc.edu',
    'live.es',
    'live.estacio.br',
    'live.esu.edu',
    'live.fae.edu',
    'live.fe',
    'live.ffr',
    'live.fi',
    'live.fr',
    'live.franklinpierce.edu',
    'live.fre',
    'live.frfrtoubapzoo',
    'live.frr',
    'live.frtoubapzoo',
    'live.ft',
    'live.gov.au',
    'live.harper.ac.uk',
    'live.hccc.edu',
    'live.hk',
    'live.horrycountyschools.net',
    'live.ibs.ac',
    'live.icom',
    'live.id',
    'live.ie',
    'live.ieeeee',
    'live.iium.edu.my',
    'live.in',
    'live.ind.br',
    'live.is',
    'live.it',
    'live.it.com',
    'live.itsz.edu.mx',
    'live.iu',
    'live.iup.edu',
    'live.iy',
    'live.jec.ac.uk',
    'live.jobcorps.org',
    'live.johnshopkins.edu',
    'live.jp',
    'live.k12.byron.mi.us',
    'live.killeenisd.org',
    'live.konyang.ac.kr',
    'live.kutztown.edu',
    'live.kutztown.edy',
    'live.kvctc.edu',
    'live.kym.edu.my',
    'live.l12.byron.mi.us',
    'live.lagcc.cuny.edu',
    'live.lancs.ac.uk',
    'live.law.cuny.edu',
    'live.lindisfarne.nsw.edu.au',
    'live.live.au',
    'live.lmc.com',
    'live.lo',
    'live.longwood.com',
    'live.longwood.edu',
    'live.ma',
    'live.madeira-edu.pt',
    'live.marshall.edu',
    'live.maryville.edu',
    'live.mcauleyhs.net',
    'live.mcl.edu.ph',
    'live.mdc.ac.uk',
    'live.mdx.ac.uk',
    'live.mercer.edu',
    'live.mercer.eu',
    'live.missouristate.ed',
    'live.missouristate.edu',
    'live.mlmclilydale.catholic.edu.au',
    'live.mnnu.ac.za',
    'live.mnwest.edu',
    'live.mom',
    'live.mv',
    'live.mx',
    'live.mx.com',
    'live.my',
    'live.naiper.ac.uk',
    'live.napier.ac.uk',
    'live.napier.co.uk',
    'live.ndm.edu',
    'live.nel',
    'live.net',
    'live.net.au',
    'live.nk',
    'live.nl',
    'live.nmhu.edu',
    'live.nmit.ac.nz',
    'live.nmmu.ac.za',
    'live.no',
    'live.northshoreschools.org',
    'live.ntcmn.edu',
    'live.nwrc.ac.uk',
    'live.o.uk',
    'live.o.za',
    'live.oc.uk',
    'live.ocasd.org',
    'live.ocm',
    'live.ocm.au',
    'live.ocm.mx',
    'live.ocm.pt',
    'live.oldham.ac.uk',
    'live.oldham.co.uk',
    'live.oldham.com',
    'live.olgchs.org',
    'live.olivet.edu',
    'live.om',
    'live.om.au',
    'live.om.mx',
    'live.omc',
    'live.oom',
    'live.org',
    'live.org.uk',
    'live.ot',
    'live.pharmacy.ac.uk',
    'live.pioneerpacific.edu',
    'live.psu.edu',
    'live.pt',
    'live.reading.ac.uk',
    'live.rf',
    'live.rhul.ac.uk',
    'live.rr',
    'live.ru',
    'live.saisd.net',
    'live.school.ac.uk',
    'live.se',
    'live.seminoelstate.edu',
    'live.seminolestate.edu',
    'live.seminolestate.edu.com',
    'live.sg',
    'live.sg.com',
    'live.siouxcityschools.com',
    'live.smuc.ac.uk',
    'live.spc.edu',
    'live.spcollege.com',
    'live.spcollege.ed',
    'live.spcollege.edu',
    'live.sscc.edu',
    'live.stmarys.ac.uk',
    'live.sumdu.edu.ua',
    'live.sunyjefferson.edu',
    'live.sunysccc.edu',
    'live.tcicolage.edu',
    'live.tcicollege.edu',
    'live.tee.ac.uk',
    'live.tees.ac',
    'live.tees.ac.uk',
    'live.time',
    'live.trinity.sa.edu.au',
    'live.ucl.ac.uk',
    'live.uem.es',
    'live.uk',
    'live.uk.com',
    'live.uleam.edu.ec',
    'live.umcs.edu.pl',
    'live.unc.com',
    'live.unc.edu',
    'live.unic.ac.cy',
    'live.unigis.net',
    'live.uni-sofia.bg',
    'live.unthsc.edu',
    'live.u-tad.com',
    'live.utm.my',
    'live.uvi.edu',
    'live.uwe.ac',
    'live.uwe.ac.uk',
    'live.va',
    'live.vanderbilt.edu',
    'live.vasteras.se',
    'live.vcom',
    'live.vo.uk',
    'live.vom',
    'live.vom.mx',
    'live.vu.com.au',
    'live.vu.edu',
    'live.vu.edu.au',
    'live.warwick.ac.uk',
    'live.wcs.ac.uk',
    'live.wsd1.org',
    'live.xl',
    'live.xlm',
    'live.xom',
    'live.xom.mx',
    'live.ycsech.org',
    'live.ycstech.com',
    'live.ycstech.edu',
    'live.ycstech.og',
    'live.ycstech.org',
    'live.ytech.edu',
    'livegmail.com',
    'lj.gmail.com',
    'lksjdkf.gmail.com',
    'lostiempos-gmail.com',
    'lrochester.rr.com',
    'ltxcgmail.com',
    'lushnje.yahoo.com',
    'lyahoo.com',
    'lyahoo.com.mx',
    'lyahoo.es',
    'lyahoo.it',
    'lycos.com',
    'ma.rr.com',
    'mac.com',
    'mac.gmail.comm',
    'magmail.com',
    'magnolia.gmail.com',
    'mahboobgmail.com',
    'mail.com',
    'mail.gmail.com',
    'mail.netvision.net.il',
    'mail.ru',
    'mail.yahoo.co.in',
    'mail.yahoo.com',
    'mail15.com',
    'mail2usa.com',
    'mail333.com',
    'mailinator.com',
    'maine.rr.com',
    'maineislegmail.com',
    'mainv.gmail.com',
    'makergmail.com',
    'marcopolo.comcastbiz.net',
    'maria.gmail.com',
    'maricott.gmail.com',
    'marketing.gmail.com',
    'mashiyahoo.com',
    'mass.rr.com',
    'mata2011yahoo.es',
    'matav.net.il',
    'matc.edu.gmail.com',
    'maxitile.gmail.com',
    'mbfcorp.com',
    'mbine.rr.com',
    'mcgmail.com',
    'mcleangmail.com',
    'mcnulty.comcastbiz.net',
    'mcsgmail.com',
    'me.com',
    'me.gmail.com',
    'medprogress.gmail.com',
    'mehra89gmail.com',
    'memberyahoo.com',
    'memori.ru',
    'mendezyahoo.com',
    'mescons.comcastbiz.net',
    'meyahoo.com',
    'mf24.yahoo.com',
    'mgfairfax.rr.com',
    'mgmail.com',
    'mi.rr.com',
    'michaeligmail.com',
    'midsouth.rr.com',
    'midsoutt.rr.com',
    'mindspring.com',
    'mkt.gmail.com',
    'mn.rr.com',
    'mobiegmail.com',
    'mohe.gmail.com',
    'mrginc.comcastbiz.net',
    'mrgmail.com',
    'mshgmail.com',
    'msn.com',
    'multinet.net.il',
    'mushime.gmail.com',
    'mweb.co.za',
    'my.gmail.com',
    'my.yahoo.com',
    'myahoo.co',
    'myahoo.no',
    'mycleaningteam.comcastbiz.net',
    'mydatingmail.com',
    'mygmail.com',
    'myhfgmail.com',
    'mymts.net',
    'mynet.com',
    'myway.com',
    'n22.bullet.mail.ukl.yahoo.com',
    'n23.bullet.mail.ukl.yahoo.com',
    'n2yahoo.com',
    'nate.com',
    'naver.com',
    'nbjch.comcastbiz.net',
    'nc.rr.com',
    'ne.rr.com',
    'neb.rr.com',
    'needles.comcastbiz.net',
    'neo.rr.com',
    'nertvision.net.il',
    'nervision.net.il',
    'netivsion.net.il',
    'netlogmail.com',
    'netmedia.net.il',
    'neto.net.il',
    'netscape.net',
    'netspace.net.au',
    'netto.net.il',
    'netvion.net.il',
    'netvisin.net.il',
    'netvision.net.il',
    'netvisoin.net.il',
    'netvison.net.il',
    'netvition.net.il',
    'netvosion.net.il',
    'netvsion.net.il',
    'netvusuon.net.il',
    'netzero.com',
    'netzero.net',
    'neupane.gmail.com',
    'new.rr.com',
    'newa.rr.com',
    'newbc.rr.com',
    'newlawgmail.com',
    'neworleans.rr.com',
    'ngmail.com',
    'nha.gmail.com',
    'nhgjgmail.com',
    'nightmail.ru',
    'nj.rr.com',
    'njgmail.com',
    'nogmail.com',
    'nonstop.net.il',
    'nordstrom.comcast.net',
    'northrock.bm',
    'nsgmail.com',
    'ntcap.rr.com',
    'nthdegreefx.gmail.com',
    'ntlworld.com',
    'nullgmail.com',
    'numericable.fr',
    'nwtvision.net.il',
    'ny.rr.com',
    'nyahoo.com',
    'nyc.ec.rr.com',
    'nyc.rr.com',
    'nycap.rr.com',
    'nycapp.rr.com',
    'nycat.rr.com',
    'o2.co.uk',
    'o2.pl',
    'odgmail.com',
    'oh.rr.com',
    'ohcc.gmail.com',
    'ohhs.gmail.com',
    'oi.com.br',
    'olympusaerial.comcastbiz.net',
    'omp404.mail.sp1.yahoo.com',
    'oogmail.com',
    'op.pl',
    'optonline.net',
    'optusnet.com.au',
    'orange.fr',
    'orange.net.il',
    'oriwise.gmail.com',
    'outlook.ac.uk',
    'outlook.ae',
    'outlook.as',
    'outlook.at',
    'outlook.au',
    'outlook.be',
    'outlook.bom',
    'outlook.ca',
    'outlook.cam',
    'outlook.ch',
    'outlook.ci',
    'outlook.cim',
    'outlook.ck',
    'outlook.cl',
    'outlook.cm',
    'outlook.cm.tr',
    'outlook.cmo',
    'outlook.co',
    'outlook.co.cy',
    'outlook.co.id',
    'outlook.co.il',
    'outlook.co.in',
    'outlook.co.jp',
    'outlook.co.nx',
    'outlook.co.nz',
    'outlook.co.th',
    'outlook.co.uk',
    'outlook.co.za',
    'outlook.cok',
    'outlook.col',
    'outlook.com',
    'outlook.com.ar',
    'outlook.com.au',
    'outlook.com.br',
    'outlook.com.cy',
    'outlook.com.es',
    'outlook.com.fr',
    'outlook.com.gr',
    'outlook.com.mx',
    'outlook.com.na',
    'outlook.com.pe',
    'outlook.com.sa',
    'outlook.com.sg',
    'outlook.com.sv',
    'outlook.com.tr',
    'outlook.com.vn',
    'outlook.con',
    'outlook.cpm',
    'outlook.cx',
    'outlook.cz',
    'outlook.de',
    'outlook.dk',
    'outlook.dom',
    'outlook.ed',
    'outlook.edu',
    'outlook.ee',
    'outlook.ees',
    'outlook.es',
    'outlook.ex',
    'outlook.fr',
    'outlook.gr',
    'outlook.hr',
    'outlook.hu',
    'outlook.ie',
    'outlook.in',
    'outlook.it',
    'outlook.iy',
    'outlook.jp',
    'outlook.kom',
    'outlook.kr',
    'outlook.ks',
    'outlook.lt',
    'outlook.lv',
    'outlook.mx',
    'outlook.my',
    'outlook.ne',
    'outlook.ne.jp',
    'outlook.net',
    'outlook.nl',
    'outlook.no',
    'outlook.oc.uk',
    'outlook.ocm',
    'outlook.om',
    'outlook.om.br',
    'outlook.org',
    'outlook.pe',
    'outlook.ph',
    'outlook.pt',
    'outlook.sa',
    'outlook.sg',
    'outlook.sk',
    'outlook.som',
    'outlook.vom',
    'outlook.xom',
    'oyahoo.com',
    'oyahoo.es',
    'ozemail.com.au',
    'pa.rr.com',
    'pacbell.net',
    'panhandle.rr.com',
    'pclabassandhgmail.com',
    'pcvr.gmail.com',
    'peacockrealty.gmail.com',
    'pendletontire.comcastbiz.net',
    'penyahoo.com',
    'perfectsolutions.comcastbiz.net',
    'pfgroup.gmail.com',
    'pgmail.com',
    'phgmail.com',
    'phicom.gmail.com',
    'photofile.ru',
    'pickeringmail.com',
    'pisem.net',
    'planetmail.com',
    'platinumpaintersnz.gmail.com',
    'play.gmail.com',
    'plkyahoo.com',
    'plp.gmail.com',
    'plumbingmail.com',
    'plyahoo.com.br',
    'pngmail.com',
    'pobox.com',
    'pochta.com',
    'pochtamt.ru',
    'post.com',
    'post.cz',
    'ppp.gmail.com',
    'pqdyahoo.com.br',
    'precisionmasonry.comcastbiz.net',
    'prodigy.net',
    'prodigy.net.mx',
    'profiles.yahoo.com',
    'profperformance.comcastbiz.net',
    'prolongmail.com',
    'propnex.gmail.com',
    'protonmail.com',
    'psnwi.comcastbiz.net',
    'ptmgmail.com',
    'publicrelations.gmail.com',
    'pyahoo.com',
    'pye.comcastbiz.net',
    'q.com',
    'qgmail.com',
    'qip.ru',
    'qos.net.il',
    'qq.com',
    'qualitymold.comcastbiz.net',
    'qualityservice.com',
    'quantech.comcastbiz.net',
    'qyahoo.com',
    'r35gmail.com',
    'r7.com',
    'rambler.ru',
    'ramirezgmail.com',
    'randallwest.gmail.com',
    'rbcmail.ru',
    'reachcybarschoolgmail.com',
    'reagan.com',
    'rediffmail.com',
    'rediggmail.com',
    'regional-drywall.gmail.com',
    'regional-drywallgmail.com',
    'regmail.com',
    'renovakaza.gmail.com',
    'reply.yahoo.com',
    'retragmail.com',
    'rgmail.com',
    'rgn.rr.com',
    'rgr.rr.com',
    'rgv.rr.com',
    'rileyelectric.comcastbiz.net',
    'rimon.net.il',
    'risd.rr.com',
    'rmqkr.net',
    'roadrunner.com',
    'roadrunner.maine.rr.com',
    'rochecter.rr.com',
    'rochesster.rr.com',
    'rocheste.rr.com',
    'rochester.rr.com',
    'rochestr.rr.com',
    'rochesyer.rr.com',
    'rocheter.rr.com',
    'rochseter.rr.com',
    'rocketgmail.com',
    'rocketmail.com',
    'rodchester.rr.com',
    'rogers.com',
    'rogerspizza.comcastbiz.net',
    'rogmail.com',
    'romanofinancial.comcastbiz.net',
    'royalgardens.comcastbiz.net',
    'rrgmail.com',
    'rrogerioyahoo.com',
    'rssinc.gmail.com',
    'rstent.gmail.com',
    'rtbgmail.com',
    'rug.gmail.com',
    'ryahoo.es',
    's4gmail.com',
    'sa.rr.com',
    'sadf.gmail.com',
    'sakpgmail.com',
    'sam.rr.com',
    'san.rr.com',
    'santanagmail.com',
    'santos.gmail.com',
    'sapo.pt',
    'sarx.rr.com',
    'sasktel.net',
    'satc.rr.com',
    'satx.rr.com',
    'sayx.rr.com',
    'sbcglobal.net',
    'sc.rr.com',
    'scomcast.net',
    'sdc.gmail.com',
    'sdsds.gmail.com',
    'se.rr.com',
    'secretary.net',
    'sefas.gmail.com',
    'segmail.com',
    'setinstone.comcast.net',
    'seznam.cz',
    'sfr.fr',
    'sgmail.com',
    'shade.gmail.com',
    'shadowgmail.com',
    'sharklasers.com',
    'shaw.ca',
    'shippingmail.com',
    'shoppybagmail.com',
    'si.rr.com',
    'silvercorp.comcastbiz.net',
    'simbiose.gmail.com',
    'sina.cn',
    'sina.com',
    'sina.com.cn',
    'sinclairelectric.comcastbiz.net',
    'sky.com',
    'smartsheet.com',
    'smiile.net.il',
    'smil.net.il',
    'smile.net.il',
    'sms.gmail.com',
    'socal.rr.com',
    'social.rr.com',
    'soims.comcastbiz.net',
    'solarconsulting.gmail.com',
    'sologmail.com',
    'sonic.net',
    'souzagmail.com',
    'spayandneuterofslc.gmail.com',
    'spoko.pl',
    'springfieldhouse.gmail.com',
    'springmail.com',
    'sr.rr.com',
    'ss.rr.com',
    'ssc.rr.com',
    'ssgmail.com',
    'sstrongmail.com',
    'staplescabinetmakers.comcastbiz.net',
    'stax.rr.com',
    'stay.rr.com',
    'sterlingmail.com',
    'stny.rr.com',
    'stofanet.dk',
    'stony.rr.com',
    'strogmail.com',
    'strongmail.com',
    'stx.rr.com',
    'suddenlink.net',
    'sumsungmail.com',
    'sunnyschickcamera.comcastbiz.net',
    'surfree.net.il',
    'svagnergmail.com',
    'svetividgmail.com',
    'sw.rr.com',
    'swfla.rr.com',
    'syahoo.com',
    'sympatico.ca',
    'talismgmt.southeast.rr.com',
    'talk21.com',
    'talktalk.net',
    'tamapabay.rr.com',
    'tamapbay.rr.com',
    'tampa.bay.rr.com',
    'tampa.rr.com',
    'tampabat.rr.com',
    'tampabay.rr.com',
    'tampaby.rr.com',
    'tampanay.rr.com',
    'tampbay.rr.com',
    'tampsbay.rr.com',
    'tau.013.net.il',
    'tbmpbbby.rr.com',
    'tcsgmail.com',
    'tds.net',
    'teksavvy.com',
    'telefonica.net',
    'telenet.be',
    'telkomsa.net',
    'telus.net',
    'terpmail.gmail.com',
    'terra.com.br',
    'testgmail.com',
    'tfgmail.com',
    'tfl.rr.com',
    'tgmail.com',
    'tgmail.com.br',
    'theodor.gmail.com',
    'theyahoo.com',
    'tirad.rr.com',
    'tiscali.co.uk',
    'tiscali.it',
    'tjgmail.com',
    'tmpabay.rr.com',
    'toast.net',
    'tpg.com.au',
    'tradingyahoo.com',
    'traid.rr.com',
    'tralref.gmail.com',
    'travel.gmail.com',
    'trbvm.com',
    'triad.rr.com',
    'triadbiz.rr.com',
    'triadiz.rr.com',
    'triadl.rr.com',
    'trial.gmail.com',
    'triomfggmail.com',
    'tsinc.comcastbiz.net',
    'tsmpabay.rr.com',
    'tt.rr.com',
    'ttriad.rr.com',
    'tutanota.com',
    'twacny.rr.com',
    'twc.rr.com',
    'twcny.rr.com',
    'twmi.rr.com',
    'twncy.rr.com',
    'tx.rr.com',
    'txcny.rr.com',
    'tyahoo.co.uk',
    'tyahoo.com',
    'tyahoo.com.ph',
    'tyahoo.es',
    'uagwfd.gmail.com',
    'ucwphilly.rr.com',
    'uggmail.com',
    'uk.yahoo.com',
    'unlimited.net.il',
    'uol.com.br',
    'uol.gmail.com',
    'usa.com',
    'usa.net',
    'uyahoo.com',
    'uyahoo.com.br',
    'uymail.com',
    'va.rr.com',
    'vandenbergmail.com',
    'vanguardcleaning.rr.com',
    'verizon.net',
    'verizonyahoo.net',
    'vgmail.com',
    'viayahoo.co.uk',
    'videotron.ca',
    'vikingmail.com',
    'virgin.net',
    'virginmedia.com',
    'vodafone.co.nz',
    'vodamail.co.za',
    'voila.fr',
    'vp.pl',
    'vtr.net',
    'wagmail.com',
    'walla.co.il',
    'walla.com',
    'walla.net.il',
    'wanadoo.fr',
    'wbsdgmail.com',
    'wc.rr.com',
    'wcugmail.com',
    'web.de',
    'web65410.mail.ac4.yahoo.com',
    'webmail.co.za',
    'westnet.com.au',
    'wfgmail.com',
    'wgmail.com',
    'who.rr.com',
    'wi.rr.com',
    'winatour.gmail.com',
    'windowslive.com',
    'windstream.net',
    'wisconsin.rr.com',
    'wkgroup.yahoo.com.au',
    'wlgmail.com',
    'woh.rr.com',
    'work.gmail.com',
    'workgmail.com',
    'worlgmail.com',
    'wowway.com',
    'wp.pl',
    'wr.gmail.com',
    'ww.yahoo.com',
    'wwprsd.gmail.com',
    'www.gmail.com',
    'www.gmail.com.doy.kr',
    'www.yahoo.co.kr.beo.kr',
    'www.yahoo.com',
    'wwwgmail.com',
    'wwyahoo.com',
    'xtra.co.nz',
    'y7gmail.com',
    'y7mail.com',
    'ya.ru',
    'yahoo.ac.id',
    'yahoo.ac.uk',
    'yahoo.acom',
    'yahoo.acom.ar',
    'yahoo.ae',
    'yahoo.ail.com',
    'yahoo.aom',
    'yahoo.aom.au',
    'yahoo.ar',
    'yahoo.at',
    'yahoo.au',
    'yahoo.awan',
    'yahoo.banleehin',
    'yahoo.be',
    'yahoo.bg',
    'yahoo.bol.br',
    'yahoo.bom',
    'yahoo.br',
    'yahoo.c.au',
    'yahoo.c.br',
    'yahoo.c.id',
    'yahoo.c.in',
    'yahoo.c.om',
    'yahoo.c.uk',
    'yahoo.c0.id',
    'yahoo.c0.in',
    'yahoo.c0.uk',
    'yahoo.c0m.sg',
    'yahoo.c2om.ar',
    'yahoo.ca',
    'yahoo.ca.uk',
    'yahoo.caa',
    'yahoo.cabsauer',
    'yahoo.cacxw',
    'yahoo.caew',
    'yahoo.cah',
    'yahoo.cam',
    'yahoo.cam.au',
    'yahoo.can',
    'yahoo.caq',
    'yahoo.cas',
    'yahoo.cat',
    'yahoo.cca',
    'yahoo.cccom',
    'yahoo.ccm',
    'yahoo.cco',
    'yahoo.cco.id',
    'yahoo.cco.jp',
    'yahoo.ccom',
    'yahoo.ccom.br',
    'yahoo.ccom.mx',
    'yahoo.cdom',
    'yahoo.ci.id',
    'yahoo.ci.in',
    'yahoo.ci.uk',
    'yahoo.cij',
    'yahoo.cim',
    'yahoo.cim.ar',
    'yahoo.cim.br',
    'yahoo.cin',
    'yahoo.cio.th',
    'yahoo.ciom',
    'yahoo.cjp',
    'yahoo.ck',
    'yahoo.ckm',
    'yahoo.cl',
    'yahoo.cl.nz',
    'yahoo.clk',
    'yahoo.cllom',
    'yahoo.clm',
    'yahoo.clom',
    'yahoo.cm',
    'yahoo.cm.ar',
    'yahoo.cm.au',
    'yahoo.cm.br',
    'yahoo.cm.hk',
    'yahoo.cm.mx',
    'yahoo.cm.ph',
    'yahoo.cm.sg',
    'yahoo.cmman',
    'yahoo.cmo',
    'yahoo.cmo.br',
    'yahoo.cmom',
    'yahoo.cn',
    'yahoo.co',
    'yahoo.co.ao',
    'yahoo.co.ar',
    'yahoo.co.au',
    'yahoo.co.br',
    'yahoo.co.bw',
    'yahoo.co.bz',
    'yahoo.co.cin',
    'yahoo.co.cn',
    'yahoo.co.co',
    'yahoo.co.com',
    'yahoo.co.cuk',
    'yahoo.co.di',
    'yahoo.co.hk',
    'yahoo.co.hp',
    'yahoo.co.ic',
    'yahoo.co.id',
    'yahoo.co.idputri',
    'yahoo.co.idslulann',
    'yahoo.co.ie',
    'yahoo.co.if',
    'yahoo.co.iid',
    'yahoo.co.iin',
    'yahoo.co.ik',
    'yahoo.co.il',
    'yahoo.co.im',
    'yahoo.co.in',
    'yahoo.co.ina',
    'yahoo.co.incom',
    'yahoo.co.inj',
    'yahoo.co.inm',
    'yahoo.co.inn',
    'yahoo.co.inr',
    'yahoo.co.inwebsite',
    'yahoo.co.ip',
    'yahoo.co.is',
    'yahoo.co.iud',
    'yahoo.co.jo',
    'yahoo.co.jp',
    'yahoo.co.ke',
    'yahoo.co.kf',
    'yahoo.co.kr',
    'yahoo.co.kt',
    'yahoo.co.ku',
    'yahoo.co.luk',
    'yahoo.co.muk',
    'yahoo.co.mx',
    'yahoo.co.mxm',
    'yahoo.co.na',
    'yahoo.co.nz',
    'yahoo.co.od',
    'yahoo.co.oid',
    'yahoo.co.oin',
    'yahoo.co.ok',
    'yahoo.co.om',
    'yahoo.co.on',
    'yahoo.co.ph',
    'yahoo.co.sg',
    'yahoo.co.th',
    'yahoo.co.ug',
    'yahoo.co.uik',
    'yahoo.co.uj',
    'yahoo.co.ujk',
    'yahoo.co.uk',
    'yahoo.co.uk.com',
    'yahoo.co.uka',
    'yahoo.co.ukant',
    'yahoo.co.ukh',
    'yahoo.co.uki',
    'yahoo.co.ukin',
    'yahoo.co.ukj',
    'yahoo.co.ukju',
    'yahoo.co.ukk',
    'yahoo.co.ukl',
    'yahoo.co.ukm',
    'yahoo.co.ukp',
    'yahoo.co.ukqqq',
    'yahoo.co.ukr',
    'yahoo.co.uks',
    'yahoo.co.ukt',
    'yahoo.co.ul',
    'yahoo.co.um',
    'yahoo.co.un',
    'yahoo.co.ur',
    'yahoo.co.uuk',
    'yahoo.co.uyk',
    'yahoo.co.uz',
    'yahoo.co.yj',
    'yahoo.co.yk',
    'yahoo.co.za',
    'yahoo.co.zm',
    'yahoo.co.zuk',
    'yahoo.coam',
    'yahoo.coc',
    'yahoo.coc.uk',
    'yahoo.cocm',
    'yahoo.cocom',
    'yahoo.cocuk',
    'yahoo.coi',
    'yahoo.coi.uk',
    'yahoo.coid',
    'yahoo.coim',
    'yahoo.coin',
    'yahoo.coj',
    'yahoo.cojk',
    'yahoo.cojm',
    'yahoo.cojp',
    'yahoo.cok',
    'yahoo.cok.mx',
    'yahoo.cokm',
    'yahoo.col',
    'yahoo.col.in',
    'yahoo.colm',
    'yahoo.com',
    'yahoo.com.aau',
    'yahoo.com.ae',
    'yahoo.com.ai',
    'yahoo.com.alammar.mammoun',
    'yahoo.com.amztfbskjxnonov.mesvr.com',
    'yahoo.com.an',
    'yahoo.com.ao',
    'yahoo.com.ar',
    'yahoo.com.arce',
    'yahoo.com.arl',
    'yahoo.com.arrmaglio',
    'yahoo.com.art',
    'yahoo.com.ary',
    'yahoo.com.asr',
    'yahoo.com.au',
    'yahoo.com.au.au',
    'yahoo.com.aua',
    'yahoo.com.auf',
    'yahoo.com.auk',
    'yahoo.com.ay',
    'yahoo.com.ba',
    'yahoo.com.bbr',
    'yahoo.com.be',
    'yahoo.com.ber',
    'yahoo.com.bnr',
    'yahoo.com.br',
    'yahoo.com.br.br',
    'yahoo.com.br.com',
    'yahoo.com.br.com.br',
    'yahoo.com.br.obs',
    'yahoo.com.bra',
    'yahoo.com.brb',
    'yahoo.com.brbr',
    'yahoo.com.brc',
    'yahoo.com.bre',
    'yahoo.com.brexx',
    'yahoo.com.brf',
    'yahoo.com.brk',
    'yahoo.com.brm',
    'yahoo.com.brq',
    'yahoo.com.brr',
    'yahoo.com.brt',
    'yahoo.com.brx',
    'yahoo.com.bt',
    'yahoo.com.btr',
    'yahoo.com.bu',
    'yahoo.com.c0m.br',
    'yahoo.com.ca',
    'yahoo.com.canada',
    'yahoo.com.cb',
    'yahoo.com.cn',
    'yahoo.com.co',
    'yahoo.com.co.in',
    'yahoo.com.co.nz',
    'yahoo.com.co.uk',
    'yahoo.com.com',
    'yahoo.com.com.ar',
    'yahoo.com.com.au',
    'yahoo.com.com.br',
    'yahoo.com.com.mx',
    'yahoo.com.com.sg',
    'yahoo.com.con',
    'yahoo.com.cu',
    'yahoo.com.de',
    'yahoo.com.edu.pk',
    'yahoo.com.email81.com',
    'yahoo.com.er',
    'yahoo.com.es',
    'yahoo.com.fj',
    'yahoo.com.fr',
    'yahoo.com.gk',
    'yahoo.com.gmail',
    'yahoo.com.gwia.bsuprim',
    'yahoo.com.he',
    'yahoo.com.hj',
    'yahoo.com.hk',
    'yahoo.com.hk.readnotify.com',
    'yahoo.com.hkk',
    'yahoo.com.hl',
    'yahoo.com.hlc',
    'yahoo.com.hr',
    'yahoo.com.hw',
    'yahoo.com.id',
    'yahoo.com.ie',
    'yahoo.com.in',
    'yahoo.com.jp',
    'yahoo.com.ke',
    'yahoo.com.kh',
    'yahoo.com.kr',
    'yahoo.com.ma',
    'yahoo.com.mc',
    'yahoo.com.mcx',
    'yahoo.com.md',
    'yahoo.com.mex',
    'yahoo.com.mix',
    'yahoo.com.mk',
    'yahoo.com.mmmmmx',
    'yahoo.com.mmx',
    'yahoo.com.ms',
    'yahoo.com.mt',
    'yahoo.com.mu',
    'yahoo.com.mx',
    'yahoo.com.mx.com',
    'yahoo.com.mx.readnotify.com',
    'yahoo.com.mxc',
    'yahoo.com.mxg',
    'yahoo.com.mxi',
    'yahoo.com.mxl',
    'yahoo.com.mxp',
    'yahoo.com.mxr',
    'yahoo.com.mxx',
    'yahoo.com.my',
    'yahoo.com.mz',
    'yahoo.com.net',
    'yahoo.com.ni',
    'yahoo.com.np',
    'yahoo.com.nr',
    'yahoo.com.nx',
    'yahoo.com.nz',
    'yahoo.com.oh',
    'yahoo.com.om',
    'yahoo.com.or',
    'yahoo.com.pch',
    'yahoo.com.pe',
    'yahoo.com.pf',
    'yahoo.com.ph',
    'yahoo.com.phl',
    'yahoo.com.phn',
    'yahoo.com.pj',
    'yahoo.com.pk',
    'yahoo.com.pn',
    'yahoo.com.py',
    'yahoo.com.rb',
    'yahoo.com.readnotify.com',
    'yahoo.com.returnreceipt.com',
    'yahoo.com.sa',
    'yahoo.com.sb',
    'yahoo.com.sg',
    'yahoo.com.sh',
    'yahoo.com.si',
    'yahoo.com.sq',
    'yahoo.com.su',
    'yahoo.com.sv',
    'yahoo.com.te',
    'yahoo.com.th',
    'yahoo.com.tn',
    'yahoo.com.tr',
    'yahoo.com.tw',
    'yahoo.com.ua',
    'yahoo.com.uk',
    'yahoo.com.ve',
    'yahoo.com.vn',
    'yahoo.com.vr',
    'yahoo.com.vu',
    'yahoo.com.we',
    'yahoo.com.wrong',
    'yahoo.com.xm',
    'yahoo.com.ytw',
    'yahoo.com.yw',
    'yahoo.com.za',
    'yahoo.coma',
    'yahoo.comablanc',
    'yahoo.comaddress',
    'yahoo.comaggy',
    'yahoo.comahb',
    'yahoo.comam',
    'yahoo.comar',
    'yahoo.comasek',
    'yahoo.comate',
    'yahoo.comau',
    'yahoo.comb',
    'yahoo.comb.br',
    'yahoo.combb',
    'yahoo.combe',
    'yahoo.combef',
    'yahoo.comboss',
    'yahoo.combr',
    'yahoo.combut',
    'yahoo.comc',
    'yahoo.comcbm',
    'yahoo.comccf',
    'yahoo.comceci',
    'yahoo.comcfc',
    'yahoo.comco.in',
    'yahoo.comco.uk',
    'yahoo.comcom',
    'yahoo.comdawudurichard',
    'yahoo.comdcaresaboutyourmon',
    'yahoo.comddress',
    'yahoo.comde',
    'yahoo.comdicky',
    'yahoo.comdmc',
    'yahoo.comdmx',
    'yahoo.comdxy',
    'yahoo.come',
    'yahoo.comeest',
    'yahoo.comefm',
    'yahoo.comenlace',
    'yahoo.comess',
    'yahoo.comex',
    'yahoo.comf',
    'yahoo.comfc',
    'yahoo.comfdf',
    'yahoo.comfebird',
    'yahoo.comfee',
    'yahoo.comfew',
    'yahoo.comfgm',
    'yahoo.comflowergoddess87aol.comtoylandwoodsonyahoo.com',
    'yahoo.comfym',
    'yahoo.comg',
    'yahoo.comgf',
    'yahoo.comgfm',
    'yahoo.comgmail',
    'yahoo.comgmm',
    'yahoo.comgq',
    'yahoo.comh',
    'yahoo.comhk',
    'yahoo.comi',
    'yahoo.comin',
    'yahoo.coming',
    'yahoo.comipf',
    'yahoo.comj',
    'yahoo.comjnavarroasiyahoo.com',
    'yahoo.comjregulacion',
    'yahoo.comk',
    'yahoo.comkaren',
    'yahoo.comkbaria',
    'yahoo.comkenbenkedrickyahoo.com',
    'yahoo.comknox',
    'yahoo.comko',
    'yahoo.comkuwait.mcc',
    'yahoo.coml',
    'yahoo.coml.com',
    'yahoo.coml.hk',
    'yahoo.comm',
    'yahoo.comm.au',
    'yahoo.comm.br',
    'yahoo.comm.mx',
    'yahoo.comm.or',
    'yahoo.commagdyr',
    'yahoo.comman',
    'yahoo.commau',
    'yahoo.commgb',
    'yahoo.commmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm',
    'yahoo.commune',
    'yahoo.commx',
    'yahoo.comn',
    'yahoo.comn.au',
    'yahoo.comn.br',
    'yahoo.comna',
    'yahoo.comnyca',
    'yahoo.como',
    'yahoo.como.hk',
    'yahoo.como.mx',
    'yahoo.comob',
    'yahoo.comom',
    'yahoo.comoo.com',
    'yahoo.comor',
    'yahoo.comp',
    'yahoo.companianimaugaot',
    'yahoo.company',
    'yahoo.compbr',
    'yahoo.comph',
    'yahoo.comq',
    'yahoo.comr',
    'yahoo.comra',
    'yahoo.comrmostafafa',
    'yahoo.comronwrnr',
    'yahoo.comrr',
    'yahoo.comsg',
    'yahoo.comsh',
    'yahoo.comt',
    'yahoo.comtext',
    'yahoo.comthe',
    'yahoo.comtw',
    'yahoo.comuk',
    'yahoo.comv',
    'yahoo.comvau',
    'yahoo.comvn',
    'yahoo.comvv',
    'yahoo.comw',
    'yahoo.comwdg',
    'yahoo.comwgx',
    'yahoo.comwin',
    'yahoo.comwme',
    'yahoo.comwxm',
    'yahoo.comx',
    'yahoo.comxcw',
    'yahoo.comxdw',
    'yahoo.comxx',
    'yahoo.comy',
    'yahoo.comyah',
    'yahoo.comyef',
    'yahoo.comyyf',
    'yahoo.comz',
    'yahoo.con',
    'yahoo.con.ar',
    'yahoo.con.au',
    'yahoo.con.br',
    'yahoo.con.hk',
    'yahoo.con.mx',
    'yahoo.con.ph',
    'yahoo.con.tw',
    'yahoo.conid',
    'yahoo.conm',
    'yahoo.conm.co',
    'yahoo.conz',
    'yahoo.coo',
    'yahoo.coo.jp',
    'yahoo.coo.uk',
    'yahoo.coom',
    'yahoo.coom.br',
    'yahoo.coomau',
    'yahoo.cop',
    'yahoo.copm',
    'yahoo.copm.br',
    'yahoo.corm.br',
    'yahoo.corn',
    'yahoo.correo.mx',
    'yahoo.cos',
    'yahoo.cou.uk',
    'yahoo.couk',
    'yahoo.coul',
    'yahoo.coyoum',
    'yahoo.cp',
    'yahoo.cp.in',
    'yahoo.cp.jp',
    'yahoo.cp.uk',
    'yahoo.cpc',
    'yahoo.cpm',
    'yahoo.cpm.br',
    'yahoo.cpm.mx',
    'yahoo.cpm.ph',
    'yahoo.cpom',
    'yahoo.cq',
    'yahoo.cs',
    'yahoo.cu',
    'yahoo.cu.uk',
    'yahoo.cum',
    'yahoo.cum.au',
    'yahoo.cvb',
    'yahoo.cvom',
    'yahoo.cx',
    'yahoo.cxo.id',
    'yahoo.cxom',
    'yahoo.cyom',
    'yahoo.cz',
    'yahoo.dcom',
    'yahoo.de',
    'yahoo.deh',
    'yahoo.dei',
    'yahoo.dk',
    'yahoo.dom',
    'yahoo.dot',
    'yahoo.dr',
    'yahoo.eamil',
    'yahoo.eas',
    'yahoo.ec',
    'yahoo.edu',
    'yahoo.edu.br',
    'yahoo.ee',
    'yahoo.ei',
    'yahoo.em',
    'yahoo.email',
    'yahoo.eo',
    'yahoo.es',
    'yahoo.es.com',
    'yahoo.es.readnotify.com',
    'yahoo.es.un',
    'yahoo.esd',
    'yahoo.ese',
    'yahoo.esf',
    'yahoo.esg',
    'yahoo.especially',
    'yahoo.ess',
    'yahoo.esw',
    'yahoo.et',
    'yahoo.etc',
    'yahoo.ew',
    'yahoo.ews',
    'yahoo.ex',
    'yahoo.fcom',
    'yahoo.fcycom',
    'yahoo.fe',
    'yahoo.fer',
    'yahoo.ff',
    'yahoo.ffr',
    'yahoo.fi',
    'yahoo.fl',
    'yahoo.fo.uk',
    'yahoo.fr',
    'yahoo.fr.centerblog.net',
    'yahoo.fr.com',
    'yahoo.frcyc',
    'yahoo.frdfc',
    'yahoo.fre',
    'yahoo.frf',
    'yahoo.frkhe',
    'yahoo.frl',
    'yahoo.frmfg',
    'yahoo.frr',
    'yahoo.frt',
    'yahoo.frw',
    'yahoo.frz',
    'yahoo.ft',
    'yahoo.ftr',
    'yahoo.fy',
    'yahoo.fz',
    'yahoo.gfr',
    'yahoo.gmail',
    'yahoo.gmail.com',
    'yahoo.go.id',
    'yahoo.go.uk',
    'yahoo.gom',
    'yahoo.gov',
    'yahoo.gov.ph',
    'yahoo.gpm',
    'yahoo.gr',
    'yahoo.gr.com',
    'yahoo.gv',
    'yahoo.hk',
    'yahoo.hoo',
    'yahoo.hotmail',
    'yahoo.hr',
    'yahoo.hu',
    'yahoo.icloud.com',
    'yahoo.id',
    'yahoo.id.co',
    'yahoo.id.com',
    'yahoo.ie',
    'yahoo.iedgm',
    'yahoo.iel',
    'yahoo.if',
    'yahoo.ih',
    'yahoo.iie',
    'yahoo.ijnh',
    'yahoo.in',
    'yahoo.inejas.patel',
    'yahoo.ini',
    'yahoo.ir',
    'yahoo.it',
    'yahoo.itw',
    'yahoo.iw',
    'yahoo.iy',
    'yahoo.jaim.at',
    'yahoo.jp',
    'yahoo.k12.ms.us',
    'yahoo.khanwaiz',
    'yahoo.knox',
    'yahoo.kom',
    'yahoo.lcom',
    'yahoo.lcom.br',
    'yahoo.les',
    'yahoo.live',
    'yahoo.lk',
    'yahoo.ln',
    'yahoo.lt',
    'yahoo.m.au',
    'yahoo.mail',
    'yahoo.mail.com',
    'yahoo.mco',
    'yahoo.me',
    'yahoo.mhs.ee.co.id',
    'yahoo.mo',
    'yahoo.mom',
    'yahoo.mu',
    'yahoo.mx',
    'yahoo.mx.com',
    'yahoo.my',
    'yahoo.ncom',
    'yahoo.ne.jp',
    'yahoo.net',
    'yahoo.nl',
    'yahoo.no',
    'yahoo.nz',
    'yahoo.o.id',
    'yahoo.o.in',
    'yahoo.o.uk',
    'yahoo.o.ukcew',
    'yahoo.oc',
    'yahoo.oc.uk',
    'yahoo.ocm',
    'yahoo.ocm.br',
    'yahoo.ocm.mx',
    'yahoo.ocom',
    'yahoo.ocom.hk',
    'yahoo.om',
    'yahoo.om.ar',
    'yahoo.om.au',
    'yahoo.om.br',
    'yahoo.om.hk',
    'yahoo.om.mx',
    'yahoo.om.sg',
    'yahoo.omc',
    'yahoo.oml',
    'yahoo.oms.br',
    'yahoo.on',
    'yahoo.oom',
    'yahoo.oom.hk',
    'yahoo.or.ar',
    'yahoo.or.id',
    'yahoo.org',
    'yahoo.ph',
    'yahoo.pl',
    'yahoo.pm',
    'yahoo.pt',
    'yahoo.rcom',
    'yahoo.rd',
    'yahoo.rf',
    'yahoo.ro',
    'yahoo.rp',
    'yahoo.rs',
    'yahoo.ru',
    'yahoo.sa.in',
    'yahoo.sdf',
    'yahoo.se',
    'yahoo.sg',
    'yahoo.sg.com',
    'yahoo.sina.com',
    'yahoo.sk',
    'yahoo.sn',
    'yahoo.so.uk',
    'yahoo.som',
    'yahoo.sv',
    'yahoo.sw',
    'yahoo.taufika62002co.id',
    'yahoo.tig',
    'yahoo.tn',
    'yahoo.tr',
    'yahoo.tr.com',
    'yahoo.uk',
    'yahoo.uk.co',
    'yahoo.uk.com',
    'yahoo.us',
    'yahoo.va',
    'yahoo.vcom',
    'yahoo.vn',
    'yahoo.vnn.vn',
    'yahoo.vo',
    'yahoo.vo.nx',
    'yahoo.vo.uk',
    'yahoo.vom',
    'yahoo.vom.au',
    'yahoo.vom.br',
    'yahoo.vom.mx',
    'yahoo.vom.tw',
    'yahoo.vom.vn',
    'yahoo.von',
    'yahoo.vpm',
    'yahoo.wa',
    'yahoo.wn',
    'yahoo.xcom',
    'yahoo.xim',
    'yahoo.xo.id',
    'yahoo.xom',
    'yahoo.xom.br',
    'yahoo.xon',
    'yahoo.yahoo.com',
    'yahoo.ycom',
    'yahoo.yxf',
    'yahoo.za',
    'yahoo.za.za',
    'yahoo.zaq',
    'yahoogmail.com',
    'yahoogroup.com',
    'yahoogroupd.com',
    'yahoogroupes.fr',
    'yahoogroupjs.com',
    'yahoogroups.co.in',
    'yahoogroups.co.uk',
    'yahoogroups.com',
    'yahoogroups.com.au',
    'yahoogroups.com.sg',
    'yahoogroups.de',
    'yahoogroups.jp',
    'yahoogrupos.com.br',
    'yahoogrupos.com.mx',
    'yahoomail.ca',
    'yahoomail.co.id',
    'yahoomail.co.in',
    'yahoomail.co.uk',
    'yahoomail.com',
    'yahoomail.com.br',
    'yahoomail.fr',
    'yahoomail.in',
    'yampabay.rr.com',
    'yandex.com',
    'yandex.ru',
    'yao.yahoo.fr',
    'yayahoo.com',
    'yc.gmail.com',
    'ycap.rr.com',
    'yeah.net',
    'yf5yahoo.com',
    'ygmail.com',
    'yibakenimiyahoo.com',
    'ymail.com',
    'yopmail.com',
    'ytampabay.rr.com',
    'ytgmail.com',
    'ytrh.gmail.com',
    'ywcny.rr.com',
    'yx.rr.com',
    'yyahoo.co.id',
    'yyahoo.co.uk',
    'yyahoo.com',
    'yyahoo.com.br',
    'yyahoo.com.my',
    'yyahoo.fr',
    'yyahoo.in',
    'zahav.net.il',
    'zahev.net.il',
    'ziggo.nl',
    'zigmail.com',
    'zigmail.com.br',
    'zippy-au.com',
    'zippy-nz.com',
    'zippy-uk.com',
    'ziza.ru',
    'zkbgmail.com',
    'zrgmail.com',
]);
