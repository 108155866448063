import axiosInstance from './Axios.instance';

const SHARING_DOMAIN_ALLOWLIST = '1833990597437316';

class ConfigSettingClient {
    private static url = 'configsetting';

    public async getSafeSharingEnabledForUserOrg(): Promise<boolean> {
        const response = await axiosInstance.get<{
            id: number; // Technically long, but we don't have support for longs yet.
            valueString?: string;
            valueLong?: number; // Long obviously, but we don't have support for longs yet.
            valueDouble?: number;
            valueBoolean?: boolean;
            valueDateTime?: Date;
        }>(`${ConfigSettingClient.url}/${SHARING_DOMAIN_ALLOWLIST}`);
        return response.data.valueBoolean ?? false;
    }
}

const configSettingClient = new ConfigSettingClient();
export default configSettingClient;
