// Adapted from app-core
/**
 * A menu list component. Takes MenuItem and MenuDivider components as children.
 *
 * Sample usage:
 * <MenuList className="sampleMenu" defaultFocusedIndex={2} maxWidth={250}>
 *   <MenuItem labelStringId="lbl_gridView" inlineControls={filterItemControls} icon={sharedIcon} />
 *   <MenuItem labelStringId="lbl_allLevles" onClick={() => alert("foobar")} />
 *   <MenuItem labelStringId="lbl_disabled" icon={gridIcon} isEnabled={false} />
 *   <MenuDivider />
 *   <MenuItem labelStringId="lbl_newFilter />
 * </MenuList>
 */

import * as React from 'react';
import { AutomationIds } from '../../../../common/enums/AutomationElements.enum';
import { MenuItem, MenuItemProps } from './MenuItem';

interface Props {
    children: React.ReactNode;
    className?: string;

    /** Index of item that is focused/highlighted by default when the menu is shown */
    defaultFocusedIndex?: number;
    /** Max height of menu list. A vertical scrollbar will appear when the list exceeds the specified height */
    maxHeight?: number;
    /** Min width of menu list */
    minWidth?: number;
    /** Max width of menu list. Will truncate items that exceed the specified width */
    maxWidth?: number;
    /** Opt-in to keyboard accessibility. Currently only supported in React contexts */
    keyboardAccessible?: boolean;
    /** Callback to restore focus if user closes with Escape */
    onClose?: () => void;
    dataClientType: string;
}

interface State {
    focusedIndex?: any;
}

export class MenuList extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);
        let focusedIndex = props.defaultFocusedIndex;
        if (!focusedIndex && props.keyboardAccessible) {
            focusedIndex = 0;
        }
        this.state = { focusedIndex };
    }

    /**
     * Set the focused item to the value of the item we are currently hovering over
     */
    private handleItemHover = (value?: number): void => {
        if (value !== this.state.focusedIndex) {
            this.setState({focusedIndex: value});
        }
    }

    /**
     * If the cursor leaves the menu list, clear the focused item
     */
    private handleMouseLeave = (): void => {
        this.setState({focusedIndex: undefined});
    }

    /**
     * Checks if the given element is an instance of MenuItem and is not disabled
     */
    private isEnabledMenuItem(element: JSX.Element): boolean {
        return element !== null && element.type === MenuItem && element.props.isEnabled !== false;
    }

    /**
     * Checks if the nth child is an instance of MenuItem is not disabled
     */
    private isEnabledMenuItemIndex(targetIndex: number): boolean {
        let isEnabled: boolean = false;
        React.Children.forEach(this.props.children,
            (child: JSX.Element, childIndex: number) => {
                if (childIndex === targetIndex && this.isEnabledMenuItem(child)) {
                    isEnabled = true;
                }
            });
        return isEnabled;
    }

    /**
     * Clone the MenuItem element and pass it event handlers and hasFocus prop
     *
     * @param menuItem - The MenuItem element to clone
     * @param index - The index of the menu item in the menu list
     */
    private cloneMenuItem(menuItem: React.ReactElement<MenuItemProps>, index: number): React.ReactElement<MenuItemProps> {
        const hasFocus = index === this.state.focusedIndex;
        const newProps = {
            hasFocus,
            onMouseEnter: this.handleItemHover.bind(this, index),
            onMouseLeave: this.handleItemHover.bind(this, undefined),
            onKeyDown: this.onMenuItemKeyDown,
        };
        return React.cloneElement(menuItem, newProps);
    }

    /**
     * Handle keyboard event on menu item
     * Enter & Space are handled by the child MenuItem and won't be seen here
     */
    private onMenuItemKeyDown = (e: React.KeyboardEvent) => {
        if (this.props.keyboardAccessible) {
            switch (e.key) {
                case 'Escape':                      // Close the menu and restore focus
                    e.preventDefault();
                    e.stopPropagation();
                    if (this.props.onClose) {
                        this.props.onClose();
                    }
                    break;

                case 'Tab':                         // Move to the next/prev menu item
                    e.preventDefault();
                    this.focusNextMenuItem(! e.shiftKey);
                    break;

                case 'ArrowUp':                     // Move to prev menu item
                    this.focusNextMenuItem(false);
                    break;

                case 'ArrowDown':                   // Move to next menu item
                    this.focusNextMenuItem(true);
                    break;

                default:
            }
        }
    }

    /**
     * Move to the next or previous menu item, skipping disabled entries, and looping around the beginning/end
     */
    private focusNextMenuItem(moveForward: boolean): void {
        const childCount: number = React.Children.count(this.props.children);
        const { focusedIndex } = this.state;

        let index = focusedIndex;

        while (true) {
            if (moveForward) {
                index++;
                if (index >= childCount) {
                    index = 0;
                }
            } else {
                index--;
                if (index < 0) {
                    index = childCount - 1;
                }
            }

            // Stop looking when we find an enabled item or have gone full circle
            if (this.isEnabledMenuItemIndex(index) || index === focusedIndex) {
                break;
            }
        }

        if (index !== focusedIndex) {                       // Did we change from initial condition?
            this.setState({ focusedIndex: index });
        }
    }

    public render(): React.ReactNode {
        const { children, maxHeight, maxWidth, minWidth } = this.props;

        return (
            <ul
                className={'menu'}
                onMouseLeave={this.handleMouseLeave}
                style={{ maxHeight, minWidth, maxWidth }}
                data-client-type={this.props.dataClientType}
                data-client-id={AutomationIds.FILTER_LIST_CONTAINER}
            >
                {React.Children.map(children, (child: JSX.Element, index: number) => {
                    // Pass additional props to MenuItem children that are enabled
                    if (this.isEnabledMenuItem(child)) {
                        return this.cloneMenuItem(child, index);
                    }
                    return child;
                })}
            </ul>
        );
    }
}
