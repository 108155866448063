export const getImageUrl = (imageId: string, imageProxyUrl: string): string => {
    if (!imageId) {
        return '';
    }
    let url: string;
    const idx: number = imageId.indexOf('http');
    if (idx !== -1) {
        url = imageId.slice(idx);
    } else {
        url = `${imageProxyUrl}/${imageId}`;
    }
    return url;
};
