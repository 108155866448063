import * as React from 'react';
import './SectionTitle.css';

interface Props {
    title: string;
    subtitle?: string;
    selectedCount?: number;
}

const sectionTitle: React.SFC<Props> = props => {
    let title = props.title;
    if (props.selectedCount && props.selectedCount > 0) {
        title = `${props.title} (${props.selectedCount})`;
    }
    return (
        <div className="section-title">
            <h4>{title}</h4>
            {props.subtitle && <p>{props.subtitle}</p>}
        </div>
    );
};

export default sectionTitle;
