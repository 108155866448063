import styled from '@emotion/styled';
import { Avatar, colors, sizes } from '@smartsheet/lodestar-core';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { UserAnalyticsGlobalContext } from '../../../common/metrics/UserAnalyticsGlobalContext';
import { getUserInitials } from '../../../common/utils/GetUserInitials';
import { profilePicUrlSelector, userEmailSelector, userFirstLastNameSelector } from '../../../containers/Auth/Selectors';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';
import { userService } from '../../../services/User.service';
import { StoreState } from '../../../store';
import OutsideClickListener from '../../hoc/OutsideClickListener';

const ControlId = {
    MENU: 'tam-1',
    MENU_ITEM: 'mim-1',
};

const AccountMenu: React.FC = () => {
    const profileImgUrl = useSelector((state: StoreState) => profilePicUrlSelector(state));
    const userEmail = useSelector((state: StoreState) => userEmailSelector(state));
    const userFirstLastName = useSelector((state: StoreState) => userFirstLastNameSelector(state));

    const userInitials: string = getUserInitials(userFirstLastName);
    const [showAccountSignOut, setShowAccountSignOut] = React.useState(false);
    const languageElements = useLanguageElements();

    const toggleAccountMenu = () => {
        setShowAccountSignOut((prevShowAccountSignOut) => !prevShowAccountSignOut);
    };

    const handleLogout = async (event: React.MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => {
        event.preventDefault();

        UserAnalyticsGlobalContext.removeAll();
        await userService.handleLogOut();

        window.location.pathname = '/';
    };

    return (
        <Container
            onClick={toggleAccountMenu}
            close={() => {
                setShowAccountSignOut(false);
            }}
        >
            <AvatarHover
                size="medium"
                email={userEmail}
                initials={userInitials}
                src={profileImgUrl}
                clientId={ControlId.MENU}
                onClick={toggleAccountMenu}
            />
            {showAccountSignOut && (
                <AccountMenuItems
                    to="/"
                    onClick={(e) => {
                        handleLogout(e);
                    }}
                    data-client-id={ControlId.MENU_ITEM}
                >
                    <Label>{languageElements.SIGN_OUT}</Label>
                    <Subtext>{userEmail || null}</Subtext>
                </AccountMenuItems>
            )}
        </Container>
    );
};

export default AccountMenu;

const Container = styled(OutsideClickListener)`
    display: flex;
    align-items: center;
`;

const AvatarHover = styled(Avatar)`
    :hover {
        cursor: pointer;
    }
`;

const AccountMenuItems = styled(NavLink)`
    background-color: ${colors.neutralLight40};
    color: ${colors.neutralDark30};
    position: fixed;
    top: calc(var(--headerHeight) - ${sizes.xxSmall}px);
    right: ${sizes.xxSmall}px;
    left: auto;
    width: 228px;
    margin: 0;
    padding: ${sizes.xSmall}px;
    border-radius: 2px;
    box-shadow: 0 1px ${sizes.xxSmall}px 0 rgba(0, 0, 0, 0.2);
    text-decoration: none;
    list-style-type: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    :hover {
        cursor: pointer;
    }
`;

const Label = styled.div`
    font-size: 13px;
`;

const Subtext = styled.div`
    font-size: 11px;
`;
