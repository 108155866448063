import * as dv from '../../../../common/interfaces';

export interface CellForApplyCondition {
    objectValue: dv.CellObjectValue;
    displayValue: string | undefined;
}

export const transformCellForApplyCondition = (cell: dv.Cell | undefined): CellForApplyCondition => {
    if (!cell) {
        return {
            objectValue: undefined,
            displayValue: undefined,
        };
    }

    return {
        objectValue: cell.objectValue || cell.value,
        displayValue: cell.displayValue,
    };
};
