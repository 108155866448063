/** @jsx jsx  */
import { jsx } from '@emotion/core';
import { MAX_SOURCEAPP_CHARS, REGEX_PATTERN_SOURCE_APP } from '../../common/constants';
import { ViewSourceType } from '../../common/enums';
import { CreateView } from '../../common/interfaces';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Actions as AppActions } from '../../containers/App/Actions';
import { homeSourceFilterSelector } from '../../containers/App/Selectors';
import { Actions as HomeActions } from '../../containers/Home/Actions';
import viewClient from '../../http-clients/View.client';
import { viewSourceClient } from '../../http-clients/ViewSource.client';
import { useLanguageElements } from '../../language-elements/withLanguageElementsHOC';

const ControlID = {
    CREATE_VIEW_ROUTE_HANDLER: 'cvrh-1',
};

interface Props {
    viewSourceId?: number;
    isReport?: boolean;
    sourceApp?: string;
    displaySheetPicker: () => void;
}

const CreateViewRouteHandler = (props: Props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const languageElements = useLanguageElements();
    const homeSourceFilter = useSelector(homeSourceFilterSelector);

    // Assign parameters
    let viewSourceId: number;
    let isReport: boolean;
    let sourceApp: string | undefined;

    const queryParams = new URLSearchParams(location.search);

    // if component is called with props: viewSourceId and isReport use those otherwise lookup from URL
    if (props.viewSourceId && props.isReport !== undefined) {
        viewSourceId = props.viewSourceId;
        isReport = props.isReport;
    } else {
        const viewSourceIdParamValue = queryParams.get('id');
        const isReportParamValue = queryParams.get('isreport');

        viewSourceId = viewSourceIdParamValue ? parseInt(viewSourceIdParamValue, 10) : -1;
        isReport = Boolean(isReportParamValue?.toLowerCase() === 'true');
    }
    // if component is called with props: sourceApp use those otherwise lookup from URL
    if (props.sourceApp) {
        sourceApp = props.sourceApp;
    } else {
        const sourceParamValue = queryParams.get('source');
        sourceApp =
            sourceParamValue && sourceParamValue.length <= MAX_SOURCEAPP_CHARS && sourceParamValue.match(REGEX_PATTERN_SOURCE_APP)
                ? sourceParamValue
                : undefined;
    }

    useEffect(() => {
        // Look up a name for the viewSource, create the view and redirect to builder screen
        const createNewView = async () => {
            {
                // Validate Parameters
                if (isNaN(viewSourceId) || viewSourceId < 0) {
                    throw new Error(languageElements.ERRORSTATE_BAD_REQUEST_TITLE);
                }

                // Lookup viewSource name
                const viewSourceName = await viewSourceClient.getViewSourceName(viewSourceId, isReport);
                const newView: CreateView = {
                    name: viewSourceName.data,
                    viewSourceType: isReport ? ViewSourceType.REPORT : ViewSourceType.SHEET,
                    viewSourceId,
                    sourceApp,
                };

                /* Create view and redirect to builder screen or handle viewCreate error
                 Handling error: If user is not an admin on the sheet and tries to create a view
                    a. when homesourceFilter is already and action is from appCore->manage : 
                      - catch an error
                      - rerender parent component which in turn will render sheet picker component
                    b. when no homeSourceFilter is set and action is from appCore->create
                      - catch an error
                      - display toast
                      -redirect to home page                
                 */
                viewClient
                    .create(newView)
                    .then((response) => {
                        const view = response.data;
                        dispatch(HomeActions.fetchHomeData());
                        history.push(`/views/${view.id!}/admin/basic`);
                    })
                    .catch(() => {
                        if (homeSourceFilter) {
                            props.displaySheetPicker();
                        } else {
                            dispatch(HomeActions.showPermissionsError(true));
                            history.push(`/`);
                        }
                    });
            }
        };
        createNewView().catch((error) => {
            history.push(`/`);
            dispatch(AppActions.setAppStageError(error));
        });
    }, [dispatch, isReport, viewSourceId, homeSourceFilter]);

    return <div className={'CreateView'} data-client-id={ControlID.CREATE_VIEW_ROUTE_HANDLER} data-testid="cvrh-test-1" />;
};

export default CreateViewRouteHandler;
