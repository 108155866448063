export enum ConfigProperty {
    DYNAMIC_VIEW_FEATURE_FLAGS = 5994542596941700, // Unmasked id value: 1621,
    SHARING_DOMAIN_ALLOWLIST = 1833990597437316, // Unmasked id value: 1068,
}

export function parseConfigProperty(configProperty: number): ConfigProperty {
    if (configProperty === ConfigProperty.DYNAMIC_VIEW_FEATURE_FLAGS) {
        return ConfigProperty.DYNAMIC_VIEW_FEATURE_FLAGS;
    }

    throw new Error(`${configProperty} is not a known ConfigProperty`);
}
