import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { colors } from '@smartsheet/lodestar-core';
import { MAX_CELL_VALUE_CHARS } from '../../common/constants';
import { ColumnType, SymbolSetName } from '../../common/enums';
import { CellObjectValue, Contact, ContactObjectValue, FormFieldInterface, PicklistItem } from '../../common/interfaces';
import * as React from 'react';
import 'reflect-metadata';
import { AutomationIds, AutomationTypes } from '../../common/enums/AutomationElements.enum';
import { getOptions, getSelectedItem } from '../../common/utils/GetOptions';
import { CellImageAttachment } from '../../containers/View/Details/CellImage';
import { CellImages } from '../../containers/View/Details/CellImage/CellImages';
import FormFieldMenu from '../Buttons/FormFieldMenu/FormFieldMenu';
import TextNumber from '../TextNumber/TextNumber';
import FormFieldCheckbox from './FormFieldCheckbox';
import FormFieldContactList from './FormFieldContactList';
import FormFieldDate from './FormFieldDate';
import FormFieldHyperlink from './FormFieldHyperlink';
import FormFieldImage from './FormFieldImage';
import FormFieldMultiContactList from './FormFieldMultiContactList';
import FormFieldMultiPicklist from './FormFieldMultiPicklist';
import FormFieldPicklist from './FormFieldPicklist';
import FormFieldSymbolPicklist from './FormFieldSymbolPicklist';
import FormFieldTextNumber from './FormFieldTextNumber';

export interface FormFieldWrapperProps extends FormFieldInterface {
    inputIndex: number;
    field?: FormFieldInterface;
    validation?: boolean;
    cellImages?: CellImages;
    onInsertImage?: (field: FormFieldInterface, file: File) => void;
    onClearContent?: (field: FormFieldInterface) => void;
    onSetImageUrlFields?: (field: FormFieldInterface) => void;
    onAltTextChange?: (field: FormFieldInterface, altText: string) => void;
    onChange?: (columnId: number, value: unknown) => void;
    setFocusRef?: (ref: Element | null) => void;
    isSettingsMode: boolean;
    isNewSubmission: boolean;
    onGetContactListValue?: (value: CellObjectValue | ContactObjectValue, substituteCurrentUser: boolean, contacts?: Contact[]) => null | Contact;
    onGetMultiContactsIfValid?: (
        initialValue: CellObjectValue,
        name?: string,
        substituteCurrentUser?: boolean,
        contacts?: Contact[]
    ) => Contact[] | string | null;
    detailsDataRef?: React.RefObject<HTMLElement>;
}

const FormFieldWrapper = ({
    field,
    columnId,
    value,
    displayValue,
    objectValue,
    inputIndex,
    cellImages,
    thumbnailUrl,
    image,
    format,
    hyperlink,
    type,
    options,
    multiline,
    symbol,
    placeholder,
    customLabel,
    title,
    description,
    isSettingsMode,
    isNewSubmission,
    required,
    validation,
    readOnly,
    contactOptions,
    systemColumnType,
    onChange,
    onInsertImage,
    onSetImageUrlFields,
    onClearContent,
    onGetContactListValue,
    onGetMultiContactsIfValid,
    setFocusRef,
    detailsDataRef,
}: FormFieldWrapperProps) => {
    const initattachedCellImage = cellImages?.attachedForUpload.get(columnId);
    const [attachedCellImage, setAttachedCellImage] = React.useState(initattachedCellImage);
    const handleChange = (newValue: unknown): void => {
        onChange?.(columnId, newValue);
    };

    const handleClearContent = () => {
        if (onClearContent && field) {
            onClearContent(field);
        }
    };

    React.useEffect(() => {
        setAttachedCellImage(cellImages?.attachedForUpload.get(columnId));
    }, [cellImages, columnId]);

    let cellImageAttachmentRef: HTMLInputElement | null;

    const handleChangeAltText = (altText: string) => {
        onChange?.(columnId, altText);
    };

    const handleSelectImage = () => cellImageAttachmentRef && cellImageAttachmentRef.click();

    const handleImageAttachment = (file: File) => {
        onInsertImage?.(field as FormFieldInterface, file);
        setAttachedCellImage(file);
    };

    const renderInput = () => {
        if (attachedCellImage || image) {
            if (onSetImageUrlFields && field) {
                onSetImageUrlFields(field);
            }
            return (
                <FormFieldImage
                    image={image}
                    onChangeAltText={handleChangeAltText}
                    attachedImage={attachedCellImage}
                    imageUrl={field?.imageUrl}
                    thumbnailUrl={thumbnailUrl}
                />
            );
        }

        // If cell data contains a hyperlink object, then display it as clickable read-only link, regardless of column type
        if (hyperlink && hyperlink.url) {
            return <FormFieldHyperlink hyperlink={hyperlink} inputIndex={inputIndex} value={value as string} />;
        }

        switch (type) {
            case ColumnType.TEXT_NUMBER:
            case ColumnType.DURATION:
                return (
                    <FormFieldTextNumber
                        inputIndex={inputIndex}
                        value={value as string}
                        format={format}
                        multiline={multiline}
                        readOnly={readOnly}
                        displayValue={displayValue}
                        objectValue={objectValue}
                        onChange={handleChange}
                    />
                );

            case ColumnType.PICKLIST:
                const picklistOptions = getOptions(type, symbol as SymbolSetName, options);
                const selectedPicklistOption = getSelectedItem(options, symbol as SymbolSetName, value as string) as PicklistItem;
                const isSymbol = picklistOptions?.some((option: PicklistItem) => option.symbol) || selectedPicklistOption?.symbol;

                if (isSymbol) {
                    return (
                        <FormFieldSymbolPicklist
                            dataClientId={AutomationIds.FORM_FIELD_WRAPPER_PICKLSIT}
                            inputIndex={inputIndex}
                            options={picklistOptions}
                            selectedOption={selectedPicklistOption}
                            placeholder={placeholder}
                            onChange={handleChange}
                            readOnly={readOnly}
                            validation={validation}
                            isSettingsMode={isSettingsMode}
                            detailsDataRef={detailsDataRef}
                        />
                    );
                }
                return (
                    <FormFieldPicklist
                        dataClientId={AutomationIds.FORM_FIELD_WRAPPER_PICKLSIT}
                        inputIndex={inputIndex}
                        options={picklistOptions}
                        selectedOption={selectedPicklistOption}
                        placeholder={placeholder}
                        onChange={handleChange}
                        readOnly={readOnly}
                        validation={validation}
                        isSettingsMode={isSettingsMode}
                        detailsDataRef={detailsDataRef}
                    />
                );

            case ColumnType.MULTI_PICKLIST:
                return (
                    <FormFieldMultiPicklist
                        dataClientId={AutomationIds.FORM_FIELD_MULTI_PICKLIST}
                        inputIndex={inputIndex}
                        options={options}
                        onChange={handleChange}
                        readOnly={readOnly}
                        placeholder={placeholder}
                        validation={validation}
                        setFocusRef={setFocusRef}
                        isSettingsMode={isSettingsMode}
                        value={value}
                        detailsDataRef={detailsDataRef}
                    />
                );

            case ColumnType.CONTACT_LIST:
                return (
                    <FormFieldContactList
                        value={value}
                        validation={validation}
                        inputIndex={inputIndex}
                        options={contactOptions}
                        onChange={handleChange}
                        readOnly={readOnly}
                        placeholder={placeholder}
                        onClearContent={handleClearContent}
                        isSettingsMode={isSettingsMode}
                        onGetContactListValue={onGetContactListValue}
                    />
                );

            case ColumnType.MULTI_CONTACT_LIST:
                return (
                    <FormFieldMultiContactList
                        inputIndex={inputIndex}
                        value={value}
                        validation={validation}
                        options={contactOptions}
                        placeholder={placeholder}
                        readOnly={readOnly}
                        onChange={handleChange}
                        onClearContent={handleClearContent}
                        isSettingsMode={isSettingsMode}
                        onGetMultiContactsIfValid={onGetMultiContactsIfValid}
                    />
                );

            case ColumnType.ABSTRACT_DATETIME:
            case ColumnType.DATE:
                return <FormFieldDate inputIndex={inputIndex} value={value} onChange={handleChange} readOnly={readOnly} validation={validation} />;

            case ColumnType.CHECKBOX:
                return (
                    <FormFieldCheckbox
                        inputIndex={inputIndex}
                        value={value}
                        onChange={handleChange}
                        readOnly={readOnly}
                        isSettingsMode={isSettingsMode}
                    />
                );

            default:
                return (
                    <TextNumber
                        key={`tni-${inputIndex}`}
                        dataClientId={AutomationIds.FORM_FIELD_WRAPPER_TEXT}
                        value={value || undefined}
                        disabled={true}
                        maxLength={MAX_CELL_VALUE_CHARS}
                        onChange={handleChange}
                    />
                );
        }
    };

    return (
        <Container isSettingsMode={isSettingsMode} data-client-type={AutomationTypes.FORM_FIELD_WRAPPER}>
            {/* This component does not have any visual presence in the UI. */}
            <CellImageAttachment inputRef={(inputElement) => (cellImageAttachmentRef = inputElement)} onSelection={handleImageAttachment} />

            <LabelAndMenuContainer data-client-id={AutomationIds.FORM_FIELD_WRAPPER_ITEM_TOP}>
                <LabelContainer>
                    <LabelStyled>
                        {customLabel || title}
                        {required && (
                            <SpanStyled
                                data-client-id={AutomationIds.FORM_FIELD_WRAPPER_SPAN_REQUIRED}
                                data-testid={AutomationIds.FORM_FIELD_WRAPPER_SPAN_REQUIRED}
                            >
                                {' *'}
                            </SpanStyled>
                        )}
                    </LabelStyled>
                    <div data-client-id={AutomationIds.FORM_FIELD_WRAPPER_DIV_DESC}>
                        <small>{description}</small>
                    </div>
                </LabelContainer>

                {!isSettingsMode && !systemColumnType && !readOnly && (
                    <FormFieldMenu
                        fieldName={customLabel || title}
                        onClearContents={handleClearContent}
                        onSelectImage={handleSelectImage}
                        dataClientId={AutomationIds.FORM_FIELD_MENU_ITEM_IMAGE}
                        validation={validation}
                        isNewSubmission={isNewSubmission}
                    />
                )}
            </LabelAndMenuContainer>
            <InputContainer>{renderInput()}</InputContainer>
        </Container>
    );
};

const dynamicContainerStyles = (props: { isSettingsMode: boolean }) => {
    if (props.isSettingsMode) {
        return;
    }
    return css`
        padding-bottom: 20px;
    `;
};

const Container = styled.div`
    :hover .form-field-menu {
        visibility: visible;
    }

    ${dynamicContainerStyles};
`;

const LabelAndMenuContainer = styled.div`
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
`;

const LabelContainer = styled.div`
    flex-grow: 1;
`;

const LabelStyled = styled.b`
    display: block;
    margin-bottom: 10px;
`;

const SpanStyled = styled.span`
    color: ${colors.danger};
`;

const InputContainer = styled.div`
    position: relative;
    textarea,
    input:only-child {
        font-family: Roboto, sans-serif;
        display: block;
        clear: both;
        width: 100%;
        border-radius: 2px;
        padding: 5px;
        border: solid 1px ${colors.neutral};
        color: ${colors.neutralDark30};
    }
    textarea {
        min-height: 26px;
        resize: vertical;
        box-sizing: border-box;
    }

    input:only-child {
        height: 30px;
        padding: 0 0 0 10px;
        background-color: ${colors.neutralLight40};
    }
    textarea:disabled,
    input:disabled {
        background-color: ${colors.neutralLight20};
        opacity: 1;
    }
`;

export default React.memo(FormFieldWrapper);
