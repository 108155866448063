import { ColumnType, SymbolSetName } from '../enums';
import { PicklistItem, SelectV2Option } from '../interfaces';
import { MultiPicklistObjectValue } from '../interfaces';
import { isMultiPicklistObjectValue } from '../utils';
import { CheckboxOptions, SymbolImageUrlResolver } from '../../components/Picklist/SymbolPickerImages';
import { ColumnDefinition } from '../../containers/Form/Interfaces/ColumnDefinition.interface';

export const getOptions = (
    type: string,
    symbolName: SymbolSetName | undefined,
    optionValues: string[] | undefined
): PicklistItem[] | SelectV2Option[] | undefined => {
    switch (type) {
        case ColumnType.PICKLIST:
            return mapToPicklistOptions(optionValues, symbolName);
        case ColumnType.MULTI_PICKLIST:
            return mapToMultiPicklistOptions(optionValues);
        case ColumnType.CHECKBOX:
            return getCheckboxOptions(symbolName);
    }
    return undefined;
};

function mapToPicklistOptions(optionValues: string[] | undefined, symbolName: SymbolSetName | undefined): PicklistItem[] | undefined {
    if (optionValues == null) {
        return undefined;
    }

    return optionValues.reduce((acc: PicklistItem[], option, index) => {
        const value = symbolName ? `${symbolName}|${option}` : option;
        acc.push(getOption(value, option, symbolName, index));
        return acc;
    }, []);
}

export function mapToMultiPicklistOptions(obj: string[] | MultiPicklistObjectValue | null | undefined): SelectV2Option[] | undefined {
    if (!obj) {
        return undefined;
    }

    let optionValues: string[];
    if (isMultiPicklistObjectValue(obj)) {
        optionValues = obj.values || [];
    } else {
        optionValues = obj as string[];
    }

    return optionValues.reduce((acc: SelectV2Option[], value: string) => {
        if (value) {
            acc.push({ value, label: value });
        }
        return acc;
    }, []);
}

export function mergeMultiSelectOptions(
    multiSelectOptions: SelectV2Option[] = [],
    selectedMultiSelectOptions: SelectV2Option[] = []
): SelectV2Option[] {
    // since all options have the same value and label, we can do this
    // convert SelectV2Option arrays into a string array
    // so we can de-dupe the options using Set
    const options = multiSelectOptions.map((opt) => opt.value);
    const selected = selectedMultiSelectOptions.map((opt) => opt.value);
    const mergedSet = new Set([...options, ...selected]);
    const merged = mapToMultiPicklistOptions([...mergedSet]);
    return merged ? merged : [];
}

function getCheckboxOptions(symbolName: SymbolSetName | undefined): PicklistItem[] {
    const checkboxSetName: SymbolSetName = symbolName || SymbolSetName.NONE;

    return CheckboxOptions.reduce((acc: PicklistItem[], option, index) => {
        const value = `${checkboxSetName}|${option}`;
        acc.push(getOption(value, option, checkboxSetName, index));
        return acc;
    }, []);
}

export const getSelectedItem = (
    options: string[] | undefined,
    symbolName: SymbolSetName | undefined,
    option: string
): PicklistItem | SelectV2Option[] => {
    const index: number = options != null && option != null ? options.indexOf(option.toString()) : -1;

    if (index === -1) {
        return {
            value: option,
            label: option,
        };
    }
    const value: string = symbolName ? `${symbolName}|${option}` : option;
    return getOption(value, option, symbolName, index);
};

const getOption = (value: any, option: string, symbolName: SymbolSetName | undefined, index: number): PicklistItem => {
    const symbol = SymbolImageUrlResolver(symbolName, index);

    return {
        value,
        label: option,
        symbol,
    };
};

// This method is only used for testing and could be removed when Picklist.spec.tsx file is updated to provide options
export const mapColumnToOptions = (columnDef: ColumnDefinition) => {
    return getOptions(columnDef.type, columnDef.symbol as SymbolSetName, columnDef.options);
};
