import styled from '@emotion/styled';
import { Button, Flyout, colors, sizes, useFlyout } from '@smartsheet/lodestar-core';
import * as React from 'react';

interface Props {
    icon: React.ReactNode;
    flyoutText: string;
    dataTestId: string;
    isDisabled?: boolean;
    onClick: () => void;
}

const AdminControlsActionButton: React.FC<Props> = ({ dataTestId, onClick, icon, flyoutText, isDisabled = false }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const { flyoutProps, targetProps } = useFlyout({
        isOpen,
        onCloseRequested: () => setIsOpen(false),
        placement: 'top',
        offset: { distance: 4 },
    });

    const handleOnMouseEnter = () => {
        if (!isDisabled) {
            setIsOpen(true);
        }
    };

    const handleOnMouseLeave = () => {
        if (isOpen) {
            setIsOpen(false);
        }
    };

    return (
        <ButtonContainer data-testid={dataTestId}>
            <Button
                isDisabled={isDisabled}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
                onClick={onClick}
                aria-label={flyoutText}
                appearance="secondary-empty"
                size="medium"
                iconBefore={icon}
                {...targetProps}
            />
            <Flyout {...flyoutProps}>
                <FlyoutText>{flyoutText}</FlyoutText>
            </Flyout>
        </ButtonContainer>
    );
};

const ButtonContainer = styled.div`
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const FlyoutText = styled.span`
    box-shadow: inset 0 0 0.5px 0.5px ${colors.buttonPrimary}, inset 0 0 1.5px 1.5px rgba(53, 118, 186, 0.2);
    line-height: ${sizes.large}px;
    font-weight: 700;
    font-size: 13px;
    color: ${colors.neutralDark30};
    padding: ${sizes.xxSmall}px ${sizes.small}px;
    border-radius: 2px;
    background-color: ${colors.neutralLight40};
`;

export default AdminControlsActionButton;
