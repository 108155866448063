export enum TimeZone {
    AFRICA_CAIRO = 'Africa/Cairo',
    AFRICA_JOHANNESBURG = 'Africa/Johannesburg',
    AFRICA_PORTO_NOVO = 'Africa/Porto-Novo',
    AFRICA_WINDHOEK = 'Africa/Windhoek',
    AMERICA_BOGOTA = 'America/Bogota',
    AMERICA_BUENOS_AIRES = 'America/Buenos_Aires',
    AMERICA_CARACAS = 'America/Caracas',
    AMERICA_GODTHAB = 'America/Godthab',
    AMERICA_GUATEMALA = 'America/Guatemala',
    AMERICA_HALIFAX = 'America/Halifax',
    AMERICA_LA_PAZ = 'America/La_Paz',
    AMERICA_MEXICO_CITY = 'America/Mexico_City',
    AMERICA_MONTEVIDEO = 'America/Montevideo',
    AMERICA_SANTIAGO = 'America/Santiago',
    AMERICA_ST_JOHNS = 'America/St_Johns',
    ASIA_ALMATY = 'Asia/Almaty',
    ASIA_BAKU = 'Asia/Baku',
    ASIA_BANGKOK = 'Asia/Bangkok',
    ASIA_CALCUTTA = 'Asia/Calcutta',
    ASIA_DHAKA = 'Asia/Dhaka',
    ASIA_DUBAI = 'Asia/Dubai',
    ASIA_HONG_KONG = 'Asia/Hong_Kong',
    ASIA_IRKUTSK = 'Asia/Irkutsk',
    ASIA_KABUL = 'Asia/Kabul',
    ASIA_KAMCHATKA = 'Asia/Kamchatka',
    ASIA_KARACHI = 'Asia/Karachi',
    ASIA_KATHMANDU = 'Asia/Kathmandu',
    ASIA_KRASNOYARSK = 'Asia/Krasnoyarsk',
    ASIA_KUALA_LUMPUR = 'Asia/Kuala_Lumpur',
    ASIA_KUWAIT = 'Asia/Kuwait',
    ASIA_MAGADAN = 'Asia/Magadan',
    ASIA_MANILA = 'Asia/Manila',
    ASIA_RANGOON = 'Asia/Rangoon',
    ASIA_RIYADH = 'Asia/Riyadh',
    ASIA_SAIGON = 'Asia/Saigon',
    ASIA_SEOUL = 'Asia/Seoul',
    ASIA_SHANGHAI = 'Asia/Shanghai',
    ASIA_TAIPEI = 'Asia/Taipei',
    ASIA_TASHKENT = 'Asia/Tashkent',
    ASIA_TEHRAN = 'Asia/Tehran',
    ASIA_VLADIVOSTOK = 'Asia/Vladivostok',
    ASIA_YAKUTSK = 'Asia/Yakutsk',
    ASIA_YEKATERINBURG = 'Asia/Yekaterinburg',
    ATLANTIC_AZORES = 'Atlantic/Azores',
    ATLANTIC_CAPE_VERDE = 'Atlantic/Cape_Verde',
    ATLANTIC_SOUTH_GEORGIA = 'Atlantic/South_Georgia',
    AUSTRALIA_ADELAIDE = 'Australia/Adelaide',
    AUSTRALIA_DARWIN = 'Australia/Darwin',
    AUSTRALIA_NSW = 'Australia/NSW',
    AUSTRALIA_PERTH = 'Australia/Perth',
    AUSTRALIA_QUEENSLAND = 'Australia/Queensland',
    BRAZIL_EAST = 'Brazil/East',
    CANADA_SASKATCHEWAN = 'Canada/Saskatchewan',
    EUROPE_AMSTERDAM = 'Europe/Amsterdam',
    EUROPE_BERLIN = 'Europe/Berlin',
    EUROPE_HELSINKI = 'Europe/Helsinki',
    EUROPE_ISTANBUL = 'Europe/Istanbul',
    EUROPE_LISBON = 'Europe/Lisbon',
    EUROPE_MADRID = 'Europe/Madrid',
    EUROPE_MOSCOW = 'Europe/Moscow',
    EUROPE_OSLO = 'Europe/Oslo',
    EUROPE_PARIS = 'Europe/Paris',
    EUROPE_ROME = 'Europe/Rome',
    GREENWICH = 'Greenwich',
    ISRAEL = 'Israel',
    JAPAN = 'Japan',
    MET = 'MET',
    PACIFIC_AUCKLAND = 'Pacific/Auckland',
    PACIFIC_FIJI = 'Pacific/Fiji',
    PACIFIC_GUADALCANAL = 'Pacific/Guadalcanal',
    PACIFIC_TONGATAPU = 'Pacific/Tongatapu',
    PRC = 'PRC',
    SINGAPORE = 'Singapore',
    US_ALASKA = 'US/Alaska',
    US_ALEUTIAN = 'US/Aleutian',
    US_ARIZONA = 'US/Arizona',
    US_CENTRAL = 'US/Central',
    US_EASTERN = 'US/Eastern',
    US_HAWAII = 'US/Hawaii',
    US_Mountain = 'US/Mountain',
    US_PACIFIC = 'US/Pacific',
    US_SAMOA = 'US/Samoa',
}

export const toTimeZone = (timeZone: string) => {
    switch (timeZone) {
        case 'Africa/Cairo':
            return TimeZone.AFRICA_CAIRO;
        case 'Africa/Johannesburg':
            return TimeZone.AFRICA_JOHANNESBURG;
        case 'Africa/Porto-Novo':
            return TimeZone.AFRICA_PORTO_NOVO;
        case 'Africa/Windhoek':
            return TimeZone.AFRICA_WINDHOEK;
        case 'America/Bogota':
            return TimeZone.AMERICA_BOGOTA;
        case 'America/Buenos_Aires':
            return TimeZone.AMERICA_BUENOS_AIRES;
        case 'America/Caracas':
            return TimeZone.AMERICA_CARACAS;
        case 'America/Godthab':
            return TimeZone.AMERICA_GODTHAB;
        case 'America/Guatemala':
            return TimeZone.AMERICA_GUATEMALA;
        case 'America/Halifax':
            return TimeZone.AMERICA_HALIFAX;
        case 'America/La_Paz':
            return TimeZone.AMERICA_LA_PAZ;
        case 'America/Mexico_City':
            return TimeZone.AMERICA_MEXICO_CITY;
        case 'America/Montevideo':
            return TimeZone.AMERICA_MONTEVIDEO;
        case 'America/Santiago':
            return TimeZone.AMERICA_SANTIAGO;
        case 'America/St_Johns':
            return TimeZone.AMERICA_ST_JOHNS;
        case 'Asia/Almaty':
            return TimeZone.ASIA_ALMATY;
        case 'Asia/Baku':
            return TimeZone.ASIA_BAKU;
        case 'Asia/Bangkok':
            return TimeZone.ASIA_BANGKOK;
        case 'Asia/Calcutta':
            return TimeZone.ASIA_CALCUTTA;
        case 'Asia/Dhaka':
            return TimeZone.ASIA_DHAKA;
        case 'Asia/Dubai':
            return TimeZone.ASIA_DUBAI;
        case 'Asia/Hong_Kong':
            return TimeZone.ASIA_HONG_KONG;
        case 'Asia/Irkutsk':
            return TimeZone.ASIA_IRKUTSK;
        case 'Asia/Kabul':
            return TimeZone.ASIA_KABUL;
        case 'Asia/Kamchatka':
            return TimeZone.ASIA_KAMCHATKA;
        case 'Asia/Karachi':
            return TimeZone.ASIA_KARACHI;
        case 'Asia/Kathmandu':
            return TimeZone.ASIA_KATHMANDU;
        case 'Asia/Krasnoyarsk':
            return TimeZone.ASIA_KRASNOYARSK;
        case 'Asia/Kuala_Lumpur':
            return TimeZone.ASIA_KUALA_LUMPUR;
        case 'Asia/Kuwait':
            return TimeZone.ASIA_KUWAIT;
        case 'Asia/Magadan':
            return TimeZone.ASIA_MAGADAN;
        case 'Asia/Manila':
            return TimeZone.ASIA_MANILA;
        case 'Asia/Rangoon':
            return TimeZone.ASIA_RANGOON;
        case 'Asia/Riyadh':
            return TimeZone.ASIA_RIYADH;
        case 'Asia/Saigon':
            return TimeZone.ASIA_SAIGON;
        case 'Asia/Seoul':
            return TimeZone.ASIA_SEOUL;
        case 'Asia/Shanghai':
            return TimeZone.ASIA_SHANGHAI;
        case 'Asia/Taipei':
            return TimeZone.ASIA_TAIPEI;
        case 'Asia/Tashkent':
            return TimeZone.ASIA_TASHKENT;
        case 'Asia/Tehran':
            return TimeZone.ASIA_TEHRAN;
        case 'Asia/Vladivostok':
            return TimeZone.ASIA_VLADIVOSTOK;
        case 'Asia/Yakutsk':
            return TimeZone.ASIA_YAKUTSK;
        case 'Asia/Yekaterinburg':
            return TimeZone.ASIA_YEKATERINBURG;
        case 'Atlantic/Azores':
            return TimeZone.ATLANTIC_AZORES;
        case 'Atlantic/Cape_Verde':
            return TimeZone.ATLANTIC_CAPE_VERDE;
        case 'Atlantic/South_Georgia':
            return TimeZone.ATLANTIC_SOUTH_GEORGIA;
        case 'Australia/Adelaide':
            return TimeZone.AUSTRALIA_ADELAIDE;
        case 'Australia/Darwin':
            return TimeZone.AUSTRALIA_DARWIN;
        case 'Australia/NSW':
            return TimeZone.AUSTRALIA_NSW;
        case 'Australia/Perth':
            return TimeZone.AUSTRALIA_PERTH;
        case 'Australia/Queensland':
            return TimeZone.AUSTRALIA_QUEENSLAND;
        case 'Brazil/East':
            return TimeZone.BRAZIL_EAST;
        case 'Canada/Saskatchewan':
            return TimeZone.CANADA_SASKATCHEWAN;
        case 'Europe/Amsterdam':
            return TimeZone.EUROPE_AMSTERDAM;
        case 'Europe/Berlin':
            return TimeZone.EUROPE_BERLIN;
        case 'Europe/Helsinki':
            return TimeZone.EUROPE_HELSINKI;
        case 'Europe/Istanbul':
            return TimeZone.EUROPE_ISTANBUL;
        case 'Europe/Lisbon':
            return TimeZone.EUROPE_LISBON;
        case 'Europe/Madrid':
            return TimeZone.EUROPE_MADRID;
        case 'Europe/Moscow':
            return TimeZone.EUROPE_MOSCOW;
        case 'Europe/Oslo':
            return TimeZone.EUROPE_OSLO;
        case 'Europe/Paris':
            return TimeZone.EUROPE_PARIS;
        case 'Europe/Rome':
            return TimeZone.EUROPE_ROME;
        case 'Greenwich':
            return TimeZone.GREENWICH;
        case 'Israel':
            return TimeZone.ISRAEL;
        case 'Japan':
            return TimeZone.JAPAN;
        case 'MET':
            return TimeZone.MET;
        case 'Pacific/Auckland':
            return TimeZone.PACIFIC_AUCKLAND;
        case 'Pacific/Fiji':
            return TimeZone.PACIFIC_FIJI;
        case 'Pacific/Guadalcanal':
            return TimeZone.PACIFIC_GUADALCANAL;
        case 'Pacific/Tongatapu':
            return TimeZone.PACIFIC_TONGATAPU;
        case 'PRC':
            return TimeZone.PRC;
        case 'Singapore':
            return TimeZone.SINGAPORE;
        case 'US/Alaska':
            return TimeZone.US_ALASKA;
        case 'US/Aleutian':
            return TimeZone.US_ALEUTIAN;
        case 'US/Arizona':
            return TimeZone.US_ARIZONA;
        case 'US/Central':
            return TimeZone.US_CENTRAL;
        case 'US/Eastern':
            return TimeZone.US_EASTERN;
        case 'US/Hawaii':
            return TimeZone.US_HAWAII;
        case 'US/Mountain':
            return TimeZone.US_Mountain;
        case 'US/Pacific':
            return TimeZone.US_PACIFIC;
        case 'US/Samoa':
            return TimeZone.US_SAMOA;
    }
    throw new Error(`An unknown time zone - ${timeZone} - was received from the Smartsheet API.`);
}
