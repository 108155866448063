import { HandleContacts } from '../../../common/classes';
import { ColumnType, ObjectType } from '../../../common/enums';
import { CellObjectValue, ContactObjectValue, FormFieldInterface } from '../../../common/interfaces';
import { isContactObjectValue, isMultiContactObjectValue, isMultiPicklistObjectValue, smartsheetFormatUtility } from '../../../common/utils';
import * as gsFormattingUtility from '../../../common/utils/GsFormattingUtility';
import { SubmittedForm } from '../SubmittedForm';
import { CellImages } from './CellImage/CellImages';

export const mapSubmittedFormToFormFieldInterfaces = (
    originalForm: FormFieldInterface[],
    submittedForm: SubmittedForm,
    cellImages: CellImages
): FormFieldInterface[] => {
    const handleContacts: HandleContacts = new HandleContacts();

    const updatedRowFields: FormFieldInterface[] = [];
    const columnIdsForUpdatedFields = new Set<number>();
    for (const key of Object.keys(submittedForm)) {
        const columnId = parseInt(key, 10);
        const field = originalForm.find((item) => item.columnId === columnId);

        // Exclude readonly fields or if field is not in originalData
        if (!field || field.readOnly) {
            continue;
        }

        // If the user has attached a cell image to this field, then we don't need to process any other value changes.
        if (cellImages.attachedForUpload.has(columnId)) {
            if (field.required) {
                // If the field is required, then we need to make sure that the form always has a value. Use the alt
                // text of the image as the value for now.  It will be overwritten once the cell image is uploaded.
                const altTextValue = cellImages.altText.get(columnId);
                if (altTextValue) {
                    updatedRowFields.push({ columnId, value: altTextValue });
                }
            }
            continue;
        }

        // Changes to the alt text of an existing cell images are processed as part of the row save.
        const altText = cellImages.altText.get(columnId);
        if (altText) {
            updatedRowFields.push({ columnId, image: { altText } });
            continue;
        }

        let value: any = submittedForm[columnId];

        // Only add updated row fields
        if (field.value === value && field.type !== ColumnType.CHECKBOX && field.type !== ColumnType.TEXT_NUMBER) {
            continue;
        }

        let objectValue: CellObjectValue;
        switch (field.type) {
            case ColumnType.CHECKBOX:
                // if CHECKBOX has a value of string(default value), change to boolean
                if (typeof value === 'string') {
                    value = !!field.defaultValue;
                } else if (value === undefined || value === Boolean(field.value)) {
                    continue;
                }
                break;
            case ColumnType.TEXT_NUMBER:
                // Ensure value has changed before adding
                if (value === smartsheetFormatUtility.getFormattedValueForEdit(field.value)) {
                    continue;
                }

                value = value != null ? gsFormattingUtility.getNumberFromTrimmedInputAndFormatStringAll(value, field.format, false) : value;
                break;
            case ColumnType.CONTACT_LIST:
                if (Array.isArray(value)) {
                    value = handleContacts.getStringFromContactArray(value);
                } else if (isContactObjectValue(value)) {
                    // For contact columns, don't process any changes if the email hasn't changed
                    // This is to address Bug-25843 where contacts are saved even if unchanged.
                    const contactObjectValue = field.objectValue as ContactObjectValue;
                    if (value.email && contactObjectValue && contactObjectValue.email === value.email) {
                        continue;
                    }

                    // We need the full contact object when using Grid Service to update CONTACT_LIST cells
                    objectValue = value;
                    value = undefined;
                } else if (value === null) {
                    if (field.value) {
                        objectValue = null;
                        value = undefined;
                    }
                } else if (value) {
                    // Handle case of non-object value selected as default, where field.value is a string instead of object
                    // by creating a contact object.  If `email` and `name` are set to the same, the backend will store their value
                    // as a Text cell rather than a Contact cell, since in this scenario the contact doesn't necessarily map
                    // to a Smartsheet user
                    objectValue = {
                        objectType: ObjectType.CONTACT,
                        email: value,
                        name: value,
                    };
                }
                break;
            case ColumnType.MULTI_PICKLIST:
                // For multi-picklist columns, don't process any changes if the selected options haven't changed
                if (
                    isMultiPicklistObjectValue(field.objectValue) &&
                    isMultiPicklistObjectValue(value) &&
                    containsMatchingStrings(field.objectValue.values, value.values)
                ) {
                    continue;
                }
                objectValue = value ? value : null;
                value = undefined;
                break;
            case ColumnType.MULTI_CONTACT_LIST:
                // For contact columns, don't process any changes if the emails haven't changed
                const originalEmails = getEmailsFromObjectValue(field.objectValue);
                const submittedEmails = getEmailsFromObjectValue(value);
                if (containsMatchingStrings(originalEmails, submittedEmails)) {
                    continue;
                }
                if (isMultiContactObjectValue(value) && Array.isArray(value.values)) {
                    value.values = value.values.map((contactValue) => ({
                        name: contactValue.name,
                        email: contactValue.email,
                        objectType: contactValue.objectType,
                    }));
                }
                // For multi-contacts, iterating over submittedForm returns objectValue,
                // so map it to the objectValue prop instead of value prop
                objectValue = value ? value : null;
                value = undefined;
                break;
        }

        updatedRowFields.push({ columnId, value, displayValue: field.displayValue, objectValue });
        columnIdsForUpdatedFields.add(columnId);
    }

    // Add row updates for fields with cell images that have been cleared, without any text being added to overwrite them.
    for (const columnIdOfRemovedCellImage of cellImages.scheduledForRemoval.keys()) {
        if (!columnIdsForUpdatedFields.has(columnIdOfRemovedCellImage)) {
            updatedRowFields.push({ columnId: columnIdOfRemovedCellImage, value: null });
        }
    }

    return updatedRowFields;
};

const getEmailsFromObjectValue = (objectValue: CellObjectValue): string[] | undefined => {
    if (!isMultiContactObjectValue(objectValue)) {
        return;
    }
    return objectValue.values.filter((value) => value.email !== undefined).map((val) => val.email!);
};

/**
 * If the arrays contain the same strings, regardless of order, then return true
 */
const containsMatchingStrings = (original: string[] | undefined, submitted: string[] | undefined): boolean => {
    if (!original || !submitted) {
        return false;
    }

    if (original.length !== submitted.length) {
        return false;
    }

    const originalSet = new Set(original);
    for (const value of submitted) {
        if (!originalSet.has(value)) {
            return false;
        }
    }

    return true;
};
