/* eslint @typescript-eslint/no-misused-promises:"off",@typescript-eslint/ban-types:"off" */
import { Checkbox, Spacer } from '@smartsheet/lodestar-core';
import { Filter } from '../../../common/classes/Filter/Filter';
import { FilterCondition } from '../../../common/classes/Filter/FilterCondition';
import { MAX_FILTER_NAME } from '../../../common/constants';
import { AccessLevel, FilterConditionOperators } from '../../../common/enums';
import { Column, DualInput, IFilterUI } from '../../../common/interfaces';
import * as React from 'react';
import plusGray from '../../../assets/images/icons/plus-gray.svg';
import { AutomationIds } from '../../../common/enums/AutomationElements.enum';
import { SymbolLookup } from '../../../common/utils/ViewSource';
import { PureBaseComponent } from '../../../components/Base';
import Button from '../../../components/Buttons/Button/Button';
import { MultiSelectItem } from '../../../components/MultiSelect/MultiSelect.interface';
import { LanguageElements } from '../../../language-elements/LanguageElements';
import { LanguageElementsProp, withLanguageElementsHOC } from '../../../language-elements/withLanguageElementsHOC';
import { SelectOption } from '../../Form/Interfaces/SelectOption.interface';
import FilterConditionBase from './FilterConditionBase';

interface Props {
    onDeleteFilter: (filterId: string) => Promise<boolean>;
    onCancelFilter: () => void;
    onApplyFilter: (filter: IFilterUI) => Promise<boolean>;
    filter?: IFilterUI;
    fields: Column[];
    picklistSymbolImageMap: Map<string, SymbolLookup | undefined>;
    allFilterItemsMap: Map<number, Map<string, MultiSelectItem>>;
    currentUserAccessLevel: AccessLevel;
}

interface State {
    activeCondition: number;
    filter: IFilterUI;
    showErrors: boolean; // activate when user clicks 'Apply', or alter this to activate onblur
    scrollTop: number;
    isServerRequestInProgress: boolean;
}

export class FilterWrapper extends PureBaseComponent<Props & LanguageElementsProp> {
    public state: State;
    private readonly selector: any;

    public constructor(props: Props & LanguageElementsProp) {
        super(props);
        this.selector = React.createRef();
        const filter: IFilterUI = new Filter(props.fields, props.filter, false).toDisplayModel();
        this.state = {
            activeCondition: -1,
            filter,
            showErrors: false,
            scrollTop: 0,
            isServerRequestInProgress: false,
        };
    }

    public render(): React.ReactNode {
        const conditions = this.state.filter.conditions;
        const isFilterEmpty: boolean = this.state.filter.isFilterEmpty;

        return (
            <div className="filter-wrapper-container" ref={this.selector}>
                <div className="container-header">
                    <span>{this.props.languageElements.VIEW_FILTER}</span>
                </div>
                <div id={'filter-scroll-container'} className="conditions-wrapper" onScroll={(event) => this.handleScroll(event)}>
                    {this.getFilterNameJSX()}
                    {conditions &&
                        conditions.map((condition: FilterCondition, i: number) => {
                            const conditionFields = this.getConditionFieldOptions(condition.conditionFields);
                            const conditionOperators = this.getConditionOperatorOptions(condition.conditionOperators);
                            const conditionMultiSelectOptions = this.lookupFilterItems(
                                this.props.allFilterItemsMap,
                                parseInt(condition.columnId, 10)
                            );
                            const selection = this.getFilterSelection(
                                condition.value,
                                condition.columnId,
                                conditionMultiSelectOptions,
                                condition.operator
                            );
                            return (
                                <FilterConditionBase
                                    key={i}
                                    condition={condition}
                                    fields={this.props.fields}
                                    onUpdateFilterCondition={this.handleUpdateFilterCondition}
                                    onDeleteFilterCondition={this.handleDeleteFilterCondition}
                                    data-client-id={AutomationIds.VIEW_FILTER_WRAPPER}
                                    index={i}
                                    showErrors={this.state.showErrors && !isFilterEmpty}
                                    disableDeleteCondition={this.state.filter.disableDeleteCondition}
                                    conditionFieldOptions={conditionFields}
                                    conditionOperatorOptions={conditionOperators}
                                    conditionMultiSelectOptions={conditionMultiSelectOptions}
                                    selection={selection}
                                    isFocused={this.state.activeCondition === i}
                                    updateActiveConditionIndex={this.updateActiveConditionIndex}
                                />
                            );
                        })}
                    <Button
                        className={'btn-add-condition'}
                        tabIndex={0}
                        onClick={this.handleClickAddCondition}
                        dataClientId={AutomationIds.FILTER_ADD_CONDITION_BUTTON}
                        disabled={this.state.filter.disableAddCondition}
                        icon={plusGray}
                        imageAltText={'plus icon'}
                        text={this.props.languageElements.FILTER_CONDITION}
                    />
                </div>
                <div className={'filter-footer'}>
                    <Button
                        id="filterFooterDeleteBtn"
                        tabIndex={0}
                        className={'btn btn-secondary'}
                        onClick={this.handleDeleteFilter}
                        dataClientId={AutomationIds.FILTER_DELETE_BUTTON}
                        disabled={isFilterEmpty || this.state.isServerRequestInProgress || !this.state.filter.id}
                        text={this.props.languageElements.FILTER_FOOTER_DELETE_BUTTON_TEXT}
                    />
                    <Button
                        id="filterFooterApplyBtn"
                        tabIndex={1}
                        className={'btn btn-primary'}
                        onClick={this.handleApplyFilter}
                        dataClientId={AutomationIds.FILTER_APPLY_BUTTON}
                        disabled={isFilterEmpty || this.state.isServerRequestInProgress || !this.state.filter.isValid || this.state.showErrors}
                        text={this.props.languageElements.FILTER_FOOTER_APPLY_BUTTON_TEXT}
                    />
                    <Button
                        id="filterFooterCancelBtn"
                        tabIndex={0}
                        className={'btn btn-secondary'}
                        onClick={this.props.onCancelFilter}
                        dataClientId={AutomationIds.FILTER_CANCEL_BUTTON}
                        text={this.props.languageElements.FILTER_FOOTER_CANCEL_BUTTON_TEXT}
                    />
                </div>
            </div>
        );
    }

    public componentDidMount(): void {
        this.setFilterFocus();
    }

    public componentDidUpdate(prevProps: Readonly<Props & LanguageElementsProp>, prevState: Readonly<{}>, snapshot?: any): void {
        this.setFilterFocus();
    }

    /**
     * If all conditions are valid, call method to consolidate any conditions
     * that have the same columnId and operator & then apply consolidated filter
     */
    public handleApplyFilter = async (): Promise<void> => {
        // Create a new filter and consolidate conditions that have the same columnId and operator
        const filter = new Filter(this.props.fields, this.state.filter, true);

        if (filter.isValid) {
            this.setState({ isServerRequestInProgress: true });
            const success = await this.props.onApplyFilter(filter.toDisplayModel());
            if (!success) {
                this.setState({ isServerRequestInProgress: false });
            }
        } else {
            this.setState({ showErrors: true });
        }
    };

    /**
     * Create a new instance so that we are not changing state directly
     */
    public handleClickAddCondition = (): void => {
        const updatedFilter = new Filter(this.props.fields, this.state.filter, false);

        updatedFilter.addNewCondition();
        const activeCondition = this.state.filter.conditions.length;

        this.setState({
            activeCondition,
            filter: updatedFilter.toDisplayModel(),
            showErrors: false,
        });
    };

    public handleDeleteFilter = (): void => {
        this.setState({ isServerRequestInProgress: true });
        this.props.onDeleteFilter(this.state.filter.id!);
    };

    public handleUpdateFilterCondition = (condition: FilterCondition, conditionIndex: number): void => {
        const updatedFilter = new Filter(this.props.fields, this.state.filter, false);

        updatedFilter.updateCondition(condition, conditionIndex);

        this.setState({
            activeCondition: conditionIndex,
            filter: updatedFilter.toDisplayModel(),
            showErrors: false,
        });
    };

    public handleDeleteFilterCondition = (conditionIndex: number): void => {
        const updatedFilter = new Filter(this.props.fields, this.state.filter, false);

        updatedFilter.deleteCondition(conditionIndex);

        this.setState({
            activeCondition: 0,
            filter: updatedFilter.toDisplayModel(),
        });
    };

    private handleChangeFilterName = (e: any): void => {
        const updatedFilter = new Filter(this.props.fields, this.state.filter, false);
        updatedFilter.changeName(e.target.value);

        // Set active condition to -1 in order to keep focus on name field
        this.setState({
            activeCondition: -1,
            filter: updatedFilter.toDisplayModel(),
            showErrors: false,
        });
    };

    public handleChangeShareStatus = (): void => {
        const updatedFilter = new Filter(this.props.fields, this.state.filter, false);
        updatedFilter.setSharing(!updatedFilter.shared);

        this.setState({
            filter: updatedFilter.toDisplayModel(),
            activeCondition: -1,
        });
    };

    private static getFilterConditionOperatorsLabel(filterConditionOperators: FilterConditionOperators, languageElements: LanguageElements): string {
        switch (filterConditionOperators) {
            case FilterConditionOperators.IS_ONE_OF:
                return languageElements.lbl_is_one_of;
            case FilterConditionOperators.IS_NOT_ONE_OF:
                return languageElements.lbl_is_not_one_of;
            case FilterConditionOperators.IS_CHECKED:
                return languageElements.lbl_is_checked;
            case FilterConditionOperators.IS_NOT_CHECKED:
                return languageElements.lbl_is_not_checked;
            case FilterConditionOperators.IS_EQUAL_TO:
                return languageElements.lbl_is_equal_to;
            case FilterConditionOperators.IS_NOT_EQUAL_TO:
                return languageElements.lbl_is_not_equal_to;
            case FilterConditionOperators.CONTAINS:
                return languageElements.lbl_contains;
            case FilterConditionOperators.DOES_NOT_CONTAIN:
                return languageElements.lbl_does_not_contain;
            case FilterConditionOperators.IS_BLANK:
                return languageElements.lbl_is_blank;
            case FilterConditionOperators.IS_NOT_BLANK:
                return languageElements.lbl_is_not_blank;
            case FilterConditionOperators.HAS_ANY_OF:
                return languageElements.lbl_has_any_of;
            case FilterConditionOperators.HAS_NONE_OF:
                return languageElements.lbl_has_none_of;
            case FilterConditionOperators.HAS_ALL_OF:
                return languageElements.lbl_has_all_of;
            case FilterConditionOperators.DOES_NOT_HAVE_ALL_OF:
                return languageElements.lbl_does_not_have_all_of;
            case FilterConditionOperators.IS_GREATER_THAN:
                return languageElements.lbl_is_greater_than;
            case FilterConditionOperators.IS_LESS_THAN:
                return languageElements.lbl_is_less_than;
            case FilterConditionOperators.IS_GREATER_THAN_OR_EQUAL_TO:
                return languageElements.lbl_is_greater_than_or_equal_to;
            case FilterConditionOperators.IS_LESS_THAN_OR_EQUAL_TO:
                return languageElements.lbl_is_less_than_or_equal_to;
            case FilterConditionOperators.IS_BETWEEN:
                return languageElements.lbl_is_between;
            case FilterConditionOperators.IS_NOT_BETWEEN:
                return languageElements.lbl_is_not_between;
            case FilterConditionOperators.IS_A_NUMBER:
                return languageElements.lbl_is_a_number;
            case FilterConditionOperators.IS_NOT_A_NUMBER:
                return languageElements.lbl_is_not_a_number;
            case FilterConditionOperators.IS_A_DATE:
                return languageElements.lbl_is_a_date;
            case FilterConditionOperators.IS_NOT_A_DATE:
                return languageElements.lbl_is_not_a_date;
            default:
                return '';
        }
    }

    private getConditionOperatorOptions = (operators: string[]): SelectOption[] => {
        const { languageElements } = this.props;
        return operators.map((operator: FilterConditionOperators) => {
            return {
                value: operator,
                label: FilterWrapper.getFilterConditionOperatorsLabel(operator, languageElements),
            } as SelectOption;
        });
    };

    private getConditionFieldOptions = (conditionFields: Column[]): SelectOption[] => {
        return conditionFields.map((field: Column) => {
            return {
                value: field.id || field.virtualId,
                label: field.title,
            } as SelectOption;
        });
    };

    private getFilterSelection = (
        filterInput: string[] | string | DualInput,
        columnId: string,
        conditionMultiSelectOptions: MultiSelectItem[],
        operator?: FilterConditionOperators
    ): MultiSelectItem[] | string | undefined | DualInput => {
        switch (operator) {
            case FilterConditionOperators.IS_CHECKED:
            case FilterConditionOperators.IS_NOT_CHECKED:
                return undefined;
            case FilterConditionOperators.IS_ONE_OF:
            case FilterConditionOperators.IS_NOT_ONE_OF:
            case FilterConditionOperators.HAS_ANY_OF:
            case FilterConditionOperators.HAS_NONE_OF:
            case FilterConditionOperators.HAS_ALL_OF:
            case FilterConditionOperators.DOES_NOT_HAVE_ALL_OF:
                return this.formatMultiSelectItems(filterInput as string[], conditionMultiSelectOptions);
            case FilterConditionOperators.CONTAINS:
            case FilterConditionOperators.DOES_NOT_CONTAIN:
            case FilterConditionOperators.IS_EQUAL_TO:
            case FilterConditionOperators.IS_NOT_EQUAL_TO:
            case FilterConditionOperators.IS_GREATER_THAN:
            case FilterConditionOperators.IS_LESS_THAN:
            case FilterConditionOperators.IS_GREATER_THAN_OR_EQUAL_TO:
            case FilterConditionOperators.IS_LESS_THAN_OR_EQUAL_TO:
                return typeof filterInput === 'string' ? filterInput : '';
            case FilterConditionOperators.IS_BETWEEN:
            case FilterConditionOperators.IS_NOT_BETWEEN:
                return filterInput as DualInput;
            default:
        }
        return '';
    };

    private formatMultiSelectItems = (filterInput: string[], conditionMultiSelectOptions: MultiSelectItem[]): MultiSelectItem[] => {
        if (!filterInput) {
            return [];
        }

        return filterInput.map((item: string) => {
            const multiSelectOption = conditionMultiSelectOptions.find((option) => option.value === item);
            return multiSelectOption
                ? multiSelectOption
                : {
                      value: item,
                      heading: item,
                      subheading: '',
                      isValid: true,
                      controlIdPrefix: 'ctl',
                  };
        });
    };

    private lookupFilterItems = (allFilterItemsMap: Map<number, Map<string, MultiSelectItem>>, columnId: number): MultiSelectItem[] => {
        let filterItems: MultiSelectItem[] = [];

        if (allFilterItemsMap.has(columnId)) {
            filterItems = Array.from(allFilterItemsMap.get(columnId)!.values());
        }

        return filterItems;
    };

    private getFilterNameJSX = (): JSX.Element => {
        const showCheckbox = this.props.currentUserAccessLevel === AccessLevel.OWNER || this.props.currentUserAccessLevel === AccessLevel.ADMIN;

        return (
            <div className={'filter-name-container'}>
                <p>
                    {this.props.languageElements.ADHOC_FILTER_NAME_PROMPT}
                    <span>{this.props.languageElements.ADHOC_FILTER_OPTIONAL}</span>
                </p>
                <div className={'flex-row'}>
                    <div className={`user-input ${showCheckbox ? 'show-checkbox' : ''}`}>
                        <input
                            type="text"
                            maxLength={MAX_FILTER_NAME}
                            onChange={(e) => this.handleChangeFilterName(e)}
                            value={this.state.filter && this.state.filter.name ? this.state.filter.name : ''}
                            data-client-id={AutomationIds.FILTER_NAME}
                        />
                    </div>
                    {showCheckbox && (
                        <Spacer orientation="row" size="medium">
                            <Checkbox
                                checkedState={Boolean(this.state.filter && this.state.filter.shared) ? 'checked' : 'unchecked'}
                                onClick={this.handleChangeShareStatus}
                                label={this.props.languageElements.ADHOC_FILTER_SHARE}
                                inputId={this.state.filter && this.state.filter.id ? this.state.filter.id : 'new'}
                                clientId={AutomationIds.FILTER_SHARE_CHECKBOX}
                            />
                        </Spacer>
                    )}
                </div>
            </div>
        );
    };

    private setFilterFocus = (): void => {
        let isFieldValid: boolean;
        let isOperatorValid: boolean;
        const filter = this.state.filter;

        const invalidIndex = filter.conditions.findIndex((condition) => !condition.isValid);

        // If a condition is active and filter is invalid, set focus on first invalid condition & attribute
        if (this.state.activeCondition !== -1 && invalidIndex !== -1) {
            isFieldValid = filter.conditions[invalidIndex].isFieldValid;
            isOperatorValid = filter.conditions[invalidIndex].isOperatorValid;

            const conditionElement = (this.selector.current as HTMLElement).getElementsByClassName('filter-condition')[invalidIndex];

            if (!isFieldValid) {
                (conditionElement.getElementsByClassName('field-button')[0] as HTMLElement).focus();
            } else if (!isOperatorValid) {
                (conditionElement.getElementsByClassName('operator-button')[0] as HTMLElement).focus();
            } else {
                (conditionElement.getElementsByClassName('condition-input')[0] as HTMLElement).focus();
            }
            return;
        }
    };

    private handleScroll = (event: any): void => {
        const container = document.getElementById('filter-scroll-container');

        if (container) {
            const scrollTop = container.scrollTop;
            this.setState({ scrollTop });
        }
    };

    private updateActiveConditionIndex = (conditionIndex: number): void => {
        if (this.state.activeCondition !== conditionIndex) {
            this.setState({
                activeCondition: conditionIndex,
            });
        }
    };
}

export default withLanguageElementsHOC(FilterWrapper);
