import { VIEW_NAME_MAX_LENGTH } from '../../../common/constants';
import * as React from 'react';

import { WithDataClientProps } from '../../../components/hoc/WithDataClient';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';
import SectionTitle from '../SectionTitle';

export interface BasicNameProps extends WithDataClientProps {
    viewName: string;
    onNameChange: (event: React.FormEvent<HTMLInputElement>) => void;
}

const BasicName: React.SFC<BasicNameProps> = props => {
    const languageElements = useLanguageElements();
    return (
        <div id="basicViewName">
            <SectionTitle title={languageElements.ADMIN_PANEL_BASIC_VIEW_NAME}/>
            <input
                className="nameInput"
                type={'text'}
                maxLength={VIEW_NAME_MAX_LENGTH}
                data-client-id={props.dataClientId}
                onChange={event => props.onNameChange(event)}
                value={props.viewName}
            />
        </div>
    );
};

export default BasicName;
