/* eslint @typescript-eslint/no-misused-promises:"off" */
import { FORBIDDEN_FILE_TYPES, MAX_FILE_SIZE_IN_BYTES } from '../../../../common/constants';
import { getFileExtension } from '../../../../common/utils';
import * as React from 'react';
import { WithDataClientProps } from '../../../../components/hoc/WithDataClient';
import withSetAppError, { WithSetAppErrorProps } from '../../../../components/hoc/WithSetAppError';
import { LanguageElementsProp, withLanguageElementsHOC } from '../../../../language-elements/withLanguageElementsHOC';

interface OwnProps extends WithDataClientProps{
    inputRef?: React.Ref<HTMLInputElement>;
    onSelection?: (file: File) => void;
}

export type CellImageAttachmentProps = OwnProps & WithSetAppErrorProps & LanguageElementsProp;

class CellImageAttachment extends React.Component<CellImageAttachmentProps> {
    public constructor(props: CellImageAttachmentProps) {
        super(props);
    }

    public render = () => (
        <input
            type="file"
            style={{display: 'none'}}
            ref={this.props.inputRef}
            onChange={this.handleFileSelection}
            accept="image/png,image/jpeg,image/gif"
        />
    )

    private handleFileSelection = (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        if (!e.target || !e.target.files || e.target.files.length < 1) {
            return Promise.resolve();
        }

        const file: File = e.target.files[0];

        // Check file extension before upload
        const fileExtension = getFileExtension(file.name);

        if (FORBIDDEN_FILE_TYPES.has(fileExtension)) {
            this.props.onSetAppStageError(new Error(this.props.languageElements.INVALID_ATTACHMENT_TYPE));
            return Promise.resolve();
        }

        if (file.size > MAX_FILE_SIZE_IN_BYTES) {
            this.props.onSetAppStageError(new Error(this.props.languageElements.FILE_SIZE_EXCEEDS_LIMITS));
            return Promise.resolve();
        }

        if (file.size < 1) {
            this.props.onSetAppStageError(new Error(this.props.languageElements.FILE_REQUIRED));
            return Promise.resolve();
        }

        if (this.props.onSelection) {
            this.props.onSelection(file);
        }
        return Promise.resolve();
    }
}

export default withLanguageElementsHOC(withSetAppError(CellImageAttachment));
