import { ColumnHeader } from '../../components/Grid/Grid.interface';
import { MultiSelectItem } from '../../components/MultiSelect/MultiSelect.interface';
import { CheckboxOptions, SymbolImageUrlResolver, getPickListOptions } from '../../components/Picklist/SymbolPickerImages';
import { LanguageElements } from '../../language-elements/LanguageElements';
import { ColumnType, SymbolSetName } from '../enums';
import { AutomationIds } from '../enums/AutomationElements.enum';
import { Column, Contact, SourceColumn, SourceSheet, ViewConfig, ViewData } from '../interfaces';
import { isContactObjectValue } from '../utils';
import { collator } from './Collator';
import { ColumnWidth, ColumnWidthRepository } from './ColumnWidthRepository';
import { SymbolLookup, ViewSource } from './ViewSource';

export type RowId = number;
export type ColumnId = number;

export class ViewSourceFactory {
    public static create(
        viewConfig: ViewConfig,
        columnWidthRepository: ColumnWidthRepository,
        languageElements: LanguageElements,
        viewData?: ViewData
    ): ViewSource {
        if (!viewData) {
            return new ViewSource(viewConfig);
        }

        let sheetColumnMap: Map<number, Column> = new Map();
        let reportColumnMap: Map<number, Column> = new Map();
        let virtualColumnIdToSheetColumnIdsMap: Map<number, number[]> = new Map();
        const rowIdToReportColumnIdToSheetColumn: ViewSource['rowIdToReportColumnIdToSheetColumn'] = new Map();

        const filterColumnId = viewConfig.filterColumnId;

        const totalRows: number = viewData.rows.length;
        const filterColumnIndex = filterColumnId
            ? viewData.columns.findIndex((column) => column.id === filterColumnId || column.virtualId === filterColumnId)
            : -1;

        // Is this a report?
        if (viewData.sourceSheets != null) {
            const allSourceColumns = ViewSourceFactory.createSourceColumnArray(viewData.sourceSheets);
            sheetColumnMap = ViewSourceFactory.createSheetColumnMap(allSourceColumns);
            reportColumnMap = ViewSourceFactory.createReportColumnMap(viewData.columns);
            virtualColumnIdToSheetColumnIdsMap = ViewSourceFactory.createVirtualColumnIdToSheetColumnIdsMap(viewData.sourceSheets);

            // Populate a look-up table (map) that will allow us to grab the sheet column by id.
            const sheetColumnsById = new Map<ColumnId, SourceColumn>();
            viewData.sourceSheets.forEach((sheet) => sheet.columns.forEach((sheetColumn) => sheetColumnsById.set(sheetColumn.id!, sheetColumn)));

            // Build up the mapping for row-id -> report-column-id -> sheet-column-object.
            viewData.rows.forEach((row) => {
                const rowMap = ViewSourceFactory.getMapForRowId(rowIdToReportColumnIdToSheetColumn, row.id);
                row.cells.forEach((cell) => rowMap.set(cell.virtualColumnId!, (cell.columnId && sheetColumnsById.get(cell.columnId)) || undefined));
            });
        } else {
            sheetColumnMap = ViewSourceFactory.createSheetColumnMap(viewData.columns);
        }

        const picklistSymbolImageMap = ViewSourceFactory.createPicklistSymbolImageMap(Array.from(sheetColumnMap.values()), languageElements);

        const allColumnOptionsMap = ViewSourceFactory.createAllColumnOptionsMap(
            viewData.columns,
            sheetColumnMap,
            virtualColumnIdToSheetColumnIdsMap,
            picklistSymbolImageMap,
            languageElements
        );

        // Format column information needed for Grid display
        const columnWidthOverrides: ColumnWidth[] = columnWidthRepository.getColumnWidths(viewData.viewId!);
        const columnHeaders = ViewSourceFactory.getColumnHeaders(viewData.viewId!, viewData.columns, columnWidthOverrides);

        return new ViewSource(viewConfig, {
            viewData,
            sheetColumnMap,
            reportColumnMap,
            picklistSymbolImageMap,
            allColumnOptionsMap,
            totalRows,
            filterColumnIndex,
            columnHeaders,
            rowIdToReportColumnIdToSheetColumn,
        });
    }

    public static createSourceColumnArray(sourceSheets: SourceSheet[]): SourceColumn[] {
        let allColumns: SourceColumn[] = [];
        sourceSheets.forEach((sheet) => {
            allColumns = allColumns.concat(sheet.columns ? sheet.columns : []);
        });

        return allColumns;
    }

    public static createSheetColumnMap(columns: SourceColumn[]): Map<number, Column> {
        const sheetColumnMap = new Map();
        if (!columns) {
            return sheetColumnMap;
        }

        for (const column of columns) {
            if (column.id != null) {
                const modifiedColumn = { ...column };

                if (column.type === ColumnType.CHECKBOX && column.symbol == null) {
                    modifiedColumn.symbol = SymbolSetName.NONE;
                }
                sheetColumnMap.set(column.id, modifiedColumn);
            }
        }

        return sheetColumnMap;
    }

    public static createReportColumnMap(columns: Column[]): Map<number, Column> {
        const reportColumnMap = new Map();

        if (columns) {
            columns.forEach((column: Column) => {
                reportColumnMap.set(column.virtualId, column);
            });
        }

        return reportColumnMap;
    }

    public static createVirtualColumnIdToSheetColumnIdsMap(sourceSheets: SourceSheet[]): Map<number, number[]> {
        const map = new Map();

        for (const sourceSheet of sourceSheets) {
            if (sourceSheet.columns) {
                for (const column of sourceSheet.columns) {
                    // Add virtualColumnId to Map if it doesn't already exist
                    if (!map.has(column.virtualId)) {
                        map.set(column.virtualId, []);
                    }

                    // Add column id to map
                    const columnIds = map.get(column.virtualId);
                    columnIds.push(column.id);
                    map.set(column.virtualId, columnIds);
                }
            }
        }

        return map;
    }

    /*
     * Creates a map to get symbol for both grid display and picklists.
     * Loops over all columnIds for all underlying source sheets so that symbols can be mapped
     * for all icons that are needed for the view. Uses <symbol set name | option value> for the key.
     * */
    public static createPicklistSymbolImageMap(columns: SourceColumn[], languageElements: LanguageElements): Map<string, SymbolLookup> {
        const map = new Map();
        let symbolSetName: SymbolSetName | undefined;
        let options: string[];

        columns.forEach((column) => {
            if (column.type === ColumnType.PICKLIST) {
                symbolSetName = column.symbol as SymbolSetName;
                options = getPickListOptions(languageElements, column.options, symbolSetName);
                ViewSourceFactory.addSymbolSVGsToMap(symbolSetName, options, map);
            }
            if (column.type === ColumnType.CHECKBOX) {
                symbolSetName = ViewSourceFactory.getSymbolSetName(column);
                options = CheckboxOptions;
                ViewSourceFactory.addSymbolSVGsToMap(symbolSetName, options, map);
            }
        });

        return map;
    }

    public static addSymbolSVGsToMap(symbolSetName: SymbolSetName | undefined, options: string[], map: Map<string, SymbolLookup>): void {
        if (options && symbolSetName) {
            options.forEach((option, idx) => {
                const key = `${symbolSetName}|${option}`;
                if (!map.has(key)) {
                    map.set(key, { symbol: SymbolImageUrlResolver(symbolSetName, idx) });
                }
            });
        }
    }

    public static getSymbolSetName(column: SourceColumn): SymbolSetName {
        return column.type === ColumnType.CHECKBOX && column.symbol == null ? SymbolSetName.NONE : (column.symbol as SymbolSetName);
    }

    public static createAllColumnOptionsMap(
        columns: SourceColumn[],
        sheetColumnMap: Map<number, Column>,
        virtualColumnIdToSheetColumnIdsMap: Map<number, number[]>,
        picklistSymbolImageMap: Map<string, SymbolLookup | undefined>,
        languageElements: LanguageElements
    ): Map<number, Map<string, MultiSelectItem>> {
        const map: Map<number, Map<string, MultiSelectItem>> = new Map();

        columns.forEach((column) => {
            if (
                column.type === ColumnType.PICKLIST ||
                column.type === ColumnType.MULTI_PICKLIST ||
                column.type === ColumnType.CHECKBOX ||
                column.type === ColumnType.CONTACT_LIST ||
                column.type === ColumnType.MULTI_CONTACT_LIST
            ) {
                const columnId = column.virtualId || column.id;

                if (columnId) {
                    const filterItemsMap = ViewSourceFactory.createFilterItemsMap(
                        sheetColumnMap,
                        virtualColumnIdToSheetColumnIdsMap,
                        picklistSymbolImageMap,
                        column.type,
                        languageElements,
                        columnId
                    );
                    map.set(columnId, filterItemsMap);
                }
            }
        });
        return map;
    }

    public static createFilterItemsMap(
        sheetColumnMap: Map<number, Column>,
        virtualColumnIdToSheetColumnIdsMap: Map<number, number[]>,
        picklistSymbolImageMap: Map<string, SymbolLookup | undefined>,
        columnType: ColumnType,
        languageElements: LanguageElements,
        filterColumnId?: number
    ): Map<string, MultiSelectItem> {
        const map: Map<string, MultiSelectItem> = new Map();

        if (!filterColumnId) {
            return map;
        }

        const columnDefinitions: Column[] = ViewSourceFactory.getColumnDefinitions(
            filterColumnId,
            sheetColumnMap,
            virtualColumnIdToSheetColumnIdsMap
        );

        if (columnDefinitions != null) {
            columnDefinitions.forEach((columnDefinition) => {
                switch (columnType) {
                    case ColumnType.PICKLIST:
                    case ColumnType.MULTI_PICKLIST:
                        // Add 'Blank' at top of filter items list
                        map.set('', ViewSourceFactory.getFilterItem(columnType, '', languageElements));

                        if (columnDefinition.options != null) {
                            columnDefinition.options.forEach((option) => {
                                const key = ViewSourceFactory.getSymbolKey(columnType, option, columnDefinition.symbol);

                                // Add picklist info if it hasn't already been added
                                if (!map.has(key.toLowerCase())) {
                                    map.set(
                                        key.toLowerCase(),
                                        ViewSourceFactory.getFilterItem(columnType, option, languageElements, picklistSymbolImageMap, key)
                                    );
                                }
                            });
                        }
                        break;

                    case ColumnType.CHECKBOX:
                        CheckboxOptions.forEach((option) => {
                            const key = ViewSourceFactory.getSymbolKey(columnType, option, columnDefinition.symbol);

                            // Add checkbox info if it hasn't already been added (some checkboxes use symbols)
                            // If key is empty, do not add it to map since this is equivalent to false for CHECKBOX
                            if (key !== '' || !map.has(key)) {
                                map.set(key, ViewSourceFactory.getFilterItem(columnType, option, languageElements, picklistSymbolImageMap, key));
                            }
                        });
                        break;

                    case ColumnType.CONTACT_LIST:
                    case ColumnType.MULTI_CONTACT_LIST:
                        // Add 'Blank' at top of filter items list
                        map.set('', ViewSourceFactory.getFilterItem(columnType, '', languageElements));

                        if (columnDefinition.contactOptions != null) {
                            columnDefinition.contactOptions.forEach((contactOption) => {
                                // Add contact info if it hasn't already been added
                                if (contactOption.email && !map.has(contactOption.email)) {
                                    map.set(contactOption.email, ViewSourceFactory.getFilterItem(columnType, contactOption, languageElements));
                                }
                            });
                        }
                        break;
                    default:
                }
            });
        }

        return ViewSourceFactory.sortFilterItemsMap(columnType, map);
    }

    /*
     * Set key based on the following:
     * 1) Set to <symbol set name>|value if the column has a symbol set and the value matches an option for that set
     * 2) Set to NONE|true or NONE|false (in order to pickup correct svg) if the column is plain checkbox and value is true or false,
     * 3) Otherwise, set key to value
     */
    public static getSymbolKey = (columnType: ColumnType, option: string, symbol?: string): string => {
        let key: string = option;

        switch (columnType) {
            case ColumnType.PICKLIST:
                // Note that PICKLISTS that are custom drop-downs do not have symbol sets, so we just set the key to option in that case
                // Also note that option here should never be an empty string -
                // We handle that filter option by adding 'Blank' option to createFilterItemsMap()
                key = symbol ? `${symbol}|${option}` : option;
                break;
            case ColumnType.MULTI_PICKLIST:
                key = option;
                break;
            case ColumnType.CHECKBOX:
                // Note that plain checkboxes do not have symbol set names on column options from SMAR
                // so we add a language el here to pick up generic checkbox symbols
                key = symbol != null ? `${symbol}|${option}` : `${SymbolSetName.NONE}|${option}`;
                break;
            default:
        }

        return key;
    };

    // Return true if value is already in the options defined for the column (then the value doesn't need to be added to the columnCellMap)
    public static getIsIncludedInOptions = (
        columnType: ColumnType,
        option: string | Contact,
        columnId: number,
        sheetColumnMap: Map<number, Column>
    ): boolean => {
        const sheetColumn = sheetColumnMap.get(columnId);

        // Column.id will be valid because it's called by a method that passes either column id or virtualId
        switch (columnType) {
            case ColumnType.PICKLIST:
            case ColumnType.MULTI_PICKLIST:
                return Boolean(
                    sheetColumn &&
                        sheetColumn.options &&
                        sheetColumn.options.some((columnOption) => String(option).toLowerCase() === columnOption.toLowerCase())
                );

            case ColumnType.CHECKBOX:
                return option === 'false' || option === 'true';

            case ColumnType.CONTACT_LIST:
            case ColumnType.MULTI_CONTACT_LIST:
                if (isContactObjectValue(option)) {
                    return Boolean(
                        sheetColumn &&
                            sheetColumn.contactOptions &&
                            sheetColumn.contactOptions.some((contactOption) => contactOption.email === option.email)
                    );
                }
                return false;

            default:
                return false;
        }
    };

    public static getColumnDefinitions = (
        filterColumnId: number,
        sheetColumnMap: Map<number, Column>,
        virtualColumnIdToSheetColumnIdsMap: Map<number, number[]>
    ): Column[] => {
        // Get array of columnIds associated with the filterColumnId.
        // If the view is based on a sheet, the filterColumnId will map to one columnId.
        // If the view is based on a report, the filterColumnId will map to multiple columnIds
        // depending on the number of underlying source sheets.
        const columnIds =
            sheetColumnMap != null && sheetColumnMap.has(filterColumnId)
                ? [filterColumnId]
                : virtualColumnIdToSheetColumnIdsMap != null && virtualColumnIdToSheetColumnIdsMap.has(filterColumnId)
                ? virtualColumnIdToSheetColumnIdsMap.get(filterColumnId)
                : undefined;

        const columnDefinitions: Column[] = [];

        // Loop through columns and create array of column definitions
        if (columnIds != null) {
            for (const columnId of columnIds) {
                columnDefinitions.push(sheetColumnMap.get(columnId)!);
            }
        }

        return columnDefinitions;
    };

    public static getFilterItem(
        columnType: ColumnType,
        option: string | Contact,
        languageElements: LanguageElements,
        picklistImageSymbolMap?: Map<string, SymbolLookup | undefined>,
        key?: string
    ): MultiSelectItem {
        // If option is a string, initialize both value and label to option
        let value = typeof option === 'string' ? option : undefined;
        let label = value;
        let symbol: string | undefined;
        let subLabel: string | undefined;

        switch (columnType) {
            case ColumnType.PICKLIST:
            case ColumnType.CHECKBOX:
                if (value === 'true') {
                    label = languageElements.ADHOC_FILTER_CHECKED;
                } else if (value === 'false') {
                    label = languageElements.ADHOC_FILTER_UNCHECKED;
                }
                symbol = key && picklistImageSymbolMap && picklistImageSymbolMap.has(key) ? picklistImageSymbolMap.get(key)!.symbol : undefined;
                break;

            // Assign email to value and name to label (if avail, otherwise email)
            case ColumnType.CONTACT_LIST:
            case ColumnType.MULTI_CONTACT_LIST:
                if (typeof option !== 'string') {
                    value = option.email ? option.email : undefined;
                    label = option.name ? option.name : undefined;
                    subLabel = option.email;
                }
                break;

            default:
        }

        return {
            value,
            label,
            symbol,
            subLabel,
            isValid: true,
            controlIdPrefix: AutomationIds.FILTER_PREFIX,
        };
    }

    public static getColumnHeaders(viewId: string, columns: Column[], columnWidthOverrides: ColumnWidth[]): ColumnHeader[] {
        const widthOverrideMap = columnWidthOverrides
            ? columnWidthOverrides.reduce((acc: Map<string, number>, curr: ColumnWidth) => {
                  acc.set(curr.columnId, curr.width);
                  return acc;
              }, new Map())
            : new Map();

        return columns.map((column) => {
            const key = (column.virtualId || column.id)!.toString();
            return {
                key,
                title: column.title,
                width: widthOverrideMap.has(key) ? widthOverrideMap.get(key)! : column.width || 0,
            };
        });
    }

    public static getSheetColumn = (
        rowIdToReportColumnIdToSheetColumn: ViewSource['rowIdToReportColumnIdToSheetColumn'],
        reportRowId: RowId,
        reportColumnId: ColumnId
    ) => ViewSourceFactory.getMapForRowId(rowIdToReportColumnIdToSheetColumn, reportRowId).get(reportColumnId);

    private static sortFilterItemsMap(columnType: ColumnType, map: Map<string, MultiSelectItem>): Map<string, MultiSelectItem> {
        if (columnType === ColumnType.CONTACT_LIST || columnType === ColumnType.MULTI_CONTACT_LIST) {
            // Use Intl.collator utility to sort contact data on label prop
            return new Map(
                [...map.entries()].sort((a, b) => {
                    if (a[1] && b[1]) {
                        return collator.compare(a[1].label || '', b[1].label || '');
                    } else {
                        return 0;
                    }
                })
            );
        }

        return map;
    }

    private static getMapForRowId = (rowIdToReportColumnIdToSheetColumn: ViewSource['rowIdToReportColumnIdToSheetColumn'], rowId: RowId) => {
        // Get the reportColumnId to sheetColumnId mapping for this report row.
        let rowMap = rowIdToReportColumnIdToSheetColumn.get(rowId);

        // Initialize the mapping if needed.
        if (!rowMap) {
            rowMap = new Map();
            rowIdToReportColumnIdToSheetColumn.set(rowId, rowMap);
        }

        return rowMap;
    };
}
