// Used for contact options. This is slightly different than the ContactObjectValue used in cell objectValues since it does not have an objectType
import { GenericObjectValue } from './CellObjectValue.interface';

export interface Contact extends GenericObjectValue {
    id?: string;
    email?: string;
    name?: string;
    isValid?: boolean;
}

export const isContact = (value: any): value is Contact => {
    return value != null && typeof value === 'object' && ('email' in value || 'name' in value);
};
