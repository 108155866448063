import { HomeView } from '../../../common/interfaces';
import classnames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { AutomationTypes } from '../../../common/enums/AutomationElements.enum';
import { HomeFilter } from '../../../common/enums/HomeFilter';
import { UserActions } from '../../../common/enums/UserActions.enum';
import { UserAnalyticsAction } from '../../../common/metrics/UserAnalyticsAction';
import { HomeViewFilter } from '../../../common/utils';
import Tooltip from '../../../components/TooltipLegacy';
import { LanguageElementsProp, withLanguageElementsHOC } from '../../../language-elements/withLanguageElementsHOC';
import { ActionByType } from '../../../store';
import { ActionTypes as AppActionTypes, Actions as AppActions } from '../../App/Actions';
import { homeFilterSelector } from '../../App/Selectors';
import { sourceFilterHomeViewsSelector } from '../../Home';

interface OwnProps {
    filter: HomeFilter;
}

interface StateProps {
    activeFilter: HomeFilter;
    homeViews: HomeView[];
}

interface DispatchProps {
    setHomeFilter: (filter: HomeFilter) => ActionByType<AppActions, AppActionTypes.SET_HOME_FILTER>;
}

type HomeFilterPanelRowProps = OwnProps & StateProps & DispatchProps & RouteComponentProps<any> & LanguageElementsProp;

const HomeFilterPanelRow: React.FC<HomeFilterPanelRowProps> = (props) => {
    const onClick = (e: React.MouseEvent<HTMLLIElement>) => {
        props.setHomeFilter(props.filter);
        UserAnalyticsAction.addFromEvent(e, { filter: props.filter }, UserActions.CLICK_HOME_FILTER);
        if (props.location.pathname !== '/') {
            props.history.push('/');
        }
    };
    const classes = classnames('container-list-item', { active: props.activeFilter === props.filter });
    const count = props.homeViews.filter(HomeViewFilter[props.filter]).length;
    const tooltipContent = (
        <>
            <span className="tooltip-title">{props.languageElements.HOME_PANEL_FILTERS[props.filter]}</span>
            <span className="tooltip-subtitle">{props.languageElements.HOME_FILTER_TOOLTIP[props.filter]}</span>
        </>
    );
    return (
        <Tooltip content={tooltipContent}>
            <li className={classes} onClick={onClick} data-client-type={AutomationTypes.HOME_FILTER}>
                <div className="label">{props.languageElements.HOME_PANEL_FILTERS[props.filter]}</div>
                {count > -1 && <div className="row-count">{count}</div>}
            </li>
        </Tooltip>
    );
};

const mapState = createStructuredSelector({
    activeFilter: homeFilterSelector,
    homeViews: sourceFilterHomeViewsSelector,
});

const mapDispatch: DispatchProps = {
    setHomeFilter: AppActions.setHomeFilter,
};

export default withLanguageElementsHOC(withRouter(connect<StateProps, DispatchProps>(mapState, mapDispatch)(HomeFilterPanelRow)));
