import { AdminControlsData } from '../../common/interfaces';
import { compareStrings, withNullChecks } from '../../common/utils';

type CheckedState = 'checked' | 'unchecked' | 'indeterminate';

export const getCheckedState = (selectedItems: number, totalViews: number): CheckedState => {
    if (selectedItems === 0) {
        return 'unchecked';
    }

    if (selectedItems === totalViews) {
        return 'checked';
    }

    return 'indeterminate';
};

export const searchAndFilterViews = (views: Map<string, AdminControlsData>, searchString: string): Map<string, AdminControlsData> => {
    const sanitizeSearchString = searchString.replace(/[-[\]{}()+?.,*\\^$|]/g, '\\$&').trim();

    if (!sanitizeSearchString) {
        return views;
    }

    const newViews = new Map<string, AdminControlsData>();

    views.forEach((view) => {
        try {
            const isOwnerNameMuchPattern = view.currentOwner.name && new RegExp(sanitizeSearchString, 'gi').test(view.currentOwner.name);
            const isOwnerEmailMuchPattern = view.currentOwner.email && new RegExp(sanitizeSearchString, 'gi').test(view.currentOwner.email);
            const isViewNameMatchPattern = view.name && new RegExp(sanitizeSearchString, 'gi').test(view.name);
            const isViewDescriptionMatchPattern = view.description && new RegExp(sanitizeSearchString, 'gi').test(view.description);

            if (isOwnerNameMuchPattern || isViewNameMatchPattern || isViewDescriptionMatchPattern || isOwnerEmailMuchPattern) {
                newViews.set(view.id, view);
            }
        } catch (_) {}
    });

    return newViews;
};

export const compareFunctions: any = {
    name: (isReversed?: boolean) => withNullChecks(compareStrings, (o: [string, AdminControlsData]) => o[1].name, isReversed),
    description: (isReversed?: boolean) => withNullChecks(compareStrings, (o: [string, AdminControlsData]) => o[1].description, isReversed),
    owner: (isReversed?: boolean) =>
        withNullChecks(
            compareStrings,
            (o: [string, AdminControlsData]) => {
                if (!o[1].currentOwner) {
                    return '';
                }

                return o[1].currentOwner.name;
            },
            isReversed
        ),
};
