import './LandingPage.css';

import * as React from 'react';

import viewIcon from '../../assets/images/icons/view.svg';
import { AutomationTypes } from '../../common/enums/AutomationElements.enum';

interface Props {
    title: string;
    message: string;
    showViewIcon?: boolean;
    className?: string;
}

const LandingPage: React.SFC<Props> = props => {
    const {
        title,
        message,
        showViewIcon,
        children,
        className,
    } = props;

    // Empty alt text because content is not meaningful for screen reader (but defined to pass Wave audit test 05/2021)
    return (
        <div
            className={`landing-page-container ${className || ''}`}
            data-client-type={AutomationTypes.LANDING_PAGE}
        >
            <div className="landing-page-contents">
                {showViewIcon &&
                    <img src={viewIcon} alt=""/>
                }
                <h3>{title}</h3>
                <pre>{message}</pre>
                {children}
            </div>
        </div>
    );
};

export default LandingPage;
