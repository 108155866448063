import { Modal, ModalBody, ModalFooter, ModalFooterButton, ModalHeader } from '@smartsheet/lodestar-core';
import classNames from 'classnames';
import * as React from 'react';
import { AutomationIds } from '../../common/enums/AutomationElements.enum';
import { UserActions } from '../../common/enums/UserActions.enum';
import { UserAnalyticsAction } from '../../common/metrics/UserAnalyticsAction';
import { getModalWidthName } from './GetModalWidthName';

import './ImageModal.css';
import { withLanguageElementsHOC, LanguageElementsProp } from '../../language-elements/withLanguageElementsHOC';

interface CellImageModalProps {
    isOpen?: boolean;
    onClose: () => void;
    imageProps: { src: string; alt: string; height?: number; width?: number };
}

interface State {
    isScaledDown: boolean;
}

export class CellImageModal extends React.Component<CellImageModalProps & LanguageElementsProp, State> {
    public constructor(props: CellImageModalProps & LanguageElementsProp) {
        super(props);
        this.state = { isScaledDown: true };
    }

    public render = (): React.ReactNode => {
        const { imageProps, isOpen = true, onClose } = this.props;
        return (
            <Modal
                data-client-id={AutomationIds.CELL_IMAGE_MODAL}
                data-testid={AutomationIds.CELL_IMAGE_MODAL}
                isOpen={isOpen}
                onCloseRequested={onClose}
                width={imageProps.width && getModalWidthName(imageProps.width)}
            >
                <ModalHeader onCloseRequested={onClose} title={imageProps.alt} />

                <ModalBody className={classNames('image-modal-body', { 'scale-down': this.state.isScaledDown })}>
                    <img {...imageProps} alt={imageProps.alt} onClick={this.handleImageClick} />
                </ModalBody>

                <ModalFooter>
                    <ModalFooterButton onClick={onClose}>{this.props.languageElements.MODAL_PRIMARY_BUTTON_DEFAULT_TEXT}</ModalFooterButton>
                </ModalFooter>
            </Modal>
        );
    };

    private handleImageClick = (event: React.MouseEvent<HTMLImageElement>) => {
        if (this.state.isScaledDown) {
            UserAnalyticsAction.addFromEvent(event, undefined, UserActions.ZOOM_CELL_IMAGE);
        }
        this.setState({ isScaledDown: !this.state.isScaledDown });
    };
}

export default withLanguageElementsHOC(CellImageModal);
