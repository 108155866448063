import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { colors, SelectV2, sizes, TextArea, Toggle, Tooltip, TooltipBody, useTooltip } from '@smartsheet/lodestar-core';
import { SupportedLocales } from '@smartsheet/lodestar-core/dist/esm/i18n/constants';
import { InfoIcon } from '@smartsheet/lodestar-icons';
import { MAX_DETAILS_PANEL_DESCRIPTION_CHARS } from '../../../common/constants/MaxCharacter.constants';
import { FALLBACK_I18N_LOCALE } from '../../../common/enums';
import { DetailsPanelTabType } from '../../../common/enums/DetailsPanelTabType.enum';
import * as React from 'react';
import { AutomationIds } from '../../../common/enums/AutomationElements.enum';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';

export interface DetailsPanel {
    detailsPanelDescription?: string | null;
    detailsPanelInitialTab?: DetailsPanelTabType;
}

export interface DetailsPanelLayoutProps {
    showRequired: boolean;
    detailsPanelLayout: DetailsPanel;
    onChange: (valuesToUpdate: Partial<DetailsPanel>) => void;
    displayAttachments: boolean;
    displayComments: boolean;
}

export interface DetailsPanelTabOption extends Record<string, string | DetailsPanelTabType> {
    label: string;
    value: DetailsPanelTabType;
}

const PRIMARY_TAB_OPTIONS = [
    { label: DetailsPanelTabType.DATA_LABEL, value: DetailsPanelTabType.DATA },
    { label: DetailsPanelTabType.ATTACHMENTS_LABEL, value: DetailsPanelTabType.ATTACHMENTS },
    { label: DetailsPanelTabType.COMMENTS_LABEL, value: DetailsPanelTabType.COMMENTS },
];

const DetailsPanelLayout = ({ showRequired, detailsPanelLayout, onChange, displayAttachments, displayComments }: DetailsPanelLayoutProps) => {
    const languageElements = useLanguageElements();
    // The description toggle will be unchecked if the details panel description is either null or undefined. Null means the detailsPanelDescription
    // previously had a value that has since been removed. Undefined means the detailsPanelDescription has never been set.
    const isChecked = detailsPanelLayout.detailsPanelDescription != null;
    const handleToggleClick = () => {
        onChange({ detailsPanelDescription: isChecked ? null : '' });
    };

    const handleTextAreaChange = (value: string) => {
        onChange({ detailsPanelDescription: value });
    };

    const handleDetailsPanelInitialTabChange = (option: DetailsPanelTabOption) => {
        onChange({
            detailsPanelInitialTab: option.value,
        });
    };

    const tabOptions = PRIMARY_TAB_OPTIONS.filter(
        (option) =>
            option.value === DetailsPanelTabType.DATA ||
            (option.value === DetailsPanelTabType.COMMENTS && displayComments) ||
            (option.value === DetailsPanelTabType.ATTACHMENTS && displayAttachments)
    );

    const { tooltipProps, targetProps } = useTooltip<HTMLImageElement>();

    let selectedTab = tabOptions.find(
        (option) => detailsPanelLayout.detailsPanelInitialTab !== undefined && option.value === detailsPanelLayout.detailsPanelInitialTab
    );
    if (!selectedTab) {
        selectedTab = PRIMARY_TAB_OPTIONS[0];
    }

    return (
        <>
            <Container>
                <Title>{languageElements.FORM_CONTAINER_EDIT_FORM_TITLE}</Title>
                {showRequired && <RequiredText>{languageElements.FORM_CONTAINER_EDIT_FORM_REQUIRED}</RequiredText>}
                <ToggleStyled
                    isChecked={isChecked}
                    onClick={handleToggleClick}
                    label={languageElements.FORM_CONTAINER_EDIT_FORM_TOGGLE_DESCRIPTION}
                    clientId={AutomationIds.ADMIN_PANEL_FORM_DETAILS_PANEL_DESCRIPTION_TOGGLE}
                />
                {isChecked && (
                    <>
                        <DescriptionLabel htmlFor={AutomationIds.ADMIN_PANEL_FORM_DETAILS_PANEL_DESCRIPTION_INPUT}>
                            {languageElements.FORM_CONTAINER_EDIT_FORM_DESCRIPTION_TITLE}
                        </DescriptionLabel>
                        <TextArea
                            id={AutomationIds.ADMIN_PANEL_FORM_DETAILS_PANEL_DESCRIPTION_INPUT}
                            onChange={handleTextAreaChange}
                            value={detailsPanelLayout.detailsPanelDescription}
                            disableManualResize={true}
                            maxHeight={'65px'}
                            maxLength={MAX_DETAILS_PANEL_DESCRIPTION_CHARS}
                            clientId={AutomationIds.ADMIN_PANEL_FORM_DETAILS_PANEL_DESCRIPTION_INPUT}
                        />
                    </>
                )}
                <DetailsPanelInitialTab>
                    <div className="focus-tab-info">
                        {languageElements.ADMIN_PANEL_DETAILS_FOCUS_TAB_TEXT}
                        <FocusTabInfoIcon {...targetProps} alt={languageElements.ADMIN_PANEL_DETAILS_FOCUS_TAB_ALT_TEXT} size="xxSmall" />
                        <FocusTabToolTip {...tooltipProps} placement="bottom">
                            <TooltipBody>{languageElements.ADMIN_PANEL_DETAILS_FOCUS_TAB_DESCRIPTION}</TooltipBody>
                        </FocusTabToolTip>
                    </div>
                    <SelectV2Style
                        options={tabOptions}
                        selectedOption={selectedTab}
                        onChange={handleDetailsPanelInitialTabChange}
                        getOptionKey={(option) => option.label as string}
                        locale={FALLBACK_I18N_LOCALE as SupportedLocales}
                        isClearable={false}
                        id={'details-panel-initial-tab-id'}
                    />
                </DetailsPanelInitialTab>
            </Container>
            <DetailsPanelPreview>
                <DetailsTab detailsTabType={DetailsPanelTabType.DATA} detailsPanelInitialTab={detailsPanelLayout.detailsPanelInitialTab}>
                    {languageElements.DETAIL_PANEL_DATA}
                </DetailsTab>
                {displayAttachments && (
                    <DetailsTab detailsTabType={DetailsPanelTabType.ATTACHMENTS} detailsPanelInitialTab={detailsPanelLayout.detailsPanelInitialTab}>
                        {languageElements.DETAIL_PANEL_ATTACHMENTS} ({0})
                    </DetailsTab>
                )}
                {displayComments && (
                    <DetailsTab detailsTabType={DetailsPanelTabType.COMMENTS} detailsPanelInitialTab={detailsPanelLayout.detailsPanelInitialTab}>
                        {languageElements.DETAIL_PANEL_COMMENTS} ({0})
                    </DetailsTab>
                )}
            </DetailsPanelPreview>
        </>
    );
};

export default DetailsPanelLayout;

const Container = styled.div`
    padding: ${sizes.large - sizes.xxSmall}px ${sizes.large - sizes.xxSmall}px ${sizes.small}px ${sizes.large - sizes.xxSmall}px;
    margin-bottom: ${sizes.small}px;
    background-color: ${colors.defaultBackground};
    font-family: Roboto, sans-serif;
    font-size: 13px;
    color: #000000;
`;

const Title = styled.h3`
    color: #444444;
    font-size: 18px;
    font-weight: 300;
    margin: 0 0 10px 0;
`;

const ToggleStyled = styled(Toggle)`
    // These styles target the label text only
    > div > span {
        margin-top: auto;
        font-size: 13px;
        font-weight: 400;
        line-height: 24px;
    }
`;

const DescriptionLabel = styled.label`
    font-weight: 500;
    padding-top: ${sizes.small}px;
    padding-bottom: ${sizes.xSmall}px;
`;

const RequiredText = styled.span`
    position: absolute;
    top: 20px;
    right: 20px;
    color: #ff0047;
`;

const FocusTabInfoIcon = styled(InfoIcon)`
    padding: ${sizes.xxSmall}px ${sizes.xxSmall}px 0 ${sizes.xxSmall}px;
    vertical-align: bottom;
`;

const FocusTabToolTip = styled(Tooltip)`
    opacity: 0.7;
`;

const DetailsPanelInitialTab = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    column-gap: ${sizes.xxSmall}px;
    padding-top: ${sizes.xSmall}px;
`;

const SelectV2Style = styled(SelectV2)`
    min-width: 160px;
`;

const DetailsPanelPreview = styled.div`
    margin: 0;
    height: 20px;
    background: ${colors.neutralLight40};
    padding-bottom: ${sizes.xxSmall}px;
    padding-left: ${sizes.large - sizes.xxSmall}px;
    border-bottom: 1px solid #cccccc;
    opacity: 0.5;
`;

const dynamicBorder = (props: { detailsTabType: DetailsPanelTabType; detailsPanelInitialTab: DetailsPanelTabType | undefined }) =>
    css`
        border-bottom: ${props.detailsTabType === DetailsPanelTabType.DATA ? '3px solid #0073ec' : 'none'};
    `;

const DetailsTab = styled.a`
    margin-right: ${sizes.large - sizes.xxSmall}px;
    text-decoration: none;
    color: ${colors.neutralDark30};
    padding: ${sizes.xxSmall}px 3px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    ${dynamicBorder};
`;
