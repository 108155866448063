import { HomeView } from '../../../common/interfaces';
import * as React from 'react';
import { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import viewListIcon from '../../../assets/images/toolbar/leftRail/3-dark-blue.svg';
import { AutomationIds, AutomationTypes } from '../../../common/enums/AutomationElements.enum';
import Tooltip from '../../../components/TooltipLegacy';
import '../LeftPanelItems.css';

export enum InteractionStatus {
    NORMAL, // the entry is not currently being selected or active
    ACTIVE, // the item pointed to by this entry is loaded in the app
}

interface RecentsListItemProps {
    className?: string;
    item: HomeView;
    interactionStatus?: InteractionStatus;
    onContextMenu?: MouseEventHandler;
}

export const FavoritesListItem: React.SFC<RecentsListItemProps> = (props) => {
    const { item, interactionStatus = InteractionStatus.NORMAL } = props;

    const isActive = interactionStatus === InteractionStatus.ACTIVE;
    const itemClassName = `container-list-item ${isActive ? 'active ' : ''}`;

    // Empty string on img alt-text is needed for audit tool to pass. No img alt text is needed here because the parent Link contains text.
    return (
        <li className={itemClassName} data-client-type={AutomationTypes.FAVORITE_LIST_ITEM}>
            <Tooltip content={item.name}>
                <Link className="itemLink" to={`/views/${props.item.id}`}>
                    <div className="iconNode">
                        <img className="icon" src={viewListIcon} alt="" data-client-id={AutomationIds.FAVORITE_ITEM_ICON} />
                    </div>
                    <div className="label">{item.name}</div>
                </Link>
            </Tooltip>
        </li>
    );
};
