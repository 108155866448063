import { OwnershipTransferStatus } from '../../common/enums';
import * as dv from '../../common/interfaces';
import { AxiosError } from 'axios';
import { Epic, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { loggingClient } from '../../http-clients/Logging.client';
import ownershipTransferClient from '../../http-clients/OwnershipTransfer.client';
import viewClient from '../../http-clients/View.client';
import { Actions, ActionTypes } from './Actions';
import { OwnershipTransferMapper } from './OwnershipTransferMapper';

const FILENAME = 'OwnershipTransfer/Epic.ts';

/**
 * Ownership transfers are fetched when a user loads the app, and fetched again when the user
 * clicks on 'Ownership Transfers' button in the Admin Panel Sharing.
 * When the app loads, note that if the GET /transfers api call returns an error we do not alert the user
 * because we want the user to be able to use the rest of the app, but we do log the error message.
 * Also, if the api call returns an error, we set pendingOwnershipTransfers.status: ERROR
 * in the store. We use this error prop in the store to post a user message when the user clicks
 * 'Ownership Transfers' in the admin panel (but not when the app loads).
 */
export const fetchingOwnershipTransfersEpic: Epic<Actions> = action$ => action$.pipe(
    ofType(ActionTypes.FETCHING_OWNERSHIP_TRANSFERS),
    switchMap(() => {
        return from(ownershipTransferClient.get(OwnershipTransferStatus.PENDING)).pipe(
            map((pendingOwnershipTransferData: dv.IPaginatedResult<dv.IOwnershipTransfer>) => {
                return Actions.storeOwnershipTransfers(pendingOwnershipTransferData);
            }),
            catchError((error: AxiosError) => {
                loggingClient.logError(FILENAME, 'fetchingOwnershipTransfersEpic', error);

                return of(Actions.fetchingErrorOwnershipTransfers());
            }),
        );
    }),
);

/**
 * This function subscribes to the FETCH_ORG_VIEWS action and performs the following:
 * - Calls DV GET /api/views?include=org
 *
 * On success:
 * - Dispatch the STORE_ORG_VIEWS action. This action updates the status to 'DONE' and the data to the response.
 *
 * On errors:
 * - Dispatch the FETCH_ORG_VIEWS_ERROR action. This action updates the status to ERROR, and the data is NOT cleared.
 */
export const fetchOrgViewsEpic: Epic<Actions> = action$ => action$.pipe(
    ofType(ActionTypes.FETCH_ORG_VIEWS),
    switchMap(() => {
        return from(viewClient.getViews(false, true, false, true)).pipe(
            map(views => {
                const orgViews = views.map(value => OwnershipTransferMapper.fromHomeView(value));
                return Actions.storeOrgViews(orgViews);
            }),
            catchError((error: AxiosError) => {
                loggingClient.logError(FILENAME, 'fetchOrgViewsEpic', error);
                return of(Actions.fetchOrgViewsError());
            }),
        );
    }),
);