import { RowFieldValidationError } from '../../../enums';
import { FormFieldInterface } from '../../../interfaces';
import { isMultiPicklistObjectValue } from '../../../utils';
import { IRowFieldValidator } from './IRowFieldValidator';
import { RowFieldBaseValidator, RowFieldValueIsValidResult } from './RowFieldBaseValidator';

export class MultiPickListValidator extends RowFieldBaseValidator implements IRowFieldValidator {
    public constructor(originalRowField: FormFieldInterface) {
        super(originalRowField);
    }

    public validate(rowField: FormFieldInterface): RowFieldValueIsValidResult {
        const rowFieldValueIsValidResult = super.validateMandatoryProperties(rowField);
        if (rowFieldValueIsValidResult) {
            return rowFieldValueIsValidResult;
        }

        if (rowField.required) {
            if ((typeof rowField.objectValue !== 'string' && typeof rowField.objectValue !== 'object') ||
                (typeof rowField.objectValue === 'string' && rowField.objectValue.trim() === '') ||
                (typeof rowField.objectValue === 'object' && !isMultiPicklistObjectValue(rowField.objectValue)) ||
                (typeof rowField.objectValue === 'object' && this.multiPicklistValuesAreEmpty(rowField.objectValue.values))) {
                return {
                    isValid: false,
                    validationError: RowFieldValidationError.MISSING_REQUIRED_VALUE,
                };
            }
        }

        if (rowField.validation) {
            // Allow null when restricted value check enable to ensure the DV user can clear values within a row field
            if (rowField.objectValue === null) {
                return {
                    isValid: true,
                };
            }

            if (!isMultiPicklistObjectValue(rowField.objectValue)) {
                return {
                    isValid: rowField.defaultValue === rowField.objectValue,
                };
            }

            if (this.checkIsObjectValueEqualToDefaultValue(rowField)) {
                return {
                    isValid: true,
                };
            }


            // Validate newly selected values only. Original values are excluded from the validation as these values
            // are already part of the source sheet and are already considered valid in the sheet.
            // Bug-25571: https://app.smartsheet.com/sheets/jfcV4rc5rrVWwh6Pq3X44cffp4mg5XwQCfFFm9W1?rowId=4321689642526596
            const originalValues = isMultiPicklistObjectValue(this.originalRowField.objectValue) ? this.originalRowField.objectValue.values : [];
            const newValues = rowField.objectValue.values.filter((value) => originalValues.indexOf(value) === -1);
            return {
                isValid: newValues.every(value => super.isPicklistOption(value, rowField.options)),
            };
        }

        return {
            isValid: true,
        };
    }

    private checkIsObjectValueEqualToDefaultValue(rowField: FormFieldInterface): boolean {
        if (!isMultiPicklistObjectValue(rowField.defaultValue) || !isMultiPicklistObjectValue(rowField.objectValue)) {
            return false;
        }

        const defaultValuesForObjectValue = rowField.defaultValue.values;
        const rowFieldValuesForObjectValue = rowField.objectValue.values;

        if (defaultValuesForObjectValue == null ||
            rowFieldValuesForObjectValue == null ||
            defaultValuesForObjectValue.length !== rowFieldValuesForObjectValue.length) {
            return false;
        }

        return String(defaultValuesForObjectValue) === String(rowFieldValuesForObjectValue);
    }

    private multiPicklistValuesAreEmpty(values: any): boolean {
        if (!Array.isArray(values)) {
            return true;
        }

        return values.every((value: any) => {
            return !(typeof value === 'number' || (typeof value === 'string' && value.trim() !== ''));
        });
    }
}
