import { FormFieldInterface } from '../../../common/interfaces';
import * as React from 'react';
import { AutomationIds } from '../../../common/enums/AutomationElements.enum';
import DraggableItem from '../../../components/DragAndDrop/DraggableItem';
import { WithDataClientProps } from '../../../components/hoc/WithDataClient';
import './AvailableFormField.css';

interface Props extends WithDataClientProps {
    model: FormFieldInterface;
    onAddEvent: any;
}

const AvailableFormField: React.SFC<Props> = props => {
    return (
        <DraggableItem
            model={{
                columnId: props.model.columnId,
                type: props.model.type!,
                title: props.model.title!,
                ordinal: props.model.ordinal,
            }}
            draggableKey={`available-${props.model.columnId}`}
            style={{}}
            dataClientType={props.dataClientType}
            className="drag-item"
        >
            <span
                className="drag-handle"
                data-client-id={AutomationIds.AVAILABLE_FORM_FIELDS_DRAG_HANDLE}
            />
            <span
                className={`item-icon ${props.model.type || ''}`}
                data-client-id={AutomationIds.AVAILABLE_FORM_FIELDS_ITEM_ICON}
            />
            <span
                data-client-id={AutomationIds.AVAILABLE_FORM_FIELDS_TITLE}
            >
                <b>{props.model.title}</b>
            </span>
            <span
                className="icon-plus float-right"
                onClick={props.onAddEvent}
                data-client-id={AutomationIds.AVAILABLE_FORM_FIELDS_PLUS_ICON}
            />
        </DraggableItem>
    );
};

export default AvailableFormField;
