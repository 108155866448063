import { FormFieldInterface, FormInterface, FormRuleInterface } from '../../common/interfaces';
import { addPropIfDefined } from '../../common/utils/AddPropIfDefined';

export const mapFromFormContainerData = (form: FormInterface): FormInterface => {
    let fields: FormFieldInterface[] = [];
    if (Array.isArray(form.fields)) {
        fields = form.fields.map((field) => ({
            columnId: field.columnId,
            ...addPropIfDefined<FormFieldInterface>(field, 'ordinal', null),
            ...addPropIfDefined<FormFieldInterface>(field, 'customLabel', null),
            ...addPropIfDefined<FormFieldInterface>(field, 'description', null),
            ...addPropIfDefined<FormFieldInterface>(field, 'defaultValue', null),
            ...addPropIfDefined<FormFieldInterface>(field, 'multiline', null),
            ...addPropIfDefined<FormFieldInterface>(field, 'required', null),
            ...addPropIfDefined<FormFieldInterface>(field, 'readOnly', null),
            ...addPropIfDefined<FormFieldInterface>(field, 'hidden', null),
        }));
    }

    let rules: FormRuleInterface[] = [];
    if (Array.isArray(form.rules)) {
        rules = form.rules.map((rule) => ({
            actions: rule.actions,
            criteria: rule.criteria,
            disabled: rule.disabled,
            ...addPropIfDefined<FormRuleInterface>(rule, 'name', null),
            ...addPropIfDefined<FormRuleInterface>(rule, 'hidden', null),
        }));
    }

    return {
        fields,
        rules,
    };
};
