import * as React from 'react';
import infoIcon from '../../assets/images/symbolIcons/icon-info-circle-main.svg';
import './InfoMessage.css';

interface Props {
    message: string;
    dataClientId: string;
}
const infoMessage: React.FC<Props> = (props) => (
    <div className="info-message" data-client-id={props.dataClientId}>
        <img className="info-message-icon" src={infoIcon} alt="info icon" />
        {props.message}
    </div>
);

export default infoMessage;
