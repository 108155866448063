import * as React from 'react';
import { BaseProps, PureBaseComponent } from '../../Base';
import './ButtonGroup.css';

export interface ButtonGroupProps extends BaseProps {
    className?: string;
}

class ButtonGroup extends PureBaseComponent<ButtonGroupProps> {
    public render(): React.ReactNode {
        const {
            children,
            className,
            dataClientId,
            dataClientType,
        } = this.props;

        return (
            <div
                className={`button-group ${ className || ''}`}
                data-client-id={dataClientId}
                data-client-type={dataClientType}
            >
                {children}
            </div>
        );
    }
}

export default ButtonGroup;
