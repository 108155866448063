import * as React from 'react';
import GenericModalContent from './Content/GenericModalContent';
import ModalWrapper from './index';

export interface ModalProps {
    closeWrapper?: () => void;
    onClickPrimaryButton?: () => void;
    title?: string;
    message?: string;
    onClickSecondaryButton?: () => void;
    primaryButtonText?: string;
    secondaryButtonText?: string;
    hidePrimaryButton?: boolean;
    hideSecondaryButton?: boolean;
    icon?: any;
    wrapperClasses?: string;
    children?: React.ReactNode;
}

/**
 *  Most modals require just 'OK' button so initialize default props for the basic scenario.
 *  Note that 'children' prop allows for child component inside modal, such as the link component for private filter warning.
 *  The closeWrapper prop is used by the ModalWrapper component to dismiss the modal so it will typically match either
 *  the onClickPrimaryButton or onClickSecondaryButton depending on modal content
 */
const renderModal = (modalProps: ModalProps): JSX.Element | null => {
    const defaultProps: ModalProps = {
        onClickPrimaryButton: () => {},
        onClickSecondaryButton: () => {},
        hideSecondaryButton: true,
        title: '',
        message: '',
        children: null,
        closeWrapper: () => {},
        wrapperClasses: '',
    };
    const {
        primaryButtonText,
        secondaryButtonText,
        onClickPrimaryButton,
        onClickSecondaryButton,
        hidePrimaryButton,
        hideSecondaryButton,
        icon,
        title,
        message,
        children,
        closeWrapper,
        wrapperClasses,
    } = modalProps;

    return (
        <ModalWrapper
            isModalOpen={true}
            onClose={closeWrapper || defaultProps.closeWrapper!}
            classes={wrapperClasses || defaultProps.wrapperClasses}
        >
            <GenericModalContent
                primaryButtonText={primaryButtonText}
                secondaryButtonText={secondaryButtonText}
                onClickPrimaryButton={onClickPrimaryButton || defaultProps.onClickPrimaryButton!}
                onClickSecondaryButton={onClickSecondaryButton || defaultProps.onClickSecondaryButton!}
                hidePrimaryButton={hidePrimaryButton}
                hideSecondaryButton={hideSecondaryButton === undefined ? defaultProps.hideSecondaryButton : hideSecondaryButton}
                icon={icon || defaultProps.icon}
                title={title || defaultProps.title!}
                message={message || defaultProps.message!}
            >
                {children || defaultProps.children}
            </GenericModalContent>
        </ModalWrapper>
    );
};

export default renderModal;
