import { IOwnershipTransfer, User } from '../../../common/interfaces';
import { fromJS, List } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import iconClose from '../../../assets/images/close-button-dark.svg';
import { AppStage } from '../../../common/enums/AppStage.enum';
import { AutomationIds } from '../../../common/enums/AutomationElements.enum';
import { NotificationType } from '../../../common/enums/NotificationType.enum';
import { getPendingReceivedTransfers } from '../../../common/utils/GetPendingTransfers';
import { Actions as AppActions } from '../../../containers/App/Actions';
import { appStageSelector, notificationsSelector } from '../../../containers/App/Selectors';
import { userSelector } from '../../../containers/Auth/Selectors';
import { Actions as OwnershipTransferActions } from '../../../containers/OwnershipTransfer/Actions';
import { pendingTransfersSelector } from '../../../containers/OwnershipTransfer/Selectors';
import { LanguageElementsProp, withLanguageElementsHOC } from '../../../language-elements/withLanguageElementsHOC';
import { StoreState } from '../../../store';
import { INotification } from './INotification';
import './Notification.css';

interface StateProps {
    notifications: INotification[];
    pendingTransfers: List<IOwnershipTransfer>;
    stage: AppStage;
    user: User;
}

interface DispatchProps {
    addNotification: () => AppActions;
    fetchingOwnershipTransfers: () => OwnershipTransferActions;
    removeNotification: () => AppActions;
}

export interface State {
    showNotification: boolean;
}

export type NotificationProps = StateProps & DispatchProps & LanguageElementsProp;

export class Notification extends React.Component<NotificationProps, State> {
    private displayTimer: any;

    public constructor(props: any) {
        super(props);

        // Set showNotification to false so that the CSS transition can slide in after the component is mounted.
        // Otherwise the transition is not smooth.
        this.state = {
            showNotification: false,
        };
    }

    // Note the click event on <button> is an empty function because the div has a click event that closes the notification
    public render(): React.ReactNode {
        const notifications = this.props.notifications ? fromJS(this.props.notifications).toJS() : undefined;
        const className = `notification ${this.state.showNotification ? 'slideIn' : 'slideOut'}`;

        const innerNotificationJSX = this.getInnerNotificationJSX(notifications);

        return (
            <div className={className} onClick={this.handleCloseNotification}>
                {innerNotificationJSX}
                <button data-client-id={AutomationIds.NOTIFICATION_CLOSE} className="notification-close-button">
                    <img src={iconClose} alt={this.props.languageElements.NOTIFICATION_ALT_TEXT} className="icon-close" />
                </button>
            </div>
        );
    }

    public componentDidUpdate(prevProps: NotificationProps): void {
        if (this.state.showNotification && this.props.stage !== AppStage.ActionInProgress) {
            this.closeNotificationAfterDisplayTime();
        }
        const currentPendingReceivedTransfers = this.props.pendingTransfers
            ? getPendingReceivedTransfers(this.props.pendingTransfers.toJS(), this.props.user.email)
            : undefined;
        const prevPendingReceivedTransfers = prevProps.pendingTransfers
            ? getPendingReceivedTransfers(prevProps.pendingTransfers.toJS(), this.props.user.email)
            : undefined;

        if (!currentPendingReceivedTransfers || currentPendingReceivedTransfers.length === 0) {
            return;
        }

        if (!prevPendingReceivedTransfers || prevPendingReceivedTransfers.length === 0) {
            this.props.addNotification();
            this.setState({ showNotification: true });
        }
    }

    private closeNotificationAfterDisplayTime = (): void => {
        // Wait 3 seconds before clearing notification
        this.displayTimer = setTimeout(this.closeNotification, 3000);
    };

    private closeNotification = () => {
        clearTimeout(this.displayTimer);
        if (this.state.showNotification) {
            this.setState({ showNotification: false }, this.removeNotificationAfterAnimation);
        }
    };

    private removeNotificationAfterAnimation = (): void => {
        // Wait 400ms before removing notification so that the message doesn't get cleared/updated before it has slid out of view
        setTimeout(this.props.removeNotification, 400);
    };

    private handleCloseNotification = () => {
        this.closeNotification();
    };

    private getInnerNotificationJSX = (notifications: INotification[]): JSX.Element | null => {
        if (!notifications || notifications.length === 0) {
            return null;
        }

        // Using switch statement here because we intend to handle additional kinds of notifications
        switch (notifications[0].type) {
            case NotificationType.PENDING_RECEIVED_TRANSFERS:
                return (
                    <div className="notification-text">
                        <p>
                            <Link to={'/transfers'} onClick={this.props.fetchingOwnershipTransfers}>
                                {this.props.languageElements.NOTIFICATION_MESSAGE}
                            </Link>
                        </p>
                    </div>
                );

            default:
                return null;
        }
    };
}

const mapState = createStructuredSelector<StoreState, StateProps>({
    notifications: notificationsSelector,
    pendingTransfers: pendingTransfersSelector,
    stage: appStageSelector,
    user: userSelector,
});

const mapDispatch: DispatchProps = {
    addNotification: AppActions.addNotification,
    fetchingOwnershipTransfers: OwnershipTransferActions.fetchingOwnershipTransfers,
    removeNotification: AppActions.removeNotification,
};

export default withLanguageElementsHOC(connect<StateProps, DispatchProps>(mapState, mapDispatch)(Notification));
