import { isNullOrEmptyString } from './StringUtil';

type QueryParamValue = number | boolean | string | Array<number | string | undefined> | undefined | null;

interface Options {
    path?: string;
    queryParams?: { [key: string]: QueryParamValue };
}

const addPath = (url: string, options: Options): string => {
    if (!options.path) {
        return url;
    }

    const path = options.path.trim();
    if (!url) {
        return path;
    }

    let builtUrl = url;

    if (builtUrl[builtUrl.length - 1] === '/') {
        builtUrl = builtUrl.slice(0, -1);
    }

    builtUrl = `${builtUrl}${path.indexOf('/') === 0 ? '' : '/'}${path}`;

    return builtUrl;
};

const addQueryParamKeyValue = (key: string, value: string | number | boolean | undefined | null, encodeValue: boolean = true): string | undefined => {
    if (key == null || isNullOrEmptyString(value)) {
        return;
    }
    if (encodeValue) {
        return key + '=' + encodeURIComponent(String(value).trim());
    }

    return key + '=' + String(value).trim();
};

export const addQueryString = (url: string, options: Options): string => {
    if (!options.queryParams) {
        return url;
    }

    const queryParamKeyValue = [];
    for (const key in options.queryParams) {
        if (!options.queryParams.hasOwnProperty(key) || options.queryParams[key] == null) {
            continue;
        }

        const queryParamValue = options.queryParams[key];
        if (Array.isArray(queryParamValue)) {
            // Convert query param array value to a comma separated string
            const values = [];
            for (const value of queryParamValue) {
                if (isNullOrEmptyString(value)) {
                    continue;
                }
                values.push(encodeURIComponent(String(value).trim()));
            }

            queryParamKeyValue.push(addQueryParamKeyValue(key, values.join(','), false));
        } else {
            queryParamKeyValue.push(addQueryParamKeyValue(key, queryParamValue));
        }
    }

    // Remove empty key value query params and join together with '&'
    const queryString: string = queryParamKeyValue.filter((queryStringParam) => Boolean(queryStringParam)).join('&');
    if (queryString) {
        return `${url}?${queryString}`;
    }

    return url;
};

/**
 * Builds a URL, including a path and query parameters
 */
export const buildUrl = <TOptions = Options>(url: string, options?: TOptions): string => {
    if (!options) {
        return url;
    }

    let builtUrl: string = url;

    builtUrl = addPath(builtUrl, options);

    builtUrl = addQueryString(builtUrl, options);

    return builtUrl;
};
