export enum ColumnTagType {
    CALENDAR_END_DATE = 'CALENDAR_END_DATE',
    CALENDAR_START_DATE = 'CALENDAR_START_DATE',
    GANTT_ALLOCATION = 'GANTT_ALLOCATION',
    GANTT_ASSIGNED_RESOURCE = 'GANTT_ASSIGNED_RESOURCE',
    GANTT_DISPLAY_LABEL = 'GANTT_DISPLAY_LABEL',
    GANTT_DURATION = 'GANTT_DURATION',
    GANTT_END_DATE = 'GANTT_END_DATE',
    GANTT_PERCENT_COMPLETE = 'GANTT_PERCENT_COMPLETE',
    GANTT_PREDECESSOR = 'GANTT_PREDECESSOR',
    GANTT_START_DATE = 'GANTT_START_DATE',
    CARD_DONE = 'CARD_DONE',
    BASELINE_START_DATE = 'BASELINE_START_DATE',
    BASELINE_END_DATE = 'BASELINE_END_DATE',
    BASELINE_VARIANCE = 'BASELINE_VARIANCE',
}
