import { OwnershipTransferStatus } from '../enums';
import { IOwnershipTransfer } from '../interfaces';
import classnames from 'classnames';
import * as React from 'react';
import acceptedIcon from '../../assets/images/ownershipTransferIcons/Accepted.svg';
import canceledIcon from '../../assets/images/ownershipTransferIcons/Canceled.svg';
import declinedIcon from '../../assets/images/ownershipTransferIcons/Declined.svg';
import Button from '../../components/Buttons/Button/Button';
import Spinner, { Color, Size } from '../../components/Spinner';
import { useLanguageElements } from '../../language-elements/withLanguageElementsHOC';
import { AutomationIds, AutomationTypes } from '../enums/AutomationElements.enum';
import { getLocaleDate, getLocaleTime } from './MomentDates';

// TODO: Refactor cell renderers to increase reusability

export const CellRendererText = (text: string | undefined, classes?: string): JSX.Element => {
    const className = classnames('cell-content', classes);
    const content = text ? text : '';

    return (
        <div title={content} className={className} data-client-type={AutomationTypes.OWNERSHIP_TRANSFER_CELL}>
            <div className="data-cell">
                <p>{content}</p>
            </div>
        </div>
    );
};

export const CellRendererDateTime = (date?: Date, showTime = false, classes?: string): JSX.Element => {
    const className = classnames('cell-content', classes);

    return (
        <div className={className} data-client-type={AutomationTypes.OWNERSHIP_TRANSFER_CELL}>
            <div className="data-cell">
                <div className="date-and-time">
                    <label className="optionLabel">{date ? getLocaleDate(date) : ''}</label>
                    {showTime && <label className="optionSubLabel">{date ? getLocaleTime(date) : ''}</label>}
                </div>
            </div>
        </div>
    );
};

// Ownership Transfer Cell Renderers
export const CellRendererReceivedActions = (
    transferId: string,
    status: OwnershipTransferStatus,
    onDecline: (transferId: string) => void,
    onAccept: (transferId: string) => void
): JSX.Element => {
    let innerContent: JSX.Element | null = null;
    const languageElements = useLanguageElements();

    switch (status) {
        case OwnershipTransferStatus.PENDING:
            innerContent = (
                <div className="data-cell transfer-buttons">
                    <Button
                        onClick={() => onDecline(transferId)}
                        className="btn btn-secondary"
                        text={languageElements.OWNERSHIP_TRANSFER_DECLINE}
                        dataClientId={AutomationIds.OWNERSHIP_TRANSFER_DECLINE}
                    />
                    <Button
                        onClick={() => onAccept(transferId)}
                        className="btn btn-non-primary"
                        text={languageElements.OWNERSHIP_TRANSFER_ACCEPT}
                        dataClientId={AutomationIds.OWNERSHIP_TRANSFER_ACCEPT}
                    />
                </div>
            );
            break;

        case OwnershipTransferStatus.ACCEPTED:
            innerContent = (
                <div className="data-cell resolved-action">
                    <div>
                        <img src={acceptedIcon} />
                        <div>{languageElements.OWNERSHIP_TRANSFER_ACCEPTED}</div>
                    </div>
                </div>
            );
            break;

        case OwnershipTransferStatus.REJECTED:
            innerContent = (
                <div className="data-cell resolved-action">
                    <div>
                        <img src={declinedIcon} />
                        <div>{languageElements.OWNERSHIP_TRANSFER_DECLINED}</div>
                    </div>
                </div>
            );
            break;

        case OwnershipTransferStatus.ACCEPT_IN_PROGRESS:
            innerContent = <Spinner color={Color.BLUE} size={Size.SMALL} label={languageElements.OWNERSHIP_TRANSFER_ACCEPT_IN_PROGRESS} />;
            break;

        case OwnershipTransferStatus.REJECT_IN_PROGRESS:
            innerContent = <Spinner color={Color.BLUE} size={Size.SMALL} label={languageElements.OWNERSHIP_TRANSFER_DECLINE_IN_PROGRESS} />;
            break;
    }

    return (
        <div className="cell-content" data-client-type={AutomationTypes.OWNERSHIP_TRANSFER_CELL}>
            {innerContent}
        </div>
    );
};

export const CellRendererSentActions = (transferId: string, status: OwnershipTransferStatus, onCancel: (transferId: string) => void): JSX.Element => {
    let innerContent: JSX.Element | null = null;
    const languageElements = useLanguageElements();

    switch (status) {
        case OwnershipTransferStatus.PENDING:
            innerContent = (
                <div className="data-cell transfer-buttons">
                    <Button
                        onClick={() => onCancel(transferId)}
                        className="btn btn-secondary"
                        text={languageElements.OWNERSHIP_TRANSFER_CANCEL}
                        dataClientId={AutomationIds.OWNERSHIP_TRANSFER_CANCEL}
                    />
                </div>
            );
            break;

        case OwnershipTransferStatus.CANCELED:
            innerContent = (
                <div className="data-cell resolved-action">
                    <div>
                        <img src={canceledIcon} />
                        <div>{languageElements.OWNERSHIP_TRANSFER_CANCELED}</div>
                    </div>
                </div>
            );
            break;

        case OwnershipTransferStatus.CANCEL_IN_PROGRESS:
            innerContent = <Spinner color={Color.BLUE} size={Size.SMALL} label={languageElements.OWNERSHIP_TRANSFER_CANCEL_IN_PROGRESS} />;
            break;
    }

    return (
        <div className="cell-content" data-client-type={AutomationTypes.OWNERSHIP_TRANSFER_CELL}>
            {innerContent}
        </div>
    );
};

export const CellRendererOrgViewsActions = (
    transfer: IOwnershipTransfer,
    onTransferOwnership: (transfer: IOwnershipTransfer) => void
): JSX.Element => {
    let innerContent: JSX.Element | null = null;
    const languageElements = useLanguageElements();

    switch (transfer.status) {
        case OwnershipTransferStatus.NONE:
            innerContent = (
                <div className="data-cell transfer-buttons">
                    <Button
                        onClick={() => onTransferOwnership(transfer)}
                        className="btn btn-secondary"
                        text={languageElements.TRANSFER_OWNERSHIP_BUTTON_TEXT}
                        dataClientId={AutomationIds.OWNERSHIP_TRANSFER}
                    />
                </div>
            );
            break;

        case OwnershipTransferStatus.PENDING:
            innerContent = (
                <div className="data-cell resolved-action">
                    <div>
                        <div className="orange-dot" />
                        <div>{languageElements.OWNERSHIP_TRANSFER_PENDING}</div>
                    </div>
                </div>
            );
            break;
    }

    return (
        <div className="cell-content" data-client-type={AutomationTypes.OWNERSHIP_TRANSFER_CELL}>
            {innerContent}
        </div>
    );
};
