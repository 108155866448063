import { AsyncStatus, OwnershipTransferStatus } from '../../common/enums';
import { AdminControlsData } from '../../common/interfaces';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loggingClient } from '../../http-clients/Logging.client';
import ownershipTransferClient from '../../http-clients/OwnershipTransfer.client';
import viewClient from '../../http-clients/View.client';
import { Actions as AppActions } from '../App/Actions';
import { AdminControlsMapper } from './AdminControlsMapper';
import { searchAndFilterViews } from './AdminControlsUtils';

interface AdminControlsViewsData {
    views: Map<string, AdminControlsData>;
    viewsTotal: number;
    viewsLoadingStatus: AsyncStatus;
    error?: string;
    handleUpdateViews: (views: Map<string, AdminControlsData>) => void;
}

const FILENAME = 'AdminControls/useAdminControlsViewsData.ts';

export const useAdminControlsViewsData = (searchValue: string): AdminControlsViewsData => {
    const [views, setViews] = useState<Map<string, AdminControlsData>>(new Map());
    const [viewsLoadingStatus, setViewsLoadingStatus] = useState<AsyncStatus>(AsyncStatus.NOT_STARTED);
    const [error, setError] = useState<string | undefined>(undefined);
    const dispatch = useDispatch();

    const filteredData = useMemo(() => searchAndFilterViews(views, searchValue), [views, searchValue]);

    useEffect(() => {
        if (viewsLoadingStatus === AsyncStatus.DONE || views.size) {
            return;
        }

        const fetchAdminControlViews = async () => {
            setViewsLoadingStatus(AsyncStatus.IN_PROGRESS);

            try {
                loggingClient.logInfo({
                    file: FILENAME,
                    message: 'Fetch Admin Controls Views - In progress',
                });

                const orgViews = await viewClient.getViews(false, true, false, true);
                const pendingOwnershipTransfers = await ownershipTransferClient.get(OwnershipTransferStatus.PENDING);

                const pendingOwnershipTransferIds = new Set(pendingOwnershipTransfers.data.map((transfer) => transfer.view.id));
                const newViews = new Map<string, AdminControlsData>();

                orgViews.forEach((view) => {
                    if (!pendingOwnershipTransferIds.has(view.id)) {
                        newViews.set(view.id, AdminControlsMapper.fromHomeView(view));
                    }
                });

                setViews(newViews);
            } catch (err) {
                loggingClient.logError(FILENAME, 'Fetch Admin Controls Views - Error', err);
                setError(err.message);
            } finally {
                setViewsLoadingStatus(AsyncStatus.DONE);
                dispatch(AppActions.resetAppStage());
            }
        };

        fetchAdminControlViews();
    }, [views, dispatch, viewsLoadingStatus]);

    return { views: filteredData, viewsLoadingStatus, error, viewsTotal: views.size, handleUpdateViews: setViews };
};
