import { Comment, UpsertCommentDto } from 'src/containers/View/Details/Conversation/types';
import axiosInstance from './Axios.instance';

class ConversationClient {
    public static getInstance(): ConversationClient {
        if (!ConversationClient.instance) {
            ConversationClient.instance = new ConversationClient();
        }
        return ConversationClient.instance;
    }

    private static instance: ConversationClient;
    private static url = '/views';

    private constructor() {}

    // Conversation Service CRUD methods
    public async getRowConversationComments(viewId: string, rowId: string, reportSheetId?: number): Promise<Comment[]> {
        const url = `${ConversationClient.url}/${viewId}/rows/${rowId}/conversations`;

        const response = await axiosInstance.get<Comment[]>(url, {
            params: {
                rsid: reportSheetId,
            },
        });

        return response.data;
    }

    public async addConversationComment(
        viewId: string,
        rowId: string,
        text: string,
        replyToId?: string,
        reportSheetId?: number
    ): Promise<UpsertCommentDto> {
        const url = `${ConversationClient.url}/${viewId}/rows/${rowId}/conversations`;

        const response = await axiosInstance.post<UpsertCommentDto>(
            url,
            { text, replyToId },
            {
                params: {
                    rsid: reportSheetId,
                },
            }
        );
        return response.data;
    }

    public async updateConversationComment(
        viewId: string,
        rowId: string,
        text: string,
        commentId: string,
        reportSheetId?: number
    ): Promise<UpsertCommentDto> {
        const url = `${ConversationClient.url}/${viewId}/rows/${rowId}/conversations/${commentId}`;
        const result = await axiosInstance.put<UpsertCommentDto>(
            url,
            { text, commentId },
            {
                params: {
                    rsid: reportSheetId,
                },
            }
        );
        return result.data;
    }

    public async deleteConversationComment(
        viewId: string,
        rowId: string,
        commentId: string,
        reportSheetId?: number,
        deleteReplies?: boolean
    ): Promise<void> {
        await axiosInstance.delete(`${ConversationClient.url}/${viewId}/rows/${rowId}/conversations/${commentId}`, {
            params: {
                rsid: reportSheetId,
                deleteReplies,
            },
        });
    }
}

const conversationClient = ConversationClient.getInstance();
export default conversationClient;
