import { LogClientType } from '../common/enums';
import { buildUrl } from '../common/utils';
import { isPrimitiveType } from '../common/utils/IsPrimitiveType';
import { AxiosError } from 'axios';
import { isAxiosErrorWithResponse } from '../common/utils/isAxiosErrorWithResponse';
import axiosInstance from './Axios.instance';

export interface MappedDiagnosticContext {
    [key: string]: number | string | boolean | undefined;
}

type LogObject = MappedDiagnosticContext;

class LoggingClient {
    public static getInstance(): LoggingClient {
        const instanceExists = LoggingClient.instance !== undefined;
        if (!instanceExists) {
            LoggingClient.instance = new LoggingClient();
        }
        return LoggingClient.instance;
    }

    private static instance: LoggingClient;

    private static async log(type: LogClientType, logObject: LogObject): Promise<void> {
        try {
            const url: string = buildUrl('/logging', {
                queryParams: {
                    type,
                },
            });
            await axiosInstance.post(url, logObject);
        } catch {
            // This is a call and forget style request, ignore error and continue. We do not want to have errors logging info to impact
            // user experience.
        }
    }

    private static logMappedDiagnosticContext(type: LogClientType, mappedDiagnosticContext: MappedDiagnosticContext): void {
        const cleanedMappedDiagnosticContext: MappedDiagnosticContext = {};

        // Clean the mappedDiagnosticContext of all non-primitive values to reduce possibilities of logging sensitive data
        for (const prop in mappedDiagnosticContext) {
            if (mappedDiagnosticContext.hasOwnProperty(prop) && isPrimitiveType(mappedDiagnosticContext[prop])) {
                cleanedMappedDiagnosticContext[prop] = mappedDiagnosticContext[prop];
            }
        }

        // This is a call and forget style request, ignore error and continue.
        // We don't want error logging to impact the user experience.
        LoggingClient.log(type, cleanedMappedDiagnosticContext).catch((_) => {});
    }

    public logError(file: string, funcName: string, error: Error | AxiosError | string, additionalInfo?: MappedDiagnosticContext): void {
        const message = typeof error === 'string' ? error : error.message;

        const mappedDiagnosticContext: MappedDiagnosticContext = {
            ...additionalInfo,
            message,
            file,
            funcName,
        };

        if (isAxiosErrorWithResponse(error)) {
            const axiosResponse = error.response!;
            mappedDiagnosticContext.statusCode = axiosResponse.status;
            mappedDiagnosticContext.errorCode = axiosResponse.data ? axiosResponse.data.errorCode : undefined;
        }

        LoggingClient.logMappedDiagnosticContext(LogClientType.ERROR, mappedDiagnosticContext);
    }

    public logInfo(info: MappedDiagnosticContext): void {
        LoggingClient.logMappedDiagnosticContext(LogClientType.INFO, info);
    }
}

export const loggingClient = LoggingClient.getInstance();
