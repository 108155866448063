import styled from '@emotion/styled';
import { colors, Link, sizes } from '@smartsheet/lodestar-core';
import { IHyperlink } from '../../common/interfaces';
import * as React from 'react';
import { AutomationIds } from '../../common/enums/AutomationElements.enum';

/**
 * Prepends 'http://' to a string if it doesn't already have it.
 * from app-core
 */
const prependHttp = (url: string | undefined): string | undefined => {
    if (url && url.length > 0 && !/(^http|^sip)/i.test(url)) {
        return 'http://' + url;
    } else {
        return url;
    }
};

interface Props {
    inputIndex: number;
    hyperlink: IHyperlink;
    value?: string;
}

const FormFieldHyperlink = ({ inputIndex, hyperlink, value }: Props) => {
    return (
        <StyledLinkBox>
            <Link
                key={`hyp-${inputIndex}`}
                href={prependHttp(hyperlink.url)}
                data-client-type={AutomationIds.FIELD_FIELD_WRAPPER_LINK}
                data-testid={AutomationIds.FIELD_FIELD_WRAPPER_LINK}
                target={'_blank'}
                rel={'noopener noreferrer'}
            >
                {value}
            </Link>
        </StyledLinkBox>
    );
};

const StyledLinkBox = styled.div`
    height: ${sizes.xLarge}px;
    box-sizing: border-box;
    border: solid 1px #cccccc;
    border-radius: 2px;
    padding: 5px;
    font-size: 13px;
    background-color: ${colors.buttonDefault};
    padding-left: ${sizes.xSmall}px;
`;

export default FormFieldHyperlink;
