import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Diff } from 'utility-types';
import * as AppActions from '../../../containers/App/Actions';

export interface WithSetAppErrorProps {
    onSetAppStageError: (error: Error) => void;
}

interface DispatchProps {
    setAppStageError: (error: Error) => AppActions.Actions;
}

const withSetAppError = <BaseProps extends WithSetAppErrorProps>(BaseComponent: React.ComponentType<BaseProps>) => {
    type HocProps = Diff<BaseProps, WithSetAppErrorProps>;

    class WithSetAppError extends React.Component<DispatchProps> {
        // Enhance component name for debugging and React-Dev-Tools
        public static displayName = `withSetAppError(${BaseComponent.name})`;

        public render(): React.ReactNode {
            return (
                <BaseComponent
                    {...(this.props as unknown) as BaseProps}
                    onSetAppStageError={error => this.props.setAppStageError(error)}
                />
            );
        }
    }

    const mapDispatch = (dispatch: Dispatch): DispatchProps => {
        return {
            setAppStageError: (error: Error) => dispatch(AppActions.Actions.setAppStageError(error)),
        };
    };

    return connect<null, DispatchProps, HocProps>(null, mapDispatch)(WithSetAppError);
};

export default withSetAppError;
